export const CLIENT_EVENT_STATUSES = {
  pendingRegistration: 'Pending Registration',
  interested: 'Interested',
  contacted: 'Contacted',
  contactedNotInterested: 'Contacted - Not Interested',
  cantMakeIt: "Can't make it",
  speaker: 'Speaker',
  specialGuest: 'Special Guest',
  specialGuestVirtual: 'Special Guest Virtual',
  paidDelegate: 'Paid Delegate',
  paidDelegateVirtual: 'Paid Delegate Virtual',
  registered: 'Registered (invoice sent)',
  staff: 'Staff',
  press: 'Press',
  sponsor: 'Sponsor',
  unknown: 'Unknown'
};
