import * as yup from 'yup';

export const invoiceItemUpdateSchema = yup.object().shape({
  discountType: yup
    .string()
    .nullable()
    .when('discountValue', {
      is: discountValue => discountValue.toString() !== '0',
      then: schema => schema.required('Discount type is required')
    })
});
