import React from 'react';

import { Field, SelectField } from 'components';

import { priceSeriesUnitList } from './sdk';

class PriceSeriesUnitSelect extends React.Component {
  state = {
    priceSeriesUnits: []
  };

  async componentDidMount() {
    const { data, success } = await priceSeriesUnitList();

    if (success) {
      this.setState({ priceSeriesUnits: data });
    }
  }

  render() {
    const { name, ...rest } = this.props;
    const { priceSeriesUnits } = this.state;

    const options = priceSeriesUnits.map(unit => {
      return {
        key: unit.id,
        value: unit.id,
        label: unit.title
      };
    });

    return (
      <Field
        name={name || 'priceSeriesUnit'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default PriceSeriesUnitSelect;
