import React from 'react';

import _ from 'lodash';

import { Field, FastField, SelectField } from 'components';

const STEEL_GROUPS = [
  'Flat Products',
  'Long Products',
  'Raw Materials',
  'Tubes and Pipes',
  'Energy for Manufacturing',
  'Global News',
  'Corporate & Industry'
];

class SteelGroupSelect extends React.Component {
  state = {
    options: STEEL_GROUPS.map(group => ({
      key: group,
      value: group,
      label: group
    }))
  };

  render() {
    const { name, fast = false, ...rest } = this.props;

    const { options } = this.state;

    let Component = Field;
    if (fast) {
      Component = FastField;
    }

    if (!_.isEmpty(options)) {
      rest['options'] = options;
    }

    return (
      <Component
        name={name || 'steelGroup'}
        component={SelectField}
        isClearable
        {...rest}
      />
    );
  }
}

export default SteelGroupSelect;
