import React from 'react';

import _ from 'lodash';
import moment from 'moment';

import { Formik } from 'formik';
import { Modal, Icon, Header, Form } from 'semantic-ui-react';

import {
  Flex,
  FormRow,
  CreateButton,
  MonthSelect,
  YearSelect,
  Field,
  DateField
} from 'components';
import { prepareFormData, handleFormErrors } from 'utils/forms';

import { notifySuccess, notifyErrors } from 'utils/notifications';
import { moveRenewalSnapshotSchema } from './utils';
import { moveRenewal, renewalClientPredictedExpirationDate } from './sdk';

class MoveRenewalModal extends React.Component {
  state = {
    newExpirationDate: null
  };

  close = () => {
    this.props.onClose();
  };

  handleSubmit = async (values, actions) => {
    const { setFieldError, setSubmitting } = actions;

    const data = prepareFormData(values);

    const id = this.props.client.id;

    setSubmitting(true);

    const { success, errors } = await moveRenewal(id, data);

    if (success) {
      notifySuccess('Successfully moved client snapshot.');
      this.props.refetch();
      this.close();
    } else {
      handleFormErrors(errors, setFieldError);
    }

    setSubmitting(false);
  };

  async componentDidMount() {
    const { client } = this.props;

    const {
      success,
      data,
      errors
    } = await renewalClientPredictedExpirationDate(client.id);

    if (success) {
      this.setState({ newExpirationDate: data.expiry_date });
    } else {
      notifyErrors(errors);
    }
  }

  render() {
    const { client } = this.props;

    const { newExpirationDate } = this.state;

    const initialValues = {
      year: new Date().getFullYear(),
      month: '',
      newExpirationDate
    };

    const formattedDate = moment(newExpirationDate).format('ll');

    return (
      <Modal open={true} size="large" centered={false}>
        <Modal.Header>
          <Flex spaceBetween>
            <div>
              <Header as="h2">
                Move {client.firstName} {client.lastName}({client.clientId}) to
                different monthly snapshot
              </Header>
              <Header as="h3">
                Current renewal date: {client.renewalDate}
              </Header>
            </div>
            <Icon name="close" onClick={this.close} />
          </Flex>
        </Modal.Header>
        <Modal.Content>
          <Formik
            initialValues={initialValues}
            onSubmit={this.handleSubmit}
            enableReinitialize={true}
            validationSchema={moveRenewalSnapshotSchema}>
            {({ handleSubmit, isSubmitting }) => (
              <Form>
                <FormRow alignEnd>
                  <YearSelect
                    width={5}
                    name="year"
                    label="Year"
                    isClearable={false}
                  />
                  <MonthSelect width={5} name="month" label="Month" />
                </FormRow>
                <FormRow>
                  <Field
                    required
                    width={5}
                    name="newExpirationDate"
                    component={DateField}
                    label="New Expiration Date"
                  />
                </FormRow>
                <FormRow>
                  <Header as="h4">
                    {_.isNil(newExpirationDate) &&
                      'Subscription was not found. Please set New Expiration Date manually.'}
                    {!_.isNil(newExpirationDate) &&
                      formattedDate === client.subscriptionExpiryDate &&
                      'Subscription was found successfully, but the new expiration date is the same as the old one. Please set New Expiration Date manually.'}
                    {!_.isNil(newExpirationDate) &&
                      formattedDate !== client.subscriptionExpiryDate &&
                      'New Expiration Date has been populated successfully from the detected subscription.'}
                  </Header>
                </FormRow>
                <Form.Field width={3}>
                  <CreateButton onClick={handleSubmit} disabled={isSubmitting}>
                    Move Client
                  </CreateButton>
                </Form.Field>
              </Form>
            )}
          </Formik>
        </Modal.Content>
      </Modal>
    );
  }
}

export default MoveRenewalModal;
