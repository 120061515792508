import React from 'react';
import _ from 'lodash';

import { withRouter, Link } from 'react-router-dom';

import { Header, Table, Segment } from 'semantic-ui-react';

import { Page, LoginRequired, Error } from 'components';

import {
  PERMISSIONS,
  PermissionsRequired,
  DisableableElement,
  hasPermission
} from 'permissions';

import { formatDateTime } from 'utils';

import { pageUrl } from 'config/routes';

import { InvoiceDetail, ClientDetail } from 'pages';

import { orderDetail } from './sdk';

import { OrderUpdateForm } from './components';

const ItemsTable = ({ items }) => (
  <Table celled selectable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Item</Table.HeaderCell>
        <Table.HeaderCell>Price</Table.HeaderCell>
        <Table.HeaderCell>Quantity</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {items.map(
        item =>
          item.client_item && (
            <Table.Row key={item.id}>
              {/* TODO: Add link to client item preview */}
              <Table.Cell>{_.get(item, 'client_item.id', 'N/A')}</Table.Cell>
              <Table.Cell>
                {item.client_item.price} {item.client_item.currency.code}
              </Table.Cell>
              <Table.Cell>{item.client_item.quantity}</Table.Cell>
            </Table.Row>
          )
      )}
    </Table.Body>
  </Table>
);

class OrderDetail extends React.Component {
  state = {
    errors: null,
    order: null
  };

  fetchOrder = async () => {
    const { orderId } = this.props.match.params;

    const { data, errors, success } = await orderDetail(orderId);

    if (success) {
      this.setState({ order: data });
    } else {
      this.setState({ errors });
    }
  };

  componentDidMount() {
    this.fetchOrder();
  }

  getClientDetailUrl = clientId => {
    return pageUrl(ClientDetail, { clientId });
  };

  render() {
    const { user, crumbs } = this.props;

    const { order, errors } = this.state;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Header as="h1">Edit Order</Header>
              {errors &&
                errors.map((error, index) => (
                  <Error key={index}>{error.message}</Error>
                ))}
              {order && (
                <>
                  <DisableableElement
                    disabled={!hasPermission(user, PERMISSIONS.CHANGE_ORDERS)}>
                    <OrderUpdateForm
                      order={order}
                      fetchOrder={this.fetchOrder}
                    />
                  </DisableableElement>
                  <div>
                    Client:{' '}
                    <Link to={this.getClientDetailUrl(order.client.id)}>
                      ID {order.client.id}
                    </Link>{' '}
                    {order.client.first_name} {order.client.last_name}
                  </div>
                  {order.invoice && (
                    <div>
                      <Link
                        to={pageUrl(InvoiceDetail, {
                          invoiceId: order.invoice.id
                        })}>
                        {order.invoice.identifier}
                      </Link>
                    </div>
                  )}
                  <div>Date: {formatDateTime(order.date_ordered)}</div>
                  <div>Total price: {order.total_price}</div>
                  <div>Currency: {order.currency.code}</div>
                  <div>Payment type: {order.payment_type}</div>
                  <div>Items: </div>
                  <ItemsTable items={order.order_items} />
                </>
              )}
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(
  PermissionsRequired([PERMISSIONS.VIEW_ORDERS])(withRouter(OrderDetail))
);
