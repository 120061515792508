import React from 'react';
import _ from 'lodash';

import { TransferListField, Field } from 'components';

import { adminList } from './sdk';

class AdminTransferSelect extends React.Component {
  state = {
    admins: []
  };

  async componentDidMount() {
    const { data, success } = await adminList();

    if (success) {
      let admins = data.map(admin => {
        return {
          key: admin.id,
          value: admin.id,
          label: `${admin.first_name} ${admin.last_name}`
        };
      });
      admins = _.sortBy(admins, 'label');
      this.setState({ admins });
    }
  }

  render() {
    const { name, ...rest } = this.props;
    const { admins } = this.state;

    return (
      <Field
        name={name || 'Admins'}
        component={TransferListField}
        options={admins}
        {...rest}
      />
    );
  }
}

export default AdminTransferSelect;
