export const DEFAULT_BANNERS = [
  {
    name: 'big_banner_center',
    label: 'Big Banner Center [778x91]'
  },
  { name: 'big_banner_top_left', label: 'Big Banner Top Left' },
  {
    name: 'second_big_banner_center',
    label: 'Second Big Banner Center [778x91]'
  },
  {
    name: 'bottom_right_column_banner',
    label: 'Bottom Right Small Banner Top'
  },
  {
    name: 'bottom_right_small_banner_middle',
    label: 'Bottom Right Small Banner Middle'
  },
  {
    name: 'bottom_right_small_banner_bottom',
    label: 'Bottom Right Small Banner Bottom'
  },
  {
    name: 'small_banner_top_right',
    label: 'Small Banner Top Right [288x91]'
  },
  {
    name: 'small_banner_bottom_right',
    label: 'Small Banner Bottom Right [288x91]'
  }
];

// For the Steel & PM homepage configurations,
// we use different order & labels for the banners,
// so that they make more sense in this context.
export const HOMEPAGE_BANNERS = [
  {
    name: 'big_banner_center',
    label: 'Top Row Left [778x91]'
  },
  {
    name: 'second_big_banner_center',
    label: 'Top Row Right [778x91]'
  },
  { name: 'big_banner_top_left', label: 'Middle Row Left' },
  {
    name: 'small_banner_top_right',
    label: 'Middle Row Middle [288x91]'
  },
  { name: 'small_banner_bottom_right', label: 'Middle Row Right [288x91]' },
  { name: 'bottom_right_column_banner', label: 'Bottom Row Left' },
  {
    name: 'bottom_right_small_banner_middle',
    label: 'Bottom Row Middle'
  },
  {
    name: 'bottom_right_small_banner_bottom',
    label: 'Bottom Row Right'
  }
];
