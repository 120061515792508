import _ from 'lodash';
import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import { Segment, Button, Form, Header } from 'semantic-ui-react';

import { Formik } from 'formik';

import { openInNewTab } from 'utils';

import { Field, FormRow, InputField, UniSelect, AdminSelect } from 'components';
import { generatePdf, getPdfUrl } from './sdk';
import { prepareFormData } from 'utils/forms';

function DownloadPdfForm({ newsletter }) {
  const { productSlug } = useParams();
  const [pdfLink, setPdfLink] = useState(null);

  let pollInterval = null;

  const handleSubmit = async (values, actions) => {
    setPdfLink(null);
    const formData = prepareFormData(values);
    const { success, data } = await generatePdf(newsletter.id, formData);
    // It is possible that PDF is alreay generated and returned
    // with the response
    if (success) {
      // No PDF generate it and start polling for it
      if (_.isNil(data.pdf_path)) {
        const intervalTime = 1000 * 5; // 10 sec
        pollInterval = setInterval(
          () => getPdfLink(formData, actions),
          intervalTime
        );
        // We have it so just set it
      } else {
        const { setSubmitting } = actions;
        setSubmitting(false);
        setPdfLink(data.pdf_path);
      }
    }
  };

  const getPdfLink = async (params, actions) => {
    const { data, success } = await getPdfUrl(newsletter.id, params);

    if (success) {
      setPdfLink(data.pdf_path);
      if (!_.isNil(data.pdf_path)) {
        const { setSubmitting } = actions;
        clearInterval(pollInterval);
        setSubmitting(false);
      }
    }
  };

  const initialValues = {
    sampleName: 'Kallanish News 2020',
    recipientGroup: null,
    region: null,
    emailType: 'PDF',
    accountManager: null
  };

  return (
    <Segment>
      <Header as="h3">Preview Newsletter as PDF</Header>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}>
        {({ values, handleSubmit, isSubmitting }) => (
          <Form>
            <FormRow widths="equal">
              <Field
                required
                component={InputField}
                name="sampleName"
                label="Sample Name"
              />
              <UniSelect
                name="recipientGroup"
                label="Recipient Group"
                source="recipientGroups"
                productSlug={productSlug}
              />
            </FormRow>
            <FormRow alignEnd>
              <UniSelect name="region" label="Region" source="regions" />
              <Field
                component={AdminSelect}
                name="accountManager"
                label="Account Manager"
              />
            </FormRow>
            <FormRow alignEnd>
              <Button
                size="large"
                onClick={handleSubmit}
                type="submit"
                primary
                disabled={isSubmitting}
                loading={isSubmitting}>
                Generate PDF
              </Button>
              {!_.isNil(pdfLink) && (
                <Button
                  size="large"
                  onClick={() => {
                    openInNewTab(pdfLink);
                  }}
                  color="green"
                  disabled={false}>
                  Open PDF
                </Button>
              )}
            </FormRow>
          </Form>
        )}
      </Formik>
    </Segment>
  );
}
export default DownloadPdfForm;
