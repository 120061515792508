import React from 'react';

import { Field, SelectField } from 'components';

const REGISTRATION_TYPES = ['Subscription', 'Subscription Trial'];

class WebsiteRegistrationTypeSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = REGISTRATION_TYPES.map(registrationType => {
      return {
        key: registrationType,
        value: registrationType,
        label: registrationType
      };
    });
    return (
      <Field
        name={name || 'registrationType'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default WebsiteRegistrationTypeSelect;
