import {
  InputField,
  SubscriptionSelect,
  SubscriptionStatusSelect,
  AdminSelect,
  RegionSelect,
  CountrySelect,
  DateField
} from 'components';

export const FILTERS = [
  [
    {
      name: 'login_time_from',
      emptyValue: null,
      component: DateField,
      label: 'Start Date'
    },
    {
      name: 'login_time_to',
      emptyValue: null,
      component: DateField,
      label: 'End Date'
    },
    {
      name: 'client',
      emptyValue: '',
      component: InputField,
      label: 'Client ID'
    }
  ],
  [
    {
      name: 'login_count_from',
      emptyValue: '',
      component: InputField,
      label: 'Login count from',
      width: 4
    },
    {
      name: 'login_count_to',
      emptyValue: '',
      component: InputField,
      label: 'Login count to',
      width: 4
    }
  ],
  [
    {
      name: 'subscription',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: SubscriptionSelect,
      label: 'Subscription'
    },
    {
      name: 'subscription_status',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: SubscriptionStatusSelect,
      label: 'Subscription Status'
    }
  ],
  [
    {
      name: 'region',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: RegionSelect,
      label: 'Region'
    },
    {
      name: 'country',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: CountrySelect,
      label: 'Country'
    },
    {
      name: 'account_manager',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: AdminSelect,
      label: 'Account manager'
    }
  ]
];
