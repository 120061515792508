import React from 'react';

import './styles.css';

class FormError extends React.Component {
  render() {
    return <p className="FormError">{this.props.children}</p>;
  }
}

export default FormError;
