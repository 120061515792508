import React from 'react';

import { Field, SelectField } from 'components';
const EVENT_STATUSES = [
  'Pending Registration',
  'Interested',
  'Contacted',
  'Contacted - Not Interested',
  "Can't make it",
  'Speaker',
  'Special Guest',
  'Special Guest Virtual',
  'Paid Delegate',
  'Paid Delegate Virtual',
  'Registered (invoice sent)',
  'Staff',
  'Press',
  'Sponsor'
];

class EventStatusSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = EVENT_STATUSES.map(eventStatus => {
      return {
        key: eventStatus,
        value: eventStatus,
        label: eventStatus
      };
    });

    return (
      <Field
        name={name || 'status'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default EventStatusSelect;
