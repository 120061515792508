import React from 'react';

import { withRouter } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  InputField,
  FormRow,
  UpdateButtonInRow,
  UnsavedFormPrompt,
  CheckboxField,
  RichTextAreaField,
  FaqSelect,
  FaqCategorySelect
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import NonFormValuesDisplay from './NonFormValuesDisplay';
import { faqUpdateSchema } from './utils';
import { faqUpdate } from './sdk';

class FaqUpdateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { faq } = this.props;

    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await faqUpdate(faq.id, data);

    setSubmitting(false);

    if (success) {
      notifySuccess('FAQ updated.');
      this.props.fetchFaq();
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  render() {
    const { faq } = this.props;

    const initialValues = {
      question: faq.question,
      slug: faq.slug,
      isPublished: faq.is_published,
      category: faq.category,
      answer: faq.answer,
      relatedFaqs: faq.related_faqs
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={faqUpdateSchema}
        onSubmit={this.handleSubmit}>
        {({ values, handleSubmit, isSubmitting, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty} />
            <UpdateButtonInRow
              subject="FAQ"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
            <FormRow>
              <Field
                width={6}
                name="question"
                label="Question"
                required
                component={InputField}
              />
              <Field
                width={6}
                name="slug"
                label="Slug"
                required
                component={InputField}
              />
              <Field
                width={4}
                name="isPublished"
                label="Is published"
                component={CheckboxField}
              />
            </FormRow>
            <FormRow>
              <FaqCategorySelect
                width={6}
                name="category"
                label="FAQ Category"
              />
            </FormRow>
            <FormRow>
              <Field
                width={12}
                component={RichTextAreaField}
                label="Answer"
                name="answer"
              />
            </FormRow>
            <FormRow>
              <FaqSelect
                width={8}
                name="relatedFaqs"
                label="Related FAQs"
                excludeOptions={[faq.id]}
              />
            </FormRow>
            <NonFormValuesDisplay faq={faq} />
            <UpdateButtonInRow
              subject="FAQ"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(FaqUpdateForm);
