import React from 'react';

import { Table } from 'semantic-ui-react';

const TableFooter = ({ children, align = 'right' }) => (
  <Table.Footer>
    <Table.Row textAlign={align}>
      <Table.HeaderCell colSpan={20}>{children}</Table.HeaderCell>
    </Table.Row>
  </Table.Footer>
);

export default TableFooter;
