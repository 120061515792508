import React from 'react';

import { Field, SelectField } from 'components';

import { upcomingEventsList } from './sdk';

class UpcomingEventSelect extends React.Component {
  state = {
    upcomingEvents: []
  };

  async componentDidMount() {
    const { data, success } = await upcomingEventsList();

    if (success) {
      this.setState({ upcomingEvents: data });
    }
  }

  render() {
    const { name, ...rest } = this.props;
    const { upcomingEvents } = this.state;

    const options = upcomingEvents.map(upcomingEvent => {
      return {
        key: upcomingEvent.id,
        value: upcomingEvent.id,
        label: upcomingEvent.title
      };
    });

    return (
      <Field
        name={name || 'upcomingEvent'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default UpcomingEventSelect;
