import React from 'react';

import cx from 'classnames';

import { Form } from 'semantic-ui-react';

import styles from './styles.module.css';

const FormRow = ({
  children,
  className,
  flexEnd = false,
  alignCenter = false,
  alignEnd = false,
  ...rest
}) => {
  return (
    <Form.Group
      {...rest}
      className={cx(
        {
          [styles.flexEnd]: flexEnd,
          [styles.alignCenter]: alignCenter,
          [styles.alignEnd]: alignEnd
        },
        styles.FormRow,
        className
      )}>
      {children}
    </Form.Group>
  );
};

export default FormRow;
