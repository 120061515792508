import React from 'react';
import _ from 'lodash';
import { Field, SelectField } from 'components';
import { adminList } from 'sdk/shared';

class AdminSelect extends React.Component {
  state = {
    options: []
  };
  async componentDidMount() {
    const { data, success } = await adminList();

    if (success) {
      let options = data.map(admin => {
        return {
          key: admin.id,
          value: admin.id,
          label: `${admin.first_name} ${admin.last_name}`
        };
      });
      options = _.sortBy(options, 'label');
      this.setState({ options });
    }
  }

  render() {
    const { name, isCustom, ...rest } = this.props;
    const { options } = this.state;

    return (
      <Field
        name={name || 'admin'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default AdminSelect;
