import {
  InputField,
  DateTimeField,
  AdminSelect,
  YesNoSelect
} from 'components';
import { coerceStringToBoolean } from 'utils';

export const FILTERS = [
  [
    {
      name: 'search',
      emptyValue: '',
      placeholder: 'Search',
      component: InputField,
      label: 'Search',
      width: 8
    },
    {
      name: 'is_client',
      emptyValue: false,
      transform: coerceStringToBoolean,
      component: YesNoSelect,
      label: 'Website logins',
      width: 3
    },
    {
      name: 'browser',
      emptyValue: '',
      placeholder: 'Search',
      component: InputField,
      label: 'Browser',
      width: 5
    },
    {
      name: 'operating_system',
      emptyValue: '',
      placeholder: 'Search',
      component: InputField,
      label: 'Operating System',
      width: 5
    },
    {
      name: 'device',
      emptyValue: '',
      placeholder: 'Search',
      component: InputField,
      label: 'Device',
      width: 5
    }
  ],
  [
    {
      name: 'attempt_time_from',
      emptyValue: null,
      component: DateTimeField,
      label: 'Attempt Time From',
      width: 4
    },
    {
      name: 'attempt_time_to',
      emptyValue: null,
      component: DateTimeField,
      label: 'Attempt Time To',
      width: 4
    },
    {
      name: 'admin',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: AdminSelect,
      label: 'Staff',
      width: 6
    }
  ]
];
