import React from 'react';

import { Header, Segment, Divider } from 'semantic-ui-react';

import { LoginRequired, Page } from 'components';

import { TradeExcelCreateForm } from './components';

class TradeExcelCreate extends React.Component {
  render() {
    const { user, crumbs } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Header as="h1">Create trade excel</Header>
              <Divider />
              <TradeExcelCreateForm />
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(TradeExcelCreate);
