import React from 'react';

import { Table } from 'semantic-ui-react';

import { Formik } from 'formik';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess, notifyErrors } from 'utils/notifications';

import { Button, InputField, Field } from 'components';

import { subscriptionFeatureUpdate, subscriptionFeatureDelete } from './sdk';

import { featureSchema } from './utils';

class FeatureForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const { feature, fetchSubscription } = this.props;
    const data = {
      ...prepareFormData(values)
    };

    setSubmitting(true);

    const { success, errors } = await subscriptionFeatureUpdate(
      feature.id,
      data
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Feature updated.');
      fetchSubscription();
      return;
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  deleteFeature = async () => {
    const { feature, fetchSubscription } = this.props;

    const { success, errors } = await subscriptionFeatureDelete(feature.id);

    if (success) {
      notifySuccess('Feature removed.');
      fetchSubscription();
      return;
    } else {
      notifyErrors(errors);
    }
  };
  render() {
    const { feature } = this.props;

    const initialValues = {
      text: feature.text
    };

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        validationSchema={featureSchema}>
        {({ isSubmitting, handleSubmit }) => (
          <Table.Row>
            <Table.Cell width={10}>
              <Field name="text" component={InputField} />
            </Table.Cell>
            <Table.Cell width={6} textAlign="center">
              <Button
                color="blue"
                disabled={isSubmitting}
                onClick={handleSubmit}>
                Update
              </Button>
              <Button color="red" onClick={this.deleteFeature}>
                Delete
              </Button>
            </Table.Cell>
          </Table.Row>
        )}
      </Formik>
    );
  }
}

export default FeatureForm;
