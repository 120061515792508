import _ from 'lodash';
import React from 'react';

import { Header, Segment, Label } from 'semantic-ui-react';
import { Page, LoginRequired, Flex } from 'components';

import styles from './styles.module.css';

const CALENDARS_URLS = [
  'https://calendar.google.com/calendar/embed?title=Kallanish%20Group%20Calendar&height=600&wkst=2&bgcolor=%2399ff99&src=kallanish.com_pf2veatji3v3k8n6gh701kqogs%40group.calendar.google.com&color=%2323164E&ctz=Europe%2FLondon',
  'https://calendar.google.com/calendar/embed?title=Kallanish%20Events%20Calendar&height=600&wkst=2&bgcolor=%2399ff99&src=c_bacdf1034324eee58da9157e5a8e2062a1b70c8f92d37620b769a85f17e5c90e%40group.calendar.google.com&ctz=Europe%2FLondon'
];

const VISION =
  'To be the preferred market intelligence resource for the global steel and energy industries.';

const MISSION =
  'To be recognised as authoritative and insightful, offering timely and relevant news and information to commodities professionals.';

const KALLANISH_VALUES = [
  'Market-led',
  'High quality products and services',
  'Trusted resource',
  'Dynamic and forward thinking',
  'Independent'
];

class Dashboard extends React.Component {
  buildCalendarElement = url => ({
    __html: `<iframe
              src="${url}"
              style="border:solid 1px #777"
              width="100%"
              height="600"
              frameborder="0"
              scrolling="no"></iframe>`
  });

  render() {
    const { user } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content maxWidth>
            <div className={styles.contentWrapper}>
              <div className={styles.calendarWrapper}>
                {_.map(CALENDARS_URLS, (url, index) => (
                  <div
                    key={index}
                    dangerouslySetInnerHTML={this.buildCalendarElement(url)}
                  />
                ))}
              </div>

              <div className={styles.valuesWrapper}>
                <Segment color="green" style={{ backgroundColor: 'white' }}>
                  Vision
                  <Header textAlign="center">{VISION}</Header>
                </Segment>
                <Segment color="red" style={{ backgroundColor: 'white' }}>
                  Mission
                  <Header textAlign="center">{MISSION}</Header>
                </Segment>
                <Segment color="yellow" style={{ backgroundColor: 'white' }}>
                  <div>Values</div>
                  <Flex className={styles.valuesRow}>
                    {KALLANISH_VALUES.map(value => (
                      <Label
                        key={value}
                        color="yellow"
                        basic
                        size="large"
                        style={{ marginTop: '10px' }}>
                        <Header>{value}</Header>
                      </Label>
                    ))}
                  </Flex>
                </Segment>
              </div>
            </div>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(Dashboard);
