import React from 'react';

import { Formik } from 'formik';

import { Segment, Form } from 'semantic-ui-react';

import { buildInitialValuesFromFilters } from 'utils/filters';
import { coerceStringToBoolean } from 'utils';

import {
  Field,
  FormRow,
  FilterButtons,
  DateField,
  YesNoSelect
} from 'components';

const FILTERS = [
  {
    name: 'status',
    emptyValue: null,
    transform: coerceStringToBoolean
  },
  {
    name: 'date_from',
    emptyValue: null
  },
  {
    name: 'date_to',
    emptyValue: null
  }
];

class Filters extends React.Component {
  submitSearch = values => {
    this.props.filterBy(values);
  };

  resetSearch = resetForm => {
    const { clearFilters } = this.props;
    resetForm();
    clearFilters();
  };

  render() {
    const { filters } = this.props;

    const initialValues = buildInitialValuesFromFilters(filters, FILTERS);

    return (
      <Segment>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={this.submitSearch}>
          {({ values, handleSubmit, resetForm }) => (
            <Form>
              <FormRow>
                <Field
                  name="live"
                  label="Status"
                  component={YesNoSelect}
                  yesLabel="Live"
                  noLabel="Being Edited"
                />
                <Field
                  name="date_to_go_out_from"
                  component={DateField}
                  label="Date from"
                />
                <Field
                  name="date_to_go_out_to"
                  component={DateField}
                  label="Date to"
                />
              </FormRow>
              <FilterButtons
                searchAction={handleSubmit}
                resetAction={() => this.resetSearch(resetForm)}
              />
            </Form>
          )}
        </Formik>
      </Segment>
    );
  }
}

export default Filters;
