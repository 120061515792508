import React from 'react';

import { Field, SelectField } from 'components';

import { energyStaffList } from './sdk';

class EnergyStaffSelect extends React.Component {
  state = {
    energyStaff: []
  };
  async componentDidMount() {
    const { data, success } = await energyStaffList();

    if (success) {
      this.setState({ energyStaff: data });
    }
  }

  render() {
    const { name, ...rest } = this.props;
    const { energyStaff } = this.state;

    const options = energyStaff.map(user => {
      return {
        key: user.id,
        value: user.id,
        label: `${user.first_name} ${user.last_name}`
      };
    });

    return (
      <Field
        name={name || 'energyStaff'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default EnergyStaffSelect;
