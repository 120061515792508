import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const priceSpreadV2MovementList = productSlug => {
  const params = {
    product_type: productSlug,
    order: '-last_update_on',
    paginate: false
  };

  const url = `${BASE_URL}/prices/spreads/v2/`;

  return requestSdk(() => get(url, params));
};
