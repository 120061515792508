import React from 'react';

import _ from 'lodash';

import { Field, TransferListField } from 'components';

import { pastEventList } from './sdk';

class PastEventTransferSelect extends React.Component {
  state = {
    options: []
  };

  async componentDidMount() {
    const { data, success } = await pastEventList();

    if (success) {
      this.setState({ options: this.setOptions(data) });
    }
  }

  setOptions = pastEvents => {
    const { currentOptions } = this.props;

    let options = pastEvents.map(pastEvent => {
      return {
        key: pastEvent.id,
        value: pastEvent.id,
        label: pastEvent.title
      };
    });

    if (!_.isNil(currentOptions)) {
      options = _.uniqBy(
        [
          ...options,
          ...currentOptions.map(option => {
            return {
              key: option.id,
              value: option.id,
              label: option.title
            };
          })
        ],
        member => member.value
      );
    }

    return options;
  };

  render() {
    const { name, currentOptions, ...rest } = this.props;
    const { options } = this.state;

    return (
      <Field
        name={name || 'pastEvent'}
        component={TransferListField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default PastEventTransferSelect;
