import React from 'react';

import {
  Page,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Flex,
  OpenModalButton,
  ExternalLinkButton
} from 'components';
import { buildFilters } from 'utils/filters';

import {
  clientProfileLogList,
  clientProfileLogsExportDownloadUrl
} from './sdk';

import {
  Filters,
  ClientProfileLogTable,
  ClientProfileLogsReportModal
} from './components';

const DEFAULT_LIMIT = 50;

class ClientProfileLogList extends React.Component {
  render() {
    const {
      user,
      data,
      limit,
      count,
      page,
      changePage,
      changeRowsPerPage,
      filters,
      clear,
      filterBy,
      orderBy,
      sort
    } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content>
            <Flex spaceBetween>
              <Header>Client profile logs</Header>
              <OpenModalButton
                style={{ marginTop: '14px' }}
                size="large"
                modal={{
                  header: 'Preview Report',
                  render: props => (
                    <ClientProfileLogsReportModal filters={filters} />
                  ),
                  props: {
                    additonalActions: (
                      <ExternalLinkButton
                        color="blue"
                        url={clientProfileLogsExportDownloadUrl(
                          buildFilters(filters)
                        )}>
                        Download
                      </ExternalLinkButton>
                    )
                  }
                }}>
                View Report
              </OpenModalButton>
            </Flex>
            {data && (
              <>
                <Filters
                  filters={filters}
                  clearFilters={clear}
                  filterBy={filterBy}
                  count={count}
                />
                <ClientProfileLogTable
                  sort={sort}
                  orderBy={orderBy}
                  profileLogs={data}
                  pagination={
                    <Pagination
                      limit={limit}
                      count={count}
                      page={page}
                      changePage={changePage}
                      changeRowsPerPage={changeRowsPerPage}
                    />
                  }
                />
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: clientProfileLogList,
  limit: DEFAULT_LIMIT
})(LoginRequired(ClientProfileLogList));
