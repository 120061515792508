import { InputField, UniSelect } from 'components';

export const FILTERS = [
  [
    {
      name: 'search',
      emptyValue: '',
      component: InputField,
      label: 'General search',
      placeholder: 'Search...',
      width: 11
    },
    {
      name: 'product_slug',
      component: UniSelect,
      label: 'Products',
      props: () => ({
        isMulti: false,
        source: 'globalProducts'
      }),
      width: 5
    }
  ]
];
