import React from 'react';

import _ from 'lodash';

import { Field, SelectField } from 'components';

import { priceSeriesList } from './sdk';

class PriceSeriesSingleSelect extends React.Component {
  state = {
    priceSeries: []
  };

  async componentDidMount() {
    const { isPowerMaterial } = this.props;
    let params = {};

    if (isPowerMaterial) {
      params = { is_power_material_related: isPowerMaterial };
    }

    const { data, success } = await priceSeriesList(params);

    if (success) {
      this.setState({ priceSeries: data });
    }
  }

  render() {
    const {
      name,
      isPowerMaterial,
      excludeOptions = null,
      ...rest
    } = this.props;
    const { priceSeries } = this.state;

    let priceSeriesOptions = priceSeries;

    if (excludeOptions) {
      priceSeriesOptions = _.filter(
        priceSeriesOptions,
        option => _.indexOf(excludeOptions, option.id) < 0
      );
    }

    const options = _.sortBy(priceSeriesOptions, singlePriceSeries =>
      isPowerMaterial
        ? singlePriceSeries.main_group.option
        : singlePriceSeries.category.title
    ).map(singlePriceSeries => {
      return {
        key: singlePriceSeries.id,
        value: singlePriceSeries.id,
        label: singlePriceSeries.display_as
      };
    });

    return (
      <>
        <Field
          name={name || 'priceSeries'}
          component={SelectField}
          options={options}
          {...rest}
        />
      </>
    );
  }
}

export default PriceSeriesSingleSelect;
