import { RegionSelect, InputField } from 'components';

export const FILTERS = [
  [
    {
      name: 'title',
      emptyValue: '',
      component: InputField,
      label: 'Title',
      width: 5
    },
    {
      name: 'region',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      component: RegionSelect,
      label: 'Region',
      width: 5
    }
  ]
];
