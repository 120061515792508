import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

import { Table } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';
import { ClientDetail } from 'pages';
import { formatDate } from 'utils';

import PipelineItemForm from './PipelineItemForm';

import { getStatusColor, getTypeColor } from './utils';

class PipelineItemRow extends React.Component {
  render() {
    const { item, canUpdate } = this.props;

    return (
      <>
        <Table.Row>
          <Table.Cell>
            <Link to={pageUrl(ClientDetail, { clientId: item.client.id })}>
              {item.client.id}
            </Link>
          </Table.Cell>
          <Table.Cell>
            {_.get(item, 'client.client_profile.company_name')}
          </Table.Cell>
          <Table.Cell>
            {_.get(item, 'client.client_profile.country.title')}
          </Table.Cell>
          <Table.Cell
            style={{
              backgroundColor: getTypeColor(item.pipeline_type)
            }}>
            {item.pipeline_type}
          </Table.Cell>
          <Table.Cell>{_.get(item, 'invoice_item.type', 'Unknown')}</Table.Cell>
          <Table.Cell>
            {_.get(item, 'client_item.item.title') || _.get(item, 'item.title')}
          </Table.Cell>
          {!canUpdate && (
            <>
              <Table.Cell>
                {item.forecasted_value_gbp
                  ? item.forecasted_value_gbp.toFixed(2)
                  : 0}
              </Table.Cell>
              <Table.Cell
                style={{
                  backgroundColor: getStatusColor(item.pipeline_engagement)
                }}>
                {item.pipeline_engagement}
              </Table.Cell>
              <Table.Cell>{formatDate(item.forecasted_date)}</Table.Cell>
              <Table.Cell>{item.certainty}</Table.Cell>
            </>
          )}
          {canUpdate && (
            <PipelineItemForm
              item={item}
              fetchPipelineItems={this.props.fetchPipelineItems}
            />
          )}
        </Table.Row>
      </>
    );
  }
}

export default PipelineItemRow;
