import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const clientCreate = async data => {
  const url = `${BASE_URL}/crm/clients/create/`;

  return await requestSdk(() => post(url, data));
};

export const clientCreateCorporateChild = async (data, corporateMasterId) => {
  const url = `${BASE_URL}/crm/clients/${corporateMasterId}/create-corporate-child/`;

  return await requestSdk(() => post(url, data));
};
