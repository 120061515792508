import React from 'react';

import { Table, Segment, Loader } from 'semantic-ui-react';

import { formatDateTime } from 'utils';
import { TableFooter, SortableHeader, YesNo } from 'components';

const LoginAttemptTable = ({
  loginAttempts,
  sort,
  orderBy,
  pagination,
  isFetching
}) => (
  <>
    {!isFetching && (
      <Table celled selectable compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              singleLine
              style={{ fontWeight: '400' }}
              colspan="8">
              {pagination}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <SortableHeader sort={sort} orderBy={orderBy} field="attempt_time">
              Attempt time
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="username">
              Username
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="ip_address">
              IP Address
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="browser">
              Browser
            </SortableHeader>
            <SortableHeader
              sort={sort}
              orderBy={orderBy}
              field="operating_system">
              Operating System
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="device">
              Device
            </SortableHeader>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Logout</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {loginAttempts.map(item => (
            <Table.Row key={item.id}>
              <Table.Cell width={2}>
                {formatDateTime(item.created_at)}
              </Table.Cell>
              <Table.Cell>{item.username}</Table.Cell>
              <Table.Cell>{item.ip_address}</Table.Cell>
              <Table.Cell>{item.browser}</Table.Cell>
              <Table.Cell>{item.operating_system}</Table.Cell>
              <Table.Cell>{item.device}</Table.Cell>
              <Table.Cell>
                <YesNo value={item.successful} />
              </Table.Cell>
              <Table.Cell width={2}>
                {formatDateTime(item.logged_out_at)}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <TableFooter>{pagination}</TableFooter>
      </Table>
    )}
    {isFetching && (
      <Segment>
        <Loader />
      </Segment>
    )}
  </>
);

export default LoginAttemptTable;
