import React from 'react';

import _ from 'lodash';

import { Segment, Header, Button } from 'semantic-ui-react';

import { AgendaForm } from './components';

class Agendas extends React.Component {
  state = {
    courseAgendasToAdd: 0
  };

  addCourseAgenda = () => {
    this.setState(prevState => {
      return {
        courseAgendasToAdd: prevState.courseAgendasToAdd + 1
      };
    });
  };

  fetchCourse = () => {
    const { fetchCourse } = this.props;

    this.setState({ courseAgendasToAdd: 0 }, fetchCourse);
  };

  render() {
    const { course } = this.props;

    const { courseAgendasToAdd } = this.state;

    const { agendas } = course;

    return (
      <Segment>
        <Header as="h2">Event agendas</Header>
        {agendas.map(agenda => (
          <AgendaForm
            course={course}
            fetchCourse={this.fetchCourse}
            agenda={agenda}
            key={agenda.id}
          />
        ))}

        {_.range(courseAgendasToAdd).map(index => (
          <AgendaForm
            key={index}
            course={course}
            fetchCourse={this.fetchCourse}
            agenda={null}
          />
        ))}

        <Button
          style={{ marginTop: '10px' }}
          size="large"
          color="green"
          onClick={this.addCourseAgenda}>
          Add event agenda
        </Button>
      </Segment>
    );
  }
}

export default Agendas;
