import React from 'react';
import _ from 'lodash';

import { Field, FastField, SelectField } from 'components';
import { webArticleGroupList } from './sdk';

class WebArticleGroupSelect extends React.Component {
  state = {
    articleGroups: []
  };

  async componentDidMount() {
    const { productSlug } = this.props;
    const { data, success } = await webArticleGroupList(productSlug);

    if (success) {
      this.setState({ articleGroups: data });
    }
  }

  render() {
    const { name, fast = false, ...rest } = this.props;
    const { articleGroups } = this.state;

    const options = articleGroups.map(articleGroup => {
      return {
        key: articleGroup.id,
        value: articleGroup.id,
        label: articleGroup.name
      };
    });

    let Component = Field;
    if (fast) {
      Component = FastField;
    }

    if (!_.isEmpty(options)) {
      rest['options'] = options;
    }

    return (
      <Component
        name={name || 'articleGroup'}
        component={SelectField}
        isClearable
        {...rest}
      />
    );
  }
}

export default WebArticleGroupSelect;
