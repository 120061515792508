import React from 'react';

import { Dimmer, Header, Icon, Loader, Modal } from 'semantic-ui-react';
import { Field, Form, Formik } from 'formik';

import {
  ClientTagsSelectField,
  Flex,
  FormRow,
  UpdateButtonInRow
} from 'components';
import { notifyErrors, notifySuccess } from 'utils/notifications';

import { clientAssignTag } from './sdk';

class AssignTagModal extends React.Component {
  close = () => {
    this.props.onClose();
  };

  handleSubmit = async (values, { setSubmitting }) => {
    const confirm = window.confirm(
      `Are you sure you want to confirm tag assignment for selected clients?`
    );

    if (!confirm) {
      setSubmitting(false);
      return;
    }

    setSubmitting(true);

    const { errors, success } = await clientAssignTag({
      tags: values.tags,
      clients: this.props.selectedClients
    });

    if (success) {
      notifySuccess(
        'Selected clients will have their tag(s) assigned shortly.'
      );
      this.props.onSuccess();
      this.close();
    } else {
      notifyErrors(errors);
    }
    setSubmitting(false);
  };

  render() {
    return (
      <Modal open={true} size="tiny" centered={false}>
        <Modal.Header>
          <Flex spaceBetween>
            <Header
              data-testid="assign-tags-modal-title"
              as="h2"
              style={{ margin: 0 }}>
              Assign Tags
            </Header>
            <Icon
              data-testid="assign-tags-modal-close-btn"
              name="close"
              onClick={this.close}
            />
          </Flex>
        </Modal.Header>
        <Modal.Content>
          <Formik initialValues={{ tags: [] }} onSubmit={this.handleSubmit}>
            {({ handleSubmit, isSubmitting, values }) => (
              <>
                <Form>
                  <FormRow>
                    <Field
                      name="tags"
                      label="Tags"
                      component={ClientTagsSelectField}
                    />
                  </FormRow>

                  <UpdateButtonInRow
                    data-testid="assign-tags-modal-assign-btn"
                    isSubmitting={isSubmitting}
                    label="Assign"
                    handleSubmit={handleSubmit}
                    style={{ marginTop: '1rem' }}
                  />
                </Form>
                {isSubmitting && (
                  <Dimmer active inverted>
                    <Loader inverted>Submitting</Loader>
                  </Dimmer>
                )}
              </>
            )}
          </Formik>
        </Modal.Content>
      </Modal>
    );
  }
}

export default AssignTagModal;
