import React from 'react';

import { Field, SelectField } from 'components';
import { CLIENT_EVENT_STATUSES } from './constants';
class ClientEventStatus extends React.Component {
  render() {
    const { name, ...rest } = this.props;
    const CLIENT_EVENT_STATUSES_ARRAY = Object.values(CLIENT_EVENT_STATUSES);

    const options = CLIENT_EVENT_STATUSES_ARRAY.map(eventStatus => ({
      key: eventStatus,
      value: eventStatus,
      label: eventStatus
    }));

    return (
      <Field
        name={name || 'client_event_status'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default ClientEventStatus;
