import React from 'react';

import { useParams } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  FormRow,
  UnsavedFormPrompt,
  UniSelect,
  InputField,
  Field
} from 'components';
import { notifySuccess } from 'utils/notifications';
import { handleFormErrors, prepareFormData } from 'utils/forms';

import { recipientGroupSchema } from './schema';
import _ from 'lodash';
import { saveRecipientGroup } from './sdk';

function RecipientGroupForm({ instance, onSave, clearSelection, formRef }) {
  const { productSlug } = useParams();

  const handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;
    setSubmitting(true);
    const { errors, success } = await saveRecipientGroup(
      prepareFormData(values)
    );
    setSubmitting(false);
    if (success) {
      notifySuccess('Recipient group saved.');
      if (_.isFunction(onSave)) {
        onSave();
      }
      clearSelection();
      return;
    }
    handleFormErrors(errors, setFieldError);
  };

  return (
    <Formik
      initialValues={instance}
      validationSchema={recipientGroupSchema}
      enableReinitialize
      ref={formRef}
      onSubmit={handleSubmit}>
      {({ values, handleSubmit, isSubmitting, dirty }) => (
        <Form>
          <UnsavedFormPrompt when={dirty && !isSubmitting} />
          <FormRow>
            <UniSelect
              width={4}
              name="subscription"
              label="Subscription"
              source="subscriptionsForProduct"
              productSlug={productSlug}
              isClearable={false}
            />
            <UniSelect
              width={4}
              name="subscriptionStatus"
              label="Subscription Status"
              source="subscriptionStatusses"
              isClearable={false}
            />
            <UniSelect
              width={3}
              name="articleGrouping"
              label="Article grouping"
              source="articleGroupings"
              isClearable={false}
            />
            <Field
              width={5}
              name="newsletterSubject"
              component={InputField}
              label="Newsletter Subject"
            />
          </FormRow>
        </Form>
      )}
    </Formik>
  );
}

export default RecipientGroupForm;
