import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Table, Button, Icon } from 'semantic-ui-react';
import { Field, Flex, InputField } from 'components';
import { priceLatestValue, priceLatestIndex } from './sdk';
import { v4 as uuidv4 } from 'uuid';
import { notifyErrors } from 'utils/notifications';

const PriceIndexesTable = ({
  values,
  choices,
  additionalOptions,
  setAdditionalOptions,
  setTotalPercent,
  priceInitialValues,
  setFieldValue,
  currencies,
  units
}) => {
  const [priceValues, setPriceValues] = useState(null);
  const [latestIndex, setLatestIndex] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);

  const handleAddOption = () => {
    const newOption = {
      id: uuidv4(),
      manual_component_name: '',
      manual_component_value: 0,
      percent: 0
    };
    setAdditionalOptions(prevOptions => [...prevOptions, newOption]);
  };

  const fetchPriceValues = useCallback(async () => {
    const dataValues = {
      currency: values.currency,
      unit: values.unit,
      index_type: values.type,
      prices: values.prices.join(',')
    };

    const { data, errors, success } = await priceLatestValue(dataValues);

    if (success) {
      setPriceValues(data);
    } else {
      notifyErrors(errors);
    }
  }, [
    values.currency,
    values.unit,
    values.type,
    values.prices,
    setPriceValues
  ]);

  useEffect(() => {
    if (values.prices.length > 0) {
      fetchPriceValues();
    }
  }, [
    priceInitialValues,
    fetchPriceValues,
    values.currency,
    values.unit,
    values.type,
    values.prices
  ]);

  const percentValuesSetCountRef = useRef(0);
  const totalPercentRef = useRef(0);

  useEffect(() => {
    if (priceInitialValues && values.prices) {
      values.prices.forEach(selectedOptionId => {
        const priceData = priceInitialValues.find(
          item => item.id === selectedOptionId
        );
        if (priceData) {
          const fieldName = `percent_${selectedOptionId}`;
          totalPercentRef.current += priceData.percent;

          if (percentValuesSetCountRef.current < values.prices.length) {
            setFieldValue(fieldName, priceData.percent);
            percentValuesSetCountRef.current++;
          }
        }
      });

      if (percentValuesSetCountRef.current === values.prices.length) {
        setTotalPercent(totalPercentRef.current);
      }
    }
  }, [
    priceInitialValues,
    values.prices,
    setTotalPercent,
    setFieldValue,
    fetchPriceValues
  ]);

  const handleDeleteOption = id => {
    setAdditionalOptions(prevOptions =>
      prevOptions.filter(option => option.id !== id)
    );
  };

  const calculateTotalPercentageValue = (values, prices) => {
    let sum = 0;
    prices?.forEach(selectedOptionId => {
      const fieldName = `percent_${selectedOptionId}`;
      if (values[fieldName]) {
        sum += parseInt(values[fieldName], 10);
      }
    });
    if (additionalOptions) {
      additionalOptions.forEach(option => {
        const percentageValue = parseInt(option.percent, 10);
        if (!isNaN(percentageValue)) {
          sum += percentageValue;
        }
      });
    }
    return sum;
  };

  const fetchLatestIndex = useCallback(async () => {
    const dataValues = {
      currency: values.currency,
      unit: values.unit,
      index_type: values.type,
      prices: values.prices.map(id => ({
        price: id,
        percent: values[`percent_${id}`] || 0
      })),
      manual_components: additionalOptions
        .filter(
          option =>
            option.manual_component_name.trim() !== '' &&
            option.manual_component_value !== 0
        )
        .map(option => ({
          name: option.manual_component_name,
          value: option.manual_component_value,
          percent: option.percent
        }))
    };

    const { data, errors, success } = await priceLatestIndex(dataValues);

    if (success) {
      setLatestIndex(data);
    } else {
      notifyErrors(errors);
    }
  }, [additionalOptions, values]);

  useEffect(() => {
    const totalPercentage = calculateTotalPercentageValue(
      values,
      values.prices,
      additionalOptions
    );
    setTotalPercent(totalPercentage);
  });

  useEffect(() => {
    let totalPercentage = 0;
    values.prices?.forEach(selectedOptionId => {
      const fieldName = `percent_${selectedOptionId}`;
      if (values[fieldName]) {
        totalPercentage += parseInt(values[fieldName], 10);
      }
    });
    if (additionalOptions) {
      additionalOptions.forEach(option => {
        const percentageValue = parseInt(option.percent, 10);
        if (!isNaN(percentageValue)) {
          totalPercentage += percentageValue;
        }
      });
    }
    if (totalPercentage === 100) {
      fetchLatestIndex();
    }
  }, [
    values.currency,
    values.unit,
    values.type,
    values.prices,
    additionalOptions,
    fetchLatestIndex,
    values
  ]);

  const getTotalPercentageColor = totalPercentage => {
    if (Number.isNaN(totalPercentage) || totalPercentage < 100) {
      return '#fdfd96';
    } else if (totalPercentage === 100) {
      return '#77dd77';
    } else {
      return '#ff6666';
    }
  };

  useEffect(() => {
    const updatedSelectedCurrency = values.currency
      ? currencies.find(option => option.id === values.currency)
      : '';

    setSelectedCurrency(updatedSelectedCurrency);
  }, [values.currency, currencies]);

  useEffect(() => {
    const updatedSelectedUnit = values.unit
      ? units.find(option => option.id === values.unit)
      : '';

    setSelectedUnit(updatedSelectedUnit);
  }, [values.unit, units]);

  return (
    <Table celled style={{ margin: '0 10px' }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{ width: '50%' }}>Title</Table.HeaderCell>
          <Table.HeaderCell style={{ width: '15%' }}>
            Original Value
          </Table.HeaderCell>
          <Table.HeaderCell style={{ width: '15%' }}>
            Converted Value
          </Table.HeaderCell>
          <Table.HeaderCell style={{ width: '20%' }}>%</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {choices.length > 0 &&
          priceValues?.map(selectedOption => {
            return (
              <Table.Row key={selectedOption.price.id}>
                <Table.Cell>{selectedOption.price.title}</Table.Cell>
                <Table.Cell>
                  {selectedOption.original_value}{' '}
                  {selectedOption.price.currency}/{selectedOption.price.unit}
                </Table.Cell>
                <Table.Cell>
                  {selectedOption.latest_value} {selectedCurrency.code}/
                  {selectedUnit.title}
                </Table.Cell>
                <Table.Cell>
                  <Field
                    name={`percent_${selectedOption.price.id}`}
                    type="number"
                    onKeyPress={e => {
                      if (e.key === '.' || e.key === '-') {
                        e.preventDefault();
                      }
                    }}
                    defaultValue={0}
                    component={InputField}
                    style={{ width: '100px', height: '30px' }}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}

        {additionalOptions.map((option, index) => (
          <Table.Row key={option.id}>
            <Table.Cell width={7}>
              <Field
                component={InputField}
                value={option.manual_component_name}
                onChange={e => {
                  const newValue = e.target.value;
                  setAdditionalOptions(prevOptions =>
                    prevOptions.map((prevOption, i) =>
                      i === index
                        ? { ...prevOption, manual_component_name: newValue }
                        : prevOption
                    )
                  );
                }}
                style={{ height: '30px' }}
              />
            </Table.Cell>
            <Table.Cell colSpan={2} width={2}>
              <Field
                component={InputField}
                value={option.manual_component_value}
                type="number"
                onKeyPress={e => {
                  if (e.key === '.' || e.key === '-') {
                    e.preventDefault();
                  }
                }}
                onChange={e => {
                  const newValue = e.target.value;
                  setAdditionalOptions(prevOptions =>
                    prevOptions.map((prevOption, i) =>
                      i === index
                        ? { ...prevOption, manual_component_value: newValue }
                        : prevOption
                    )
                  );
                }}
                style={{ width: '100px', height: '30px' }}
              />
            </Table.Cell>
            <Table.Cell width={2}>
              <Flex>
                <Field
                  component={InputField}
                  value={option.percent}
                  type="number"
                  onKeyPress={e => {
                    if (e.key === '.' || e.key === '-') {
                      e.preventDefault();
                    }
                  }}
                  onChange={e => {
                    const newValue = e.target.value;
                    setAdditionalOptions(prevOptions =>
                      prevOptions.map((prevOption, i) =>
                        i === index
                          ? { ...prevOption, percent: newValue }
                          : prevOption
                      )
                    );
                  }}
                  style={{ width: '100px', height: '30px' }}
                />
                <Button
                  color="red"
                  icon
                  onClick={() => handleDeleteOption(option.id)}>
                  <Icon name="trash" />
                </Button>
              </Flex>
            </Table.Cell>
          </Table.Row>
        ))}
        <Table.Row>
          <Table.Cell colSpan={4} textAlign="center">
            <Button type="button" onClick={handleAddOption} color="green">
              Add Manual Element
            </Button>
          </Table.Cell>
        </Table.Row>
        {calculateTotalPercentageValue(values, values.prices) === 100 && (
          <Table.Row>
            <Table.Cell>Latest index value</Table.Cell>
            <Table.Cell colSpan={3}>
              {latestIndex && (
                <>
                  {latestIndex.latest_value} {selectedCurrency.code}/
                  {selectedUnit.title}
                </>
              )}
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>

      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell
            colSpan={3}
            style={{
              backgroundColor: 'transparent',
              fontWeight: '700'
            }}>
            Total percentage
          </Table.HeaderCell>
          <Table.HeaderCell
            style={{
              fontWeight: '700',
              backgroundColor: getTotalPercentageColor(
                calculateTotalPercentageValue(values, values.prices)
              )
            }}>
            {Number.isNaN(calculateTotalPercentageValue(values, values.prices))
              ? '0%'
              : `${calculateTotalPercentageValue(values, values.prices)}%`}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export default PriceIndexesTable;
