import * as yup from 'yup';

export const chinaSteelReportCreateSchema = yup.object().shape({
  title: yup.string().required('Title is required.'),
  issueNumber: yup.string().required('Issue number is required.'),
  lead: yup.string().required('Lead is required.'),
  text: yup.string().required('Text is required.'),
  issueDate: yup
    .string()
    .nullable()
    .required('Issue date is required.'),
  pdf: yup
    .string()
    .nullable()
    .required('PDF is required.'),
  cover: yup
    .string()
    .nullable()
    .required('Cover image is required.'),
  samplePdf: yup
    .string()
    .nullable()
    .required('Sample PDF is required.'),
  xls: yup
    .string()
    .nullable()
    .required('Xls is required.'),
  sampleXls: yup
    .string()
    .nullable()
    .required('Sample Xls is required.')
});
