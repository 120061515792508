import React, { useState, useCallback, useEffect } from 'react';

import _ from 'lodash';

import { Header, Segment } from 'semantic-ui-react';

import { withRouter, useParams } from 'react-router-dom';

import { Page, LoginRequired, Button, Flex } from 'components';
import { notifySuccess, notifyErrors } from 'utils/notifications';
import {
  PERMISSIONS,
  PermissionsRequired,
  DisableableElement,
  hasPermission
} from 'permissions';

import { staffDetail, memberSendResetPassword } from './sdk';

import { StaffUpdateForm } from './components';

const StaffDetail = ({ user, crumbs }) => {
  const { userId } = useParams();
  const [staffMember, setStaffMember] = useState(null);

  const fetchStaffMember = useCallback(async () => {
    const { data, errors, success } = await staffDetail(userId);

    if (success) {
      setStaffMember(data);
    } else {
      notifyErrors(errors);
    }
  }, [userId]);

  const sendResetPassword = useCallback(async () => {
    const { errors, success } = await memberSendResetPassword(userId);

    if (success) {
      notifySuccess('Reset password email sent');
    } else {
      notifyErrors(errors);
    }
  }, [userId]);

  useEffect(() => {
    fetchStaffMember();
  }, [fetchStaffMember]);

  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content crumbs={crumbs}>
          <DisableableElement
            disabled={!hasPermission(user, PERMISSIONS.CHANGE_STAFF)}>
            <Segment>
              <Flex spaceBetween>
                <Header as="h1">Staff Member Detail</Header>
                <Button color="red" onClick={sendResetPassword}>
                  Send reset password
                </Button>
              </Flex>
              {!_.isNil(staffMember) && (
                <StaffUpdateForm
                  data={staffMember}
                  fetchStaffMember={fetchStaffMember}
                />
              )}
            </Segment>
          </DisableableElement>
        </Page.Content>
      </Page.Body>
    </Page>
  );
};

export default LoginRequired(
  PermissionsRequired([PERMISSIONS.VIEW_STAFF])(withRouter(StaffDetail))
);
