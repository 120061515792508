import React from 'react';
import _ from 'lodash';

import { Field, FastField, SelectField } from 'components';

const PIPELINE_TYPES = ['Subscription', 'Event', 'Report', 'Advertisement'];

class PipelineTypeSelect extends React.Component {
  state = {
    options: PIPELINE_TYPES.map(section => {
      return {
        key: section,
        value: section,
        label: section
      };
    })
  };

  render() {
    const { name, fast = false, ...rest } = this.props;

    const { options } = this.state;

    let Component = Field;
    if (fast) {
      Component = FastField;
    }

    if (!_.isEmpty(options)) {
      rest['options'] = options;
    }

    return (
      <Component
        name={name || 'pipelineType'}
        component={SelectField}
        isClearable
        {...rest}
      />
    );
  }
}

export default PipelineTypeSelect;
