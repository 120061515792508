import _ from 'lodash';
import React from 'react';
import { Header } from 'semantic-ui-react';
import { withRouter, useParams } from 'react-router-dom';

import {
  Page,
  RecipientGroupsTable,
  Flex,
  Filters as SharedFilters,
  Pagination,
  ListViewEssentials,
  LoginRequired
} from 'components';

import { PermissionsRequired, PERMISSIONS } from 'permissions';

import { recipientGroupsList } from './sdk';
import { FILTERS as LAYOUT_FILTERS } from './utils';

function RecipientGroupsList({
  user,
  data,
  count,
  page,
  limit,
  filters,
  sort,
  filterBy,
  orderBy,
  clear,
  changePage,
  changeRowsPerPage,
  crumbs,
  refetch
}) {
  const { productSlug } = useParams();
  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content crumbs={crumbs}>
          <Flex spaceBetween>
            <Header>Power Materials Price Series</Header>
          </Flex>
          {data && (
            <>
              <SharedFilters
                filters={filters}
                count={count}
                filterBy={filterBy}
                clearFilters={clear}
                layout={LAYOUT_FILTERS(productSlug)}
              />
              <RecipientGroupsTable
                data={data}
                sort={sort}
                orderBy={orderBy}
                refetch={refetch}
                pagination={
                  <Pagination
                    limit={limit}
                    count={count}
                    page={page}
                    changePage={changePage}
                    changeRowsPerPage={changeRowsPerPage}
                  />
                }
              />
            </>
          )}
        </Page.Content>
      </Page.Body>
    </Page>
  );
}

export default ListViewEssentials({
  sdk: props => {
    const { productSlug } = props.match.params;
    return _.partial(recipientGroupsList, productSlug);
  },
  passPropsToSdk: true
})(
  LoginRequired(
    withRouter(
      PermissionsRequired([PERMISSIONS.VIEW_PRICES])(RecipientGroupsList)
    )
  )
);
