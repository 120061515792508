import React from 'react';

import { Table, Icon } from 'semantic-ui-react';

import styles from './styles.module.css';

const SortableHeader = ({ sort, orderBy, field, children, ...rest }) => (
  <Table.HeaderCell
    className={styles.HeaderCell}
    onClick={() => orderBy(field)}
    {...rest}>
    {children}
    {sort.field === field && sort.direction === 'desc' && (
      <Icon name="angle down" />
    )}
    {sort.field === field && sort.direction === 'asc' && (
      <Icon name="angle up" />
    )}
  </Table.HeaderCell>
);

export default SortableHeader;
