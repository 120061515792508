import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const mailshotGroupItemDelete = async (
  mailshotGroupId,
  mailshotGroupItemId
) => {
  const url = `${BASE_URL}/emails/mailshot-groups/${mailshotGroupId}/items/${mailshotGroupItemId}/delete/`;

  return await requestSdk(() => post(url));
};

export const mailshotGroupItemCreate = async (mailshotGroupId, data) => {
  const url = `${BASE_URL}/emails/mailshot-groups/${mailshotGroupId}/items/create/`;

  return await requestSdk(() => post(url, data));
};

export const mailshotGroupItemUpdate = async (
  mailshotGroupId,
  mailshotGroupItemId,
  data
) => {
  const url = `${BASE_URL}/emails/mailshot-groups/${mailshotGroupId}/items/${mailshotGroupItemId}/update/`;

  return await requestSdk(() => post(url, data));
};

export const mailshotGroupBulkUpdateMailshotStatus = async (
  mailshotGroupId,
  data
) => {
  const url = `${BASE_URL}/emails/mailshot-groups/${mailshotGroupId}/items/bulk-update-mailshot-status/`;

  return await requestSdk(() => post(url, data));
};
