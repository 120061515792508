import React from 'react';

import _ from 'lodash';

import { Formik } from 'formik';

import { Segment, Header, Form, Button } from 'semantic-ui-react';

import {
  SubscriptionSelect,
  ReportSelect,
  EventSelect,
  FormRow,
  AdvertisementSelect,
  UnsavedFormPrompt
} from 'components';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { invoiceCreateInvoiceItem } from './sdk';
import { getInvoiceItemsFor } from './utils';

class InvoiceAddForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { invoice, fetchInvoice } = this.props;

    const { setSubmitting, setFieldError, resetForm } = actions;

    setSubmitting(true);
    const { success, errors } = await invoiceCreateInvoiceItem(
      invoice.id,
      prepareFormData(values)
    );
    setSubmitting(false);

    if (success) {
      notifySuccess('Invoice item added.');
      resetForm();
      fetchInvoice();
    }

    if (!success) {
      handleFormErrors(errors, setFieldError);
    }
  };

  render() {
    const initialValues = {
      subscription: null,
      report: null,
      event: null
    };

    const {
      invoice: { invoice_items }
    } = this.props;

    return (
      <Segment>
        <Header as="h2">Add invoice item</Header>
        <Formik onSubmit={this.handleSubmit} initialValues={initialValues}>
          {({ values, handleSubmit, isSubmitting, dirty }) => {
            const noValueSelected = _.isEmpty(
              _.pickBy(values, _.negate(_.isNil))
            );

            return (
              <Form>
                <UnsavedFormPrompt
                  when={dirty}
                  formName="Add invoice item form"
                />
                <FormRow alignEnd>
                  <ReportSelect
                    width={4}
                    name="report"
                    label="Reports"
                    excludeOptions={getInvoiceItemsFor(invoice_items, 'Report')}
                  />

                  <EventSelect
                    width={4}
                    name="event"
                    label="Events"
                    excludeOptions={getInvoiceItemsFor(invoice_items, 'Course')}
                  />

                  <SubscriptionSelect
                    width={3}
                    name="subscription"
                    label="Subscription"
                    excludeOptions={getInvoiceItemsFor(
                      invoice_items,
                      'Subscription'
                    )}
                  />

                  {/* TODO: Disabled for deployment */}
                  <AdvertisementSelect
                    width={3}
                    name="advertisement"
                    label="Advertisement"
                    excludeOptions={getInvoiceItemsFor(
                      invoice_items,
                      'Advertisement'
                    )}
                  />

                  <Form.Field width={1}>
                    <Button
                      fluid
                      onClick={handleSubmit}
                      type="submit"
                      color="green"
                      disabled={isSubmitting || noValueSelected}>
                      Add
                    </Button>
                  </Form.Field>
                </FormRow>
              </Form>
            );
          }}
        </Formik>
      </Segment>
    );
  }
}

export default InvoiceAddForm;
