import React from 'react';
import { Formik } from 'formik';
import { Form } from 'semantic-ui-react';
import { save } from './sdk';
import { SendingInstanceSchema } from './schema';
import {
  prepareFormData,
  prepareInitialValues,
  handleFormErrors
} from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import {
  Field,
  FormRow,
  FileField,
  InputField,
  DateTimeField,
  TextAreaField,
  RichTextAreaField,
  YesNoSelect,
  UnsavedFormPrompt,
  ErrorsAndCreateButtonInRow,
  UniSelect
} from 'components';

function SendingInstanceForm({ instance, onSave }) {
  const handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;
    setSubmitting(true);
    const { errors, success } = await save(prepareFormData(values));
    setSubmitting(false);
    if (success) {
      notifySuccess('Instance saved.');
    }
    handleFormErrors(errors, setFieldError);
    onSave();
  };

  return (
    <Formik
      initialValues={prepareInitialValues(instance)}
      validationSchema={SendingInstanceSchema}
      enableReinitialize
      onSubmit={handleSubmit}>
      {({ values, handleSubmit, isSubmitting, dirty, touched, errors }) => (
        <Form>
          <UnsavedFormPrompt when={dirty && isSubmitting} />
          <ErrorsAndCreateButtonInRow
            touched={touched}
            errors={errors}
            onClick={handleSubmit}>
            Save sending instance
          </ErrorsAndCreateButtonInRow>
          <FormRow>
            <Field
              required
              width={4}
              name="datetime"
              component={DateTimeField}
              label="Date to go Out"
            />
            <Field
              width={3}
              name="editorApproved"
              component={YesNoSelect}
              label="Editor Approved"
            />
            <Field
              width={3}
              name="marketingApproved"
              component={YesNoSelect}
              label="Marketer Approved"
            />
            <UniSelect
              source="weeklySeasmTags"
              name="tag"
              width={4}
              label="Tag"
            />
            <Field
              width={2}
              name="disableSamples"
              component={YesNoSelect}
              label="Disable samples"
            />
          </FormRow>
          <FormRow>
            <Field required name="pdf" component={FileField} label="PDF" />
            <Field
              required
              name="samplePdf"
              component={FileField}
              label="Sample PDF"
            />
          </FormRow>
          <Field
            width={6}
            name="trialSubject"
            component={InputField}
            label="Trials Subject"
          />
          <Field
            name="trialHtml"
            component={RichTextAreaField}
            label="Trials HTML"
            config={{
              height: '400px'
            }}
          />
          <Field
            name="trialText"
            component={TextAreaField}
            label="Trials Text"
          />
          <Field
            width={6}
            name="subscriberSubject"
            component={InputField}
            label="Subscribers Subject"
          />
          <Field
            name="subscriberHtml"
            component={RichTextAreaField}
            label="Subscribers HTML"
            config={{
              height: '400px'
            }}
          />
          <Field
            name="subscriberText"
            component={TextAreaField}
            label="Subscribers Text"
          />
          <Field
            width={6}
            name="chasingSubject"
            component={InputField}
            label="Chasing Subject"
          />
          <Field
            name="chasingHtml"
            component={RichTextAreaField}
            label="Chasing HTML"
            config={{
              height: '400px'
            }}
          />
          <Field
            name="chasingText"
            component={TextAreaField}
            label="Chasing Text"
          />
          <ErrorsAndCreateButtonInRow
            touched={touched}
            errors={errors}
            onClick={handleSubmit}>
            Save sending instance
          </ErrorsAndCreateButtonInRow>
        </Form>
      )}
    </Formik>
  );
}

export default SendingInstanceForm;
