import React from 'react';

import { Link } from 'react-router-dom';
import { Table, List, Segment } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { ClientDetail } from 'pages';

import { SUBSCRIPTION } from 'domainConstants';

import { formatDate } from 'utils';

import { TableFooter, Loader } from 'components';

const filterByItemType = (clientItems, type) => {
  return clientItems.filter(clientItem => clientItem.item.item_type === type);
};

const WeeklySteelOpenCountTable = ({
  items,
  sort,
  orderBy,
  pagination,
  isFetching
}) => (
  <>
    {!isFetching && (
      <Table celled selectable compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              singleLine
              style={{ fontWeight: '400' }}
              colspan="9">
              {pagination}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Client ID</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>First Name</Table.HeaderCell>
            <Table.HeaderCell>Last Name</Table.HeaderCell>
            <Table.HeaderCell>Company</Table.HeaderCell>
            <Table.HeaderCell>Region</Table.HeaderCell>
            <Table.HeaderCell>Subscription Status</Table.HeaderCell>
            <Table.HeaderCell>Subscription expiry date</Table.HeaderCell>
            <Table.HeaderCell>Open Count</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {items.map(client => (
            <Table.Row key={client.id}>
              <Table.Cell width={1}>
                <Link to={pageUrl(ClientDetail, { clientId: client.id })}>
                  {client.id}
                </Link>
              </Table.Cell>
              <Table.Cell width={2}>{client.email}</Table.Cell>
              <Table.Cell width={1}>{client.first_name}</Table.Cell>
              <Table.Cell width={1}>{client.last_name}</Table.Cell>
              <Table.Cell width={2}>{client.profile.company}</Table.Cell>
              <Table.Cell width={2}>{client.profile.region}</Table.Cell>
              <Table.Cell width={3}>
                <List bulleted>
                  {filterByItemType(client.client_items, SUBSCRIPTION).map(
                    (clientItem, index) => {
                      return (
                        <List.Item key={index}>
                          {clientItem.subscription_status.status} /{' '}
                          {clientItem.item.title}
                        </List.Item>
                      );
                    }
                  )}
                </List>
              </Table.Cell>
              <Table.Cell width={3}>
                <List bulleted>
                  {filterByItemType(client.client_items, SUBSCRIPTION).map(
                    (clientItem, index) => {
                      return (
                        <List.Item key={index}>
                          {clientItem.subscription_status.date_end &&
                            `${formatDate(
                              clientItem.subscription_status.date_end
                            )} /`}
                          {clientItem.item.title}
                        </List.Item>
                      );
                    }
                  )}
                </List>
              </Table.Cell>
              <Table.Cell width={1}>{client.open_count}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <TableFooter>{pagination}</TableFooter>
      </Table>
    )}
    {isFetching && (
      <Segment>
        <Loader />
      </Segment>
    )}
  </>
);

export default WeeklySteelOpenCountTable;
