import _ from 'lodash';
import moment from 'moment';
import { formatDateTime, formatDate } from 'utils';

export const RENEWALS_STATE_COLORS = {
  Pending: 'grey',
  'In touch': 'black',
  Booked: '#0080009c',
  Paid: '#027402',
  Cancelled: 'red',
  PNR: 'red'
};

export const getRenewalClientData = client => ({
  id: client.id,
  clientId: client.client,
  firstName: client.first_name,
  lastName: client.last_name,
  companyName: client.company_name,
  country: _.get(client, 'country.title', ''),
  accountManager: !_.isNil(client.account_manager)
    ? `${client.account_manager.first_name} ${client.account_manager.last_name}`
    : '',
  subscription: _.get(client, 'subscription.title', ''),
  subscriptionStatus: _.get(client, 'subscription_status.title', ''),
  renewalDate: moment(client.renewal_date).format('MMMM YYYY'),
  lastUpdateAt: `${client.last_updated_by} ${formatDateTime(
    client.last_updated_at
  )}`,
  subscriptionExpiryDate: formatDate(client.subscription_expiry_date),
  pastInvoicePaidDate: formatDate(client.past_invoice_paid_date),
  invoiceValue: `${client.past_invoice_value} ${_.get(
    client,
    'past_invoice_currency.code',
    ''
  )}`,
  pastInvoiceId: _.get(client, 'past_invoice.id'),
  pastInvoiceConvertedValue: client.past_invoice_converted_value.toFixed(2),
  renewalState: client.renewal_state,
  futureInvoiceValue: !_.isNil(client.future_invoice)
    ? client.future_invoice_value.toFixed(2)
    : '',
  futureInvoiceId: _.get(client, 'future_invoice.id'),
  futureInvoiceConvertedValue: client.future_invoice_converted_value
    ? client.future_invoice_converted_value.toFixed(2)
    : '',
  upsell: !_.isNil(client.upsell_amount) ? client.upsell_amount.toFixed(2) : '',
  upsellPercentage: !_.isNil(client.upsell_percentage)
    ? client.upsell_percentage.toFixed(2) + ' %'
    : ''
});
