import React from 'react';
import _ from 'lodash';

import { FastField as Field, TransferListField } from 'components';
import { countryList } from './sdk';

class CountrySelect extends React.Component {
  state = {
    countries: []
  };
  async componentDidMount() {
    const { data, success } = await countryList();

    if (success) {
      let countries = data.map(country => {
        return {
          key: country.id,
          value: country.id,
          label: country.title,
          meta: {
            region: _.get(country, 'region.title', null)
          }
        };
      });
      countries = _.orderBy(countries, 'label');

      this.setState({
        countries: countries
      });
    }
  }

  render() {
    const { name, isCustom, ...rest } = this.props;
    const { countries } = this.state;

    const options = countries;

    if (!_.isEmpty(options)) {
      rest['options'] = options;
    }

    return (
      <Field name={name || 'country'} component={TransferListField} {...rest} />
    );
  }
}

export default CountrySelect;
