import React from 'react';

import { Formik } from 'formik';

import { withRouter } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import {
  Field,
  FormRow,
  DateField,
  InputField,
  FileField,
  RichTextAreaField,
  CheckboxField,
  TagSelect,
  MarketDataReportRelatedProductSelect,
  CreateButtonInRow,
  UnsavedFormPrompt
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { populateSlug } from 'utils';

import { pageUrl } from 'config/routes';
import { MarketDataReportList } from 'pages';
import {
  prepareCurrencies,
  buildCurrencyKey
} from 'pages/MarketDataReport/utils';
import { notifySuccess } from 'utils/notifications';

import { DEFAULT_INITIAL_VALUES, marketDataReportCreateSchema } from './utils';

import { currencyList, marketDataReportCreate } from './sdk';

class MarketDataReportCreateForm extends React.Component {
  state = {
    currencies: []
  };

  async componentDidMount() {
    const { success, data } = await currencyList();

    if (success) {
      this.setState({ currencies: data });
    }
  }

  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const prices = prepareCurrencies(values);

    const data = prepareFormData(values);
    data.prices = prices;

    setSubmitting(true);

    const { errors, success } = await marketDataReportCreate(data);

    if (success) {
      this.props.history.push(pageUrl(MarketDataReportList));
      notifySuccess('Market data report created.');
      return;
    }

    handleFormErrors(errors, setFieldError);

    setSubmitting(false);
  };

  render() {
    const initialValues = DEFAULT_INITIAL_VALUES;

    const { currencies } = this.state;

    currencies.forEach(currency => {
      initialValues[buildCurrencyKey(currency)] = 0;
    });

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={marketDataReportCreateSchema}
        onSubmit={this.handleSubmit}>
        {({ handleSubmit, isSubmitting, setFieldValue, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty && !isSubmitting} />
            <FormRow widths="equal">
              <Field
                required
                name="title"
                component={InputField}
                label="Title"
                onChange={(e, data) => {
                  populateSlug(data, setFieldValue);
                }}
              />

              <Field required name="slug" component={InputField} label="Slug" />

              <Field
                required
                name="dateActivation"
                component={DateField}
                label="Date Of Activation"
              />
              <Field
                name="isSteelReport"
                component={CheckboxField}
                label="Is Steel Report"
              />
              <Field
                name="isCustomReport"
                component={CheckboxField}
                label="Is Custom Report"
              />
            </FormRow>

            <FormRow widths="equal">
              <Field name="image" component={FileField} label="Image" />
              <Field name="thumb" component={FileField} label="Thumb" />
              <Field name="file" component={FileField} label="File" />
              <Field name="sample" component={FileField} label="Sample" />
            </FormRow>

            <FormRow>
              {currencies.map(currency => (
                <Field
                  key={currency.id}
                  name={buildCurrencyKey(currency)}
                  label={`Price in ${currency.code}`}
                  component={InputField}
                />
              ))}
            </FormRow>

            <Field
              width={16}
              name="relatedProducts"
              label="Related Products"
              component={MarketDataReportRelatedProductSelect}
              isMulti
            />
            <Field width={16} name="tags" label="Tags" component={TagSelect} />

            <FormRow>
              <Field
                name="textHtml"
                component={RichTextAreaField}
                label="Text"
              />
            </FormRow>
            <CreateButtonInRow onClick={handleSubmit} disabled={isSubmitting} />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(MarketDataReportCreateForm);
