import React from 'react';

import { Dropdown } from 'semantic-ui-react';
import { logout } from 'sdk';

import { getLoggedUserInitials } from './utils';

import styles from './styles.module.scss';

class Profile extends React.Component {
  render() {
    const { user } = this.props;

    return (
      <Dropdown
        trigger={
          <div className={styles.userName}>{getLoggedUserInitials(user)}</div>
        }>
        <Dropdown.Menu>
          <div className={styles.dropdownMenu}>
            <Dropdown.Item text={user.name} />
            <Dropdown.Item
              icon="log out"
              text="Logout"
              as="a"
              onClick={logout}
            />
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default Profile;
