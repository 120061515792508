import React from 'react';

import _ from 'lodash';

import { Segment, Tab } from 'semantic-ui-react';

import { prepareFormData } from 'utils/forms';
import { notifySuccess, notifyErrors } from 'utils/notifications';
import { SortableList } from 'components';

import { Prices } from './components';

import { dailyNewsletterOrderArticle } from './sdk';

class ArticleSectionContainer extends React.Component {
  state = {
    articles: []
  };

  onSortEnd = async ({ item, oldIndex, newIndex }) => {
    const article = item;
    const { dailyNewsletter, section, fetchDailyNewsletter } = this.props;
    const articleId = article.id;

    const data = {
      article: articleId,
      oldIndex,
      newIndex
    };

    const { success, errors } = await dailyNewsletterOrderArticle(
      dailyNewsletter.id,
      section,
      prepareFormData(data)
    );

    if (success) {
      fetchDailyNewsletter();
      notifySuccess('Articles reordered.');
    } else {
      notifyErrors(errors);
    }
  };

  render() {
    const { articles, dailyNewsletter } = this.props;

    const items = articles.map(article => {
      return {
        title: article.full_headline,
        ...article
      };
    });

    return (
      <SortableList
        disabled={dailyNewsletter.live}
        items={items}
        onSortEnd={this.onSortEnd}
      />
    );
  }
}

const TabSection = ({
  sectionName,
  articles,
  dailyNewsletter,
  section,
  fetchDailyNewsletter
}) => {
  return (
    <Segment>
      <p>
        <strong>{sectionName}</strong>
      </p>
      <ArticleSectionContainer
        articles={articles}
        dailyNewsletter={dailyNewsletter}
        section={section}
        fetchDailyNewsletter={fetchDailyNewsletter}
      />
    </Segment>
  );
};

class Sections extends React.Component {
  render() {
    const {
      dailyNewsletter,
      dailyNewsletterPrices,
      fetchDailyNewsletterPrices,
      fetchDailyNewsletter
    } = this.props;

    const regionSections = _.filter(
      dailyNewsletter.sections,
      section => !_.isEmpty(section.articles)
    );

    const productTypeSections = _.filter(
      dailyNewsletter.group_sections,
      section => !_.isEmpty(section.articles)
    );

    const panes = [
      {
        menuItem: 'Regions',
        render: () => (
          <Tab.Pane>
            {regionSections.map(section => (
              <TabSection
                key={section.section}
                sectionName={section.section}
                articles={_.orderBy(section.articles, ['order'], ['asc'])}
                dailyNewsletter={dailyNewsletter}
                fetchDailyNewsletter={fetchDailyNewsletter}
                section={section.human_readable_name}
              />
            ))}
          </Tab.Pane>
        )
      },
      {
        menuItem: 'Product Type Groups',
        render: () => (
          <Tab.Pane>
            {productTypeSections.map(section => (
              <TabSection
                key={section.group_section}
                sectionName={section.group_section}
                articles={_.orderBy(section.articles, ['order'], ['asc'])}
                dailyNewsletter={dailyNewsletter}
                fetchDailyNewsletter={fetchDailyNewsletter}
                section={section.human_readable_name}
              />
            ))}
          </Tab.Pane>
        )
      }
    ];

    const topHeadlines = _.orderBy(
      dailyNewsletter.top_headlines,
      ['order'],
      ['asc']
    );

    const featured = _.orderBy(dailyNewsletter.featured, ['order'], ['asc']);

    return (
      <div>
        <Segment>
          <p>
            <strong>Top headlines</strong>
          </p>
          <ArticleSectionContainer
            articles={topHeadlines}
            dailyNewsletter={dailyNewsletter}
            section="top-headlines"
            fetchDailyNewsletter={fetchDailyNewsletter}
          />
        </Segment>
        <Segment>
          <p>
            <strong>Featured</strong>
          </p>
          <ArticleSectionContainer
            articles={featured}
            dailyNewsletter={dailyNewsletter}
            section="featured"
            fetchDailyNewsletter={fetchDailyNewsletter}
          />
        </Segment>
        <Tab panes={panes} />
        <Prices
          dailyNewsletter={dailyNewsletter}
          dailyNewsletterPrices={dailyNewsletterPrices}
          fetchDailyNewsletterPrices={fetchDailyNewsletterPrices}
        />
      </div>
    );
  }
}

export default Sections;
