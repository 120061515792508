import React from 'react';

import { pageUrl } from 'config/routes';

import { Dashboard } from 'pages';

class RedirectToDashboard extends React.Component {
  componentDidMount() {
    return this.props.history.push(pageUrl(Dashboard));
  }

  render() {
    return null;
  }
}

export default RedirectToDashboard;
