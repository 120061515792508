import { get, post, requestSdk } from 'sdk';

import { BASE_URL } from 'sdk/urls';

export const clientSnapshotPriceSeriesList = async (clientId, params) => {
  const url = `${BASE_URL}/crm/clients/${clientId}/snapshot/price-series/`;

  return await requestSdk(() => get(url, params));
};

export const clientSnapshotTogglePriceSerie = async (clientId, priceId) => {
  const url = `${BASE_URL}/crm/clients/${clientId}/snapshot/price-series/toggle/`;

  return await requestSdk(() => post(url, { price_serie: priceId }));
};

export const clientSnapshotToggleEmailNotificationsForPriceSeries = async ({
  clientId,
  priceSerieId,
  enabled
}) => {
  const url = `${BASE_URL}/crm/clients/${clientId}/snapshot/price-series/${priceSerieId}/toggle-email-notifications/`;

  return await requestSdk(() =>
    post(url, { email_notifications_enabled: enabled })
  );
};

export const clientSnapshotPriceSpreadsList = async (clientId, params) => {
  const url = `${BASE_URL}/crm/clients/${clientId}/snapshot/price-spreads/`;

  return await requestSdk(() => get(url, params));
};

export const clientSnapshotTogglePriceSpread = async (
  clientId,
  priceSpreadId
) => {
  const url = `${BASE_URL}/crm/clients/${clientId}/snapshot/price-spreads/toggle/`;

  return await requestSdk(() => post(url, { price_spread: priceSpreadId }));
};

export const clientSnapshotToggleEmailNotificationsForPriceSpread = async ({
  clientId,
  priceSpreadId,
  enabled
}) => {
  const url = `${BASE_URL}/crm/clients/${clientId}/snapshot/price-spreads/${priceSpreadId}/toggle-email-notifications/`;

  return await requestSdk(() =>
    post(url, { email_notifications_enabled: enabled })
  );
};
