import _ from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Header, Segment } from 'semantic-ui-react';
import { notifyErrors } from 'utils/notifications';
import { prepareInitialValues } from 'utils/forms';
import { Page } from 'components';

import { PERMISSIONS } from 'permissions';
import { withLoggedUser, withPermissions } from 'hooks';
import ContentSectionForm from './form';
import SectionData from './SectionData';
import { contentSectionDetail } from './sdk';

function ContentSection({ user, crumbs }) {
  const { newsletterId, sectionId } = useParams();

  const [section, setSection] = useState({
    id: null,
    title: '',
    displayName: '',
    newsletter: newsletterId,
    column: '',
    sectionType: '',
    templateName: '',
    enabled: false,
    websiteDisplay: false,
    articleSection: null,
    articleGroup: null,
    visibleFor: []
  });

  const getContentSection = useCallback(async () => {
    const { errors, success, data } = await contentSectionDetail(sectionId);
    if (success) {
      setSection(prepareInitialValues(data));
    } else {
      notifyErrors(errors);
    }
  }, [sectionId]);

  useEffect(() => {
    if (sectionId) {
      getContentSection();
    }
  }, [getContentSection, sectionId]);

  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content crumbs={crumbs}>
          <Segment>
            <Header as="h1">Content section</Header>
            <ContentSectionForm section={section} />
          </Segment>
          {!_.isNil(sectionId) && <SectionData sectionId={sectionId} />}
        </Page.Content>
      </Page.Body>
    </Page>
  );
}

export default withLoggedUser(
  withPermissions([PERMISSIONS.CREATE_NEWSLETTERS])(ContentSection)
);
