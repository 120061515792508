import * as yup from 'yup';
import 'utils/yup';

export const MailshotValidationSchema = yup.object().shape({
  scheduledDate: yup
    .string()
    .nullable()
    .required('Scheduled date is required.'),
  fromName: yup.string().required('Sender name is required.'),
  fromEmail: yup
    .string()
    .strongEmail()
    .required('Sender email is required.'),
  title: yup.string().required('Mailshot title is required.'),
  subject: yup.string().required('Subject is required.')
});

export const INITIAL_VALUES = {
  scheduledDate: null,
  fromName: '',
  fromEmail: '',
  returnPath: '',
  title: '',
  subject: ''
};
export const MAILSHOT_EMAIL_OPTIONS = [
  {
    key: 'news',
    value: 'news@kallanish.com',
    label: 'news@kallanish.com'
  },
  {
    key: 'marketing',
    value: 'marketing@kallanish.com',
    label: 'marketing@kallanish.com'
  }
];
