import _ from 'lodash';
import React from 'react';

import { Tab } from 'semantic-ui-react';

import ArticlesSection from './ArticlesSection';
import PricesSection from './PricesSection';

import { newsletterOrderSection } from './sdk';

import { getArticles } from '../ArticleTable/utils';

import { POWER_MATERIAL_SLUG, STEEL_SLUG } from 'domainConstants';

class ArticlesSections extends React.Component {
  render() {
    const {
      newsletter,
      newsletterPrices,
      fetchNewsletterPrices,
      fetchNewsletterContentSections,
      newsletterContentSections,
      productSlug
    } = this.props;

    const newsletterProps = {
      newsletter,
      fetchNewsletterContentSections,
      newsletterOrderSection
    };

    const regionSections = _.filter(
      newsletterContentSections,
      section =>
        section.internal_slug === 'region-articles' &&
        !_.isEmpty(_.get(section, 'section_data.articles', []))
    );

    const productTypeSections = _.filter(
      newsletterContentSections,
      section =>
        section.internal_slug === 'product-type-group-articles' &&
        !_.isEmpty(_.get(section, 'section_data.articles', []))
    );

    const panes = [
      {
        menuItem: 'Regions',
        render: () => (
          <Tab.Pane>
            {regionSections.map(section => (
              <ArticlesSection
                key={section}
                sectionName={section.display_name}
                articles={_.get(section, 'section_data.articles', [])}
                section="region-articles"
                articleSection={section.article_section}
                {...newsletterProps}
              />
            ))}
          </Tab.Pane>
        )
      },
      {
        menuItem: 'Product Type Groups',
        render: () => (
          <Tab.Pane>
            {productTypeSections.map(section => (
              <ArticlesSection
                key={section}
                sectionName={section.display_name}
                articles={_.get(section, 'section_data.articles', [])}
                section="product-type-group-articles"
                articleGroup={section.article_group}
                {...newsletterProps}
              />
            ))}
          </Tab.Pane>
        )
      }
    ];
    return (
      <div>
        {productSlug === POWER_MATERIAL_SLUG && (
          <ArticlesSection
            sectionName="Selected Articles"
            section="selected-articles"
            articles={getArticles(
              newsletterContentSections,
              'selected-articles'
            )}
            color="green"
            {...newsletterProps}
          />
        )}
        {productSlug === STEEL_SLUG && (
          <>
            <ArticlesSection
              sectionName="Top Headlines"
              section="top-headlines"
              articles={getArticles(newsletterContentSections, 'top-headlines')}
              color="green"
              {...newsletterProps}
            />
            <ArticlesSection
              sectionName="Featured"
              section="featured"
              articles={getArticles(
                newsletterContentSections,
                'featured-articles'
              )}
              color="red"
              {...newsletterProps}
            />

            <Tab panes={panes} />

            <PricesSection
              newsletter={newsletter}
              newsletterPrices={newsletterPrices}
              fetchNewsletterPrices={fetchNewsletterPrices}
              newsletterOrderSection={newsletterOrderSection}
            />
          </>
        )}
      </div>
    );
  }
}

export default ArticlesSections;
