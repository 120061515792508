import React from 'react';

import _ from 'lodash';

import { Page, Header, LoginRequired } from 'components';

import { stringifyParams } from 'utils';

import { PermissionsRequired, PERMISSIONS } from 'permissions';

import {
  getInitialFilters,
  getFilters,
  getFiltersFromUrl,
  buildFilters,
  FILTERS_KEY
} from 'utils/filters';
import { notifyErrors } from 'utils/notifications';

import { priceSeriesForUpdateList, pmPriceSeriesForUpdateList } from './sdk';

import { PriceSeriesFrequencyTable, Filters } from './components';

import { DAILY, WEEKLY, MONTHLY, QUARTERLY, STEPS } from './utils';

const FREQUENCIES = [DAILY, WEEKLY, MONTHLY, QUARTERLY];

class BasePriceSeriesUpdate extends React.Component {
  state = {
    [DAILY]: null,
    [WEEKLY]: null,
    [MONTHLY]: null,
    [QUARTERLY]: null,
    ...getInitialFilters()
  };

  resetData = () => {
    this.setState({
      [DAILY]: null,
      [WEEKLY]: null,
      [QUARTERLY]: null
    });
  };

  updateFilters = (filters, callback = () => {}) => {
    this.setState(prevState => {
      return {
        ...prevState,
        [FILTERS_KEY]: filters
      };
    }, callback);
  };

  changeUrl = (additionalParam = {}) => {
    // Get only needed params
    const filters = getFilters(this.state);

    const params = { ...filters };

    const p = value => _.isNil(value) || value === '';

    const paramsWithValue = _.pickBy(params, _.negate(p));

    this.props.history.replace({
      search: stringifyParams(paramsWithValue)
    });
  };

  filterBy = filters => {
    // Always open first page when new filter is applied
    this.updateFilters(filters, () => {
      this.resetData();
      this.changeUrl();
      this.callFetch();
    });
  };

  clear = () => {
    this.updateFilters(getInitialFilters()[FILTERS_KEY], () => {
      this.resetData();
      this.changeUrl();
      this.callFetch();
    });
  };

  componentDidMount() {
    this.initialize();
  }

  callFetch = () => {
    const filters = getFilters(this.state);
    const filterParams = buildFilters(filters);
    const params = {
      ...filterParams
    };

    if (params.frequency) {
      const frequencies = FREQUENCIES.filter(
        frequency => params.frequency.toLowerCase() !== frequency
      );

      const stateChange = {};

      frequencies.forEach(frequency => {
        stateChange[frequency] = null;
      });

      this.setState(stateChange, () => {
        this.fetchForFrequency(params.frequency.toLowerCase(), params);
      });
    } else {
      FREQUENCIES.forEach(frequency => {
        this.fetchForFrequency(frequency, params);
      });
    }
  };

  initialize = () => {
    const url = this.props.history.location.search;

    const filters = getFiltersFromUrl(url);

    this.updateFilters(filters, this.callFetch);
  };

  fetchForFrequency = async (frequency, params) => {
    const { success, data, errors } = await this.props.sdk(
      frequency,
      params || buildFilters(getFilters(this.state)) // When we are called after changing price value
    );

    if (success) {
      this.setState({ [frequency]: data });
    } else {
      notifyErrors(errors);
    }
  };

  render() {
    const { user, crumbs } = this.props;

    const daily = this.state[DAILY];
    const weekly = this.state[WEEKLY];
    const monthly = this.state[MONTHLY];
    const quarterly = this.state[QUARTERLY];

    const filters = getFilters(this.state);
    const filterBy = this.filterBy;
    const clearFilters = this.clear;

    const frequencyFilter = (filters.frequency || '').toLowerCase();

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content maxWidth crumbs={crumbs}>
            <Header>Price Series Update</Header>
            <Filters
              filters={filters}
              filterBy={filterBy}
              clearFilters={clearFilters}
              isPowerMaterial={this.props.isPowerMaterial}
            />

            <PriceSeriesFrequencyTable
              user={user}
              frequency={DAILY}
              frequencyFilter={frequencyFilter}
              priceSeries={daily}
              fetchForFrequency={() => this.fetchForFrequency(DAILY)}
              filters={filters}
              filterBy={filterBy}
              {...STEPS[DAILY]}
            />

            <PriceSeriesFrequencyTable
              user={user}
              frequency={WEEKLY}
              frequencyFilter={frequencyFilter}
              priceSeries={weekly}
              fetchForFrequency={() => this.fetchForFrequency(WEEKLY)}
              filters={filters}
              filterBy={filterBy}
              {...STEPS[WEEKLY]}
            />

            <PriceSeriesFrequencyTable
              user={user}
              frequency={MONTHLY}
              frequencyFilter={frequencyFilter}
              priceSeries={monthly}
              fetchForFrequency={() => this.fetchForFrequency(MONTHLY)}
              filters={filters}
              filterBy={filterBy}
              {...STEPS[MONTHLY]}
            />

            <PriceSeriesFrequencyTable
              user={user}
              frequency={QUARTERLY}
              frequencyFilter={frequencyFilter}
              priceSeries={quarterly}
              fetchForFrequency={() => this.fetchForFrequency(QUARTERLY)}
              filters={filters}
              filterBy={filterBy}
              {...STEPS[QUARTERLY]}
            />
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

BasePriceSeriesUpdate = LoginRequired(
  PermissionsRequired([PERMISSIONS.VIEW_PRICES])(BasePriceSeriesUpdate)
);

export const PriceSeriesUpdate = props => (
  <BasePriceSeriesUpdate sdk={priceSeriesForUpdateList} {...props} />
);

export const PMPriceSeriesUpdate = props => (
  <BasePriceSeriesUpdate
    sdk={pmPriceSeriesForUpdateList}
    isPowerMaterial={true}
    {...props}
  />
);
