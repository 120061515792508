import React from 'react';

import { Table } from 'semantic-ui-react';

import { Link, useParams } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { NewsletterDetail } from 'pages';

import { formatDateTime } from 'utils';

import { YesNo, TableFooter } from 'components';

function NewsletterTable({ dailyNewsletters, pagination, sort, orderBy }) {
  const { productSlug } = useParams();

  return (
    <Table celled selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            singleLine
            style={{ fontWeight: '400' }}
            colspan="11">
            {pagination}
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>Issue</Table.HeaderCell>
          <Table.HeaderCell>Date to go out</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Sending Status</Table.HeaderCell>
          <Table.HeaderCell>Editor Approved</Table.HeaderCell>
          <Table.HeaderCell>Editor</Table.HeaderCell>
          <Table.HeaderCell>Marketing Approved</Table.HeaderCell>
          <Table.HeaderCell>Marketer</Table.HeaderCell>
          <Table.HeaderCell>Copies sent</Table.HeaderCell>
          <Table.HeaderCell>Articles</Table.HeaderCell>
          <Table.HeaderCell>Prices</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {dailyNewsletters.map(newsletter => (
          <Table.Row key={newsletter.id}>
            <Table.Cell>
              <Link
                to={pageUrl(NewsletterDetail, {
                  productSlug,
                  newsletterId: newsletter.id
                })}>
                # {newsletter.id}
              </Link>
            </Table.Cell>
            <Table.Cell>{formatDateTime(newsletter.datetime)}</Table.Cell>
            <Table.Cell>{newsletter.status}</Table.Cell>
            <Table.Cell>{newsletter.sending_status}</Table.Cell>
            <Table.Cell>
              <YesNo value={newsletter.editor_approved} />
            </Table.Cell>
            <Table.Cell>{newsletter.editor || 'N/A'}</Table.Cell>
            <Table.Cell>
              <YesNo value={newsletter.marketing_approved} />
            </Table.Cell>
            <Table.Cell>{newsletter.marketer || 'N/A'}</Table.Cell>
            <Table.Cell>{newsletter.sent_count}</Table.Cell>
            <Table.Cell>{newsletter.article_count}</Table.Cell>
            <Table.Cell>{newsletter.price_count}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <TableFooter>{pagination}</TableFooter>
    </Table>
  );
}

export default NewsletterTable;
