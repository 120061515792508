import React from 'react';

import { Page, Header, LoginRequired, ListViewEssentials } from 'components';

import { EventsInfoTableInverted, EventsInvoices } from './components';

import { eventsInvoices } from './sdk';

class EventsDashboard extends React.Component {
  render() {
    const {
      user,
      crumbs,
      sort,
      orderBy,
      limit,
      count,
      changePage,
      changeRowsPerPage,
      page,
      data
    } = this.props;

    const eventsInvoicesProps = {
      sort,
      orderBy,
      limit,
      count,
      changePage,
      changeRowsPerPage,
      page,
      data
    };
    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Header>Events Dashboard</Header>
            <EventsInfoTableInverted user={user} />
            <EventsInvoices {...eventsInvoicesProps} />
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: eventsInvoices
})(LoginRequired(EventsDashboard));
