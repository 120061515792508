import _ from 'lodash';

import React, { useState, useCallback, useEffect } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';

import { Loader, Page } from 'components';

import { withLoggedUser, withPermissions } from 'hooks';

import { PERMISSIONS } from 'permissions';

import { notifyErrors } from 'utils/notifications';

import { PriceTable } from './components';
import { pricesMissingDataList } from './sdk';

function PriceSerieMissingDataList({ user, crumbs }) {
  const { productSlug } = useParams();

  const [priceMissingData, setPriceMissingData] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchPriceMissingDataByFrequency = useCallback(
    async frequency => {
      const { data, errors, success } = await pricesMissingDataList(
        frequency,
        productSlug
      );

      if (success) {
        setPriceMissingData(prevData => ({ ...prevData, [frequency]: data }));
      } else {
        notifyErrors(errors);
      }
    },
    [productSlug]
  );

  const fetchPriceMissingData = useCallback(async () => {
    await Promise.all([
      fetchPriceMissingDataByFrequency('daily'),
      fetchPriceMissingDataByFrequency('weekly'),
      fetchPriceMissingDataByFrequency('monthly'),
      fetchPriceMissingDataByFrequency('quarterly')
    ]);

    setLoading(false);
  }, [fetchPriceMissingDataByFrequency]);

  useEffect(() => {
    fetchPriceMissingData();
  }, [fetchPriceMissingData]);

  const frequencies = ['daily', 'weekly', 'monthly', 'quarterly'];

  return (
    <Page user={user} crumbs={crumbs}>
      <Page.Body>
        <Page.Content>
          <Header as="h1">Missing Prices Data</Header>
          {loading && <Loader />}
          {priceMissingData &&
            !loading &&
            _.map(frequencies, frequency => (
              <Segment key={frequency}>
                <Header as="h2">{_.capitalize(frequency)} Price Series</Header>
                <PriceTable
                  priceSeries={priceMissingData[frequency].prices}
                  startDate={priceMissingData[frequency].period.start}
                  endDate={priceMissingData[frequency].period.end}
                  frequency={_.capitalize(frequency)}
                />
              </Segment>
            ))}
        </Page.Content>
      </Page.Body>
    </Page>
  );
}

export default withLoggedUser(
  withPermissions([PERMISSIONS.VIEW_PRICES])(PriceSerieMissingDataList)
);
