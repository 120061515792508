import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Header, Segment } from 'semantic-ui-react';

import { Page, LoginRequired } from 'components';
import { PERMISSIONS, PermissionsRequired } from 'permissions';
import { notifyError } from 'utils/notifications';

import { GroupsAndPermissionsUpdateForm } from './components';

import { adminGroupDetail } from './sdk';

const GroupsAndPermissionsUpdate = ({ user, crumbs }) => {
  const [adminGroup, setAdminGroup] = useState(null);
  const { groupId } = useParams();

  const fetchAdminGroup = useCallback(async () => {
    const { data, errors, success } = await adminGroupDetail(groupId);

    if (success) {
      setAdminGroup(data);
    } else {
      notifyError(errors);
    }
  }, [groupId]);

  useEffect(() => {
    fetchAdminGroup();
  }, [fetchAdminGroup]);

  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content crumbs={crumbs}>
          <Segment>
            <Header as="h1">Update group and permissions</Header>
            {adminGroup && (
              <GroupsAndPermissionsUpdateForm
                data={adminGroup}
                fetchAdminGroup={fetchAdminGroup}
              />
            )}
          </Segment>
        </Page.Content>
      </Page.Body>
    </Page>
  );
};

export default LoginRequired(
  PermissionsRequired([PERMISSIONS.CHANGE_GROUPS])(GroupsAndPermissionsUpdate)
);
