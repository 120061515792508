import { InputField } from 'components';

export const FILTERS = () => [
  [
    {
      name: 'search',
      emptyValue: '',
      component: InputField,
      placeholder: 'Search ...',
      label: 'Search email',
      width: 8
    }
  ]
];
