import _ from 'lodash';
import moment from 'moment';

export const getPriceValues = data => {
  const hasData = !_.isNil(data.data);

  const value1 = (hasData && data.data.value_1) || null;
  const value2 = (hasData && data.data.value_2) || null;
  const mid_value = (hasData && data.data.mid_value) || null;

  return {
    value1,
    value2,
    // This is available only for prices synced from KIS
    mid_value
  };
};

export const compare = (v1, v2) => {
  // v1 existing value
  // v2 is the new value
  // This is being used to decide if we want to show the update button
  // Return true if the values are the same
  // Return false, otherwise

  // If we are deleting an existing value
  if (_.isNumber(v1) && _.isEmpty(v2)) {
    return false;
  }

  // If we don't have any values
  if (_.isEmpty(v1) && _.isEmpty(v2)) {
    return true;
  }

  return parseFloat(v1) === parseFloat(v2);
};

export const isLowGreaterThanHigh = (v1, v2) => {
  return parseFloat(v1) > parseFloat(v2);
};

const getAverage = (v1, v2) => {
  if (_.isNil(v2)) {
    return parseFloat(v1);
  }

  return (parseFloat(v1) + parseFloat(v2)) / 2;
};

export const isDiffAbove10Percent = (v1, v2) => {
  const fv1 = parseFloat(v1);
  const fv2 = parseFloat(v2);

  const a = Math.min(fv1, fv2);
  const b = Math.max(fv1, fv2);

  return 1 - parseFloat(a) / parseFloat(b) > 0.1;
};

export const hasWarnings = (value1, value2, priceSeries) => {
  const average = getAverage(value1, value2);

  const isAbove10Percent =
    priceSeries.latestValue !== 0 &&
    isDiffAbove10Percent(priceSeries.latestValue, average);

  return isAbove10Percent;
};

export const calculateDaysDifference = date => {
  const currentDate = moment();
  const targetDate = moment(date);

  const daysDifference = targetDate.diff(currentDate, 'days');

  return daysDifference;
};

export const shouldShowBigTimeGapAlert = (daysDifference, frequency) => {
  const frequencyLower = _.lowerCase(frequency);

  if (frequencyLower === 'daily' || frequencyLower === 'weekly') {
    return daysDifference > 6;
  }
  return false;
};
