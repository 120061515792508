import React from 'react';
import _ from 'lodash';

import { FastField as Field, TransferListField } from 'components';
import { articleTopicList } from './sdk';

class ArticleTopicSelect extends React.Component {
  state = {
    articleTopics: []
  };
  async componentDidMount() {
    const { data, success } = await articleTopicList();

    if (success) {
      this.setState({
        articleTopics: data.map(articleTopic => {
          return {
            key: articleTopic.id,
            value: articleTopic.id,
            label: articleTopic.title
          };
        })
      });
    }
  }

  render() {
    const { name, isCustom, ...rest } = this.props;
    const { articleTopics } = this.state;

    const options = articleTopics;
    if (!_.isEmpty(options)) {
      rest['options'] = options;
    }

    return (
      <Field
        name={name || 'articleTopic'}
        component={TransferListField}
        {...rest}
      />
    );
  }
}

export default ArticleTopicSelect;
