import React from 'react';

import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { Form, Divider } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { populateSlug } from 'utils';
import { notifySuccess } from 'utils/notifications';

import {
  Field,
  InputField,
  FormRow,
  DateField,
  ErrorsAndCreateButtonInRow,
  CheckboxField,
  AdminSelect,
  RegionSelect,
  PriceSeriesCurrencySelect,
  PriceSeriesCategorySelect,
  PriceSeriesProductSelect,
  PriceSeriesRegionSelect,
  PriceSeriesIncoTermSelect,
  PriceSeriesFormatSelect,
  PriceSeriesUnitSelect,
  PriceSeriesFrequencySelect,
  PriceSeriesScaleSelect,
  RichTextAreaField,
  UnsavedFormPrompt,
  PriceSeriesDataSourceSelect,
  PMPriceSeriesMainGroupSelect
} from 'components';

import { getInitialValues, validationSchema } from './utils';

class PriceSeriesCreateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;
    const { isPowerMaterial } = this.props;

    const data = prepareFormData(values);

    setSubmitting(true);

    if (isPowerMaterial && !values.mainGroup) {
      setFieldError('mainGroup', 'This field is required');
      setSubmitting(false);
      return;
    }
    if (!isPowerMaterial && !values.category) {
      setFieldError('category', 'This field is required');
      setSubmitting(false);
      return;
    }

    const { errors, success } = await this.props.sdk(data);

    if (success) {
      notifySuccess('Price series created.', {
        onOpen: this.props.history.push(pageUrl(this.props.successPage))
      });
      return;
    }

    handleFormErrors(errors, setFieldError);
    setSubmitting(false);
  };

  render() {
    const { isPowerMaterial } = this.props;

    const initialValues = getInitialValues(isPowerMaterial);
    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}>
        {({
          isSubmitting,
          handleSubmit,
          setFieldValue,
          dirty,
          touched,
          errors,
          values
        }) => (
          <Form>
            <UnsavedFormPrompt when={dirty && !isSubmitting} />
            <ErrorsAndCreateButtonInRow
              errors={errors}
              touched={touched}
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
            <FormRow>
              <Field
                width={2}
                name="showOnHomepage"
                label="Show on home page"
                component={CheckboxField}
              />

              <Field
                width={2}
                name="showInRightColumn"
                label="Show in right column"
                component={CheckboxField}
              />
              {isPowerMaterial && (
                <>
                  <Field
                    width={3}
                    name="availableToSteelSubscribers"
                    label="Available to Steel Subscribers"
                    component={CheckboxField}
                  />

                  <Field
                    width={3}
                    name="availableToSteelLiveSubscribers"
                    label="Available to Steel Live Subscribers"
                    component={CheckboxField}
                  />
                </>
              )}
            </FormRow>
            <FormRow>
              <Field
                required
                width={6}
                name="title"
                label="Title:"
                component={InputField}
                onChange={(e, data) => {
                  populateSlug(data, setFieldValue);
                }}
              />
              <Field
                required
                width={4}
                name="shortTitle"
                label="Short title:"
                component={InputField}
              />
            </FormRow>
            <FormRow alignCenter>
              <Field
                width={6}
                name="slug"
                label="Slug:"
                component={InputField}
              />
              <Field
                width={4}
                name="displayAs"
                label="Display as:"
                component={InputField}
              />
            </FormRow>
            <FormRow>
              {isPowerMaterial && (
                <PMPriceSeriesMainGroupSelect
                  width={6}
                  name="mainGroup"
                  label="Category"
                  required
                />
              )}
              {!isPowerMaterial && (
                <PriceSeriesCategorySelect
                  required
                  width={5}
                  name="category"
                  label="Category:"
                  isPowerMaterial={isPowerMaterial}
                />
              )}
              <PriceSeriesProductSelect
                required
                width={5}
                name="product"
                label="Product:"
                isPowerMaterial={isPowerMaterial}
              />
            </FormRow>
            <FormRow>
              <PriceSeriesRegionSelect
                required
                width={5}
                name="region"
                label="Region:"
              />

              <PriceSeriesRegionSelect
                width={5}
                name="region2"
                label="Region 2:"
              />
            </FormRow>
            <FormRow widths="equal">
              <PriceSeriesIncoTermSelect
                required
                name="incoTerms"
                label="INCO terms:"
              />

              <PriceSeriesFormatSelect required name="format" label="Format:" />

              <PriceSeriesUnitSelect required name="unit" label="Unit:" />

              <PriceSeriesFrequencySelect
                required
                name="frequency"
                label="Frequency:"
              />
            </FormRow>
            <FormRow>
              <PriceSeriesCurrencySelect
                required
                width={3}
                name="currency"
                label="Currency:"
              />

              <PriceSeriesScaleSelect
                required
                width={3}
                name="scale"
                label="Scale:"
              />
              <Field
                required
                width={3}
                name="decimalPlaces"
                label="Decimal places:"
                component={InputField}
                type="number"
              />

              <Field
                required
                width={4}
                name="date"
                label="Start date:"
                component={DateField}
              />
            </FormRow>
            <FormRow>
              <PriceSeriesDataSourceSelect
                required
                width={4}
                name="priceDataSource"
                label="Price Data Source"
              />
              {values['priceDataSource'] &&
                values['priceDataSource'] !== 'Manual' && (
                  <Field
                    width={4}
                    name="priceDataSymbols"
                    label="Price Data External Symbols:"
                    component={InputField}
                    required
                  />
                )}
            </FormRow>
            <AdminSelect
              width={16}
              name="access"
              isMulti={true}
              label="Access:"
            />

            <RegionSelect
              isMulti={true}
              name="articleRegions"
              label="Region for articles:"
            />
            <PriceSeriesCategorySelect
              isMulti={true}
              name="articlePrices"
              label="Prices for articles:"
              isPowerMaterial={isPowerMaterial}
            />

            <PriceSeriesProductSelect
              isMulti={true}
              name="articleProducts"
              label="Products for articles:"
              isPowerMaterial={isPowerMaterial}
            />
            <AdminSelect
              isMulti={true}
              name="articleAuthors"
              label="Authors for articles:"
            />
            <Field name="text" component={RichTextAreaField} label="Text: " />
            <Divider />
            <FormRow widths="equal">
              <Field
                name="metaTitle"
                label="Meta Title"
                component={InputField}
              />

              <Field
                name="metaKeywords"
                label="Meta Keywords"
                component={InputField}
              />

              <Field
                name="metaDescription"
                label="Meta Description"
                component={InputField}
              />

              <Field
                name="metaStandout"
                label="Meta Standout"
                component={InputField}
              />
            </FormRow>
            <ErrorsAndCreateButtonInRow
              errors={errors}
              touched={touched}
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(PriceSeriesCreateForm);
