import React from 'react';

import _ from 'lodash';

import { Field, TransferListField } from 'components';

import { faqList } from './sdk';

class FaqSelect extends React.Component {
  state = {
    faqs: []
  };

  async componentDidMount() {
    const { data, success } = await faqList();

    if (success) {
      this.setState({
        faqs: _.map(data, faq => ({
          key: faq.id,
          value: faq.id,
          label: faq.question
        }))
      });
    }
  }

  render() {
    const { name, excludeOptions, ...rest } = this.props;
    const { faqs } = this.state;

    let options = faqs;

    if (!_.isEmpty(excludeOptions)) {
      options = _.filter(
        options,
        option => _.indexOf(excludeOptions, option.value) < 0
      );
    }

    return (
      <>
        <Field
          name={name || 'faqs'}
          component={TransferListField}
          options={options}
          isClearable
          {...rest}
        />
      </>
    );
  }
}

export default FaqSelect;
