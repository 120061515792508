import React from 'react';

import { withRouter } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { GlossaryCategoryCreate } from 'pages';

import {
  Page,
  Flex,
  Header,
  LoginRequired,
  ListViewEssentials,
  Button
} from 'components';

import { glossaryCategoryList } from './sdk';

import { GlossaryCategoriesTable } from './components';

const DEFAULT_LIMIT = 20;

class GlossaryCategoryList extends React.Component {
  onCompanyCreateClick = () => {
    const url = pageUrl(GlossaryCategoryCreate);
    this.props.history.push(url);
  };

  render() {
    const { user, data, sort, orderBy, crumbs } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Flex spaceBetween>
              <Header>Glossary Categories</Header>
              <Button onClick={this.onCompanyCreateClick}>Create</Button>
            </Flex>
            {data && (
              <GlossaryCategoriesTable
                categories={data}
                sort={sort}
                orderBy={orderBy}
              />
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: glossaryCategoryList,
  limit: DEFAULT_LIMIT
})(LoginRequired(withRouter(GlossaryCategoryList)));
