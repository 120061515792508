import React from 'react';

import cx from 'classnames';

import { Navigation, Breadcrumbs, StagingStickyFooter } from 'components';

import styles from './styles.module.css';

class PageBody extends React.Component {
  render() {
    const { className, children } = this.props;

    return <div className={cx(styles.PageBody, className)}>{children}</div>;
  }
}

const PageContent = ({
  className,
  children,
  maxWidth = false,
  crumbs = [],
  additionalContent = null
}) => (
  <div
    className={cx(
      styles.PageContent,
      { [styles.maxPageContent]: maxWidth },
      className
    )}>
    <Breadcrumbs crumbs={crumbs} additionalContent={additionalContent} />
    {children}
  </div>
);

class Page extends React.Component {
  render() {
    const { className, children } = this.props;
    const showStagingHeader = process.env.REACT_APP_TEST_ENVIRONMENT === 'true';
    return (
      <>
        <Navigation user={this.props.user} />
        <div className={cx(styles.Page, className)}>
          <div className={styles.PageWrapper}>{children}</div>
        </div>
        {showStagingHeader && <StagingStickyFooter />}
      </>
    );
  }
}

Page.Body = PageBody;
Page.Content = PageContent;

export default Page;
