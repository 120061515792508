import _ from 'lodash';

export const compare = (v1, v2) => {
  // v1 existing value
  // v2 is the new value
  // This is being used to decide if we want to show the update button
  // Return true if the values are the same
  // Return false, otherwise

  // If we are deleting an existing value
  if (_.isNumber(v1) && _.isEmpty(v2)) {
    return false;
  }

  // If we don't have any values
  if (_.isEmpty(v1) && _.isEmpty(v2)) {
    return true;
  }

  return parseFloat(v1) === parseFloat(v2);
};

export const isLowGreaterThanHigh = (v1, v2) => {
  return parseFloat(v1) > parseFloat(v2);
};

export const isDiffAbove10Percent = (v1, v2) => {
  const fv1 = parseFloat(v1);
  const fv2 = parseFloat(v2);

  const a = Math.min(fv1, fv2);
  const b = Math.max(fv1, fv2);

  return 1 - parseFloat(a) / parseFloat(b) > 0.1;
};

export const getAverage = (v1, v2) => {
  if (_.isNil(v2)) {
    return parseFloat(v1);
  }

  return (parseFloat(v1) + parseFloat(v2)) / 2;
};
