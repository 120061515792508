import React from 'react';

import _ from 'lodash';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { Form, Button } from 'semantic-ui-react';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';
import {
  Field,
  UpdateButton,
  TextAreaField,
  InputField,
  SelectField,
  FormRow,
  DateTimeField,
  UnsavedFormPrompt,
  ErrorsAndButtonsInRow,
  Flex,
  CreatedUpdatedFormValues,
  NonFormValuesDisplay,
  BaseRichTextAreaField
} from 'components';

import { mailshotUpdate, mailshotSaveAsNew } from './sdk';
import {
  MAILSHOT_EMAIL_OPTIONS,
  mailshotValidationSchema,
  getNonFormItems
} from './utils';

class MailshotUpdateForm extends React.Component {
  state = {
    savingAsNew: false
  };

  handleSubmit = async (values, actions) => {
    const data = _.pickBy(values, _.negate(_.isNil));

    const { fetchMailshot } = this.props;
    const { mailshotId } = this.props.match.params;

    const { setSubmitting, setFieldError } = actions;

    if (this.state.savingAsNew) {
      return this.handleSaveAsNew(values, actions);
    }

    const { errors, success } = await mailshotUpdate(
      mailshotId,
      prepareFormData(data)
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Mailshot has been updated successfully.');
      fetchMailshot();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  handleSaveAsNew = async (values, actions) => {
    const data = _.pickBy(values, _.negate(_.isNil));

    const { mailshotId } = this.props.match.params;

    const { setSubmitting, setFieldError } = actions;

    const { errors, success } = await mailshotSaveAsNew(
      mailshotId,
      prepareFormData(data)
    );

    setSubmitting(false);
    this.setState({ savingAsNew: false });

    if (success) {
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const {
      created_at: createdAt,
      created_by: createdBy,
      css,
      date_sent: dateSent,
      from_email: fromEmail,
      from_name: fromName,
      html,
      plain_text: plainText,
      return_path: returnPath,
      status,
      subject,
      title,
      updated_at: updatedAt,
      updated_by: updatedBy,
      scheduled_date: scheduledDate,
      sending_status,
      mailshot_group_item
    } = this.props.data;

    const nonFormValues = {
      createdAt,
      createdBy,
      dateSent,
      returnPath,
      status,
      updatedAt,
      updatedBy
    };

    const initialValues = {
      html,
      css,
      plainText,
      status,
      title,
      subject,
      fromName,
      fromEmail,
      scheduledDate
    };

    const isDisableAllFieldWithoutScheduleDate =
      sending_status === 'Preparation';

    let isStatusSent = status === 'Sent';
    let isInGroup = !_.isNull(mailshot_group_item);

    isStatusSent = false;

    return (
      <Formik
        enableReinitialize={true}
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        validationSchema={mailshotValidationSchema}>
        {({ handleSubmit, isSubmitting, dirty, errors, touched }) => {
          return (
            <Form>
              <UnsavedFormPrompt when={dirty} />
              <FormRow>
                <Field
                  required
                  width={4}
                  name="scheduledDate"
                  label="Scheduled for:"
                  component={DateTimeField}
                  disabled={isStatusSent}
                />

                <Field
                  required
                  width={6}
                  name="fromName"
                  label="From name:"
                  component={InputField}
                  disabled={
                    isDisableAllFieldWithoutScheduleDate ||
                    isStatusSent ||
                    isInGroup
                  }
                />
                <Field
                  width={6}
                  name="fromEmail"
                  label="From email:"
                  component={SelectField}
                  options={MAILSHOT_EMAIL_OPTIONS}
                  required
                  isClearable
                  disabled={
                    isDisableAllFieldWithoutScheduleDate ||
                    isStatusSent ||
                    isInGroup
                  }
                />
              </FormRow>
              <Field
                width={8}
                required
                name="title"
                label="Title:"
                component={InputField}
                disabled={isDisableAllFieldWithoutScheduleDate || isStatusSent}
              />
              <Field
                width={8}
                required
                name="subject"
                label="Subject:"
                component={InputField}
                disabled={isDisableAllFieldWithoutScheduleDate || isStatusSent}
              />

              <Field
                required
                name="html"
                label="HTML"
                config={{
                  height: '600px',
                  readOnly: isDisableAllFieldWithoutScheduleDate,
                  extraPlugins: 'image2'
                }}
                component={BaseRichTextAreaField}
                disabled={
                  isDisableAllFieldWithoutScheduleDate ||
                  isStatusSent ||
                  isInGroup
                }
              />
              <Field
                name="plainText"
                label="Plain text"
                style={{ minHeight: '350px' }}
                component={TextAreaField}
                disabled={
                  isDisableAllFieldWithoutScheduleDate ||
                  isStatusSent ||
                  isInGroup
                }
              />
              <Field
                component={TextAreaField}
                name="css"
                label="CSS"
                disabled={
                  isDisableAllFieldWithoutScheduleDate ||
                  isStatusSent ||
                  isInGroup
                }
              />
              <CreatedUpdatedFormValues values={nonFormValues} />
              <NonFormValuesDisplay items={getNonFormItems(nonFormValues)} />
              <ErrorsAndButtonsInRow touched={touched} errors={errors}>
                <Flex>
                  <Button
                    type="button"
                    size="large"
                    color="green"
                    onClick={event => {
                      this.setState({ savingAsNew: true }, handleSubmit(event));
                    }}
                    disabled={isSubmitting}>
                    Save As New
                  </Button>
                  <UpdateButton
                    subject="Mailshot"
                    onClick={handleSubmit}
                    disabled={isSubmitting || isStatusSent}
                  />
                </Flex>
              </ErrorsAndButtonsInRow>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default withRouter(MailshotUpdateForm);
