import React from 'react';
import { Table } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import { pageUrl } from 'config/routes';
import {
  StaffDetail,
  WebArticleDetail,
  NewsletterDetail,
  ClientDetail,
  CourseDetail,
  MailshotDetail,
  WeeklySteelDetail,
  SEASMEditionDetail
} from 'pages';
import { STEEL_SLUG, POWER_MATERIAL_SLUG } from 'domainConstants';

const OnlineUsersTable = ({ onlineUsers, sort, orderBy }) => {
  const TYPES_URLS = {
    steel_article: {
      url: WebArticleDetail,
      arg: 'articleId',
      additionalArgs: {
        productSlug: STEEL_SLUG
      },
      label: 'Steel Article'
    },
    power_materials_article: {
      url: WebArticleDetail,
      arg: 'articleId',
      additionalArgs: {
        productSlug: POWER_MATERIAL_SLUG
      },
      label: 'Power Materials Article'
    },
    client: {
      url: ClientDetail,
      arg: 'clientId',
      label: 'Client'
    },
    course: {
      url: CourseDetail,
      arg: 'courseId',
      label: 'Course'
    },
    daily_steel_newsletter: {
      url: NewsletterDetail,
      arg: 'newsletterId',
      additionalArgs: {
        productSlug: STEEL_SLUG
      },
      label: 'Daily Steel Newsletter'
    },
    daily_power_materials_newsletter: {
      url: NewsletterDetail,
      arg: 'newsletterId',
      additionalArgs: {
        productSlug: POWER_MATERIAL_SLUG
      },
      label: 'Daily Power Materials Newsletter'
    },
    mailshot: {
      url: MailshotDetail,
      arg: 'mailshotId',
      label: 'Mailshot Detail'
    },
    'weekly-steel': {
      url: WeeklySteelDetail,
      arg: 'id',
      label: 'Weekly Steel'
    },
    'weekly-seasm': {
      url: SEASMEditionDetail,
      arg: 'id',
      label: 'Weekly SEASM'
    }
  };

  return (
    <Table celled selectable compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Email address</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Currently editing</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {onlineUsers.map(user => (
          <Table.Row key={user.id}>
            <Table.Cell>
              <Link to={pageUrl(StaffDetail, { userId: user.id })}>
                {user.email}
              </Link>
            </Table.Cell>
            <Table.Cell>
              {user.first_name} {user.last_name}
            </Table.Cell>
            <Table.Cell>
              {user.currently_editing.map(link => {
                const urlConfig = TYPES_URLS[link.content_type];

                if (urlConfig) {
                  const { url, arg, additionalArgs = {}, label } = urlConfig;

                  return (
                    <div key={link.object_id}>
                      <Link
                        to={pageUrl(url, {
                          [arg]: link.object_id,
                          ...additionalArgs
                        })}>
                        {label} {link.object_id}
                      </Link>
                    </div>
                  );
                }

                return null;
              })}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default OnlineUsersTable;
