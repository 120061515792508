import {
  EmailTemplateStatusSelect,
  EmailTemplateTypeSelect,
  EmailTriggerSelect,
  InputField,
  YesNoSelect
} from 'components';
import { coerceStringToBoolean } from 'utils';

export const FILTERS = [
  [
    {
      name: 'title',
      emptyValue: '',
      component: InputField,
      label: 'Title'
    },
    {
      name: 'status',
      emptyValue: null,
      fieldComponent: EmailTemplateStatusSelect,
      label: 'Status'
    },
    {
      name: 'trigger',
      emptyValue: null,
      fieldComponent: EmailTriggerSelect,
      label: 'Trigger'
    },
    {
      name: 'template_type',
      emptyValue: '',
      component: EmailTemplateTypeSelect,
      label: 'Type'
    },
    {
      name: 'favourites_only',
      label: 'Show favourites only',
      component: YesNoSelect,
      transform: coerceStringToBoolean,
      emptyValue: false,
      props: () => ({
        isClearable: false
      })
    }
  ]
];
