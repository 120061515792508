import React from 'react';

import _ from 'lodash';

import { withRouter } from 'react-router-dom';

import { Header, Segment, Button, Divider } from 'semantic-ui-react';

import { Page, LoginRequired, Flex, Loader } from 'components';
import OpenModalButton from 'components/OpenModalButton';
import { notifyErrors, notifySuccess } from 'utils/notifications';

import { PERMISSIONS, PermissionsRequired } from 'permissions';

import { dailyNewsletterPermanentPrices, showOrHidePrices } from './sdk';

import {
  PermanentPrices,
  OrderPricesModal,
  SectionUpdateForm
} from './components';

class PermanentPricesDetail extends React.Component {
  state = {
    permanentPrices: null,
    section: null,
    displayed: null
  };

  fetchPermanentPrices = async () => {
    const { data, errors, success } = await dailyNewsletterPermanentPrices();

    if (success) {
      this.setState({
        permanentPrices: data.prices,
        section: data.section,
        displayed: _.filter(data.prices, price => price.displayed).length > 0
      });
    } else {
      notifyErrors(errors);
    }
  };

  showOrHidePrices = async () => {
    const { permanentPrices, displayed } = this.state;

    const postData = {
      permanent_prices: _.map(permanentPrices, 'id'),
      displayed: !displayed
    };
    const { errors, success } = await showOrHidePrices(postData);

    if (success) {
      const label = displayed ? 'hide' : 'show';
      notifySuccess(`Successfully ${label} permament prices.`);
      this.fetchPermanentPrices();
    } else {
      notifyErrors(errors);
    }
  };

  componentDidMount() {
    this.fetchPermanentPrices();
  }

  orderPrices = () => {};

  render() {
    const { user, crumbs } = this.props;

    const { permanentPrices, section, displayed } = this.state;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Header as="h1">Permanent Prices</Header>

            <Segment compact style={{ minWidth: '900px' }}>
              {_.isNil(permanentPrices) && <Loader />}
              {!_.isNil(permanentPrices) && (
                <>
                  <Header as="h3">Heading</Header>
                  <SectionUpdateForm section={section} />
                  <Divider />
                  <Flex spaceBetween style={{ maxWidth: '900px' }}>
                    <Header as="h3">Prices</Header>
                    <Flex>
                      <Button
                        color={displayed ? 'red' : 'green'}
                        disabled={_.isEmpty(permanentPrices)}
                        onClick={this.showOrHidePrices}>
                        {displayed ? 'Hide' : 'Show'} prices
                      </Button>
                      <OpenModalButton
                        size="small"
                        color="yellow"
                        disabled={_.isEmpty(permanentPrices)}
                        modal={{
                          header: 'Order Prices',
                          render: props => (
                            <OrderPricesModal
                              prices={permanentPrices}
                              refetch={this.fetchPermanentPrices}
                              {...props}
                            />
                          )
                        }}>
                        Order Prices
                      </OpenModalButton>
                    </Flex>
                  </Flex>
                  <PermanentPrices
                    prices={permanentPrices}
                    fetchPermanentPrices={this.fetchPermanentPrices}
                  />
                </>
              )}
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(
  PermissionsRequired([PERMISSIONS.VIEW_NEWSLETTERS])(
    withRouter(PermanentPricesDetail)
  )
);
