import React from 'react';

import { Modal, Icon, Header, Form, Loader, Dimmer } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Flex,
  FormRow,
  Field,
  SubscriptionSelect,
  UpdateButtonInRow,
  SubscriptionStatusSelect,
  DateField
} from 'components';

import { notifySuccess, notifyErrors } from 'utils/notifications';

import { validationSchema } from './schema';
import { clientUpdateSubscription } from './sdk';

const initialValues = {
  subscription: null,
  status: null,
  date_start: null,
  date_end: null
};

class AssignSubscriptionModal extends React.Component {
  close = () => {
    this.props.onClose();
  };

  handleSubmit = async (values, { setSubmitting }) => {
    const confirm = window.confirm(
      `Are you sure you want to confirm subscription assignment for selected clients?`
    );

    if (!confirm) {
      setSubmitting(false);
      return;
    }

    setSubmitting(true);

    const { errors, success } = await clientUpdateSubscription({
      subscription: values.subscription,
      status: values.status,
      date_start: values.date_start,
      date_end: values.date_end,
      clients: this.props.selectedClients
    });

    if (success) {
      notifySuccess(
        'Selected clients will have their subscription assigned shortly.'
      );
      this.props.onSuccess();
      this.close();
    } else {
      notifyErrors(errors);
    }
    setSubmitting(false);
  };

  render() {
    return (
      <Modal open={true} size="tiny" centered={false}>
        <Modal.Header>
          <Flex spaceBetween>
            <Header as="h2" style={{ margin: 0 }}>
              Assign Subscription
            </Header>
            <Icon name="close" onClick={this.close} />
          </Flex>
        </Modal.Header>
        <Modal.Content>
          <Formik
            initialValues={initialValues}
            onSubmit={this.handleSubmit}
            validationSchema={validationSchema}>
            {({ handleSubmit, isSubmitting, isValid }) => (
              <>
                <Form>
                  <FormRow>
                    <SubscriptionSelect
                      name="subscription"
                      label="Subscription"
                      testId="bulk-actions-assign-subscription-subscription-select"
                    />
                  </FormRow>
                  <FormRow>
                    <SubscriptionStatusSelect
                      name="status"
                      label="Subscription Status"
                      testId="bulk-actions-assign-subscription-subscription-status-select"
                    />
                  </FormRow>
                  <FormRow>
                    <Field
                      name="date_start"
                      component={DateField}
                      label="Start Date"
                      testId="bulk-actions-assign-subscription-subscription-start-date-select"
                    />
                  </FormRow>
                  <FormRow>
                    <Field
                      name="date_end"
                      component={DateField}
                      label="End Date"
                      testId="bulk-actions-assign-subscription-subscription-end-date-select"
                    />
                  </FormRow>

                  <UpdateButtonInRow
                    onClick={handleSubmit}
                    label="Assign"
                    disabled={isSubmitting || !isValid}
                    data-testid="bulk-actions-assign-subscription-assign-btn"
                  />
                </Form>
                {isSubmitting && (
                  <Dimmer active inverted>
                    <Loader inverted>Submitting</Loader>
                  </Dimmer>
                )}
              </>
            )}
          </Formik>
        </Modal.Content>
      </Modal>
    );
  }
}

export default AssignSubscriptionModal;
