import React from 'react';

import { Field, SelectField } from 'components';

const ARTICLE_GROUPS = ['Region', 'Group'];

class ArticleGroupSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = ARTICLE_GROUPS.map(group => {
      return {
        key: group,
        value: group,
        label: group
      };
    });

    return (
      <Field
        name={name || 'articleGroup'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default ArticleGroupSelect;
