import * as yup from 'yup';

export const articleCreateSchema = yup.object().shape({
  fullHeadline: yup.string().required('Full headline is required.'),
  marketingHeadline: yup.string().required('Marketing headline is required.'),
  slug: yup.string().required('Slug is required.'),
  type: yup
    .string()
    .nullable()
    .required('Type is required.'),
  status: yup
    .string()
    .nullable()
    .required('Status is required.'),
  authors: yup.array().required('Pick at least 1 author.'),
  tags: yup
    .array()
    .of(yup.string().max(100, 'Tag must be at most 100 characters long.'))
});
