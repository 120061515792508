import { UniSelect } from 'components';

export const FILTERS = productSlug => [
  [
    {
      name: 'subscription',
      emptyValue: null,
      fieldComponent: UniSelect,
      label: 'Subscription',
      props: () => ({
        productSlug: productSlug,
        source: 'subscriptionsForProduct'
      })
    },
    {
      name: 'subscription_status',
      emptyValue: null,
      fieldComponent: UniSelect,
      label: 'Subscription Status',
      props: () => ({
        productSlug: productSlug,
        source: 'subscriptionStatusses'
      })
    },
    {
      name: 'article_grouping',
      emptyValue: null,
      fieldComponent: UniSelect,
      label: 'Article grouping',
      props: () => ({
        productSlug: productSlug,
        source: 'articleGroupings'
      })
    }
  ]
];
