import React from 'react';

import { Table } from 'semantic-ui-react';

import { Formik } from 'formik';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { Button, InputField, Field } from 'components';

import { subscriptionFeatureCreate } from './sdk';
import { featureSchema } from './utils';

class CreateFeatureForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError, resetForm } = actions;

    const { subscription, fetchSubscription } = this.props;
    const data = {
      ...prepareFormData(values),
      subscription: subscription.id
    };

    setSubmitting(true);

    const { success, errors } = await subscriptionFeatureCreate(data);

    setSubmitting(false);

    if (success) {
      notifySuccess('Feature created.');
      fetchSubscription();
      resetForm();
      return;
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  render() {
    const initialValues = {
      text: ''
    };

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        validationSchema={featureSchema}>
        {({ isSubmitting, handleSubmit }) => (
          <Table.Row>
            <Table.Cell>
              <Field name="text" component={InputField} />
            </Table.Cell>
            <Table.Cell width={6} textAlign="center">
              <Button
                color="green"
                disabled={isSubmitting}
                onClick={handleSubmit}>
                Create
              </Button>
            </Table.Cell>
          </Table.Row>
        )}
      </Formik>
    );
  }
}

export default CreateFeatureForm;
