import React from 'react';

import { withRouter } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { CountryCreate } from 'pages';

import {
  Page,
  Flex,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Button,
  Filters as SharedFilters
} from 'components';

import { countryList } from './sdk';

import { CountryTable } from './components';
import { FILTERS } from './utils';

class CountryList extends React.Component {
  onCountryCreateClick = () => {
    const url = pageUrl(CountryCreate);
    this.props.history.push(url);
  };

  render() {
    const {
      user,
      data,
      count,
      page,
      limit,
      sort,
      orderBy,
      filters,
      filterBy,
      clear,
      changePage,
      changeRowsPerPage,
      crumbs
    } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Flex spaceBetween>
              <Header>Countries</Header>
              <Button onClick={this.onCountryCreateClick}>Create</Button>
            </Flex>
            {data && (
              <>
                <SharedFilters
                  count={count}
                  filters={filters}
                  filterBy={filterBy}
                  clearFilters={clear}
                  layout={FILTERS}
                />
                <CountryTable
                  countries={data}
                  sort={sort}
                  orderBy={orderBy}
                  pagination={
                    <Pagination
                      limit={limit}
                      count={count}
                      page={page}
                      changePage={changePage}
                      changeRowsPerPage={changeRowsPerPage}
                    />
                  }
                />
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: countryList
})(LoginRequired(withRouter(CountryList)));
