import React from 'react';

import { Table } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { WebArticleDetail } from 'pages';

import {
  TopHeadlinesForm,
  FeaturedForm,
  RegionForm,
  ProductTypeGroupForm
} from './components';

const ArticleTable = ({ articles, dailyNewsletter, fetchDailyNewsletter }) => (
  <Table celled selectable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Title</Table.HeaderCell>
        <Table.HeaderCell>Top Headlines</Table.HeaderCell>
        <Table.HeaderCell>Featured</Table.HeaderCell>
        <Table.HeaderCell>Region</Table.HeaderCell>
        <Table.HeaderCell>Product Type Group</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {articles.map(article => (
        <Table.Row key={article.id}>
          <Table.Cell width={4}>
            <Link to={pageUrl(WebArticleDetail, { articleId: article.id })}>
              {article.full_headline}
            </Link>
          </Table.Cell>
          <Table.Cell width={1}>
            <TopHeadlinesForm
              article={article}
              dailyNewsletter={dailyNewsletter}
              fetchDailyNewsletter={fetchDailyNewsletter}
            />
          </Table.Cell>
          <Table.Cell width={1}>
            <FeaturedForm
              article={article}
              dailyNewsletter={dailyNewsletter}
              fetchDailyNewsletter={fetchDailyNewsletter}
            />
          </Table.Cell>
          <Table.Cell width={5}>
            <RegionForm
              article={article}
              dailyNewsletter={dailyNewsletter}
              fetchDailyNewsletter={fetchDailyNewsletter}
            />
          </Table.Cell>
          <Table.Cell width={5}>
            <ProductTypeGroupForm
              article={article}
              dailyNewsletter={dailyNewsletter}
              fetchDailyNewsletter={fetchDailyNewsletter}
            />
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);

export default ArticleTable;
