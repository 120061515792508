import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const emailTemplatePresetOptionsList = async params => {
  const url = `${BASE_URL}/emails/email-templates/preset-options/`;

  return await requestSdk(() => get(url, params));
};

export const emailTemplateAddToFavourites = async templateId => {
  const url = `${BASE_URL}/emails/email-templates/${templateId}/add-to-favourites/`;

  return await requestSdk(() => post(url));
};

export const emailTemplateRemoveFromFavourites = async templateId => {
  const url = `${BASE_URL}/emails/email-templates/${templateId}/remove-from-favourites/`;

  return await requestSdk(() => post(url));
};
