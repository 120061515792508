import React from 'react';

import { Dimmer, Loader } from 'semantic-ui-react';

import { Page, Flex, Header, LoginRequired } from 'components';
import { notifyErrors } from 'utils/notifications';

import { pageList } from './sdk';

import { PageTable } from './components';

class PageList extends React.Component {
  state = {
    pages: [],
    isLoading: false
  };

  async componentDidMount() {
    this.setState({ isLoading: true });

    const { success, data, errors } = await pageList();

    if (success) {
      this.setState({ pages: data, isLoading: false });
    } else {
      this.setState({ isLoading: false });

      notifyErrors(errors);
    }
  }

  render() {
    const { user } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content>
            <Flex spaceBetween>
              <Header>Pages</Header>
            </Flex>
            {this.state.isLoading && (
              <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>
            )}
            {!this.state.isLoading && <PageTable pages={this.state.pages} />}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(PageList);
