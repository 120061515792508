import React, { useState } from 'react';
import { arrayMoveImmutable } from 'array-move';

import { Modal, Icon, Header } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  SortableList,
  UpdateButtonInRow,
  Flex,
  FormRow,
  UniSelect
} from 'components';

import { notifyErrors } from 'utils/notifications';

import { orderPricesForCategory, priceSeriesByCategoryList } from './sdk';
import { notifySuccess } from 'utils/notifications';

function CategoryOrderModal({ onClose, productSlug }) {
  const [options, setOptions] = useState([]);

  const fetchPrices = async categoryId => {
    const { data, errors, success } = await priceSeriesByCategoryList(
      categoryId,
      productSlug
    );

    if (success) {
      setOptions(
        data.map(price => ({ id: price.id, title: price.display_as }))
      );
    } else {
      notifyErrors(errors);
    }
  };

  const onSortEnd = async ({ item, oldIndex, newIndex }) => {
    setOptions(prevOptions =>
      arrayMoveImmutable(prevOptions, oldIndex, newIndex)
    );
  };

  const confirmOrder = async () => {
    const data = { prices: options.map(price => price.id) };

    const { success } = await orderPricesForCategory(data);

    if (success) {
      notifySuccess('Prices are reordered successfully.');
      onClose();
    }
  };

  return (
    <Modal open={true} size="large" centered={false}>
      <Modal.Header>
        <Flex spaceBetween>
          <Header as="h2">Order Prices By Category</Header>
          <Icon name="close" onClick={onClose} />
        </Flex>
      </Modal.Header>
      <Modal.Content>
        <Formik>
          {({ values }) => (
            <FormRow>
              <UniSelect
                name="category"
                placeholder="Select Category"
                source="priceSerieCategories"
                value={values.category}
                onChange={event => fetchPrices(event.target.value)}
                productSlug={productSlug}
              />
            </FormRow>
          )}
        </Formik>
        {options.length ? (
          <div style={{ marginTop: 20 }}>
            <SortableList items={options} onSortEnd={onSortEnd} />
          </div>
        ) : (
          <Header as="h3">A category must be selected</Header>
        )}
        <Flex flexEnd>
          <UpdateButtonInRow onClick={confirmOrder} subject="Prices Order" />
        </Flex>
      </Modal.Content>
    </Modal>
  );
}

export default CategoryOrderModal;
