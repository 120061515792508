import _ from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import { Login } from 'pages';

import { pageUrl } from 'config/routes';

import { useHistory } from 'react-router-dom';

import { getNextUrl } from 'utils';

import { me, logout } from 'sdk';

const withLoggedUser = Component => props => {
  const [user, setUser] = useState(null);

  const history = useHistory();

  const fetchMe = useCallback(async () => {
    const { data } = await me();
    if (!_.isEmpty(data)) {
      setUser(data);
    } else {
      logout();
      // redirect to login with next params
      const nextUrl = getNextUrl(pageUrl(Login), history.location.pathname);
      history.push(nextUrl);
    }
  }, [history]);

  useEffect(() => {
    fetchMe();
  }, [fetchMe]);

  return user ? <Component {...props} user={user} /> : null;
};

export default withLoggedUser;
