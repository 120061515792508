import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Formik, FieldArray } from 'formik';
import { Segment, Table } from 'semantic-ui-react';

import { UpdateButtonInRow } from 'components';
import { notifyErrors, notifySuccess } from 'utils/notifications';

import MonthsForm from './MonthsForm';

import { updateBudget } from './sdk';

class ProductMontsTable extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting } = actions;

    const year = values.year;
    const postData = {
      data: _.filter(values.data, item => item.key !== 'Total')
    };

    const { errors, success } = await updateBudget(year, postData);

    setSubmitting(false);

    if (success) {
      notifySuccess('Successfully updated.');
      this.props.refetch();
    } else {
      notifyErrors(errors);
    }
  };

  render() {
    const { data } = this.props;

    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];

    const totalValues = _.head(
      _.filter(data.data, item => item.key === 'Total')
    ).value;

    return (
      <Segment>
        {!_.isNil(months) && (
          <Table celled selectable definition={true}>
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell>Total</Table.HeaderCell>
                <Table.HeaderCell>Product</Table.HeaderCell>
                {_.map(months, month => (
                  <Table.HeaderCell>{month}</Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Formik
                enableReinitialize={true}
                initialValues={data}
                onSubmit={this.handleSubmit}>
                {({ values, handleSubmit, isSubmitting, dirty }) => (
                  <>
                    <FieldArray
                      name="data"
                      render={() =>
                        values.data.map(
                          (productData, index) =>
                            productData.key !== 'Total' && (
                              <MonthsForm
                                key={productData.key}
                                index={index}
                                product={productData}
                                data={values.data}
                              />
                            )
                        )
                      }
                    />
                    <Table.Row>
                      <Table.Cell>
                        {_.sumBy(totalValues, item => item.value)}
                      </Table.Cell>
                      <Table.Cell>Total</Table.Cell>
                      {totalValues.map(total => (
                        <Table.Cell textAlign="center">
                          {total.value}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell colSpan="14" textAlign="right">
                        <UpdateButtonInRow
                          onClick={handleSubmit}
                          disabled={isSubmitting}
                          label="Save"
                        />
                      </Table.Cell>
                    </Table.Row>
                  </>
                )}
              </Formik>
            </Table.Body>
          </Table>
        )}
      </Segment>
    );
  }
}

export default withRouter(ProductMontsTable);
