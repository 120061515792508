import _ from 'lodash';
import React from 'react';

import { withRouter, Link } from 'react-router-dom';

import { Table } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { ChinaSteelReportDetail } from 'pages';

import { TableFooter, SortableHeader } from 'components';

import { formatDateTime } from 'utils';

const ChinaSteelTable = ({ reports, sort, orderBy, history, pagination }) => (
  <Table celled selectable compact={true}>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell singleLine style={{ fontWeight: '400' }} colspan="5">
          {pagination}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <SortableHeader sort={sort} orderBy={orderBy} field="report">
          Report
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="created_by">
          Created by
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="created_at">
          Created at
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="updated_by">
          Updated by
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="updated_at">
          Updated at
        </SortableHeader>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {reports.map(report => (
        <Table.Row key={report.id}>
          <Table.Cell>
            <Link
              to={pageUrl(ChinaSteelReportDetail, {
                reportId: report.id
              })}>
              {report.issue_number} - {report.title}
            </Link>
          </Table.Cell>
          <Table.Cell>{_.get(report.created_by, 'username', 'N/A')}</Table.Cell>
          <Table.Cell width={2}>{formatDateTime(report.created_at)}</Table.Cell>
          <Table.Cell>{_.get(report.updated_by, 'username', 'N/A')}</Table.Cell>
          <Table.Cell width={2}>{formatDateTime(report.updated_at)}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default withRouter(ChinaSteelTable);
