import React from 'react';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import { FormRow, CreateButton, MonthSelect, YearSelect } from 'components';

class YearMonthForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting } = actions;
    setSubmitting(true);

    const data = {
      ...values,
      defaultOnEmpty: false
    };

    await this.props.submit(data);

    setSubmitting(false);
  };

  render() {
    const { initialValues } = this.props;

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        ref={this.formRef}
        enableReinitialize={true}>
        {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
          <Form>
            <FormRow alignEnd>
              <MonthSelect
                width={3}
                name="forecastedMonth"
                label="Month"
                isClearable={false}
              />
              <YearSelect
                width={3}
                name="forecastedYear"
                label="Year"
                isClearable={false}
              />
              <Form.Field width={3}>
                <CreateButton onClick={handleSubmit} disabled={isSubmitting}>
                  Search
                </CreateButton>
              </Form.Field>
            </FormRow>
          </Form>
        )}
      </Formik>
    );
  }
}

export default YearMonthForm;
