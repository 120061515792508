import * as yup from 'yup';
import 'utils/yup';

export const sendPresetEmailValidationSchema = yup.object().shape({
  subject: yup.string().required('Subject is required.'),
  fromName: yup.string().required('From name is required.'),
  fromEmail: yup.string().required('From email is required.'),
  testEmail: yup
    .string()
    .strongEmail()
    .when('isTest', {
      is: true,
      then: schema => schema.required('Test email is required.')
    }),
  cc: yup
    .string()
    .matches(
      /^[a-z]([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(((;)|( )){1}[a-z][\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/,
      'Separate emails by a space or a semicolon.'
    )
});
