import _ from 'lodash';
import moment from 'moment';

const formatHeaderDate = (date, frequency) => {
  // Takes year like 2019-09-01
  // Outputs 1 Aug 2019 (Thu)

  if (frequency.toLowerCase() === 'monthly') {
    return moment(date).format('MMM YYYY');
  }

  return moment(date).format('D MMM YYYY (ddd)');
};

const getPriceHeaders = (data, frequency) => {
  const dataPointsByDate = _.get(_.first(data), 'data_points_by_date');

  return _.map(dataPointsByDate, point =>
    formatHeaderDate(point.date, frequency)
  );
};

const getPricesSeries = data => {
  return _.map(data, price => {
    let firstColumnData = {
      title: price.display_as,
      isPriceData: false,
      id: price.id
    };
    let otherColumns = _.map(price.data_points_by_date, priceDataPoint => ({
      id: price.id,
      format: price.format,
      latestValue: price.latest_value,
      isPriceData: true,
      ...priceDataPoint
    }));

    return [firstColumnData, ...otherColumns];
  });
};

export const getPricesData = (data, frequency) => ({
  headers: getPriceHeaders(data, frequency),
  pricesData: getPricesSeries(data)
});
