import React from 'react';

import { withRouter } from 'react-router-dom';

import { Button, Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import { Field, FormRow, InputField } from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';

import { mailshotSendTestEmail } from './sdk';

import { sendTestEmailSchema } from './schema';
import { notifySuccess } from 'utils/notifications';

class ShowTestEmailForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { mailshot, client, cancel } = this.props;

    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData({ ...values, client: client.id });

    setSubmitting(true);

    const { errors, success } = await mailshotSendTestEmail(mailshot.id, data);

    setSubmitting(false);

    if (success) {
      notifySuccess('Test email sent.');
      cancel(client);
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const { cancel, client } = this.props;

    const initialValues = {
      email: ''
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={sendTestEmailSchema}
        onSubmit={this.handleSubmit}>
        {({ handleSubmit, isSubmitting }) => (
          <Form>
            <FormRow>
              <Field
                required
                name="email"
                component={InputField}
                placeholder="Email"
              />
              <Button
                onClick={handleSubmit}
                type="submit"
                primary
                disabled={isSubmitting}>
                Send
              </Button>
              <Button onClick={() => cancel(client)}>Cancel</Button>
            </FormRow>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(ShowTestEmailForm);
