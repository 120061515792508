import _ from 'lodash';

import React from 'react';

import { Link } from 'react-router-dom';

import { Table } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { InvoiceDetail } from 'pages';

import { formatDate } from 'utils';

import { TableFooter, SortableHeader } from 'components';

class InvoiceTable extends React.Component {
  render() {
    const { invoices, sort, orderBy, pagination } = this.props;

    return (
      <>
        <Table celled selectable compact={true} size="large">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                singleLine
                style={{ fontWeight: '400' }}
                colspan="10">
                {pagination}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row textAlign="center" verticalAlign="middle">
              <SortableHeader sort={sort} orderBy={orderBy} field="identifier">
                Identifier
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="proform_id">
                Proform ID
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="date">
                Date
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="paid_date">
                Date Paid
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="client_id">
                Client ID
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="name">
                Name
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="company">
                Company
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="email">
                Email
              </SortableHeader>
              <SortableHeader
                sort={sort}
                orderBy={orderBy}
                field="invoice_status">
                Invoice status
              </SortableHeader>
              <SortableHeader
                sort={sort}
                orderBy={orderBy}
                field="invoice_converted_value">
                Total (without Vat)
              </SortableHeader>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {invoices.map(invoice => (
              <Table.Row key={invoice.id}>
                <Table.Cell>
                  <Link to={pageUrl(InvoiceDetail, { invoiceId: invoice.id })}>
                    {invoice.identifier}
                  </Link>
                </Table.Cell>
                <Table.Cell>{invoice.proform_id}</Table.Cell>
                <Table.Cell>
                  {invoice.invoice_date && formatDate(invoice.invoice_date)}
                </Table.Cell>
                <Table.Cell>
                  {invoice.invoice_paid_on &&
                    formatDate(invoice.invoice_paid_on)}
                </Table.Cell>
                <Table.Cell>
                  {_.get(invoice.contact_info, 'client.id')}
                </Table.Cell>
                <Table.Cell>{_.get(invoice.contact_info, 'name')}</Table.Cell>
                <Table.Cell>
                  {_.get(invoice.contact_info, 'company_name')}
                </Table.Cell>
                <Table.Cell>{_.get(invoice.contact_info, 'email')}</Table.Cell>
                <Table.Cell>{invoice.invoice_status}</Table.Cell>
                <Table.Cell>{invoice.invoice_converted_value}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <TableFooter>{pagination}</TableFooter>
        </Table>
      </>
    );
  }
}

export default InvoiceTable;
