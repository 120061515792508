import React from 'react';
import _ from 'lodash';

import { Link } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { MarketDataReportDetail, CourseDetail } from 'pages';

const RELATED_PAGES = {
  'market report': { page: MarketDataReportDetail, prop: 'reportId' },
  events: { page: CourseDetail, prop: 'courseId' }
};

const RelatedObject = ({ relatedObj, relatedObjType }) => {
  const pageInfo = RELATED_PAGES[_.lowerCase(relatedObjType)];

  return pageInfo ? (
    <Link to={pageUrl(pageInfo.page, { [pageInfo.prop]: relatedObj.id })}>
      {relatedObj.title}
    </Link>
  ) : (
    <div>{relatedObj.title}</div>
  );
};

export default RelatedObject;
