import _ from 'lodash';

export const getArticles = (contentSections, slug) => {
  const contentSection = _.first(
    _.filter(contentSections, { internal_slug: slug })
  );

  return _.get(contentSection, 'section_data.articles', []);
};

export const getArticle = (contentSections, slug, article) =>
  _.find(getArticles(contentSections, slug), { id: article.id });

export const isArticleAdded = (contentSections, slug, article) =>
  !_.isEmpty(getArticle(contentSections, slug, article));

export const getArticleContentSection = (contentSections, slug, article) => {
  const groupContentSections = _.filter(
    contentSections,
    section =>
      section.internal_slug === slug &&
      !_.isEmpty(_.get(section, 'section_data.articles', []))
  );

  return _.first(
    _.filter(groupContentSections, section =>
      _.find(section.section_data.articles, { id: article.id })
    )
  );
};

export const getArticleGroup = (contentSections, slug, article) => {
  const articleContentSection = getArticleContentSection(
    contentSections,
    slug,
    article
  );

  return _.get(articleContentSection, 'article_group', null);
};

export const getArticleSection = (contentSections, slug, article) => {
  const articleContentSection = getArticleContentSection(
    contentSections,
    slug,
    article
  );

  return _.get(articleContentSection, 'article_section', null);
};
