import React from 'react';
import _ from 'lodash';

import { Field, SelectField } from 'components';

import { POLL_TYPE_CHOICES } from 'pages/Poll/constants';

class PollTypeSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = _.map(POLL_TYPE_CHOICES, (poll, key) => {
      return {
        key: key,
        value: key,
        label: poll
      };
    });

    return (
      <Field
        name={name || 'pollType'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default PollTypeSelect;
