import React from 'react';

import { Table } from 'semantic-ui-react';

import { TableFooter, SortableHeader, LinkButton } from 'components';

import { formatDateTime, formatDate } from 'utils';

import { pageUrl } from 'config/routes';

import { Link } from 'react-router-dom';

import { WebArticleDetail } from 'pages';

class TrackedArticlesTable extends React.Component {
  render() {
    const {
      articleChanges,
      orderBy,
      pagination,
      sort,
      addFilters
    } = this.props;

    return (
      <>
        <Table celled selectable compact={true}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                singleLine
                style={{ fontWeight: '400' }}
                colspan="6">
                {pagination}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Client ID</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <SortableHeader sort={sort} orderBy={orderBy} field="open_at">
                Open at
              </SortableHeader>
              <Table.HeaderCell>Article Publish Date</Table.HeaderCell>
              <Table.HeaderCell>Article Full Headline</Table.HeaderCell>
              <Table.HeaderCell>Request Method</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {articleChanges.map(item => (
              <Table.Row>
                <Table.Cell>
                  <LinkButton
                    onClick={() =>
                      addFilters({
                        user: item.user.id
                      })
                    }>
                    {item.user.id}
                  </LinkButton>
                </Table.Cell>
                <Table.Cell>{item.user.email}</Table.Cell>
                <Table.Cell>{formatDateTime(item.open_at)}</Table.Cell>
                <Table.Cell>{formatDate(item.publish_date)}</Table.Cell>
                <Table.Cell>
                  <Link
                    to={pageUrl(WebArticleDetail, {
                      productSlug: item.article.type
                        .replace(/\s+/g, '-')
                        .toLowerCase(),
                      articleId: item.article.id
                    })}>
                    {item.article.full_headline}
                  </Link>
                </Table.Cell>
                <Table.Cell>{item.request_method}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <TableFooter>{pagination}</TableFooter>
        </Table>
      </>
    );
  }
}

export default TrackedArticlesTable;
