import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-toastify/dist/ReactToastify.css';

import App from 'config/entry';

ReactDOM.render(<App />, document.getElementById('root'));
