import React from 'react';
import { Formik } from 'formik';
import { Header, Form } from 'semantic-ui-react';

import { InputField, Field, CreateButton, FormRow } from 'components';
import { notifySuccess } from 'utils/notifications';
import { handleFormErrors } from 'utils/forms';

import { mailshotGroupAddLink } from './sdk';
import { mailshotGroupLinkSchema } from './utils';

import styles from './styles.module.css';

const MailshotGroupAddLink = ({ mailshot, fetchMailshotGroup }) => {
  const addLink = async (values, actions) => {
    const { setSubmitting, setFieldError, resetForm } = actions;
    setSubmitting(true);

    const { success, errors } = await mailshotGroupAddLink(mailshot.id, values);

    setSubmitting(false);

    if (success) {
      notifySuccess('Link added successfully.');
      fetchMailshotGroup();
      resetForm();
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  const initialValues = { link: '' };

  return (
    <>
      <Header as="h4">Create Link</Header>
      <Formik
        onSubmit={addLink}
        initialValues={initialValues}
        validationSchema={mailshotGroupLinkSchema}>
        {({ handleSubmit, isSubmitting, isValid }) => (
          <Form>
            <FormRow>
              <Field required width={5} name="link" component={InputField} />
              <div className={styles.createButtonContainer}>
                <CreateButton
                  onClick={handleSubmit}
                  className={styles.createButton}
                  disabled={isSubmitting || !isValid}
                  subject="Link"
                />
              </div>
            </FormRow>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MailshotGroupAddLink;
