import React from 'react';

import { Formik } from 'formik';
import { withRouter, useHistory } from 'react-router-dom';
import { Form } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { GroupsAndPermissionsList } from 'pages';

import {
  Field,
  InputField,
  CreateButtonInRow,
  PermissionSelect,
  AdminTransferSelect,
  UnsavedFormPrompt
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';
import { preparePermissions } from 'permissions/utils';

import { groupCreateSchema } from './schema';
import { groupCreate } from './sdk';

import styles from './styles.module.css';

const GroupsAndPermissionsCreateForm = () => {
  const history = useHistory();

  const handleGroupCreate = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    let data = prepareFormData(values);
    data['permissions'] = preparePermissions(data);

    setSubmitting(true);

    const { errors, success } = await groupCreate(data);

    if (success) {
      notifySuccess('Group has been created.');
      history.push(pageUrl(GroupsAndPermissionsList));
    } else {
      handleFormErrors(errors, setFieldError);
    }

    setSubmitting(false);
  };

  const initialValues = {
    name: '',
    permissions: [],
    members: []
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={groupCreateSchema}
      onSubmit={handleGroupCreate}>
      {({ values, handleSubmit, isSubmitting, dirty }) => (
        <Form className={styles.form}>
          <UnsavedFormPrompt when={dirty && !isSubmitting} />
          <Field
            width={6}
            required
            component={InputField}
            name="name"
            label="Group name"
          />
          <PermissionSelect
            required
            width={10}
            name="permissions"
            label="Permissions"
          />
          <AdminTransferSelect
            required
            width={10}
            name="members"
            label="Members"
          />
          <CreateButtonInRow onClick={handleSubmit} disabled={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(GroupsAndPermissionsCreateForm);
