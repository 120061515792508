import React from 'react';
import _ from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import { Segment, Table, Dimmer, Loader } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { CourseDetail } from 'pages';
import { VisibleIf } from 'permissions';

import { eventsDashboard } from './sdk';
import { buildEventRows } from './utils';

class EventsInfoTable extends React.Component {
  state = {
    events: null
  };

  fetchEvents = async () => {
    const { data, errors, success } = await eventsDashboard();

    if (success) {
      this.setState({ events: data.results });
    } else {
      this.setState({ errors });
    }
  };
  componentDidMount() {
    this.fetchEvents();
  }

  render() {
    const { events } = this.state;
    const { user } = this.props;
    let rows = [];

    if (!_.isNil(events)) {
      rows = buildEventRows(events);
    }

    return (
      <Segment style={{ overflow: 'auto' }}>
        {_.isNil(events) && (
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        )}
        {!_.isNil(events) && !_.isEmpty(events) && (
          <Table celled selectable definition={true}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1} />
                {events.map(event => (
                  <Table.HeaderCell
                    // calculate width of event cell to be equal
                    width={_.floor(_.divide(11, events.length))}>
                    <Link to={pageUrl(CourseDetail, { courseId: event.id })}>
                      {event.title}
                    </Link>
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {rows.map(row => (
                <Table.Row>
                  {row.map(item => (
                    <VisibleIf
                      user={user}
                      permissions={_.get(item, 'props.permissions', [])}>
                      <Table.Cell {...item.props}>
                        {item.linkTo ? (
                          <Link to={item.linkTo}>{item.render}</Link>
                        ) : (
                          item.render
                        )}
                      </Table.Cell>
                    </VisibleIf>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
        {!_.isNil(events) && _.isEmpty(events) && 'There are no active events'}
      </Segment>
    );
  }
}

export default withRouter(EventsInfoTable);
