import React from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { Header, Segment } from 'semantic-ui-react';

import { withLoggedUser, withPermissions } from 'hooks';
import { Page, Flex, DeleteButton } from 'components';
import {
  PERMISSIONS,
  DisableableElement,
  hasPermission,
  VisibleIfSuperuser
} from 'permissions';

import { PriceSpreadV2UpdateForm } from './components';
import { usePriceSpread } from './hooks';

function PriceSpreadV2Detail({ user, crumbs, history }) {
  const [priceSpread, setPriceSpread, deletePriceSpread] = usePriceSpread(
    history
  );

  const { productSlug } = useParams();

  return (
    <>
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Flex spaceBetween>
                <Header as="h1">Price Spread Detail</Header>
                <VisibleIfSuperuser user={user}>
                  {/* TODO: Use DeleteModal */}
                  <DeleteButton onClick={deletePriceSpread}>
                    Delete
                  </DeleteButton>
                </VisibleIfSuperuser>
              </Flex>
              {!_.isNull(priceSpread) && (
                <DisableableElement
                  disabled={!hasPermission(user, PERMISSIONS.CHANGE_PRICES)}>
                  <div />
                  <PriceSpreadV2UpdateForm
                    priceSpread={priceSpread}
                    setPriceSpread={setPriceSpread}
                    productSlug={productSlug}
                  />
                </DisableableElement>
              )}
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    </>
  );
}

export default withLoggedUser(
  withPermissions([PERMISSIONS.VIEW_PRICES])(PriceSpreadV2Detail)
);
