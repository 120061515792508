import React from 'react';

import { Statistic, Segment } from 'semantic-ui-react';

import {
  Page,
  Header,
  LoginRequired,
  ListViewEssentials,
  Flex,
  Filters as SharedFilters,
  Button
} from 'components';

import { pageUrl } from 'config/routes';

import { RenewalClientListV2 } from 'pages';

import { SnapshotsStatsTable } from './components';

import { renewalsDashboardApi } from './sdk';
import {
  calculateRenewalVolume,
  calculateRenewalValue,
  FILTERS
} from './utils';

class RenewalsDashboard extends React.Component {
  render() {
    const {
      user,
      crumbs,
      data,
      filters,
      count,
      filterBy,
      clear,
      history
    } = this.props;

    const { volumeResult, volumePercentageResult } = calculateRenewalVolume(
      data
    );

    const { valueResult, valuePercentageResult } = calculateRenewalValue(data);

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Flex spaceBetween>
              <Header>Renewals Dashboard</Header>
              <Button
                size="large"
                onClick={() => history.push(pageUrl(RenewalClientListV2))}>
                Renewals Sheet
              </Button>
            </Flex>
            <SharedFilters
              filters={filters}
              count={count}
              filterBy={filterBy}
              clearFilters={clear}
              layout={FILTERS}
            />
            <SnapshotsStatsTable filters={filters} data={data} />

            {data && (
              <Segment compact>
                <Flex flexStart>
                  <Statistic.Group size="tiny" style={{ margin: 0 }}>
                    <Statistic color="green">
                      <Statistic.Label>Renewal Volume</Statistic.Label>
                      <Statistic.Value>
                        {volumeResult} {volumePercentageResult}%
                      </Statistic.Value>
                    </Statistic>
                  </Statistic.Group>

                  <Statistic.Group size="tiny" style={{ margin: 0 }}>
                    <Statistic color="green">
                      <Statistic.Label>Renewal Value</Statistic.Label>
                      <Statistic.Value>
                        {valueResult} {valuePercentageResult}%
                      </Statistic.Value>
                    </Statistic>
                  </Statistic.Group>
                </Flex>
              </Segment>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}
export default ListViewEssentials({
  sdk: renewalsDashboardApi,
  limit: null
})(LoginRequired(RenewalsDashboard));
