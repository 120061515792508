import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import { formatDate } from 'react-day-picker/moment';

import 'react-day-picker/lib/style.css';

import 'moment/locale/en-ie';

import { getValueDateAsObj } from './utils';

import YearMonthForm from './YearMonthForm';

const DATE_FORMAT = 'DD/MM/YYYY';
const BACKEND_FORMAT = 'YYYY-MM-DD';

class DatePicker extends React.Component {
  state = {
    month: new Date()
  };

  handleYearMonthChange = month => {
    // month is a Date object
    this.setState({ month });
  };

  render() {
    const {
      onChange,
      value,
      inputProps = {},
      dayPickerProps = {} // selectedDays, disabledDays
    } = this.props;

    const valueAsDateObj = getValueDateAsObj(value);

    return (
      <DayPickerInput
        clickUnselectsDay={true}
        placeholder="DD/MM/YYYY"
        onDayChange={(selectedDay, _modifiers, dayPickerInput) => {
          if (selectedDay) {
            const formatSelectedDay = moment(selectedDay).format(
              BACKEND_FORMAT
            );
            onChange(formatSelectedDay);
            return;
          }
          let inputValue = dayPickerInput.getInput().value;

          const momentDate = moment(inputValue, DATE_FORMAT, true);
          if (momentDate.isValid()) {
            inputValue = momentDate.format(BACKEND_FORMAT);
            // update the daypicker(calendar)
            this.handleYearMonthChange(getValueDateAsObj(inputValue));
            // save to formik
            return onChange(inputValue);
          } else if (inputValue === '') {
            return onChange('');
          }
        }}
        value={!_.isEmpty(value) ? formatDate(value, 'L', 'en-ie') : ''}
        dayPickerProps={{
          selectedDays: valueAsDateObj,
          // month is a Date object with month and year which are displayed in the calendar
          month: this.state.month,
          // first day to be Monday
          firstDayOfWeek: 1,
          // open calendar with initial month,
          initialMonth:
            value && !_.isNil(valueAsDateObj) ? valueAsDateObj : new Date(),
          captionElement: ({ date, localeUtils }) => (
            <YearMonthForm
              // date is not equal to field.value
              // date contains selected month and year for the calendar
              date={date}
              localeUtils={localeUtils}
              onChange={this.handleYearMonthChange}
            />
          ),
          ...dayPickerProps
        }}
        inputProps={inputProps}
      />
    );
  }
}
export default DatePicker;
