import React from 'react';

import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';
import { SubscriptionDetail } from 'pages';

import { formatDateTime } from 'utils';

import { Admin, TableFooter } from 'components';

const SubscriptionTable = ({ items, pagination }) => (
  <Table celled selectable compact>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Subscription</Table.HeaderCell>
        <Table.HeaderCell>Create by</Table.HeaderCell>
        <Table.HeaderCell>Create time</Table.HeaderCell>
        <Table.HeaderCell>Update by</Table.HeaderCell>
        <Table.HeaderCell>Update time</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {items.map(item => (
        <Table.Row key={item.id}>
          <Table.Cell>
            <Link
              to={pageUrl(SubscriptionDetail, {
                subscriptionId: item.id
              })}>
              {item.title}
            </Link>
          </Table.Cell>
          <Table.Cell width={3}>
            <Admin value={item.created_by} />
          </Table.Cell>
          <Table.Cell width={2}>{formatDateTime(item.created_at)}</Table.Cell>
          <Table.Cell width={3}>
            <Admin value={item.updated_by} />
          </Table.Cell>
          <Table.Cell width={2}>{formatDateTime(item.updated_at)}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default SubscriptionTable;
