import React from 'react';

export const DisableableElement = ({ disabled, children }) => {
  return !disabled ? (
    children
  ) : (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 2
        }}
      />
      <div style={{ opacity: '.70' }}>{children}</div>
    </div>
  );
};

export default DisableableElement;
