import React from 'react';
import _ from 'lodash';

import { Link } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { PriceSerieUpdate } from 'pages';

import { formatDateForApi } from 'utils';

export const getPriceDataRepresentation = (
  priceData,
  date = null,
  priceId,
  productSlug
) => {
  const EMPTY_DATA_VALUE = '';

  if (_.isNil(priceData)) {
    return EMPTY_DATA_VALUE;
  }

  const isRange = !_.isNil(priceData.value_1) && !_.isNil(priceData.value_2);
  const isSingle = !isRange && !_.isNil(priceData.value_1);

  let dataRepresentation = EMPTY_DATA_VALUE;

  if (isRange) {
    dataRepresentation = `${priceData.value_1} | ${priceData.value_2}`;
  }

  if (isSingle) {
    dataRepresentation = priceData.value_1;
  }

  if (dataRepresentation !== EMPTY_DATA_VALUE) {
    dataRepresentation = (
      <Link
        to={{
          pathname: pageUrl(PriceSerieUpdate, { productSlug }),
          search: `?id=${priceId}&date=${formatDateForApi(date)}`
        }}>
        {dataRepresentation}
      </Link>
    );
  }

  return dataRepresentation;
};

export const getDatePriceArray = (price, dates, productSlug) =>
  dates.map(date =>
    getPriceDataRepresentation(
      _.find(price.price_data, priceData => priceData.date === date),
      date,
      price.id,
      productSlug
    )
  );
