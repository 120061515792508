import React from 'react';
import _ from 'lodash';

import { withRouter } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { MailshotCreate, MailshotGroupList } from 'pages';

import {
  Page,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Button,
  Flex,
  NoDataMessage,
  Filters as SharedFilters
} from 'components';

import { PERMISSIONS, PermissionsRequired, VisibleIf } from 'permissions';

import { MailshotTable } from './components';
import { FILTERS } from './utils';

import { mailshotList } from './sdk';

const MailshotList = ({
  user,
  data,
  count,
  page,
  limit,
  filters,
  sort,
  filterBy,
  orderBy,
  clear,
  changePage,
  changeRowsPerPage,
  history
}) => {
  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content>
          <Flex spaceBetween>
            <Header as="h1">Mailshots</Header>
            <Flex>
              <Button
                color="grey"
                size="large"
                onClick={() => history.push(pageUrl(MailshotGroupList))}>
                View Mailshot Groups
              </Button>
              <VisibleIf
                user={user}
                permissions={[PERMISSIONS.CREATE_MAILSHOTS]}>
                <Button
                  primary
                  size="large"
                  onClick={() => history.push(pageUrl(MailshotCreate))}>
                  Create Mailshot
                </Button>
              </VisibleIf>
            </Flex>
          </Flex>
          {data && (
            <>
              <SharedFilters
                filters={filters}
                clearFilters={clear}
                filterBy={filterBy}
                layout={FILTERS}
                count={count}
              />
              {_.isEmpty(data) && <NoDataMessage />}
              {!_.isEmpty(data) && (
                <MailshotTable
                  mailshots={data}
                  sort={sort}
                  orderBy={orderBy}
                  pagination={
                    <Pagination
                      limit={limit}
                      count={count}
                      page={page}
                      changePage={changePage}
                      changeRowsPerPage={changeRowsPerPage}
                    />
                  }
                />
              )}
            </>
          )}
        </Page.Content>
      </Page.Body>
    </Page>
  );
};

export default withRouter(
  ListViewEssentials({
    sdk: mailshotList
  })(
    LoginRequired(
      PermissionsRequired([PERMISSIONS.VIEW_MAILSHOTS])(
        withRouter(MailshotList)
      )
    )
  )
);
