import React from 'react';

import _ from 'lodash';

import { Dashboard } from 'pages';
import { pageUrl } from 'config/routes';
import { me } from 'sdk';
import { Flex } from 'components';

import { LoginForm } from './components';

import styles from './styles.module.css';

class Login extends React.Component {
  state = {
    isFetching: true
  };

  componentDidMount() {
    this.checkIfLoggedIn();
  }

  checkIfLoggedIn = async () => {
    const { data } = await me();

    if (!_.isEmpty(data)) {
      return this.redirectToDashboard();
    }

    this.setState({ isFetching: false });
  };

  redirectToDashboard = () => this.props.history.push(pageUrl(Dashboard));

  render() {
    const { isFetching } = this.state;

    return (
      <Flex>
        {!isFetching && (
          <div className={styles.LoginWrapper}>
            <h2 className={styles.title}>Welcome to Kallanish Admin Portal!</h2>
            <LoginForm />
          </div>
        )}
      </Flex>
    );
  }
}

export default Login;
