import React from 'react';

import { withRouter } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  FormRow,
  DateField,
  InputField,
  FileField,
  RichTextAreaField,
  UpdateButtonInRow,
  UnsavedFormPrompt
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { chinaSteelReportUpdate } from './sdk';

import { chinaSteelReportUpdateSchema } from './schema';

import { prepareInitialValues } from './utils';

import styles from './styles.module.css';

class ChinaSteelReportDetail extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;
    const { chinaSteelReport, fetchChinaSteelReport } = this.props;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await chinaSteelReportUpdate(
      chinaSteelReport.id,
      data
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('China steel report updated.');
      fetchChinaSteelReport();
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const { chinaSteelReport } = this.props;

    return (
      <Formik
        enableReinitialize={true}
        initialValues={prepareInitialValues(chinaSteelReport)}
        validationSchema={chinaSteelReportUpdateSchema}
        onSubmit={this.handleSubmit}>
        {({ handleSubmit, isSubmitting, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty} />
            <FormRow widths="equal">
              <Field
                required
                name="issueDate"
                component={DateField}
                label="Issue date"
              />

              <Field
                required
                name="issueNumber"
                component={InputField}
                label="Issue number"
              />

              <Field
                required
                name="title"
                component={InputField}
                label="Title"
              />
            </FormRow>

            <Field
              required
              name="cover"
              width={4}
              component={FileField}
              label="Cover Image"
            />

            <Field
              required
              name="lead"
              component={RichTextAreaField}
              label="Lead"
            />

            <Field
              required
              name="text"
              component={RichTextAreaField}
              label="Text"
            />

            <FormRow widths="equal" className={styles.filesRow}>
              <Field required name="pdf" component={FileField} label="PDF" />

              <Field required name="xls" component={FileField} label="Xls" />

              <Field
                required
                name="samplePdf"
                component={FileField}
                label="Sample pdf"
              />

              <Field
                required
                name="sampleXls"
                component={FileField}
                label="Sample xls"
              />
            </FormRow>

            <FormRow>
              <Field
                width={4}
                name="createdBy"
                component={InputField}
                label="Created by"
                disabled
              />

              <Field
                width={4}
                name="createdAt"
                component={DateField}
                label="Created at"
                disabled
              />
            </FormRow>
            <FormRow>
              <Field
                width={4}
                name="updatedBy"
                component={InputField}
                label="Updated by"
                disabled
              />

              <Field
                width={4}
                name="updatedAt"
                component={DateField}
                label="Updated at"
                disabled
              />
            </FormRow>
            <UpdateButtonInRow
              subject="Report"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(ChinaSteelReportDetail);
