import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const courseUpdate = async (courseId, data) => {
  const url = `${BASE_URL}/courses/${courseId}/update/`;

  return await requestSdk(() => post(url, data));
};

export const coursePublish = async courseId => {
  const url = `${BASE_URL}/courses/${courseId}/publish/`;

  return await requestSdk(() => post(url));
};

export const courseUnpublish = async courseId => {
  const url = `${BASE_URL}/courses/${courseId}/unpublish/`;

  return await requestSdk(() => post(url));
};

export const downloadCheckInCsvUrl = courseId =>
  `${BASE_URL}/courses/dashboard/${courseId}/download-check-in/`;

export const downloadAttendeeListCsvUrl = courseId =>
  `${BASE_URL}/courses/dashboard/${courseId}/download-attendee-list/`;

export const courseDelete = async sdkArgs => {
  const { itemId } = sdkArgs;
  const url = `${BASE_URL}/courses/${itemId}/delete/`;

  return await requestSdk(() => post(url));
};
