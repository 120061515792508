import * as yup from 'yup';
export const SendingInstanceSchema = yup.object().shape({
  datetime: yup
    .string()
    .nullable()
    .required('Date to go out is required.'),
  pdf: yup
    .string()
    .nullable()
    .required('PDF is required.'),
  samplePdf: yup
    .string()
    .nullable()
    .required('Sample PDF image is required.')
});
