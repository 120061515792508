import { post, requestSdk } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const clientUpdateSubscriptionSelectedPrices = async (
  clientId,
  clientItemId,
  data
) => {
  const url = `${BASE_URL}/crm/clients/${clientId}/products/update/subscription/${clientItemId}/change-selected-prices/`;

  return await requestSdk(() => post(url, data));
};
