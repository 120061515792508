import React from 'react';

import { withRouter } from 'react-router-dom';

import _ from 'lodash';

import { Header, Segment } from 'semantic-ui-react';

import {
  Page,
  LoginRequired,
  Error,
  Meta,
  ContentEditionWarning
} from 'components';

import { EDITION_WARNING_CONTENT_TYPES } from 'domainConstants';

import {
  courseDetail,
  currencyList,
  courseUpdateMeta,
  promoCodeList
} from './sdk';

import {
  CourseUpdateForm,
  Presales,
  PromoCodes,
  Blocks,
  Agendas
} from './components';

class CourseDetail extends React.Component {
  state = {
    errors: null,
    course: null,
    currencies: null,
    promocodes: null
  };
  fetchCourse = async () => {
    const { courseId } = this.props.match.params;

    const { data, errors, success } = await courseDetail(courseId);

    if (success) {
      this.setState({ course: data });
    } else {
      this.setState({ errors });
    }
  };

  fetchCurrencies = async () => {
    const { success, data } = await currencyList();

    if (success) {
      this.setState({ currencies: data });
    }
  };

  fetchPromoCodes = async () => {
    const { data, errors, success } = await promoCodeList(
      this.state.course.item.id
    );
    if (success) {
      this.setState({ promocodes: data });
    } else {
      this.setState({ errors });
    }
  };

  async componentDidMount() {
    await this.fetchCourse();
    this.fetchCurrencies();
    this.fetchPromoCodes();
  }

  render() {
    const { user, crumbs } = this.props;
    const { course, currencies, promocodes, errors } = this.state;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            {!_.isNil(course) && (
              <ContentEditionWarning
                user={user}
                contentType={EDITION_WARNING_CONTENT_TYPES.COURSE}
                contentId={course.id}
              />
            )}

            <Header as="h1">Edit Event</Header>
            {errors &&
              errors.map((error, index) => (
                <Error key={index}>{error.message}</Error>
              ))}

            {course && currencies && promocodes && (
              <>
                <Segment>
                  <CourseUpdateForm
                    course={course}
                    currencies={currencies}
                    fetchCourse={this.fetchCourse}
                    user={user}
                  />
                </Segment>
                <Presales
                  course={course}
                  currencies={currencies}
                  fetchCourse={this.fetchCourse}
                />
                <PromoCodes
                  course={course}
                  promocodes={promocodes}
                  fetchPromoCodes={this.fetchPromoCodes}
                />

                <Blocks course={course} fetchCourse={this.fetchCourse} />

                <Agendas course={course} fetchCourse={this.fetchCourse} />

                <Meta
                  object={course}
                  fetchObject={this.fetchCourse}
                  sdk={courseUpdateMeta}
                />
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(withRouter(CourseDetail));
