import React from 'react';

import { Link, useParams } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { PriceSerieDetail, PriceSerieUpdate } from 'pages';

import { formatDate } from 'utils';

function PriceSeriesMovementTable({ priceSeries }) {
  const { productSlug } = useParams();

  const getPriceSeriesDetailUrl = priceSeriesId =>
    pageUrl(PriceSerieDetail, { productSlug, priceSeriesId });

  const getPriceSeriesUpdateUrl = priceSeries => {
    return {
      pathname: pageUrl(PriceSerieUpdate, { productSlug }),
      search: `?id=${priceSeries.id}&date=${priceSeries.last_approval_date}`
    };
  };

  return (
    <Table celled selectable compact={true}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Latest Update</Table.HeaderCell>
          <Table.HeaderCell>Product Group</Table.HeaderCell>
          <Table.HeaderCell>Product</Table.HeaderCell>
          <Table.HeaderCell>Frequency</Table.HeaderCell>
          <Table.HeaderCell>Latest Price</Table.HeaderCell>
          <Table.HeaderCell>Change</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {priceSeries.map(price => (
          <Table.Row key={price.id}>
            <Table.Cell>{formatDate(price.last_approval_date)}</Table.Cell>
            <Table.Cell>{price.group}</Table.Cell>
            <Table.Cell>
              <Link to={getPriceSeriesDetailUrl(price.id)}>
                {price.product}
              </Link>
            </Table.Cell>
            <Table.Cell>{price.frequency}</Table.Cell>
            <Table.Cell>
              <Link to={getPriceSeriesUpdateUrl(price)}>
                {price.latest_value} {price.currency}/{price.unit}
              </Link>
            </Table.Cell>
            <Table.Cell>{price.latest_change_percentage}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

export default PriceSeriesMovementTable;
