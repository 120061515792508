import React from 'react';

import { Formik } from 'formik';

import { Form } from 'semantic-ui-react';

import { Field, TextAreaField, UnsavedFormPrompt } from 'components';

import { Button } from 'semantic-ui-react';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { invoiceCreateInvoiceComment } from './sdk';

import { invoiceCommentSchema } from './utils';

class InvoiceCommentForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { invoice, fetchInvoice } = this.props;

    const { setSubmitting, setFieldError, resetForm } = actions;

    setSubmitting(true);
    const { success, errors } = await invoiceCreateInvoiceComment(
      invoice.id,
      prepareFormData(values)
    );
    setSubmitting(false);

    if (success) {
      notifySuccess('Invoice comment added.');
      resetForm();
      fetchInvoice();
    }

    if (!success) {
      handleFormErrors(errors, setFieldError);
    }
  };

  render() {
    const initialValues = {
      comment: ''
    };

    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        validationSchema={invoiceCommentSchema}
        enableReinitialize={true}>
        {({ values, handleSubmit, isSubmitting, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty} formName="Comment form" />
            <Field
              required
              name="comment"
              component={TextAreaField}
              label="Create comment"
            />

            <Button
              color="green"
              onClick={handleSubmit}
              type="submit"
              disabled={isSubmitting}>
              Create
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
}

export default InvoiceCommentForm;
