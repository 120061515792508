import React from 'react';

import { Header, Segment } from 'semantic-ui-react';

import { Page, LoginRequired } from 'components';

import { PermissionsRequired, PERMISSIONS } from 'permissions';

import { pageUrl } from 'config/routes';

import { NotFound } from 'pages';

import { notifyErrors } from 'utils/notifications';

import { ClientCreateForm } from './components';

import {
  registrationAttemptDetail,
  clientCreate,
  createClientWithRegistrationAttempt
} from './sdk';

class ClientCreate extends React.Component {
  render() {
    const { user, crumbs } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Header as="h1">Create client</Header>
              <ClientCreateForm clientCreate={clientCreate} />
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

ClientCreate = LoginRequired(
  PermissionsRequired([PERMISSIONS.CREATE_CLIENTS])(ClientCreate)
);

class ClientCreateFromRegistrationAttempt extends React.Component {
  state = {
    registrationAttempt: null
  };

  componentDidMount() {
    this.fetchRegistrationAttempt();
  }

  fetchRegistrationAttempt = async id => {
    const { params } = this.props.match;

    const { data, errors, success } = await registrationAttemptDetail(
      params.registrationAttemptId
    );

    if (success) {
      if (data.finished) {
        return this.props.history.push(pageUrl(NotFound));
      }

      this.setState({ registrationAttempt: data });
    } else {
      notifyErrors(errors);
    }
  };

  createClient = async data => {
    const { registrationAttempt } = this.state;

    return await createClientWithRegistrationAttempt(
      registrationAttempt.id,
      data
    );
  };

  render() {
    const { user, crumbs } = this.props;

    const { registrationAttempt } = this.state;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Header as="h1">Create client</Header>

              <ClientCreateForm
                registrationAttempt={registrationAttempt}
                clientCreate={data => this.createClient(data)}
              />
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

ClientCreateFromRegistrationAttempt = LoginRequired(
  PermissionsRequired([PERMISSIONS.CREATE_CLIENTS])(
    ClientCreateFromRegistrationAttempt
  )
);

export { ClientCreate, ClientCreateFromRegistrationAttempt };
