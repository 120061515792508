import React from 'react';

import {
  Page,
  Flex,
  Header,
  Pagination,
  LoginRequired,
  ListViewEssentials
} from 'components';

import { EmailTable, Filters } from './components';

import { emailSuppressedList } from './sdk';

class EmailSuppressedList extends React.Component {
  render() {
    const {
      user,
      data,
      count,
      page,
      limit,
      changePage,
      changeRowsPerPage,
      isFetching,
      crumbs,
      filters,
      filterBy,
      clear
    } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content maxWidth crumbs={crumbs}>
            <Flex flexStart>
              <Header>
                Suppressed Emails (Non-existing clients): {count} Total
              </Header>
            </Flex>
            <Filters
              filters={filters}
              count={count}
              filterBy={filterBy}
              clearFilters={clear}
            />
            <EmailTable
              isFetching={isFetching}
              suppressions={data}
              user={user}
              pagination={
                <Pagination
                  limit={limit}
                  count={count}
                  page={page}
                  changePage={changePage}
                  changeRowsPerPage={changeRowsPerPage}
                />
              }
            />
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: emailSuppressedList
})(LoginRequired(EmailSuppressedList));
