import React from 'react';

import { withRouter, Link } from 'react-router-dom';

import { Table } from 'semantic-ui-react';

import { TableFooter, YesNo, Admin, SortableHeader } from 'components';

import { formatDateTime } from 'utils';

import { pageUrl } from 'config/routes';

import { MarketDataReportDetail } from 'pages';

const MarketDataReportTable = ({
  reports,
  sort,
  orderBy,
  history,
  pagination
}) => (
  <Table celled selectable compact={true}>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell singleLine style={{ fontWeight: '400' }} colspan="8">
          {pagination}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <SortableHeader sort={sort} orderBy={orderBy} field="title">
          Title
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="is_published">
          Is Published
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="is_steel_report">
          Is Steel Report
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="is_custom_report">
          Is Custom Report
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="created_by">
          Created by
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="created_at">
          Created at
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="updated_by">
          Updated by
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="updated_at">
          Updated at
        </SortableHeader>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {reports.map(report => (
        <Table.Row key={report.id}>
          <Table.Cell>
            <Link
              to={pageUrl(MarketDataReportDetail, {
                reportId: report.id
              })}>
              {report.title}
            </Link>
          </Table.Cell>
          <Table.Cell>
            <YesNo value={report.is_published} />
          </Table.Cell>
          <Table.Cell>
            <YesNo value={report.is_steel_report} />
          </Table.Cell>
          <Table.Cell>
            <YesNo value={report.is_custom_report} />
          </Table.Cell>
          <Table.Cell>
            <Admin value={report.created_by} />
          </Table.Cell>
          <Table.Cell width={2}>{formatDateTime(report.created_at)}</Table.Cell>
          <Table.Cell>
            <Admin value={report.updated_by} />
          </Table.Cell>
          <Table.Cell width={2}>{formatDateTime(report.updated_at)}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default withRouter(MarketDataReportTable);
