import React from 'react';

import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { PriceSpreadV2Detail, PriceSerieDetail } from 'pages';

import { YesNo, SortableHeader, TableFooter, ExternalLink } from 'components';
import { PRICE_SPREAD_V2_DETAILS_URL } from 'sdk/urls';

const getPriceSpreadUrl = (productSlug, priceSpreadId) =>
  pageUrl(PriceSpreadV2Detail, { productSlug, priceSpreadId });

const getPriceSerieUrl = (productSlug, priceSeriesId) =>
  pageUrl(PriceSerieDetail, { productSlug, priceSeriesId });

const PriceSpreadsTable = ({
  priceSpreads,
  productSlug,
  sort,
  orderBy,
  pagination
}) => {
  return (
    <Table celled selectable compact={true}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            singleLine
            style={{ fontWeight: '400' }}
            colspan="7">
            {pagination}
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <SortableHeader sort={sort} orderBy={orderBy} field="id">
            ID
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="title">
            Title
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="display_as">
            Display as
          </SortableHeader>
          <Table.HeaderCell>Show on homepage</Table.HeaderCell>
          <SortableHeader sort={sort} orderBy={orderBy} field="price_serie_1">
            Price Serie 1
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="price_serie_2">
            Price Serie 2
          </SortableHeader>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {priceSpreads &&
          priceSpreads.map(spread => (
            <Table.Row key={spread.id}>
              <Table.Cell>
                <Link to={getPriceSpreadUrl(productSlug, spread.id)}>
                  {spread.id}
                </Link>
              </Table.Cell>
              <Table.Cell width={3}>{spread.title}</Table.Cell>
              <Table.Cell>{spread.display_as}</Table.Cell>
              <Table.Cell width={1}>
                <YesNo value={spread.active_in_website} />
              </Table.Cell>
              <Table.Cell>
                <Link
                  to={getPriceSerieUrl(productSlug, spread.price_serie_1.id)}>
                  {spread.price_serie_1.title}
                </Link>
              </Table.Cell>
              <Table.Cell>
                <Link
                  to={getPriceSerieUrl(productSlug, spread.price_serie_2.id)}>
                  {spread.price_serie_2.title}
                </Link>
              </Table.Cell>
              <Table.Cell width={2}>
                <ExternalLink
                  url={PRICE_SPREAD_V2_DETAILS_URL(
                    spread.main_group_slug,
                    spread.slug
                  )}>
                  View in website
                </ExternalLink>
              </Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
      <TableFooter>{pagination}</TableFooter>
    </Table>
  );
};

export default PriceSpreadsTable;
