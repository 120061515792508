import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const emailLogStatistic = async (logType, logObjectId) => {
  const url = `${BASE_URL}/emails/logs/${logType}/${logObjectId}/statistic/`;

  return await requestSdk(() => get(url));
};

export const emailLogStatisticLocationLookup = async (logType, logObjectId) => {
  const url = `${BASE_URL}/emails/logs/${logType}/${logObjectId}/location-lookup/`;

  return await requestSdk(() => post(url));
};
