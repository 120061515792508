import React from 'react';

import { withRouter } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  InputField,
  FormRow,
  UpdateButton,
  UnsavedFormPrompt,
  Flex,
  CheckboxField,
  CreatedUpdatedFormValues
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { glossaryCategoryUpdateSchema } from './utils';
import { glossaryCategoryUpdate } from './sdk';

class GlossaryCategoryUpdateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { glossaryCategory } = this.props;

    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await glossaryCategoryUpdate(
      glossaryCategory.id,
      data
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Glossary category updated.');
      this.props.fetchGlossaryCategory();
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  render() {
    const { glossaryCategory } = this.props;

    const initialValues = {
      title: glossaryCategory.title,
      isActive: glossaryCategory.is_active
    };

    const {
      created_at: createdAt,
      created_by: createdBy,
      updated_at: updatedAt,
      updated_by: updatedBy
    } = glossaryCategory;

    const nonFormValues = {
      createdAt,
      createdBy,
      updatedAt,
      updatedBy
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={glossaryCategoryUpdateSchema}
        onSubmit={this.handleSubmit}>
        {({ values, handleSubmit, isSubmitting, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty} />

            <FormRow style={{ alignItems: 'flex-end' }}>
              <Field
                width={6}
                name="title"
                label="Title"
                required
                component={InputField}
              />
              <Field
                width={3}
                name="isActive"
                label="Is Active"
                component={CheckboxField}
              />
              <Flex style={{ alignItems: 'flex-end' }}>
                <UpdateButton onClick={handleSubmit} disabled={isSubmitting} />
              </Flex>
            </FormRow>
            <CreatedUpdatedFormValues values={nonFormValues} />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(GlossaryCategoryUpdateForm);
