import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const corporateMasterAccountAdd = async (clientId, data) => {
  const url = `${BASE_URL}/crm/clients/${clientId}/cm-accounts/add/`;

  return await requestSdk(() => post(url, data));
};

export const clientConvertToCorporateMaster = async clientId => {
  const url = `${BASE_URL}/crm/clients/${clientId}/convert-to-corporate-master/`;

  return await requestSdk(() => post(url));
};
