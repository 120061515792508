import { InputField, UniSelect } from 'components';

export const FEEDBACK_FILTERS = [
  [
    {
      name: 'email',
      emptyValue: '',
      component: InputField,
      label: 'Client Email',
      width: 4
    }
  ],
  [
    {
      name: 'feedback_id',
      emptyValue: '',
      component: InputField,
      label: 'Feedback ID',
      width: 4
    },
    {
      name: 'product',
      emptyValue: '',
      component: UniSelect,
      label: 'Product',
      width: 4,
      props: () => ({
        source: 'feedbackProductsAll'
      })
    },
    {
      name: 'display_option',
      emptyValue: '',
      component: UniSelect,
      label: 'Display Option',
      width: 4,
      props: () => ({
        source: 'feedbackDisplayOptions'
      })
    }
  ]
];
