import React from 'react';
import _ from 'lodash';
import { FastField as Field, TransferListField } from 'components';
import { priceSeriesCategoryList } from './sdk';

class PriceSeriesCategorySelect extends React.Component {
  state = {
    priceSeriesCategories: []
  };

  async componentDidMount() {
    const { isPowerMaterial } = this.props;
    let params = {};

    if (isPowerMaterial) {
      params = { is_power_material_related: isPowerMaterial };
    }
    const { data, success } = await priceSeriesCategoryList(params);

    if (success) {
      this.setState({
        priceSeriesCategories: data.map(priceSeriesCategory => {
          return {
            key: priceSeriesCategory.id,
            value: priceSeriesCategory.id,
            label: priceSeriesCategory.title
          };
        })
      });
    }
  }

  render() {
    const { name, isCustom, ...rest } = this.props;
    const { priceSeriesCategories } = this.state;

    const options = priceSeriesCategories;

    if (!_.isEmpty(options)) {
      rest['options'] = options;
    }

    return (
      <Field
        name={name || 'priceSeriesCategory'}
        component={TransferListField}
        {...rest}
      />
    );
  }
}

export default PriceSeriesCategorySelect;
