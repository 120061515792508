import React from 'react';

import _ from 'lodash';

import { Header, Segment } from 'semantic-ui-react';

import { withRouter } from 'react-router-dom';

import { Page, LoginRequired, DisplayItem, Flex, Admin } from 'components';

import { formatDateTime } from 'utils';

import { sentimentHeatMapDetail } from './sdk';

import { SentimentHeatMapUpdateForm } from './components';

class SentimentHeatMapDetail extends React.Component {
  state = {
    errors: null,
    sentimentHeatMap: null
  };

  componentDidMount() {
    this.fetchSentimentHeatMap();
  }

  fetchSentimentHeatMap = async () => {
    const { data, errors, success } = await sentimentHeatMapDetail();

    if (success) {
      this.setState({ sentimentHeatMap: data });
    } else {
      this.setState({ errors });
    }
  };

  render() {
    const { user } = this.props;

    const { sentimentHeatMap } = this.state;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content>
            <Segment>
              <Header as="h1">Sentiment Heat Map Detail</Header>
              {!_.isNil(sentimentHeatMap) && (
                <>
                  <Flex flexStart>
                    <DisplayItem
                      label="Updated at"
                      value={formatDateTime(sentimentHeatMap.updated_at)}
                    />
                    <DisplayItem
                      label="Updated by"
                      value={<Admin value={sentimentHeatMap.updated_by} />}
                    />
                  </Flex>
                  <SentimentHeatMapUpdateForm
                    data={sentimentHeatMap}
                    fetchSentimentHeatMap={this.fetchSentimentHeatMap}
                  />
                </>
              )}
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(withRouter(SentimentHeatMapDetail));
