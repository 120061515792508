import _ from 'lodash';

import { pageUrl } from 'config/routes';

import { SUBSCRIPTION } from 'domainConstants';
import { ClientDetail, ClientProfileLogList } from 'pages';

export const filterByItemType = (clientItems, type) => {
  return clientItems.filter(clientItem => clientItem.item.item_type === type);
};

export const filterCorporateMasterSubscriptions = (clientItems, clientId) => {
  // Remove subscriptions for which the child does not have availability
  return _.filter(clientItems, clientItem => {
    if (clientItem.item.item_type !== SUBSCRIPTION) {
      return true;
    }

    if (_.isNil(clientItem.available_to_corporate_children)) {
      return true;
    }

    return _.includes(clientItem.available_to_corporate_children, clientId);
  });
};

export const getClientId = client => {
  if (client.client_profile.is_corporate_master_profile) {
    return `CM ${client.id}`;
  }

  if (client.client_profile.corporate_master_account) {
    return `C ${client.id}`;
  }

  return `${client.id}`;
};

export const getClientDetailUrl = clientId => {
  return pageUrl(ClientDetail, { clientId });
};

export const getProfileLogUrl = clientId => {
  return `${pageUrl(ClientProfileLogList)}?client_id=${clientId}`;
};

export const getClientItems = client => {
  const clientItems = _.get(client, 'client_items', []);
  const cmClientItems = _.get(
    client,
    'client_profile.corporate_master_account.client_items',
    []
  );

  return clientItems.concat(cmClientItems);
};

export const getCorporateItems = client => {
  const clientItems = _.get(client, 'client_items', []);
  const cmClientItems = _.get(client, 'user.client_items', []);

  return clientItems.concat(cmClientItems);
};
