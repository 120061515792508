import React from 'react';

import { pageUrl } from 'config/routes';

import { ClientReminderCreate } from 'pages';

import {
  Page,
  Flex,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Button,
  Filters as SharedFilters
} from 'components';

import { VisibleIf, PERMISSIONS, PermissionsRequired } from 'permissions';
import { clientReminderList } from './sdk';

import { ClientReminderTable } from './components';
import { FILTERS } from './utils';

class ClientReminderList extends React.Component {
  createClientReminder = () => {
    const location = pageUrl(ClientReminderCreate);

    this.props.history.push(location);
  };

  render() {
    const {
      user,
      data,
      limit,
      count,
      page,
      changePage,
      changeRowsPerPage,
      filterBy,
      filters,
      sort,
      orderBy,
      clear
    } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content>
            <Flex spaceBetween>
              <Header>Client reminders</Header>
              <VisibleIf user={user} permissions={[PERMISSIONS.CREATE_CLIENTS]}>
                <Button onClick={this.createClientReminder} size="large">
                  Create client reminder
                </Button>
              </VisibleIf>
            </Flex>
            {data && (
              <>
                <SharedFilters
                  filters={filters}
                  clearFilters={clear}
                  filterBy={filterBy}
                  count={count}
                  layout={FILTERS}
                />
                <ClientReminderTable
                  clientReminders={data}
                  sort={sort}
                  orderBy={orderBy}
                  pagination={
                    <Pagination
                      limit={limit}
                      count={count}
                      page={page}
                      changePage={changePage}
                      changeRowsPerPage={changeRowsPerPage}
                    />
                  }
                />
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: clientReminderList
})(
  LoginRequired(
    PermissionsRequired([PERMISSIONS.VIEW_CLIENTS])(ClientReminderList)
  )
);
