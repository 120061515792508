import React from 'react';

import _ from 'lodash';

import { Table } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { ClientDetail } from 'pages';

import { formatDateTime } from 'utils';

import { TableFooter } from 'components';

const TrackingFAQSubmissionsTable = ({ data, pagination, eventType }) => {
  return (
    <Table celled selectable compact={true}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            singleLine
            style={{ fontWeight: '400' }}
            colspan="6">
            {pagination}
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>Client id</Table.HeaderCell>
          <Table.HeaderCell>Names</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Account Manager</Table.HeaderCell>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>Question</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {data.map(log => (
          <Table.Row key={log.id}>
            <Table.Cell width={1}>
              {log.user && (
                <Link
                  to={pageUrl(ClientDetail, {
                    clientId: log.user.id
                  })}>
                  {log.user.id}
                </Link>
              )}
              {!log.user && 'N/A'}
            </Table.Cell>
            <Table.Cell width={2}>
              {_.get(log, 'extra_data.name', 'N/A')}
            </Table.Cell>
            <Table.Cell width={2}>
              {_.get(log, 'extra_data.email', 'N/A')}
            </Table.Cell>
            <Table.Cell width={2}>
              {log.user
                ? `${_.get(log, 'user.account_manager.first_name')} ${_.get(
                    log,
                    'user.account_manager.last_name'
                  )}`
                : 'N/A'}
            </Table.Cell>
            <Table.Cell>{formatDateTime(log.created_at)}</Table.Cell>
            <Table.Cell>{_.get(log, 'extra_data.question')}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <TableFooter>{pagination}</TableFooter>
    </Table>
  );
};

export default TrackingFAQSubmissionsTable;
