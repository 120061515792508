import React from 'react';

import { withRouter } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  FormRow,
  TagSelect,
  YesNoSelect,
  DateField,
  InputField,
  RichTextAreaField,
  VideoSelect,
  CreateButtonInRow,
  UnsavedFormPrompt
} from 'components';

import { handleFormErrors, prepareFormData, getToday } from 'utils/forms';
import { populateSlug } from 'utils';

import { pageUrl } from 'config/routes';
import { VideoGalleryList } from 'pages';
import { videoCreate } from './sdk';
import { videoCreateSchema } from './utils';

class VideoCreateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await videoCreate(data);

    if (success) {
      this.props.history.push(pageUrl(VideoGalleryList));
      return;
    }

    setSubmitting(false);

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const initialValues = {
      dateOfActivation: getToday(),
      videoUrl: '',
      title: '',
      slug: '',
      about: '',
      relatedVideos: [],
      isPublished: false,
      tags: []
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={videoCreateSchema}
        onSubmit={this.handleSubmit}>
        {({ handleSubmit, isSubmitting, setFieldValue, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty && !isSubmitting} />

            <FormRow>
              <Field
                required
                width={7}
                name="title"
                component={InputField}
                label="Title"
                onChange={(e, data) => {
                  populateSlug(data, setFieldValue);
                }}
              />
              <Field
                required
                width={4}
                name="videoUrl"
                component={InputField}
                label="Video URL"
              />

              <Field
                required
                width={4}
                name="slug"
                component={InputField}
                label="Slug"
              />

              <Form.Field width={6}>
                <CreateButtonInRow
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                />
              </Form.Field>
            </FormRow>
            <FormRow>
              <Field
                width={3}
                name="dateOfActivation"
                component={DateField}
                label="Issue date"
              />
            </FormRow>

            <FormRow>
              <handleFormErrors
                name="about"
                component={RichTextAreaField}
                label="About"
              />
            </FormRow>
            <FormRow>
              <Field
                width={10}
                name="relatedVideos"
                component={VideoSelect}
                label="Related Videos"
              />

              <Field width={6} name="tags" label="Tags" component={TagSelect} />
            </FormRow>
            <Field
              width={3}
              name="isPublished"
              component={YesNoSelect}
              label="Is published"
            />

            <CreateButtonInRow onClick={handleSubmit} disabled={isSubmitting} />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(VideoCreateForm);
