import _ from 'lodash';
import React from 'react';

import { Field, SelectField } from 'components';

import { reportList } from './sdk';

class ReportSelect extends React.Component {
  state = {
    options: []
  };

  async componentDidMount() {
    const { data, success } = await reportList();

    if (success) {
      this.setState({
        options: data.map(report => ({
          key: report.id,
          value: report.id,
          label: report.title,
          itemId: report.item_id
        }))
      });
    }
  }

  render() {
    const { name, excludeOptions = [], ...rest } = this.props;
    let options = this.state.options;

    if (!_.isEmpty(excludeOptions)) {
      options = _.filter(
        options,
        option => _.findIndex(excludeOptions, { id: option.itemId }) < 0
      );
    }

    return (
      <Field
        name={name || 'report'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default ReportSelect;
