import React, { useState, useEffect, useCallback } from 'react';

import _ from 'lodash';

import { Header, Segment, Button } from 'semantic-ui-react';

import { useParams, useHistory } from 'react-router-dom';

import {
  Flex,
  Page,
  NewsletterLiveValues,
  Loader,
  ContentEditionWarning
} from 'components';
import { notifyErrors } from 'utils/notifications';

import {
  EDITION_WARNING_CONTENT_TYPES,
  POWER_MATERIAL_SLUG
} from 'domainConstants';

import { DisableableElement, hasPermission, PERMISSIONS } from 'permissions';

import { withLoggedUser, withPermissions } from 'hooks';
import { pageUrl } from 'config/routes';
import { NewsletterPermanentPrices } from 'pages';

import {
  newsletterDetail,
  newsletterContentSectionsList,
  newsletterPricesList,
  rfpArticlesList
} from './sdk';

import {
  ArticleTable,
  Sections,
  SendAndPreview,
  NewsletterUpdateForm,
  DownloadPdfForm
} from './components';

import { areAllArticlesConnected } from './utils';

import styles from './styles.module.css';

function NewsletterDetail({ user, crumbs }) {
  const [newsletter, setNewsletter] = useState(null);
  const [newsletterContentSections, setNewsletterContentSections] = useState(
    []
  );
  const [newsletterPrices, setNewsletterPrices] = useState([]);
  const [rfpArticles, setRfpArticles] = useState([]);

  const { newsletterId, productSlug } = useParams();
  const history = useHistory();

  const fetchRfpArticles = useCallback(
    async newsletterDate => {
      const { data, success } = await rfpArticlesList(
        productSlug,
        newsletterDate
      );

      if (success) {
        setRfpArticles(data);
      }
    },
    [productSlug]
  );

  const fetchNewsletter = useCallback(async () => {
    const { data, errors, success } = await newsletterDetail(newsletterId);

    if (success) {
      setNewsletter(data);
      fetchRfpArticles(data.datetime);
    } else {
      notifyErrors(errors);
    }
  }, [newsletterId, fetchRfpArticles]);

  const fetchNewsletterContentSections = useCallback(async () => {
    const { data, success } = await newsletterContentSectionsList(newsletterId);

    if (success) {
      setNewsletterContentSections(data);
    }
  }, [newsletterId]);

  const fetchNewsletterPrices = useCallback(async () => {
    const { data, errors, success } = await newsletterPricesList(
      newsletterId,
      productSlug === POWER_MATERIAL_SLUG ? 'pm-issue-prices' : 'issue-prices'
    );

    if (success) {
      setNewsletterPrices(data);
    } else {
      notifyErrors(errors);
    }
  }, [newsletterId, productSlug]);

  useEffect(() => {
    fetchNewsletter();
    fetchNewsletterContentSections();
    fetchNewsletterPrices();
  }, [fetchNewsletter, fetchNewsletterContentSections, fetchNewsletterPrices]);

  const openPermanentPrices = () => {
    history.push(pageUrl(NewsletterPermanentPrices, { productSlug }));
  };

  const isLive = _.get(newsletter, 'live', false);
  const sendingStatus = _.get(newsletter, 'sending_status', '');
  const unpublishedPriceSeries = _.get(
    newsletter,
    'unpublished_price_series',
    ''
  );
  const hasArticlesWithoutSection = areAllArticlesConnected(
    rfpArticles,
    newsletterContentSections
  );

  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content maxWidth crumbs={crumbs}>
          {!_.isNil(newsletter) && (
            <ContentEditionWarning
              user={user}
              contentType={
                productSlug === 'steel'
                  ? EDITION_WARNING_CONTENT_TYPES.DAILY_STEEL_NEWSLETTER
                  : EDITION_WARNING_CONTENT_TYPES.DAILY_POWER_MATERIALS_NEWSLETTER
              }
              contentId={newsletter.id}
            />
          )}

          <Flex spaceBetween>
            <Header as="h1">Edit daily newsletter</Header>
            <Flex>
              {productSlug !== POWER_MATERIAL_SLUG && (
                <Button onClick={openPermanentPrices}>
                  View Permanent Prices
                </Button>
              )}
              <Segment>
                <p>
                  Sending status: <strong>{sendingStatus}</strong>
                </p>
              </Segment>
            </Flex>
          </Flex>

          {!(newsletter && newsletterContentSections) && (
            <Segment>
              <Loader />
            </Segment>
          )}

          {newsletter && newsletterContentSections && (
            <DisableableElement
              disabled={!hasPermission(user, [PERMISSIONS.CHANGE_NEWSLETTERS])}>
              <div className={styles.Split}>
                <div>
                  {isLive && <NewsletterLiveValues newsletter={newsletter} />}
                  {!isLive && (
                    <NewsletterUpdateForm
                      hasArticlesWithoutSection={hasArticlesWithoutSection}
                      newsletter={newsletter}
                      fetchNewsletter={fetchNewsletter}
                      fetchNewsletterPrices={fetchNewsletterPrices}
                    />
                  )}
                  <SendAndPreview newsletter={newsletter} user={user} />
                  <DownloadPdfForm newsletter={newsletter} />
                </div>
                <div>
                  {!isLive && (
                    <>
                      {!_.isEmpty(unpublishedPriceSeries) && (
                        <Segment color="red" inverted>
                          The following price series: '{unpublishedPriceSeries}'
                          are not published.
                        </Segment>
                      )}

                      <ArticleTable
                        newsletterContentSections={newsletterContentSections}
                        newsletter={newsletter}
                        productSlug={productSlug}
                        fetchNewsletterContentSections={
                          fetchNewsletterContentSections
                        }
                        rfpArticles={rfpArticles}
                        hasArticlesWithoutSection={hasArticlesWithoutSection}
                      />
                    </>
                  )}
                  <Sections
                    newsletter={newsletter}
                    newsletterContentSections={newsletterContentSections}
                    newsletterPrices={newsletterPrices}
                    fetchNewsletterContentSections={
                      fetchNewsletterContentSections
                    }
                    fetchNewsletterPrices={fetchNewsletterPrices}
                    productSlug={productSlug}
                  />
                </div>
              </div>
            </DisableableElement>
          )}
        </Page.Content>
      </Page.Body>
    </Page>
  );
}

export default withLoggedUser(
  withPermissions([PERMISSIONS.VIEW_NEWSLETTERS])(NewsletterDetail)
);
