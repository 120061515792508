import _ from 'lodash';
import {
  DateField,
  InputField,
  MonthSelect,
  YearSelect,
  EventSelect,
  MarketDataReportRelatedProductSelect,
  CountrySelect,
  AdminSelect,
  RegionSelect,
  YesNoSelect
} from 'components';
import {
  CommentsTable,
  FeedbackTable,
  TrackingEventsTable,
  TrackingSubscriptionEventsTable,
  TrackingUnsubscriptionEventsTable,
  TrackingFAQSubmissionsTable
} from './components';

import { EVENT_TYPES } from './constants';

const COMMENTS_FILTERS = [
  [
    {
      name: 'created_by_id',
      emptyValue: '',
      component: InputField,
      label: 'Client ID',
      width: 2
    },
    {
      name: 'date_from',
      emptyValue: null,
      component: DateField,
      label: 'Date from',
      width: 4
    },
    {
      name: 'date_to',
      emptyValue: null,
      component: DateField,
      label: 'Date to',
      width: 4
    }
  ],
  [
    {
      name: 'article_id',
      emptyValue: '',
      component: InputField,
      label: 'Article ID',
      width: 3
    },
    {
      name: 'power_material_article_id',
      emptyValue: '',
      component: InputField,
      label: 'Power Material Article ID',
      width: 3
    }
  ],
  [
    {
      name: 'first_name',
      emptyValue: '',
      component: InputField,
      label: 'First Name',
      width: 4
    },
    {
      name: 'last_name',
      emptyValue: '',
      component: InputField,
      label: 'Last Name',
      width: 4
    },
    {
      name: 'company_name',
      emptyValue: '',
      component: InputField,
      label: 'Company Name',
      width: 4
    }
  ],
  [
    {
      name: 'profile_country',
      emptyValue: null,
      component: CountrySelect,
      label: 'Country',
      width: 4
    },
    {
      name: 'profile_region',
      emptyValue: null,
      component: RegionSelect,
      label: 'Region',
      width: 4
    },
    {
      name: 'account_manager',
      emptyValue: null,
      component: AdminSelect,
      label: 'Account Manager',
      width: 4
    }
  ]
];

const SHARED_FILTERS = [
  [
    {
      name: 'user_id',
      emptyValue: '',
      component: InputField,
      label: 'Client ID',
      width: 6
    },
    {
      name: 'month',
      emptyValue: null,
      fieldComponent: MonthSelect,
      label: 'Month',
      width: 4,
      transform: value => parseInt(value, 10)
    },
    {
      name: 'year',
      emptyValue: null,
      fieldComponent: YearSelect,
      label: 'Year',
      width: 4,
      transform: value => parseInt(value, 10),
      props: () => ({
        customOptions: _.range(
          new Date().getFullYear(),
          new Date().getFullYear() + 10
        )
      })
    }
  ],
  [
    {
      name: 'first_name',
      emptyValue: '',
      component: InputField,
      label: 'First Name',
      width: 4
    },
    {
      name: 'last_name',
      emptyValue: '',
      component: InputField,
      label: 'Last Name',
      width: 4
    },
    {
      name: 'company_name',
      emptyValue: '',
      component: InputField,
      label: 'Company Name',
      width: 4
    }
  ],
  [
    {
      name: 'profile_country',
      emptyValue: null,
      component: CountrySelect,
      label: 'Country',
      width: 4
    },
    {
      name: 'profile_region',
      emptyValue: null,
      component: RegionSelect,
      label: 'Region',
      width: 4
    },
    {
      name: 'account_manager',
      emptyValue: null,
      component: AdminSelect,
      label: 'Account Manager',
      width: 4
    }
  ]
];

const COURSES_FILTERS = [
  ...SHARED_FILTERS,
  [
    {
      name: 'related_obj_id',
      emptyValue: null,
      fieldComponent: EventSelect,
      label: 'Event',
      width: 6
    },
    {
      name: 'guest',
      emptyValue: null,
      fieldComponent: YesNoSelect,
      label: 'Guest registrations',
      width: 4
    }
  ]
];

const REPORTS_FILTERS = [
  ...SHARED_FILTERS,
  [
    {
      name: 'related_obj_id',
      emptyValue: null,
      fieldComponent: MarketDataReportRelatedProductSelect,
      label: 'Report',
      width: 6
    }
  ]
];

const FEEDBACK_FILTERS = [
  [
    {
      name: 'user_id',
      emptyValue: '',
      component: InputField,
      label: 'Client ID',
      width: 6
    },
    {
      name: 'date_from',
      emptyValue: null,
      component: DateField,
      label: 'Published from',
      width: 4
    },
    {
      name: 'date_to',
      emptyValue: null,
      component: DateField,
      label: 'Published to',
      width: 4
    }
  ],
  [
    {
      name: 'first_name',
      emptyValue: '',
      component: InputField,
      label: 'First Name',
      width: 4
    },
    {
      name: 'last_name',
      emptyValue: '',
      component: InputField,
      label: 'Last Name',
      width: 4
    },
    {
      name: 'company_name',
      emptyValue: '',
      component: InputField,
      label: 'Company Name',
      width: 4
    }
  ],
  [
    {
      name: 'profile_country',
      emptyValue: null,
      component: CountrySelect,
      label: 'Country',
      width: 4
    },
    {
      name: 'profile_region',
      emptyValue: null,
      component: RegionSelect,
      label: 'Region',
      width: 4
    },
    {
      name: 'account_manager',
      emptyValue: null,
      component: AdminSelect,
      label: 'Account Manager',
      width: 4
    }
  ],
  [
    {
      name: 'feedback_id',
      emptyValue: '',
      component: InputField,
      label: 'Feedback ID',
      width: 3
    },
    {
      name: 'created_date_from',
      emptyValue: null,
      component: DateField,
      label: 'Created from',
      width: 4
    },
    {
      name: 'created_date_to',
      emptyValue: null,
      component: DateField,
      label: 'Created to',
      width: 4
    }
  ]
];

export const FILTERS = {
  [EVENT_TYPES.comments.slug]: COMMENTS_FILTERS,
  [EVENT_TYPES.feedback.slug]: FEEDBACK_FILTERS,
  [EVENT_TYPES.trial.slug]: SHARED_FILTERS,
  [EVENT_TYPES.unsubscription.slug]: SHARED_FILTERS,
  [EVENT_TYPES.subscription.slug]: SHARED_FILTERS,
  [EVENT_TYPES.marketReports.slug]: REPORTS_FILTERS,
  [EVENT_TYPES.courses.slug]: COURSES_FILTERS,
  [EVENT_TYPES.faqSubmission.slug]: SHARED_FILTERS
};

export const TABLE_COMPONENTS = {
  [EVENT_TYPES.comments.slug]: CommentsTable,
  [EVENT_TYPES.feedback.slug]: FeedbackTable,
  [EVENT_TYPES.trial.slug]: TrackingSubscriptionEventsTable,
  [EVENT_TYPES.unsubscription.slug]: TrackingUnsubscriptionEventsTable,
  [EVENT_TYPES.subscription.slug]: TrackingSubscriptionEventsTable,
  [EVENT_TYPES.marketReports.slug]: TrackingEventsTable,
  [EVENT_TYPES.courses.slug]: TrackingEventsTable,
  [EVENT_TYPES.faqSubmission.slug]: TrackingFAQSubmissionsTable
};
