import React from 'react';
import { Formik } from 'formik';
import { Form, Message } from 'semantic-ui-react';

import { handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';
import { STEEL_SLUG, POWER_MATERIAL_SLUG } from 'domainConstants';

import { FormRow, Button, Flex, UniSelect } from 'components';

import { clientUpdateSubscriptionSelectedPrices } from './sdk';

export default function ManagePriceSeriesModal({
  client,
  clientItem,
  selectedPrices,
  fetchClient
}) {
  const handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    setSubmitting(true);

    const { errors, success } = await clientUpdateSubscriptionSelectedPrices(
      client.id,
      clientItem.id,
      {
        ...values
      }
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Access to prices updated.');
      fetchClient();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  const initialValues = {
    prices: selectedPrices
  };

  return (
    <div>
      <Message data-testid="manage-prices-modal-notice">
        Changing the prices the cilent has access to would also update their
        snapshot.
      </Message>
      <Formik
        enableReinitialize={true}
        onSubmit={handleSubmit}
        initialValues={initialValues}>
        {({ handleSubmit }) => (
          <Form>
            <FormRow>
              <UniSelect
                isMulti
                transferList
                closeMenuOnSelect
                showCount
                name="prices"
                label="Price series"
                source="priceSeries"
                // This is handling the Prices Data Feed (Select) subscription settings,
                // because we should not allow Hydrogen price selection for this sub,
                // and it is the only one that supports price selection at the current moment.
                // If this changes at some point - edit this too and make it `sub -> product(s)` mapping.
                productSlug={`${STEEL_SLUG},${POWER_MATERIAL_SLUG}`}
              />
            </FormRow>
            <FormRow>
              <Flex spaceBetween style={{ width: '100%' }}>
                <Button
                  type="submit"
                  color="green"
                  onClick={handleSubmit}
                  data-testid="manage-prices-modal-submit-btn">
                  Submit
                </Button>
              </Flex>
            </FormRow>
          </Form>
        )}
      </Formik>
    </div>
  );
}
