import React from 'react';
import _ from 'lodash';

import { Formik } from 'formik';

import { Form, Button } from 'semantic-ui-react';

import {
  Field,
  FormRow,
  InputField,
  FullPageRichTextAreaField,
  TextAreaField,
  DateTimeField,
  Flex,
  UpdateButton,
  SelectField
} from 'components';
import { handleFormErrors, prepareFormData } from 'utils/forms';

import { notifySuccess, notifyErrors } from 'utils/notifications';

import {
  instantNewValidationSchema,
  EMAIL_OPTIONS,
  getInitialValues,
  getGeneratedContent,
  checkInstantNewDateIsInPast
} from './utils';

import { createInstantNew, updateInstantNew, changeReadyStatus } from './sdk';

class InstantNewForm extends React.Component {
  state = {
    initialValues: {},
    formRef: React.createRef()
  };

  componentDidMount() {
    const initialValues = getInitialValues(this.props.instantNewsData);

    this.setState({ initialValues });
  }

  componentDidUpdate(prevProps) {
    if (this.props.instantNewsData !== prevProps.instantNewsData) {
      // get current form values because we can't reinitilize form only for X fields
      // initialValues = current values (from_name, send_at,..) + new generated data (html, plain_text, css)
      const newInitialValues = {
        ...this.state.formRef.current.state.values,
        ...getGeneratedContent(this.props.instantNewsData)
      };

      this.setState({ initialValues: newInitialValues });
    }
  }

  handleSubmit = async (values, actions) => {
    if (this.props.instantNew) {
      await this.updateInstantNew(values, actions);
    } else {
      await this.createInstantNew(values, actions);
    }
  };

  createInstantNew = async (values, actions) => {
    const { articleData, newsType } = this.props;

    let data = {
      ...prepareFormData(values),
      ...articleData,
      news_type: newsType
    };

    const { setSubmitting, setFieldError } = actions;

    setSubmitting(true);

    const { errors, success } = await createInstantNew(data);

    setSubmitting(false);

    if (success) {
      notifySuccess('Successfully create news alert.');
      this.props.close();
      this.props.refetch();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  // for existing instant news
  updateInstantNew = async (values, actions) => {
    const { instantNew, newsType } = this.props;

    let data = {
      ...prepareFormData(values),
      news_type: newsType
    };

    const { setSubmitting, setFieldError } = actions;

    setSubmitting(true);

    const { errors, success } = await updateInstantNew(instantNew, data);

    setSubmitting(false);

    if (success) {
      notifySuccess('Successfully update news alert.');
      this.props.close();
      this.props.refetch();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  // for existing instant news
  toggleReadyStatus = async () => {
    const { instantNew, instantNewsData } = this.props;

    const data = {
      is_ready: !instantNewsData.is_ready
    };

    const { success, errors } = await changeReadyStatus(instantNew, data);

    if (success) {
      notifySuccess('Successfully changed ready status.');
      this.props.fetchInstantNew(instantNew.id);
      this.props.refetch();
      return;
    }

    notifyErrors(errors);
  };

  render() {
    const { instantNewsData, instantNew } = this.props;

    const { initialValues } = this.state;
    const isReady = _.get(instantNewsData, 'is_ready', false);

    const isSendDateInPast = checkInstantNewDateIsInPast(instantNewsData);

    return (
      !_.isEmpty(initialValues) && (
        <Formik
          ref={this.state.formRef}
          enableReinitialize={true}
          validationSchema={instantNewValidationSchema}
          onSubmit={this.handleSubmit}
          initialValues={initialValues}>
          {({ values, handleSubmit, isSubmitting }) => (
            <Form>
              <FormRow>
                <Field
                  width={11}
                  required
                  label="Subject"
                  name="subject"
                  component={InputField}
                />
                <Form.Field width={5}>
                  <Flex flexEnd>
                    {instantNew && !isSendDateInPast && (
                      <Button
                        size="large"
                        onClick={this.toggleReadyStatus}
                        disabled={isSubmitting}
                        color={isReady ? 'orange' : 'red'}>
                        Mark as {isReady && 'not'} ready
                      </Button>
                    )}

                    <UpdateButton
                      type="submit"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                      label={instantNew ? 'Update' : 'Create'}
                    />
                  </Flex>
                </Form.Field>
              </FormRow>
              <FormRow>
                <Field
                  width={5}
                  required
                  label="Send at"
                  name="sendAt"
                  component={DateTimeField}
                />
                <Field
                  width={6}
                  required
                  label="From name"
                  name="fromName"
                  component={InputField}
                />
                <Field
                  width={5}
                  name="fromEmail"
                  label="From email:"
                  component={SelectField}
                  options={EMAIL_OPTIONS}
                  required
                />
              </FormRow>
              <FormRow>
                <Field
                  label="HTML"
                  config={{
                    height: '600px'
                  }}
                  name="html"
                  component={FullPageRichTextAreaField}
                />
              </FormRow>
              <FormRow>
                <Field
                  label="Plain text"
                  name="plainText"
                  component={TextAreaField}
                />
              </FormRow>
              <FormRow>
                <Field label="CSS" name="css" component={TextAreaField} />
              </FormRow>
            </Form>
          )}
        </Formik>
      )
    );
  }
}

export default InstantNewForm;
