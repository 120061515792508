import React from 'react';

import _ from 'lodash';

import { Button, Segment } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import {
  MailshotDetail,
  MailshotSelectedClients,
  MailshotGroupDetail
} from 'pages';

import {
  Page,
  Flex,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Error,
  Filters as SharedFilters
} from 'components';

import { clientList, mailshotDetail } from './sdk';

import { ClientTable } from './components';
import { FILTERS, ADVANCED_FILTERS_LAYOUT } from './utils';

class MailshotClients extends React.Component {
  state = {
    mailshot: null,
    errors: null
  };

  goToMailshotEdit = () => {
    const { mailshotId } = this.props.match.params;

    const url = pageUrl(MailshotDetail, { mailshotId });

    this.props.history.push(url);
  };

  async componentDidMount() {
    const { mailshotId } = this.props.match.params;

    const { success, data, errors } = await mailshotDetail(mailshotId);

    if (success) {
      this.setState({ mailshot: data });
    } else {
      this.setState({ errors });
    }
  }

  goToSelectedUsers = () => {
    const { mailshotId } = this.props.match.params;

    const url = pageUrl(MailshotSelectedClients, { mailshotId });

    this.props.history.push(url);
  };

  goToMailshotGroup = groupId => {
    const url = `${pageUrl(MailshotGroupDetail, {
      mailshotGroupId: groupId
    })}`;

    this.props.history.push(url);
  };

  render() {
    const {
      user,
      crumbs,
      data,
      count,
      page,
      limit,
      filters,
      sort,
      filterBy,
      orderBy,
      clear,
      changePage,
      changeRowsPerPage,
      refetch,
      location
    } = this.props;

    const { mailshot, errors } = this.state;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content maxWidth crumbs={crumbs}>
            <Segment>
              <Flex spaceBetween>
                <div>
                  <Flex>
                    <Header
                      as="h2"
                      color="blue"
                      onClick={this.goToMailshotEdit}
                      style={{ margin: '0 10px 0 0', cursor: 'pointer' }}>
                      {_.get(mailshot, 'title', '....')}
                    </Header>
                    <Header as="h2" style={{ margin: 0 }}>
                      Selected Clients
                    </Header>
                  </Flex>
                </div>

                <div>
                  {mailshot && !_.isNull(mailshot.mailshot_group_item) && (
                    <Button
                      basic
                      color="teal"
                      onClick={() =>
                        this.goToMailshotGroup(
                          mailshot.mailshot_group_item.mailshot_group.id
                        )
                      }>
                      Go to group
                    </Button>
                  )}
                  <Button basic color="teal" onClick={this.goToSelectedUsers}>
                    Selected users
                  </Button>
                </div>
              </Flex>
              {errors && (
                <div>
                  {errors.map((error, index) => (
                    <Error key={index}>{error.message}</Error>
                  ))}
                </div>
              )}
            </Segment>
            {data && (
              <>
                <SharedFilters
                  filters={filters}
                  count={count}
                  filterBy={filterBy}
                  clearFilters={clear}
                  layout={FILTERS}
                  advancedLayout={ADVANCED_FILTERS_LAYOUT}
                />
                <ClientTable
                  mailshot={mailshot}
                  clients={data}
                  count={count}
                  filters={filters}
                  sort={sort}
                  orderBy={orderBy}
                  refetch={refetch}
                  location={location}
                  pagination={
                    <Pagination
                      limit={limit}
                      count={count}
                      page={page}
                      changePage={changePage}
                      changeRowsPerPage={changeRowsPerPage}
                    />
                  }
                />
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: props => {
    const { mailshotId } = props.match.params;
    return _.partial(clientList, mailshotId);
  },
  passPropsToSdk: true
})(LoginRequired(MailshotClients));
