import React from 'react';
import _ from 'lodash';
import cx from 'classnames';

import { Table, List, Segment, Checkbox } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import { TableFooter, SortableHeader, Loader, Button } from 'components';

import { PERMISSIONS, VisibleIf } from 'permissions';

import { formatDate, formatDateTime } from 'utils';
import { buildFilters } from 'utils/filters';
import { SUBSCRIPTION } from 'domainConstants';

import {
  filterByItemType,
  getClientId,
  getClientDetailUrl,
  getProfileLogUrl,
  getClientItems,
  filterCorporateMasterSubscriptions
} from 'pages/Client/utils';

import { BulkActionsModal, QuickApproveModal } from './components';
import styles from './styles.module.css';

import { clientIdList } from '../../sdk';

class ClientTable extends React.Component {
  state = {
    selectedClients: [],
    selectAllOnPage: false,
    selectAllGlobal: false,
    clientsAllGlobal: [],
    isOpenBulkActionsModal: false,
    isOpenQuickApproveModal: false
  };

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.search) {
      const prevLocationSearch = prevProps.location.search;
      const currentLocationSearch = location.search;
      const pagePattern = /page=(\d+)/;

      const prevWithoutPage = prevLocationSearch.replace(pagePattern, '');
      const currentWithoutPage = currentLocationSearch.replace(pagePattern, '');

      if (
        prevWithoutPage !== currentWithoutPage &&
        !(prevLocationSearch === '' && currentWithoutPage === '?')
      ) {
        this.setState(prevState => ({
          selectedClients: [],
          selectAllGlobal: false,
          selectAllOnPage: false
        }));
      }
    }
  }

  updateOnSuccess = () => {
    this.setState({
      selectedClients: [],
      selectAllOnPage: false
    });
    this.props.refetch();
  };

  isSelected = clientId => {
    const { selectedClients } = this.state;

    return !_.isUndefined(_.find(selectedClients, id => id === clientId));
  };

  toggleClientSelect = (data, clientId) => {
    const { checked } = data;

    if (checked) {
      this.setState(prevState => {
        return {
          selectedClients: [...prevState.selectedClients, clientId]
        };
      });
    } else {
      this.setState(prevState => {
        return {
          selectedClients: prevState.selectedClients.filter(
            id => id !== clientId
          )
        };
      });
    }
  };

  toggleSelectAllOnPage = data => {
    const { checked } = data;
    const { clients } = this.props;
    let selectedClients = [];

    if (checked) {
      selectedClients = clients.map(client => client.id);
    }

    this.setState(prevState => {
      return {
        selectedClients,
        selectAllOnPage: !prevState.selectAllOnPage
      };
    });
    if (!checked && this.state.selectAllGlobal) {
      this.setState(prevState => ({
        selectAllGlobal: !prevState.selectAllGlobal
      }));
    }
  };

  toggleSelectAllGlobal = async () => {
    if (!this.state.selectAllGlobal) {
      const { data, success } = await clientIdList(
        buildFilters({ ...this.props.filters })
      );

      if (success) {
        this.setState(prevState => ({
          clientsAllGlobal: data
        }));
      }

      const selectedClients = this.state.clientsAllGlobal;

      this.setState(prevState => ({
        selectedClients,
        selectAllGlobal: !prevState.selectAllGlobal,
        selectAllOnPage: !prevState.selectAllOnPage
      }));
    } else {
      this.setState(prevState => ({
        selectedClients: [],
        selectAllGlobal: !prevState.selectAllGlobal,
        selectAllOnPage: !prevState.selectAllOnPage
      }));
    }
  };

  setOpenModalBulkActionsModal = isOpenBulkActionsModal => {
    this.setState({ isOpenBulkActionsModal: isOpenBulkActionsModal });
  };

  setOpenModalQuickApproveModal = isOpenQuickApproveModal => {
    this.setState({ isOpenQuickApproveModal: isOpenQuickApproveModal });
  };

  render() {
    const {
      clients,
      orderBy,
      pagination,
      sort,
      isFetching,
      user,
      count,
      refetch
    } = this.props;

    const { selectedClients, selectAllGlobal } = this.state;

    return (
      <>
        <Button
          onClick={this.toggleSelectAllGlobal}
          style={{
            backgroundColor: selectAllGlobal ? 'red' : 'rgb(36, 214, 163)'
          }}>
          {!selectAllGlobal && `Select all ${count} clients`}
          {selectAllGlobal && `Unselect all ${count} clients`}
        </Button>
        <VisibleIf user={user} permissions={[PERMISSIONS.BULK_CHANGE_CLIENTS]}>
          <BulkActionsModal
            onOpen={() => this.setOpenModalBulkActionsModal(true)}
            onClose={() => this.setOpenModalBulkActionsModal(false)}
            selectedClients={selectedClients}
            updateOnSuccess={this.updateOnSuccess}
            user={user}
          />
        </VisibleIf>
        <Table celled selectable compact={true}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                singleLine
                style={{ fontWeight: '400' }}
                colspan="13">
                {pagination}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>
                <Checkbox
                  onChange={(event, data) => this.toggleSelectAllOnPage(data)}
                  checked={
                    (!_.isNil(clients) &&
                      selectedClients.length === clients.length) ||
                    this.state.selectAllGlobal
                  }
                />
              </Table.HeaderCell>
              <SortableHeader sort={sort} orderBy={orderBy} field="id">
                Id
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="email">
                Email
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="first_name">
                First name
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="last_name">
                Last name
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="company">
                Company
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="job_title">
                Job Title
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="country">
                Country
              </SortableHeader>
              <SortableHeader
                sort={sort}
                orderBy={orderBy}
                field="account_manager">
                Account Manager
              </SortableHeader>
              <Table.HeaderCell width={3}>Subscription status</Table.HeaderCell>
              <Table.HeaderCell>Subscription expiry date</Table.HeaderCell>
              <SortableHeader sort={sort} orderBy={orderBy} field="updated_at">
                Last Updated By
              </SortableHeader>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          {!isFetching && (
            <>
              <Table.Body>
                {clients.map(client => {
                  const subscriptions = filterCorporateMasterSubscriptions(
                    filterByItemType(getClientItems(client), SUBSCRIPTION),
                    client.id
                  );

                  const pendingTrialItems = _.filter(
                    subscriptions,
                    ({ subscription_status }) =>
                      subscription_status.status === 'Pending for Trial'
                  );
                  return (
                    <Table.Row
                      key={client.id}
                      data-testid="client-row"
                      className={cx({
                        [styles.unsubscribedRow]:
                          client.client_profile.unsubscribed
                      })}>
                      <Table.Cell data-testid="client-cell-checkbox">
                        <Checkbox
                          onChange={(event, data) =>
                            this.toggleClientSelect(data, client.id)
                          }
                          checked={this.isSelected(client.id)}
                        />
                      </Table.Cell>
                      <Table.Cell data-testid="client-cell-id" width={1}>
                        <Link to={getClientDetailUrl(client.id)}>
                          {getClientId(client)}
                        </Link>
                      </Table.Cell>
                      <Table.Cell
                        data-testid="client-cell-email"
                        width={3}
                        style={{ wordBreak: 'break-word' }}>
                        {client.email}
                      </Table.Cell>
                      <Table.Cell
                        width={1}
                        data-testid="client-cell-first-name">
                        {client.first_name}
                      </Table.Cell>
                      <Table.Cell width={1} data-testid="client-cell-last-name">
                        {client.last_name}
                      </Table.Cell>
                      <Table.Cell
                        width={1}
                        data-testid="client-cell-company-name">
                        {client.client_profile.company_name}
                      </Table.Cell>
                      <Table.Cell width={1} data-testid="client-cell-job-title">
                        {client.client_profile.job_title}
                      </Table.Cell>
                      <Table.Cell width={1} data-testid="client-cell-country">
                        {client.client_profile.country}
                      </Table.Cell>
                      <Table.Cell
                        width={1}
                        data-testid="client-cell-account-manager">
                        {client.client_profile?.account_manager
                          ? `${client.client_profile.account_manager.first_name} ${client.client_profile.account_manager.last_name}`
                          : undefined}
                      </Table.Cell>
                      <Table.Cell
                        width={2}
                        data-testid="client-cell-subscription-status">
                        <List bulleted>
                          {subscriptions.map((clientItem, index) => {
                            return (
                              <List.Item key={index}>
                                {clientItem.subscription_status.status} /{' '}
                                {clientItem.item.title}
                              </List.Item>
                            );
                          })}
                        </List>
                      </Table.Cell>
                      <Table.Cell
                        width={2}
                        data-testid="client-cell-subscription-expiry-date">
                        <List bulleted>
                          {subscriptions.map((clientItem, index) => {
                            return (
                              <List.Item key={index}>
                                {clientItem.subscription_status.date_end &&
                                  `${formatDate(
                                    clientItem.subscription_status.date_end
                                  )} /`}
                                {clientItem.item.title}
                              </List.Item>
                            );
                          })}
                        </List>
                      </Table.Cell>
                      <Table.Cell
                        width={2}
                        data-testid="client-cell-last-updated-by">
                        <Link to={getProfileLogUrl(client.id)}>
                          {client.last_updated_by}{' '}
                          {formatDateTime(client.last_updated_at)}
                        </Link>
                      </Table.Cell>
                      <Table.Cell width={1} data-testid="client-cell-actions">
                        {pendingTrialItems.length > 0 && (
                          <QuickApproveModal
                            onOpen={() =>
                              this.setOpenModalQuickApproveModal(true)
                            }
                            onClose={() =>
                              this.setOpenModalQuickApproveModal(false)
                            }
                            refetch={refetch}
                            client={client}
                            products={pendingTrialItems}
                          />
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
              <TableFooter>{pagination}</TableFooter>
            </>
          )}
        </Table>
        {isFetching && (
          <Segment>
            <Loader />
          </Segment>
        )}
      </>
    );
  }
}

export default ClientTable;
