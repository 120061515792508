import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const mailshotSelectedClients = async (mailshotId, params) => {
  const url = `${BASE_URL}/emails/mailshots/${mailshotId}/selected-clients/`;

  return await requestSdk(() => get(url, params));
};

export const mailshotDetail = async id => {
  const url = `${BASE_URL}/emails/mailshots/${id}/`;

  return await requestSdk(() => get(url));
};

export const mailshotClearAll = async mailshotId => {
  const url = `${BASE_URL}/emails/mailshots/${mailshotId}/clear-all/`;

  return await requestSdk(() => post(url));
};
