import * as yup from 'yup';

export const bannerUpdateSchema = yup.object().shape({
  title: yup.string().required('Title is required.'),
  image: yup
    .number()
    .nullable()
    .required('Image is required'),
  url: yup.string().kallanishUrl()
});
