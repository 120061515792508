import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const pollChoiceUpdate = async (id, data) => {
  const url = `${BASE_URL}/polls/admin/${id}/update-choice/`;

  return await requestSdk(() => post(url, data));
};

export const pollChoiceRemove = async (id, data) => {
  const url = `${BASE_URL}/polls/admin/${id}/remove-choice/`;

  return await requestSdk(() => post(url, data));
};
