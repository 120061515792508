import _ from 'lodash';

import { transformValuesForMultiselect } from 'utils/filters';
import {
  InputField,
  UpcomingEventSelect,
  PastEventSelect,
  InvoiceStatusSelect,
  SubscriptionSelect,
  SubscriptionStatusSelect,
  MonthSelect,
  YearSelect,
  DateField,
  AdminSelect,
  PaymentReceivedSelect
} from 'components';

export const FILTERS = [
  [
    {
      name: 'invoice_paid_month',
      emptyValue: null,
      fieldComponent: MonthSelect,
      transform: value => parseInt(value, 10),
      label: 'Month'
    },
    {
      name: 'invoice_paid_year',
      emptyValue: null,
      fieldComponent: YearSelect,
      transform: value => parseInt(value, 10),
      label: 'Year',
      props: () => ({
        customOptions: _.range(
          new Date().getFullYear(),
          new Date().getFullYear() + 10
        )
      })
    },

    {
      name: 'invoice_paid_date_from',
      emptyValue: null,
      component: DateField,
      label: 'Start Date'
    },
    {
      name: 'invoice_paid_date_to',
      emptyValue: null,
      component: DateField,
      label: 'End Date'
    }
  ],
  [
    {
      name: 'search',
      emptyValue: '',
      component: InputField,
      label: 'Search',
      placeholder: 'Search...',
      width: 8
    },
    {
      name: 'payment_received',
      emptyValue: null,
      component: PaymentReceivedSelect,
      label: 'Payment Received',
      width: 4
    }
  ],
  [
    {
      name: 'invoice_id',
      emptyValue: '',
      component: InputField,
      label: 'Invoice ID',
      placeholder: 'Search by invoice ID ...',
      width: 4
    },
    {
      name: 'proform_id',
      emptyValue: '',
      component: InputField,
      label: 'Pro-form ID',
      placeholder: 'Search by Pro-form ID ...',
      width: 4
    },
    {
      name: 'client_id',
      emptyValue: '',
      component: InputField,
      label: 'Client ID',
      placeholder: 'Search by Client ID ...',
      width: 4
    },
    {
      name: 'account_manager',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: AdminSelect,
      label: 'Account manager',
      width: 4
    }
  ],
  [
    {
      name: 'total_from_without_vat',
      emptyValue: '',
      component: InputField,
      label: 'Total from',
      type: 'number',
      width: 4
    },
    {
      name: 'total_to_without_vat',
      emptyValue: null,
      component: InputField,
      label: 'Total to',
      type: 'number',
      width: 4
    }
  ],
  [
    {
      name: 'client_name',
      emptyValue: '',
      component: InputField,
      label: 'Client name',
      placeholder: 'Search by client name...',
      width: 6
    },
    {
      name: 'company_name',
      emptyValue: '',
      component: InputField,
      label: 'Company name',
      placeholder: 'Search by company name...',
      width: 6
    }
  ],
  [
    {
      name: 'invoice_status',
      emptyValue: null,
      fieldComponent: InvoiceStatusSelect,
      label: 'Invoice status'
    },
    {
      name: 'upcoming_event',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: UpcomingEventSelect,
      label: 'Upcoming event',
      props: () => ({
        isMulti: true
      })
    },
    {
      name: 'past_event',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: PastEventSelect,
      label: 'Past Event',
      props: () => ({
        isMulti: true
      })
    },
    {
      name: 'subscription',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: SubscriptionSelect,
      label: 'Subscription'
    },
    {
      name: 'subscription_status',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: SubscriptionStatusSelect,
      label: 'Subscription status'
    }
  ]
];

export const getFirstAndLastDayOfMonthAndYear = (month, year) => {
  let date = new Date(parseInt(year), parseInt(month) - 1, 1);

  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  return { firstDay, lastDay };
};
