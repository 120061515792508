import React from 'react';

import _ from 'lodash';

import { Segment } from 'semantic-ui-react';

import { notifyError } from 'utils/notifications';

import { contentEditionInformation } from './sdk';

class ContentEditionWarning extends React.Component {
  state = {
    currentEditor: null
  };

  async componentDidMount() {
    const currentEditor = await this.checkContentInformation();

    if (!_.isNil(currentEditor) && currentEditor.id !== this.props.user.id) {
      notifyError(`${currentEditor.email} is currently editing this item`, {
        autoClose: 7000
      });
    }

    const intervalTime = 1000 * 60 * 2; // 2 minute

    this.pollInterval = setInterval(this.checkContentInformation, intervalTime);
  }

  componentWillUnmount() {
    clearInterval(this.pollInterval);
    this.pollInterval = null;
  }

  checkContentInformation = async () => {
    const { contentType, contentId } = this.props;

    const { data, success } = await contentEditionInformation(
      contentType,
      contentId
    );

    if (success) {
      this.setState({ currentEditor: data });

      return data;
    }

    return null;
  };

  render() {
    const { currentEditor } = this.state;
    const { user } = this.props;

    if (_.isNil(currentEditor) || _.isNil(user)) {
      return null;
    }

    if (currentEditor.id !== user.id) {
      return (
        <Segment color="red" inverted>
          {currentEditor.email} is currently editing this item
        </Segment>
      );
    }

    return null;
  }
}

export default ContentEditionWarning;
