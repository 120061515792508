import _ from 'lodash';
import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { withRouter } from 'react-router-dom';

import { Form, Input, Message } from 'semantic-ui-react';

import { FormError, Button } from 'components';

import { pageUrl } from 'config/routes';

import { Dashboard } from 'pages';

import { buildUrlFromNextRedirect } from 'utils';
import { login } from 'sdk';
import 'utils/yup';

import styles from './styles.module.css';

class LoginForm extends React.Component {
  state = {
    errors: []
  };
  constructor(props) {
    super(props);

    this.validationSchema = yup.object().shape({
      username: yup
        .string()
        .strongEmail('Email is invalid.')
        .required('Email is required.'),
      password: yup.string().required('Password is required.')
    });
  }

  handleForm = async values => {
    const { data, errors } = await login(values);

    if (!_.isEmpty(data)) {
      const { search } = this.props.history.location;
      let nextUrl = pageUrl(Dashboard);

      if (search && !_.isEmpty(search)) {
        nextUrl = buildUrlFromNextRedirect(search);
      }
      this.props.history.push(nextUrl);
    }

    if (!_.isEmpty(errors)) {
      this.setState({ errors });
    }
  };

  render() {
    const { errors } = this.state;

    return (
      <div className={styles.LoginForm}>
        {errors &&
          errors.map((error, key) => (
            <Message key={key} color="red">
              {error.message}
            </Message>
          ))}

        <Formik
          onSubmit={this.handleForm}
          validationSchema={this.validationSchema}
          initialValues={{ username: '', password: '' }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <Form onSubmit={handleSubmit} className={styles.formContainer}>
              <Form.Field>
                <Input
                  name="username"
                  icon="mail"
                  iconPosition="left"
                  placeholder="Email address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username}
                />
                {errors.username && touched.username && (
                  <FormError>{errors.username}</FormError>
                )}
              </Form.Field>
              <Form.Field>
                <Input
                  name="password"
                  type="password"
                  icon="key"
                  iconPosition="left"
                  placeholder="Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                {errors.password && touched.password && (
                  <FormError>{errors.password}</FormError>
                )}
              </Form.Field>
              <Button
                capital
                className={styles.submitButton}
                color="green"
                type="submit">
                Login
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withRouter(LoginForm);
