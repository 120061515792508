import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';
import moment from 'moment';

export const newsletterDetail = async id => {
  const url = `${BASE_URL}/newsletters/v2/${id}/`;

  return await requestSdk(() => get(url));
};

export const newsletterContentSectionsList = async id => {
  const params = {
    slug:
      'selected-articles,top-headlines,featured-articles,product-type-group-articles,region-articles'
  };

  const url = `${BASE_URL}/newsletters/v2/${id}/content-sections/`;

  return await requestSdk(() => get(url, params));
};

export const newsletterPricesList = async (id, priceSectionSlug) => {
  const params = {
    prices_section_slug: priceSectionSlug
  };

  const url = `${BASE_URL}/newsletters/v2/${id}/prices/`;

  return await requestSdk(() => get(url, params));
};

export const rfpArticlesList = async (productSlug, newsletterDate) => {
  const params = {
    paginate: false,
    status: 'Ready for Publication',
    embargoed_until: moment.utc(newsletterDate).toISOString()
  };
  const url = `${BASE_URL}/articles/${productSlug}/`;

  return await requestSdk(() => get(url, params));
};
