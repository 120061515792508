import React from 'react';

import _ from 'lodash';

import { Table, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { ClientDetail, InvoiceDetail, ClientProfileLogList } from 'pages';

import { SortableHeader, TableFooter } from 'components';

import { notifySuccess, notifyErrors } from 'utils/notifications';

import MoveRenewalModal from './MoveRenewalModal';

import { RENEWALS_STATE_COLORS, getRenewalClientData } from './utils';

import styles from './styles.module.css';

class ClientTable extends React.Component {
  state = {
    isOpenMoveRenewalModal: false,
    client: null
  };

  openMoveRenewalModal = client => {
    this.setState({
      isOpenMoveRenewalModal: true,
      client
    });
  };

  closeMoveRenewalModal = () => {
    this.setState({
      isOpenMoveRenewalModal: false,
      client: null
    });
  };

  deleteRenewal = async renewalId => {
    const confirmed = window.confirm(
      'This action will permanently delete the renewal entry. Are you sure you wan to proceed?'
    );

    if (!confirmed) {
      return;
    }

    const { errors, success } = await this.props.renewalClientDelete(renewalId);

    if (success) {
      notifySuccess('Renewal entry removed.');
      this.props.refetch();
    } else {
      notifyErrors(errors);
    }
  };

  render() {
    const { clients, orderBy, sort, pagination } = this.props;

    let convertedCurrency = this.props.convertedCurrency;

    if (_.isNil(convertedCurrency)) {
      convertedCurrency = '';
    }

    return (
      <>
        <div className={styles.scrollableTable}>
          <Table celled compact={true} style={{ minWidth: '1900px' }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan={20} style={{ fontWeight: '400' }}>
                  {pagination}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Header>
              <Table.Row>
                <SortableHeader
                  rowSpan="2"
                  sort={sort}
                  orderBy={orderBy}
                  field="client_id">
                  Id
                </SortableHeader>
                <SortableHeader
                  rowSpan="2"
                  sort={sort}
                  orderBy={orderBy}
                  field="first_name">
                  Name
                </SortableHeader>
                <SortableHeader
                  rowSpan="2"
                  sort={sort}
                  orderBy={orderBy}
                  field="last_name">
                  Surname
                </SortableHeader>
                <SortableHeader
                  rowSpan="2"
                  width={1}
                  sort={sort}
                  orderBy={orderBy}
                  field="company_name">
                  Company name
                </SortableHeader>
                <SortableHeader
                  rowSpan="2"
                  sort={sort}
                  orderBy={orderBy}
                  field="country">
                  Country
                </SortableHeader>
                <Table.HeaderCell rowSpan="2">Account Manager</Table.HeaderCell>
                <SortableHeader
                  rowSpan="2"
                  sort={sort}
                  orderBy={orderBy}
                  field="subscription">
                  Service
                </SortableHeader>
                <Table.HeaderCell rowSpan="2">
                  Live Subs Status
                </Table.HeaderCell>
                <Table.HeaderCell rowSpan="2">Month</Table.HeaderCell>
                <SortableHeader
                  width={1}
                  rowspan="2"
                  sort={sort}
                  orderBy={orderBy}
                  field="updated_at">
                  Last Update
                </SortableHeader>
                <Table.HeaderCell colSpan="5">Future</Table.HeaderCell>
                <Table.HeaderCell colSpan="4">Past</Table.HeaderCell>
                <Table.HeaderCell rowSpan="2">Actions</Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                {/* future */}
                <SortableHeader
                  sort={sort}
                  orderBy={orderBy}
                  field="renewal_state">
                  Renewal State
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={orderBy}
                  field="future_invoice_value">
                  Renewal Invoice
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={orderBy}
                  field="future_invoice_converted_value">
                  Converted {convertedCurrency}
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={orderBy}
                  field="upsell_amount">
                  Upsell amount
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={orderBy}
                  field="upsell_percentage">
                  Upsell %
                </SortableHeader>
                <SortableHeader
                  width={1}
                  sort={sort}
                  orderBy={orderBy}
                  field="expiry_date">
                  Expiry date
                </SortableHeader>
                <SortableHeader
                  width={1}
                  sort={sort}
                  orderBy={orderBy}
                  field="past_invoice_paid_date">
                  Invoice Paid date
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={orderBy}
                  field="past_invoice_value">
                  Invoice value
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={orderBy}
                  field="past_invoice_converted_value">
                  Converted {convertedCurrency}
                </SortableHeader>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {clients.map(client => {
                const clientData = getRenewalClientData(client);

                return (
                  <Table.Row
                    style={{
                      color: RENEWALS_STATE_COLORS[clientData.renewalState]
                    }}>
                    <Table.Cell>
                      <Link
                        to={pageUrl(ClientDetail, {
                          clientId: clientData.clientId
                        })}
                        style={{
                          color: RENEWALS_STATE_COLORS[clientData.renewalState]
                        }}>
                        {clientData.clientId}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>{clientData.firstName}</Table.Cell>
                    <Table.Cell>{clientData.lastName}</Table.Cell>
                    <Table.Cell>{clientData.companyName}</Table.Cell>
                    <Table.Cell>{clientData.country}</Table.Cell>
                    <Table.Cell>{clientData.accountManager}</Table.Cell>
                    <Table.Cell>{clientData.subscription}</Table.Cell>
                    <Table.Cell>{clientData.subscriptionStatus}</Table.Cell>
                    <Table.Cell>{clientData.renewalDate}</Table.Cell>
                    <Table.Cell>
                      <Link
                        to={{
                          pathname: pageUrl(ClientProfileLogList),
                          search: `?client_id=${clientData.clientId}`
                        }}>
                        {clientData.lastUpdateAt}
                      </Link>
                    </Table.Cell>

                    <Table.Cell>{clientData.renewalState}</Table.Cell>
                    <Table.Cell>{clientData.futureInvoiceValue}</Table.Cell>
                    <Table.Cell>
                      <Link
                        to={pageUrl(InvoiceDetail, {
                          invoiceId: clientData.futureInvoiceId
                        })}>
                        {clientData.futureInvoiceConvertedValue}{' '}
                        {convertedCurrency}
                      </Link>
                    </Table.Cell>

                    <Table.Cell>{clientData.upsell}</Table.Cell>
                    <Table.Cell style={{ minWidth: '90px' }}>
                      {clientData.upsellPercentage}
                    </Table.Cell>

                    <Table.Cell>{clientData.subscriptionExpiryDate}</Table.Cell>
                    <Table.Cell>{clientData.pastInvoicePaidDate}</Table.Cell>
                    <Table.Cell style={{ minWidth: '90px' }}>
                      {clientData.invoiceValue}
                    </Table.Cell>
                    <Table.Cell>
                      <Link
                        to={pageUrl(InvoiceDetail, {
                          invoiceId: clientData.pastInvoiceId
                        })}>
                        {clientData.pastInvoiceConvertedValue}{' '}
                        {convertedCurrency}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        compact
                        color="green"
                        onClick={() => this.openMoveRenewalModal(clientData)}>
                        Move
                      </Button>
                      <Button
                        compact
                        color="red"
                        onClick={() => this.deleteRenewal(clientData.id)}
                        style={{ marginTop: 3 }}>
                        Delete
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
            <TableFooter align="left">{pagination}</TableFooter>
          </Table>
        </div>
        {this.state.isOpenMoveRenewalModal && (
          <MoveRenewalModal
            onClose={this.closeMoveRenewalModal}
            refetch={this.props.refetch}
            client={this.state.client}
          />
        )}
      </>
    );
  }
}

export default ClientTable;
