import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const eventsDashboard = async params => {
  const url = `${BASE_URL}/courses/dashboard/`;

  return await requestSdk(() => get(url, params));
};

export const downloadCheckInCsvUrl = courseId =>
  `${BASE_URL}/courses/dashboard/${courseId}/download-check-in/`;

export const downloadAttendeeListCsvUrl = courseId =>
  `${BASE_URL}/courses/dashboard/${courseId}/download-attendee-list/`;
