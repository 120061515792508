import React from 'react';

import _ from 'lodash';

import { Segment, Header, Button } from 'semantic-ui-react';

import { BlockForm } from './components';

class Blocks extends React.Component {
  state = {
    courseBlocksToAdd: 0
  };

  addCourseBlock = () => {
    this.setState(prevState => {
      return {
        courseBlocksToAdd: prevState.courseBlocksToAdd + 1
      };
    });
  };

  fetchCourse = () => {
    const { fetchCourse } = this.props;

    this.setState({ courseBlocksToAdd: 0 }, fetchCourse);
  };

  render() {
    const { course } = this.props;

    const { courseBlocksToAdd } = this.state;

    const { blocks } = course;

    return (
      <Segment>
        <Header as="h2">Event blocks</Header>
        {blocks.map(block => (
          <BlockForm
            course={course}
            fetchCourse={this.fetchCourse}
            block={block}
            key={block.id}
          />
        ))}

        {_.range(courseBlocksToAdd).map(index => (
          <BlockForm
            key={index}
            course={course}
            fetchCourse={this.fetchCourse}
            block={null}
          />
        ))}
        <Button
          style={{ marginTop: '10px' }}
          color="green"
          size="large"
          onClick={this.addCourseBlock}>
          Add event block
        </Button>
      </Segment>
    );
  }
}

export default Blocks;
