import React from 'react';

import _ from 'lodash';

import { Button, Segment } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { MailshotDetail, MailshotClients, MailshotGroupDetail } from 'pages';

import {
  Page,
  Flex,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Error
} from 'components';
import { notifySuccess, notifyErrors } from 'utils/notifications';

import {
  mailshotSelectedClients,
  mailshotDetail,
  mailshotClearAll
} from './sdk';

import { ClientTable } from './components';

class MailshotSelectedClients extends React.Component {
  state = {
    mailshot: null,
    errors: null
  };

  goToMailshotEdit = () => {
    const { mailshotId } = this.props.match.params;

    const url = pageUrl(MailshotDetail, { mailshotId });

    this.props.history.push(url);
  };

  async componentDidMount() {
    const { mailshotId } = this.props.match.params;

    const { success, data, errors } = await mailshotDetail(mailshotId);

    if (success) {
      this.setState({ mailshot: data });
    } else {
      this.setState({ errors });
    }
  }

  onClearAllClick = async () => {
    const { mailshot } = this.state;
    const { refetch, count } = this.props;

    const clearConfirmed = window.confirm(
      `Are you sure you want to remove all ${count} clients from the mailshot?`
    );

    if (!clearConfirmed) {
      return;
    }

    const { success, errors } = await mailshotClearAll(mailshot.id);

    if (success) {
      notifySuccess('All clients cleared from mailshot.');
      refetch();
    } else {
      notifyErrors(errors);
    }
  };

  goToSelectUsers = () => {
    const { mailshotId } = this.props.match.params;

    const url = `${pageUrl(MailshotClients, {
      mailshotId
    })}?unsubscribed=false`;

    this.props.history.push(url);
  };

  goToMailshotGroup = groupId => {
    const url = `${pageUrl(MailshotGroupDetail, {
      mailshotGroupId: groupId
    })}`;

    this.props.history.push(url);
  };

  render() {
    const {
      user,
      crumbs,
      data,
      count,
      page,
      limit,
      sort,
      orderBy,
      changePage,
      changeRowsPerPage,
      refetch
    } = this.props;

    const { mailshot, errors } = this.state;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Flex spaceBetween>
                <div>
                  <Flex>
                    <Header
                      as="h2"
                      color="blue"
                      onClick={this.goToMailshotEdit}
                      style={{ margin: 0, cursor: 'pointer' }}>
                      {_.get(mailshot, 'title', '....')}
                    </Header>
                    <Header as="h2" style={{ margin: '0 0 0 10px' }}>
                      Clients
                    </Header>
                  </Flex>
                </div>
                <Flex>
                  {mailshot && !_.isNull(mailshot.mailshot_group_item) && (
                    <div>
                      <Button
                        basic
                        color="teal"
                        onClick={() =>
                          this.goToMailshotGroup(
                            mailshot.mailshot_group_item.mailshot_group.id
                          )
                        }>
                        Go to group
                      </Button>
                    </div>
                  )}
                  {mailshot && mailshot.status !== 'Sent' && (
                    <div>
                      <Button color="teal" onClick={this.goToSelectUsers}>
                        Select users
                      </Button>
                      <Button color="red" onClick={this.onClearAllClick}>
                        Clear all
                      </Button>
                    </div>
                  )}
                </Flex>
              </Flex>
              {mailshot && (
                <Header as="h2" style={{ margin: 0 }}>
                  {mailshot.total_users} Clients
                </Header>
              )}
            </Segment>
            {errors && (
              <div>
                {errors.map((error, index) => (
                  <Error key={index}>{error.message}</Error>
                ))}
              </div>
            )}

            {data && mailshot && (
              <>
                <ClientTable
                  mailshot={mailshot}
                  clients={data}
                  sort={sort}
                  orderBy={orderBy}
                  refetch={refetch}
                  pagination={
                    <Pagination
                      limit={limit}
                      count={count}
                      page={page}
                      changePage={changePage}
                      changeRowsPerPage={changeRowsPerPage}
                    />
                  }
                />
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: props => {
    const { mailshotId } = props.match.params;
    return _.partial(mailshotSelectedClients, mailshotId);
  },
  passPropsToSdk: true
})(LoginRequired(MailshotSelectedClients));
