import React from 'react';

import _ from 'lodash';

import { Table, Segment } from 'semantic-ui-react';

import {
  calculateTotalSubscription,
  calculatePartialSubscriptionTotal,
  calculatePercentageFromTarget
} from './utils';

const TargetDifferenceCell = ({ accumulated, target }) => {
  const result = calculatePercentageFromTarget(accumulated, target);

  if (target === 0) {
    return <Table.Cell>-</Table.Cell>;
  }

  let cellProps = {};

  if (result < 100) {
    cellProps.error = true;
  }

  if (result > 100) {
    cellProps.positive = true;
  }

  return (
    <Table.Cell {...cellProps}>
      {result} % from {target}
    </Table.Cell>
  );
};

class SubscriptionsStatistics extends React.Component {
  render() {
    const { statistics, budget } = this.props;

    const perSubscriptionStatistics = statistics.per_product_statistics || {};

    const subscriptions =
      perSubscriptionStatistics && perSubscriptionStatistics['Subscription'];

    const subscriptionNewTotal =
      perSubscriptionStatistics &&
      calculatePartialSubscriptionTotal(subscriptions, 'new_total');

    const subscriptionRepeatTotal =
      perSubscriptionStatistics &&
      calculatePartialSubscriptionTotal(subscriptions, 'repeat_total');

    const subscriptionUpsellTotal =
      perSubscriptionStatistics &&
      calculatePartialSubscriptionTotal(subscriptions, 'upsell_total');

    const subscriptionTotal =
      perSubscriptionStatistics && calculateTotalSubscription(subscriptions);

    const syndicationNew = perSubscriptionStatistics['Syndication'].new_total;
    const syndicationRepeat =
      perSubscriptionStatistics['Syndication'].repeat_total;
    const syndicationTotal = syndicationNew + syndicationRepeat;

    return (
      <Segment compact>
        <Table celled compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>New</Table.HeaderCell>
              <Table.HeaderCell>Repeat</Table.HeaderCell>
              <Table.HeaderCell>Upsell</Table.HeaderCell>
              <Table.HeaderCell>Total</Table.HeaderCell>
              <Table.HeaderCell>Budget</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.keys(subscriptions).map(subscription => {
              let data = subscriptions[subscription];
              return (
                <Table.Row textAlign="center">
                  <Table.Cell textAlign="left">{subscription}</Table.Cell>
                  <Table.Cell>{data.new_total.toFixed(0)}</Table.Cell>
                  <Table.Cell>{data.repeat_total.toFixed(0)}</Table.Cell>
                  <Table.Cell>{data.upsell_total.toFixed(0)}</Table.Cell>
                  <Table.Cell>
                    {(data.new_total + data.repeat_total).toFixed(0)}
                  </Table.Cell>
                  <TargetDifferenceCell
                    accumulated={data.new_total + data.repeat_total}
                    target={budget[subscription]}
                  />
                </Table.Row>
              );
            })}
            <Table.Row textAlign="center" warning>
              <Table.Cell textAlign="left">Subscriptions</Table.Cell>
              <Table.Cell>{subscriptionNewTotal.toFixed(0)}</Table.Cell>
              <Table.Cell>{subscriptionRepeatTotal.toFixed(0)}</Table.Cell>
              <Table.Cell>{subscriptionUpsellTotal.toFixed(0)}</Table.Cell>
              <Table.Cell>{subscriptionTotal.toFixed(0)}</Table.Cell>
              <TargetDifferenceCell
                accumulated={subscriptionTotal}
                target={budget.subscriptions_total}
              />
            </Table.Row>
            <Table.Row textAlign="center">
              <Table.Cell textAlign="left">Conferences</Table.Cell>
              <Table.Cell>-</Table.Cell>
              <Table.Cell>-</Table.Cell>
              <Table.Cell>-</Table.Cell>
              <Table.Cell>
                {perSubscriptionStatistics['Course'].toFixed(0)}
              </Table.Cell>
              <TargetDifferenceCell
                accumulated={perSubscriptionStatistics['Course']}
                target={budget['Conferences']}
              />
            </Table.Row>

            <Table.Row textAlign="center">
              <Table.Cell textAlign="left">Reports</Table.Cell>
              <Table.Cell>-</Table.Cell>
              <Table.Cell>-</Table.Cell>
              <Table.Cell>-</Table.Cell>
              <Table.Cell>
                {perSubscriptionStatistics['Report'].toFixed(0)}
              </Table.Cell>
              <TargetDifferenceCell
                accumulated={perSubscriptionStatistics['Report']}
                target={budget['Reports']}
              />
            </Table.Row>

            <Table.Row textAlign="center">
              <Table.Cell textAlign="left">Advertisement</Table.Cell>
              <Table.Cell>-</Table.Cell>
              <Table.Cell>-</Table.Cell>
              <Table.Cell>-</Table.Cell>
              <Table.Cell>
                {perSubscriptionStatistics['Advertisement'].toFixed(0)}
              </Table.Cell>
              <TargetDifferenceCell
                accumulated={perSubscriptionStatistics['Advertisement']}
                target={budget['Advertisement']}
              />
            </Table.Row>
            <Table.Row textAlign="center">
              <Table.Cell textAlign="left">Syndication</Table.Cell>
              <Table.Cell>{syndicationNew.toFixed(0)}</Table.Cell>
              <Table.Cell>{syndicationRepeat.toFixed(0)}</Table.Cell>
              <Table.Cell>-</Table.Cell>
              <Table.Cell>{syndicationTotal.toFixed(0)}</Table.Cell>
              <TargetDifferenceCell
                accumulated={syndicationTotal}
                target={budget['Syndication']}
              />
            </Table.Row>
            <Table.Row textAlign="center" warning>
              <Table.Cell textAlign="left">All Products</Table.Cell>
              <Table.Cell>
                {(subscriptionNewTotal + syndicationNew).toFixed(0)}
              </Table.Cell>
              <Table.Cell>
                {(subscriptionRepeatTotal + syndicationRepeat).toFixed(0)}
              </Table.Cell>
              <Table.Cell>{subscriptionUpsellTotal.toFixed(0)}</Table.Cell>
              <Table.Cell>
                {(
                  subscriptionTotal +
                  perSubscriptionStatistics['Advertisement'] +
                  perSubscriptionStatistics['Course'] +
                  perSubscriptionStatistics['Report'] +
                  syndicationTotal
                ).toFixed(0)}
              </Table.Cell>
              <TargetDifferenceCell
                accumulated={
                  subscriptionTotal +
                  perSubscriptionStatistics['Advertisement'] +
                  perSubscriptionStatistics['Course'] +
                  perSubscriptionStatistics['Report'] +
                  syndicationTotal
                }
                target={budget.grand_total}
              />
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

export default SubscriptionsStatistics;
