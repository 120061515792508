import React from 'react';

import { Table, List, Segment, Dropdown } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import {
  TableFooter,
  SortableHeader,
  Loader,
  EmailLink,
  YesNo
} from 'components';

import { formatDate, formatDateTime } from 'utils';
import { notifyErrors, notifySuccess } from 'utils/notifications';

import {
  filterByItemType,
  getClientId,
  getClientDetailUrl,
  getProfileLogUrl,
  getClientItems
} from 'pages/Client/utils';

import { clientUnsubscribe } from './sdk';

import { clientRemoveFromSuppression } from 'pages/Client/Detail/sdk';

import {
  SUPPRESSION_MAILTO_SUBJECT,
  SUPPRESSION_MAILTO_CONTENT
} from './utils';

import styles from './styles.module.css';

const SUBSCRIPTION = 'Subscription';

class ClientTable extends React.Component {
  unsubscribeClient = async (clientId, email) => {
    const confirmed = window.confirm(
      `Are you sure you want to unsubscribe ${email}?`
    );

    if (!confirmed) {
      return;
    }

    const { refetch } = this.props;

    const { errors, success } = await clientUnsubscribe(clientId);

    if (success) {
      notifySuccess(`${email} successfully unsubscribed.`);
      refetch();
      return;
    }

    notifyErrors(errors);
  };

  removeClient = async (clientId, email) => {
    const confirmed = window.confirm(
      `Are you sure you want to remove ${email} from SES suppression list?`
    );

    if (!confirmed) {
      return;
    }

    const { refetch } = this.props;

    const { data, errors, success } = await clientRemoveFromSuppression(
      clientId
    );

    if (success) {
      if (data.success) {
        notifySuccess(data.text);
      } else {
        notifyErrors(data.text);
      }
      refetch();
      return;
    }

    notifyErrors(errors);
  };

  render() {
    const { clients, orderBy, pagination, sort, isFetching } = this.props;

    return (
      <>
        <Table celled selectable compact={true}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                singleLine
                style={{ fontWeight: '400' }}
                colspan="14">
                {pagination}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <SortableHeader sort={sort} orderBy={orderBy} field="id">
                Id
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="first_name">
                First name
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="last_name">
                Last name
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="company">
                Company
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="country">
                Country
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="priority">
                Priority
              </SortableHeader>
              <Table.HeaderCell width={3}>Subscription status</Table.HeaderCell>
              <Table.HeaderCell>Subscription expiry date</Table.HeaderCell>
              <Table.HeaderCell>Suppression Reason</Table.HeaderCell>
              <Table.HeaderCell>Suppression Occurrence</Table.HeaderCell>
              <SortableHeader sort={sort} orderBy={orderBy} field="updated_at">
                Last Updated By
              </SortableHeader>
              <Table.HeaderCell>New</Table.HeaderCell>
              <Table.HeaderCell>Is Unsubscribed</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          {!isFetching && (
            <>
              <Table.Body>
                {clients.map(client => (
                  <Table.Row key={client.id}>
                    <Table.Cell width={1}>
                      <Link to={getClientDetailUrl(client.id)}>
                        {getClientId(client)}
                      </Link>
                    </Table.Cell>
                    <Table.Cell width={1}>{client.first_name}</Table.Cell>
                    <Table.Cell width={1}>{client.last_name}</Table.Cell>
                    <Table.Cell width={1}>
                      {client.client_profile.company_name}
                    </Table.Cell>
                    <Table.Cell width={1}>
                      {client.client_profile.country}
                    </Table.Cell>
                    <Table.Cell width={1}>
                      {client.client_profile.priority}
                    </Table.Cell>
                    <Table.Cell width={2}>
                      <List bulleted>
                        {filterByItemType(
                          getClientItems(client),
                          SUBSCRIPTION
                        ).map((clientItem, index) => {
                          return (
                            <List.Item key={index}>
                              {clientItem.subscription_status.status} /{' '}
                              {clientItem.item.title}
                            </List.Item>
                          );
                        })}
                      </List>
                    </Table.Cell>
                    <Table.Cell width={2}>
                      <List bulleted>
                        {filterByItemType(
                          getClientItems(client),
                          SUBSCRIPTION
                        ).map((clientItem, index) => {
                          return (
                            <List.Item key={index}>
                              {clientItem.subscription_status.date_end &&
                                `${formatDate(
                                  clientItem.subscription_status.date_end
                                )} /`}
                              {clientItem.item.title}
                            </List.Item>
                          );
                        })}
                      </List>
                    </Table.Cell>
                    <Table.Cell width={1}>
                      {client.email_suppressions[0].reason}
                    </Table.Cell>
                    <Table.Cell width={1}>
                      {formatDateTime(
                        client.email_suppressions[0].last_aws_suppression_update
                      )}
                    </Table.Cell>
                    <Table.Cell width={2}>
                      <Link to={getProfileLogUrl(client.id)}>
                        {client.last_updated_by}{' '}
                        {formatDateTime(client.last_updated_at)}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      <YesNo value={client.email_suppressions[0].is_new} />
                    </Table.Cell>
                    <Table.Cell width={1}>
                      <YesNo value={client.client_profile.unsubscribed} />
                    </Table.Cell>
                    <Table.Cell width={2}>
                      <Dropdown text="Actions">
                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <div
                              className={styles.removeLink}
                              onClick={() =>
                                this.removeClient(client.id, client.email)
                              }>
                              Remove from list
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <EmailLink
                              email={client.email}
                              subject={SUPPRESSION_MAILTO_SUBJECT}
                              content={
                                `Dear ${client.first_name} ${client.last_name},` +
                                SUPPRESSION_MAILTO_CONTENT
                              }>
                              Send Email
                            </EmailLink>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <div
                              className={styles.unsubscribeLink}
                              onClick={() =>
                                this.unsubscribeClient(client.id, client.email)
                              }>
                              Unsubscribe
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              <TableFooter>{pagination}</TableFooter>
            </>
          )}
        </Table>
        {isFetching && (
          <Segment>
            <Loader />
          </Segment>
        )}
      </>
    );
  }
}

export default ClientTable;
