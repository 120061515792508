import { callSdkCache } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const adminList = async () => {
  const url = `${BASE_URL}/users/admins/`;

  return await callSdkCache(url);
};

export const subscriptionStatusList = async () => {
  const url = `${BASE_URL}/editorials/subscription-statuses/`;

  return await callSdkCache(url);
};

export const subscriptionList = async () => {
  const url = `${BASE_URL}/editorials/subscriptions/`;

  return await callSdkCache(url);
};

export const currencyList = async () => {
  const url = `${BASE_URL}/common/currencies/`;

  return await callSdkCache(url);
};

export const clientPendingTrialCount = async () => {
  const url = `${BASE_URL}/crm/clients/pending-for-trial-count/`;

  return await callSdkCache(url);
};
