import React from 'react';

import { Field, SelectField } from 'components';

const ARTICLE_RSS_SOURCE = ['Steelforce', 'Bloomberg', 'Stemcor'];

class CorporateRssClientSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = ARTICLE_RSS_SOURCE.map(source => ({
      key: source,
      value: source,
      label: source
    }));

    return (
      <Field
        name={name || 'client'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default CorporateRssClientSelect;
