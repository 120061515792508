import * as yup from 'yup';
import _ from 'lodash';

const getMetaFieldData = (fields, type) =>
  _.get(
    _.find(fields, item => item.meta_field_type.meta_type === type),
    'meta_data',
    ''
  );

export const getInitialValues = data => {
  const {
    display_as: displayAs,
    short_title: shortTitle,
    price_serie_1: priceSerie1,
    price_serie_2: priceSerie2,
    active_in_website: activeInWebsite,
    price_spread_fields: priceSpreadFields,
    title,
    slug
  } = data;

  let metaTitle = getMetaFieldData(priceSpreadFields, 'Title');
  let metaDescription = getMetaFieldData(priceSpreadFields, 'Meta Description');
  let metaKeywords = getMetaFieldData(priceSpreadFields, 'Meta Keywords');
  let metaStandout = getMetaFieldData(priceSpreadFields, 'Meta Standout');

  return {
    displayAs,
    shortTitle,
    title,
    slug,
    metaDescription,
    metaTitle,
    metaStandout,
    metaKeywords,
    priceSerie1,
    priceSerie2,
    activeInWebsite
  };
};

export const validationSchema = yup.object().shape({
  title: yup.string().required('This field is required.'),
  shortTitle: yup.string().required('This field is required.'),
  slug: yup.string().required('This field is required.'),
  priceSerie1: yup
    .string()
    .nullable()
    .required('This field is required.'),
  priceSerie2: yup
    .string()
    .nullable()
    .required('This field is required.')
    .when('priceSerie1', {
      is: priceSerie1 => priceSerie1 !== null,
      then: schema =>
        schema.notOneOf(
          [yup.ref('priceSerie1')],
          'Price Serie 2 cannot be the same as Price Serie 1.'
        )
    }),
  displayAs: yup.string().required('This field is required.')
});
