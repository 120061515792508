import React from 'react';

import { Button, Icon } from 'semantic-ui-react';

import { Formik, Form } from 'formik';

import { prepareFormData, handleFormErrors } from 'utils/forms';

import {
  addArticleToNewsletterSection,
  removeArticleFromNewsletterSection
} from '../sdk';
import { notifySuccess } from 'utils/notifications';

import { STEEL_SLUG } from 'domainConstants';

function TopHeadlinesForm({
  newsletter,
  fetchNewsletterContentSections,
  article,
  isAdded,
  productSlug
}) {
  const handleSubmit = async (values, actions) => {
    const sdk = isAdded
      ? removeArticleFromNewsletterSection
      : addArticleToNewsletterSection;

    const { setSubmitting, setFieldError } = actions;

    const data = {
      ...prepareFormData(values),
      section_slug:
        productSlug === STEEL_SLUG ? 'top-headlines' : 'selected-articles'
    };

    setSubmitting(true);

    const { errors, success } = await sdk(newsletter.id, data);

    setSubmitting(false);

    if (success) {
      const message = isAdded ? 'removed from' : 'added to';
      notifySuccess(`${article.full_headline} ${message} top headlines.`);
      fetchNewsletterContentSections();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  const initialValues = {
    article: article.id
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <Form>
          <input type="hidden" name="article" />
          <Button
            basic
            color={isAdded ? 'grey' : 'green'}
            type="submit"
            disabled={isSubmitting}
            icon>
            <Icon name={isAdded ? 'cancel' : 'add'} />
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default TopHeadlinesForm;
