import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';
import { stringifyParams } from 'utils';

export const emailLogList = async (logType, params) => {
  const url = `${BASE_URL}/emails/logs/general/${logType}/`;

  return await requestSdk(() => get(url, params));
};

export const emailLogCount = async (logType, params) => {
  const url = `${BASE_URL}/emails/logs/general/${logType}/count/?${stringifyParams(
    params
  )}`;

  return await requestSdk(() => get(url));
};
