import React from 'react';

import { Formik } from 'formik';

import { Form } from 'semantic-ui-react';

import { handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { Field, SelectField, UpdateButtonInRow } from 'components';

import { bannerList } from './sdk';
import { DEFAULT_BANNERS, HOMEPAGE_BANNERS } from './constants';
import { isIndexPage } from '../../utils';

class BannerForm extends React.Component {
  state = {
    options: []
  };

  fetchOptions = async () => {
    const { success, data } = await bannerList({ paginate: false });

    if (success) {
      this.setState({
        options: data.map(banner => {
          return {
            key: banner.id,
            value: banner.id,
            label: banner.title
          };
        })
      });
    }
  };

  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    setSubmitting(true);

    const { errors, success } = await this.props.pageUpdate(
      this.props.page.id,
      {
        banner_manager: values
      }
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Banners updated successfully!');
      this.props.fetchPage();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  async componentDidMount() {
    await this.fetchOptions();
  }

  render() {
    const pageIdentifier = this.props.page.identifier;
    const initialValues = this.props.page.banner_manager;

    const bannerConfig = isIndexPage(pageIdentifier)
      ? HOMEPAGE_BANNERS
      : DEFAULT_BANNERS;

    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        enableReinitialize={true}>
        {({ handleSubmit, isSubmitting }) => {
          return (
            <Form>
              {bannerConfig.map(({ name, label }) => (
                <Field
                  key={name}
                  name={name}
                  label={label}
                  isClearable
                  component={SelectField}
                  options={this.state.options}
                />
              ))}
              <UpdateButtonInRow
                subject="Banner Information"
                onClick={handleSubmit}
                disabled={isSubmitting}
              />
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default BannerForm;
