import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const newsletterPermanentPricesSectionsList = async productSlug => {
  const params = {
    slug_starting: 'permanent-prices'
  };

  const url = `${BASE_URL}/newsletters/v2/${productSlug}/daily/content-sections/`;

  return await requestSdk(() => get(url, params));
};

export const permanentPricesSectionAddContent = async (productSlug, data) => {
  const url = `${BASE_URL}/newsletters/v2/${productSlug}/daily/content-sections/add-content/`;

  return await requestSdk(() => post(url, data));
};

export const permanentPricesSectionRemoveContent = async (
  productSlug,
  data
) => {
  const url = `${BASE_URL}/newsletters/v2/${productSlug}/daily/content-sections/remove-content/`;

  return await requestSdk(() => post(url, data));
};

export const sectionUpdate = async (productSlug, data) => {
  const url = `${BASE_URL}/newsletters/v2/${productSlug}/daily/content-sections/update/`;

  return await requestSdk(() => post(url, data));
};
