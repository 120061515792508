import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  title: yup.string().required('This field is required.'),
  shortTitle: yup.string().required('This field is required.'),
  slug: yup.string().required('This field is required.'),
  priceSerie1: yup
    .string()
    .nullable()
    .required('This field is required.'),
  priceSerie2: yup
    .string()
    .nullable()
    .required('This field is required.')
    .when('priceSerie1', {
      is: priceSerie1 => priceSerie1 !== null,
      then: schema =>
        schema.notOneOf(
          [yup.ref('priceSerie1')],
          'Price Serie 2 cannot be the same as Price Serie 1.'
        )
    }),
  displayAs: yup.string().required('This field is required.')
});

export const DEFAULT_INITIAL_VALUES = {
  activeInWebsite: false,
  title: '',
  shortTitle: '',
  displayAs: '',
  slug: '',
  metaTitle: '',
  metaKeywords: '',
  metaDescription: '',
  metaStandout: '',
  priceSerie1: null,
  priceSerie2: null
};
