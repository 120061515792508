import React from 'react';

import { Table } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { WebArticleDetail, ArticleChangesList } from 'pages';

import { Admin, SortableHeader } from 'components';

import styles from './styles.module.css';

class ArticleChangesAnalyticsTable extends React.Component {
  render() {
    const { articleChanges, filters, orderBy, sort } = this.props;
    return (
      <Table celled selectable className={styles.ArticleChangesAnalyticsTable}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Title </Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Authors</Table.HeaderCell>
            <SortableHeader
              sort={sort}
              orderBy={orderBy}
              field="article_total_changes">
              Total Changes
            </SortableHeader>
            <SortableHeader
              sort={sort}
              orderBy={orderBy}
              field="article_total_edits">
              Total Edits
            </SortableHeader>
            <SortableHeader
              sort={sort}
              orderBy={orderBy}
              field="article_avg_percent_total_changes">
              Avg. % Changes
            </SortableHeader>
            <SortableHeader
              sort={sort}
              orderBy={orderBy}
              field="article_avg_percent_total_edits">
              Avg % Edits
            </SortableHeader>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {articleChanges.map(log => (
            <Table.Row key={log.article_id}>
              <Table.Cell width={3}>
                <Link
                  to={pageUrl(WebArticleDetail, {
                    productSlug: log.article_type
                      .replace(/\s+/g, '-')
                      .toLowerCase(),
                    articleId: log.article_id
                  })}>
                  {log.article_title}
                </Link>
              </Table.Cell>
              <Table.Cell width={1}>{log.article_type}</Table.Cell>
              <Table.Cell width={2}>
                {log.authors.map((author, index) => (
                  <>
                    {index > 0 && ', '}
                    <Admin value={author} key={index} />{' '}
                  </>
                ))}
              </Table.Cell>
              <Table.Cell width={1}>{log.total_changes}</Table.Cell>
              <Table.Cell width={1}> {log.total_edits}</Table.Cell>
              <Table.Cell width={1}>
                {log.avg_percent_total_changes ? (
                  <>{log.avg_percent_total_changes}%</>
                ) : (
                  'N/A'
                )}
              </Table.Cell>
              <Table.Cell width={1}>
                {log.avg_percent_total_edits ? (
                  <>{log.avg_percent_total_edits}%</>
                ) : (
                  'N/A'
                )}
              </Table.Cell>
              <Table.Cell width={2} className={styles.LargeText}>
                <Link
                  to={{
                    pathname: pageUrl(ArticleChangesList),
                    search: `?article=${log.article_id}&updated_at_from=${filters.start}&updated_at_to=${filters.end}`
                  }}>
                  View History
                </Link>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}

export default ArticleChangesAnalyticsTable;
