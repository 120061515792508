import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const emailTemplateDetail = async emailTemplateId => {
  const url = `${BASE_URL}/emails/email-templates/${emailTemplateId}/`;

  return await requestSdk(() => get(url));
};

export const presetEmailSend = async (clientId, data) => {
  const url = `${BASE_URL}/crm/clients/${clientId}/send-preset-email/`;

  return await requestSdk(() => post(url, data));
};
