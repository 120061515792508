import React from 'react';

import _ from 'lodash';
import cx from 'classnames';

import { CKEditor } from 'ckeditor4-react';

import { Error } from 'components';

import styles from './styles.module.css';

const RichTextAreaField = ({
  label,
  containerClassName,
  form,
  field,
  onChange = () => {},
  withoutErrorMessage = false,
  withoutLabel = false,
  disabled = false,
  config = {},
  ...props
}) => {
  const hasErrors =
    _.get(form.touched, field.name) && _.get(form.errors, field.name);

  config.allowedContent = {
    // $<n> is a rule name - it does not match element names.
    $1: {
      // Use the ability to specify elements as an object.
      attributes: true,
      styles: true,
      classes: true
    }
  };
  config.disallowedContent =
    '*{font*}; *{line-height*}; *{margin*}; font; p{color*}';

  // Custom rule to add target="_blank" and rel="noopener noreferrer nofollow" for links
  const handleOnInstanceReady = event => {
    event.editor.dataProcessor.htmlFilter.addRules({
      elements: {
        a: el => {
          try {
            const url = new URL(el.attributes.href);
            if (url.hostname.endsWith('kallanish.com')) return;
          } catch (error) {
            // Constructing an URL from the template variables raises an error.
            // e.g. new URL("{{ daily_report_url }}")
          }
          el.attributes.target = '_blank';
          el.attributes.rel = 'noopener noreferrer nofollow';
        }
      }
    });
  };

  return (
    <div
      className={cx(
        styles.container,
        { [styles.disabled]: disabled },
        containerClassName
      )}>
      {!withoutLabel && <label>{label}</label>}
      <CKEditor
        initData={field.value}
        editorUrl={process.env.REACT_APP_CKEDITOR_URL}
        onChange={changeEvent => {
          const data = changeEvent.editor.getData();
          const diff = data.split(field.value).join('');

          if (diff === '\n' && data.slice(-1) === '\n') return;

          field.onChange(field.name)(changeEvent.editor.getData());
        }}
        onMode={event => {
          const editor = event.editor;

          const editable = editor.editable();

          const eventHandler = e => {
            field.onChange(field.name)(e.data.$.target.value);
          };

          // The content is not automatically saved in "SOURCE" mode.
          // This patch here fixes that.
          if (editor.mode === 'source') {
            editable.attachListener(editable, 'input', eventHandler);
          }
        }}
        onBeforeLoad={CKEDITOR => {
          CKEDITOR.disableAutoInline = true;
        }}
        onBlur={field.onBlur(field.name)}
        onPaste={e => {
          e.data.dataValue = e.data.dataValue.replace(/color="[^"]*"/gi, '');
          e.data.dataValue = e.data.dataValue.replace(/style="[^"]*"/gi, '');
        }}
        onInstanceReady={handleOnInstanceReady}
        config={config}
        {...props}
      />
      {!withoutErrorMessage && hasErrors && (
        <Error className={styles.error}>{_.get(form.errors, field.name)}</Error>
      )}
    </div>
  );
};

export default RichTextAreaField;
