import React from 'react';
import _ from 'lodash';

import { Table, Segment } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import { TableFooter, SortableHeader, Loader } from 'components';

import { getClientId, getClientDetailUrl } from 'pages/Client/utils';

import { formatDateTime } from 'utils';

import { getLinkedClientData } from './utils';

class ClientTable extends React.Component {
  render() {
    const { clients, orderBy, pagination, sort, isFetching } = this.props;

    return (
      <>
        <Table celled selectable compact={true}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                singleLine
                style={{ fontWeight: '400' }}
                colspan="9">
                {pagination}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <SortableHeader
                rowSpan={2}
                sort={sort}
                orderBy={orderBy}
                field="id">
                Id
              </SortableHeader>
              <SortableHeader
                rowSpan={2}
                sort={sort}
                orderBy={orderBy}
                field="first_name">
                First name
              </SortableHeader>
              <SortableHeader
                rowSpan={2}
                sort={sort}
                orderBy={orderBy}
                field="last_name">
                Last name
              </SortableHeader>
              <SortableHeader
                rowSpan={2}
                sort={sort}
                orderBy={orderBy}
                field="company">
                Company
              </SortableHeader>
              <Table.HeaderCell colSpan={4}>Linked to</Table.HeaderCell>
              <Table.HeaderCell rowSpan={2}>Reversed Links</Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>

              <Table.HeaderCell>Names</Table.HeaderCell>
              <Table.HeaderCell>Company</Table.HeaderCell>
              <Table.HeaderCell>Last update</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          {!isFetching && (
            <>
              <Table.Body>
                {clients.map(client => {
                  const {
                    linkedClientId,
                    linkedClientNames,
                    linkedClientCompany
                  } = getLinkedClientData(client);

                  return (
                    <Table.Row key={client.id}>
                      <Table.Cell width={1}>
                        <Link to={getClientDetailUrl(client.id)}>
                          {getClientId(client)}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>{client.first_name}</Table.Cell>
                      <Table.Cell>{client.last_name}</Table.Cell>
                      <Table.Cell>
                        {client.client_profile.company_name}
                      </Table.Cell>
                      <Table.Cell>
                        {linkedClientId && (
                          <Link to={getClientDetailUrl(linkedClientId)}>
                            {linkedClientId}
                          </Link>
                        )}
                      </Table.Cell>
                      <Table.Cell>{linkedClientNames}</Table.Cell>
                      <Table.Cell>{linkedClientCompany}</Table.Cell>
                      <Table.Cell>
                        {client.last_updated_at && (
                          <>
                            {formatDateTime(client.last_updated_at)} (
                            {client.last_updated_by})
                          </>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {_.map(client.reversed_links, (link, index) => (
                          <>
                            {index > 0 && ', '}
                            <Link to={getClientDetailUrl(link)}>{link}</Link>
                          </>
                        ))}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
              <TableFooter>{pagination}</TableFooter>
            </>
          )}
        </Table>
        {isFetching && (
          <Segment>
            <Loader />
          </Segment>
        )}
      </>
    );
  }
}

export default ClientTable;
