import React from 'react';

import { pageUrl } from 'config/routes';
import { CompanyV2Create } from 'pages';

import {
  Page,
  Flex,
  Header,
  ListViewEssentials,
  Pagination,
  Button,
  Filters as SharedFilters
} from 'components';

import { Link } from 'react-router-dom';

import { withLoggedUser } from 'hooks';

import { companyV2PageList } from './sdk';

import { CompanyV2Table } from './components';

import { FILTERS } from './utils';

const CompanyV2List = ({
  user,
  data,
  count,
  page,
  limit,
  sort,
  orderBy,
  filters,
  filterBy,
  clear,
  changePage,
  changeRowsPerPage,
  crumbs
}) => {
  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content crumbs={crumbs}>
          <Flex spaceBetween>
            <Header>Companies</Header>
            <Link to={pageUrl(CompanyV2Create)}>
              <Button>Create</Button>
            </Link>
          </Flex>
          {data && (
            <>
              <SharedFilters
                count={count}
                filters={filters}
                filterBy={filterBy}
                clearFilters={clear}
                layout={FILTERS}
              />
              <CompanyV2Table
                companies={data}
                sort={sort}
                orderBy={orderBy}
                pagination={
                  <Pagination
                    limit={limit}
                    count={count}
                    page={page}
                    changePage={changePage}
                    changeRowsPerPage={changeRowsPerPage}
                  />
                }
              />
            </>
          )}
        </Page.Content>
      </Page.Body>
    </Page>
  );
};

export default ListViewEssentials({
  sdk: companyV2PageList
})(withLoggedUser(CompanyV2List));
