import React from 'react';

import { Link } from 'react-router-dom';

import { Table, Segment } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { WebArticleDetail } from 'pages';

import { Header } from 'components';

class TopArticleTable extends React.Component {
  render() {
    const { articleData } = this.props;

    return (
      <Segment compact style={{ margin: '0 1.5rem 0 0' }}>
        <Header>Top Articles by Opens</Header>
        <Table celled compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Article</Table.HeaderCell>

              <Table.HeaderCell>Total Times Opened</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {articleData.map(article => (
              <Table.Row>
                <Table.Cell>
                  <Link
                    to={pageUrl(WebArticleDetail, {
                      productSlug: article.product,
                      articleId: article.id
                    })}>
                    {article.full_headline}
                  </Link>
                </Table.Cell>
                <Table.Cell>{article.total_article_opens}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

export default TopArticleTable;
