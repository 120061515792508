import { transformValuesForMultiselect } from 'utils/filters';
import { EventSelect, InputField } from 'components';

export const FILTERS = [
  [
    {
      name: 'search',
      emptyValue: '',
      component: InputField,
      label: 'Search'
    },
    {
      name: 'events',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      component: EventSelect,
      label: 'Events',
      props: () => ({ isMulti: true })
    }
  ]
];
