import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { Table } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { ClientDetail } from 'pages';

import { TableFooter } from 'components';

function NewsletterReceiversTable({ data, pagination }) {
  return (
    <Table celled selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            singleLine
            style={{ fontWeight: '400' }}
            colspan="3">
            {pagination}
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>Client ID</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {data.map(item => (
          <Table.Row key={item.id}>
            <Table.Cell width={1}>
              <Link
                to={pageUrl(ClientDetail, {
                  clientId: _.get(item, 'id')
                })}>
                {_.get(item, 'id')}{' '}
              </Link>
            </Table.Cell>
            <Table.Cell width={6}>
              {_.get(item, 'first_name')} {_.get(item, 'last_name')}
            </Table.Cell>

            <Table.Cell width={6}>{_.get(item, 'email')}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <TableFooter>{pagination}</TableFooter>
    </Table>
  );
}

export default NewsletterReceiversTable;
