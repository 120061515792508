import React from 'react';

import { Link } from 'react-router-dom';

import { Icon, Segment } from 'semantic-ui-react';

import styles from './styles.module.css';

const Breadcrumbs = ({ crumbs, additionalContent = null }) => {
  // Don't render a single breadcrumb.
  if (crumbs.length <= 1) {
    return null;
  }

  return (
    <Segment tertiary>
      {/* Link back to any previous steps of the breadcrumb. */}
      {crumbs.map(({ title, path }, key) =>
        key + 1 === crumbs.length ? (
          <span className={styles.activeCrumb} key={key}>
            {title}
          </span>
        ) : (
          <Link key={key} to={path}>
            {title}
            <Icon name="angle right" />
          </Link>
        )
      )}
      {additionalContent && (
        <span className={styles.activeCrumb}>{additionalContent}</span>
      )}
    </Segment>
  );
};

export default Breadcrumbs;
