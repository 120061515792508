import React from 'react';

import _ from 'lodash';

import { Field, FastField, SelectField } from 'components';

const PUBLISH_IN_GROUPS = [
  'Battery Materials',
  'Electric Vehicles',
  'Hydrogen',
  'Fixed Storage',
  'Research & Development',
  'Policy, Finance and Regulation'
];

class PMPublishInGroupSelect extends React.Component {
  state = {
    options: PUBLISH_IN_GROUPS.map(group => ({
      key: group,
      value: group,
      label: group
    }))
  };

  render() {
    const { name, fast = false, ...rest } = this.props;

    const { options } = this.state;

    let Component = Field;
    if (fast) {
      Component = FastField;
    }

    if (!_.isEmpty(options)) {
      rest['options'] = options;
    }

    return (
      <Component
        name={name || 'publishInGroup'}
        component={SelectField}
        isClearable
        {...rest}
      />
    );
  }
}

export default PMPublishInGroupSelect;
