import React from 'react';
import _ from 'lodash';

import { Table, Checkbox } from 'semantic-ui-react';

import { COURSE } from 'domainConstants';

import ProductItemForm from './ProductItemForm';

import { areAllProductsSelected } from './utils';

class ProductsTable extends React.Component {
  render() {
    const {
      products,
      selectedProducts,
      client,
      fetchClient,
      toggleSelectAll,
      toggleProductSelect,
      user,
      openLatestIssue,
      addProduct
    } = this.props;

    const product = _.head(products);

    return !_.isEmpty(products) ? (
      <Table celled selectable compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ textAlign: 'center' }}>
              <Checkbox
                onChange={(event, data) => toggleSelectAll(data, products)}
                checked={areAllProductsSelected(products, selectedProducts)}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Date Start</Table.HeaderCell>
            <Table.HeaderCell>Date End</Table.HeaderCell>
            {product && product.type === COURSE && (
              <>
                <Table.HeaderCell>Attended</Table.HeaderCell>
                <Table.HeaderCell>Self Registered</Table.HeaderCell>
                <Table.HeaderCell>Promo code</Table.HeaderCell>
              </>
            )}
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {products.map(product => (
            <ProductItemForm
              user={user}
              addProduct={addProduct}
              key={product.id}
              product={product}
              client={client}
              fetchClient={fetchClient}
              toggleProductSelect={toggleProductSelect}
              selectedProducts={selectedProducts}
              openLatestIssue={openLatestIssue}
            />
          ))}
        </Table.Body>
      </Table>
    ) : (
      'There are no items.'
    );
  }
}

export default ProductsTable;
