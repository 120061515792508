import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { TableFooter } from 'components';

import { pageUrl } from 'config/routes';

import { GroupsAndPermissionsDetail } from 'pages';

const GroupsAndPermissionsTable = ({ data, sort, orderBy, pagination }) => (
  <Table celled selectable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>ID</Table.HeaderCell>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell>Members</Table.HeaderCell>
        <Table.HeaderCell>Permissions</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {data.map(group => (
        <Table.Row key={group.id}>
          <Table.Cell>
            <Link
              to={pageUrl(GroupsAndPermissionsDetail, {
                groupId: group.id
              })}>
              {group.id}
            </Link>
          </Table.Cell>
          <Table.Cell>{group.name}</Table.Cell>
          <Table.Cell>{group.members.length}</Table.Cell>
          <Table.Cell>
            {_.filter(group.permissions, permission => permission.value).length}
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default GroupsAndPermissionsTable;
