import _ from 'lodash';
import React, { useState } from 'react';
import { arrayMoveImmutable } from 'array-move';

import { SortableList, UpdateButtonInRow, Flex } from 'components';
import { notifySuccess } from 'utils/notifications';

import { sectionOrder } from './sdk';

function OrderPricesModal({ section, productSlug, refetch, onClose, prices }) {
  const initialPriceOptions = prices.map(price => ({
    ...price,
    title: price.display_as
  }));
  const [options, setOptions] = useState(initialPriceOptions);

  const confirmOrder = async () => {
    const data = {
      prices: _.map(options, 'id'),
      section_slug: section.internal_slug
    };

    const { success } = await sectionOrder(productSlug, data);

    if (success) {
      notifySuccess('Prices are reordered successfully.');
      refetch();
      onClose();
    }
  };

  const onSortEnd = async ({ item, oldIndex, newIndex }) => {
    setOptions(prevOptions =>
      arrayMoveImmutable(prevOptions, oldIndex, newIndex)
    );
  };

  return (
    <>
      {options && <SortableList items={options} onSortEnd={onSortEnd} />}
      <Flex flexEnd>
        <UpdateButtonInRow onClick={confirmOrder} subject="Prices Order" />
      </Flex>
    </>
  );
}

export default OrderPricesModal;
