import React from 'react';
import { Header } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { PriceSpreadCreate } from 'pages';

import {
  Page,
  LoginRequired,
  ListViewEssentials,
  Flex,
  Button,
  Pagination,
  ExternalLink,
  Filters as SharedFilters
} from 'components';

import { PermissionsRequired, PERMISSIONS, VisibleIf } from 'permissions';
import { PRICE_SPREADS_URL, PRICE_SPREADS_DOWNLOAD_URL } from 'sdk/urls';

import { priceSpreadList } from './sdk';

import { PriceTable } from './components';

import { FILTERS as LAYOUT_FILTERS } from './utils';

class PriceSpreadList extends React.Component {
  render() {
    const {
      user,
      data,
      crumbs,
      filters,
      count,
      filterBy,
      clear,
      sort,
      orderBy,
      limit,
      page,
      changePage,
      changeRowsPerPage
    } = this.props;

    return (
      <Page user={user} crumbs={crumbs}>
        <Page.Body>
          <Page.Content>
            <Flex spaceBetween>
              <Header as="h1">Price Spreads</Header>
              <div>
                <ExternalLink url={PRICE_SPREADS_DOWNLOAD_URL}>
                  <Button color="orange" size="large">
                    Download Latest Spreads
                  </Button>
                </ExternalLink>
                <ExternalLink url={PRICE_SPREADS_URL}>
                  <Button color="orange" size="large">
                    Price spreads in website
                  </Button>
                </ExternalLink>
                <VisibleIf
                  user={user}
                  permissions={[PERMISSIONS.CREATE_PRICES]}>
                  <Button
                    size="large"
                    onClick={() =>
                      this.props.history.push(pageUrl(PriceSpreadCreate))
                    }>
                    Add Price Spread
                  </Button>
                </VisibleIf>
              </div>
            </Flex>
            {data && (
              <>
                <SharedFilters
                  filters={filters}
                  count={count}
                  filterBy={filterBy}
                  clearFilters={clear}
                  layout={LAYOUT_FILTERS}
                />
                <PriceTable
                  priceSpreads={data}
                  sort={sort}
                  orderBy={orderBy}
                  pagination={
                    <Pagination
                      limit={limit}
                      count={count}
                      page={page}
                      changePage={changePage}
                      changeRowsPerPage={changeRowsPerPage}
                    />
                  }
                />{' '}
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: priceSpreadList
})(
  LoginRequired(PermissionsRequired([PERMISSIONS.VIEW_PRICES])(PriceSpreadList))
);
