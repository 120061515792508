import React from 'react';

import _ from 'lodash';

import { Header, Segment } from 'semantic-ui-react';

import { withRouter } from 'react-router-dom';

import { Page, LoginRequired, Flex } from 'components';

import { bannerDetail } from './sdk';

import { BannerUpdateForm } from './components';

class BannerDetail extends React.Component {
  state = {
    errors: null,
    banner: null
  };

  fetchBanner = async () => {
    const { bannerId } = this.props.match.params;

    const { data, errors, success } = await bannerDetail(bannerId);

    if (success) {
      this.setState({
        banner: data
      });
    } else {
      this.setState({ errors });
    }
  };

  componentDidMount() {
    this.fetchBanner();
  }

  render() {
    const { user, crumbs } = this.props;

    const { banner } = this.state;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Flex spaceBetween>
                <Header as="h1">Banner Detail</Header>
              </Flex>
              {!_.isNil(banner) && (
                <BannerUpdateForm
                  data={banner}
                  fetchBanner={this.fetchBanner}
                />
              )}
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(withRouter(BannerDetail));
