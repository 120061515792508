import React from 'react';

import { withRouter } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import { pageUrl } from 'config/routes';

import { ChinaSteelList } from 'pages';

import {
  Field,
  FormRow,
  DateField,
  InputField,
  FileField,
  RichTextAreaField,
  CreateButtonInRow,
  UnsavedFormPrompt
} from 'components';

import { handleFormErrors, prepareFormData, getToday } from 'utils/forms';

import { chinaSteelReportCreate } from './sdk';

import { chinaSteelReportCreateSchema } from './schema';

import styles from './styles.module.css';

class ChinaSteelReportDetail extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;
    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await chinaSteelReportCreate(data);

    if (success) {
      return this.props.history.push(pageUrl(ChinaSteelList));
    }

    handleFormErrors(errors, setFieldError);
    setSubmitting(false);
  };

  render() {
    const initialValues = {
      issueDate: getToday(),
      issueNumber: '',
      title: '',

      cover: null,
      pdf: null,
      samplePdf: null,
      xls: null,
      sampleXls: null,

      lead: '',
      text: ''
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={chinaSteelReportCreateSchema}
        onSubmit={this.handleSubmit}>
        {({ handleSubmit, isSubmitting, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty && !isSubmitting} />
            <FormRow>
              <Field
                width={4}
                name="issueDate"
                component={DateField}
                label="Issue date"
              />
              <Field
                required
                width={6}
                name="issueNumber"
                component={InputField}
                label="Issue number"
              />

              <Field
                required
                width={6}
                name="title"
                component={InputField}
                label="Title"
              />
            </FormRow>

            <Field
              required
              name="cover"
              width={4}
              component={FileField}
              label="Cover Image"
            />

            <Field
              required
              name="lead"
              component={RichTextAreaField}
              label="Lead"
            />

            <Field
              required
              name="text"
              component={RichTextAreaField}
              label="Text"
            />

            <FormRow widths="equal" className={styles.filesRow}>
              <Field required name="pdf" component={FileField} label="PDF" />

              <Field required name="xls" component={FileField} label="Xls" />

              <Field
                required
                name="samplePdf"
                component={FileField}
                label="Sample pdf"
              />

              <Field
                required
                name="sampleXls"
                component={FileField}
                label="Sample xls"
              />
            </FormRow>
            <CreateButtonInRow onClick={handleSubmit} disabled={isSubmitting} />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(ChinaSteelReportDetail);
