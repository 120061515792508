import _ from 'lodash';
import {
  MonthSelect,
  YearSelect,
  DateField,
  CountrySelect,
  InputField,
  AdminSelect,
  PipelineEngagementSelect,
  PaymentReceivedSelect,
  YesNoSelect,
  SubscriptionSelect,
  EventSelect,
  ReportSelect,
  AdvertisementSelect,
  PipelineTypeSelect
} from 'components';

export const FILTERS_LAYOUT = [
  [
    {
      name: 'forecasted_month',
      emptyValue: null,
      fieldComponent: MonthSelect,
      transform: value => parseInt(value, 10),
      label: 'Pipeline Month',
      width: 3
    },
    {
      name: 'forecasted_year',
      emptyValue: null,
      fieldComponent: YearSelect,
      transform: value => parseInt(value, 10),
      label: 'Pipeline Year',
      width: 3,
      props: () => ({
        customOptions: _.range(
          new Date().getFullYear() - 1,
          new Date().getFullYear() + 10
        )
      })
    },
    {
      name: 'forecasted_date_from',
      emptyValue: null,
      component: DateField,
      label: 'Pipeline Date From',
      width: 3
    },
    {
      name: 'forecasted_date_to',
      emptyValue: null,
      component: DateField,
      label: 'Pipeline Date To',
      width: 3
    }
  ],
  [
    {
      name: 'client',
      emptyValue: '',
      component: InputField,
      label: 'Client ID',
      placeholder: 'Search by client id',
      width: 4
    },
    {
      name: 'account_manager',
      emptyValue: '',
      component: AdminSelect,
      label: 'Account Manager',
      width: 4
    },
    {
      name: 'pipeline_engagement',
      emptyValue: '',
      component: PipelineEngagementSelect,
      label: 'Status',
      width: 4
    },
    {
      name: 'invoice_type',
      emptyValue: null,
      fieldComponent: PaymentReceivedSelect,
      label: 'Invoice type',
      width: 4
    }
  ],
  [
    {
      name: 'country',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: CountrySelect,
      label: 'Country',
      width: 4
    },
    {
      name: 'company',
      emptyValue: '',
      component: InputField,
      label: 'Company',
      width: 4
    },
    {
      name: 'created_by_sales',
      emptyValue: null,
      component: YesNoSelect,
      label: 'Created By Sales',
      width: 4
    },
    {
      name: 'pipeline_type',
      emptyValue: '',
      component: PipelineTypeSelect,
      label: 'Type',
      width: 4
    }
  ],
  [
    {
      name: 'subscription',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: SubscriptionSelect,
      label: 'Subscription',
      width: 4
    },
    {
      name: 'event',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: EventSelect,
      label: 'Event',
      width: 4
    },
    {
      name: 'report',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: ReportSelect,
      label: 'Report',
      width: 4
    },
    {
      name: 'advertisement',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: AdvertisementSelect,
      label: 'Advertisement',
      width: 4
    }
  ]
];
