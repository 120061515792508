import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const clientUpdateProduct = async (clientId, type, data) => {
  const url = `${BASE_URL}/crm/clients/${clientId}/products/update/${type.toLowerCase()}/`;

  return await requestSdk(() => post(url, data));
};

export const clientRemoveProduct = async (clientId, data) => {
  const url = `${BASE_URL}/crm/clients/${clientId}/products/delete/`;

  return await requestSdk(() => post(url, data));
};
