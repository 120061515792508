import React, { useCallback, useMemo } from 'react';

import _ from 'lodash';
import { Formik } from 'formik';
import { Form } from 'semantic-ui-react';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { UpdateButtonInRow, CreatedUpdatedFormValues } from 'components';

import { PriceSpreadV2Form } from 'pages/PriceSpreadV2/shared/components';

import { validationSchema, getInitialValues } from './utils';
import { priceSpreadV2Update } from './sdk';

const PriceSpreadV2UpdateForm = ({
  priceSpread,
  setPriceSpread,
  productSlug
}) => {
  const handleSubmit = useCallback(
    (values, actions) => {
      const data = _.pickBy(values, _.negate(_.isNil));

      const { setSubmitting, setFieldError } = actions;

      priceSpreadV2Update(priceSpread.id, prepareFormData(data)).then(
        ({ errors, data, success }) => {
          setSubmitting(false);

          if (success) {
            notifySuccess('Price spread has been updated successfully.');
            setPriceSpread(data);
          } else {
            handleFormErrors(errors, setFieldError);
          }
        }
      );
    },
    [setPriceSpread, priceSpread.id]
  );

  const initialValues = getInitialValues(priceSpread);

  const nonFormValues = useMemo(
    () => ({
      createdAt: priceSpread.created_at,
      createdBy: priceSpread.created_by,
      updatedAt: priceSpread.updated_at,
      updatedBy: priceSpread.updated_by
    }),
    [
      priceSpread.created_at,
      priceSpread.created_by,
      priceSpread.updated_at,
      priceSpread.updated_by
    ]
  );

  return (
    <Formik
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}>
      {({ isSubmitting, handleSubmit, setFieldValue, dirty, errors }) => (
        <Form>
          <PriceSpreadV2Form
            FormikProps={{ setFieldValue, dirty }}
            productSlug={productSlug}
          />
          <CreatedUpdatedFormValues values={nonFormValues} />
          <UpdateButtonInRow
            subject="Price Spread"
            onClick={handleSubmit}
            disabled={isSubmitting || !_.isEmpty(errors)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default PriceSpreadV2UpdateForm;
