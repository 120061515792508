import React from 'react';

import _ from 'lodash';

import { Table } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { ClientDetail } from 'pages';

import { formatDateTime, formatDate } from 'utils';

import { SortableHeader, TableFooter, DeleteButton } from 'components';

import RelatedObject from '../RelatedObject';

import { commentRemove } from './sdk';

const deleteComment = async (feedbackId, refetch) => {
  if (!window.confirm('Are you sure you want to delete this comment?')) {
    return;
  }
  const { success } = await commentRemove(feedbackId);
  if (success) {
    refetch();
  }
};

const CommentsTable = ({ data, pagination, sort, orderBy, refetch }) => (
  <Table celled selectable compact={true}>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell singleLine style={{ fontWeight: '400' }} colspan="9">
          {pagination}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <Table.HeaderCell>Client</Table.HeaderCell>
        <Table.HeaderCell>Names</Table.HeaderCell>
        <Table.HeaderCell>Company Name</Table.HeaderCell>
        <Table.HeaderCell>Account Manager</Table.HeaderCell>
        <SortableHeader sort={sort} orderBy={orderBy} field="created_on">
          Created on
        </SortableHeader>
        <Table.HeaderCell>Title</Table.HeaderCell>
        <Table.HeaderCell>Date</Table.HeaderCell>
        <Table.HeaderCell>Text</Table.HeaderCell>
        <Table.HeaderCell>Delete</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {data.map(comment => (
        <Table.Row key={comment.id}>
          <Table.Cell width={1}>
            {comment.created_by && (
              <Link
                to={pageUrl(ClientDetail, {
                  clientId: comment.created_by.id
                })}>
                {comment.created_by.id}
              </Link>
            )}
          </Table.Cell>
          <Table.Cell width={2}>
            {_.get(comment, 'created_by.first_name')}{' '}
            {_.get(comment, 'created_by.last_name')}
          </Table.Cell>
          <Table.Cell width={2}>
            {_.get(comment, 'created_by.company_name')}
          </Table.Cell>
          <Table.Cell width={2}>
            {_.get(comment, 'created_by.account_manager.first_name')}{' '}
            {_.get(comment, 'created_by.account_manager.last_name')}
          </Table.Cell>
          <Table.Cell width={2}>
            {formatDateTime(comment.created_on)}
          </Table.Cell>
          <Table.Cell width={3}>
            <RelatedObject
              relatedObj={comment.related_obj}
              relatedObjType={comment.related_obj_type}
            />
          </Table.Cell>
          <Table.Cell width={1}>
            {formatDate(_.get(comment, 'related_obj.created_at'))}
          </Table.Cell>
          <Table.Cell width={3}>{comment.text}</Table.Cell>
          <Table.Cell>
            <DeleteButton
              onClick={() => {
                deleteComment(comment.id, refetch);
              }}
            />
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default CommentsTable;
