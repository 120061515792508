import { requestSdk, post, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const clientAddProduct = async (clientId, products) => {
  const url = `${BASE_URL}/crm/clients/${clientId}/add-to-invoice/`;

  return await requestSdk(() => post(url, products));
};

export const clientPipelineItems = async (id, params) => {
  const url = `${BASE_URL}/crm/pipelines/for-client/${id}/`;

  return await requestSdk(() => get(url, params));
};
