import React from 'react';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import { populateSlug } from 'utils';

import {
  Field,
  InputField,
  FormRow,
  UpdateButtonInRow,
  UnsavedFormPrompt,
  UniSelect
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { companyV2UpdateSchema } from './utils';
import { companyV2Update } from './sdk';

const CompanyV2UpdateForm = ({ company, fetchCompany }) => {
  const handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;
    const data = prepareFormData(values);
    setSubmitting(true);
    const { errors, success } = await companyV2Update(company.id, data);
    setSubmitting(false);
    if (success) {
      notifySuccess('Company updated.');
      fetchCompany();
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  const initialValues = {
    title: company.title,
    slug: company.slug,
    product: company.product
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={companyV2UpdateSchema}
      onSubmit={handleSubmit}>
      {({ values, handleSubmit, isSubmitting, setFieldValue, dirty }) => (
        <Form>
          <UnsavedFormPrompt when={dirty} />
          <UpdateButtonInRow onClick={handleSubmit} disabled={isSubmitting} />
          <FormRow>
            <Field
              width={4}
              name="title"
              label="Title"
              required
              component={InputField}
              onChange={(e, data) => {
                populateSlug(data, setFieldValue);
              }}
            />
            <Field
              width={4}
              name="slug"
              label="Slug"
              required
              component={InputField}
            />
            <UniSelect
              width={5}
              name="product"
              label="Select Product"
              source="globalProducts"
              isMulti
            />
          </FormRow>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyV2UpdateForm;
