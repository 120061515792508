import React from 'react';

import _ from 'lodash';

import { Button, Icon } from 'semantic-ui-react';

import { Formik, Form } from 'formik';

import { prepareFormData, handleFormErrors } from 'utils/forms';

import { notifySuccess } from 'utils/notifications';
import {
  dailyNewsletterAddFeaturedArticle,
  dailyNewsletterRemoveFeaturedArticle
} from './sdk';

class FeaturedForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const isAdded = this.isArticleAdded();

    const sdk = isAdded
      ? dailyNewsletterRemoveFeaturedArticle
      : dailyNewsletterAddFeaturedArticle;

    const { article, dailyNewsletter, fetchDailyNewsletter } = this.props;

    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await sdk(dailyNewsletter.id, data);

    setSubmitting(false);

    if (success) {
      const message = isAdded ? 'removed from' : 'added to';
      notifySuccess(`${article.full_headline} ${message} featured.`);
      fetchDailyNewsletter();
      return;
    }
    handleFormErrors(errors, setFieldError);
  };

  isArticleAdded = () => {
    const { article, dailyNewsletter } = this.props;

    const query = { id: article.id };

    return _.find(dailyNewsletter.featured, query);
  };

  render() {
    const { article } = this.props;
    const initialValues = {
      article: article.id
    };

    const isAdded = this.isArticleAdded();

    return (
      <Formik initialValues={initialValues} onSubmit={this.handleSubmit}>
        {({ values, handleSubmit, isSubmitting }) => (
          <Form>
            <input type="hidden" name="article" />
            <Button
              basic
              color={isAdded ? 'grey' : 'red'}
              type="submit"
              disabled={isSubmitting}
              icon>
              <Icon name={isAdded ? 'cancel' : 'add'} />
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
}

export default FeaturedForm;
