import React from 'react';

import { withRouter } from 'react-router-dom';

import {
  Page,
  Header,
  Flex,
  LoginRequired,
  ListViewEssentials,
  CursorPagination,
  Button
} from 'components';

import { pageUrl } from 'config/routes';

import { PollCreate } from 'pages';

import { pollList } from './sdk';

import { PollTable } from './components';

class PollList extends React.Component {
  createPoll = () => {
    this.props.history.push(pageUrl(PollCreate));
  };

  render() {
    const {
      user,
      data,
      nextCursor,
      previousCursor,
      pageSize,
      changePage,
      changeRowsPerPage
    } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content>
            <Flex spaceBetween>
              <Header>Polls</Header>
              <Button size="large" onClick={this.createPoll}>
                Create Poll
              </Button>
            </Flex>
            {data && (
              <PollTable
                polls={data}
                pagination={
                  <CursorPagination
                    nextCursor={nextCursor}
                    previousCursor={previousCursor}
                    pageSize={pageSize}
                    changePage={changePage}
                    changeRowsPerPage={changeRowsPerPage}
                  />
                }
              />
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: pollList,
  cursorPagination: true
})(LoginRequired(withRouter(PollList)));
