import React from 'react';

import _ from 'lodash';

import {
  Header,
  Page,
  LoginRequired,
  ListViewEssentials,
  NoDataMessage,
  Filters as SharedFilters
} from 'components';

import { ExchangeCurrencyRatesTable } from './components';

import { exchangeCurrencyRateList } from './sdk';

import { FILTERS as LAYOUT_FILTERS } from './utils';

class ExchangeRates extends React.Component {
  render() {
    const {
      user,
      data,
      orderBy,
      filters,
      filterBy,
      clear,
      crumbs
    } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Header>Exchange Rates</Header>
            {data && (
              <>
                <SharedFilters
                  filters={filters}
                  filterBy={filterBy}
                  clearFilters={clear}
                  layout={LAYOUT_FILTERS}
                />
                {_.isEmpty(data) && <NoDataMessage />}
                {!_.isEmpty(data) && (
                  <ExchangeCurrencyRatesTable rates={data} orderBy={orderBy} />
                )}
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: exchangeCurrencyRateList
})(LoginRequired(ExchangeRates));
