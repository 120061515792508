import * as yup from 'yup';

export const articleUpdateSchema = yup.object().shape({
  fullHeadline: yup.string().required('Full headline is required.'),
  marketingHeadline: yup.string().required('Marketing headline is required.'),
  slug: yup.string().required('Slug is required.'),
  text: yup.string(),
  summary: yup.string().nullable(),
  tableIcon: yup.boolean(),
  graphIcon: yup.boolean(),
  imageIcon: yup.boolean(),
  date: yup.date(),
  embargoed: yup.boolean(),
  free: yup.boolean(),
  featured: yup.boolean(),
  metaDescription: yup.string(),
  metaKeywords: yup.string(),
  metaStandout: yup.string(),
  metaTitle: yup.string(),
  notRfp: yup.boolean(),
  publishDate: yup.date().nullable(),
  status: yup.string(),
  authors: yup.array().required('Pick at least 1 author.'),
  tags: yup
    .array()
    .of(yup.string().max(100, 'Tag must be at most 100 characters long.'))
});
