import React from 'react';
import { Field, SelectField } from 'components';
import moment from 'moment';

const PERIODS = [
  { label: '1 week', duration: { length: 1, type: 'weeks' } },
  { label: '2 weeks', duration: { length: 2, type: 'weeks' } },
  { label: '1 month', duration: { length: 1, type: 'months' } },
  { label: '3 months', duration: { length: 3, type: 'months' } },
  { label: '6 months', duration: { length: 6, type: 'months' } },
  {
    label: '12 months',
    duration: { length: 12, type: 'months' }
  },
  {
    label: '24 months',
    duration: { length: 24, type: 'months' }
  }
];

class FixedDurationsSelect extends React.Component {
  render() {
    const { name, isClearable = true, ...rest } = this.props;

    const options = PERIODS.map(({ label, duration }) => {
      const endDate = moment()
        .add(duration.length, duration.type)
        .format('YYYY-MM-DD');
      return {
        key: endDate,
        value: endDate,
        label: label
      };
    });

    return (
      <Field
        name={name || 'fixedDurationType'}
        component={SelectField}
        options={options}
        isClearable={isClearable}
        {...rest}
      />
    );
  }
}

export default FixedDurationsSelect;
