import React from 'react';

import { Table } from 'semantic-ui-react';

import ChoiceForm from './ChoiceForm';
import CreateChoiceForm from './CreateChoiceForm';

class Choices extends React.Component {
  render() {
    const { poll, fetchPoll } = this.props;

    const { choice_set } = poll;

    return (
      <Table celled style={{ maxWidth: '600px' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Choice</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {choice_set.map((choice, index) => (
            <ChoiceForm
              key={index}
              choice={choice}
              poll={poll}
              fetchPoll={fetchPoll}
            />
          ))}
          <CreateChoiceForm poll={poll} fetchPoll={fetchPoll} />
        </Table.Body>
      </Table>
    );
  }
}

export default Choices;
