import * as yup from 'yup';
import _ from 'lodash';

import { getItemIds } from 'utils';

const getMetaFieldData = (fields, type) =>
  _.get(
    _.find(fields, item => item.meta_field_type.meta_type === type),
    'meta_data',
    ''
  );

export const prepareValues = values => {
  return {
    ...values,
    access: getItemIds(values.access),
    article_regions: getItemIds(values.article_regions),
    article_prices: getItemIds(values.article_prices),
    article_products: getItemIds(values.article_products),
    article_authors: getItemIds(values.article_authors),
    price_data_symbols: _.get(values.external_price_source, 'symbols'),

    metaTitle: getMetaFieldData(values.price_series_fields, 'Title'),
    metaDescription: getMetaFieldData(
      values.price_series_fields,
      'Meta Description'
    ),
    metaKeywords: getMetaFieldData(values.price_series_fields, 'Meta Keywords'),
    metaStandout: getMetaFieldData(values.price_series_fields, 'Meta Standout')
  };
};

export const getInitialValues = (data, isPowerMaterial) => {
  const {
    access,
    article_authors: articleAuthors,
    article_prices: articlePrices,
    article_products: articleProducts,
    article_regions: articleRegions,
    currency,
    date,
    decimal_places: decimalPlaces,
    display_as: displayAs,
    format,
    frequency,
    inco_terms: incoTerms,
    product,
    region,
    region_2: region2,
    scale,
    short_title: shortTitle,
    show_in_right_column: showInRightColumn,
    show_on_homepage: showOnHomepage,
    slug,
    text,
    title,
    unit,
    metaDescription,
    metaTitle,
    metaStandout,
    metaKeywords,
    price_data_source: priceDataSource,
    price_data_symbols: priceDataSymbols
  } = data;

  let initialValues = {
    access,
    articleAuthors,
    articlePrices,
    articleProducts,
    articleRegions,
    currency,
    date,
    decimalPlaces,
    displayAs,
    format,
    frequency,
    incoTerms,
    product,
    region,
    region2,
    scale,
    shortTitle,
    showInRightColumn,
    showOnHomepage,
    slug,
    text,
    title,
    unit,
    metaDescription,
    metaTitle,
    metaStandout,
    metaKeywords,
    priceDataSource,
    priceDataSymbols
  };

  if (isPowerMaterial) {
    initialValues = {
      ...initialValues,
      availableToSteelSubscribers: data.available_to_steel_subscribers || false,
      availableToSteelLiveSubscribers:
        data.available_to_steel_live_subscribers || false,
      mainGroup: data.main_group || ''
    };
  } else {
    initialValues = { ...initialValues, category: data.category };
  }

  return initialValues;
};

export const validationSchema = yup.object().shape({
  title: yup.string().required('This field is required.'),
  shortTitle: yup.string().required('This field is required.'),
  product: yup
    .string()
    .nullable()
    .required('This field is required.'),
  incoTerms: yup
    .string()
    .nullable()
    .required('This field is required.'),
  format: yup
    .string()
    .nullable()
    .required('This field is required.'),
  unit: yup
    .string()
    .nullable()
    .required('This field is required.'),
  frequency: yup
    .string()
    .nullable()
    .required('This field is required.'),
  currency: yup
    .string()
    .nullable()
    .required('This field is required.'),
  scale: yup
    .string()
    .nullable()
    .required('This field is required.'),
  decimalPlaces: yup
    .number()
    .required('This field is required.')
    .min(0),
  date: yup.string().required('This field is required.'),
  region: yup
    .string()
    .nullable()
    .required('This field is required.'),
  priceDataSource: yup
    .string()
    .nullable()
    .required('This field is required.'),
  priceDataSymbols: yup.string().when('priceDataSource', {
    is: value => value !== 'Manual',
    then: schema => schema.required('This field is required.')
  })
});
