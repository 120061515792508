import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const glossaryTermDetail = async id => {
  const url = `${BASE_URL}/cms/glossary/terms/${id}/`;

  return await requestSdk(() => get(url));
};

export const glossaryTermUpdateMeta = async (id, data) => {
  const url = `${BASE_URL}/cms/glossary/terms/${id}/update-meta/`;

  return await requestSdk(() => post(url, data));
};
