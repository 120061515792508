import React from 'react';

import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { formatDate } from 'utils';

class PriceSpreadMovementTable extends React.Component {
  getPriceSpreadDetailUrl = priceSpreadId => {
    return pageUrl(this.props.detailPage, { priceSpreadId });
  };

  getPriceSeriesDetailUrl = priceSeriesId => {
    return pageUrl(this.props.priceSeriesDetailPage, { priceSeriesId });
  };

  render() {
    const { priceSpreads } = this.props;

    return (
      <Table celled selectable compact={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Latest Update</Table.HeaderCell>
            <Table.HeaderCell>Product</Table.HeaderCell>
            <Table.HeaderCell>Latest Price</Table.HeaderCell>
            <Table.HeaderCell>Change</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {priceSpreads.map(priceSpread => (
            <Table.Row key={priceSpread.id}>
              <Table.Cell>{formatDate(priceSpread.last_update_on)}</Table.Cell>
              <Table.Cell>
                <Link to={this.getPriceSpreadDetailUrl(priceSpread.id)}>
                  {priceSpread.title}
                </Link>
              </Table.Cell>
              <Table.Cell>
                <Link
                  to={this.getPriceSeriesDetailUrl(
                    priceSpread.price_serie_1.id
                  )}>
                  {priceSpread.latest_value}{' '}
                  {priceSpread.price_serie_1.formatted_metrics}
                </Link>
              </Table.Cell>
              <Table.Cell>{priceSpread.diff || '0.00'}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}

export default PriceSpreadMovementTable;
