import React from 'react';

import { withRouter } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { NotFound } from 'pages';

import { hasPermissions } from 'permissions/utils';

const PermissionsRequired = (permissions = []) => {
  return WrappedComponent => {
    class HigherOrderComponent extends React.Component {
      componentDidMount() {
        const permitted = hasPermissions(this.props.user, permissions);

        if (!permitted) {
          this.props.history.push(pageUrl(NotFound));
        }
      }

      render() {
        return (
          hasPermissions(this.props.user, permissions) &&
          (<WrappedComponent {...this.props} /> || null)
        );
      }
    }

    return withRouter(HigherOrderComponent);
  };
};

export default PermissionsRequired;
