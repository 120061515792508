import React from 'react';

import cx from 'classnames';

import styles from './styles.module.css';

const READY_FOR_PUBLICATION_STATUS = 'Ready for Publication';
const LIVE_STATUS = 'Live';
const WORK_IN_PROGRESS_STATUS = 'Work In Progress';

const ArticleStatus = ({ value }) => {
  return (
    <span
      className={cx({
        [styles.rfp]: value === READY_FOR_PUBLICATION_STATUS,
        [styles.live]: value === LIVE_STATUS,
        [styles.wip]: value === WORK_IN_PROGRESS_STATUS
      })}>
      {value}
    </span>
  );
};

export default ArticleStatus;
