import { DateTimeField, UniSelect } from 'components';

export const FILTERS = [
  [
    {
      name: 'source',
      emptyValue: [],
      fieldComponent: UniSelect,
      label: 'RSS Corporate Client',
      props: () => ({
        source: 'corporateRssClients'
      })
    },
    {
      name: 'created_at_from',
      emptyValue: null,
      component: DateTimeField,
      label: 'Access Date From'
    },
    {
      name: 'created_at_to',
      emptyValue: null,
      component: DateTimeField,
      label: 'Access Date To'
    }
  ]
];
