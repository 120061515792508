import React from 'react';

import _ from 'lodash';

import { Table } from 'semantic-ui-react';

const items = [
  {
    key: 'total',
    label: 'Total Emails'
  },
  {
    key: 'sent',
    label: 'Sent Emails'
  },
  {
    key: 'opened',
    label: 'Opened Emails'
  },
  {
    key: 'bounced',
    label: 'Bounced Emails'
  },
  {
    key: 'totalClicks',
    label: 'Total Clicks'
  },
  {
    key: 'uniqueClicks',
    label: 'Unique Clicks'
  },
  {
    key: 'ctr',
    label: 'CTR'
  },
  {
    key: 'uctr',
    label: 'UCTR'
  },
  {
    key: 'openRate',
    label: 'Open Rate'
  }
];

const EmailStatistics = ({ data }) => {
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Statistic</Table.HeaderCell>
          <Table.HeaderCell>Value</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {items.map(item => (
          <Table.Row key={item.key}>
            <Table.Cell>{item.label}</Table.Cell>
            <Table.Cell>{_.get(data, item.key, 'N/A')}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default EmailStatistics;
