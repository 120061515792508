import React from 'react';

import { withRouter } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  InputField,
  FormRow,
  CreateButton,
  UnsavedFormPrompt,
  Flex,
  CheckboxField
} from 'components';
import { notifySuccess } from 'utils/notifications';
import { handleFormErrors, prepareFormData } from 'utils/forms';
import { pageUrl } from 'config/routes';
import { GlossaryCategoryList } from 'pages';

import { glossaryCategoryCreateSchema } from './utils';
import { glossaryCategoryCreate } from './sdk';

class GlossaryCategoryCreateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await glossaryCategoryCreate(data);

    if (success) {
      notifySuccess('Glossary category created.');
      this.props.history.push(pageUrl(GlossaryCategoryList));
    } else {
      handleFormErrors(errors, setFieldError);
    }
    setSubmitting(false);
  };

  render() {
    const initialValues = {
      title: '',
      isActive: false
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={glossaryCategoryCreateSchema}
        onSubmit={this.handleSubmit}>
        {({ handleSubmit, isSubmitting, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty && !isSubmitting} />

            <FormRow style={{ alignItems: 'flex-end' }}>
              <Field
                width={6}
                name="title"
                label="Title"
                required
                component={InputField}
              />
              <Field
                width={3}
                name="isActive"
                label="Is Active"
                component={CheckboxField}
              />
              <Flex style={{ alignItems: 'flex-end' }}>
                <CreateButton onClick={handleSubmit} disabled={isSubmitting} />
              </Flex>
            </FormRow>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(GlossaryCategoryCreateForm);
