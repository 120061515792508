import React from 'react';

import { Field, SelectField } from 'components';

const SOURCES = [
  'Manual',
  'Trading Economics',
  'London Metal Exchange',
  'Shanghai Metals'
];

class PriceSeriesDataSourceSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = SOURCES.map(source => {
      return {
        key: source,
        value: source,
        label: source
      };
    });

    return (
      <Field
        name={name || 'priceSeriesDataSource'}
        component={SelectField}
        options={options}
        isClearable={false}
        {...rest}
      />
    );
  }
}

export default PriceSeriesDataSourceSelect;
