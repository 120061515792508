import {
  MailshotStatusSelect,
  InputField,
  DateField,
  YesNoSelect,
  AdminSelect
} from 'components';
import { coerceStringToBoolean } from 'utils';

export const FILTERS = [
  [
    {
      name: 'search',
      emptyValue: '',
      component: InputField,
      label: 'General search',
      placeholder: 'Search...',
      width: 6
    },
    {
      name: 'created_by',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: AdminSelect,
      label: 'Created By',
      width: 4
    },
    {
      name: 'status',
      emptyValue: null,
      fieldComponent: MailshotStatusSelect,
      label: 'Status',
      width: 3
    },
    {
      name: 'include_group_mailshots',
      label: 'Show mailshots from groups',
      component: YesNoSelect,
      transform: coerceStringToBoolean,
      emptyValue: false,
      width: 3,
      props: () => ({
        isClearable: false
      })
    }
  ],
  [
    {
      name: 'date_sent_from',
      emptyValue: null,
      component: DateField,
      label: 'Sent date from',
      width: 4
    },
    {
      name: 'date_sent_to',
      emptyValue: null,
      component: DateField,
      label: 'Sent date to',
      width: 4
    }
  ]
];
