import _ from 'lodash';
import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const save = async data => {
  let url = `${BASE_URL}/newsletters/weekly-seasm/create/`;
  if (!_.isNil(data.id)) {
    url = `${BASE_URL}/newsletters/weekly-seasm/update/${data.id}/`;
  }
  return await requestSdk(() => post(url, data));
};

export const deleteSendingInstance = async sdkArgs => {
  const { itemId } = sdkArgs;
  const url = `${BASE_URL}/newsletters/weekly-seasm/delete/${itemId}/`;
  return await requestSdk(() => post(url, {}));
};
