import React from 'react';

import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { formatDateTime } from 'utils';

import { YesNo, Admin, TableFooter, SortableHeader } from 'components';

const WeeklySteelTable = ({ items, sort, orderBy, pagination, detailPage }) => (
  <Table celled selectable compact>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell singleLine style={{ fontWeight: '400' }} colspan="11">
          {pagination}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <SortableHeader sort={sort} orderBy={orderBy} field="title">
          Title
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="date">
          Date to go out
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="status">
          Status
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="sending_status">
          Sending Status
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="editor_approved">
          Editor Approved
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="editor">
          Editor
        </SortableHeader>
        <SortableHeader
          sort={sort}
          orderBy={orderBy}
          field="marketing_approved">
          Marketing Approved
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="marketer">
          Marketer
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="created_by">
          Created by
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="copies">
          Copies sent
        </SortableHeader>
        <Table.HeaderCell>Report will be sent to</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {items.map(item => (
        <Table.Row key={item.id}>
          <Table.Cell width={4}>
            <Link to={pageUrl(detailPage, { id: item.id })}>{item.title}</Link>
          </Table.Cell>
          <Table.Cell width={2}>{formatDateTime(item.datetime)}</Table.Cell>
          <Table.Cell>{item.status}</Table.Cell>
          <Table.Cell width={1}>{item.sending_status}</Table.Cell>
          <Table.Cell>
            <YesNo value={item.editor_approved} />
          </Table.Cell>
          <Table.Cell width={2}>
            <Admin value={item.editor} />
          </Table.Cell>
          <Table.Cell>
            <YesNo value={item.marketing_approved} />
          </Table.Cell>
          <Table.Cell width={2}>
            <Admin value={item.marketer} />
          </Table.Cell>
          <Table.Cell width={2}>
            <Admin value={item.created_by} />
          </Table.Cell>
          <Table.Cell width={1}>{item.sent_count}</Table.Cell>
          <Table.Cell>{item.receivers_count}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default WeeklySteelTable;
