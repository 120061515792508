import * as yup from 'yup';
import 'utils/yup';

import { POWER_MATERIAL_SLUG } from 'domainConstants';

export function createNewsletterSendTestSchema(productSlug) {
  let schema = {
    sendTo: yup
      .string()
      .strongEmail()
      .required('Email is required.')
  };

  if (productSlug === POWER_MATERIAL_SLUG) {
    schema.recipientGroup = yup
      .string()
      .required('Recipient Group is required.');
  }

  return yup.object().shape(schema);
}
