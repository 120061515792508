import React from 'react';

import { Formik } from 'formik';

import { Form } from 'semantic-ui-react';

import { handleFormErrors, prepareFormData } from 'utils/forms';

import { notifySuccess, notifyErrors } from 'utils/notifications';

import {
  Button,
  Html,
  Field,
  FormRow,
  InputField,
  RichTextAreaField
} from 'components';

import {
  clientGetLatestDailyNewsletterIssue,
  clientSendLatestDailyNewsletterIssue
} from './sdk';

import { sendLatestIssueSchema } from './schema';

class DailyNewsletters extends React.Component {
  state = {
    latestIssue: null
  };

  async componentDidMount() {
    const { product, client } = this.props;

    const { success, data, errors } = await clientGetLatestDailyNewsletterIssue(
      client.id,
      product.id
    );

    if (success) {
      this.setState({ latestIssue: data });
    } else {
      notifyErrors(errors);
    }
  }

  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const { client, product, onClose } = this.props;
    const data = prepareFormData(values);

    setSubmitting(true);

    const { success, errors } = await clientSendLatestDailyNewsletterIssue(
      client.id,
      product.id,
      data
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Latest issue sent.');
      onClose();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const { latestIssue } = this.state;

    const initialValues = {
      subject: 'Kallanish Newsletter',
      html: ''
    };

    return (
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={sendLatestIssueSchema}
          onSubmit={this.handleSubmit}>
          {({ values, handleSubmit, isSubmitting }) => (
            <Form>
              <FormRow>
                <Field
                  required
                  label="Subject"
                  component={InputField}
                  name="subject"
                />
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={isSubmitting}>
                  Send
                </Button>
              </FormRow>
              <FormRow>
                <Field label="HTML" component={RichTextAreaField} name="html" />
              </FormRow>
            </Form>
          )}
        </Formik>
        {latestIssue && <Html>{latestIssue.html}</Html>}
      </div>
    );
  }
}

export default DailyNewsletters;
