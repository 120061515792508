import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Segment, Table, Dimmer, Loader } from 'semantic-ui-react';

import { SortableHeader, TableFooter } from 'components';

import PipelineItemRow from './PipelineItemRow';

class PipelineItemsTable extends React.Component {
  render() {
    const { data, sort, orderBy, canUpdate, pagination } = this.props;

    return (
      <Segment>
        {_.isNil(data) && (
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        )}
        {!_.isNil(data) && (
          <Table celled selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  singleLine
                  style={{ fontWeight: '400' }}
                  colspan="10">
                  {pagination}
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <SortableHeader sort={sort} orderBy={orderBy} field="client_id">
                  ID
                </SortableHeader>
                <SortableHeader sort={sort} orderBy={orderBy} field="company">
                  Company
                </SortableHeader>
                <SortableHeader sort={sort} orderBy={orderBy} field="country">
                  Country
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={orderBy}
                  field="pipeline_type">
                  Type
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={orderBy}
                  field="invoice_type">
                  Invoice type
                </SortableHeader>
                <SortableHeader sort={sort} orderBy={orderBy} field="product">
                  Product
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={orderBy}
                  field="forecasted_value">
                  Value(GBP)
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={orderBy}
                  field="pipeline_engagement">
                  Status
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={orderBy}
                  field="forecasted_date">
                  Forecasted Date
                </SortableHeader>
                <SortableHeader sort={sort} orderBy={orderBy} field="certainty">
                  Certainty
                </SortableHeader>
                {canUpdate && <Table.HeaderCell>Actions</Table.HeaderCell>}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {_.map(data, item => (
                <PipelineItemRow
                  item={item}
                  canUpdate={canUpdate}
                  fetchPipelineItems={this.props.fetchPipelineItems}
                />
              ))}
            </Table.Body>
            <TableFooter>{pagination}</TableFooter>
          </Table>
        )}
      </Segment>
    );
  }
}

export default withRouter(PipelineItemsTable);
