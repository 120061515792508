import React from 'react';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { clientReminderCreateSchema } from './schema';
import { clientReminderCreate } from './sdk';

import {
  Field,
  FormRow,
  DateField,
  InputField,
  ClientReminderTimeSelect,
  CreateButton,
  UnsavedFormPrompt
} from 'components';

class ClientReminderForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { client, fetchClient } = this.props;

    const { setSubmitting, setFieldError, resetForm } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);
    const { errors, success } = await clientReminderCreate(client.id, data);
    setSubmitting(false);

    if (success) {
      notifySuccess('Reminder added');
      resetForm();
      fetchClient();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const initialValues = {
      sentDate: null,
      time: 8,
      description: ''
    };

    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        validationSchema={clientReminderCreateSchema}>
        {({ values, handleSubmit, isSubmitting, dirty }) => (
          <Form>
            <UnsavedFormPrompt
              when={dirty}
              formName="Client reminder create form"
            />
            <FormRow alignCenter>
              <Field
                required
                width={2}
                name="sentDate"
                component={DateField}
                label="Sent date"
              />

              <Field
                required
                width={2}
                name="time"
                component={ClientReminderTimeSelect}
                label="Time"
              />

              <Field
                required
                width={12}
                name="description"
                component={InputField}
                label="Description"
              />
              <CreateButton onClick={handleSubmit} disabled={isSubmitting}>
                Add
              </CreateButton>
            </FormRow>
          </Form>
        )}
      </Formik>
    );
  }
}

export default ClientReminderForm;
