import _ from 'lodash';
import React from 'react';

import { Segment } from 'semantic-ui-react';

import { PermissionsRequired, PERMISSIONS } from 'permissions';

import {
  Page,
  Header,
  Flex,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  NoDataMessage,
  Filters as SharedFilters,
  Loader
} from 'components';

import { ClientLoginsTable } from './components';

import { clientLoginList } from './sdk';

import { FILTERS } from './utils';

const ClientLoginInformationList = ({
  user,
  data,
  limit,
  count,
  page,
  changePage,
  changeRowsPerPage,
  filters,
  filterBy,
  clear,
  sort,
  orderBy,
  isFetching
}) => (
  <Page user={user}>
    <Page.Body>
      <Page.Content>
        <Flex spaceBetween>
          <Header>Website Login Attempts</Header>
        </Flex>
        <>
          <SharedFilters
            filters={filters}
            filterBy={filterBy}
            clearFilters={clear}
            count={count}
            layout={FILTERS}
          />
          {isFetching && (
            <Segment>
              <Loader />
            </Segment>
          )}
          {_.isEmpty(data) && !isFetching && <NoDataMessage />}
          {!_.isEmpty(data) && !isFetching && (
            <ClientLoginsTable
              clientLogins={data}
              sort={sort}
              orderBy={orderBy}
              isFetching={isFetching}
              pagination={
                <Pagination
                  limit={limit}
                  count={count}
                  page={page}
                  changePage={changePage}
                  changeRowsPerPage={changeRowsPerPage}
                />
              }
            />
          )}
        </>
      </Page.Content>
    </Page.Body>
  </Page>
);

export default ListViewEssentials({
  sdk: clientLoginList
})(
  LoginRequired(
    PermissionsRequired([PERMISSIONS.VIEW_CLIENTS])(ClientLoginInformationList)
  )
);
