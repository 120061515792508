import React from 'react';

import { Link } from 'react-router-dom';

import { Flex } from 'components';

const TotalRow = ({
  eventIndexValue,
  eventPaidSum,
  linkTotal,
  outstandingSum,
  linkOutstanding,
  linkGrandTotal
}) => (
  <Flex spaceBetween>
    <Flex flexStart style={{ flex: '1' }}>
      <div>{eventIndexValue}</div>
    </Flex>
    <Flex
      style={{
        flexDirection: 'column',
        alignItems: 'flex-end',
        flex: '1',
        textAlign: 'right'
      }}>
      <div>
        <b> Total paid </b>
      </div>
      <div>
        <Link to={linkTotal}>
          <b>{eventPaidSum}£</b>
        </Link>
      </div>
      <div>
        <b> Outstanding (Registered) </b>
      </div>
      <div>
        <Link to={linkOutstanding}>
          <b>{outstandingSum}£</b>
        </Link>
      </div>
      <div>
        <b> Grand total </b>
      </div>
      <div>
        <Link to={linkGrandTotal}>
          <b>{eventPaidSum + outstandingSum}£</b>
        </Link>
      </div>
    </Flex>
  </Flex>
);

export default TotalRow;
