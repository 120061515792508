import React from 'react';

import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { formatDate } from 'utils';

import { Admin, TableFooter, YesNo, SortableHeader } from 'components';

import { pageUrl } from 'config/routes';

import { CourseDetail } from 'pages';

const CourseTable = ({ courses, sort, orderBy, pagination }) => (
  <Table celled selectable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell singleLine style={{ fontWeight: '400' }} colspan="8">
          {pagination}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <SortableHeader sort={sort} orderBy={orderBy} field="title">
          Title
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="is_published">
          Is published
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="is_conference">
          Event type
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="date_start">
          Start date
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="created_by">
          Created by
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="created_at">
          Created at
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="updated_by">
          Updated by
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="updated_at">
          Updated at
        </SortableHeader>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {courses.map(course => (
        <Table.Row key={courses.id}>
          <Table.Cell width={4}>
            <Link to={pageUrl(CourseDetail, { courseId: course.id })}>
              {course.title}
            </Link>
          </Table.Cell>
          <Table.Cell>
            <YesNo value={course.is_published} />
          </Table.Cell>
          <Table.Cell>{course.event_type}</Table.Cell>
          <Table.Cell>{formatDate(course.date_start)}</Table.Cell>
          <Table.Cell>
            <Admin value={course.created_by} />
          </Table.Cell>
          <Table.Cell>{formatDate(course.created_at)}</Table.Cell>
          <Table.Cell>
            <Admin value={course.updated_by} />
          </Table.Cell>
          <Table.Cell>{formatDate(course.updated_at)}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default CourseTable;
