import React from 'react';

import { Formik } from 'formik';

import { Form } from 'semantic-ui-react';

import { handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';
import { Field, TextAreaField, UpdateButtonInRow } from 'components';

class MetaDataForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    setSubmitting(true);

    const { errors, success } = await this.props.pageUpdate(
      this.props.page.id,
      values
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Meta Data updated successfully!');
      this.props.fetchPage();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const { meta_keywords, meta_description } = this.props.page;
    const initialValues = { meta_keywords, meta_description };

    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        enableReinitialize={true}>
        {({ values, handleSubmit, isSubmitting }) => {
          return (
            <Form>
              <Field
                name="meta_keywords"
                component={TextAreaField}
                label="Meta Keywords"
              />
              <Field
                name="meta_description"
                component={TextAreaField}
                label="Meta Description"
              />

              <UpdateButtonInRow
                subject="Meta Information"
                onClick={handleSubmit}
                disabled={isSubmitting}
              />
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default MetaDataForm;
