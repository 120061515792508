import React from 'react';

import { withRouter } from 'react-router-dom';

import _ from 'lodash';

import { pageUrl } from 'config/routes';

import { WebArticleCreate } from 'pages';

import {
  Page,
  Header,
  Flex,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Button,
  NoDataMessage,
  Filters as SharedFilters
} from 'components';
import {
  PermissionsRequired,
  PERMISSIONS,
  DisableableElement,
  hasPermission,
  VisibleIf
} from 'permissions';

import { articleList } from './sdk';

import { ArticleTable } from './components';

import { FILTERS as LAYOUT_FILTERS } from './utils';

import { getProductName } from 'utils';

class WebArticleList extends React.Component {
  createArticle = () => {
    const { productSlug } = this.props.match.params;
    const location = pageUrl(WebArticleCreate, { productSlug });

    this.props.history.push(location);
  };

  render() {
    const { productSlug } = this.props.match.params;
    const {
      user,
      data,
      limit,
      count,
      page,
      changePage,
      changeRowsPerPage,
      filters,
      clear,
      filterBy,
      orderBy,
      sort,
      crumbs
    } = this.props;

    const productTitle = getProductName(productSlug);

    return (
      <Page user={user} key={`${productSlug}-articles-list`}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Flex spaceBetween>
              <Header>{productTitle} Articles</Header>
              <VisibleIf
                user={user}
                permissions={[PERMISSIONS.CREATE_ARTICLES]}>
                <Button size="large" onClick={this.createArticle}>
                  Create {productTitle} Article
                </Button>
              </VisibleIf>
            </Flex>
            {data && (
              <>
                <DisableableElement
                  disabled={!hasPermission(user, PERMISSIONS.SEARCH_ARTICLES)}>
                  <SharedFilters
                    filters={filters}
                    clearFilters={clear}
                    count={count}
                    filterBy={filterBy}
                    layout={LAYOUT_FILTERS(productSlug)}
                  />
                </DisableableElement>
                {_.isEmpty(data) && <NoDataMessage />}
                {!_.isEmpty(data) && (
                  <ArticleTable
                    orderBy={orderBy}
                    sort={sort}
                    articles={data}
                    productSlug={productSlug}
                    pagination={
                      <Pagination
                        limit={limit}
                        count={count}
                        page={page}
                        changePage={changePage}
                        changeRowsPerPage={changeRowsPerPage}
                      />
                    }
                  />
                )}
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: props => {
    const { productSlug } = props.match.params;
    return _.partial(articleList, productSlug);
  },
  passPropsToSdk: true
})(
  LoginRequired(
    PermissionsRequired([PERMISSIONS.VIEW_ARTICLES])(withRouter(WebArticleList))
  )
);
