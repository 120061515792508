import React from 'react';

import _ from 'lodash';

import { Segment, Header, Form, Table } from 'semantic-ui-react';

import { InvoiceItemForm } from './components';

import { OpenModalButton, PromoCodeApplicationModal } from 'components';

const INVOICE_STATUS = {
  PAID: 'Paid'
};

const getInvoiceItemData = item => {
  const data = {
    id: item.id,
    description: item.description,
    discountName: item.discount_name,
    discountType: item.discount_type,
    discountValue: item.discount_value,
    price: item.price,
    quantity: item.quantity,
    months: item.months,
    itemType: item.item.item_type
  };

  return data;
};

class InvoiceItemTable extends React.Component {
  render() {
    const { invoice, fetchInvoice } = this.props;

    const invoiceStatus = invoice.invoice_status;
    const invoiceItems = invoice.invoice_items;
    const currency = _.get(invoice, 'payment_info.currency.code', '');

    return (
      <Segment>
        <Header as="h2" style={{ paddingBottom: '1.5em' }}>
          Invoice Items
          {!_.isEmpty(invoiceItems) && (
            <Form>
              {invoiceStatus !== INVOICE_STATUS.PAID && (
                <OpenModalButton
                  style={{ float: 'right' }}
                  color="blue"
                  modal={{
                    header: 'Promo Code Application',
                    render: props => (
                      <PromoCodeApplicationModal
                        invoiceId={invoice.id}
                        fetchInvoice={fetchInvoice}
                        {...props}
                      />
                    )
                  }}>
                  Apply Promo Code
                </OpenModalButton>
              )}
            </Form>
          )}
        </Header>
        {_.isEmpty(invoiceItems) && <p>Client has no invoice items yet.</p>}
        <Table compact={true} selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Service Description</Table.HeaderCell>
              <Table.HeaderCell>Quantity</Table.HeaderCell>
              <Table.HeaderCell>Months</Table.HeaderCell>
              <Table.HeaderCell>Price({currency})</Table.HeaderCell>
              <Table.HeaderCell>Discount type</Table.HeaderCell>
              <Table.HeaderCell>Discount name</Table.HeaderCell>
              <Table.HeaderCell>Discount value</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {invoiceItems.map(getInvoiceItemData).map(item => (
              <InvoiceItemForm
                key={item.id}
                invoiceItem={item}
                invoice={invoice}
                fetchInvoice={fetchInvoice}
              />
            ))}
          </Table.Body>
        </Table>
        <Header as="h3">Total price: {invoice.total_price}</Header>
      </Segment>
    );
  }
}

export default InvoiceItemTable;
