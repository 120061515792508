import * as yup from 'yup';
import 'utils/yup';

export const MailshotGroupValidationSchema = yup.object().shape({
  fromEmail: yup
    .string()
    .strongEmail()
    .required('Sender email is required.'),
  title: yup.string().required('Mailshot title is required.')
});

export const INITIAL_VALUES = {
  fromEmail: '',
  title: ''
};
