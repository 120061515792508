import React from 'react';

import _ from 'lodash';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { Form } from 'semantic-ui-react';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import {
  Field,
  UpdateButtonInRow,
  CheckboxField,
  DjangoFileField,
  UnsavedFormPrompt,
  CreatedUpdatedFormValues
} from 'components';

import { tradeExcelUpdate } from './sdk';

class TradeExcelUpdateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { fetchTradeExcel } = this.props;
    const { tradeExcelId } = this.props.match.params;

    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    const formData = new FormData();

    _.mapKeys(data, (value, key) => formData.append(key, value));

    const { errors, success } = await tradeExcelUpdate(tradeExcelId, formData);

    setSubmitting(false);

    if (success) {
      notifySuccess('Trade excel has been updated successfully.');
      fetchTradeExcel();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const {
      created_at: createdAt,
      created_by: createdBy,
      updated_at: updatedAt,
      updated_by: updatedBy,
      is_published: isPublished,
      name,
      trade_excel: tradeExcel
    } = this.props.data;

    const nonFormValues = {
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
      name
    };

    const initialValues = {
      isPublished
    };

    return (
      <Formik
        enableReinitialize={true}
        onSubmit={this.handleSubmit}
        initialValues={initialValues}>
        {({ handleSubmit, isSubmitting, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty} />
            <Field
              width={4}
              required
              name="tradeExcel"
              label="File"
              fileUrl={tradeExcel}
              component={DjangoFileField}
              accept={{
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
                  '.xlsx'
                ],
                'application/vnd.ms-excel': ['.xls']
              }}
            />
            <Field
              required
              name="isPublished"
              label="Published"
              component={CheckboxField}
            />
            <CreatedUpdatedFormValues
              values={nonFormValues}
              itemsBefore={[
                { label: 'Current file name', value: nonFormValues.name }
              ]}
            />
            <UpdateButtonInRow
              subject="Trade Excel"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(TradeExcelUpdateForm);
