import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const invoiceDetail = async id => {
  const url = `${BASE_URL}/crm/invoices/${id}/`;

  return await requestSdk(() => get(url));
};

export const invoiceSaveAsValid = async id => {
  const url = `${BASE_URL}/crm/invoices/${id}/save-as-valid/`;

  return await requestSdk(() => post(url));
};
