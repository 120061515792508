import React from 'react';

import { Field, SelectField } from 'components';
const STATUSES = ['Sent', 'Draft', 'Ready', 'In Process'];

class MaishotStatusSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = STATUSES.map(status => {
      return {
        value: status,
        label: status,
        key: status
      };
    });

    return (
      <Field
        name={name || 'mailshotStatus'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default MaishotStatusSelect;
