import {
  InputField,
  ArticleStatusSelect,
  AuthorSelect,
  YesNoSelect,
  DateField,
  SelectField
} from 'components';

const slugOptions = [
  {
    key: 'Steel',
    value: 'Steel',
    label: 'Steel'
  },
  {
    key: 'PowerMaterials',
    value: 'Power Materials',
    label: 'Power Materials'
  }
];

export const FILTERS = [
  [
    {
      name: 'article',
      emptyValue: '',
      component: InputField,
      placeholder: 'Article ID',
      label: 'Article ID',
      width: 6
    },
    {
      name: 'article_type',
      emptyValue: '',
      component: SelectField,
      label: 'Article Type',
      width: 4,
      props: () => ({
        options: slugOptions
      })
    }
  ],
  [
    {
      name: 'author',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: AuthorSelect,
      label: 'Author',
      width: 5
    },
    {
      name: 'updated_by',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: AuthorSelect,
      label: 'Updated By',
      width: 5
    }
  ],
  [
    {
      name: 'updated_at_from',
      emptyValue: null,
      component: DateField,
      label: 'Date from',
      width: 4
    },
    {
      name: 'updated_at_to',
      emptyValue: null,
      component: DateField,
      label: 'Date to',
      width: 4
    }
  ],
  [
    {
      name: 'percent_changed_text_min',
      emptyValue: '',
      component: InputField,
      placeholder: 'Min Percent',
      label: 'Min Percent Changed Text',
      width: 3
    },
    {
      name: 'percent_changed_text_max',
      emptyValue: '',
      component: InputField,
      placeholder: 'Max Percent',
      label: 'Max Percent Changed Text',
      width: 3
    },
    {
      name: 'has_text_change',
      emptyValue: null,
      fieldComponent: YesNoSelect,
      label: 'Text change',
      width: 3
    }
  ],
  [
    {
      name: 'has_status_change',
      emptyValue: null,
      fieldComponent: YesNoSelect,
      label: 'Status change',
      width: 3
    },
    {
      name: 'old_status',
      emptyValue: null,
      fieldComponent: ArticleStatusSelect,
      label: 'Old Status',
      width: 4
    },
    {
      name: 'new_status',
      emptyValue: null,
      fieldComponent: ArticleStatusSelect,
      label: 'New Status',
      width: 4
    }
  ]
];
