import React from 'react';

import _ from 'lodash';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { Form, Divider } from 'semantic-ui-react';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import {
  Field,
  UpdateButtonInRow,
  FormRow,
  InputField,
  PriceSeriesSingleSelect,
  CheckboxField,
  UnsavedFormPrompt,
  CreatedUpdatedFormValues
} from 'components';

import { validationSchema, getInitialValues } from './utils';
import { populateSlug } from 'utils';
import { priceSpreadUpdate } from './sdk';

class PriceSpreadUpdateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const data = _.pickBy(values, _.negate(_.isNil));

    const { priceSpreadId } = this.props.match.params;

    const { setSubmitting, setFieldError } = actions;

    const { errors, success } = await priceSpreadUpdate(
      priceSpreadId,
      prepareFormData(data)
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Price spread has been updated successfully.');
      this.props.fetchPriceSpread();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const initialValues = getInitialValues(this.props.data);
    const {
      created_at: createdAt,
      created_by: createdBy,
      updated_at: updatedAt,
      updated_by: updatedBy
    } = this.props.data;

    const nonFormValues = {
      createdAt,
      createdBy,
      updatedAt,
      updatedBy
    };

    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}>
        {({ isSubmitting, handleSubmit, setFieldValue, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty} />
            <FormRow>
              <Field
                required
                width={6}
                name="title"
                label="Title:"
                component={InputField}
              />

              <Field
                required
                width={4}
                name="shortTitle"
                label="Short title:"
                component={InputField}
              />
            </FormRow>
            <FormRow>
              <Field
                required
                width={6}
                name="slug"
                label="Slug:"
                component={InputField}
                onChange={(e, data) => {
                  populateSlug(data, setFieldValue);
                }}
              />
              <Field
                required
                width={4}
                name="displayAs"
                label="Display as:"
                component={InputField}
              />
              <Field
                required
                width={2}
                name="showOnHomepage"
                label="Show on homepage"
                component={CheckboxField}
              />
            </FormRow>
            <FormRow>
              <PriceSeriesSingleSelect
                required
                width={6}
                name="priceSerie1"
                label="Price Serie 1:"
              />

              <PriceSeriesSingleSelect
                required
                width={6}
                name="priceSerie2"
                label="Price Serie 2:"
              />
            </FormRow>
            <Divider />
            <FormRow widths="equal">
              <Field
                name="metaTitle"
                label="Meta Title"
                component={InputField}
              />

              <Field
                name="metaKeywords"
                label="Meta Keywords"
                component={InputField}
              />

              <Field
                name="metaDescription"
                label="Meta Description"
                component={InputField}
              />

              <Field
                name="metaStandout"
                label="Meta Standout"
                component={InputField}
              />
            </FormRow>
            <CreatedUpdatedFormValues values={nonFormValues} />
            <UpdateButtonInRow
              subject="Price Spread"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(PriceSpreadUpdateForm);
