import React from 'react';
import _ from 'lodash';

import { Field, FastField, SelectField } from 'components';
import { webArticleSectionList } from './sdk';

class WebArticleSectionSelect extends React.Component {
  state = {
    articleSections: []
  };

  async componentDidMount() {
    const { productSlug } = this.props;
    const { data, success } = await webArticleSectionList(productSlug);

    if (success) {
      this.setState({ articleSections: data });
    }
  }

  render() {
    const { name, fast = false, ...rest } = this.props;
    const { articleSections } = this.state;

    const options = articleSections.map(articleSection => {
      return {
        key: articleSection.id,
        value: articleSection.id,
        label: articleSection.name
      };
    });

    let Component = Field;
    if (fast) {
      Component = FastField;
    }

    if (!_.isEmpty(options)) {
      rest['options'] = options;
    }

    return (
      <Component
        name={name || 'articleSection'}
        component={SelectField}
        isClearable
        {...rest}
      />
    );
  }
}

export default WebArticleSectionSelect;
