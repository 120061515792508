import React from 'react';

import _ from 'lodash';
import cx from 'classnames';
import { Formik } from 'formik';
import { Table, Button, List } from 'semantic-ui-react';
import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';
import { pageUrl } from 'config/routes';
import { ClientDetail } from 'pages';

import {
  filterByItemType,
  getCorporateItems,
  getClientItems
} from 'pages/Client/utils';
import { formatDate } from 'utils';

import { removeClientFromCorporateMasterAccount } from './sdk';

import styles from './styles.module.css';

const SUBSCRIPTION = 'Subscription';

//Components for subscription status and expiry date columns which are reused in both CM and C accounts
const SubscriptionStatus = ({ clientItem, corporateMaster = false }) => (
  <List.Item style={{ color: corporateMaster ? 'black' : 'darkgreen' }}>
    {corporateMaster && '(CM) '}
    {clientItem.subscription_status.status &&
      `${clientItem.subscription_status.status} /`}
    {clientItem.item.title}
  </List.Item>
);

const SubscriptionExpiryDate = ({ clientItem, corporateMaster = false }) => (
  <List.Item style={{ color: corporateMaster ? 'black' : 'darkgreen' }}>
    {corporateMaster && '(CM) '}
    {clientItem.subscription_status.date_end &&
      `${formatDate(clientItem.subscription_status.date_end)} /`}
    {clientItem.item.title}
  </List.Item>
);

class CorporateMasterAccount extends React.Component {
  handleSubmit = async (values, actions) => {
    const { corporateProfile, client, fetchClient } = this.props;
    const { setSubmitting, setFieldError } = actions;

    const postData = {
      client: corporateProfile.user.id
    };

    setSubmitting(true);

    const { success, errors } = await removeClientFromCorporateMasterAccount(
      client.id,
      prepareFormData(postData)
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('User removed from CM account.');
      fetchClient();
    }

    if (!success) {
      handleFormErrors(errors, setFieldError);
    }
  };

  getClientDetailUrl = clientId => {
    return pageUrl(ClientDetail, { clientId });
  };

  render() {
    const { corporateProfile, client } = this.props;
    const initialValues = {
      cmAccount: null
    };

    const parentSubscriptions = filterByItemType(
      getClientItems(client),
      SUBSCRIPTION
    );

    const childSubscriptions = filterByItemType(
      getCorporateItems(corporateProfile),
      SUBSCRIPTION
    );

    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        enableReinitialize={true}>
        {({ values, handleSubmit, isSubmitting }) => (
          <Table.Row
            key={corporateProfile.id}
            className={cx({
              [styles.unsubscribedRow]: corporateProfile.unsubscribed
            })}>
            <Table.Cell>
              <a href={this.getClientDetailUrl(corporateProfile.user.id)}>
                C {corporateProfile.user.id}
              </a>
            </Table.Cell>
            <Table.Cell>{corporateProfile.user.first_name}</Table.Cell>
            <Table.Cell>{corporateProfile.user.last_name}</Table.Cell>
            <Table.Cell width={2}>{corporateProfile.job_title}</Table.Cell>
            <Table.Cell width={2}>{corporateProfile.user.email}</Table.Cell>
            <Table.Cell>{corporateProfile.city}</Table.Cell>
            <Table.Cell>
              {_.get(corporateProfile, 'country.title', 'N/A')}
            </Table.Cell>
            <Table.Cell width={2}>
              <List bulleted>
                {parentSubscriptions.map((clientItem, index) => (
                  <SubscriptionStatus
                    corporateMaster
                    key={index}
                    clientItem={clientItem}
                  />
                ))}
                {childSubscriptions.map((clientItem, index) => (
                  <SubscriptionStatus key={index} clientItem={clientItem} />
                ))}
              </List>
            </Table.Cell>
            <Table.Cell width={2}>
              <List bulleted>
                {parentSubscriptions.map((clientItem, index) => (
                  <SubscriptionExpiryDate
                    corporateMaster
                    key={index}
                    clientItem={clientItem}
                  />
                ))}
                {childSubscriptions.map((clientItem, index) => (
                  <SubscriptionExpiryDate key={index} clientItem={clientItem} />
                ))}
              </List>
            </Table.Cell>
            <Table.Cell>
              <Button
                onClick={handleSubmit}
                type="submit"
                disabled={isSubmitting}>
                Remove
              </Button>
            </Table.Cell>
          </Table.Row>
        )}
      </Formik>
    );
  }
}

export default CorporateMasterAccount;
