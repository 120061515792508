import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const mailshotAddClient = async (mailshotId, data) => {
  const url = `${BASE_URL}/emails/mailshots/${mailshotId}/add-client/`;

  return await requestSdk(() => post(url, data));
};

export const mailshotRemoveClient = async (mailshotId, data) => {
  const url = `${BASE_URL}/emails/mailshots/${mailshotId}/remove-client/`;

  return await requestSdk(() => post(url, data));
};

export const mailshotAddClients = async (mailshotId, data) => {
  const url = `${BASE_URL}/emails/mailshots/${mailshotId}/add-clients/`;

  return await requestSdk(() => post(url, data));
};

export const mailshotRemoveClients = async (mailshotId, data) => {
  const url = `${BASE_URL}/emails/mailshots/${mailshotId}/remove-clients/`;

  return await requestSdk(() => post(url, data));
};
