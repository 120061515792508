import React from 'react';

import { withRouter } from 'react-router-dom';

import { Header, Segment } from 'semantic-ui-react';

import { Page, LoginRequired, Meta } from 'components';
import { notifyErrors } from 'utils/notifications';

import { GlossaryTermUpdateForm } from './components';
import { glossaryTermDetail, glossaryTermUpdateMeta } from './sdk';

class GlossaryTermDetail extends React.Component {
  state = {
    glossaryTerm: null
  };

  fetchGlossaryTerm = async () => {
    const { glossaryTermId } = this.props.match.params;

    const { data, errors, success } = await glossaryTermDetail(glossaryTermId);

    if (success) {
      this.setState({ glossaryTerm: data });
    } else {
      notifyErrors(errors);
    }
  };

  componentDidMount() {
    this.fetchGlossaryTerm();
  }

  render() {
    const { user, crumbs } = this.props;

    const { glossaryTerm } = this.state;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Header as="h1">Edit Glossary Term</Header>

            {glossaryTerm && (
              <Segment>
                <GlossaryTermUpdateForm
                  glossaryTerm={glossaryTerm}
                  fetchGlossaryTerm={this.fetchGlossaryTerm}
                />
                <Meta
                  object={glossaryTerm}
                  fetchObject={this.fetchGlossaryTerm}
                  sdk={glossaryTermUpdateMeta}
                />
              </Segment>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(withRouter(GlossaryTermDetail));
