import { useCallback, useMemo, useRef } from 'react';

function usePolling({ callback, delay }) {
  const pollingRef = useRef(null);

  const startPolling = useCallback(() => {
    pollingRef.current = setInterval(callback, delay);
  }, [callback, delay]);

  const stopPolling = useCallback(() => {
    clearInterval(pollingRef.current);
  }, []);

  return useMemo(() => ({ startPolling, stopPolling }), [
    startPolling,
    stopPolling
  ]);
}

export default usePolling;
