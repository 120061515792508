import _ from 'lodash';
import React from 'react';
import cx from 'classnames';

import styles from './styles.module.css';

const DisplayItem = ({ label, value, onClick }) => (
  <div className={styles.item}>
    <label>{label}</label>
    <div
      onClick={onClick}
      className={cx({ [styles.clicky]: !_.isNil(onClick) })}>
      {value ? value : 'None'}
    </div>
  </div>
);

export default DisplayItem;
