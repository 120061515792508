import _ from 'lodash';
import React from 'react';
import { Radio, Table } from 'semantic-ui-react';

import { AddRemoveButton, Flex, Tooltip } from 'components';

import { pluralize } from 'utils/string';

function TableWrapper({ children }) {
  return (
    <div
      style={{
        maxHeight: '350px',
        overflowY: 'auto',
        display: 'block',
        width: '100%'
      }}>
      {children}
    </div>
  );
}

export default function BaseTable({
  entity,
  data,
  isLoading,
  filtersComponent,
  toggleInSnpashot,
  toggleEmailNotification
}) {
  return (
    <>
      {filtersComponent}
      <TableWrapper>
        <Table celled striped singleLine>
          <Table.Header style={{ position: 'sticky', zIndex: 1, top: 0 }}>
            <Table.Row>
              <Table.HeaderCell>Price {entity}</Table.HeaderCell>
              <Table.HeaderCell>Email Notifications</Table.HeaderCell>
              <Table.HeaderCell>Added By</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {isLoading ? (
              <>
                {_.range(9).map(index => (
                  <Table.Row key={index}>
                    {_.range(3).map(index => (
                      <Table.Cell key={index}>Loading...</Table.Cell>
                    ))}
                    <Table.Cell>
                      <AddRemoveButton disabled />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </>
            ) : (
              <>
                {data.map(priceEntity => (
                  <Table.Row key={priceEntity.id}>
                    <Table.Cell>{priceEntity.display_as}</Table.Cell>
                    <Table.Cell>
                      <Radio
                        style={{ zIndex: 0 }}
                        toggle
                        disabled={!priceEntity.in_snapshot}
                        checked={priceEntity.email_notifications_enabled}
                        onChange={(e, target) => {
                          toggleEmailNotification(priceEntity);
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell>{priceEntity.added_by || 'N/A'}</Table.Cell>
                    <Table.Cell>
                      <Tooltip
                        on="hover"
                        position="top"
                        content={`Client does not have an active subscription that grants them access to this price ${entity.toLowerCase()}!`}
                        disabled={
                          priceEntity.has_access || priceEntity.in_snapshot
                        }
                        trigger={
                          <div>
                            <AddRemoveButton
                              add={!priceEntity.in_snapshot}
                              onClick={() => toggleInSnpashot(priceEntity.id)}
                              disabled={
                                !priceEntity.has_access &&
                                !priceEntity.in_snapshot
                              }
                            />
                          </div>
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </>
            )}
          </Table.Body>
          <Table.Footer style={{ position: 'sticky', zIndex: 1, bottom: 0 }}>
            <Table.Row>
              <Table.HeaderCell>
                <Flex flexStart style={{ gap: '6px' }}>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>
                      {data?.length || 0}
                    </span>{' '}
                    price{' '}
                    {pluralize(entity.toLowerCase(), data?.length || 0, 's')}
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold' }}>
                      {data?.filter(priceEntity => priceEntity.in_snapshot)
                        .length || 0}
                    </span>{' '}
                    in snapshot
                  </div>
                </Flex>
              </Table.HeaderCell>
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell />
            </Table.Row>
          </Table.Footer>
        </Table>
      </TableWrapper>
    </>
  );
}
