import * as yup from 'yup';

export const contentSectionSchema = yup.object().shape({
  title: yup.string().required('Title is required.'),
  displayName: yup.string().required('Display name is required.'),
  column: yup.string().required('Column is required.'),
  sectionType: yup.string().required('Section is required.'),
  visibleFor: yup.array().required('At least on recepient is required.'),
  articleSection: yup
    .string()
    .nullable()
    .notRequired(),
  articleGroup: yup
    .string()
    .nullable()
    .notRequired()
});
