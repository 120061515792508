import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const courseAddBlock = async (courseId, data) => {
  const url = `${BASE_URL}/courses/${courseId}/add-block/`;

  return await requestSdk(() => post(url, data));
};

export const courseUpdateBlock = async (courseId, data) => {
  const url = `${BASE_URL}/courses/${courseId}/update-block/`;

  return await requestSdk(() => post(url, data));
};

export const courseRemoveBlock = async (courseId, data) => {
  const url = `${BASE_URL}/courses/${courseId}/remove-block/`;

  return await requestSdk(() => post(url, data));
};
