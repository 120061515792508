import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const assignStaffMemberToSalesTerritory = async (
  salesTerritory,
  data
) => {
  const url = `${BASE_URL}/users/sales-territories/${salesTerritory}/assign-manager/`;

  return await requestSdk(() => post(url, data));
};
