import React from 'react';

import _ from 'lodash';
import cx from 'classnames';

import { Error } from 'components';

import styles from './styles.module.css';

const TextAreaField = ({
  ref,
  label,
  className,
  form,
  field,
  onChange = () => {},
  withoutErrorMessage = false,
  withoutLabel = false,
  ...props
}) => {
  const hasErrors =
    _.get(form.touched, field.name) && _.get(form.errors, field.name);
  return (
    <div className={cx(styles.container, className)}>
      {!withoutLabel && <label>{label}</label>}
      <textarea
        ref={ref}
        {...field}
        {...props}
        onChange={(...args) => {
          field.onChange(...args);
          onChange(...args, form.values);
        }}
        className={cx({
          [styles.hasErrors]: hasErrors
        })}
      />
      {!withoutErrorMessage && hasErrors && (
        <Error className={styles.error}>{_.get(form.errors, field.name)}</Error>
      )}
    </div>
  );
};

export default TextAreaField;
