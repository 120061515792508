import React from 'react';

import { Formik } from 'formik';

import { Segment, Form } from 'semantic-ui-react';

import { buildInitialValuesFromFilters } from 'utils/filters';

import {
  SelectField,
  FormRow,
  Field,
  FilterButtons,
  DateField,
  YesNoSelect
} from 'components';

import { FILTERS } from './utils';

class Filters extends React.Component {
  submitSearch = values => {
    this.props.filterBy(values);
  };

  resetSearch = resetForm => {
    const { clearFilters } = this.props;

    resetForm();
    clearFilters();
  };

  render() {
    const { filters } = this.props;

    const initialValues = buildInitialValuesFromFilters(filters, FILTERS);

    return (
      <Segment>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={this.submitSearch}>
          {({ values, handleSubmit, resetForm }) => (
            <Form>
              <FormRow>
                <Field
                  name="reason"
                  component={SelectField}
                  label="Suppression Reason"
                  isClearable={true}
                  options={[
                    {
                      key: 'BOUNCE',
                      value: 'BOUNCE',
                      label: 'Bounce'
                    },
                    {
                      key: 'COMPLAINT',
                      value: 'COMPLAINT',
                      label: 'Complaint'
                    }
                  ]}
                />
                <Field
                  name="batch_date"
                  label="Show suppressed list batch for"
                  component={DateField}
                />
                <YesNoSelect name="is_new" label="New" />
              </FormRow>
              <FilterButtons
                searchAction={handleSubmit}
                resetAction={() => this.resetSearch(resetForm)}
              />
            </Form>
          )}
        </Formik>
      </Segment>
    );
  }
}

export default Filters;
