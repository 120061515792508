import _ from 'lodash';
import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { PriceIndexCreate } from 'pages';

import { Page, Button, ListViewEssentials, Flex, Header } from 'components';

import { PERMISSIONS, VisibleIf } from 'permissions';

import { withLoggedUser, withPermissions } from 'hooks';

import { PriceIndexesTable, CategoryOrderModal } from './components';

import { priceIndexList } from './sdk';

const DEFAULT_LIMIT = 20;

function PriceIndexList({
  user,
  data,
  count,
  page,
  limit,
  sort,
  orderBy,
  changePage,
  crumbs,
  history
}) {
  const [isOpenCategoryOrderModal, setCategoryOrderModal] = useState(false);
  const { productSlug } = useParams();

  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content crumbs={crumbs}>
          <Flex spaceBetween>
            <Header>Price Indexes</Header>
            <VisibleIf user={user} permissions={[PERMISSIONS.CREATE_PRICES]}>
              <div>
                <Button
                  color="yellow"
                  size="large"
                  onClick={() =>
                    setCategoryOrderModal(prevValue => !prevValue)
                  }>
                  Order Indexes for Category
                </Button>
                <Button
                  size="large"
                  onClick={() =>
                    history.push(pageUrl(PriceIndexCreate, { productSlug }))
                  }>
                  Create Price Index
                </Button>
              </div>
            </VisibleIf>
          </Flex>
          {data && (
            <>
              <PriceIndexesTable data={data} sort={sort} orderBy={orderBy} />
            </>
          )}
        </Page.Content>
        {isOpenCategoryOrderModal && (
          <CategoryOrderModal
            onClose={() => setCategoryOrderModal(prevValue => !prevValue)}
            productSlug={productSlug}
          />
        )}
      </Page.Body>
    </Page>
  );
}

export default ListViewEssentials({
  sdk: props => {
    const { productSlug } = props.match.params;
    return _.partial(priceIndexList, productSlug);
  },
  passPropsToSdk: true,
  limit: DEFAULT_LIMIT
})(withLoggedUser(withPermissions([PERMISSIONS.VIEW_PRICES])(PriceIndexList)));
