import * as yup from 'yup';

export const courseAddPresaleSchema = yup.object().shape({
  title: yup.string().required('Title is required.'),
  start_date: yup
    .string()
    .nullable()
    .required('Start date is required.'),
  end_date: yup
    .string()
    .nullable()
    .required('End date is required.')
});
