import React from 'react';
import { withRouter } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { EmailTemplateCreate } from 'pages';

import {
  Flex,
  Page,
  Header,
  LoginRequired,
  Button,
  ListViewEssentials,
  Pagination,
  Filters as SharedFilters
} from 'components';
import { PERMISSIONS, PermissionsRequired, VisibleIf } from 'permissions';

import { emailTemplateList } from './sdk';

import { EmailTemplateTable } from './components';

import { FILTERS } from './utils';

const EmailTemplateList = ({
  user,
  data,
  count,
  page,
  limit,
  filters,
  sort,
  filterBy,
  orderBy,
  clear,
  changePage,
  changeRowsPerPage,
  history,
  refetch
}) => (
  <Page user={user}>
    <Page.Body>
      <Page.Content>
        <Flex spaceBetween>
          <Header>Email templates</Header>
          <VisibleIf user={user} permissions={[PERMISSIONS.CREATE_MAILSHOTS]}>
            <Button
              primary
              size="large"
              onClick={() => history.push(pageUrl(EmailTemplateCreate))}>
              Create email template
            </Button>
          </VisibleIf>
        </Flex>
        {data && (
          <>
            <SharedFilters
              filters={filters}
              clearFilters={clear}
              filterBy={filterBy}
              count={count}
              layout={FILTERS}
            />
            <EmailTemplateTable
              data={data}
              sort={sort}
              orderBy={orderBy}
              refetch={refetch}
              user={user}
              pagination={
                <Pagination
                  limit={limit}
                  count={count}
                  page={page}
                  changePage={changePage}
                  changeRowsPerPage={changeRowsPerPage}
                />
              }
            />
          </>
        )}
      </Page.Content>
    </Page.Body>
  </Page>
);

export default ListViewEssentials({
  sdk: emailTemplateList
})(
  LoginRequired(
    PermissionsRequired([PERMISSIONS.VIEW_MAILSHOTS])(
      withRouter(EmailTemplateList)
    )
  )
);
