import React from 'react';

import _ from 'lodash';

import { Formik } from 'formik';

import { Form, Button } from 'semantic-ui-react';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import {
  SubscriptionSelect,
  ReportSelect,
  EventSelect,
  FormRow,
  AdvertisementSelect,
  UnsavedFormPrompt
} from 'components';

import { clientAddProducts } from './sdk';

import styles from './styles.module.css';

class ProductAddForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const data = _.pickBy(values, _.negate(_.isNil));
    const { client, fetchClient } = this.props;

    const { setSubmitting, resetForm, setFieldError } = actions;

    const { errors, success } = await clientAddProducts(
      client.id,
      prepareFormData(data)
    );

    setSubmitting(false);

    if (success) {
      resetForm();
      notifySuccess('Products added.');
      fetchClient();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const initialValues = {
      subscription: null,
      report: null,
      event: null,
      advertisement: null
    };

    return (
      <Formik onSubmit={this.handleSubmit} initialValues={initialValues}>
        {({ values, handleSubmit, isSubmitting, dirty }) => {
          const noValueSelected = _.isEmpty(
            _.pickBy(values, _.negate(_.isNil))
          );

          return (
            <Form className={styles.Form}>
              <UnsavedFormPrompt when={dirty} formName="Add product form" />
              <FormRow alignEnd>
                <SubscriptionSelect
                  name="subscription"
                  label="Subscription"
                  testId="subscription-select"
                />

                <ReportSelect name="report" label="Reports" />

                <EventSelect name="event" label="Events" />

                {/* TODO: Disabled for deployment */}
                <AdvertisementSelect
                  name="advertisement"
                  label="Advertisements"
                />

                <Form.Field>
                  <Button
                    fluid
                    data-testid="add-product-btn"
                    onClick={handleSubmit}
                    type="submit"
                    color="green"
                    disabled={isSubmitting || noValueSelected}>
                    Add Product
                  </Button>
                </Form.Field>
              </FormRow>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default ProductAddForm;
