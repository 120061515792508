import React from 'react';

import _ from 'lodash';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { notifySuccess } from 'utils/notifications';
import { handleFormErrors, prepareFormData } from 'utils/forms';
import { formatDate } from 'utils';

import {
  Field,
  FormRow,
  InputField,
  CheckboxField,
  DateField,
  HourSelect,
  ReminderSelect,
  UpdateButtonInRow,
  UnsavedFormPrompt,
  CreatedUpdatedFormValues
} from 'components';

import { clientReminderUpdate } from './sdk';
import { clientReminderUpdateSchema } from './utils';

class ClientReminderEditForm extends React.Component {
  handleSubmit = async (values, actions) => {
    let data = values;

    if (data['sentDate'] === '') {
      data['sentDate'] = null;
    }

    const { fetchClientReminder } = this.props;
    const { clientReminderId } = this.props.match.params;

    const { setSubmitting, setFieldError } = actions;

    const { errors, success } = await clientReminderUpdate(
      clientReminderId,
      prepareFormData(data)
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Client reminder has been updated successfully.');
      fetchClientReminder();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const {
      time,
      client,
      description,
      sent_date: sentDate,
      is_done: isDone,
      is_sent: isSent,
      created_by: createdBy,
      created_at: createdAt,
      updated_by: updatedBy,
      updated_at: updatedAt,
      subscription_status: subscriptionStatus
    } = this.props.data;

    const reminder = _.get(this.props.data, 'reminder.id');

    const nonFormValues = {
      client,
      createdBy,
      createdAt,
      updatedBy,
      updatedAt,
      subscriptionStatus
    };

    const initialValues = {
      description,
      sentDate,
      isDone,
      isSent,
      time,
      reminder
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        validationSchema={clientReminderUpdateSchema}>
        {({ isSubmitting, handleSubmit, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty} />
            <FormRow>
              <Field
                required
                width={4}
                component={DateField}
                name="sentDate"
                label="Sent date:"
              />
              <HourSelect
                required
                width={3}
                name="time"
                label="Time:"
                isClearable={false}
              />
              <ReminderSelect
                required
                width={4}
                name="reminder"
                label="Reminder:"
              />
              <Field
                width={2}
                component={CheckboxField}
                name="isDone"
                label="Is done"
              />

              <Field
                width={2}
                component={CheckboxField}
                name="isSent"
                label="Is sent"
              />
            </FormRow>
            <Field
              required
              width={10}
              component={InputField}
              name="description"
              label="Description:"
            />
            <CreatedUpdatedFormValues
              values={nonFormValues}
              itemsBefore={[
                {
                  label: 'Client',
                  value: nonFormValues.client.email,
                  redirectToClient: nonFormValues.client
                }
              ]}
              itemsAfter={[
                {
                  label: 'Subscription Status',
                  value: nonFormValues.subscriptionStatus?.status || 'N/A'
                },
                {
                  label: 'Subscription Start',
                  value: formatDate(
                    nonFormValues.subscriptionStatus?.date_start
                  )
                },
                {
                  label: 'Subscription End',
                  value: formatDate(nonFormValues.subscriptionStatus?.date_end)
                }
              ]}
            />
            <UpdateButtonInRow
              subject="Client Reminder"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(ClientReminderEditForm);
