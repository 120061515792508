import React from 'react';

import { Formik } from 'formik';

import { Header, Segment, Form } from 'semantic-ui-react';

import { Flex, FormRow, OpenModalButtonControlled } from 'components';

import {
  EmailTemplateSelect,
  EmailTemplateSelectModal,
  EmailTemplateTypeSelect,
  SendPresetEmailModal
} from './components';
import { sendPresetEmailValidationSchema } from './utils';
import { ALL_EMAILS } from './constants';

class SendPresetEmailForm extends React.Component {
  state = {
    sendPresetEmailModalOpened: false,
    emailTemplateSelectModalOpened: false
  };

  toggleSendPresetEmailModal = () => {
    this.setState(prevState => ({
      sendPresetEmailModalOpened: !prevState.sendPresetEmailModalOpened
    }));
  };

  toggleEmailTemplateSelectModal = () => {
    this.setState(prevState => ({
      emailTemplateSelectModalOpened: !prevState.emailTemplateSelectModalOpened
    }));
  };

  closeAllDialogs = () => {
    this.setState({
      sendPresetEmailModalOpened: false,
      emailTemplateSelectModalOpened: false
    });
  };

  render() {
    const { client } = this.props;

    const initialValues = {
      emailTemplate: null,
      emailTemplateType: ALL_EMAILS
    };

    return (
      <Segment>
        <Formik
          initialValues={initialValues}
          validationSchema={sendPresetEmailValidationSchema}>
          {({ values, setFieldValue, setValues, resetForm }) => (
            <>
              <Flex spaceBetween>
                <Header as="h2">Send preset email</Header>
                <OpenModalButtonControlled
                  basic
                  isModalOpen={this.state.emailTemplateSelectModalOpened}
                  toggleModal={this.toggleEmailTemplateSelectModal}
                  modal={{
                    header: 'Select email template',
                    render: props => (
                      <EmailTemplateSelectModal
                        onNextClick={emailTemplate => {
                          this.toggleSendPresetEmailModal();
                          setValues({
                            emailTemplate,
                            emailTemplateType: values.emailTemplateType
                          });
                        }}
                        {...props}
                      />
                    )
                  }}>
                  Expand Selection
                </OpenModalButtonControlled>
              </Flex>
              <Form>
                <FormRow alignEnd>
                  <EmailTemplateTypeSelect
                    required
                    width={5}
                    label="Email Template Type"
                    onChange={event => {
                      setFieldValue('emailTemplateType', event.target.value);
                      setFieldValue('emailTemplate', null);
                    }}
                  />
                  <EmailTemplateSelect
                    required
                    width={5}
                    label="Email Template"
                    emailTemplateType={values.emailTemplateType}
                  />

                  <OpenModalButtonControlled
                    type="submit"
                    disabled={!values.emailTemplate}
                    isModalOpen={this.state.sendPresetEmailModalOpened}
                    toggleModal={this.toggleSendPresetEmailModal}
                    modal={{
                      header: 'Send preset email',
                      render: props => (
                        <SendPresetEmailModal
                          client={client}
                          emailTemplate={values.emailTemplate}
                          onSubmitCallback={() => {
                            this.closeAllDialogs();
                            resetForm();
                          }}
                          {...props}
                        />
                      )
                    }}>
                    Next
                  </OpenModalButtonControlled>
                </FormRow>
              </Form>
            </>
          )}
        </Formik>
      </Segment>
    );
  }
}

export default SendPresetEmailForm;
