import React from 'react';

import { Button, Header, Modal } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { DisplayItem } from 'components';

class ConfirmationModal extends React.Component {
  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const {
      title,
      message,
      handleConfirmation,
      handleDecline,
      disableConfirmation = false
    } = this.props;

    return (
      <Modal
        open={this.props.open}
        onClose={this.handleClose}
        basic
        size="small">
        <Header icon="remove circle" content={title} />
        <Modal.Content>
          {message ? <DisplayItem value={message} /> : null}
          {!disableConfirmation && (
            <DisplayItem label="Would you like to continue?" value={'   '} />
          )}
        </Modal.Content>
        {!disableConfirmation && (
          <Modal.Actions>
            <Button
              basic
              color="gray"
              inverted
              onClick={() => {
                handleDecline();
                this.handleClose();
              }}>
              No
            </Button>
            <Button
              color="green"
              inverted
              onClick={() => {
                handleConfirmation();
                this.handleClose();
              }}>
              Yes
            </Button>
          </Modal.Actions>
        )}
      </Modal>
    );
  }
}

export default withRouter(ConfirmationModal);
