import React from 'react';

import { withRouter } from 'react-router-dom';

import _ from 'lodash';

import { Form, Divider } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  InputField,
  TextAreaField,
  CheckboxField,
  TitleOptionSelect,
  CountrySelect,
  EmailTypeSelect,
  BusinessActivitySelect,
  PrioritySelect,
  RegistrationTypeSelect,
  CommoditySelect,
  IndustrySelect,
  SubIndustrySelect,
  FormRow,
  AdminSelect,
  UnsavedFormPrompt,
  ErrorsAndCreateButtonInRow,
  ClientTagsSelectField
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';

import { pageUrl } from 'config/routes';

import { ClientDetail } from 'pages';

import { clientCreateSchema } from './schema';
import { clientCreateCorporateChild } from './sdk';

import {
  getDefaultInitialValues,
  getInitialValuesFromRegistrationAttempt
} from './utils';

import styles from './styles.module.css';

class ClientCreateForm extends React.Component {
  state = {
    region: ''
  };

  updateRegion = selectFieldData => {
    if (_.isNil(selectFieldData)) {
      this.setState({ region: '' });
      return;
    }

    const {
      target: {
        meta: { region }
      }
    } = selectFieldData;

    this.setState({ region });
  };

  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const postData = { ...prepareFormData(values), username: values.email };

    setSubmitting(true);

    const sdk = !_.isNil(this.props.corporateMasterId)
      ? async () =>
          clientCreateCorporateChild(postData, this.props.corporateMasterId)
      : async () => this.props.clientCreate(postData);

    const { data, errors, success } = await sdk();

    if (success) {
      if (this.props.corporateMasterId) {
        this.props.history.push(
          pageUrl(ClientDetail, { clientId: this.props.corporateMasterId })
        );

        return;
      }

      this.props.history.push(pageUrl(ClientDetail, { clientId: data.id }));
      return;
    }
    setSubmitting(false);

    handleFormErrors(errors, setFieldError);
  };

  render() {
    let initialValues = {};

    if (this.props.registrationAttempt) {
      initialValues = getInitialValuesFromRegistrationAttempt(
        this.props.registrationAttempt
      );
    } else {
      initialValues = getDefaultInitialValues();
    }

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={clientCreateSchema}
        onSubmit={this.handleSubmit}
        enableReinitialize>
        {({
          values,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          dirty,
          errors,
          touched
        }) => (
          <Form className={styles.form}>
            <UnsavedFormPrompt when={dirty && !isSubmitting} />

            <ErrorsAndCreateButtonInRow
              errors={errors}
              touched={touched}
              onClick={handleSubmit}
              disabled={isSubmitting}>
              Save & Continue edit
            </ErrorsAndCreateButtonInRow>
            <FormRow>
              <Field
                width={6}
                name="username"
                placeholder="Username ..."
                component={InputField}
                label="Username"
                disabled
                value={values.email}
              />

              <Field
                required
                name="email"
                width={5}
                placeholder="Email ..."
                component={InputField}
                label="Email"
              />
              <PrioritySelect
                required
                width={5}
                name="priority"
                label="Priority"
              />
              <EmailTypeSelect
                width={5}
                name="emailType"
                label="Email type"
                isClearable={false}
              />
            </FormRow>

            <Field
              width={11}
              name="adminComments"
              component={TextAreaField}
              label="Admin comments"
            />
            <Form.Group>
              <TitleOptionSelect width={4} name="title" label="Title" />

              <Field
                width={6}
                name="firstName"
                component={InputField}
                label="First name"
              />

              <Field
                width={6}
                name="lastName"
                component={InputField}
                label="Last name"
              />
            </Form.Group>

            <Form.Group>
              <Field
                width={6}
                name="jobTitle"
                component={InputField}
                label="Job title"
              />

              <Field
                width={6}
                name="companyName"
                component={InputField}
                label="Company name"
              />
            </Form.Group>
            <Divider className={styles.divider} />
            <Form.Group>
              <Field
                width={8}
                name="address_1"
                component={InputField}
                label="Address 1"
              />

              <Field
                width={8}
                name="address_2"
                component={InputField}
                label="Address 2"
              />
            </Form.Group>

            <Form.Group>
              <Field
                width={6}
                name="city"
                component={InputField}
                label="City (town)"
              />

              <Field
                width={4}
                name="postcode"
                component={InputField}
                label="Postcode"
              />

              <Field
                width={6}
                name="stateOrProvince"
                component={InputField}
                label="State or province"
              />
            </Form.Group>

            <Form.Group>
              <CountrySelect
                width={6}
                name="country"
                label="Country"
                onChange={this.updateRegion}
              />

              <Field
                width={6}
                name="region"
                component={InputField}
                label="Region"
                disabled
                value={this.state.region}
              />
            </Form.Group>

            <Divider className={styles.divider} />
            <Form.Group>
              <Field
                width={10}
                name="website"
                component={InputField}
                label="Website"
              />

              <Field
                width={6}
                name="phone"
                component={InputField}
                label="Phone"
              />
            </Form.Group>
            <Form.Group>
              <Field width={5} name="fax" component={InputField} label="Fax" />

              <Field
                width={5}
                name="mobile"
                component={InputField}
                label="Mobile"
              />

              <Field
                width={6}
                name="linkedinProfile"
                component={InputField}
                label="LinkedIn Profile"
              />
            </Form.Group>

            <Form.Group>
              <RegistrationTypeSelect
                width={5}
                name="registrationType"
                label="Registration Type"
              />

              <Field
                width={3}
                name="unsubscribed"
                component={CheckboxField}
                label="Unsubscribed"
              />

              <Field
                width={3}
                name="autoEmails"
                component={CheckboxField}
                label="Auto emails"
              />
            </Form.Group>

            <Form.Group>
              <BusinessActivitySelect
                width={6}
                name="businessActivity"
                label="Business Activity"
              />

              <CommoditySelect
                width={6}
                name="commodity"
                label="Commodity"
                required
              />
              <AdminSelect
                width={6}
                name="accountManager"
                label="Account Manager"
              />
            </Form.Group>
            <Form.Group>
              <IndustrySelect
                width={6}
                required
                name="industry"
                label="Industry"
                onChange={e => setFieldValue('subIndustry', null)}
              />

              <SubIndustrySelect
                width={6}
                name="subIndustry"
                label="Sub-Industry"
                disabled={_.isNil(values.industry)}
                industry={values.industry}
              />
            </Form.Group>

            <Field
              width={16}
              name="personalStatement"
              component={TextAreaField}
              label="Personal statement"
            />

            <Field
              width={8}
              component={ClientTagsSelectField}
              name="tags"
              label="Tags"
            />

            <ErrorsAndCreateButtonInRow
              errors={errors}
              touched={touched}
              onClick={handleSubmit}
              disabled={isSubmitting}>
              Save & Continue edit
            </ErrorsAndCreateButtonInRow>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(ClientCreateForm);
