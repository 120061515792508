import React from 'react';

import { Header, Segment, Divider } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { LoginRequired, Page } from 'components';
import { PERMISSIONS, PermissionsRequired } from 'permissions';

import { MailshotGroupCreateForm } from './components';

const MailshotGroupCreate = ({ user, crumbs, history }) => (
  <Page user={user}>
    <Page.Body>
      <Page.Content crumbs={crumbs}>
        <Segment>
          <Header as="h1">Create Mailshot Group</Header>
          <Divider />
          <MailshotGroupCreateForm history={history} />
        </Segment>
      </Page.Content>
    </Page.Body>
  </Page>
);

export default LoginRequired(
  PermissionsRequired([PERMISSIONS.CREATE_MAILSHOTS])(
    withRouter(MailshotGroupCreate)
  )
);
