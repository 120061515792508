import { SelectField, DateField } from 'components';

const currencyOptions = [
  {
    key: 'usd',
    value: 'USD',
    label: 'USD'
  },
  {
    key: 'gbp',
    value: 'GBP',
    label: 'GBP'
  },
  {
    key: 'eur',
    value: 'EUR',
    label: 'EUR'
  }
];

export const FILTERS = [
  [
    {
      name: 'date_to',
      emptyValue: null,
      component: DateField,
      label: 'Date',
      width: 4
    },
    {
      name: 'currency',
      emptyValue: 'EUR',
      component: SelectField,
      label: 'Currency',
      width: 4,
      props: () => ({
        options: currencyOptions
      })
    }
  ]
];
