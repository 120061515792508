import React from 'react';
import _ from 'lodash';

import { Segment, Button } from 'semantic-ui-react';

import {
  Page,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Filters as SharedFilters,
  Flex,
  Loader
} from 'components';

import { PipelineItemsTable, Statistics } from './components';

import { FILTERS_LAYOUT } from './utils';

import { pipelinesDashboardApi } from './sdk';

class GeneralPipelinesDashboard extends React.Component {
  componentWillMount() {
    const { history } = this.props;
    if (_.isEmpty(history.location.search)) {
      const month = new Date().getMonth() + 1;
      const year = new Date().getFullYear();

      const queryParams = `?forecasted_month=${month}&forecasted_year=${year}`;

      history.replace({ search: queryParams });
      this.props.initialize();
    }
  }

  showStatisticsPerProduct = () => {
    const { history } = this.props;

    let queryParams = this.props.history.location.search;

    queryParams = `${queryParams}&aggregate_by_product=true`;
    history.replace({ search: queryParams });

    this.props.initialize();
  };

  showStatisticsPerSales = () => {
    const { history } = this.props;

    let queryParams = this.props.history.location.search.replace(
      'aggregate_by_product=true',
      ''
    );

    history.replace({ search: queryParams });

    this.props.initialize();
  };

  render() {
    const {
      user,
      crumbs,
      data,
      filters,
      count,
      filterBy,
      clear,
      limit,
      page,
      sort,
      orderBy,
      changePage,
      changeRowsPerPage,
      response,
      isFetching
    } = this.props;

    const statistics = _.get(response, 'aggregations', {});

    const perProduct = _.get(filters, 'aggregate_by_product', false);

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Header>General Pipeline Dashboard</Header>
            <SharedFilters
              filters={filters}
              count={count}
              filterBy={filterBy}
              clearFilters={clear}
              layout={FILTERS_LAYOUT}
            />

            {isFetching && <Loader />}

            {data && !isFetching && (
              <>
                <Segment compact>
                  <Flex flexStart style={{ marginBottom: '15px' }}>
                    <Button
                      basic
                      color="black"
                      onClick={this.showStatisticsPerSales}
                      disabled={!perProduct}>
                      Per Sales Manager
                    </Button>
                    <Button
                      basic
                      color="black"
                      onClick={this.showStatisticsPerProduct}
                      disabled={perProduct}>
                      Per Product
                    </Button>
                  </Flex>
                  <Statistics statistics={statistics} />
                </Segment>
                <PipelineItemsTable
                  data={data}
                  orderBy={orderBy}
                  sort={sort}
                  pagination={
                    <Pagination
                      limit={limit}
                      count={count}
                      page={page}
                      changePage={changePage}
                      changeRowsPerPage={changeRowsPerPage}
                    />
                  }
                />
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}
export default ListViewEssentials({
  sdk: pipelinesDashboardApi
})(LoginRequired(GeneralPipelinesDashboard));
