import React from 'react';

import _ from 'lodash';
import { Link, useParams } from 'react-router-dom';
import { Table, Icon } from 'semantic-ui-react';
import { ExternalLinkButton, Flex } from 'components';

import moment from 'moment';
import { formatDateForApi } from 'utils';

import { pageUrl } from 'config/routes';

import { PriceIndexDetail, PriceIndexChart } from 'pages';

import { formatDate } from 'utils';

import { downloadSpreadsheet } from './sdk';

function PriceIndexesTable({ data, sort, orderBy }) {
  const { productSlug } = useParams();
  const formattedStartDate = formatDateForApi(moment().subtract(30, 'days'));
  const formattedEndDate = formatDateForApi(moment());

  return (
    <Table celled selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell sort={sort} orderBy={orderBy} field="title">
            Title
          </Table.HeaderCell>
          <Table.HeaderCell sort={sort} orderBy={orderBy} field="currency">
            Currency
          </Table.HeaderCell>
          <Table.HeaderCell sort={sort} orderBy={orderBy} field="type">
            Index Type
          </Table.HeaderCell>
          <Table.HeaderCell sort={sort} orderBy={orderBy} field="is_free">
            Is Free
          </Table.HeaderCell>
          <Table.HeaderCell sort={sort} orderBy={orderBy} field="updated_at">
            Created By
          </Table.HeaderCell>
          <Table.HeaderCell sort={sort} orderBy={orderBy} field="updated_at">
            Updated at
          </Table.HeaderCell>
          <Table.HeaderCell>Extra Details</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {data.map(priceIndex => (
          <Table.Row key={priceIndex.id}>
            <Table.Cell>
              <Link
                to={pageUrl(PriceIndexDetail, {
                  productSlug: productSlug,
                  priceIndexesId: priceIndex.id
                })}>
                {priceIndex.title}
              </Link>
            </Table.Cell>
            <Table.Cell>{priceIndex.currency.code}</Table.Cell>
            <Table.Cell>{priceIndex.type}</Table.Cell>
            <Table.Cell>{priceIndex.is_free ? 'Yes' : 'No'}</Table.Cell>
            <Table.Cell>
              {_.get(priceIndex.created_by, 'email', 'N/A')}
            </Table.Cell>
            <Table.Cell>{formatDate(priceIndex.updated_at)}</Table.Cell>
            <Table.Cell>
              <Flex spaceBetween>
                <Link
                  to={{
                    pathname: pageUrl(PriceIndexChart, {
                      productSlug: productSlug,
                      priceIndexesId: priceIndex.id
                    }),
                    search: `?start_date=${formattedStartDate}&end_date=${formattedEndDate}`
                  }}>
                  See Chart
                </Link>
                <ExternalLinkButton
                  style={{
                    paddingLeft: '0.7em',
                    paddingRight: '0.7em'
                  }}
                  url={downloadSpreadsheet(
                    priceIndex.id,
                    formattedStartDate,
                    formattedEndDate
                  )}>
                  <Icon name="download" />
                  Spreadsheet
                </ExternalLinkButton>
              </Flex>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

export default PriceIndexesTable;
