import React from 'react';
import _ from 'lodash';
import { Formik } from 'formik';

import { Segment, Header, Form, Divider } from 'semantic-ui-react';

import {
  Field,
  DateField,
  InvoiceStatusSelect,
  CheckboxField,
  FormRow,
  InputField,
  CountrySelect,
  PaymentMethodTypeSelect,
  CurrencySelect,
  BankAccountSelect,
  PaymentReceivedSelect,
  PaymentTypeSelect,
  AdminSelect,
  UnsavedFormPrompt,
  PipelineEngagementSelect,
  ErrorsAndUpdateButtonInRow
} from 'components';
import { handleFormErrors, prepareNestedFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import {
  defaultInitialValues,
  getInitialValuesFromInvoice,
  invoiceUpdateSchema
} from './utils';

import { invoiceUpdate } from './sdk';

const UNITED_KINGDOM = 144;

class InvoiceUpdateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;
    const data = prepareNestedFormData(values);

    var postData = data;
    if (postData.payment_info.type === 'Itemized') {
      postData['payment_info'] = _.omit(postData.payment_info, [
        'service_description',
        'discount_name',
        'months',
        'combined_quantity'
      ]);
    }

    const { invoice, fetchInvoice } = this.props;

    setSubmitting(true);

    const { errors, success } = await invoiceUpdate(invoice.id, postData);

    setSubmitting(false);

    if (success) {
      notifySuccess('Invoice updated.');
      fetchInvoice();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  onChangeCountry = (e, prevValues, setFieldValue, setValues) => {
    const prevCountryValue = prevValues.contactInfo.country;

    if (prevCountryValue === UNITED_KINGDOM) {
      const { paymentInfo } = defaultInitialValues;
      setFieldValue('paymentInfo.taxName', paymentInfo.taxName);
      setFieldValue('paymentInfo.taxValue', paymentInfo.taxValue);
      setFieldValue('paymentInfo.withVat', paymentInfo.withVat);
    }

    if (_.isNil(e)) {
      return;
    }

    if (e.target.value === UNITED_KINGDOM) {
      setFieldValue('paymentInfo.taxName', 'VAT');
      setFieldValue('paymentInfo.taxValue', 20);
      setFieldValue('paymentInfo.withVat', true);
    }
  };

  handleInvoiceStatusChange = (e, prevValues, setFieldValue, setValues) => {
    if (e.target.value === 'Paid') {
      setFieldValue('pipelineEngagement', 'Paid');
    }
  };

  handleInvoicePaidOnChange = (value, setFieldValue) => {
    setFieldValue('pipelineForecastedDate', value);
  };

  render() {
    const { invoice } = this.props;
    let initialValues = null;

    if (invoice) {
      initialValues = getInitialValuesFromInvoice(invoice);
    } else {
      initialValues = defaultInitialValues;
    }

    return (
      <>
        <Formik
          enableReinitialize
          onSubmit={this.handleSubmit}
          initialValues={initialValues}
          validationSchema={invoiceUpdateSchema}>
          {({
            values,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setValues,
            dirty,
            errors,
            touched
          }) => {
            return (
              <Form>
                <UnsavedFormPrompt when={dirty} formName="Invoice form" />
                <Segment>
                  <ErrorsAndUpdateButtonInRow
                    errors={errors}
                    touched={touched}
                    subject="Invoice"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  />
                  <FormRow>
                    <PaymentTypeSelect
                      required
                      width={3}
                      name="paymentInfo.type"
                      label="Display Invoice Mode"
                      isClearable={false}
                    />
                    {values.paymentInfo.type === 'Combined' && (
                      <>
                        <Field
                          width={4}
                          name="paymentInfo.serviceDescription"
                          component={InputField}
                          label="Service Description"
                          required
                        />
                        <Field
                          width={3}
                          name="paymentInfo.discountName"
                          component={InputField}
                          label="Discount name"
                        />
                        <Field
                          width={3}
                          name="paymentInfo.months"
                          component={InputField}
                          label="Months"
                        />
                        <Field
                          width={3}
                          name="paymentInfo.combinedQuantity"
                          type="number"
                          component={InputField}
                          label="Users"
                          required
                        />
                      </>
                    )}
                  </FormRow>
                  <Divider />
                  <Header as="h2">Invoice</Header>
                  <FormRow>
                    <Field
                      width={3}
                      name="invoiceDate"
                      component={DateField}
                      label="Invoice date:"
                    />

                    <Field
                      width={3}
                      name="invoiceTermsAndConditions"
                      component={CheckboxField}
                      label="Terms and conditions"
                    />
                    <Field
                      width={3}
                      name="eventTermsAndConditions"
                      component={CheckboxField}
                      label="Event Terms and conditions"
                    />
                    <Field
                      width={3}
                      name="invoicePaidOn"
                      component={DateField}
                      label="Invoice paid on:"
                      onChangeHook={value =>
                        this.handleInvoicePaidOnChange(value, setFieldValue)
                      }
                    />

                    <InvoiceStatusSelect
                      required
                      width={4}
                      name="invoiceStatus"
                      label="Invoice status"
                      onChange={(e, prevValues) =>
                        this.handleInvoiceStatusChange(
                          e,
                          prevValues,
                          setFieldValue,
                          setValues
                        )
                      }
                    />

                    <Field
                      width={3}
                      name="linkedInvoice"
                      component={InputField}
                      label="Linked Invoice ID"
                    />
                  </FormRow>
                  <Divider />
                  <Header as="h2">Invoice Contact Info</Header>
                  <FormRow>
                    <Field
                      width={5}
                      name="contactInfo.name"
                      component={InputField}
                      label="Name"
                    />

                    <Field
                      width={5}
                      name="contactInfo.email"
                      component={InputField}
                      label="Email"
                    />
                    <Field
                      width={6}
                      name="contactInfo.companyName"
                      component={InputField}
                      label="Company Name"
                    />
                  </FormRow>
                  <FormRow>
                    <Field
                      width={4}
                      name="contactInfo.phone"
                      component={InputField}
                      label="Phone"
                    />
                    <Field
                      width={6}
                      name="contactInfo.address1"
                      component={InputField}
                      label="Address 1"
                    />

                    <Field
                      width={6}
                      name="contactInfo.address2"
                      component={InputField}
                      label="Address 2"
                    />
                  </FormRow>
                  <FormRow>
                    <Field
                      width={4}
                      name="contactInfo.city"
                      component={InputField}
                      label="City"
                    />

                    <Field
                      width={2}
                      name="contactInfo.postcode"
                      component={InputField}
                      label="Postcode"
                    />
                    <Field
                      width={4}
                      name="contactInfo.stateOrProvince"
                      component={InputField}
                      label="Province"
                    />

                    <CountrySelect
                      width={4}
                      name="contactInfo.country"
                      label="Country"
                      onChange={(e, prevValues) =>
                        this.onChangeCountry(
                          e,
                          prevValues,
                          setFieldValue,
                          setValues
                        )
                      }
                    />
                  </FormRow>
                  <FormRow>
                    <AdminSelect
                      width={4}
                      name="contactInfo.contact"
                      label="Contact name"
                    />
                    <AdminSelect
                      width={4}
                      name="contactInfo.commission1"
                      label="Commission 1"
                    />{' '}
                    <AdminSelect
                      width={4}
                      name="contactInfo.commission2"
                      label="Commission 2"
                    />
                  </FormRow>
                  <Divider />
                  <Header as="h2">Invoice Payment Info</Header>
                  <FormRow alignCenter>
                    <Field
                      width={4}
                      name="paymentInfo.taxName"
                      label="Tax name"
                      component={InputField}
                    />

                    <Field
                      required
                      width={2}
                      component={InputField}
                      name="paymentInfo.taxValue"
                      label="Tax value(%)"
                      type="number"
                    />
                    <Field
                      width={2}
                      name="paymentInfo.withVat"
                      component={CheckboxField}
                      label="With VAT"
                    />
                    <Field
                      width={4}
                      name="paymentInfo.vat"
                      component={InputField}
                      label="VAT number"
                    />

                    <Field
                      width={4}
                      name="paymentInfo.poNumber"
                      component={InputField}
                      label="PO number"
                    />
                  </FormRow>
                  <FormRow>
                    <PaymentMethodTypeSelect
                      width={4}
                      name="paymentInfo.paymentMethodType"
                      label="Payment Method"
                    />

                    <CurrencySelect
                      required
                      width={2}
                      name="paymentInfo.currency"
                      label="Currency"
                      isClearable={false}
                    />
                    <Field
                      width={3}
                      name="paymentInfo.validFor"
                      component={InputField}
                      label="Valid for"
                      type="number"
                    />

                    <BankAccountSelect
                      width={5}
                      name="paymentInfo.bankAccount"
                      label="Bank account"
                    />
                  </FormRow>
                  <FormRow alignCenter>
                    <PaymentReceivedSelect
                      width={4}
                      name="paymentInfo.paymentReceived"
                      label="Payment received"
                      required
                    />

                    <Field
                      width={2}
                      name="paymentInfo.creditNote"
                      component={CheckboxField}
                      label="Credit note"
                    />
                    <Field
                      width={3}
                      name="paymentInfo.syndication"
                      component={CheckboxField}
                      label="Syndication"
                    />
                  </FormRow>
                  {/* TODO: disable for prod deploy */}
                  <FormRow>
                    <PipelineEngagementSelect
                      width={4}
                      label="Pipeline Engagement"
                      name="pipelineEngagement"
                      required
                    />
                    <Field
                      component={DateField}
                      label="Pipeline Forecasted Date"
                      name="pipelineForecastedDate"
                      width={4}
                      required
                    />
                  </FormRow>
                  <ErrorsAndUpdateButtonInRow
                    errors={errors}
                    touched={touched}
                    subject="Invoice"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  />
                </Segment>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}

export default InvoiceUpdateForm;
