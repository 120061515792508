import React from 'react';

import { Button as SemanticButton } from 'semantic-ui-react';
import { FormRow, ErrorsAndButtonsInRow } from 'components';

const UpdateButton = ({ subject, capital, color, style, label, ...props }) => {
  return (
    <SemanticButton
      style={{
        textTransform: capital && 'uppercase',
        ...style
      }}
      type="submit"
      size="large"
      color="blue"
      {...props}>
      {label || 'Update'} {subject}
    </SemanticButton>
  );
};
const UpdateAndExitButton = ({
  subject,
  capital,
  color,
  style,
  label,
  onClick,
  onUpdateAndExitClick,
  ...props
}) => {
  return (
    <SemanticButton
      style={{
        textTransform: capital && 'uppercase',
        ...style
      }}
      type="submit"
      size="large"
      color="blue"
      basic
      onClick={() => onUpdateAndExitClick(onClick)}
      {...props}>
      Update {subject} and Exit
    </SemanticButton>
  );
};

const UpdateButtonInRow = ({ showWithExitBtn = false, ...props }) => (
  <FormRow flexEnd>
    <UpdateButton {...props} />
    {showWithExitBtn && <UpdateAndExitButton {...props} />}
  </FormRow>
);

const ErrorsAndUpdateButtonInRow = ({
  errors,
  touched,
  showWithExitBtn = false,
  ...props
}) => (
  <ErrorsAndButtonsInRow errors={errors} touched={touched}>
    <UpdateButton {...props} />
    {showWithExitBtn && <UpdateAndExitButton {...props} />}
  </ErrorsAndButtonsInRow>
);

export { UpdateButton, UpdateButtonInRow, ErrorsAndUpdateButtonInRow };
