import { requestSdk, post, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const courseCreate = async data => {
  const url = `${BASE_URL}/courses/create/`;

  return await requestSdk(() => post(url, data));
};

export const currencyList = async () => {
  const url = `${BASE_URL}/common/currencies/`;

  return await requestSdk(() => get(url));
};
