import _ from 'lodash';

export const buildCurrencyKey = currency => `currency_${currency.id}`;

export const buildDefaultCurrencyKey = currency =>
  `default_currency_${currency.id}`;

const isCurrencyKey = value => value.startsWith('currency_');

const extractCurrencyId = value => value.split('currency_')[1];

const isDefaultCurrencyKey = value => value.startsWith('default_currency_');

const extractDefaultCurrencyId = value => value.split('default_currency_')[1];

export const preparePrices = values => {
  const result = [];

  const currencies = _.mapKeys(
    _.pickBy(values, (value, key) => isCurrencyKey(key)),
    (value, key) => extractCurrencyId(key)
  );

  const defaultCurrencies = _.mapKeys(
    _.pickBy(values, (value, key) => isDefaultCurrencyKey(key)),
    (value, key) => extractDefaultCurrencyId(key)
  );

  _.forEach(currencies, (value, key) => {
    const currency = {
      currency: key,
      value: value,
      default: defaultCurrencies[key]
    };

    result.push(currency);
  });

  return result;
};
