export const TRENDING_NEWS = { label: 'Trending now', value: 'Trending Now' };

export const BREAKING_NEWS = { label: 'Breaking news', value: 'Breaking News' };

export const getArticleData = (
  article,
  energyArticle,
  powerMaterialArticle,
  webArticle
) => {
  if (article) {
    return {
      articleData: { steel_article: article.id },
      choosenArticle: article
    };
  }
  if (energyArticle) {
    return {
      articleData: { energy_article: energyArticle.id },
      choosenArticle: energyArticle
    };
  }

  if (powerMaterialArticle) {
    return {
      articleData: { power_material_article: powerMaterialArticle.id },
      choosenArticle: powerMaterialArticle
    };
  }
  if (webArticle) {
    return {
      articleData: { web_article: webArticle.id },
      choosenArticle: webArticle
    };
  }
};
