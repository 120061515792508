import React from 'react';
import { Formik } from 'formik';
import { Form } from 'semantic-ui-react';

import { handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import {
  Field,
  TransferListField,
  FormRow,
  Button,
  Flex,
  ExternalLinkButton,
  Tooltip
} from 'components';
import { VisibleIf, PERMISSIONS } from 'permissions';

import { clientItemSetAvailability, getDownloadChildrenEmailsUrl } from './sdk';

class ManageSubscriptionChildrenModal extends React.Component {
  close = () => {
    this.props.onClose();
  };

  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const { client, clientItem, fetchClient } = this.props;

    setSubmitting(true);

    const { errors, success } = await clientItemSetAvailability(client.id, {
      client_item: clientItem.id,
      ...values
    });

    setSubmitting(false);

    if (success) {
      notifySuccess('Availability updated.');
      fetchClient();

      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const { user, client, clientItem } = this.props;

    const children = client.corporate_profiles || [];

    const childrenOptions = children.map(childProfile => {
      return {
        key: childProfile.user.id,
        value: childProfile.user.id,
        label: childProfile.user.email
      };
    });

    const initialValues = {
      children: clientItem.availableToCorporateChildren.map(child => {
        return child.id;
      })
    };

    return (
      <div>
        <Formik
          enableReinitialize={true}
          onSubmit={this.handleSubmit}
          initialValues={initialValues}>
          {({ handleSubmit, dirty }) => (
            <Form>
              <FormRow>
                <Field
                  name="children"
                  component={TransferListField}
                  options={childrenOptions}
                  enableSelectedSearch={true}
                  showCount={true}
                />
              </FormRow>
              <FormRow>
                <Flex spaceBetween style={{ width: '100%' }}>
                  <Button type="submit" color="green" onClick={handleSubmit}>
                    Submit
                  </Button>

                  <VisibleIf
                    user={user}
                    permissions={[PERMISSIONS.DOWNLOAD_CM_CHILDREN_EXPORT]}>
                    <Tooltip
                      disabled={!dirty}
                      on="hover"
                      content="Submit your changes to export the latest data."
                      trigger={
                        <div>
                          <ExternalLinkButton
                            disabled={dirty}
                            color="blue"
                            url={getDownloadChildrenEmailsUrl(
                              client.id,
                              clientItem.id
                            )}>
                            Export Chosen Children
                          </ExternalLinkButton>
                        </div>
                      }
                    />
                  </VisibleIf>
                </Flex>
              </FormRow>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default ManageSubscriptionChildrenModal;
