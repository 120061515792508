import React from 'react';

import { Field, SelectField } from 'components';

import { businessActivityList } from './sdk';

class BusinessActivitySelect extends React.Component {
  state = {
    businessActivities: []
  };
  async componentDidMount() {
    const { data, success } = await businessActivityList();

    if (success) {
      this.setState({ businessActivities: data });
    }
  }

  render() {
    const { name, ...rest } = this.props;
    const { businessActivities } = this.state;

    const options = businessActivities.map(businessActivity => {
      return {
        key: businessActivity.id,
        value: businessActivity.id,
        label: businessActivity.title
      };
    });

    return (
      <Field
        name={name || 'businessActivity'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default BusinessActivitySelect;
