import React from 'react';

import {
  Page,
  Flex,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Filters as SharedFilters
} from 'components';

import { registrationAttemptList } from './sdk';

import { RegistrationAttemptTable } from './components';

import { FILTERS as LAYOUT_FILTERS } from './utils';

class RegistrationAttemptList extends React.Component {
  render() {
    const {
      user,
      data,
      count,
      page,
      limit,
      filters,
      sort,
      filterBy,
      orderBy,
      clear,
      changePage,
      changeRowsPerPage,
      isFetching
    } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content maxWidth>
            <Flex flexStart>
              <Header>Register Attempts</Header>
            </Flex>
            <SharedFilters
              filters={filters}
              filterBy={filterBy}
              clearFilters={clear}
              count={count}
              layout={LAYOUT_FILTERS}
            />
            <RegistrationAttemptTable
              isFetching={isFetching}
              registerAttempts={data}
              sort={sort}
              orderBy={orderBy}
              pagination={
                <Pagination
                  limit={limit}
                  count={count}
                  page={page}
                  changePage={changePage}
                  changeRowsPerPage={changeRowsPerPage}
                />
              }
            />
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: registrationAttemptList
})(LoginRequired(RegistrationAttemptList));
