import React from 'react';

import { Segment, Header, Table, Button } from 'semantic-ui-react';

import { notifyErrors } from 'utils/notifications';

import { PresaleForm, PresalesUsageModal } from './components';

import { Flex, Tooltip } from 'components';

import { presalesUsage } from './sdk';

class Presales extends React.Component {
  state = {
    isOpenPresalesUsageModal: false,
    presalesUsage: null
  };

  fetchPresalesUsage = async () => {
    const { data, errors, success } = await presalesUsage(this.props.course.id);
    if (success) {
      this.setState({ presalesUsage: data });
    } else {
      notifyErrors(errors);
    }
  };

  toggleIsOpenPresalesUsageModal = () => {
    // Fetch promo code usage data when the modal is opened
    if (!this.state.isOpenPresalesUsageModal) {
      this.fetchPresalesUsage();
    }

    this.setState(prevState => ({
      isOpenPresalesUsageModal: !prevState.isOpenPresalesUsageModal
    }));
  };

  async componentDidMount() {
    this.fetchPresalesUsage();
  }

  render() {
    const { currencies, course, fetchCourse } = this.props;

    const { presalesUsage } = this.state;

    const { presales } = course;

    return (
      <Segment>
        <Flex spaceBetween>
          <Header as="h2">Presales</Header>
          <Tooltip
            on="hover"
            disabled={presalesUsage && presalesUsage.length > 0}
            content="This event has no used presales yet."
            trigger={
              <div>
                <Button
                  basic
                  color="blue"
                  size="small"
                  disabled={presalesUsage && presalesUsage.length === 0}
                  onClick={this.toggleIsOpenPresalesUsageModal}>
                  View Usage
                </Button>
              </div>
            }
          />
        </Flex>
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Start date</Table.HeaderCell>
              <Table.HeaderCell>End date</Table.HeaderCell>
              <Table.HeaderCell>Title</Table.HeaderCell>
              {currencies.map(currency => (
                <Table.HeaderCell key={currency.id}>
                  Price in {currency.code}
                </Table.HeaderCell>
              ))}
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {presales.map(presale => (
              <PresaleForm
                key={presale.id}
                course={course}
                presale={presale}
                allPresales={presales}
                currencies={currencies}
                fetchCourse={fetchCourse}
              />
            ))}
            <PresaleForm
              presale={null}
              allPresales={presales}
              course={course}
              currencies={currencies}
              fetchCourse={fetchCourse}
            />
          </Table.Body>
        </Table>
        {this.state.isOpenPresalesUsageModal && (
          <PresalesUsageModal
            onClose={this.toggleIsOpenPresalesUsageModal}
            course={course}
            presalesUsage={presalesUsage}
          />
        )}
      </Segment>
    );
  }
}

export default Presales;
