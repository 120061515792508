import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const clientList = async (mailshotId, params) => {
  const url = `${BASE_URL}/emails/mailshots/${mailshotId}/clients/`;

  return await requestSdk(() => get(url, params));
};

export const mailshotDetail = async id => {
  const url = `${BASE_URL}/emails/mailshots/${id}/`;

  return await requestSdk(() => get(url));
};

export const clientIdList = async (mailshotId, params) => {
  const url = `${BASE_URL}/emails/mailshots/${mailshotId}/client-id-list/`;

  return await requestSdk(() => get(url, params));
};
