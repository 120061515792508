import React from 'react';

import { Field, SelectField } from 'components';
const ENERGY_ARTICLE_SECTIONS = [
  'Africa',
  'Asia',
  'Central America',
  'Europe',
  'Middle East',
  'North America',
  'South America',
  'World',
  'Steel in Energy',
  'Unknown'
];

class EnergyArticleSectionSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = ENERGY_ARTICLE_SECTIONS.map(section => {
      return {
        key: section,
        value: section,
        label: section
      };
    });

    return (
      <Field
        name={name || 'section'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default EnergyArticleSectionSelect;
