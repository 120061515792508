import React from 'react';

import cx from 'classnames';
import { Form } from 'semantic-ui-react';
import { Field as FormikField, FastField as FormikFastField } from 'formik';

import styles from './styles.module.css';

const Field = ({ width, containerClassName, className, testId, ...props }) => (
  <Form.Field
    data-testid={testId}
    width={width}
    className={cx(
      containerClassName,
      { [styles.required]: props.required },
      styles.container
    )}>
    <FormikField {...props} className={className} />
  </Form.Field>
);

const FastField = ({
  width,
  containerClassName,
  className,
  testId,
  ...props
}) => (
  <Form.Field
    data-testid={testId}
    width={width}
    className={cx(
      containerClassName,
      { [styles.required]: props.required },
      styles.container
    )}>
    <FormikFastField {...props} className={className} />
  </Form.Field>
);

export { Field, FastField };
