import React from 'react';

import { Field, SelectField } from 'components';

import { clientReminderCreatorList } from './sdk';

class ClientReminderCreatorSelect extends React.Component {
  state = {
    users: []
  };

  async componentDidMount() {
    const { data, success } = await clientReminderCreatorList();
    if (success) {
      this.setState({ users: data });
    }
  }

  render() {
    const { name, ...rest } = this.props;
    const { users } = this.state;

    const options = users.map(user => {
      return {
        key: user.id,
        value: user.id,
        label: user.email
      };
    });

    return (
      <Field
        name={name || 'user'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default ClientReminderCreatorSelect;
