export const ALL_EMAILS = 'all-emails';
export const EVENT_EMAILS = 'event-emails';
export const SUBSCRIPTION_EMAILS = 'subscription-emails';
export const OTHER_EMAILS = 'other-emails';
export const FAVOURITE_EMAILS = 'favourite-emails';
export const EMAIL_TEMPLATE_TYPES = [
  ALL_EMAILS,
  EVENT_EMAILS,
  SUBSCRIPTION_EMAILS,
  OTHER_EMAILS,
  FAVOURITE_EMAILS
];
