import React from 'react';

import { withRouter } from 'react-router-dom';

import { Formik } from 'formik';

import { Table, Button } from 'semantic-ui-react';

import {
  Field,
  DateField,
  InputField,
  PipelineEngagementSelect
} from 'components';
import { handleFormErrors, prepareFormData } from 'utils/forms';

import { notifySuccess } from 'utils/notifications';

import { getStatusColor } from '../utils';

import { pipelineItemUpdate } from './sdk';

class PipelineItemForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    var data = prepareFormData(values);

    setSubmitting(true);

    const pipelineItemId = this.props.item.id;

    const { errors, success } = await pipelineItemUpdate(pipelineItemId, data);

    if (success) {
      notifySuccess('Successfully updated');

      this.props.fetchPipelineItems();
      return;
    } else {
      handleFormErrors(errors, setFieldError);
    }

    setSubmitting(false);
  };

  render() {
    const { item } = this.props;

    const initialValues = {
      pipelineEngagement: item.pipeline_engagement,
      forecastedDate: item.forecasted_date,
      forecastedValueGbp: item.forecasted_value_gbp,
      certainty: item.certainty
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={this.handleSubmit}>
        {({ handleSubmit, resetForm, setFieldValue, values }) => (
          <>
            <Table.Cell>
              <Field component={InputField} name="forecastedValueGbp" />
            </Table.Cell>
            <Table.Cell
              width={3}
              style={{
                backgroundColor: getStatusColor(item.pipeline_engagement)
              }}>
              <PipelineEngagementSelect name="pipelineEngagement" />
            </Table.Cell>
            <Table.Cell>
              <Field component={DateField} name="forecastedDate" />
            </Table.Cell>
            <Table.Cell>
              <Field component={InputField} name="certainty" />
            </Table.Cell>
            <Table.Cell>
              <Button type="submit" onClick={handleSubmit} color="blue">
                Update
              </Button>
            </Table.Cell>
          </>
        )}
      </Formik>
    );
  }
}

export default withRouter(PipelineItemForm);
