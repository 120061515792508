import _ from 'lodash';

import { parseQueryString } from 'utils';

import {
  INITIAL_CURSOR_PAGINATION_STATE,
  INITIAL_PAGINATION_STATE
} from 'utils/pagination';

import { SORT_URL_KEY } from 'utils/sort';

const isEmptyFilterValue = value => {
  if (_.isArray(value)) {
    return _.isEmpty(value);
  }

  return _.isNil(value) || value === '';
};

const getFilterValue = value => {
  if (_.isArray(value)) {
    return value.join(',');
  }

  return value;
};

export const FILTERS_KEY = 'filters';

export const getInitialFilters = () => {
  return { [FILTERS_KEY]: {} };
};

export const getFilters = state => {
  return state[FILTERS_KEY];
};

export const getFiltersFromUrl = url => {
  const urlParams = parseQueryString(url);

  const omitKeys = [
    SORT_URL_KEY,
    ...Object.keys(INITIAL_PAGINATION_STATE),
    ..._.map(Object.keys(INITIAL_CURSOR_PAGINATION_STATE), _.snakeCase)
  ];

  const result = {};

  Object.keys(urlParams)
    .filter(key => omitKeys.indexOf(key) === -1)
    .forEach(key => {
      result[key] = urlParams[key];
    });

  return result;
};

export const buildFilters = filters => {
  const params = {};

  if (_.isEmpty(filters)) {
    return params;
  }

  Object.keys(filters).forEach(key => {
    const value = filters[key];
    if (!isEmptyFilterValue(value)) {
      params[key] = getFilterValue(value);
    }
  });

  return params;
};

export const buildInitialValuesFromFilters = (filters, definitions) => {
  const initialValues = {
    ...filters
  };

  definitions.forEach(filter => {
    const { name, emptyValue, transform } = filter;

    let f = x => x;

    if (transform) {
      f = transform;
    }

    let value = filters[name];

    if (_.isNil(value)) {
      value = emptyValue;
    } else {
      value = f(value);
    }

    initialValues[name] = value;
  });

  return initialValues;
};

export const transformValuesForMultiselect = value => {
  if (_.isArray(value)) {
    return value.map(el => parseInt(el, 10));
  }
  return [parseInt(value, 10)];
};
