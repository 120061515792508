import React from 'react';
import _ from 'lodash';
import { Header } from 'semantic-ui-react';

import { Button, DeleteModal } from 'components';

import { clientAdminCommentsArchiveClear } from './sdk';
import styles from './styles.module.css';

const AdminCommentsArchive = ({ archive, clientId, clearArchive }) => {
  if (_.isEmpty(archive)) {
    return <Header as="h3">No admin comments archive yet.</Header>;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.buttonContainer}>
        <h4>Admin Comments Archive</h4>
        <div className={styles.buttons}>
          <Button
            color="green"
            onClick={() => {
              navigator.clipboard.writeText(archive);
            }}>
            Copy to Clipboard
          </Button>
          <DeleteModal
            itemId={clientId}
            objName="Admin Comments Archive"
            deleteButtonText="Clear"
            sdk={clientAdminCommentsArchiveClear}
            onSuccess={clearArchive}
          />
        </div>
      </div>
      <div className={styles.content}>{archive}</div>
    </div>
  );
};

export default AdminCommentsArchive;
