import React from 'react';
import _ from 'lodash';

import { withRouter } from 'react-router-dom';

import { Table, Message } from 'semantic-ui-react';

function TrackingEventDetailsModal({ extraData }) {
  if (extraData === null) {
    return <Message negative content="No data available." />;
  }

  return (
    <>
      <Table definition collapsing>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Email</Table.Cell>
            <Table.Cell>{extraData.email} </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Phone</Table.Cell>
            <Table.Cell>{extraData.phone} </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Names</Table.Cell>
            <Table.Cell>
              {extraData.title} {extraData.first_name} {extraData.last_name}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Job title, Company, Bussiness activity</Table.Cell>
            <Table.Cell>
              {extraData.job_title}, {extraData.company_name},{' '}
              {extraData.business_activity}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              Country, {extraData?.state !== 'None' && 'State,'} city
            </Table.Cell>
            <Table.Cell>
              {extraData.country}
              {extraData?.state !== 'None' ? `, ${extraData.state}` : ''},{' '}
              {extraData.city}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Address, postcode</Table.Cell>
            <Table.Cell>
              {extraData.address_1}, {extraData.postcode}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Event Price, Currency, Payment type</Table.Cell>
            <Table.Cell>
              {extraData?.price !== 'None' ? ` ${extraData.price}` : 'N/A'},{' '}
              {extraData.currency}, {extraData.payment_type}
            </Table.Cell>
          </Table.Row>
          {_.get(extraData, 'client.promo_code') && (
            <Table.Row>
              <Table.Cell>Promotion Code</Table.Cell>
              <Table.Cell>{_.get(extraData, 'client.promo_code')} </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>

      {!_.isEmpty(_.get(extraData, 'delegates')) && (
        <Table definition collapsing>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Delegates</Table.Cell>
              <Table.Cell>{extraData['delegates'].length}</Table.Cell>
            </Table.Row>
            {extraData['delegates'].map((delegate, index) => (
              <Table.Row>
                <Table.Cell>
                  Delegate {index + 1}: {delegate.email}
                </Table.Cell>
                <Table.Cell>
                  <>
                    <p>
                      {delegate.title} {delegate.first_name}{' '}
                      {delegate.last_name}, {delegate.phone}
                    </p>
                    <p>
                      {delegate.job_title}, {delegate.company_name},{' '}
                      {delegate.business_activity}
                    </p>
                    <p>
                      {delegate.country}, {delegate.city}, {delegate.address_1},{' '}
                      {delegate.postcode}
                    </p>
                  </>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </>
  );
}

export default withRouter(TrackingEventDetailsModal);
