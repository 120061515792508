import React from 'react';

import { Button, Header, Modal } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { pageUrl } from 'config/routes';
import { notifySuccess, notifyError } from 'utils/notifications';

class ArticleDeleteModal extends React.Component {
  state = { modalOpen: false };

  handleOpen = () => {
    this.setState({ modalOpen: true });
    this.props.onOpen();
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
    this.props.onClose();
  };

  handleSubmit = async () => {
    // delete article
    const { article, articleDelete, successPage } = this.props;
    const articleId = article.id;
    const { errors, success } = await articleDelete(articleId);

    if (success) {
      notifySuccess('Article deleted.');

      this.props.history.push(pageUrl(successPage));
    } else {
      this.handleClose();
      notifyError(errors);
    }
  };

  render() {
    return (
      <Modal
        trigger={
          <Button color="red" onClick={this.handleOpen}>
            Delete Article
          </Button>
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        basic
        size="small">
        <Header icon="archive" content="Delete article" />
        <Modal.Content>
          <p>
            Are you sure you want to delete article "
            {this.props.article.full_headline}"
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="red" inverted onClick={this.handleClose}>
            No
          </Button>
          <Button color="green" inverted onClick={this.handleSubmit}>
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withRouter(ArticleDeleteModal);
