import _ from 'lodash';
import { SUBSCRIPTIONS } from 'pages/FormsDashboard/constants';

export const hasSubscription = (data, subscription) => {
  const subscriptionIds = SUBSCRIPTIONS[subscription];

  const clientSubscriptionIds = _.map(_.get(data, 'subscriptions'), 'id');

  if (_.isArray(subscriptionIds)) {
    return !_.isEmpty(_.intersection(clientSubscriptionIds, subscriptionIds));
  }

  return _.includes(clientSubscriptionIds, subscriptionIds);
};
