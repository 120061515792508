import { transformValuesForMultiselect } from 'utils/filters';
import {
  InputField,
  DateField,
  InvoiceStatusSelect,
  CurrencySelect,
  UpcomingEventSelect,
  SubscriptionSelect,
  SubscriptionStatusSelect,
  YesNoSelect,
  CountrySelect,
  ClientEventStatus,
  BankAccountSelect,
  PaymentMethodTypeSelect,
  AdminSelect
} from 'components';

export const FILTERS = [
  [
    {
      name: 'search',
      emptyValue: '',
      component: InputField,
      label: 'Search',
      placeholder: 'Search...',
      width: 9
    }
  ],
  [
    {
      name: 'invoice_id',
      emptyValue: '',
      component: InputField,
      label: 'Invoice ID',
      placeholder: 'Search by invoice ID ...',
      width: 4
    },
    {
      name: 'proform_id',
      emptyValue: '',
      component: InputField,
      label: 'Pro-form ID',
      placeholder: 'Search by Pro-form ID ...',
      width: 4
    },
    {
      name: 'client_id',
      emptyValue: '',
      component: InputField,
      label: 'Client ID',
      placeholder: 'Search by Client ID ...',
      width: 4
    }
  ],
  [
    {
      name: 'invoice_date_from',
      emptyValue: null,
      component: DateField,
      label: 'Invoice date from'
    },
    {
      name: 'invoice_date_to',
      emptyValue: null,
      component: DateField,
      label: 'Invoice date to'
    },
    {
      name: 'invoice_paid_date_from',
      emptyValue: null,
      component: DateField,
      label: 'Invoice paid from'
    },
    {
      name: 'invoice_paid_date_to',
      emptyValue: null,
      component: DateField,
      label: 'Invoice paid to'
    }
  ],
  [
    {
      name: 'total_from',
      emptyValue: '',
      component: InputField,
      label: 'Total from',
      type: 'number',
      width: 4
    },
    {
      name: 'total_to',
      emptyValue: '',
      component: InputField,
      label: 'Total to',
      type: 'number',
      width: 4
    },
    {
      name: 'syndication',
      emptyValue: null,
      fieldComponent: YesNoSelect,
      label: 'Syndication',
      width: 4
    }
  ],
  [
    {
      name: 'client_name',
      emptyValue: '',
      component: InputField,
      label: 'Client name',
      placeholder: 'Search by client name...',
      width: 6
    },
    {
      name: 'company_name',
      emptyValue: '',
      component: InputField,
      label: 'Company name',
      placeholder: 'Search by company name...',
      width: 6
    },
    {
      name: 'country',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: CountrySelect,
      label: 'Country',
      width: 4
    },
    {
      name: 'currency',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: CurrencySelect,
      label: 'Currency',
      width: 4
    }
  ],
  [
    {
      name: 'invoice_status',
      emptyValue: [],
      fieldComponent: InvoiceStatusSelect,
      label: 'Invoice status',
      props: () => ({
        isMulti: true
      })
    },
    {
      name: 'upcoming_event',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: UpcomingEventSelect,
      label: 'Upcoming event',
      props: () => ({
        isMulti: true
      })
    },
    {
      name: 'subscription',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: SubscriptionSelect,
      label: 'Subscription'
    },
    {
      name: 'subscription_status',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: SubscriptionStatusSelect,
      label: 'Subscription status'
    }
  ],
  [
    {
      name: 'client_event_status',
      emptyValue: [],
      fieldComponent: ClientEventStatus,
      label: 'Client event status',
      width: 4,
      props: () => ({
        isMulti: true
      })
    },
    {
      name: 'bank_account',
      emptyValue: null,
      fieldComponent: BankAccountSelect,
      label: 'Bank account',
      width: 4
    },
    {
      name: 'payment_method_type',
      emptyValue: null,
      fieldComponent: PaymentMethodTypeSelect,
      label: 'Payment Method',
      width: 4
    }
  ],
  [
    {
      name: 'contact',
      emptyValue: null,
      fieldComponent: AdminSelect,
      transform: value => parseInt(value, 10),
      label: 'Contact name',
      width: 4
    },
    {
      name: 'commission_1',
      emptyValue: null,
      fieldComponent: AdminSelect,
      transform: value => parseInt(value, 10),
      label: 'Commission 1',
      width: 4
    },
    {
      name: 'commission_2',
      emptyValue: null,
      fieldComponent: AdminSelect,
      transform: value => parseInt(value, 10),
      label: 'Commission 2',
      width: 4
    }
  ]
];
