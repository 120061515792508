import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const courseAddAgenda = async (courseId, data) => {
  const url = `${BASE_URL}/courses/${courseId}/add-agenda/`;

  return await requestSdk(() => post(url, data));
};

export const courseUpdateAgenda = async (courseId, data) => {
  const url = `${BASE_URL}/courses/${courseId}/update-agenda/`;

  return await requestSdk(() => post(url, data));
};

export const courseRemoveAgenda = async (courseId, data) => {
  const url = `${BASE_URL}/courses/${courseId}/remove-agenda/`;

  return await requestSdk(() => post(url, data));
};
