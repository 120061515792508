import React from 'react';

import { Segment } from 'semantic-ui-react';

import { SortableList } from 'components';

import { dailyNewsletterOrderPrices } from './sdk';
import { notifySuccess, notifyErrors } from 'utils/notifications';

class PriceSectionContainer extends React.Component {
  onSortEnd = ({ oldIndex, newIndex, item, items }) => {
    const { orderPrices, priceFrequency } = this.props;

    orderPrices(priceFrequency.frequency, items);
  };

  render() {
    const { priceFrequency, dailyNewsletter } = this.props;

    const items = priceFrequency.prices;

    return (
      <Segment>
        <p>
          <strong>{priceFrequency.frequency}</strong>
        </p>
        <SortableList
          disabled={dailyNewsletter.live}
          items={items}
          onSortEnd={this.onSortEnd}
        />
      </Segment>
    );
  }
}

class Prices extends React.Component {
  orderPrices = async (frequency, prices) => {
    const allPrices = [];

    const {
      dailyNewsletter,
      dailyNewsletterPrices,
      fetchDailyNewsletterPrices
    } = this.props;

    dailyNewsletterPrices.forEach(priceFrequency => {
      if (priceFrequency.frequency === frequency) {
        prices.forEach(price => allPrices.push(price));
      } else {
        priceFrequency.prices.forEach(price => allPrices.push(price));
      }
    });

    const { success, errors } = await dailyNewsletterOrderPrices(
      dailyNewsletter.id,
      {
        prices: allPrices.map(price => price.slug)
      }
    );

    if (success) {
      notifySuccess(`Prices for ${frequency} reordered.`);
      fetchDailyNewsletterPrices();
    } else {
      notifyErrors(errors);
    }
  };

  render() {
    const { dailyNewsletter, dailyNewsletterPrices } = this.props;

    return (
      <Segment>
        <p>
          <strong>Prices by frequencies</strong>
        </p>
        {dailyNewsletterPrices &&
          dailyNewsletterPrices.map(priceFrequency => (
            <PriceSectionContainer
              dailyNewsletter={dailyNewsletter}
              key={priceFrequency.frequency}
              priceFrequency={priceFrequency}
              orderPrices={this.orderPrices}
            />
          ))}
      </Segment>
    );
  }
}

export default Prices;
