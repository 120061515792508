import _ from 'lodash';

export const orderArticles = articles =>
  _.orderBy(articles, ['order'], ['asc']);

export const getNonEmptySections = sections =>
  _.filter(sections, section => !_.isEmpty(section.articles));

export const areAllArticlesConnected = ({ articles, newsletter }) => {
  const newsletterGroupArticles = _.uniq(
    _.flatten(
      _.map(newsletter.group_sections, section => _.map(section.articles, 'id'))
    )
  );
  const newsletterRegionArticles = _.uniq(
    _.flatten(
      _.map(newsletter.sections, section => _.map(section.articles, 'id'))
    )
  );

  const topHeadlinedAndFeatured = _.map(
    _.concat(newsletter.top_headlines, newsletter.featured),
    'id'
  );

  const articlesIds = _.difference(
    _.map(articles, 'id'),
    topHeadlinedAndFeatured
  );

  return (
    _.isEmpty(_.difference(articlesIds, newsletterGroupArticles)) &&
    _.isEmpty(_.difference(articlesIds, newsletterRegionArticles))
  );
};

export const areAllPowerMaterialArticlesConnected = ({
  articles,
  newsletter
}) => {
  const newsletterGroupArticles = _.uniq(
    _.flatten(
      _.map(newsletter.group_sections, section => _.map(section.articles, 'id'))
    )
  );

  const topHeadlinedAndFeatured = _.map(
    _.concat(newsletter.top_headlines, newsletter.featured),
    'id'
  );

  const articlesIds = _.difference(
    _.map(articles, 'id'),
    topHeadlinedAndFeatured
  );

  return _.isEmpty(_.difference(articlesIds, newsletterGroupArticles));
};
