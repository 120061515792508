import React from 'react';

import { Field, SelectField } from 'components';

const CM_ACCOUNTS_ONLY = 0;
const C_ACCOUNTS_ONLY = 1;
const INDIVIDUAL_ACCOUNTS_ONLY = 2;

const TYPE_CHOICES = [
  {
    value: CM_ACCOUNTS_ONLY,
    label: 'CM Accounts Only'
  },
  {
    value: C_ACCOUNTS_ONLY,
    label: 'C Accounts Only'
  },
  {
    value: INDIVIDUAL_ACCOUNTS_ONLY,
    label: 'Individual Accounts Only'
  }
];

class ClientProfileTypeSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = TYPE_CHOICES.map(type => {
      return {
        key: type.value,
        value: type.value,
        label: type.label
      };
    });

    return (
      <Field
        name={name || 'cm'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default ClientProfileTypeSelect;
