import React from 'react';
import _ from 'lodash';

import { Segment } from 'semantic-ui-react';

import {
  Page,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Filters as SharedFilters
} from 'components';

import { PipelineItemsTable, Statistics } from './components';

import { FILTERS_LAYOUT, getPersonalStatistics } from './utils';

import { pipelinesDashboardApi } from './sdk';

class Dashboard extends React.Component {
  componentWillMount() {
    const { history } = this.props;
    if (_.isEmpty(history.location.search)) {
      const month = new Date().getMonth() + 1;
      const year = new Date().getFullYear();

      const queryParams = `?forecasted_month=${month}&forecasted_year=${year}`;

      history.replace({ search: queryParams });
      this.props.initialize();
    }
  }

  render() {
    const {
      user,
      crumbs,
      data,
      filters,
      count,
      filterBy,
      clear,
      limit,
      page,
      sort,
      orderBy,
      changePage,
      changeRowsPerPage,
      response,
      refetch
    } = this.props;

    const { managerId } = this.props.match.params;

    const salesMemberData = getPersonalStatistics(
      _.get(response, 'aggregations.per_sales_member', {}),
      user,
      managerId
    );
    const salesMember = _.get(salesMemberData, 'sales_member');

    const canUpdate = user.id === _.get(salesMember, 'id');

    const statistics = _.get(response, 'aggregations.full_aggregations', {});

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs} maxWidth>
            <Header>Personal Pipeline Dashboard</Header>
            <SharedFilters
              filters={filters}
              count={count}
              filterBy={filterBy}
              clearFilters={clear}
              layout={FILTERS_LAYOUT}
            />
            {data && (
              <Segment>
                <Header as="h3">
                  {_.get(salesMember, 'first_name')}{' '}
                  {_.get(salesMember, 'last_name')}
                </Header>
                <Statistics
                  statistics={statistics}
                  salesMemberStatistics={salesMemberData}
                />
              </Segment>
            )}

            <PipelineItemsTable
              data={data}
              orderBy={orderBy}
              sort={sort}
              canUpdate={canUpdate}
              fetchPipelineItems={refetch}
              pagination={
                <Pagination
                  limit={limit}
                  count={count}
                  page={page}
                  changePage={changePage}
                  changeRowsPerPage={changeRowsPerPage}
                />
              }
            />
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

const PersonalPipelineDashboard = LoginRequired(
  ListViewEssentials({
    sdk: props => {
      const userId = props.user.id;

      return _.partial(pipelinesDashboardApi, userId);
    },
    passPropsToSdk: true
  })(Dashboard)
);

const SalesManagerPipelineDashboard = LoginRequired(
  ListViewEssentials({
    sdk: props => {
      const { managerId } = props.match.params;

      return _.partial(pipelinesDashboardApi, managerId);
    },
    passPropsToSdk: true
  })(Dashboard)
);

export { PersonalPipelineDashboard, SalesManagerPipelineDashboard };
