import { YearSelect } from 'components';

export const FILTERS = [
  [
    {
      name: 'year',
      emptyValue: null,
      fieldComponent: YearSelect,
      transform: value => parseInt(value, 10),
      label: 'Year',
      width: 3
    }
  ]
];
