import Flex from 'components/Flex';

import { Select as SemanticSelect } from 'semantic-ui-react';

export const RowsPerPageSelect = ({ value, options, onChange }) => {
  return (
    <Flex style={{ gap: '0.5rem' }}>
      Rows Per Page:
      <SemanticSelect
        value={value}
        options={options}
        onChange={onChange}
        style={{ minWidth: 'fit-content' }}
      />
    </Flex>
  );
};

export default RowsPerPageSelect;
