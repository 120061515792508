import _ from 'lodash';

export const prepareInitialValues = chinaSteelReport => ({
  issueDate: chinaSteelReport.issue_date,
  issueNumber: chinaSteelReport.issue_number,
  title: chinaSteelReport.title,

  cover: chinaSteelReport.cover,
  pdf: chinaSteelReport.pdf,
  samplePdf: chinaSteelReport.sample_pdf,
  xls: chinaSteelReport.xls,
  sampleXls: chinaSteelReport.sample_xls,

  lead: chinaSteelReport.lead,
  text: chinaSteelReport.text,

  createdBy: _.get(chinaSteelReport, 'created_by.username', ''),
  updatedBy: _.get(chinaSteelReport, 'updated_by.username', ''),
  createdAt: chinaSteelReport.created_at,
  updatedAt: chinaSteelReport.updated_at
});
