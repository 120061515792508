import _ from 'lodash';
import React from 'react';

import { Segment, Header } from 'semantic-ui-react';

import { SortableList } from 'components';

import { notifySuccess, notifyErrors } from 'utils/notifications';

function PriceSectionContainer({
  priceFrequency,
  prices,
  newsletter,
  orderPrices
}) {
  const onSortEnd = ({ items }) => {
    orderPrices(priceFrequency, items);
  };

  const items = prices.map(price => ({ ...price, title: price.display_as }));

  return (
    <Segment>
      <Header as="div" size="tiny">
        {priceFrequency}
      </Header>
      <SortableList
        disabled={newsletter.live}
        items={items}
        onSortEnd={onSortEnd}
      />
    </Segment>
  );
}

function Prices({
  newsletter,
  newsletterPrices,
  fetchNewsletterPrices,
  newsletterOrderSection
}) {
  const orderPrices = async (frequency, prices) => {
    const data = {
      prices: prices.map(price => price.id),
      section_slug: 'issue-prices'
    };

    const { success, errors } = await newsletterOrderSection(
      newsletter.id,
      data
    );

    if (success) {
      notifySuccess(`Prices for ${frequency} reordered.`);
      fetchNewsletterPrices();
    } else {
      notifyErrors(errors);
    }
  };

  return (
    <>
      <Header size="large" attached="top">
        Prices by frequencies
      </Header>
      <Segment attached>
        {newsletterPrices &&
          _.keys(newsletterPrices).map(priceFrequency => (
            <PriceSectionContainer
              newsletter={newsletter}
              key={priceFrequency.frequency}
              priceFrequency={priceFrequency}
              prices={newsletterPrices[priceFrequency]}
              orderPrices={orderPrices}
            />
          ))}
      </Segment>
    </>
  );
}

export default Prices;
