export const FROM_EMAIL_OPTIONS = [
  {
    key: 'news',
    value: 'news@kallanish.com',
    label: 'news@kallanish.com'
  },
  {
    key: 'marketing',
    value: 'marketing@kallanish.com',
    label: 'marketing@kallanish.com'
  }
];
