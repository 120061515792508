import * as yup from 'yup';
import 'utils/yup';

export const invoiceSendValidationSchema = yup.object().shape({
  subject: yup.string().required('Subject is required.'),
  testEmail: yup
    .string()
    .strongEmail()
    .when('isTest', {
      is: true,
      then: schema => schema.required('Test email is required.')
    })
});
