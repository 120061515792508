import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { Field, SelectField } from 'components';

import { promoCodeList } from 'pages/Course/Detail/sdk';
import { notifyErrors } from 'utils/notifications';

const EventPromoCodeSelect = ({
  name = 'promoCode',
  courseItemId,
  ...rest
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function fetchPromoCodes() {
      const { data, errors, success } = await promoCodeList(courseItemId);

      if (success) {
        setOptions(_.map(data, item => ({ label: item.name, value: item.id })));
      } else {
        notifyErrors(errors);
      }
    }

    fetchPromoCodes();
  }, [courseItemId]);

  return (
    <Field
      name={name}
      component={SelectField}
      options={options}
      isClearable
      {...rest}
    />
  );
};

export default EventPromoCodeSelect;
