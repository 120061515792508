import React from 'react';

import _ from 'lodash';

import { Formik } from 'formik';

import { Segment, Header, Table, Button } from 'semantic-ui-react';

import { Field, InputField } from 'components';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

class Meta extends React.Component {
  handleSubmit = async (values, actions) => {
    const { object, fetchObject, sdk } = this.props;

    const { setSubmitting, setFieldError } = actions;

    setSubmitting(true);

    const { success, errors } = await sdk(object.id, prepareFormData(values));

    setSubmitting(false);

    if (success) {
      notifySuccess(`Meta fields updated.`);
      fetchObject();
    }

    if (!success) {
      handleFormErrors(errors, setFieldError);
    }
  };

  getMetaFieldValue = (object, field) => {
    const metaField = _.find(object.meta, m => m.type === field);

    if (metaField) {
      return metaField.meta_data;
    }

    return '';
  };

  render() {
    const { object } = this.props;

    const initialValues = {
      title: this.getMetaFieldValue(object, 'title'),
      description: this.getMetaFieldValue(object, 'description'),
      standout: this.getMetaFieldValue(object, 'standout'),
      keywords: this.getMetaFieldValue(object, 'keywords')
    };

    return (
      <Segment>
        <Header as="h2">Meta fields</Header>
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Title</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Keywords</Table.HeaderCell>
              <Table.HeaderCell>Standout</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Formik
              onSubmit={this.handleSubmit}
              initialValues={initialValues}
              enableReinitialize={true}>
              {({ values, handleSubmit, isSubmitting }) => (
                <Table.Row>
                  <Table.Cell>
                    <Field name="title" component={InputField} />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name="description" component={InputField} />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name="keywords" component={InputField} />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name="standout" component={InputField} />
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      onClick={handleSubmit}
                      type="submit"
                      color="blue"
                      disabled={isSubmitting}>
                      Update
                    </Button>
                  </Table.Cell>
                </Table.Row>
              )}
            </Formik>
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

export default Meta;
