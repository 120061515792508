import React from 'react';

import { Header, Segment } from 'semantic-ui-react';

import { useParams } from 'react-router-dom';
import { Page, LoginRequired } from 'components';

import { PermissionsRequired, PERMISSIONS } from 'permissions';

import WebArticleCreateForm from './form';

import { getProductName } from 'utils';

function WebArticleCreate({ user, crumbs }) {
  const { productSlug } = useParams();

  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content crumbs={crumbs}>
          <Segment>
            <Header as="h1">
              Create {getProductName(productSlug)} Article
            </Header>
            <WebArticleCreateForm />
          </Segment>
        </Page.Content>
      </Page.Body>
    </Page>
  );
}

export default LoginRequired(
  PermissionsRequired([PERMISSIONS.CREATE_ARTICLES])(WebArticleCreate)
);
