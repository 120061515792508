import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const priceSeriesForPublishList = async (productSlug, params) => {
  params['product_type'] = productSlug;

  const url = `${BASE_URL}/prices/series/v2/data-points/by-dates/`;

  return await requestSdk(() => get(url, params));
};

export const priceSeriesApprove = async priceId => {
  const url = `${BASE_URL}/prices/series/v2/${priceId}/data-points/approve/`;

  return await requestSdk(() => post(url, {}));
};
