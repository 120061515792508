import React from 'react';

import { Field, SelectField } from 'components';
import { titleOptionList } from './sdk';

class TitleOptionSelect extends React.Component {
  state = {
    titleOptions: []
  };
  async componentDidMount() {
    const { data, success } = await titleOptionList();

    if (success) {
      this.setState({ titleOptions: data });
    }
  }

  render() {
    const { name, ...rest } = this.props;
    const { titleOptions } = this.state;

    const options = titleOptions.map(titleOption => {
      return {
        key: titleOption.id,
        value: titleOption.id,
        label: titleOption.title
      };
    });

    return (
      <Field
        name={name || 'titleOption'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default TitleOptionSelect;
