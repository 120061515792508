import React from 'react';
import _ from 'lodash';
import { Field, FastField, SelectField } from 'components';

const INDEX_TYPE = [
  {
    id: 'Average',
    title: 'Average Price'
  },
  {
    id: 'Percentage Index',
    title: '% Index of Prices'
  }
];

class IndexTypeSelect extends React.Component {
  state = {
    options: INDEX_TYPE.map(status => ({
      key: status.id,
      value: status.id,
      label: status.title
    }))
  };

  render() {
    const { name, fast = false, ...rest } = this.props;

    const { options } = this.state;

    let Component = Field;
    if (fast) {
      Component = FastField;
    }

    if (!_.isEmpty(options)) {
      rest['options'] = options;
    }

    return (
      <Component
        name={name || 'indexType'}
        component={SelectField}
        isClearable
        {...rest}
      />
    );
  }
}

export default IndexTypeSelect;
