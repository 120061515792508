import React from 'react';

import { Formik } from 'formik';

import { Segment, Form, Statistic } from 'semantic-ui-react';

import { buildInitialValuesFromFilters } from 'utils/filters';

import { Field, Flex, FormRow, FilterButtons, InputField } from 'components';

import { FILTERS } from './utils';

class Filters extends React.Component {
  submitSearch = values => {
    this.props.filterBy(values);
  };

  resetSearch = resetForm => {
    const { clearFilters } = this.props;

    resetForm();
    clearFilters();
  };

  render() {
    const { count, filters } = this.props;

    const initialValues = buildInitialValuesFromFilters(filters, FILTERS);

    return (
      <Segment>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={this.submitSearch}>
          {({ values, handleSubmit, resetForm }) => (
            <Form>
              <FormRow>
                <Field
                  width={8}
                  name="search"
                  placeholder="Search ..."
                  component={InputField}
                  label="Search email"
                />
              </FormRow>

              <Flex spaceBetween>
                <Statistic.Group size="tiny">
                  <Statistic color="green">
                    <Statistic.Value>{count}</Statistic.Value>
                    <Statistic.Label>Clients</Statistic.Label>
                  </Statistic>
                </Statistic.Group>
                <FilterButtons
                  searchAction={handleSubmit}
                  resetAction={() => this.resetSearch(resetForm)}
                />
              </Flex>
            </Form>
          )}
        </Formik>
      </Segment>
    );
  }
}

export default Filters;
