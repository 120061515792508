import React from 'react';

import _ from 'lodash';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import { Field, SteelGroupSelect } from 'components';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { dailyNewsletterAddProductTypeGroupArticle } from './sdk';

import styles from './styles.module.css';

class ProductTypeGroupForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { article, dailyNewsletter, fetchDailyNewsletter } = this.props;

    const { setSubmitting, setFieldError } = actions;

    setSubmitting(true);

    const data = prepareFormData(values);

    if (data.product_type_group === 'Energy For Manufacturing') {
      data.product_type_group = 'Energy for Manufacturing';
    }

    const { errors, success } = await dailyNewsletterAddProductTypeGroupArticle(
      dailyNewsletter.id,
      data
    );

    setSubmitting(false);

    if (success) {
      if (_.isNil(values.productTypeGroup)) {
        notifySuccess(
          `${article.full_headline} removed from product type groups.`
        );
      } else {
        notifySuccess(
          `${article.full_headline} added to ${values.productTypeGroup}.`
        );
      }
      fetchDailyNewsletter();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  getArticleProductTypeGroup = () => {
    const { dailyNewsletter, article } = this.props;

    return _.get(
      _.find(dailyNewsletter.group_sections, section => {
        return _.find(section.articles, { id: article.id });
      }),
      'group_section',
      null
    );
  };

  onChange = submitForm => {
    // https://github.com/jaredpalmer/formik/issues/1218
    setTimeout(submitForm);
  };

  render() {
    const articleProductTypeGroup = this.getArticleProductTypeGroup();

    const { article } = this.props;

    const initialValues = {
      article: article.id,
      productTypeGroup: articleProductTypeGroup
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={this.handleSubmit}>
        {({ values, submitForm, isSubmitting, setFieldValue }) => (
          <Form className={styles.Grid}>
            <input type="hidden" name="article" />
            <Field
              name="productTypeGroup"
              component={SteelGroupSelect}
              className={styles.SmallerSelect}
              onChange={() => this.onChange(submitForm)}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default ProductTypeGroupForm;
