import React from 'react';

import { Header, Segment } from 'semantic-ui-react';

import { Page, LoginRequired } from 'components';
import { PERMISSIONS, PermissionsRequired } from 'permissions';

import { WeeklyPowerMaterialsCreateForm } from './components';
import WeeklyPowerMaterialsList from '../List';

import {
  weeklyPowerMaterialsCreate,
  weeklyPowerMaterialsCreateTemplates
} from './sdk';

class WeeklyPowerMaterialsCreate extends React.Component {
  render() {
    const { user, crumbs } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Header as="h1">Create Weekly Power Materials</Header>
              <WeeklyPowerMaterialsCreateForm
                editionTemplatesSdk={weeklyPowerMaterialsCreateTemplates}
                editionCreateSdk={weeklyPowerMaterialsCreate}
                successPage={WeeklyPowerMaterialsList}
                title="Weekly Power Materials"
              />
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(
  PermissionsRequired([PERMISSIONS.CREATE_WEEKLY_POWER_MATERIALS])(
    WeeklyPowerMaterialsCreate
  )
);
