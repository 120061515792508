import React from 'react';
import _ from 'lodash';
import { Formik, FieldArray } from 'formik';
import { Form } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import {
  Field,
  UpdateButtonInRow,
  FormRow,
  FileField,
  InputField,
  RichTextAreaField,
  UnsavedFormPrompt,
  CreatedUpdatedFormValues
} from 'components';

import { subscriptionUpdate } from './sdk';
import { HYDROGEN } from 'domainConstants';

class SubscriptionUpdateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { subscription, fetchSubscription } = this.props;

    const { setSubmitting, setFieldError } = actions;

    setSubmitting(true);

    let omitValues = ['title'];
    if (subscription.title === HYDROGEN) {
      omitValues.push('sampleNewsletterUrl', 'fullAccessText', 'description');
    }

    const data = _.omit(values, omitValues);

    const { success, errors } = await subscriptionUpdate(
      subscription.id,
      prepareFormData(data)
    );

    setSubmitting(false);

    if (success) {
      notifySuccess(`Subscription is updated.`);
      fetchSubscription();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const {
      title,
      description,
      item: { prices },
      from_name: fromName,
      from_email: fromEmail,
      return_path: returnPath,
      sample_newsletter_url: sampleNewsletterUrl,
      sample_thumbnail: sampleThumbnail,
      full_access_text: fullAccessText,
      created_by: createdBy,
      created_at: createdAt,
      updated_by: updatedBy,
      updated_at: updatedAt
    } = this.props.data;

    const nonFormValues = {
      createdBy,
      createdAt,
      updatedBy,
      updatedAt
    };
    const initialValues = {
      title,
      description,
      fromName,
      fromEmail,
      returnPath,
      sampleNewsletterUrl,
      sampleThumbnail,
      fullAccessText,
      prices
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={this.handleSubmit}>
        {({ values, handleSubmit, isSubmitting, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty} />
            <FormRow>
              <Field
                required
                width={6}
                name="title"
                component={InputField}
                label="Title"
                disabled
              />
              <Form.Field width={10}>
                <UpdateButtonInRow
                  subject="subscription"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                />
              </Form.Field>
            </FormRow>
            {title !== HYDROGEN && (
              <FormRow>
                <Field
                  width={12}
                  name="sampleNewsletterUrl"
                  component={InputField}
                  label="Sample Newsletter URL:"
                />
              </FormRow>
            )}
            <FormRow>
              <Field
                width={5}
                name="sampleThumbnail"
                component={FileField}
                label="Sample thumbnail"
              />
              {title !== HYDROGEN && (
                <Field
                  width={7}
                  name="fullAccessText"
                  component={InputField}
                  label="Full Access Banner (website)"
                />
              )}
            </FormRow>
            <FormRow>
              <FieldArray
                name="prices"
                render={() =>
                  values.prices.map((price, index) => (
                    <Field
                      key={index}
                      name={`prices.${index}.amount`}
                      component={InputField}
                      label={`Price in ${price.code}`}
                    />
                  ))
                }
              />
            </FormRow>
            {title !== HYDROGEN && (
              <Field
                name="description"
                component={RichTextAreaField}
                label="Mobile description"
                config={{
                  height: '300px'
                }}
              />
            )}

            <CreatedUpdatedFormValues values={nonFormValues} />
            <UpdateButtonInRow
              subject="subscription"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(SubscriptionUpdateForm);
