import React from 'react';

import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { ClientDetail, OrderDetail } from 'pages';

import { formatDate } from 'utils';

import { TableFooter, SortableHeader } from 'components';

class OrderTable extends React.Component {
  getClientDetailUrl = clientId => {
    return pageUrl(ClientDetail, { clientId });
  };

  getOrderDetailUrl = orderId => {
    return pageUrl(OrderDetail, { orderId });
  };

  render() {
    const { orders, sort, orderBy, pagination } = this.props;
    return (
      <Table celled selectable compact={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              singleLine
              style={{ fontWeight: '400' }}
              colspan="7">
              {pagination}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <SortableHeader sort={sort} orderBy={orderBy} field="id">
              Id
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="client_id">
              Client Id
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="name">
              Client name
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="date">
              Date
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="total_price">
              Total price
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="currency">
              Currency
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="status">
              Status
            </SortableHeader>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {orders.map(order => (
            <Table.Row key={order.id}>
              <Table.Cell>
                <Link to={this.getOrderDetailUrl(order.id)}>{order.id}</Link>
              </Table.Cell>
              <Table.Cell>
                <Link to={this.getClientDetailUrl(order.client.id)}>
                  {order.client.id}
                </Link>
              </Table.Cell>
              <Table.Cell>
                {order.client.first_name} {order.client.last_name}
              </Table.Cell>
              <Table.Cell>{formatDate(order.date_ordered)}</Table.Cell>
              <Table.Cell>{order.total_price}</Table.Cell>
              <Table.Cell>{order.currency.code}</Table.Cell>
              <Table.Cell>{order.status}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <TableFooter>{pagination}</TableFooter>
      </Table>
    );
  }
}

export default OrderTable;
