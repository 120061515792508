import React from 'react';

import { withRouter } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  InputField,
  FormRow,
  FileField,
  CourseDateTypeSelect,
  DateField,
  CountrySelect,
  CourseTopicSelect,
  CourseSubjectSelect,
  CourseVideoSelect,
  CourseTrainerSelect,
  BannersSelect,
  CheckboxField,
  RichTextAreaField,
  PastEventSelect,
  UnsavedFormPrompt,
  EventTypeSelect,
  YesNoSelect,
  EventRegistrationTypeSelect,
  EventDurationTypeSelect,
  ErrorsAndCreateButtonInRow
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';
import { populateSlug } from 'utils';

import { pageUrl } from 'config/routes';
import { CourseList } from 'pages';

import {
  buildCurrencyKey,
  buildDefaultCurrencyKey,
  preparePrices
} from 'pages/Course/utils';

import { DEFAULT_INITIAL_VALUES, courseFormSchema } from './utils';
import { courseCreate, currencyList } from './sdk';

import styles from './styles.module.css';

class CourseCreateForm extends React.Component {
  state = {
    currencies: []
  };

  async componentDidMount() {
    const { success, data } = await currencyList();

    if (success) {
      this.setState({ currencies: data });
    }
  }

  onDefaultCurrencyChange = (selectedCurrency, setFieldValue) => {
    const { currencies } = this.state;
    setFieldValue(buildDefaultCurrencyKey(selectedCurrency), true);

    currencies.forEach(currency => {
      if (currency.id !== selectedCurrency.id) {
        setFieldValue(buildDefaultCurrencyKey(currency), false);
      }
    });
  };

  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const prices = preparePrices(values);

    if (!values.showPreviousAttendeeList) {
      values['previousEvents'] = [];
    }
    if (values.taxValue === '') {
      values.taxValue = null;
    }

    const data = prepareFormData(values);

    data.prices = prices;

    setSubmitting(true);

    const { errors, success } = await courseCreate(data);

    if (success) {
      this.props.history.push(pageUrl(CourseList));
      notifySuccess(`Course created.`);
    }
    setSubmitting(false);

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const { currencies } = this.state;
    let initialValues = DEFAULT_INITIAL_VALUES;

    currencies.forEach(currency => {
      initialValues[buildCurrencyKey(currency)] = 0;
      initialValues[buildDefaultCurrencyKey(currency)] = false;
    });

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={courseFormSchema}
        onSubmit={this.handleSubmit}>
        {({
          values,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          dirty,
          errors,
          touched
        }) => (
          <Form>
            <UnsavedFormPrompt when={dirty && !isSubmitting} />
            <ErrorsAndCreateButtonInRow
              touched={touched}
              errors={errors}
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
            <FormRow>
              <Field
                width={4}
                name="title"
                label="Title"
                required
                component={InputField}
                onChange={(e, data) => {
                  populateSlug(data, setFieldValue);
                }}
              />
              <Field
                width={4}
                name="slug"
                label="Slug"
                required
                component={InputField}
              />
              <EventTypeSelect
                width={4}
                name="eventType"
                label="Event Type"
                required
              />
              <YesNoSelect
                width={4}
                name="isRegistrationOpen"
                label="Registration Status"
                required
                yesLabel="Open"
                noLabel="Closed"
                isClearable={false}
              />
            </FormRow>
            <FormRow alignEnd>
              <Field
                width={4}
                name="titleWeb"
                label="Event Name"
                required
                component={InputField}
              />
              <Field
                width={3}
                name="duration"
                label="Duration"
                required
                component={InputField}
              />
              <EventDurationTypeSelect
                width={3}
                name="durationType"
                label="Duration Dimension"
                required
                isClearable={false}
              />
              <YesNoSelect
                width={2}
                name="isFree"
                label="Is Free"
                required
                isClearable={false}
              />

              <EventRegistrationTypeSelect
                width={3}
                name="registrationType"
                label="Registration Type"
                required
                isClearable={false}
              />
              {values['registrationType'] &&
                values['registrationType'] === 'Custom' && (
                  <Field
                    component={InputField}
                    name="customRegistrationUrl"
                    width={4}
                    label="Custom Registration Url"
                    required
                  />
                )}
            </FormRow>
            <FormRow widths="equal">
              <Field name="image" label="Image" component={FileField} />
              <Field name="file" label="Prospectus" component={FileField} />
              <Field
                name="applicationFile"
                label="Application File"
                component={FileField}
              />
            </FormRow>
            <FormRow widths="equal">
              <Field
                name="sponsorship"
                label="Sponsorship"
                component={FileField}
              />
              <Field
                name="hotelBookingForm"
                label="Hotel Booking Form"
                component={FileField}
              />
              <Field
                name="hotelBookingLink"
                label="Hotel Booking Link"
                component={InputField}
              />
            </FormRow>
            <FormRow>
              <Field
                width={4}
                name="dateType"
                label="Date Type"
                component={CourseDateTypeSelect}
                isClearable={false}
                onChange={this.onCourseDateTypeChange}
              />
              <Field
                width={4}
                name="dateCustom"
                label="Custom Date"
                component={InputField}
                disabled={values.dateType === 'Fixed Date'}
              />
            </FormRow>
            <FormRow>
              <Field
                required
                width={4}
                name="dateStart"
                label="Start date"
                component={DateField}
              />
              <Field
                required
                width={4}
                name="dateActivation"
                label="Date of Activation"
                component={DateField}
              />
            </FormRow>
            <FormRow>
              <Field
                width={4}
                name="country"
                label="Country"
                component={CountrySelect}
              />
              <Field
                width={4}
                name="city"
                label="City"
                component={InputField}
              />
            </FormRow>
            <FormRow>
              <Field
                width={4}
                name="topic"
                label="Topic"
                component={CourseTopicSelect}
              />
              <Field
                width={4}
                name="subject"
                label="Subject"
                component={CourseSubjectSelect}
              />
            </FormRow>
            <div className={styles.currencyRow}>
              {currencies.map(currency => (
                <div key={currency.id}>
                  <Field
                    name={buildCurrencyKey(currency)}
                    label={`Price in ${currency.code}`}
                    component={InputField}
                  />
                  <Field
                    name={buildDefaultCurrencyKey(currency)}
                    label="Default currency"
                    component={CheckboxField}
                    onChange={() =>
                      this.onDefaultCurrencyChange(currency, setFieldValue)
                    }
                  />
                </div>
              ))}
            </div>
            <FormRow>
              <Field
                width={4}
                name="taxName"
                label="Tax name"
                component={InputField}
              />
              <Field
                width={4}
                name="taxValue"
                label="Tax value(%)"
                component={InputField}
                type="number"
              />
            </FormRow>
            <FormRow>
              <Field
                width={4}
                name="videoTitle"
                label="Video Title"
                component={InputField}
              />
              <Field
                width={4}
                name="video"
                label="Video"
                component={CourseVideoSelect}
              />
            </FormRow>
            <Field
              width={8}
              name="trainersTitle"
              label="Speakers Title"
              component={InputField}
            />
            <Field
              name="trainers"
              label="Speakers"
              component={CourseTrainerSelect}
              isMulti
            />
            <FormRow widths="equal">
              <Field
                name="trainersTextOnTop"
                label="Speakers text on top"
                component={RichTextAreaField}
              />

              <Field
                name="trainersTextOnBottom"
                label="Speakers text on bottom"
                component={RichTextAreaField}
              />
            </FormRow>
            <FormRow widths="equal">
              <Field
                name="agendaTextOnTop"
                label="Agenda text on top"
                component={RichTextAreaField}
              />

              <Field
                name="agendaTextOnBottom"
                label="Agenda text on bottom"
                component={RichTextAreaField}
              />
            </FormRow>
            <Field
              width={16}
              name="attendeesText"
              label="Attendees Tab"
              component={RichTextAreaField}
            />
            <FormRow>
              <Field
                width={3}
                component={CheckboxField}
                name="showCurrentAttendeeList"
                label="Make current attendee list live"
              />
              <Field
                width={3}
                component={CheckboxField}
                name="showPreviousAttendeeList"
                label="Show previous attendee lists"
              />
            </FormRow>
            {values.showPreviousAttendeeList && (
              <PastEventSelect
                width={6}
                name="previousEvents"
                label="Select previous events"
                isMulti={true}
              />
            )}
            <FormRow>
              <Field
                name="beforeAttendeesText"
                label="Before attendees text"
                component={RichTextAreaField}
              />
              <Field
                name="afterAttendeesText"
                label="After attendees text"
                component={RichTextAreaField}
              />
            </FormRow>
            <FormRow>
              <BannersSelect
                name="banners"
                label="Banners"
                isMulti
                enableOrdering={true}
              />
            </FormRow>
            <ErrorsAndCreateButtonInRow
              touched={touched}
              errors={errors}
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(CourseCreateForm);
