import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const mailshotUpdate = async (mailshotId, data) => {
  const url = `${BASE_URL}/emails/mailshots/${mailshotId}/update/`;

  return await requestSdk(() => post(url, data));
};

export const mailshotSaveAsNew = async (mailshotId, data) => {
  const url = `${BASE_URL}/emails/mailshots/${mailshotId}/save-as-new/`;

  return await requestSdk(() => post(url, data));
};
