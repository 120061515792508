import React from 'react';

import { withRouter } from 'react-router-dom';

import { Segment, Button, Form, Header } from 'semantic-ui-react';

import { Formik } from 'formik';

import { openInNewTab } from 'utils';

import {
  Field,
  FormRow,
  InputField,
  RegionSelect,
  ArticleGroupSelect,
  AdminSelect,
  SimpleSelect
} from 'components';
import {
  handleFormErrors,
  prepareFormData,
  toSimpleSelectOptions
} from 'utils/forms';

import { newsletterDownloadPdf, checkPdfStatus, downloadPdfApi } from './sdk';

class DownloadPdfForm extends React.Component {
  state = {
    isDowloading: false,
    pdfLink: null
  };

  handleSubmit = async (values, actions) => {
    const { newsletter } = this.props;

    const { setSubmitting, setFieldError } = actions;

    const postData = prepareFormData(values);

    setSubmitting(true);

    const { data, errors, success } = await newsletterDownloadPdf(
      newsletter.id,
      postData
    );

    setSubmitting(false);

    if (success) {
      const pdfUuid = data.identifier;
      this.setState({ isDowloading: true, pdfUuid });

      const intervalTime = 1000 * 10; // 10 sec

      this.pollInterval = setInterval(this.callPdfStatus, intervalTime);

      return;
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  callPdfStatus = async () => {
    const { pdfUuid } = this.state;

    const { data, success } = await checkPdfStatus(pdfUuid);

    if (success && data.completed) {
      this.setState({ isDowloading: false });

      clearInterval(this.pollInterval);
      this.pollInterval = null;

      this.downloadPdf();
    }

    return null;
  };

  downloadPdf = async () => {
    const { pdfUuid } = this.state;

    const { data, success } = await downloadPdfApi(pdfUuid);

    if (success) {
      this.setState({ pdfLink: data.file });

      return;
    }
    return null;
  };

  render() {
    const editionOptions = toSimpleSelectOptions(['Full', 'Headlines']);

    const initialValues = {
      sampleName: 'Kallanish News 2020',
      orderBy: 'Region',
      region: null,
      product: null,
      accountManager: null,
      dailyNewsletterType: 'full'
    };

    const { pdfLink, isDowloading } = this.state;

    return (
      <Segment>
        <Header as="h3">Printable Newsletter</Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={this.handleSubmit}>
          {({ values, handleSubmit, isSubmitting }) => (
            <Form>
              <FormRow>
                <Field
                  required
                  component={InputField}
                  name="sampleName"
                  label="Sample Name"
                  width={10}
                />
                <Field
                  component={SimpleSelect}
                  name="dailyNewsletterType"
                  options={editionOptions}
                  isClearable={false}
                  width={6}
                  label="Edition"
                />
              </FormRow>
              <FormRow>
                <Field
                  component={ArticleGroupSelect}
                  name="orderBy"
                  isClearable={false}
                  label="Order by"
                />
                <Field component={RegionSelect} name="region" label="Region" />
              </FormRow>
              <FormRow alignEnd>
                <Field
                  width={8}
                  component={AdminSelect}
                  name="accountManager"
                  label="Account Manager"
                />
                <Button
                  size="large"
                  onClick={handleSubmit}
                  type="submit"
                  primary
                  disabled={isSubmitting || isDowloading}
                  loading={isSubmitting || isDowloading}>
                  Generate PDF
                </Button>
                {!isDowloading && pdfLink && (
                  <Button
                    size="large"
                    onClick={() => openInNewTab(pdfLink)}
                    color="green"
                    disabled={isSubmitting}>
                    Open PDF
                  </Button>
                )}
              </FormRow>
            </Form>
          )}
        </Formik>
      </Segment>
    );
  }
}

export default withRouter(DownloadPdfForm);
