import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const pipelineItemUpdate = async (id, data) => {
  const url = `${BASE_URL}/crm/pipelines/${id}/update/`;

  return await requestSdk(() => post(url, data));
};

export const pipelineItemRemove = async id => {
  const url = `${BASE_URL}/crm/pipelines/${id}/remove/`;

  return await requestSdk(() => post(url));
};
