import React from 'react';

import { Field, SelectField } from 'components';

import { glossaryCategoriesList } from './sdk';

class GlossaryCategorySelect extends React.Component {
  state = {
    glossaryCategories: []
  };

  async componentDidMount() {
    const { data, success } = await glossaryCategoriesList();

    if (success) {
      this.setState({ glossaryCategories: data });
    }
  }

  render() {
    const { name, ...rest } = this.props;
    const { glossaryCategories } = this.state;

    const options = glossaryCategories.map(category => {
      return {
        key: category.id,
        value: category.id,
        label: category.title
      };
    });

    return (
      <Field
        name={name || 'glossaryCategory'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default GlossaryCategorySelect;
