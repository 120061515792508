import * as yup from 'yup';

export const companyV2CreateSchema = yup.object().shape({
  title: yup.string().required('Title is required.'),
  slug: yup.string().required('Slug is required.'),
  product: yup
    .array()
    .of(yup.string())
    .required('Product is required.')
});
