import React from 'react';

import _ from 'lodash';

import { withRouter } from 'react-router-dom';

import { FieldArray } from 'formik';

import { Table } from 'semantic-ui-react';

import { Field, InputField } from 'components';

class MonthsForm extends React.Component {
  render() {
    const { product, index } = this.props;
    const productValues = product.value;

    const totalByMonths = _.sumBy(productValues, item =>
      _.toInteger(item.value)
    );

    return (
      <Table.Row textAlign="center">
        <Table.Cell>{totalByMonths}</Table.Cell>
        <Table.Cell>{product.key}</Table.Cell>
        <FieldArray
          name="value"
          render={() =>
            productValues.map((productValue, productIndex) => (
              <Table.Cell style={{ padding: ' 11px 5px' }}>
                <Field
                  name={`data.${index}.value.${productIndex}.value`}
                  component={InputField}
                  small
                />
              </Table.Cell>
            ))
          }
        />
      </Table.Row>
    );
  }
}

export default withRouter(MonthsForm);
