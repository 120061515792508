import _ from 'lodash';
import React from 'react';

import { Table } from 'semantic-ui-react';

const ExchangeCurrencyRatesTable = ({ rates }) => (
  <Table celled selectable style={{ maxWidth: '500px' }}>
    <Table.Header>
      <Table.Row textAlign="center" verticalAlign="middle">
        <Table.HeaderCell>Currency</Table.HeaderCell>
        <Table.HeaderCell>Rate</Table.HeaderCell>
        <Table.HeaderCell>+/-</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {_.map(rates, rate => (
        <Table.Row key={rate.title}>
          <Table.Cell>{rate.title}</Table.Cell>
          <Table.Cell textAlign="center">{rate.rate}</Table.Cell>
          <Table.Cell textAlign="center">{rate.has_changed}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);

export default ExchangeCurrencyRatesTable;
