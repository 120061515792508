import moment from 'moment';

export const DAILY = 'daily';
export const WEEKLY = 'weekly';
export const MONTHLY = 'monthly';
export const QUARTERLY = 'quarterly';

const DATE_FORMAT = 'YYYY-MM-DD';

const getDate = filters => {
  let date = filters.date;

  if (date) {
    return moment(date, DATE_FORMAT);
  }

  return moment();
};

const prepareFilter = date => {
  return {
    date: date.format(DATE_FORMAT)
  };
};

const getMonday = date => {
  let monday = null;

  const weekday = date.isoWeekday(); // 1 = Monday, 7 = Sunday

  if (weekday === 1) {
    monday = date;
  } else {
    monday = date.add(7 - weekday + 1, 'days');
  }

  return monday;
};

const onDailySmallStepBack = (filters, filterBy) => {
  const date = getDate(filters);

  const newDate = date.subtract(1, 'days');

  filterBy(prepareFilter(newDate));
};

const onDailyBigStepBack = (filters, filterBy) => {
  const date = getDate(filters);

  const newDate = date.subtract(1, 'weeks');

  filterBy(prepareFilter(newDate));
};

const onDailySmallStepForward = (filters, filterBy) => {
  const date = getDate(filters);
  const newDate = date.add(1, 'days');

  filterBy(prepareFilter(newDate));
};

const onDailyBigStepForward = (filters, filterBy) => {
  const date = getDate(filters);

  const newDate = date.add(1, 'weeks');

  filterBy(prepareFilter(newDate));
};

const onWeeklySmallStepBack = (filters, filterBy) => {
  const date = getDate(filters);

  // Last week's Monday
  const newDate = date
    .startOf('isoWeek') // Monday
    .subtract(1, 'days') // Saturday, previous week
    .startOf('isoWeek'); // Monday, previous week

  filterBy(prepareFilter(newDate));
};

const onWeeklyBigStepBack = (filters, filterBy) => {
  const date = getDate(filters);

  // Last months's first Monday
  const startOfPreviousMonth = date
    .startOf('month') // Begin of month
    .subtract(1, 'days') // Previous month
    .startOf('month'); // Begin of previous month

  const newDate = getMonday(startOfPreviousMonth);

  filterBy(prepareFilter(newDate));
};

const onWeeklySmallStepForward = (filters, filterBy) => {
  const date = getDate(filters);

  // Next week's Monday
  const newDate = date
    .endOf('isoWeek') // Saturday
    .add(1, 'days'); // Monday, next week

  filterBy(prepareFilter(newDate));
};

const onWeeklyBigStepForward = (filters, filterBy) => {
  const date = getDate(filters);

  // Next month's Monday
  let newDate = date
    .endOf('month') // Saturday
    .startOf('isoWeek');

  if (newDate.month() === date.month()) {
    // In case we are already on next Monday of the month
    newDate = date
      .endOf('month')
      .add(1, 'days')
      .endOf('month')
      .startOf('isoWeek');
  }

  filterBy(prepareFilter(newDate));
};

const onMonthlySmallStepBack = (filters, filterBy) => {
  onWeeklyBigStepBack(filters, filterBy);
};

const onMonthlyBigStepBack = (filters, filterBy) => {
  const date = getDate(filters);

  const startOfMonth = date.subtract(3, 'months').startOf('month');

  const newDate = getMonday(startOfMonth);

  filterBy(prepareFilter(newDate));
};

const onMonthlySmallStepForward = (filters, filterBy) => {
  onWeeklyBigStepForward(filters, filterBy);
};

const onMonthlyBigStepForward = (filters, filterBy) => {
  const date = getDate(filters);

  const startOfMonth = date.add(3, 'months').startOf('month');

  const newDate = getMonday(startOfMonth);

  filterBy(prepareFilter(newDate));
};

const onQuarterlySmallStepBack = (filters, filterBy) => {
  const date = getDate(filters);

  const currentQuarter = date.quarter();
  const nextQuarter = currentQuarter - 1;

  let newDate = null;

  if (nextQuarter === 0) {
    // Go to next year
    newDate = date.subtract(1, 'years').quarter(nextQuarter);
  } else {
    newDate = date.quarter(nextQuarter);
  }

  filterBy(prepareFilter(newDate));
};

const onQuarterlyBigStepBack = (filters, filterBy) => {
  const date = getDate(filters);

  const newDate = date.subtract(3, 'quarters');

  filterBy(prepareFilter(newDate));
};

const onQuarterlySmallStepForward = (filters, filterBy) => {
  const date = getDate(filters);

  const currentQuarter = date.quarter();
  const nextQuarter = (currentQuarter + 1) % 4;

  let newDate = null;

  if (nextQuarter < currentQuarter) {
    // Go to next year
    newDate = date.add(1, 'years').quarter(nextQuarter);
  } else {
    newDate = date.quarter(nextQuarter);
  }

  filterBy(prepareFilter(newDate));
};

const onQuarterlyBigStepForward = (filters, filterBy) => {
  const date = getDate(filters);

  const newDate = date.add(3, 'quarters');

  filterBy(prepareFilter(newDate));
};

export const STEPS = {
  [DAILY]: {
    onSmallStepBack: onDailySmallStepBack,
    onBigStepBack: onDailyBigStepBack,
    onSmallStepForward: onDailySmallStepForward,
    onBigStepForward: onDailyBigStepForward
  },
  [WEEKLY]: {
    onSmallStepBack: onWeeklySmallStepBack,
    onBigStepBack: onWeeklyBigStepBack,
    onSmallStepForward: onWeeklySmallStepForward,
    onBigStepForward: onWeeklyBigStepForward
  },
  [MONTHLY]: {
    onSmallStepBack: onMonthlySmallStepBack,
    onBigStepBack: onMonthlyBigStepBack,
    onSmallStepForward: onMonthlySmallStepForward,
    onBigStepForward: onMonthlyBigStepForward
  },
  [QUARTERLY]: {
    onSmallStepBack: onQuarterlySmallStepBack,
    onBigStepBack: onQuarterlyBigStepBack,
    onSmallStepForward: onQuarterlySmallStepForward,
    onBigStepForward: onQuarterlyBigStepForward
  }
};
