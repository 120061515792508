import React from 'react';

import File from 'react-dropzone';

import { Icon, Button, Segment } from 'semantic-ui-react';

import styles from './styles.module.css';
import _ from 'lodash';

class UploadWithPreview extends React.Component {
  render() {
    const { fileUrl, onDrop, multiple, accept, maxSize } = this.props;

    return (
      <div className={styles.Container}>
        <>
          <Segment className={styles.segment}>
            {!_.isNil(fileUrl) && (
              <a
                className={styles.previewLink}
                target="_blank"
                href={fileUrl}
                rel="noopener noreferrer">
                <Icon name="file pdf" size="big" />
                Preview
              </a>
            )}
            <File
              onDrop={onDrop}
              multiple={multiple}
              {...(accept && { accept })}
              {...(maxSize && { maxSize })}>
              {({ getRootProps, getInputProps }) => (
                <span className={styles.uploadFile} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Button color="grey" basic>
                    <Icon name="upload" color="grey" />
                    Upload file
                  </Button>
                </span>
              )}
            </File>
          </Segment>
        </>
      </div>
    );
  }
}

export default UploadWithPreview;
