import _ from 'lodash';

import { useEffect, useState } from 'react';

import Flex from 'components/Flex';

import { Input, Icon } from 'semantic-ui-react';

export const GoToPageInputField = ({ goToPageHandler, totalPages }) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    // if the totalPages has changed (for example because of a rows per page change)
    // we need to reset the field value
    setValue('');
  }, [totalPages]);

  const disableSubmission = _.isNil(value) || value === '';

  return (
    <form
      onSubmit={e => {
        e.preventDefault();

        if (disableSubmission) {
          return;
        }

        goToPageHandler(value);
      }}>
      <Flex style={{ gap: '0.5rem' }}>
        Go To Page:
        <Input
          style={{ width: '130px' }}
          type="number"
          value={value}
          onChange={(e, data) => {
            if (data.value === '') {
              setValue(null);
              return;
            }

            const newValue = parseInt(data.value);

            if (newValue < 1) {
              setValue(1);
              return;
            }

            if (newValue >= totalPages) {
              setValue(totalPages);
              return;
            }

            setValue(newValue);
          }}
          icon={
            <Icon
              link={!disableSubmission}
              type="submit"
              name="arrow right"
              onClick={() => goToPageHandler(value)}
              disabled={disableSubmission}
            />
          }
        />
      </Flex>
    </form>
  );
};

export default GoToPageInputField;
