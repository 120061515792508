import React from 'react';

import _ from 'lodash';

import { Segment, Header, Form, Button } from 'semantic-ui-react';

import { Formik } from 'formik';

import { ConfirmationModal, UniSelect } from 'components';

import { notifySuccess } from 'utils/notifications';

import { assignStaffMemberToSalesTerritory } from './sdk';

class StaffProfileModal extends React.Component {
  state = {
    openConfirmationModal: false,
    targetStaffProfile: null,
    currentStaffProfile: null,
    salesTerritory: null,
    confirmationModalMessage: null,
    confirmationModalTitle: 'Overriding existing Account Manager'
  };

  handleModalConfirmation = () => {
    this.props.onClose();
    this.submitData();
  };

  onCloseConfirmationModal = async () => {
    this.setState({ openConfirmationModal: false });
  };

  handleSalesTerritoryStaffProfileChange = staffProfile => {
    if (staffProfile != null) {
      this.setState({ targetStaffProfile: staffProfile });
    }
  };

  handleSubmit = async () => {
    if (!_.isNil(this.state.currentStaffProfile)) {
      const message =
        `The account manager for ${this.state.salesTerritory.title} is ${this.state.currentStaffProfile.first_name} ${this.state.currentStaffProfile.last_name}` +
        `. Continuing would override current account manager!`;

      this.setState({ confirmationModalMessage: message }, () => {
        this.setState({ openConfirmationModal: true });
      });
    } else {
      this.submitData();
      this.props.onClose();
    }
  };

  submitData = async () => {
    const data = {
      staff_profile_user: this.state.targetStaffProfile.target.key
    };

    const { success } = await assignStaffMemberToSalesTerritory(
      this.state.salesTerritory.id,
      data
    );

    if (success) {
      notifySuccess('Sales territory updated');
      const { refetch } = this.props;
      refetch();
    }
  };

  componentDidMount() {
    this.setState({
      currentStaffProfile: this.props.currentStaffProfile,
      salesTerritory: this.props.extraData
    });
  }

  render() {
    const territoryTitle = this.props.extraData.title;
    return (
      <Segment>
        <Header as="h2">Select Staff Profile for {territoryTitle}</Header>
        <Formik onSubmit={this.handleSubmit}>
          {({ isSubmitting, onSubmit }) => (
            <Form>
              <UniSelect
                width={6}
                name="territoryStaffProfile"
                label="Territory Staff Profile"
                source="salesTerritoryStaffProfiles"
                onChange={this.handleSalesTerritoryStaffProfileChange}
              />
              {this.state.targetStaffProfile && (
                <Form.Field width={4}>
                  <Button
                    fluid
                    type="submit"
                    onClick={this.handleSubmit}
                    color="blue"
                    disabled={isSubmitting}>
                    Submit
                  </Button>
                </Form.Field>
              )}
              {this.state.targetStaffProfile && (
                <ConfirmationModal
                  title={this.state.confirmationModalTitle}
                  message={this.state.confirmationModalMessage}
                  open={this.state.openConfirmationModal}
                  onClose={this.onCloseConfirmationModal}
                  handleConfirmation={this.handleModalConfirmation}
                  handleDecline={() => {}}
                />
              )}
            </Form>
          )}
        </Formik>
      </Segment>
    );
  }
}
export default StaffProfileModal;
