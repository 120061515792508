import React from 'react';

import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { WebArticleDetail } from 'pages';

import { formatDate, formatDateTime } from 'utils';

import { Admin, TableFooter, SortableHeader, ArticleStatus } from 'components';

const ArticleTable = ({ articles, sort, orderBy, pagination, productSlug }) => (
  <Table celled selectable compact>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell singleLine style={{ fontWeight: '400' }} colspan="7">
          {pagination}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <SortableHeader sort={sort} orderBy={orderBy} field="title">
          Title
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="date">
          Date
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="status">
          Status
        </SortableHeader>
        <Table.HeaderCell>Authors</Table.HeaderCell>
        <SortableHeader sort={sort} orderBy={orderBy} field="updated_by">
          Update by
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="updated_at">
          Update time
        </SortableHeader>
        <SortableHeader
          sort={sort}
          orderBy={orderBy}
          field="ready_for_publication_date"
          textAlign="center">
          Ready for Publication Date
        </SortableHeader>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {articles.map(article => (
        <Table.Row key={article.id}>
          <Table.Cell width={3}>
            <Link
              to={pageUrl(WebArticleDetail, {
                productSlug,
                articleId: article.id
              })}>
              {article.full_headline}
            </Link>
          </Table.Cell>
          <Table.Cell>{formatDate(article.date)}</Table.Cell>
          <Table.Cell textAlign="center">
            <ArticleStatus value={article.status} />
          </Table.Cell>
          <Table.Cell width={3}>
            {article.authors.map((author, index) => (
              <>
                {index > 0 && ', '}
                <Admin value={author} key={index} />{' '}
              </>
            ))}
          </Table.Cell>
          <Table.Cell width={3}>
            <Admin value={article.updated_by} />
          </Table.Cell>
          <Table.Cell>{formatDateTime(article.updated_at)}</Table.Cell>
          <Table.Cell textAlign="center">
            {(article.ready_for_publication_date &&
              formatDateTime(article.ready_for_publication_date)) ||
              'N/A'}
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default ArticleTable;
