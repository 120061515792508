import _ from 'lodash';

export const areAllArticlesConnected = (rfpArticles, contentSections) => {
  const contentSectionArticles = _.uniq(
    _.flatten(
      _.map(contentSections, section =>
        _.map(_.get(section, 'section_data.articles', []), 'id')
      )
    )
  );

  return !_.isEmpty(
    _.difference(_.map(rfpArticles, 'id'), contentSectionArticles)
  );
};
