import React from 'react';

import { Header, Segment } from 'semantic-ui-react';

import { Page, LoginRequired } from 'components';

import { PermissionsRequired, PERMISSIONS } from 'permissions';

import PriceSpreadCreateForm from './form';

class PriceSpreadCreate extends React.Component {
  render() {
    const { user, crumbs } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Header as="h1">Create Price Spread</Header>
              <PriceSpreadCreateForm />
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(
  PermissionsRequired([PERMISSIONS.CREATE_PRICES])(PriceSpreadCreate)
);
