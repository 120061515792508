import React from 'react';

import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { Form } from 'semantic-ui-react';

import { handleFormErrors } from 'utils/forms';

import {
  Field,
  UpdateButtonInRow,
  TextAreaField,
  UnsavedFormPrompt
} from 'components';

import { sentimentalHeatMapUpdate } from './sdk';
import { notifySuccess } from 'utils/notifications';
import { sentimentHeatMapUpdateSchema } from './utils';

class SentimentHeatMapUpdateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    setSubmitting(true);

    const { errors, success } = await sentimentalHeatMapUpdate(values);

    setSubmitting(false);

    if (success) {
      notifySuccess('Sentiment heat map is updated.');
      this.props.fetchSentimentHeatMap();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const { content } = this.props.data;

    const initialValues = {
      content
    };

    return (
      <Formik
        enableReinitialize={true}
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        validationSchema={sentimentHeatMapUpdateSchema}>
        {({ handleSubmit, isSubmitting, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty} />

            <Field
              required
              name="content"
              label="Content"
              component={TextAreaField}
            />

            <UpdateButtonInRow
              subject="Sentiment Heat Map"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(SentimentHeatMapUpdateForm);
