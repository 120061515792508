import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import { Header, Segment } from 'semantic-ui-react';

import { openInNewTab, formatDateTime } from 'utils';
import { notifySuccess, notifyErrors } from 'utils/notifications';

import { pageUrl } from 'config/routes';

import { ClientDetail } from 'pages';

import { BACKEND_URL } from 'sdk/urls';

import {
  Page,
  LoginRequired,
  Error,
  Flex,
  Button,
  DisplayItem,
  Admin,
  InvoiceSendModal
} from 'components';

import {
  PermissionsRequired,
  DisableableElement,
  hasPermission,
  PERMISSIONS,
  VisibleIf
} from 'permissions';

import { invoiceDetail, invoiceSaveAsValid } from './sdk';

import {
  InvoiceAddForm,
  InvoiceUpdateForm,
  InvoiceItemTable,
  InvoiceComments
} from './components';

class InvoiceDetail extends React.Component {
  state = {
    errors: null,
    invoice: null,
    invoiceSendModal: false
  };

  onClientProfileClick = () => {
    const { invoice } = this.state;

    const clientId = invoice.contact_info.client.id;

    const url = pageUrl(ClientDetail, { clientId });

    this.props.history.push(url);
  };

  onPreviewClick = () => {
    const { invoice } = this.state;

    const url = `${BACKEND_URL}/crm/invoice_preview/${invoice.id}/`;

    openInNewTab(url);
  };

  onPreviewAsPdfClick = () => {
    const { invoice } = this.state;

    const url = `${BACKEND_URL}/crm/invoice_preview_pdf/${invoice.id}/`;

    openInNewTab(url);
  };

  onSaveAsValidInvoiceClick = async () => {
    const { invoice } = this.state;

    const { success, errors } = await invoiceSaveAsValid(invoice.id);

    if (success) {
      this.fetchInvoice();
      notifySuccess('Invoice saved as valid');
    } else {
      notifyErrors(errors);
    }
  };

  fetchInvoice = async () => {
    const { invoiceId } = this.props.match.params;

    const { data, errors, success } = await invoiceDetail(invoiceId);

    if (success) {
      this.setState({ invoice: data });
    } else {
      this.setState({ errors });
    }
  };

  openInvoiceSendModal = () => {
    this.setState({ invoiceSendModal: true });
  };

  closeInvoiceSendModal = () => {
    this.setState({ invoiceSendModal: false });
  };

  componentDidMount() {
    this.fetchInvoice();
  }

  render() {
    const { user, crumbs } = this.props;

    const { invoice, errors, invoiceSendModal } = this.state;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Flex spaceBetween>
              <Header as="h1">
                Edit invoice {(invoice && invoice.identifier) || '...'}
              </Header>
              <div>
                {_.get(invoice, 'contact_info.client') && (
                  <Button onClick={this.onClientProfileClick}>
                    Client profile
                  </Button>
                )}
                <Button onClick={this.onPreviewClick}>Preview</Button>
                <Button onClick={this.onPreviewAsPdfClick}>
                  Preview as PDF
                </Button>
                {invoice &&
                  invoice.invoice_status === 'Paid' &&
                  !invoice.is_invoice && (
                    <Button
                      color="blue"
                      onClick={this.onSaveAsValidInvoiceClick}>
                      Save as valid invoice
                    </Button>
                  )}
                <VisibleIf
                  user={user}
                  permissions={[PERMISSIONS.SEND_INVOICES]}>
                  <Button color="red" onClick={this.openInvoiceSendModal}>
                    Send
                  </Button>
                </VisibleIf>
              </div>
            </Flex>
            {errors &&
              errors.map((error, index) => (
                <Error key={index}>{error.message}</Error>
              ))}

            {invoice && (
              <>
                <Segment>
                  <Flex flexStart>
                    <DisplayItem label="ID" value={invoice.id} />
                    <DisplayItem
                      label="Identifier"
                      value={invoice.identifier}
                    />
                    <DisplayItem
                      label="Client ID"
                      value={_.get(invoice, 'contact_info.client.id', 'N/A')}
                    />
                    <DisplayItem
                      label="Last Edited at"
                      value={formatDateTime(invoice.updated_at)}
                    />
                    <DisplayItem
                      label="Last Edited by"
                      value={<Admin value={invoice.updated_by} />}
                    />
                  </Flex>
                </Segment>
                <DisableableElement
                  disabled={!hasPermission(user, PERMISSIONS.CHANGE_INVOICES)}>
                  <InvoiceAddForm
                    invoice={invoice}
                    fetchInvoice={this.fetchInvoice}
                  />
                  <InvoiceItemTable
                    invoice={invoice}
                    fetchInvoice={this.fetchInvoice}
                  />
                  <InvoiceUpdateForm
                    invoice={invoice}
                    fetchInvoice={this.fetchInvoice}
                  />

                  <InvoiceComments
                    invoice={invoice}
                    fetchInvoice={this.fetchInvoice}
                  />
                  {invoiceSendModal && (
                    <InvoiceSendModal
                      invoice={invoice}
                      onClose={this.closeInvoiceSendModal}
                    />
                  )}
                </DisableableElement>
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(
  PermissionsRequired([PERMISSIONS.VIEW_INVOICES])(withRouter(InvoiceDetail))
);
