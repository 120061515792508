import React from 'react';
import _ from 'lodash';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import { Field, InputField, FormRow, CreateButton } from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { setClientLink } from './sdk';

class LinkForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { client } = this.props;

    if (_.get(client, 'client_profile.linked_client.id')) {
      const confirm = window.confirm(
        'You will override the current linked client. Are you sure you want to processed?'
      );

      if (!confirm) {
        return;
      }
    }

    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await setClientLink(client.id, data);

    setSubmitting(false);

    if (success) {
      notifySuccess(`Client linked successfully`);
      this.props.fetchClient();
      return;
    }
    handleFormErrors(errors, setFieldError);
  };

  render() {
    const initialValues = {
      linkedClient: _.get(
        this.props.client,
        'client_profile.linked_client.id',
        ''
      )
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={this.handleSubmit}>
        {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
          <Form>
            <FormRow alignEnd>
              <Field width={8} name="linkedClient" component={InputField} />
              <Form.Field width={6}>
                <CreateButton
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  size="small">
                  Change linked client
                </CreateButton>
              </Form.Field>
            </FormRow>
          </Form>
        )}
      </Formik>
    );
  }
}

export default LinkForm;
