import React from 'react';

import { Table } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import { TableFooter, YesNo, LinkButton } from 'components';

import { pageUrl } from 'config/routes';

import { ClientDetail } from 'pages';

import { BACKEND_URL } from 'sdk/urls';

import { openInNewTab } from 'utils';
import { notifySuccess, notifyErrors } from 'utils/notifications';

import { SendTestEmailForm } from './components';

import { mailshotRemoveClient } from './sdk';

class ClientTable extends React.Component {
  state = {
    testEmails: {}
  };

  getClientDetailUrl = clientId => {
    return pageUrl(ClientDetail, { clientId });
  };

  getPreviewUrl = (type, clientId) => {
    const { mailshot } = this.props;

    return `${BACKEND_URL}/emails/mailshot/view/${mailshot.id}/${clientId}/${type}/`;
  };

  onSendTestEmailClick = (event, client) => {
    event.preventDefault();

    this.setState(prevState => {
      return {
        testEmails: {
          ...prevState.testEmails,
          [client.id]: true
        }
      };
    });
  };

  onSendTestEmailCancel = client => {
    this.setState(prevState => {
      return {
        testEmails: {
          ...prevState.testEmails,
          [client.id]: false
        }
      };
    });
  };

  showTestEmailForm = client => {
    return !!this.state.testEmails[client.id];
  };

  onRemoveSelectedClick = async client => {
    const { mailshot, refetch } = this.props;

    const { success, errors } = await mailshotRemoveClient(mailshot.id, {
      client: client.id
    });

    if (success) {
      notifySuccess('Client removed.');
      refetch();
    } else {
      notifyErrors(errors);
    }
  };

  render() {
    const { clients, orderBy, pagination, mailshot } = this.props;

    return (
      <Table celled selectable compact={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              singleLine
              style={{ fontWeight: '400' }}
              colspan="10">
              {pagination}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell onClick={() => orderBy('id')}>
              Id
            </Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>First name</Table.HeaderCell>
            <Table.HeaderCell>Last name</Table.HeaderCell>
            <Table.HeaderCell>Company</Table.HeaderCell>
            <Table.HeaderCell>Sent</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {clients.map(client => (
            <Table.Row key={client.id}>
              <Table.Cell>
                <Link to={this.getClientDetailUrl(client.id)}>{client.id}</Link>
              </Table.Cell>
              <Table.Cell>{client.email}</Table.Cell>
              <Table.Cell width={1}>{client.first_name}</Table.Cell>
              <Table.Cell width={1}>{client.last_name}</Table.Cell>
              <Table.Cell>{client.client_profile.company_name}</Table.Cell>
              <Table.Cell>
                <YesNo value={client.mailshot_sent} />
              </Table.Cell>
              <Table.Cell>
                <LinkButton
                  onClick={() =>
                    openInNewTab(this.getPreviewUrl('html', client.id))
                  }>
                  HTML Preview
                </LinkButton>
              </Table.Cell>
              <Table.Cell>
                <LinkButton
                  onClick={() =>
                    openInNewTab(this.getPreviewUrl('text', client.id))
                  }>
                  TEXT Preview
                </LinkButton>
              </Table.Cell>
              <Table.Cell>
                {!this.showTestEmailForm(client) && (
                  <LinkButton
                    onClick={event => this.onSendTestEmailClick(event, client)}>
                    Send Test E-Email
                  </LinkButton>
                )}
                {this.showTestEmailForm(client) && (
                  <SendTestEmailForm
                    client={client}
                    mailshot={mailshot}
                    cancel={this.onSendTestEmailCancel}
                  />
                )}
              </Table.Cell>
              <Table.Cell>
                <LinkButton onClick={() => this.onRemoveSelectedClick(client)}>
                  Remove selected
                </LinkButton>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <TableFooter>{pagination}</TableFooter>
      </Table>
    );
  }
}

export default ClientTable;
