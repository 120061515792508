import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const subscriptionFeatureUpdate = async (id, data) => {
  const url = `${BASE_URL}/editorials/subscription-features/${id}/update/`;

  return await requestSdk(() => post(url, data));
};

export const subscriptionFeatureDelete = async id => {
  const url = `${BASE_URL}/editorials/subscription-features/${id}/delete/`;

  return await requestSdk(() => post(url));
};
