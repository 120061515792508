import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const faqDetail = async id => {
  const url = `${BASE_URL}/cms/faqs/${id}/`;

  return await requestSdk(() => get(url));
};

export const faqUpdateMeta = async (id, data) => {
  const url = `${BASE_URL}/cms/faqs/${id}/update-meta/`;

  return await requestSdk(() => post(url, data));
};
