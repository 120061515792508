import React from 'react';

import { Field, SelectField } from 'components';

const toLabel = (value, yesLabel, noLabel) => {
  if (value === 'Yes') {
    return yesLabel || 'Yes';
  }

  return noLabel || 'No';
};
const YES_NO_OPTIONS = [
  { key: 'Yes', value: true },
  { key: 'No', value: false }
];

class YesNoSelect extends React.Component {
  render() {
    const { name, yesLabel, noLabel, ...rest } = this.props;

    const options = YES_NO_OPTIONS.map(option => {
      return {
        key: option.key,
        value: option.value,
        label: toLabel(option.key, yesLabel, noLabel)
      };
    });

    return (
      <Field
        name={name || 'yesNo'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default YesNoSelect;
