import React from 'react';

import { Formik } from 'formik';
import { Form } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import {
  Field,
  UpdateButtonInRow,
  FormRow,
  DateField,
  FileField,
  InputField,
  AdminSelect,
  DateTimeField,
  TextAreaField,
  RichTextAreaField,
  YesNoSelect,
  UnsavedFormPrompt,
  ErrorsAndUpdateButtonInRow,
  CreatedUpdatedFormValues,
  UniSelect
} from 'components';

import { weeklySteelUpdateSchema } from './utils';

class WeeklySteelUpdateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const { fetchWeeklySteelDetail, updateSdk, subject } = this.props;
    const { id } = this.props.match.params;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await updateSdk(id, data);

    setSubmitting(false);

    if (success) {
      notifySuccess(`${subject} updated.`);
      fetchWeeklySteelDetail();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const {
      issue_number: issueNumber,
      issue_date: issueDate,
      title,
      lead,
      text,
      datetime,
      editor,
      marketer,
      cover,
      pdf,
      sample_pdf: samplePdf,
      editor_approved: editorApproved,
      marketing_approved: marketingApproved,
      live,
      trial_subject: trialSubject,
      trial_html: trialHtml,
      trial_text: trialText,
      subscriber_subject: subscriberSubject,
      subscriber_html: subscriberHtml,
      subscriber_text: subscriberText,
      chasing_subject: chasingSubject,
      chasing_html: chasingHtml,
      chasing_text: chasingText,
      created_by: createdBy,
      created_at: createdAt,
      updated_by: updatedBy,
      updated_at: updatedAt,
      tag,
      disable_samples: disableSamples
    } = this.props.data;

    const { subject } = this.props;

    const nonFormValues = {
      createdBy,
      createdAt,
      updatedBy,
      updatedAt,
      live
    };
    const initialValues = {
      pdf,
      samplePdf,
      cover,
      issueNumber,
      issueDate,
      title,
      lead,
      text,
      datetime,
      editor,
      marketer,
      editorApproved,
      marketingApproved,
      trialSubject,
      trialHtml,
      trialText,
      subscriberSubject,
      subscriberHtml,
      subscriberText,
      chasingSubject,
      chasingHtml,
      chasingText,
      tag,
      disableSamples
    };

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        validationSchema={weeklySteelUpdateSchema}
        enableReinitialize={true}>
        {({ handleSubmit, isSubmitting, dirty, touched, errors }) => (
          <Form>
            <UnsavedFormPrompt when={dirty} />
            <FormRow>
              <Field
                required
                width={10}
                name="title"
                component={InputField}
                label="Title"
              />
              <Form.Field width={6}>
                <UpdateButtonInRow
                  subject={subject}
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                />
              </Form.Field>
            </FormRow>
            <FormRow>
              <Field
                required
                width={3}
                name="issueNumber"
                component={InputField}
                label="Issue number"
              />
              <Field
                required
                width={3}
                name="issueDate"
                component={DateField}
                label="Issue date"
              />
              <Field
                required
                width={4}
                name="datetime"
                component={DateTimeField}
                label="Date to go Out"
              />
              <UniSelect
                source="weeklySeasmTags"
                name="tag"
                width={3}
                label="Tag"
              />
              <Field
                width={2}
                name="disableSamples"
                component={YesNoSelect}
                label="Disable samples"
              />
            </FormRow>

            <FormRow>
              <Field
                required
                width={5}
                name="editor"
                component={AdminSelect}
                label="Editor"
              />

              <Field
                width={3}
                name="editorApproved"
                component={YesNoSelect}
                label="Editor Approved"
              />

              <Field
                required
                width={5}
                name="marketer"
                component={AdminSelect}
                label="Marketer"
              />
              <Field
                width={3}
                name="marketingApproved"
                component={YesNoSelect}
                label="Marketer Approved"
              />
            </FormRow>
            <FormRow widths="equal">
              <Field
                required
                name="cover"
                component={FileField}
                label="Cover Image"
              />

              <Field required name="pdf" component={FileField} label="PDF" />

              <Field
                required
                name="samplePdf"
                component={FileField}
                label="Sample PDF"
              />
            </FormRow>

            <Field
              required
              name="lead"
              component={RichTextAreaField}
              label="Lead"
            />

            <Field
              required
              name="text"
              component={RichTextAreaField}
              label="Text"
            />

            <Field
              width={6}
              name="trialSubject"
              component={InputField}
              label="Trials Subject"
            />
            <Field
              name="trialHtml"
              component={RichTextAreaField}
              label="Trials HTML"
              config={{
                height: '400px'
              }}
            />
            <Field
              name="trialText"
              component={TextAreaField}
              label="Trials Text"
            />

            <Field
              width={6}
              name="subscriberSubject"
              component={InputField}
              label="Subscribers Subject"
            />
            <Field
              name="subscriberHtml"
              component={RichTextAreaField}
              label="Subscribers HTML"
              config={{
                height: '400px'
              }}
            />
            <Field
              name="subscriberText"
              component={TextAreaField}
              label="Subscribers Text"
            />

            <Field
              width={6}
              name="chasingSubject"
              component={InputField}
              label="Chasing Subject"
            />
            <Field
              name="chasingHtml"
              component={RichTextAreaField}
              label="Chasing HTML"
              config={{
                height: '400px'
              }}
            />

            <Field
              name="chasingText"
              component={TextAreaField}
              label="Chasing Text"
            />

            <CreatedUpdatedFormValues
              values={nonFormValues}
              itemsAfter={[
                {
                  label: 'Live',
                  value: nonFormValues.live ? 'live' : 'Not live'
                }
              ]}
            />
            <ErrorsAndUpdateButtonInRow
              touched={touched}
              errors={errors}
              subject={subject}
              onClick={handleSubmit}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(WeeklySteelUpdateForm);
