import _ from 'lodash';
import { toast } from 'react-toastify';

const DEFAULT_ERROR_MESSAGE = 'Error occurred.';

const humanizeFieldName = fieldName =>
  _.capitalize(_.snakeCase(fieldName).replace('_', ' '));

const getMessageFromError = error =>
  _.get(error, 'message', DEFAULT_ERROR_MESSAGE);

export const buildErrorMessage = error => {
  const message = getMessageFromError(error);

  if (_.has(error, 'field')) {
    let fieldName = humanizeFieldName(error.field);
    return `${fieldName}: ${message}`;
  }

  return message;
};

export const notifySuccess = message => toast.success(message);

export const notifyError = (obj, options) => {
  let errorMessage = obj;

  if (typeof obj !== 'string') {
    errorMessage = buildErrorMessage(obj);
  }

  toast.error(errorMessage, options);
};

export const notifyErrors = (errors, options) => {
  if (_.isNil(errors)) {
    notifyError(DEFAULT_ERROR_MESSAGE);
    return;
  }
  errors.forEach(notifyError, options);
};
