import _ from 'lodash';
import React from 'react';

import { Table } from 'semantic-ui-react';

import { getResults } from './utils';

class Results extends React.Component {
  render() {
    const { poll } = this.props;

    const { vote_set, choice_set } = poll;

    const results = getResults({ choice_set, vote_set });

    return (
      <>
        {_.isEmpty(vote_set) && "There aren't votes yet."}
        {!_.isEmpty(vote_set) && (
          <Table celled style={{ maxWidth: '600px' }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Answer</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Votes</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Percentage
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {results.map(result => (
                <Table.Row>
                  <Table.Cell width={8}>{result.choice}</Table.Cell>
                  <Table.Cell textAlign="center">
                    {result.votesCount}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {result.percentage}%
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </>
    );
  }
}

export default Results;
