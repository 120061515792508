import _ from 'lodash';
import { requestSdk, post, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const sectionRelated = async sdkArgs => {
  // Gets articles or prices list
  // Adds or removes article/price to section
  // Reorders articles or prices in section
  const { sectionId, itemsType, items, itemId, action } = sdkArgs;

  let url = `${BASE_URL}/newsletters/v2/content-sections/${sectionId}/${itemsType}/`;
  if (!_.isNil(action)) {
    url += `${action}/`;
  } else {
    return await requestSdk(() => get(url));
  }

  const data = items ? items : { id: itemId };

  return await requestSdk(() => post(url, data));
};
