import React from 'react';
import { Header, Icon, Segment } from 'semantic-ui-react';

const NoDataMessage = () => (
  <Segment placeholder>
    <Header icon>
      <Icon name="search" />
      We don't have any data matching your query.
    </Header>
  </Segment>
);

export default NoDataMessage;
