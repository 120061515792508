import React from 'react';

import _ from 'lodash';

import { Formik } from 'formik';

import { Button, Form, Segment, Header } from 'semantic-ui-react';

import {
  Field,
  RichTextAreaField,
  InputField,
  FormRow,
  UnsavedFormPrompt
} from 'components';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess, notifyErrors } from 'utils/notifications';

import { courseAddBlockSchema } from './schema';

import { courseAddBlock, courseUpdateBlock, courseRemoveBlock } from './sdk';

import styles from './styles.module.css';

class BlockForm extends React.Component {
  handleSubmit = (values, actions) => {
    const { block } = this.props;
    if (_.isNil(block)) {
      this.createBlock(values, actions);
    } else {
      this.updateBlock(values, actions);
    }
  };

  removeBlock = async () => {
    const { block, course, fetchCourse } = this.props;
    const data = { courseBlock: block.id };

    const { success, errors } = await courseRemoveBlock(
      course.id,
      prepareFormData(data)
    );

    if (success) {
      notifySuccess(`Block for ${block.title} removed.`);
      fetchCourse();
    }

    if (!success) {
      notifyErrors(errors);
    }
  };

  updateBlock = async (values, actions) => {
    const { course, fetchCourse, block } = this.props;
    const { setSubmitting, setFieldError } = actions;

    const data = { ...values, courseBlock: block.id };

    setSubmitting(true);

    const { success, errors } = await courseUpdateBlock(
      course.id,
      prepareFormData(data)
    );

    setSubmitting(false);

    if (success) {
      notifySuccess(`Block for ${values.title} updated.`);
      fetchCourse();
    }

    if (!success) {
      handleFormErrors(errors, setFieldError);
    }
  };

  createBlock = async (values, actions) => {
    const { course, fetchCourse } = this.props;
    const { setSubmitting, setFieldError, resetForm } = actions;

    setSubmitting(true);

    const { success, errors } = await courseAddBlock(
      course.id,
      prepareFormData(values)
    );

    setSubmitting(false);

    if (success) {
      notifySuccess(`Block for ${values.title} added.`);
      resetForm();
      fetchCourse();
    }

    if (!success) {
      handleFormErrors(errors, setFieldError);
    }
  };

  getCreateInitialValues = () => {
    return {
      title: '',
      order: 0,
      textHtml: ''
    };
  };

  getUpdateInitialValues = block => {
    return {
      title: block.title,
      order: block.order,
      textHtml: block.text_html
    };
  };

  render() {
    const { block } = this.props;

    const hasBlock = !_.isNil(block);

    let initialValues = {};

    if (!hasBlock) {
      initialValues = this.getCreateInitialValues();
    } else {
      initialValues = this.getUpdateInitialValues(block);
    }

    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        validationSchema={courseAddBlockSchema}
        enableReinitialize={true}>
        {({ values, handleSubmit, isSubmitting, dirty }) => (
          <>
            <Header attached="top" as="h3" block>
              {hasBlock ? block.title : 'Create course block'}
            </Header>
            <Segment attached>
              <Form>
                <UnsavedFormPrompt when={dirty} formName="Block form" />

                <FormRow>
                  <Field
                    width={6}
                    required
                    name="title"
                    label="Title"
                    component={InputField}
                  />
                  <Field
                    width={3}
                    required
                    name="order"
                    label="Order"
                    component={InputField}
                  />
                </FormRow>
                <FormRow>
                  <Field
                    name="textHtml"
                    label="Text"
                    component={RichTextAreaField}
                  />
                </FormRow>
                <FormRow className={styles.Buttons}>
                  {!hasBlock && (
                    <Button
                      onClick={handleSubmit}
                      type="submit"
                      color="blue"
                      disabled={isSubmitting}>
                      Add
                    </Button>
                  )}

                  {hasBlock && (
                    <div className={styles.UpdateButtons}>
                      <Button
                        onClick={handleSubmit}
                        type="submit"
                        color="blue"
                        disabled={isSubmitting}>
                        Update
                      </Button>
                      <Button
                        basic
                        color="grey"
                        onClick={this.removeBlock}
                        type="submit"
                        disabled={isSubmitting}>
                        Remove
                      </Button>
                    </div>
                  )}
                </FormRow>
              </Form>
            </Segment>
          </>
        )}
      </Formik>
    );
  }
}

export default BlockForm;
