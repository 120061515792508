import { requestSdk, post, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const generateContent = async data => {
  const url = `${BASE_URL}/editorials/instant_news/get-article-content/`;

  return await requestSdk(() => post(url, data));
};

export const getInstantNew = async id => {
  const url = `${BASE_URL}/editorials/instant_news/${id}/`;

  return await requestSdk(() => get(url));
};
