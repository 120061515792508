import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const currencyRateList = async params => {
  const url = `${BASE_URL}/editorials/currency-rates/`;

  return await requestSdk(() => get(url, params));
};

export const priceSeriesCurrencyListForCurrencyRate = async () => {
  const url = `${BASE_URL}/prices/series/price-series-currencies/?is_enabled=True&order=id`;

  return await requestSdk(() => get(url));
};
