export const FILTERS = [
  {
    name: 'start_date',
    emptyValue: null
  },
  {
    name: 'end_date',
    emptyValue: null
  },
  {
    name: 'subscription',
    emptyValue: null,
    transform: value => parseInt(value, 10)
  },
  {
    name: 'subscription_status',
    emptyValue: null,
    transform: value => parseInt(value, 10)
  },
  {
    name: 'account_manager',
    emptyValue: null,
    transform: value => parseInt(value, 10)
  },
  {
    name: 'past_invoice_currency',
    emptyValue: null,
    transform: value => parseInt(value, 10)
  },
  {
    name: 'convert_to',
    emptyValue: null,
    transform: value => parseInt(value, 10)
  },
  {
    name: 'region',
    emptyValue: null,
    transform: value => parseInt(value, 10)
  },
  {
    name: 'country',
    emptyValue: null,
    transform: value => parseInt(value, 10)
  },
  {
    name: 'renewal_state',
    emptyValue: null
  },
  {
    name: 'renewal_month',
    emptyValue: null,
    transform: value => parseInt(value, 10)
  },
  {
    name: 'renewal_year',
    emptyValue: new Date().getFullYear()
  },
  {
    name: 'company_name',
    emptyValue: ''
  }
];
