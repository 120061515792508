import React from 'react';

import { Field, SelectField } from 'components';

const SCALES = ['1', '100', '1000'];

class PriceSeriesScaleSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = SCALES.map(scale => {
      return {
        key: scale,
        value: scale,
        label: scale
      };
    });

    return (
      <Field
        name={name || 'priceSeriesScale'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default PriceSeriesScaleSelect;
