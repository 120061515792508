import _ from 'lodash';
import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const trackingEventsList = async (eventType, params) => {
  const url = `${BASE_URL}/forms-dashboard/tracking-events/${eventType}/`;

  return await requestSdk(() => get(url, params));
};

export const feedbackList = async params => {
  const url = `${BASE_URL}/forms-dashboard/tracking-events/feedback/`;

  return await requestSdk(() => get(url, params));
};

export const commentsList = async params => {
  if (_.has(params, 'article_id')) {
    params['content_type_id'] = 65;
    params['related_obj_id'] = params['article_id'];
  } else if (_.has(params, 'power_material_article_id')) {
    params['content_type_id'] = 197;
    params['related_obj_id'] = params['power_material_article_id'];
  }

  const url = `${BASE_URL}/forms-dashboard/tracking-events/comments/`;

  return await requestSdk(() => get(url, params));
};
