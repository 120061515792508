import React from 'react';

import {
  Flex,
  Pagination,
  ListViewEssentials,
  Filters as SharedFilters
} from 'components';

import { EmailTemplateTable } from './components';
import { emailTemplatePresetOptionsList } from './sdk';
import { FILTERS_LAYOUT } from './utils';

const EmailTemplateSelectModal = ({
  onNextClick,
  data,
  count,
  page,
  limit,
  filters,
  sort,
  filterBy,
  orderBy,
  clear,
  changePage,
  changeRowsPerPage,
  refetch
}) => {
  return (
    <Flex spaceBetween style={{ flexDirection: 'column', gap: '10px' }}>
      <div style={{ width: '100%' }}>
        <SharedFilters
          filters={filters}
          clearFilters={clear}
          filterBy={filterBy}
          count={count}
          layout={FILTERS_LAYOUT}
        />
      </div>
      {data && (
        <EmailTemplateTable
          onNextClick={onNextClick}
          data={data}
          sort={sort}
          orderBy={orderBy}
          refetch={refetch}
          pagination={
            <Pagination
              limit={limit}
              count={count}
              page={page}
              changePage={changePage}
              changeRowsPerPage={changeRowsPerPage}
            />
          }
        />
      )}
    </Flex>
  );
};

export default ListViewEssentials({
  sdk: emailTemplatePresetOptionsList,
  sort: { field: 'created_at', direction: 'desc' },
  urlUpdatesEnabled: false
})(EmailTemplateSelectModal);
