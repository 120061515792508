import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { GlossaryCategoryDetail } from 'pages';

import { YesNo } from 'components';

import { formatDateTime } from 'utils';

class GlossaryCategoriesTable extends React.Component {
  render() {
    const { categories } = this.props;

    return (
      <Table celled selectable compact={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Is Active</Table.HeaderCell>
            <Table.HeaderCell>Created by</Table.HeaderCell>
            <Table.HeaderCell>Create time</Table.HeaderCell>
            <Table.HeaderCell>Updated by</Table.HeaderCell>
            <Table.HeaderCell>Updated time</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {categories.map(category => (
            <Table.Row key={category.id}>
              <Table.Cell width={5}>
                <Link
                  to={pageUrl(GlossaryCategoryDetail, {
                    glossaryCategoryId: category.id
                  })}>
                  {category.title}
                </Link>
              </Table.Cell>
              <Table.Cell>
                <YesNo value={category.is_active} />
              </Table.Cell>
              <Table.Cell>
                {_.get(category, 'created_by.first_name')}{' '}
                {_.get(category, 'created_by.last_name')}
              </Table.Cell>
              <Table.Cell>{formatDateTime(category.created_at)}</Table.Cell>
              <Table.Cell>
                {_.get(category, 'updated_by.first_name')}
              </Table.Cell>
              <Table.Cell>{formatDateTime(category.updated_at)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}

export default GlossaryCategoriesTable;
