import React from 'react';

import { Field, SelectField } from 'components';

const OPTIONS = ['Start date', 'End date'];

class EmailTriggerSendWhenSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = OPTIONS.map(option => {
      return {
        value: option,
        label: option,
        key: option
      };
    });

    return (
      <Field
        name={name || 'emailTriggerSendWhen'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default EmailTriggerSendWhenSelect;
