import React from 'react';

import { withRouter } from 'react-router-dom';

import _ from 'lodash';

import { Form, Divider } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  InputField,
  TextAreaField,
  CheckboxField,
  TitleOptionSelect,
  CountrySelect,
  EmailTypeSelect,
  BusinessActivitySelect,
  PrioritySelect,
  RegistrationTypeSelect,
  CommoditySelect,
  IndustrySelect,
  SubIndustrySelect,
  FormRow,
  ArticleGroupSelect,
  SteelGroupSelect,
  EnergyGroupSelect,
  AdminSelect,
  UnsavedFormPrompt,
  ErrorsAndUpdateButtonInRow,
  UniSelect,
  Button,
  ClientTagsSelectField
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess, notifyErrors } from 'utils/notifications';

import { clientUpdateSchema } from './schema';
import {
  clientUpdate,
  clientAdminCommentsArchiveGet,
  clientAdminCommentsArchiveUpdate
} from './sdk';
import { AdminCommentsArchive } from './components';

import styles from './styles.module.css';

const US_TITLE = 'United States';
const AUSTRALIA_TITLE = 'Australia';

class ClientUpdateForm extends React.Component {
  state = {
    region: '',
    country: '',
    commentsArchive: ''
  };

  updateRegion = selectFieldData => {
    if (_.isNil(selectFieldData)) {
      this.setState({ region: '' });
      this.setState({ country: '' });
      return;
    }
    this.setState({ country: selectFieldData.target.label });

    const {
      target: {
        meta: { region }
      }
    } = selectFieldData;

    this.setState({ region });
  };

  clearCommentsArchive = () => {
    this.setState({ commentsArchive: '' });
  };

  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const { client, fetchClient } = this.props;
    const data = { ...prepareFormData(values) };

    setSubmitting(true);

    const { errors, success } = await clientUpdate(client.id, data);

    setSubmitting(false);

    if (success) {
      notifySuccess('Client updated.');
      fetchClient();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  componentDidMount() {
    const { client } = this.props;

    if (!_.isNil(client.client_profile.region)) {
      this.setState({
        region: client.client_profile.region.title,
        country: _.get(client, 'client_profile.country.title', '')
      });
    }
    this.getAdminCommentsArchive();
  }

  getAdminCommentsArchive() {
    const { client } = this.props;
    if (!_.isNil(client)) {
      clientAdminCommentsArchiveGet(client.id).then(
        ({ data, errors, success }) => {
          if (success) {
            this.setState({ commentsArchive: data.admin_comments_archive });
          } else {
            notifyErrors(errors);
            this.setState({ commentsArchive: '' });
          }
        }
      );
    }
  }

  saveAdminCommentsArchive(adminComments, setFieldValue) {
    const { client } = this.props;

    if (client) {
      clientAdminCommentsArchiveUpdate(this.props.client.id, {
        text: adminComments
      }).then(({ data, errors, success }) => {
        if (success) {
          notifySuccess('Admin Comments Archive updated.');
          this.setState({ commentsArchive: data.admin_comments_archive });
          setFieldValue('adminComments', '');
        } else {
          notifyErrors(errors);
        }
      });
    }
  }

  getInitialValues = () => {
    const initialValues = {
      username: '',
      email: '',
      autoEmails: true,
      unsubscribed: false,
      adminComments: '',
      firstName: '',
      lastName: '',
      jobTitle: '',
      companyName: '',
      address_1: '',
      address_2: '',
      city: '',
      postcode: '',
      stateOrProvince: '',
      website: '',
      phone: '',
      fax: '',
      mobile: '',
      linkedinProfile: '',
      personalStatement: '',
      priority: null,
      title: null,
      country: null,
      state: null,
      emailType: null,
      registrationType: null,
      businessActivity: null,
      commodity: null,
      industry: null,
      isActive: true,
      interestedInEvents: false,
      interestedInNews: false,
      interestedInReports: false,
      group: null,
      energyGroup: null,
      groupArticlesBy: null,
      groupEnergyArticlesBy: null,
      accountManager: null,
      receiveSteelNewsletterInPdf: false,
      tags: []
    };

    return initialValues;
  };

  getInitialValuesWithClient = client => {
    const profile = client.client_profile;

    const initialValues = {
      username: client.username,
      email: client.email,
      adminComments: profile.admin_comments,
      autoEmails: !profile.no_auto_emails,
      unsubscribed: profile.unsubscribed,
      firstName: client.first_name,
      lastName: client.last_name,
      jobTitle: profile.job_title,
      companyName: profile.company_name,
      address_1: profile.address_1,
      address_2: profile.address_2,
      city: profile.city,
      postcode: profile.postcode,
      stateOrProvince: profile.state_or_province,
      website: profile.website,
      phone: profile.phone,
      fax: profile.fax,
      mobile: profile.mobile,
      linkedinProfile: profile.linkedin_profile,
      personalStatement: profile.personal_statement || '',
      priority: profile.priority,
      title: (profile.title && profile.title.id) || null,
      country: (profile.country && profile.country.id) || null,
      state: (profile.state && profile.state.id) || null,
      emailType: profile.email_type,
      registrationType: profile.registration_type,
      businessActivity:
        (profile.business_activity && profile.business_activity.id) || null,
      commodity: profile.commodity,
      industry: (profile.industry && profile.industry.id) || null,
      subIndustry: (profile.sub_industry && profile.sub_industry.id) || null,
      isActive: client.is_active,
      interestedInEvents: profile.interested_in_events,
      interestedInNews: profile.interested_in_news,
      interestedInReports: profile.interested_in_reports,
      group: profile.group,
      energyGroup: profile.energy_group,
      groupArticlesBy: profile.group_articles_by,
      groupEnergyArticlesBy: profile.group_energy_articles_by,
      accountManager: profile.account_manager,
      receiveSteelNewsletterInPdf: profile.receive_steel_newsletter_in_pdf,
      powerMaterialsUnsubscribed: profile.power_materials_unsubscribed,
      tags: profile.tags
    };

    return initialValues;
  };

  render() {
    const { client, user } = this.props;
    let { region, commentsArchive } = this.state;

    let initialValues = null;

    if (client) {
      initialValues = this.getInitialValuesWithClient(client);
    } else {
      initialValues = this.getInitialValues();
    }

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={clientUpdateSchema}
        onSubmit={this.handleSubmit}>
        {({
          values,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          dirty,
          errors,
          touched
        }) => (
          <Form>
            <UnsavedFormPrompt
              when={dirty}
              formName="Client information form"
            />

            <ErrorsAndUpdateButtonInRow
              errors={errors}
              touched={touched}
              subject="User"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />

            <FormRow widths="equal">
              <Field
                required
                name="username"
                placeholder="Username ..."
                component={InputField}
                label="Username"
              />

              <Field
                required
                name="email"
                placeholder="Email ..."
                component={InputField}
                label="Email"
              />

              <PrioritySelect required name="priority" label="Priority" />
            </FormRow>

            <Field
              name="adminComments"
              component={TextAreaField}
              label={
                <div
                  className={styles.adminCommentsLabel}
                  onClick={e => {
                    if (e.target.localName !== 'button') {
                      e.stopPropagation();
                      e.preventDefault();
                    }
                  }}>
                  <h4>Admin comments</h4>
                  {user.is_superuser && (
                    <Button
                      color="green"
                      onClick={() =>
                        this.saveAdminCommentsArchive(
                          values.adminComments,
                          setFieldValue
                        )
                      }>
                      Save To Archive
                    </Button>
                  )}
                </div>
              }
            />

            {!_.isNil(client) && user.is_superuser && (
              <AdminCommentsArchive
                archive={commentsArchive}
                clientId={client.id}
                clearArchive={this.clearCommentsArchive}
              />
            )}

            <FormRow>
              <TitleOptionSelect width={4} name="title" label="Title" />

              <Field
                width={6}
                name="firstName"
                component={InputField}
                label="First name"
              />

              <Field
                width={6}
                name="lastName"
                component={InputField}
                label="Last name"
              />
            </FormRow>

            <FormRow>
              <Field
                width={6}
                name="jobTitle"
                component={InputField}
                label="Job title"
              />

              <Field
                width={6}
                name="companyName"
                component={InputField}
                label="Company name"
              />
            </FormRow>
            <Divider />
            <FormRow>
              <Field
                width={8}
                name="address_1"
                component={InputField}
                label="Address 1"
              />

              <Field
                width={8}
                name="address_2"
                component={InputField}
                label="Address 2"
              />
            </FormRow>
            <FormRow>
              <Field
                width={6}
                name="city"
                component={InputField}
                label="City (town)"
              />

              <Field
                width={4}
                name="postcode"
                component={InputField}
                label="Postcode"
              />
              <Field
                width={6}
                name="stateOrProvince"
                component={InputField}
                label="State or province"
              />
            </FormRow>

            <FormRow>
              <CountrySelect
                width={6}
                name="country"
                label="Country"
                onChange={this.updateRegion}
              />
              {(this.state.country === US_TITLE ||
                this.state.country === AUSTRALIA_TITLE) && (
                <UniSelect
                  key={`state-select-${this.state.country}`}
                  width={4}
                  name="state"
                  label="State"
                  source="salesTerritoryStates"
                  params={{ country: this.state.country }}
                />
              )}
              <Field
                width={6}
                name="region"
                component={InputField}
                label="Region"
                disabled
                value={region}
              />
            </FormRow>

            <Divider className={styles.divider} />
            <FormRow>
              <Field
                width={10}
                name="website"
                component={InputField}
                label="Website"
              />

              <Field
                width={6}
                name="phone"
                component={InputField}
                label="Phone"
              />
            </FormRow>
            <FormRow>
              <Field width={5} name="fax" component={InputField} label="Fax" />

              <Field
                width={5}
                name="mobile"
                component={InputField}
                label="Mobile"
              />

              <Field
                width={6}
                name="linkedinProfile"
                component={InputField}
                label="LinkedIn Profile"
              />
            </FormRow>

            <FormRow alignEnd>
              <EmailTypeSelect
                width={4}
                name="emailType"
                label="Email type"
                isClearable={false}
              />
              <Field
                width={4}
                component={CheckboxField}
                name="receiveSteelNewsletterInPdf"
                label="Receive steel newsletter in PDF"
              />

              <RegistrationTypeSelect
                width={4}
                name="registrationType"
                label="Registration Type"
              />

              <AdminSelect
                width={6}
                name="accountManager"
                label="Account Manager"
              />
            </FormRow>

            <FormRow>
              <Field
                name="unsubscribed"
                component={CheckboxField}
                label="Unsubscribed"
              />
              <Field
                name="powerMaterialsUnsubscribed"
                component={CheckboxField}
                label="Power Materials Unsubscribed"
              />

              <Field
                name="autoEmails"
                component={CheckboxField}
                label="Auto emails"
              />

              <Field
                name="isActive"
                component={CheckboxField}
                label="Is active"
              />
            </FormRow>

            <FormRow>
              <Field
                name="interestedInEvents"
                component={CheckboxField}
                label="Interested in Events"
              />

              <Field
                name="interestedInNews"
                component={CheckboxField}
                label="Interested in News"
              />

              <Field
                name="interestedInReports"
                component={CheckboxField}
                label="Interested in Reports"
              />
            </FormRow>

            <FormRow>
              <BusinessActivitySelect
                width={6}
                name="businessActivity"
                label="Business Activity"
              />

              <CommoditySelect
                required
                width={6}
                name="commodity"
                label="Commodity"
              />
            </FormRow>
            <FormRow>
              <IndustrySelect
                required
                width={6}
                name="industry"
                label="Industry"
                onChange={e => setFieldValue('subIndustry', null)}
              />

              <SubIndustrySelect
                width={6}
                name="subIndustry"
                label="Sub-Industry"
                disabled={_.isNil(values.industry)}
                industry={values.industry}
              />
            </FormRow>

            <FormRow>
              <ArticleGroupSelect
                name="groupArticlesBy"
                label="Group Steel Articles by"
              />

              <SteelGroupSelect name="group" label="Steel Product Groups" />

              <ArticleGroupSelect
                name="groupEnergyArticlesBy"
                label="Group Energy Articles by"
              />

              <EnergyGroupSelect
                name="energyGroup"
                label="Energy Product Groups"
              />
            </FormRow>

            <Field
              name="personalStatement"
              component={TextAreaField}
              label="Personal statement"
            />

            <div data-testid="client-detail-tags-field">
              <Field
                width={8}
                component={ClientTagsSelectField}
                name="tags"
                label="Tags"
              />
            </div>

            <ErrorsAndUpdateButtonInRow
              errors={errors}
              touched={touched}
              subject="User"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(ClientUpdateForm);
