import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const articleUpdate = async (productSlug, articleId, data) => {
  const url = `${BASE_URL}/articles/${productSlug}/${articleId}/update/`;

  return await requestSdk(() => post(url, data));
};

export const articleDelete = async (productSlug, articleId) => {
  const url = `${BASE_URL}/articles/${productSlug}/${articleId}/delete/`;

  return await requestSdk(() => post(url));
};
