import React from 'react';

import _ from 'lodash';
import cx from 'classnames';

import { CKEditor } from 'ckeditor4-react';

import { Error } from 'components';

import styles from './styles.module.css';

const BaseRichTextAreaField = ({
  label,
  containerClassName,
  form,
  field,
  onChange = () => {},
  withoutErrorMessage = false,
  withoutLabel = false,
  disabled = false,
  config = {},
  ...props
}) => {
  const hasErrors =
    _.get(form.touched, field.name) && _.get(form.errors, field.name);

  config.allowedContent = {
    $1: {
      // Use the ability to specify elements as an object.
      attributes: true,
      styles: true,
      classes: true
    }
  };

  return (
    <div
      className={cx(
        styles.container,
        { [styles.disabled]: disabled },
        containerClassName
      )}>
      {!withoutLabel && <label>{label}</label>}
      <CKEditor
        initData={field.value}
        editorUrl={process.env.REACT_APP_CKEDITOR_URL}
        onChange={changeEvent => {
          const data = changeEvent.editor.getData();
          const diff = data.split(field.value).join('');

          if (diff === '\n' && data.slice(-1) === '\n') return;

          field.onChange(field.name)(changeEvent.editor.getData());
        }}
        onMode={event => {
          const editor = event.editor;

          const editable = editor.editable();

          const eventHandler = e => {
            field.onChange(field.name)(e.data.$.target.value);
          };

          if (editor.mode === 'source') {
            editable.attachListener(editable, 'input', eventHandler);
          }
        }}
        onBeforeLoad={CKEDITOR => {
          CKEDITOR.disableAutoInline = true;
        }}
        onBlur={field.onBlur(field.name)}
        config={config}
        {...props}
      />
      {!withoutErrorMessage && hasErrors && (
        <Error className={styles.error}>{_.get(form.errors, field.name)}</Error>
      )}
    </div>
  );
};

export default BaseRichTextAreaField;
