import React from 'react';
import _ from 'lodash';

import { Field, FastField, SelectField } from 'components';

import { articleTypeList } from './sdk';

class ArticleTypeSelect extends React.Component {
  state = {
    articleTypes: []
  };
  async componentDidMount() {
    const { data, success } = await articleTypeList();

    if (success) {
      this.setState({
        articleTypes: data.map(articleType => {
          return {
            key: articleType.id,
            value: articleType.id,
            label: articleType.title
          };
        })
      });
    }
  }

  render() {
    const { name, fast = false, ...rest } = this.props;
    const { articleTypes } = this.state;

    const options = articleTypes;

    let Component = Field;
    if (fast) {
      Component = FastField;
    }

    if (!_.isEmpty(options)) {
      rest['options'] = options;
    }

    return (
      <Component
        name={name || 'articleType'}
        component={SelectField}
        isClearable
        {...rest}
      />
    );
  }
}

export default ArticleTypeSelect;
