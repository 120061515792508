import React from 'react';

import {
  Page,
  Flex,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  ClientFilters,
  Button,
  SelectField,
  DateField,
  YesNoSelect
} from 'components';

import { pageUrl } from 'config/routes';

import { EmailSuppressedList } from 'pages';

import {
  PermissionsRequired,
  PERMISSIONS,
  DisableableElement,
  hasPermission
} from 'permissions';

import { coerceStringToBoolean } from 'utils';

import { clientSuppressedList } from './sdk';

import { ClientTable } from './components';

const FILTERS = [
  {
    name: 'reason',
    label: 'Suppression Reason',
    emptyValue: null,
    component: SelectField,
    rest: {
      isClearable: true,
      options: [
        {
          key: 'BOUNCE',
          value: 'BOUNCE',
          label: 'Bounce'
        },
        {
          key: 'COMPLAINT',
          value: 'COMPLAINT',
          label: 'Complaint'
        }
      ]
    }
  },
  {
    name: 'batch_date',
    label: 'Show suppressed list batch for',
    emptyValue: null,
    component: DateField
  },
  {
    name: 'is_new',
    label: 'Is New',
    component: YesNoSelect,
    rest: {
      transform: coerceStringToBoolean,
      width: 4
    }
  }
];

class ClientSuppressedList extends React.Component {
  render() {
    const {
      user,
      data,
      count,
      page,
      limit,
      filters,
      sort,
      filterBy,
      orderBy,
      clear,
      changePage,
      changeRowsPerPage,
      isFetching,
      crumbs,
      history,
      refetch
    } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content maxWidth crumbs={crumbs}>
            <Flex spaceBetween>
              <Header>Suppressed Clients</Header>
              <Button
                size="large"
                onClick={() => history.push(pageUrl(EmailSuppressedList))}>
                View Clientless Emails
              </Button>
            </Flex>
            <DisableableElement
              disabled={!hasPermission(user, PERMISSIONS.SEARCH_CLIENTS)}>
              <ClientFilters
                filters={filters}
                count={count}
                filterBy={filterBy}
                clearFilters={clear}
                extraFilters={FILTERS}
              />
            </DisableableElement>
            <ClientTable
              isFetching={isFetching}
              clients={data}
              sort={sort}
              orderBy={orderBy}
              user={user}
              refetch={refetch}
              pagination={
                <Pagination
                  limit={limit}
                  count={count}
                  page={page}
                  changePage={changePage}
                  changeRowsPerPage={changeRowsPerPage}
                />
              }
            />
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: clientSuppressedList
})(
  LoginRequired(
    PermissionsRequired([PERMISSIONS.VIEW_CLIENTS])(ClientSuppressedList)
  )
);
