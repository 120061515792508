import React from 'react';

import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Header, Segment } from 'semantic-ui-react';

import { Page, LoginRequired, DeleteButton, Flex } from 'components';

import {
  PermissionsRequired,
  PERMISSIONS,
  DisableableElement,
  hasPermission,
  VisibleIfSuperuser
} from 'permissions';

import { pageUrl } from 'config/routes';

import { PriceSpreadList } from 'pages';

import { notifySuccess, notifyErrors } from 'utils/notifications';

import { priceSpreadDetail, priceSpreadDelete } from './sdk';
import PriceSpreadUpdateForm from './form';

class PriceSpreadDetail extends React.Component {
  state = {
    errors: null,
    priceSpread: null
  };

  fetchPriceSpread = async () => {
    const { priceSpreadId } = this.props.match.params;

    const { data, errors, success } = await priceSpreadDetail(priceSpreadId);

    if (success) {
      this.setState({ priceSpread: data });
    } else {
      this.setState({ errors });
    }
  };

  componentDidMount() {
    this.fetchPriceSpread();
  }

  deletePriceSpread = async () => {
    const { priceSpreadId } = this.props.match.params;

    const { errors, success } = await priceSpreadDelete(priceSpreadId);

    if (success) {
      notifySuccess('Price spread deleted.');
      this.props.history.push(pageUrl(PriceSpreadList));
    } else {
      notifyErrors(errors);
    }
  };

  render() {
    const { user, crumbs } = this.props;
    const { priceSpread } = this.state;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Flex spaceBetween>
                <Header as="h1">Price Spread Detail</Header>
                <VisibleIfSuperuser user={user}>
                  <DeleteButton onClick={this.deletePriceSpread}>
                    Delete
                  </DeleteButton>
                </VisibleIfSuperuser>
              </Flex>
              {!_.isNil(priceSpread) && (
                <DisableableElement
                  disabled={!hasPermission(user, PERMISSIONS.CHANGE_PRICES)}>
                  <PriceSpreadUpdateForm
                    data={priceSpread}
                    fetchPriceSpread={this.fetchPriceSpread}
                  />
                </DisableableElement>
              )}
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(
  withRouter(PermissionsRequired([PERMISSIONS.VIEW_PRICES])(PriceSpreadDetail))
);
