import React from 'react';

import { Table } from 'semantic-ui-react';

import _ from 'lodash';

const MailshotLinks = ({ links }) => {
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Variable</Table.HeaderCell>
          <Table.HeaderCell>Link</Table.HeaderCell>
          <Table.HeaderCell>Total Clicks</Table.HeaderCell>
          <Table.HeaderCell>Unique Clicks</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {_.orderBy(links, 'id').map(link => (
          <Table.Row key={link.id}>
            <Table.Cell>{link.variable}</Table.Cell>
            <Table.Cell>{link.link}</Table.Cell>
            <Table.Cell>{link.total_clicks}</Table.Cell>
            <Table.Cell>{link.unique_clicks}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default MailshotLinks;
