import React from 'react';

import { Table, Button } from 'semantic-ui-react';

import {
  Flex,
  TableFooter,
  SortableHeader,
  AddToFavouritesButton
} from 'components';

import { formatDateTime } from 'utils';
import styles from './styles.module.css';

import {
  emailTemplateAddToFavourites,
  emailTemplateRemoveFromFavourites
} from '../../sdk';

const TableWrapper = ({ children }) => (
  <div className={styles.tableContainer}>{children}</div>
);

const EmailTemplateTable = ({
  onNextClick,
  data,
  sort,
  orderBy,
  pagination
}) => (
  <TableWrapper>
    <Table celled selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            singleLine
            style={{ fontWeight: '400' }}
            colSpan="8">
            {pagination}
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row className={styles.tableHeaderRow}>
          <SortableHeader sort={sort} orderBy={orderBy} field="title">
            Title
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="subject">
            Subject
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="from_name">
            From Name
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="created_by">
            Created By
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="created_at">
            Created At
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="updated_by">
            Updated By
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="updated_at">
            Updated At
          </SortableHeader>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {data.map(emailTemplate => (
          <Table.Row key={emailTemplate.id}>
            <Table.Cell>
              <Flex flexStart style={{ gap: '5px' }}>
                <AddToFavouritesButton
                  entityName="email template"
                  initialIsFavouredState={emailTemplate.is_favoured}
                  addToFavouritesHandler={() =>
                    emailTemplateAddToFavourites(emailTemplate.id)
                  }
                  removeFromFavouritesHandler={() =>
                    emailTemplateRemoveFromFavourites(emailTemplate.id)
                  }
                />{' '}
                {emailTemplate.title}
              </Flex>
            </Table.Cell>
            <Table.Cell>{emailTemplate.subject}</Table.Cell>
            <Table.Cell>{emailTemplate.from_name}</Table.Cell>
            <Table.Cell>{emailTemplate.created_by?.name}</Table.Cell>
            <Table.Cell className={styles.dateCell}>
              {formatDateTime(emailTemplate.created_at)}
            </Table.Cell>
            <Table.Cell>{emailTemplate.updated_by?.name}</Table.Cell>
            <Table.Cell className={styles.dateCell}>
              {formatDateTime(emailTemplate.updated_at)}
            </Table.Cell>
            <Table.Cell>
              <Button
                color="blue"
                onClick={() => onNextClick(emailTemplate.id)}>
                Next
              </Button>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <TableFooter>{pagination}</TableFooter>
    </Table>
  </TableWrapper>
);

export default EmailTemplateTable;
