import React from 'react';
import { withRouter, useHistory } from 'react-router-dom';

import {
  Page,
  Flex,
  Header,
  Button,
  LoginRequired,
  ListViewEssentials,
  Filters as SharedFilters
} from 'components';
import { pageUrl } from 'config/routes';
import { StaffCreate } from 'pages';
import { PermissionsRequired, PERMISSIONS, VisibleIf } from 'permissions';

import { staffList } from './sdk';

import { StaffTable } from './components';

import { FILTERS as LAYOUT_FILTERS } from './utils';

const StaffList = ({ data, user, sort, orderBy, filters, filterBy, clear }) => {
  const history = useHistory();
  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content>
          <Flex spaceBetween>
            <Header>Staff Members</Header>
            <VisibleIf user={user} permissions={[PERMISSIONS.CREATE_STAFF]}>
              <div>
                <Button
                  size="large"
                  onClick={() => history.push(pageUrl(StaffCreate))}>
                  Add Staff Member
                </Button>
              </div>
            </VisibleIf>
          </Flex>
          <SharedFilters
            filters={filters}
            filterBy={filterBy}
            clearFilters={clear}
            layout={LAYOUT_FILTERS}
          />
          {data && (
            <StaffTable staffMembers={data} sort={sort} orderBy={orderBy} />
          )}
        </Page.Content>
      </Page.Body>
    </Page>
  );
};

export default ListViewEssentials({
  sdk: staffList,
  limit: null
})(
  LoginRequired(
    PermissionsRequired([PERMISSIONS.VIEW_STAFF])(withRouter(StaffList))
  )
);
