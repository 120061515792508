import React from 'react';

import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { ClientReminderDetail } from 'pages';

import { Table } from 'semantic-ui-react';

import { YesNo, TableFooter, SortableHeader } from 'components';

import { formatDate } from 'utils';

const formatClientReminderTime = time => {
  const mapper = {
    0: '00:00',
    1: '01:00',
    2: '02:00',
    3: '03:00',
    4: '04:00',
    5: '05:00',
    6: '06:00',
    7: '07:00',
    8: '08:00',
    9: '09:00',
    10: '10:00',
    11: '11:00',
    12: '12:00',
    13: '13:00',
    14: '14:00',
    15: '15:00',
    16: '16:00',
    17: '17:00',
    18: '18:00',
    19: '19:00',
    20: '20:00',
    21: '21:00',
    22: '22:00',
    23: '23:00'
  };

  return mapper[time];
};

const ClientReminderTable = ({
  clientReminders,
  orderBy,
  sort,
  history,
  pagination
}) => (
  <Table celled selectable compact={true}>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell singleLine style={{ fontWeight: '400' }} colspan="6">
          {pagination}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <SortableHeader sort={sort} orderBy={orderBy} field="sent_date">
          Date and Time
        </SortableHeader>
        <Table.HeaderCell>Description</Table.HeaderCell>
        <SortableHeader sort={sort} orderBy={orderBy} field="created_by">
          Created by
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="is_done">
          Is Done
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="client">
          Client
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="company">
          Company
        </SortableHeader>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {clientReminders.map(clientReminder => (
        <Table.Row key={clientReminder.id}>
          <Table.Cell width={2} textAlign="center">
            <Link
              to={pageUrl(ClientReminderDetail, {
                clientReminderId: clientReminder.id
              })}>
              {formatDate(clientReminder.sent_date)} at{' '}
              {formatClientReminderTime(clientReminder.time)}
            </Link>
          </Table.Cell>
          <Table.Cell width={5}>
            <p>{clientReminder.description}</p>
          </Table.Cell>
          <Table.Cell width={2}>
            {_.get(clientReminder.created_by, 'email', 'N/A')}
          </Table.Cell>
          <Table.Cell width={1}>
            <YesNo value={clientReminder.is_done} />
          </Table.Cell>
          <Table.Cell width={2}>
            {clientReminder.client
              ? `${clientReminder.client.first_name} ${clientReminder.client.last_name}`
              : 'N/A'}
          </Table.Cell>
          <Table.Cell width={3}>
            {clientReminder.client && clientReminder.client.profile
              ? clientReminder.client.profile.company_name
              : 'N/A'}
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default withRouter(ClientReminderTable);
