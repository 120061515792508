import * as yup from 'yup';

export const clientReminderUpdateSchema = yup.object().shape({
  sentDate: yup.string().required('Sent Date is required'),
  time: yup
    .number()
    .nullable()
    .required('Time is required.'),
  reminder: yup
    .number()
    .nullable()
    .required('Reminder is required.'),
  description: yup.string().required('Description is required')
});
