import _ from 'lodash';
import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import { pageUrl } from 'config/routes';

import { NewsletterList } from 'pages';

import {
  Field,
  FormRow,
  AdminSelect,
  YesNoSelect,
  DateTimeField,
  CreateButtonInRow,
  UnsavedFormPrompt,
  UniSelect,
  TextAreaField,
  InputField
} from 'components';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { dailyNewsletterCreateSchema } from './schema';
import { newsletterCreate } from './sdk';

import { STEEL_SLUG, POWER_MATERIAL_SLUG } from 'domainConstants';

function NewsletterCreateForm({ productSlug }) {
  const history = useHistory();

  const [upcomingCourse, setUpcomingCourse] = useState(null);

  const handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = {
      ...prepareFormData(values),
      prices_section_slug:
        productSlug === POWER_MATERIAL_SLUG
          ? 'pm-issue-prices'
          : 'issue-prices',
      insert_in_newsletter_sections: [
        'region-articles',
        'product-type-group-articles'
      ]
    };

    setSubmitting(true);

    const { errors, success } = await newsletterCreate(productSlug, data);

    if (success) {
      notifySuccess('Newsletter created.');
      history.push(pageUrl(NewsletterList, { productSlug }));
      return;
    }

    handleFormErrors(errors, setFieldError);
    setSubmitting(false);
  };

  const initialValues = {
    datetime: null,
    editor: null,
    marketer: null,
    priceSeries: [],
    editorApproved: false,
    marketingApproved: false,
    weeklyReportUrl: '',
    weeklySidebarText: '',
    defaultCourse: upcomingCourse
  };

  const productSlugWithHydrogen = `${productSlug},hydrogen`;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={dailyNewsletterCreateSchema}
      enableReinitialize={true}
      onSubmit={handleSubmit}>
      {({ handleSubmit, isSubmitting, dirty }) => (
        <Form>
          <UnsavedFormPrompt when={dirty && !isSubmitting} />
          <FormRow>
            <Field
              width={5}
              required
              name="editor"
              component={AdminSelect}
              label="Editor"
            />

            <Field
              width={3}
              name="editorApproved"
              component={YesNoSelect}
              label="Editor Approved"
              disabled={true}
            />

            <Field
              width={5}
              required
              name="marketer"
              component={AdminSelect}
              label="Marketer"
            />

            <Field
              width={3}
              name="marketingApproved"
              component={YesNoSelect}
              label="Marketer Approved"
              disabled={true}
            />
          </FormRow>

          <FormRow>
            <Field
              required
              width={5}
              name="datetime"
              component={DateTimeField}
              label="Date to go Out"
            />
            {productSlug === STEEL_SLUG && (
              <UniSelect
                width={8}
                name="defaultCourse"
                label="Selected event"
                source="upcomingEvents"
                apiSuccessCallback={upcomingCourses => {
                  if (
                    _.isArray(upcomingCourses) &&
                    !_.isEmpty(upcomingCourses)
                  ) {
                    setUpcomingCourse(upcomingCourses[0].id);
                  }
                }}
                params={{ order: 'date_start' }}
              />
            )}
          </FormRow>
          {productSlug === POWER_MATERIAL_SLUG && (
            <>
              <Field
                width={16}
                name="weeklyReportUrl"
                component={InputField}
                label="Weekly Report URL"
              />
              <Field
                width={16}
                name="weeklySidebarText"
                component={TextAreaField}
                label="Weekly Sidebar Text"
              />
            </>
          )}
          <UniSelect
            name="prices"
            label="Price series"
            isMulti
            closeMenuOnSelect={false}
            transferList={true}
            source="priceSeries"
            productSlug={productSlugWithHydrogen}
          />
          <CreateButtonInRow onClick={handleSubmit} disabled={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
}

export default NewsletterCreateForm;
