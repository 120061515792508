import React from 'react';

import { Field, SelectField } from 'components';
import { priceSeriesCurrencyList } from './sdk';

class PriceSeriesCurrencySelect extends React.Component {
  state = {
    priceSeriesCurrencies: []
  };

  async componentDidMount() {
    const { data, success } = await priceSeriesCurrencyList();

    if (success) {
      this.setState({ priceSeriesCurrencies: data });
    }
  }

  render() {
    const { name, ...rest } = this.props;
    const { priceSeriesCurrencies } = this.state;

    const options = priceSeriesCurrencies.map(currency => {
      return {
        key: currency.id,
        value: currency.id,
        label: currency.code
      };
    });

    return (
      <Field
        name={name || 'priceSeriesCurrency'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default PriceSeriesCurrencySelect;
