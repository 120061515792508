import React from 'react';

import { Header, Segment, Divider } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { LoginRequired, Page } from 'components';
import { PERMISSIONS, PermissionsRequired } from 'permissions';

import { MailshotCreateForm } from './components';

class MailshotCreate extends React.Component {
  render() {
    const { user, crumbs } = this.props;
    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Header as="h1">Create Mailshot</Header>
              <Divider />
              <MailshotCreateForm />
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(
  PermissionsRequired([PERMISSIONS.CREATE_MAILSHOTS])(
    withRouter(MailshotCreate)
  )
);
