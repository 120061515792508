import React from 'react';
import { withRouter } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import _ from 'lodash';

import {
  Page,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Button,
  Flex,
  NoDataMessage,
  Filters as SharedFilters,
  ExternalLinkButton
} from 'components';

import {
  PermissionsRequired,
  PERMISSIONS,
  VisibleIfSuperuser
} from 'permissions';

import { pageUrl } from 'config/routes';

import { RenewalsDashboard } from 'pages';

import { currencyList } from 'sdk/shared';

import { buildFilters } from 'utils/filters';
import { buildSort } from 'utils/sort';

import {
  renewalClientList,
  renewalClientDelete,
  getDownloadRenewalsExportUrl
} from './sdk';
import {
  RenewalClientTable,
  SnapshotCreateForm,
  AdditionalStatistics
} from './components';
import { getCurrency, FILTERS } from './utils';

class RenewalClientList extends React.Component {
  state = {
    currencies: []
  };
  componentWillMount() {
    const { history } = this.props;
    if (_.isEmpty(history.location.search)) {
      const queryParams = '?convert_to=1&order=expiry_date';

      history.replace({ search: queryParams });
      this.props.initialize();
    }
  }
  componentDidMount() {
    this.fetchCurrencies();
  }

  fetchCurrencies = async () => {
    const { data, success } = await currencyList();

    if (success) {
      this.setState({
        currencies: data
      });
    }
  };

  render() {
    const {
      user,
      data,
      response,
      count,
      filters,
      sort,
      filterBy,
      orderBy,
      clear,
      crumbs,
      limit,
      page,
      changePage,
      changeRowsPerPage,
      refetch,
      history
    } = this.props;
    const { currencies } = this.state;

    const convertedCurrency =
      filters.convert_to && getCurrency(currencies, filters.convert_to);

    const existingMonthsYears = _.get(response, 'populated_months', []);

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content maxWidth crumbs={crumbs}>
            <Flex spaceBetween>
              <Header>Renewals Clients</Header>
              <Button
                size="large"
                onClick={() => history.push(pageUrl(RenewalsDashboard))}>
                Renewals Dashboard
              </Button>
            </Flex>
            <Flex spaceBetween>
              <SnapshotCreateForm
                excludeMonthsYears={existingMonthsYears}
                fetchClients={refetch}
              />
              <VisibleIfSuperuser user={user}>
                <ExternalLinkButton
                  size="large"
                  style={{
                    width: '120px',
                    alignSelf: 'end',
                    marginBottom: '14px'
                  }}
                  url={getDownloadRenewalsExportUrl(
                    buildFilters(filters),
                    buildSort(sort)
                  )}>
                  <Icon name="download" />
                  Export
                </ExternalLinkButton>
              </VisibleIfSuperuser>
            </Flex>
            <SharedFilters
              filters={filters}
              count={count}
              filterBy={filterBy}
              clearFilters={clear}
              layout={FILTERS}
              additionalStatistics={
                <AdditionalStatistics
                  stats={_.get(response, 'aggregations', {})}
                />
              }
            />

            {!_.isEmpty(data) && (
              <RenewalClientTable
                clients={data}
                renewalClientDelete={renewalClientDelete}
                sort={sort}
                orderBy={orderBy}
                convertedCurrency={convertedCurrency}
                refetch={refetch}
                pagination={
                  <Pagination
                    limit={limit}
                    count={count}
                    page={page}
                    changePage={changePage}
                    changeRowsPerPage={changeRowsPerPage}
                  />
                }
              />
            )}
            {_.isEmpty(data) && <NoDataMessage />}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: renewalClientList
})(
  withRouter(
    LoginRequired(
      PermissionsRequired([PERMISSIONS.VIEW_CLIENTS])(RenewalClientList)
    )
  )
);
