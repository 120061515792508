import React from 'react';

import {
  Page,
  Header,
  LoginRequired,
  ListViewEssentials,
  Filters as SharedFilters,
  Loader
} from 'components';

import { ProductMontsTable } from './components';

import { budgetDashboardApi } from './sdk';
import { FILTERS } from './utils';

class BudgetDashboard extends React.Component {
  render() {
    const {
      user,
      crumbs,
      data,
      filters,
      filterBy,
      clear,
      isFetching,
      refetch
    } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Header>Budget Dashboard</Header>
            <SharedFilters
              filters={filters}
              filterBy={filterBy}
              clearFilters={clear}
              layout={FILTERS}
            />
            {isFetching && <Loader />}
            {data && !isFetching && (
              <ProductMontsTable data={data} refetch={refetch} />
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}
export default ListViewEssentials({
  sdk: budgetDashboardApi,
  limit: null
})(LoginRequired(BudgetDashboard));
