import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const dailyNewsletterOrderArticle = async (
  dailyNewsletterId,
  section,
  data
) => {
  const url = `${BASE_URL}/newsletters/daily/${dailyNewsletterId}/order/${section}/`;

  return await requestSdk(() => post(url, data));
};
