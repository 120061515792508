import _ from 'lodash';
import { useEffect, useState } from 'react';

import { Field, SelectField } from 'components';

import {
  emailTemplateList,
  emailTemplateListFavourites,
  emailTemplateListEvents,
  emailTemplateListSubscriptions,
  emailTemplateListOther
} from './sdk';
import {
  ALL_EMAILS,
  FAVOURITE_EMAILS,
  EVENT_EMAILS,
  SUBSCRIPTION_EMAILS,
  OTHER_EMAILS
} from '../../constants';

const SDK_FETCHER_MAP = {
  [ALL_EMAILS]: emailTemplateList,
  [FAVOURITE_EMAILS]: emailTemplateListFavourites,
  [EVENT_EMAILS]: emailTemplateListEvents,
  [SUBSCRIPTION_EMAILS]: emailTemplateListSubscriptions,
  [OTHER_EMAILS]: emailTemplateListOther
};

const EmailTemplateSelect = ({
  name,
  isCustom,
  emailTemplateType,
  ...rest
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fecther = SDK_FETCHER_MAP[emailTemplateType];
    const fetchOptions = async () => {
      const { data, success } = await fecther();

      if (success) {
        const optionsList = data.map(emailTemplate => {
          return {
            key: emailTemplate.id,
            value: emailTemplate.id,
            label: emailTemplate.title
          };
        });
        setOptions(_.orderBy(optionsList, 'label'));
      }
    };

    fetchOptions();
  }, [emailTemplateType]);

  return (
    <Field
      name={name || 'emailTemplate'}
      component={SelectField}
      options={options}
      isClearable
      {...rest}
    />
  );
};

export default EmailTemplateSelect;
