import React from 'react';

import { Button, Icon } from 'semantic-ui-react';

import { Formik, Form } from 'formik';

import { prepareFormData, handleFormErrors } from 'utils/forms';

import { notifySuccess } from 'utils/notifications';
import {
  addArticleToNewsletterSection,
  removeArticleFromNewsletterSection
} from '../sdk';

function FeaturedForm({
  newsletter,
  fetchNewsletterContentSections,
  article,
  isAdded
}) {
  const handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const sdk = isAdded
      ? removeArticleFromNewsletterSection
      : addArticleToNewsletterSection;

    const data = {
      ...prepareFormData(values),
      section_slug: 'featured-articles'
    };

    setSubmitting(true);

    const { errors, success } = await sdk(newsletter.id, data);
    setSubmitting(false);

    if (success) {
      const message = isAdded ? 'removed from' : 'added to';
      notifySuccess(`${article.full_headline} ${message} featured.`);
      fetchNewsletterContentSections();
      return;
    }
    handleFormErrors(errors, setFieldError);
  };

  const initialValues = {
    article: article.id
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <Form>
          <input type="hidden" name="article" />
          <Button
            basic
            color={isAdded ? 'grey' : 'red'}
            type="submit"
            disabled={isSubmitting}
            icon>
            <Icon name={isAdded ? 'cancel' : 'add'} />
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default FeaturedForm;
