import React from 'react';
import _ from 'lodash';

import { Segment, Statistic } from 'semantic-ui-react';

import { SUBSCRIPTIONS } from 'pages/FormsDashboard/constants';

const getCount = (statistics, sub) => {
  const subscriptionIds = SUBSCRIPTIONS[sub];
  let count = 0;
  _.forEach(subscriptionIds, subId => {
    count += _.get(statistics, subId, 0);
  });
  return count;
};

const SubscriptionsStatistics = ({ statistics }) => (
  <Segment compact>
    {_.keys(SUBSCRIPTIONS).map(sub => (
      <Statistic size="mini" color="green">
        <Statistic.Label>{sub}</Statistic.Label>
        <Statistic.Value>{getCount(statistics, sub)}</Statistic.Value>
      </Statistic>
    ))}
  </Segment>
);

export default SubscriptionsStatistics;
