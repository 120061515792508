import React from 'react';

import { Table } from 'semantic-ui-react';

import FeatureForm from './FeatureForm';
import CreateFeatureForm from './CreateFeatureForm';

class SubscriptionFeatures extends React.Component {
  render() {
    const { subscription, fetchSubscription } = this.props;

    const { features } = subscription;

    return (
      <Table celled style={{ maxWidth: '1000px' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Feature</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {features.map((feature, index) => (
            <FeatureForm
              key={index}
              feature={feature}
              fetchSubscription={fetchSubscription}
            />
          ))}
          <CreateFeatureForm
            subscription={subscription}
            fetchSubscription={fetchSubscription}
          />
        </Table.Body>
      </Table>
    );
  }
}

export default SubscriptionFeatures;
