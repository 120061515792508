import { transformValuesForMultiselect } from 'utils/filters';
import { coerceStringToBoolean } from 'utils';

export const FILTERS = [
  {
    name: 'search',
    emptyValue: ''
  },
  {
    name: 'account_manager',
    emptyValue: null,
    transform: value => parseInt(value, 10)
  },
  {
    name: 'subscription',
    emptyValue: [],
    transform: transformValuesForMultiselect
  },
  {
    name: 'subscription_status',
    emptyValue: [],
    transform: transformValuesForMultiselect
  },
  {
    name: 'subscription_expires_from',
    emptyValue: null
  },
  {
    name: 'subscription_expires_to',
    emptyValue: null
  },
  {
    name: 'cm',
    emptyValue: null,
    transform: value => parseInt(value, 10)
  },
  {
    name: 'first_name',
    emptyValue: ''
  },
  {
    name: 'last_name',
    emptyValue: ''
  },
  {
    name: 'email',
    emptyValue: ''
  },
  {
    name: 'job_title',
    emptyValue: ''
  },
  {
    name: 'company_name',
    emptyValue: ''
  },
  {
    name: 'unsubscribed',
    emptyValue: null,
    transform: coerceStringToBoolean
  },
  {
    name: 'unsubscribed_at',
    emptyValue: null
  },
  {
    name: 'unsubscribed_method',
    emptyValue: null
  },
  {
    name: 'is_active',
    emptyValue: null,
    transform: coerceStringToBoolean
  },
  {
    name: 'interested_in_events',
    emptyValue: null,
    transform: coerceStringToBoolean
  },
  {
    name: 'interested_in_news',
    emptyValue: null,
    transform: coerceStringToBoolean
  },
  {
    name: 'interested_in_reports',
    emptyValue: null,
    transform: coerceStringToBoolean
  },
  {
    name: 'address',
    emptyValue: ''
  },
  {
    name: 'state_or_province',
    emptyValue: ''
  },
  {
    name: 'phone',
    emptyValue: ''
  },
  {
    name: 'fax',
    emptyValue: ''
  },
  {
    name: 'city',
    emptyValue: ''
  },
  {
    name: 'postcode',
    emptyValue: ''
  },
  {
    name: 'mobile',
    emptyValue: ''
  },
  {
    name: 'website',
    emptyValue: ''
  },
  {
    name: 'email_type',
    emptyValue: ''
  },
  {
    name: 'registration_type',
    emptyValue: ''
  },
  {
    name: 'countries',
    emptyValue: [],
    transform: transformValuesForMultiselect
  },
  {
    name: 'is_in_sanctioned_country',
    emptyValue: null,
    transform: coerceStringToBoolean,
    props: () => ({
      yesLabel: 'Sanctioned only',
      noLabel: 'Not sanctioned only'
    })
  },
  {
    name: 'has_country',
    emptyValue: null,
    transform: coerceStringToBoolean
  },
  {
    name: 'regions',
    emptyValue: [],
    transform: transformValuesForMultiselect
  },
  {
    name: 'industry',
    emptyValue: [],
    transform: transformValuesForMultiselect
  },
  {
    name: 'business_activity',
    emptyValue: [],
    transform: transformValuesForMultiselect
  },
  {
    name: 'product',
    emptyValue: null,
    transform: value => parseInt(value, 10)
  },
  {
    name: 'product_status',
    emptyValue: null
  },
  {
    name: 'product_status_date_from',
    emptyValue: null
  },
  {
    name: 'product_status_date_to',
    emptyValue: null
  },
  {
    name: 'upcoming_event',
    emptyValue: [],
    transform: transformValuesForMultiselect
  },
  {
    name: 'past_event',
    emptyValue: [],
    transform: transformValuesForMultiselect
  },
  {
    name: 'event_status_date_from',
    emptyValue: null
  },
  {
    name: 'event_status_date_to',
    emptyValue: null
  },
  {
    name: 'has_my_snapshot_prices',
    emptyValue: null,
    transform: coerceStringToBoolean
  },
  {
    name: 'my_snapshot_prices_date_from',
    emptyValue: null
  },
  {
    name: 'my_snapshot_prices_date_to',
    emptyValue: null
  },
  {
    name: 'id',
    emptyValue: ''
  },
  {
    name: 'uses_mobile_app',
    emptyValue: null,
    transform: coerceStringToBoolean
  },
  {
    name: 'event_status',
    emptyValue: []
  },
  {
    name: 'commodities',
    emptyValue: []
  },
  {
    name: 'sub_industry',
    emptyValue: [],
    transform: transformValuesForMultiselect
  },
  {
    name: 'priority',
    emptyValue: null
  },
  {
    name: 'has_admin_updates',
    emptyValue: null,
    transform: coerceStringToBoolean
  }
];
