import React from 'react';

import { Field, SelectField } from 'components';

import { priceSeriesProductList } from './sdk';

class PriceSeriesProductSelect extends React.Component {
  state = {
    priceSeriesProducts: []
  };

  async componentDidMount() {
    const { isPowerMaterial } = this.props;
    let params = {};

    if (isPowerMaterial) {
      params = { is_power_material_related: isPowerMaterial };
    }
    const { data, success } = await priceSeriesProductList(params);

    if (success) {
      this.setState({ priceSeriesProducts: data });
    }
  }

  render() {
    const { name, isCustom, ...rest } = this.props;
    const { priceSeriesProducts } = this.state;

    const options = priceSeriesProducts.map(priceSeriesProduct => {
      return {
        key: priceSeriesProduct.id,
        value: priceSeriesProduct.id,
        label: priceSeriesProduct.title
      };
    });

    return (
      <Field
        name={name || 'priceSeriesProduct'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default PriceSeriesProductSelect;
