import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const dailyNewsletterAddTopHeadlineArticle = async (
  dailyNewsletterId,
  data
) => {
  const url = `${BASE_URL}/newsletters/daily/${dailyNewsletterId}/add-top-headline/`;

  return await requestSdk(() => post(url, data));
};

export const dailyNewsletterRemoveTopHeadlineArticle = async (
  dailyNewsletterId,
  data
) => {
  const url = `${BASE_URL}/newsletters/daily/${dailyNewsletterId}/remove-top-headline/`;

  return await requestSdk(() => post(url, data));
};
