import React from 'react';

import { Link } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { CountryDetail } from 'pages';

import { Table } from 'semantic-ui-react';

import { TableFooter, SortableHeader } from 'components';

import { formatDateTime } from 'utils';

class CountryTable extends React.Component {
  render() {
    const { countries, sort, orderBy, pagination } = this.props;

    return (
      <Table celled selectable compact={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              singleLine
              style={{ fontWeight: '400' }}
              colspan="4">
              {pagination}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <SortableHeader sort={sort} orderBy={orderBy} field="title">
              Country
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="region">
              Region
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="created_at">
              Created At
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="updated_at">
              Updated At
            </SortableHeader>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {countries.map(country => (
            <Table.Row key={country.id}>
              <Table.Cell>
                <Link to={pageUrl(CountryDetail, { countryId: country.id })}>
                  {country.title}
                </Link>
              </Table.Cell>
              <Table.Cell>
                {(country.region && country.region.title) || 'N/A'}
              </Table.Cell>
              <Table.Cell>{formatDateTime(country.created_at)}</Table.Cell>
              <Table.Cell>{formatDateTime(country.updated_at)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <TableFooter>{pagination}</TableFooter>
      </Table>
    );
  }
}

export default CountryTable;
