import React from 'react';
import _ from 'lodash';

import {
  Page,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Filters as SharedFilters,
  Flex,
  NoDataMessage
} from 'components';

import {
  withEventType,
  Navigation,
  SubscriptionStatistics,
  AverageRating
} from './components';

import { commentsList, feedbackList, trackingEventsList } from './sdk';

import { FILTERS, TABLE_COMPONENTS } from './utils';

import { EVENT_TYPES } from './constants';
class FormsDashboard extends React.Component {
  render() {
    const {
      user,
      crumbs,
      data,
      limit,
      count,
      page,
      changePage,
      changeRowsPerPage,
      sort,
      orderBy,
      isFetching,
      eventType,
      filters,
      filterBy,
      clear,
      response,
      refetch
    } = this.props;

    const Table = TABLE_COMPONENTS[eventType.slug];

    const showSubscriptionStatistics =
      eventType.slug === EVENT_TYPES.trial.slug ||
      eventType.slug === EVENT_TYPES.subscription.slug;

    const showAverageRating = eventType.slug === EVENT_TYPES.feedback.slug;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs} maxWidth>
            <Flex spaceBetween>
              <Header>Forms Dashboard</Header>
              <Navigation />
            </Flex>
            <Header as="h2">{eventType.title}</Header>
            <SharedFilters
              filters={filters}
              count={count}
              filterBy={filterBy}
              clearFilters={clear}
              layout={FILTERS[eventType.slug]}
            />
            {!isFetching && showSubscriptionStatistics && (
              <SubscriptionStatistics
                statistics={_.get(response, 'subscriptions_count', {})}
              />
            )}
            {!isFetching && showAverageRating && (
              <AverageRating rating={_.get(response, 'average_rating')} />
            )}
            {_.isEmpty(data) && !isFetching && <NoDataMessage />}
            {!_.isEmpty(data) && !isFetching && (
              <Table
                data={data}
                orderBy={orderBy}
                sort={sort}
                eventType={eventType.title}
                refetch={refetch}
                pagination={
                  <Pagination
                    limit={limit}
                    count={count}
                    page={page}
                    changePage={changePage}
                    changeRowsPerPage={changeRowsPerPage}
                  />
                }
              />
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export const TrackingFeedback = ListViewEssentials({
  sdk: feedbackList
})(
  LoginRequired(
    withEventType({
      eventType: EVENT_TYPES.feedback
    })(FormsDashboard)
  )
);

export const TrackingComments = ListViewEssentials({
  sdk: commentsList
})(
  LoginRequired(
    withEventType({
      eventType: EVENT_TYPES.comments
    })(FormsDashboard)
  )
);

const eventTypes = _.omit(EVENT_TYPES, ['comments', 'feedback']);

const [
  TrackingSubscriptionTrialEvents,
  TrackingSubscriptionEvents,
  TrackingUnsubscriptionEvents,
  TrackingMarketReportEvents,
  TrackingCoursesEvents,
  TrackingFAQSubmissions
] = _.keys(eventTypes).map(eventType =>
  ListViewEssentials({
    sdk: params => trackingEventsList(EVENT_TYPES[eventType].slug, params)
  })(
    LoginRequired(
      withEventType({
        eventType: EVENT_TYPES[eventType]
      })(FormsDashboard)
    )
  )
);

export {
  TrackingSubscriptionTrialEvents,
  TrackingSubscriptionEvents,
  TrackingUnsubscriptionEvents,
  TrackingMarketReportEvents,
  TrackingCoursesEvents,
  TrackingFAQSubmissions
};
