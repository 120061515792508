import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const recipientGroupsList = async (productSlug, params) => {
  const url = `${BASE_URL}/newsletters/v2/${productSlug}/recipient-groups/`;

  return await requestSdk(() => get(url, params));
};

export const recipientGroup = async id => {
  const url = `${BASE_URL}/newsletters/v2/recipient-groups/${id}/`;

  return await requestSdk(() => get(url));
};
