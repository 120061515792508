import _ from 'lodash';
import React, { useMemo } from 'react';

import { Modal, Icon, Header, Table, Button } from 'semantic-ui-react';

import {
  Flex,
  SortableHeader,
  ExternalLinkButton,
  LinkButton,
  Tooltip
} from 'components';
import { pageUrl } from 'config/routes';
import { ClientDetail, InvoiceDetail } from 'pages';
import { formatDate, openInNewTab } from 'utils';
import { useSortable } from 'utils/sort';

import { getPresalesDownloadUsageUrl } from './sdk';

const PRESALE_TITLE_SLICE_LIMIT = 10;

const getNumericValue = price => Number(price.split(' ')[0]) || 0;

const PresalesUsageModal = ({ course, presalesUsage, onClose }) => {
  const defaultData = useMemo(
    () =>
      _.map(presalesUsage, ({ invoice, ...rest }) => ({
        ...rest,
        invoice: {
          ...invoice,
          // Extract numeric values so we can sort by these fields
          initial_price_numeric: getNumericValue(invoice.initial_price),
          final_price_numeric: getNumericValue(invoice.final_price)
        }
      })),
    [presalesUsage]
  );

  const { data, sort, handleSortUpdate } = useSortable({
    defaultData: defaultData
  });

  return (
    <Modal open={true} size="large" centered={false}>
      <Modal.Header>
        <Flex spaceBetween>
          <Header as="h2" style={{ margin: 0 }}>
            {course.title} Presales Usage
          </Header>
          <Icon name="close" onClick={onClose} />
        </Flex>
      </Modal.Header>
      <Modal.Content>
        <Flex flexEnd>
          <div>
            <ExternalLinkButton
              color="blue"
              url={getPresalesDownloadUsageUrl(course.id)}>
              Export
            </ExternalLinkButton>
          </div>
        </Flex>
        <div
          style={{ maxHeight: '60vh', overflowY: 'auto', marginTop: '15px' }}>
          <Table celled selectable>
            <Table.Header style={{ position: 'sticky', top: 0, zIndex: 1 }}>
              <Table.Row>
                <SortableHeader
                  sort={sort}
                  orderBy={handleSortUpdate}
                  field="invoice.title"
                  width={1}>
                  Invoice
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={handleSortUpdate}
                  field="invoice.status"
                  width={1}>
                  Invoice Status
                </SortableHeader>
                <SortableHeader
                  width={1}
                  sort={sort}
                  field="invoice.initial_price_numeric"
                  orderBy={handleSortUpdate}>
                  Initial price
                </SortableHeader>
                <Table.HeaderCell width={1}>Discount</Table.HeaderCell>
                <SortableHeader
                  width={1}
                  sort={sort}
                  field="invoice.final_price_numeric"
                  orderBy={handleSortUpdate}>
                  Final price
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={handleSortUpdate}
                  field="items_count"
                  width={1}>
                  # of All Delegates
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={handleSortUpdate}
                  field="user_id"
                  width={1}>
                  User ID
                </SortableHeader>
                <Table.HeaderCell width={3}>Matching Presales</Table.HeaderCell>
                <SortableHeader
                  sort={sort}
                  orderBy={handleSortUpdate}
                  field="from_website"
                  width={1}>
                  Source
                </SortableHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.map((invoice, index) => (
                <Table.Row key={index}>
                  <Table.Cell width={1}>
                    <LinkButton
                      onClick={() =>
                        openInNewTab(
                          pageUrl(InvoiceDetail, {
                            invoiceId: invoice.invoice.id
                          })
                        )
                      }>
                      {invoice.invoice.title}
                    </LinkButton>
                  </Table.Cell>
                  <Table.Cell width={1}>{invoice.invoice.status}</Table.Cell>
                  <Table.Cell width={1}>
                    {invoice.invoice.initial_price}
                  </Table.Cell>
                  <Table.Cell width={1}>{invoice.invoice.discount}</Table.Cell>
                  <Table.Cell width={1}>
                    {invoice.invoice.final_price}
                  </Table.Cell>
                  <Table.Cell width={1}>{invoice.items_count}</Table.Cell>
                  <Table.Cell width={1}>
                    <LinkButton
                      onClick={() =>
                        openInNewTab(
                          pageUrl(ClientDetail, { clientId: invoice.user_id })
                        )
                      }>
                      {invoice.user_id}
                    </LinkButton>
                  </Table.Cell>
                  <Table.Cell width={1}>
                    {invoice.presales.map((presale, index) => (
                      <Flex key={index} spaceBetween>
                        {presale.title.length >
                        PRESALE_TITLE_SLICE_LIMIT + 3 ? (
                          <Tooltip
                            on="hover"
                            content={presale.title}
                            trigger={
                              <div>{`${presale.title.slice(
                                0,
                                PRESALE_TITLE_SLICE_LIMIT
                              )}...`}</div>
                            }
                          />
                        ) : (
                          <div>{presale.title}</div>
                        )}
                        <div>
                          {formatDate(presale.start_date)} -{' '}
                          {formatDate(presale.end_date)}
                        </div>
                      </Flex>
                    ))}
                  </Table.Cell>
                  <Table.Cell width={1}>
                    {invoice.from_website ? 'Website' : 'Backoffice'}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={onClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PresalesUsageModal;
