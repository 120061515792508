import _ from 'lodash';

const CURRENCY = {
  EUR: '€',
  USD: '$',
  GBP: '£'
};

export const getPresalePrice = (event, presale) => {
  let priceObj;
  if (event.default_currency) {
    priceObj = _.first(
      _.filter(
        presale.prices,
        price => price.currency === event.default_currency
      )
    );
  } else {
    priceObj = presale.prices[0];
  }

  // try to get currency short sign
  return `${priceObj.amount}${CURRENCY[priceObj.currency] ||
    priceObj.currency}`;
};
