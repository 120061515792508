import React from 'react';

import { Field, SelectField } from 'components';
const PAYMENT_RECEIVED = ['New', 'Repeat'];

class PaymentReceivedSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = PAYMENT_RECEIVED.map(item => {
      return {
        key: item,
        value: item,
        label: item
      };
    });

    return (
      <Field
        name={name || 'paymentReceived'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default PaymentReceivedSelect;
