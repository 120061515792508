import React from 'react';

import { Formik } from 'formik';

import { Form } from 'semantic-ui-react';

import { handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';
import { Field, RichTextAreaField, UpdateButtonInRow } from 'components';

class TextForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    setSubmitting(true);

    const { errors, success } = await this.props.pageUpdate(
      this.props.page.id,
      values
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Page Text updated successfully!');
      this.props.fetchPage();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const { logged_in_text, logged_out_text } = this.props.page;
    const initialValues = { logged_in_text, logged_out_text };

    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        enableReinitialize={true}>
        {({ handleSubmit, isSubmitting }) => (
          <Form>
            <Field
              component={RichTextAreaField}
              name="logged_in_text"
              label="Logged in Text"
            />
            <Field
              component={RichTextAreaField}
              name="logged_out_text"
              label="Logged out Text"
            />

            <UpdateButtonInRow
              subject="Text"
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default TextForm;
