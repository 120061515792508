import React from 'react';

import { pageUrl } from 'config/routes';

import { NotFound } from 'pages';

class RedirectToNotFound extends React.Component {
  componentDidMount() {
    return this.props.history.push(pageUrl(NotFound));
  }

  render() {
    return null;
  }
}

export default RedirectToNotFound;
