import _ from 'lodash';
import moment from 'moment';

export const getTime = datetime => {
  if (_.isNil(datetime)) {
    return '';
  }

  return moment(datetime).format('HH:mm');
};

export const parseTime = time => {
  if (!time) {
    return [0, 0];
  }
  return time.split(':');
};
