import React from 'react';
import _ from 'lodash';

import { Table } from 'semantic-ui-react';

import { Button, YesNo } from 'components';
import { formatDateTime } from 'utils';
import RegistrationAttemptDetailsModal from './RegistrationAttemptDetailsModal';

class RegistrationAttemptRow extends React.Component {
  state = {
    isDetailsModalOpen: false
  };

  toggleDetailsModal = () => {
    this.setState(prevState => ({
      isDetailsModalOpen: !prevState.isDetailsModalOpen
    }));
  };

  render() {
    const { item } = this.props;

    const { isDetailsModalOpen } = this.state;

    return (
      <>
        <Table.Row>
          <Table.Cell>{item.email}</Table.Cell>
          <Table.Cell>{formatDateTime(item.created_at)}</Table.Cell>
          <Table.Cell>{formatDateTime(item.updated_at)}</Table.Cell>
          <Table.Cell>{item.registration_type}</Table.Cell>
          <Table.Cell>{item.title}</Table.Cell>
          <Table.Cell>
            {item.first_name} {item.last_name}
          </Table.Cell>
          <Table.Cell>{item.job_title}</Table.Cell>
          <Table.Cell>{item.company_name}</Table.Cell>
          <Table.Cell>{item.phone}</Table.Cell>
          <Table.Cell>{item.city}</Table.Cell>
          <Table.Cell>{item.coutry}</Table.Cell>
          <Table.Cell>
            <YesNo value={item.finished} />
          </Table.Cell>
          <Table.Cell>
            {_.get(item.finished_by, 'first_name', 'N/A')}{' '}
            {_.get(item.finished_by, 'last_name', '')}
          </Table.Cell>
          <Table.Cell>
            <Button onClick={this.toggleDetailsModal}>Show details</Button>
          </Table.Cell>
        </Table.Row>

        {isDetailsModalOpen && (
          <RegistrationAttemptDetailsModal
            item={item}
            close={this.toggleDetailsModal}
          />
        )}
      </>
    );
  }
}

export default RegistrationAttemptRow;
