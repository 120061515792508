import React from 'react';

import { Icon } from 'semantic-ui-react';

import { Flex } from 'components';

const YesNo = ({ value, checkColor = null, crossColor = null, style = {} }) => (
  <Flex style={style}>
    {value ? (
      <Icon name="check circle outline" color={checkColor || 'green'} />
    ) : (
      <Icon name="times circle outline" color={crossColor || 'red'} />
    )}
  </Flex>
);

export default YesNo;
