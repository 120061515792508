import React from 'react';

import { Table } from 'semantic-ui-react';
import styles from './styles.module.css';

import ImportClientsHeaderRow from '../ImportClientsHeaderRow';

const ImportClientsFormatTable = () => (
  <Table celled className={styles.TableScroll}>
    <ImportClientsHeaderRow />
    <Table.Body>
      <Table.Row>
        <Table.Cell>example@kallanish.com</Table.Cell>
        <Table.Cell>example@kallanish.com</Table.Cell>
        <Table.Cell>AAA</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>example.com</Table.Cell>
        <Table.Cell>(123) 123-0123</Table.Cell>
        <Table.Cell>(123) 123-0123</Table.Cell>
        <Table.Cell>(123) 123-0123</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>PDF</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Mining</Table.Cell>
        <Table.Cell>Metals / Mining</Table.Cell>
        <Table.Cell>Metals</Table.Cell>
        <Table.Cell>Steel</Table.Cell>
        <Table.Cell>ExampleTag</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>example2@kallanish.com</Table.Cell>
        <Table.Cell>example2@kallanish.com</Table.Cell>
        <Table.Cell>A</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>example.com</Table.Cell>
        <Table.Cell>(123) 123-0123</Table.Cell>
        <Table.Cell>(123) 123-0123</Table.Cell>
        <Table.Cell>(123) 123-0123</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>HTML</Table.Cell>
        <Table.Cell>Example</Table.Cell>
        <Table.Cell>Financial</Table.Cell>
        <Table.Cell>Energy</Table.Cell>
        <Table.Cell>Power</Table.Cell>
        <Table.Cell>Electricity</Table.Cell>
        <Table.Cell>SomeTag, SomeOtherTag</Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
);

export default ImportClientsFormatTable;
