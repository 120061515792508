import React from 'react';

import { Field, SelectField } from 'components';

import { bankAccountList } from './sdk';

class BankAccountSelect extends React.Component {
  state = {
    bankAccounts: []
  };
  async componentDidMount() {
    const { data, success } = await bankAccountList();

    if (success) {
      this.setState({ bankAccounts: data });
    }
  }

  render() {
    const { name, ...rest } = this.props;
    const { bankAccounts } = this.state;

    const options = bankAccounts.map(account => {
      return {
        key: account.id,
        value: account.id,
        label: account.title
      };
    });

    return (
      <Field
        name={name || 'bankAccount'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default BankAccountSelect;
