import React from 'react';

import _ from 'lodash';

import { Error, TransferList } from 'components';

import styles from './styles.module.css';

const TransferListField = ({
  label,
  className,
  form,
  field,
  options,
  showCount = false,
  onChange = () => {},
  ...props
}) => {
  const hasErrors =
    _.get(form.touched, field.name) && _.get(form.errors, field.name);

  return (
    <div className={styles.container}>
      <TransferList
        label={label}
        onChange={value => form.setFieldValue(field.name, value)}
        options={options}
        value={field.value || []}
        showCount={showCount}
        {...props}
      />
      {hasErrors && (
        <Error className={styles.error}>{_.get(form.errors, field.name)}</Error>
      )}
    </div>
  );
};

export default TransferListField;
