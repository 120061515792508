import _ from 'lodash';
import React from 'react';

import { Table } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import { TableFooter, SortableHeader } from 'components';

import { formatDateTime } from 'utils';
import { pageUrl } from 'config/routes';
import { MailshotGroupDetail } from 'pages/MailshotGroup';

const MailshotGroupsTable = ({ mailshotGroups, sort, orderBy, pagination }) => (
  <Table celled selectable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell singleLine style={{ fontWeight: '400' }} colspan="4">
          {pagination}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <SortableHeader sort={sort} orderBy={orderBy} field="title">
          Title
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="created_by">
          Created by
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="created_at">
          Date created
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="mailshot_count">
          Mailshot Count
        </SortableHeader>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {mailshotGroups.map(mailshotGroup => (
        <Table.Row key={mailshotGroup.id}>
          <Table.Cell width={4}>
            <Link
              to={pageUrl(MailshotGroupDetail, {
                mailshotGroupId: mailshotGroup.id
              })}>
              {mailshotGroup.title}
            </Link>
          </Table.Cell>
          <Table.Cell width={2}>
            {_.get(mailshotGroup.created_by, 'email', 'N/A')}
          </Table.Cell>
          <Table.Cell>{formatDateTime(mailshotGroup.created_at)}</Table.Cell>
          <Table.Cell>{mailshotGroup.mailshot_count}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default MailshotGroupsTable;
