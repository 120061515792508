import React from 'react';

import { withRouter } from 'react-router-dom';

import {
  Page,
  Header,
  Flex,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Button,
  Filters as SharedFilters
} from 'components';

import { pageUrl } from 'config/routes';

import { TrainerCreate } from 'pages';

import { trainerList } from './sdk';

import { TrainerTable } from './components';

import { FILTERS } from './utils';

class TrainerList extends React.Component {
  createTrainer = () => {
    const location = pageUrl(TrainerCreate);

    this.props.history.push(location);
  };

  render() {
    const {
      user,
      data,
      limit,
      count,
      page,
      changePage,
      changeRowsPerPage,
      orderBy,
      sort,
      filters,
      filterBy,
      clear
    } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content>
            <Flex spaceBetween>
              <Header>Speakers</Header>
              <Button size="large" onClick={this.createTrainer}>
                Add Speaker
              </Button>
            </Flex>
            <SharedFilters
              filters={filters}
              count={count}
              filterBy={filterBy}
              clearFilters={clear}
              layout={FILTERS}
            />
            {data && (
              <>
                <TrainerTable
                  orderBy={orderBy}
                  sort={sort}
                  trainers={data}
                  pagination={
                    <Pagination
                      limit={limit}
                      count={count}
                      page={page}
                      changePage={changePage}
                      changeRowsPerPage={changeRowsPerPage}
                    />
                  }
                />
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: trainerList
})(LoginRequired(withRouter(TrainerList)));
