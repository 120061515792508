import _ from 'lodash';
import React from 'react';

import {
  Page,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Filters as SharedFilters,
  Loader,
  NoDataMessage,
  Flex
} from 'components';

import { Segment } from 'semantic-ui-react';

import { trackedArticlesList } from './sdk';

import { TrackedArticlesTable, TopArticleTable } from './components';

import { FILTERS as LAYOUT_FILTERS } from './utils';

import { PermissionsRequired, PERMISSIONS } from 'permissions';

class TrackedArticlesList extends React.Component {
  render() {
    const {
      user,
      data,
      limit,
      count,
      page,
      changePage,
      changeRowsPerPage,
      filters,
      clear,
      filterBy,
      orderBy,
      sort,
      isFetching,
      addFilters,
      response
    } = this.props;

    const mostOpenedArticles = _.get(response, 'most_opened_articles', []);

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content>
            <Header>Tracked Articles Opens</Header>
            <SharedFilters
              filters={filters}
              clearFilters={clear}
              filterBy={filterBy}
              count={count}
              layout={LAYOUT_FILTERS}
            />
            {isFetching && (
              <Segment>
                <Loader />
              </Segment>
            )}
            {_.isEmpty(data) && !isFetching && <NoDataMessage />}
            {!_.isEmpty(data) && !isFetching && (
              <React.Fragment>
                <TrackedArticlesTable
                  sort={sort}
                  orderBy={orderBy}
                  articleChanges={data}
                  isFetching={isFetching}
                  addFilters={addFilters}
                  pagination={
                    <Pagination
                      limit={limit}
                      count={count}
                      page={page}
                      changePage={changePage}
                      changeRowsPerPage={changeRowsPerPage}
                    />
                  }
                />
                <Flex flexStart={true} alignStart={true}>
                  <TopArticleTable
                    articleData={mostOpenedArticles}
                    filters={filters}
                    filterBy={filterBy}
                  />
                </Flex>
              </React.Fragment>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: trackedArticlesList
})(
  LoginRequired(
    PermissionsRequired([PERMISSIONS.VIEW_CLIENTS])(TrackedArticlesList)
  )
);
