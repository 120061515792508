const STATUS_COLORS = {
  'Payment Promised': '#a333c863',
  Paid: '#21ba455c'
};

export const getStatusColor = status => STATUS_COLORS[status];

const TYPE_COLORS = {
  Subscription: '#ff000080'
};

export const getTypeColor = type => TYPE_COLORS[type];
