import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { pageUrl } from 'config/routes';
import { GlossaryTermDetail } from 'pages';
import { YesNo, TableFooter, SortableHeader, DeleteModal } from 'components';

import { Table } from 'semantic-ui-react';

import { formatDateTime } from 'utils';

import { glossaryTermDelete } from '../../../sdk';

class GlossaryTermsTable extends React.Component {
  modalClosed = () => {
    const { refetch } = this.props;
    refetch();
  };

  render() {
    const { terms, pagination, sort, orderBy } = this.props;

    return (
      <Table celled selectable compact={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              singleLine
              style={{ fontWeight: '400' }}
              colspan="8">
              {pagination}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <SortableHeader sort={sort} orderBy={orderBy} field="title">
              Title
            </SortableHeader>
            <Table.HeaderCell>Glossary Category</Table.HeaderCell>
            <Table.HeaderCell>Is published</Table.HeaderCell>
            <SortableHeader sort={sort} orderBy={orderBy} field="created_by">
              Created by
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="created_at">
              Create time
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="updated_by">
              Updated by
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="updated_at">
              Updated time
            </SortableHeader>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {terms.map(term => (
            <Table.Row key={term.id}>
              <Table.Cell width={5}>
                <Link
                  to={pageUrl(GlossaryTermDetail, { glossaryTermId: term.id })}>
                  {term.title}
                </Link>
              </Table.Cell>
              <Table.Cell>{_.get(term, 'category.title', '')}</Table.Cell>
              <Table.Cell>
                <YesNo value={term.is_published} />
              </Table.Cell>
              <Table.Cell>
                {term.created_by.first_name} {term.created_by.last_name}
              </Table.Cell>
              <Table.Cell>{formatDateTime(term.created_at)}</Table.Cell>
              <Table.Cell>
                {_.get(term, 'updated_by.first_name')}{' '}
                {_.get(term, 'updated_by.last_name')}
              </Table.Cell>
              <Table.Cell>{formatDateTime(term.updated_at)}</Table.Cell>
              <Table.Cell>
                <DeleteModal
                  itemId={term.id}
                  objName="Glossary Term"
                  sdk={glossaryTermDelete}
                  size="mini"
                  inverted={true}
                  objDetails={`${term.title} with ID: ${term.id}`}
                  onClose={this.modalClosed}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <TableFooter>{pagination}</TableFooter>
      </Table>
    );
  }
}

export default GlossaryTermsTable;
