import React from 'react';

import { Field, SelectField } from 'components';
import { courseTopicList } from './sdk';

class CourseTopicSelect extends React.Component {
  state = {
    courseTopics: []
  };
  async componentDidMount() {
    const { data, success } = await courseTopicList();

    if (success) {
      this.setState({ courseTopics: data });
    }
  }

  render() {
    const { name, ...rest } = this.props;
    const { courseTopics } = this.state;

    const options = courseTopics.map(courseTopic => {
      return {
        key: courseTopic.id,
        value: courseTopic.id,
        label: courseTopic.title
      };
    });

    return (
      <Field
        name={name || 'courseTopic'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default CourseTopicSelect;
