import React from 'react';

import _ from 'lodash';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { FormRow, Field, InputField, UpdateButton } from 'components';

import { permamentPriceSectionSchema } from './utils';

function SectionUpdateForm({ section, productSlug, refetch, sectionUpdate }) {
  const handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = {
      ...prepareFormData(values),
      section_slug: section.internal_slug
    };

    setSubmitting(true);

    const { success, errors } = await sectionUpdate(productSlug, data);

    setSubmitting(false);

    if (success) {
      notifySuccess('Heading updated.');
      refetch();
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  const initialValues = {
    displayName: _.get(section, 'display_name')
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={permamentPriceSectionSchema}>
      {({ isSubmitting, handleSubmit }) => (
        <Form style={{ maxWidth: '500px' }}>
          <FormRow>
            <Field width={12} name="displayName" component={InputField} />

            <UpdateButton disabled={isSubmitting} onClick={handleSubmit} />
          </FormRow>
          <div>This heading changes the header of the newsletter section.</div>
        </Form>
      )}
    </Formik>
  );
}

export default SectionUpdateForm;
