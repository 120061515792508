import React from 'react';

class RedirectToMedia extends React.Component {
  componentDidMount() {
    const path = document.location.pathname.split('/media/')[1];
    const url = `https://media.kallanish.com/${path}`;

    window.location = url;
  }

  render() {
    return null;
  }
}

export default RedirectToMedia;
