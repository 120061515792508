import React from 'react';

import { Field, SelectField } from 'components';

const RENEWAL_STATE = [
  'Pending',
  'In touch',
  'Booked',
  'Paid',
  'Cancelled',
  'PNR'
];

class RenewalStateSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = RENEWAL_STATE.map(item => {
      return {
        key: item,
        value: item,
        label: item
      };
    });

    return (
      <Field
        name={name || 'renewalState'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default RenewalStateSelect;
