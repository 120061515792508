import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const dailyNewsletterDetail = async id => {
  const url = `${BASE_URL}/newsletters/daily/${id}/`;

  return await requestSdk(() => get(url));
};

export const articleReadyForPublicationList = async () => {
  const url = `${BASE_URL}/editorials/articles/rfp/`;

  return await requestSdk(() => get(url));
};

export const dailyNewsletterDetailPrices = async id => {
  const url = `${BASE_URL}/newsletters/daily/${id}/prices/`;

  return await requestSdk(() => get(url));
};
