import React from 'react';

import { Segment, Header } from 'semantic-ui-react';

import { SortableList } from 'components';

import { notifySuccess, notifyErrors } from 'utils/notifications';

class PriceSectionContainer extends React.Component {
  onSortEnd = ({ oldIndex, newIndex, item, items }) => {
    const { orderPrices, priceFrequency } = this.props;

    orderPrices(priceFrequency.frequency, items);
  };

  render() {
    const { priceFrequency, newsletter } = this.props;

    const items = priceFrequency.prices;

    return (
      <Segment>
        <Header as="div" size="tiny">
          {priceFrequency.frequency}
        </Header>
        <SortableList
          disabled={newsletter.live}
          items={items}
          onSortEnd={this.onSortEnd}
        />
      </Segment>
    );
  }
}

class Prices extends React.Component {
  orderPrices = async (frequency, prices) => {
    const allPrices = [];

    const {
      newsletter,
      newsletterPrices,
      fetchNewsletterPrices,
      newsletterOrderPrices
    } = this.props;

    newsletterPrices.forEach(priceFrequency => {
      if (priceFrequency.frequency === frequency) {
        prices.forEach(price => allPrices.push(price));
      } else {
        priceFrequency.prices.forEach(price => allPrices.push(price));
      }
    });

    const { success, errors } = await newsletterOrderPrices(newsletter.id, {
      prices: allPrices.map(price => price.slug)
    });

    if (success) {
      notifySuccess(`Prices for ${frequency} reordered.`);
      fetchNewsletterPrices();
    } else {
      notifyErrors(errors);
    }
  };

  render() {
    const { newsletter, newsletterPrices } = this.props;

    return (
      <>
        <Header size="large" attached="top">
          Prices by frequencies
        </Header>
        <Segment attached>
          {newsletterPrices &&
            newsletterPrices.map(priceFrequency => (
              <PriceSectionContainer
                newsletter={newsletter}
                key={priceFrequency.frequency}
                priceFrequency={priceFrequency}
                orderPrices={this.orderPrices}
              />
            ))}
        </Segment>
      </>
    );
  }
}

export default Prices;
