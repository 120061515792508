import React from 'react';

import {
  Page,
  Header,
  LoginRequired,
  ListViewEssentials,
  Flex,
  Button,
  ExternalLink
} from 'components';

import { PermissionsRequired, PERMISSIONS } from 'permissions';

import { PriceSpreadDetail, PriceSeriesDetail } from 'pages';

import { PRICE_MOVEMENT_EXPORT_URL } from 'sdk/urls';

import { PriceSpreadMovementTable } from './components';

import { priceSpreadsMovementList } from './sdk';

class PriceSpreadMovementList extends React.Component {
  render() {
    const { user, data, crumbs } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Flex spaceBetween>
              <Header>Prices Spread Movement</Header>
              <ExternalLink url={PRICE_MOVEMENT_EXPORT_URL}>
                <Button color="green">Export to excel</Button>
              </ExternalLink>
            </Flex>
            {data && (
              <>
                <PriceSpreadMovementTable
                  priceSpreads={data}
                  detailPage={PriceSpreadDetail}
                  priceSeriesDetailPage={PriceSeriesDetail}
                />
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: priceSpreadsMovementList
})(
  LoginRequired(
    PermissionsRequired([PERMISSIONS.VIEW_PRICES])(PriceSpreadMovementList)
  )
);
