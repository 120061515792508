import React from 'react';

import _ from 'lodash';

import { Icon, Menu, Sidebar, Header, Button } from 'semantic-ui-react';

import { Logo } from 'components';

import MenuItem from './MenuItem';

import { logout } from 'sdk';

import styles from './styles.module.css';

class MobileNavigation extends React.Component {
  state = {
    isSidebarVisible: false
  };

  toggleSidebar = () => {
    this.setState(prevState => ({
      isSidebarVisible: !prevState.isSidebarVisible
    }));
  };

  closeSidebar = () => {
    this.setState({ isSidebarVisible: false });
  };

  shouldDisplayItem = (user, menuItem) => {
    if (menuItem.superUserRequired && !_.get(user, 'is_superuser', false)) {
      return false;
    }

    return true;
  };

  render() {
    const { user, navigationItems } = this.props;

    const menuItems = navigationItems;

    return (
      <>
        <div className={styles.navigationWrapper}>
          <Logo style={{ height: '36px' }} />
          <span onClick={this.toggleSidebar}>
            <Icon name="sidebar" size="big" />
          </span>
        </div>
        <Sidebar
          as={Menu}
          style={{ width: '75%', maxWidth: '270px' }}
          direction="left"
          animation="push"
          icon="labeled"
          onHide={() => this.toggleSidebar()}
          vertical
          visible={this.state.isSidebarVisible}>
          {menuItems.map(
            menuItem =>
              this.shouldDisplayItem(user, menuItem) && (
                <MenuItem {...menuItem} user={user} />
              )
          )}
          <Header as="h4">{user.name}</Header>
          <Button onClick={logout}>Logout</Button>
        </Sidebar>
      </>
    );
  }
}

export default MobileNavigation;
