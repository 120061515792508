import React from 'react';
import _ from 'lodash';

import { FastField as Field, TransferListField } from 'components';

import { regionList } from './sdk';

class RegionSelect extends React.Component {
  state = {
    regions: []
  };

  async componentDidMount() {
    const { data, success } = await regionList();

    if (success) {
      this.setState({
        regions: data.map(region => {
          return {
            key: region.id,
            value: region.id,
            label: region.title
          };
        })
      });
    }
  }

  render() {
    const { name, isCustom, ...rest } = this.props;
    const { regions } = this.state;

    const options = regions;

    if (!_.isEmpty(options)) {
      rest['options'] = options;
    }

    return (
      <Field
        name={name || 'region'}
        component={TransferListField}
        isClearable
        {...rest}
      />
    );
  }
}

export default RegionSelect;
