import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const priceMovementList = async productSlug => {
  const params = { product_type: productSlug };
  const url = `${BASE_URL}/prices/series/v2/movement/`;

  return await requestSdk(() => get(url, params));
};

export const PRICE_MOVEMENT_EXPORT_URL = (
  productSlug,
  weeklyResolution = false
) =>
  `${BASE_URL}/prices/series/v2/movement/?spreadsheet=True&product_type=${productSlug}&weekly_resolution=${weeklyResolution}`;
