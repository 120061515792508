import React from 'react';

import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import { Form } from 'semantic-ui-react';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';
import {
  Field,
  UpdateButtonInRow,
  InputField,
  FormRow,
  YesNoSelect,
  RichTextAreaField,
  VideoSelect,
  DateField,
  TagSelect,
  UnsavedFormPrompt,
  CreatedUpdatedFormValues
} from 'components';

import { videoUpdate } from './sdk';

import { videoUpdateSchema } from './utils';

class VideoUpdateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { fetchVideo } = this.props;
    const { videoId } = this.props.match.params;

    const { setSubmitting, setFieldError } = actions;

    const { errors, success } = await videoUpdate(
      videoId,
      prepareFormData(values)
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Video has been updated successfully.');
      fetchVideo();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const {
      title,
      slug,
      video_url: videoUrl,
      date_activation: dateOfActivation,
      about,
      related_videos: relatedVideos,
      tags,
      is_published: isPublished,
      created_at: createdAt,
      created_by: createdBy,
      updated_at: updatedAt,
      updated_by: updatedBy
    } = this.props.data;

    const initialValues = {
      title,
      slug,
      videoUrl,
      dateOfActivation,
      about,
      relatedVideos: relatedVideos.map(v => v.id),
      tags: tags.map(t => t.tag),
      isPublished
    };

    const nonFormValues = {
      createdAt,
      createdBy,
      updatedAt,
      updatedBy
    };

    return (
      <Formik
        enableReinitialize={true}
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        validationSchema={videoUpdateSchema}>
        {({ handleSubmit, isSubmitting, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty} />

            <FormRow>
              <Field
                required
                width={7}
                name="title"
                component={InputField}
                label="Title"
              />
              <Field
                required
                width={4}
                name="videoUrl"
                component={InputField}
                label="Video URL"
              />

              <Field
                required
                width={4}
                name="slug"
                component={InputField}
                label="Slug"
              />
            </FormRow>
            <FormRow>
              <Field
                width={3}
                name="dateOfActivation"
                component={DateField}
                label="Issue date"
              />
            </FormRow>

            <FormRow>
              <Field name="about" component={RichTextAreaField} label="About" />
            </FormRow>
            <FormRow>
              <Field
                width={10}
                name="relatedVideos"
                component={VideoSelect}
                label="Related Videos"
              />
            </FormRow>
            <FormRow>
              <Field
                width={10}
                name="tags"
                label="Tags"
                component={TagSelect}
              />
              <Field
                width={3}
                name="isPublished"
                component={YesNoSelect}
                label="Is published"
              />
            </FormRow>
            <CreatedUpdatedFormValues values={nonFormValues} />
            <UpdateButtonInRow
              subject="Video"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(VideoUpdateForm);
