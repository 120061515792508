import React from 'react';

import _ from 'lodash';

import { Header, Segment } from 'semantic-ui-react';

import { withRouter } from 'react-router-dom';

import { Page, LoginRequired, Flex } from 'components';

import { videoDetail } from './sdk';

import { VideoUpdateForm } from './components';

class VideoDetail extends React.Component {
  state = {
    errors: null,
    video: null
  };

  fetchVideo = async () => {
    const { videoId } = this.props.match.params;

    const { data, errors, success } = await videoDetail(videoId);

    if (success) {
      this.setState({
        video: data
      });
    } else {
      this.setState({ errors });
    }
  };

  componentDidMount() {
    this.fetchVideo();
  }

  render() {
    const { user, crumbs } = this.props;

    const { video } = this.state;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Flex spaceBetween>
                <Header as="h1">Video Detail</Header>
              </Flex>
              {!_.isNil(video) && (
                <VideoUpdateForm data={video} fetchVideo={this.fetchVideo} />
              )}
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(withRouter(VideoDetail));
