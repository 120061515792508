import React from 'react';
import _ from 'lodash';

const ClientBreadcrumb = ({ client }) => {
  return (
    client && (
      <span>
        {' '}
        {client.id} {client.first_name} {client.last_name}
        {!_.isEmpty(client.client_profile.company_name) &&
          ` (${client.client_profile.company_name})`}
      </span>
    )
  );
};

export default ClientBreadcrumb;
