import React from 'react';
import _ from 'lodash';

import { withRouter } from 'react-router-dom';

import { Table, Checkbox } from 'semantic-ui-react';

import { Formik, FieldArray } from 'formik';

import { UpdateButtonInRow } from 'components';

import { notifyErrors, notifySuccess, notifyError } from 'utils/notifications';

import { prepareFormData } from 'utils/forms';

import PipelineItemForm from './PipelineItemForm';
import YearMonthForm from './YearMonthForm';

import {
  areAllItemsSelected,
  preparePipelineItems,
  prepareParams,
  pipelineItemsSchema
} from './utils';

import { clientPipelineItems, updatePipelineItems } from './sdk';

class PipelineItems extends React.Component {
  state = {
    pipelineItems: [],
    selectedItems: [],
    pipelineParams: {}
  };

  componentDidMount() {
    const { initialPipelineItems, pipelineParams } = this.props;
    this.setState({
      pipelineItems: preparePipelineItems(
        this.props.products,
        initialPipelineItems
      ),
      pipelineParams: pipelineParams
    });
  }

  fetchPipelines = async params => {
    this.setState({ pipelineParams: params });

    const { client, products } = this.props;

    const preparedParams = prepareFormData(params);

    const { data, errors, success } = await clientPipelineItems(
      client.id,
      preparedParams
    );

    if (success) {
      const pipelineItems = preparePipelineItems(products, data);

      let pipelineParams = this.state.pipelineParams;

      if (params.defaultOnEmpty && !_.isEmpty(data)) {
        pipelineParams = prepareParams(data);
      }
      this.props.updatePipelineItems(data, pipelineParams);

      this.setState({ pipelineItems, refs: [], pipelineParams });
    } else {
      notifyErrors(errors);
    }
  };

  handleSubmit = async (values, actions) => {
    let hasForecastedValue = true;

    _.forEach(_.filter(values.pipelineItems, { inDashboard: true }), item => {
      if (
        (_.isNil(item.forecastedValueEur) || item.forecastedValueEur === 0) &&
        (_.isNil(item.forecastedValueGbp) || item.forecastedValueGbp === 0) &&
        (_.isNil(item.forecastedValueUsd) || item.forecastedValueUsd === 0)
      ) {
        hasForecastedValue = false;
        notifyError(`${item.title} has nо forecasted value.`);
      }
    });

    const { setSubmitting } = actions;

    if (!hasForecastedValue) {
      setSubmitting(false);
      return;
    }

    const postPipelineItems = _.map(values.pipelineItems, item => {
      if (item.certainty === '') {
        item.certainty = null;
      }

      return {
        ...prepareFormData(item)
      };
    });

    const postData = {
      pipeline_items: postPipelineItems
    };

    setSubmitting(true);

    const { errors, success } = await updatePipelineItems(
      this.props.client.id,
      postData
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Successfully updated.');
      const params = {
        ...this.state.pipelineParams,
        defaultOnEmpty: true
      };

      this.fetchPipelines(params);
    } else {
      notifyErrors(errors);
    }
  };

  render() {
    const { selectedItems, pipelineItems, pipelineParams } = this.state;

    const initialValues = { pipelineItems };

    return (
      <>
        <YearMonthForm
          initialValues={pipelineParams}
          submit={data => this.fetchPipelines(data)}
        />
        {!_.isEmpty(pipelineItems) ? (
          <Table celled selectable compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ textAlign: 'center' }}>
                  <Checkbox
                    onChange={(event, data) => this.toggleSelectAll(data)}
                    checked={areAllItemsSelected(pipelineItems, selectedItems)}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Pipeline Engagement</Table.HeaderCell>
                <Table.HeaderCell>Month expected</Table.HeaderCell>
                <Table.HeaderCell>Forecasted value(USD)</Table.HeaderCell>
                <Table.HeaderCell>Forecasted value(GBP)</Table.HeaderCell>
                <Table.HeaderCell>Forecasted value(EUR)</Table.HeaderCell>
                <Table.HeaderCell>Certainty (%)</Table.HeaderCell>
                <Table.HeaderCell>Notes</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={this.handleSubmit}
                validationSchema={pipelineItemsSchema}>
                {({ handleSubmit, isSubmitting, values }) => (
                  <>
                    <FieldArray
                      name="pipelineItems"
                      render={arrayHelpers =>
                        values.pipelineItems.map((item, index) => (
                          <PipelineItemForm
                            key={item.key}
                            index={index}
                            item={item}
                            pipelineItems={values.pipelineItems}
                            fetchItems={() => {
                              this.fetchPipelines({ ...pipelineParams });
                            }}
                          />
                        ))
                      }
                    />
                    <Table.Row>
                      <Table.Cell colSpan="9" textAlign="right">
                        <UpdateButtonInRow
                          onClick={handleSubmit}
                          disabled={isSubmitting}
                          label="Save"
                        />
                      </Table.Cell>
                    </Table.Row>
                  </>
                )}
              </Formik>
            </Table.Body>
          </Table>
        ) : (
          'There are no pipeline items.'
        )}
      </>
    );
  }
}

export default withRouter(PipelineItems);
