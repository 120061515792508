import React from 'react';

import _ from 'lodash';

import { withRouter } from 'react-router-dom';

import { Header, Segment, Button } from 'semantic-ui-react';

import {
  Flex,
  Page,
  LoginRequired,
  Error,
  SendAndPreview,
  NewsletterLiveValues,
  Loader,
  ContentEditionWarning
} from 'components';
import { notifyErrors } from 'utils/notifications';

import { EDITION_WARNING_CONTENT_TYPES } from 'domainConstants';

import {
  DisableableElement,
  hasPermission,
  PERMISSIONS,
  PermissionsRequired
} from 'permissions';

import { pageUrl } from 'config/routes';
import { PermanentPricesDetail } from 'pages';
import { areAllArticlesConnected } from 'utils/newsletter';

import {
  dailyNewsletterDetail,
  dailyNewsletterDetailPrices,
  articleReadyForPublicationList
} from './sdk';

import {
  DailyNewsletterUpdateForm,
  ArticleTable,
  Sections,
  DownloadPdfForm
} from './components';

import styles from './styles.module.css';

class DailyNewsletterDetail extends React.Component {
  state = {
    errors: null,
    dailyNewsletter: null,
    dailyNewsletterPrices: null,
    articles: null,
    hasArticlesWithoutSection: false
  };

  fetchDailyNewsletterPrices = async () => {
    const { dailyNewsletterId } = this.props.match.params;

    const { data, errors, success } = await dailyNewsletterDetailPrices(
      dailyNewsletterId
    );

    if (success) {
      this.setState({ dailyNewsletterPrices: data });
    } else {
      notifyErrors(errors);
    }
  };

  fetchDailyNewsletter = async () => {
    const { dailyNewsletterId } = this.props.match.params;

    const { data, errors, success } = await dailyNewsletterDetail(
      dailyNewsletterId
    );

    if (success) {
      const hasArticlesWithoutSection = !areAllArticlesConnected({
        articles: this.state.articles,
        newsletter: data
      });

      this.setState({ dailyNewsletter: data, hasArticlesWithoutSection });
    } else {
      this.setState({ errors });
      notifyErrors(errors);
    }
  };

  fetchArticles = async () => {
    const { data, success } = await articleReadyForPublicationList();

    if (success) {
      this.setState({ articles: data });
    }
  };

  componentDidMount() {
    this.fetchDailyNewsletter();
    this.fetchArticles();
    this.fetchDailyNewsletterPrices();
  }

  openPermanentPrices = () => {
    this.props.history.push(pageUrl(PermanentPricesDetail));
  };

  render() {
    const { user, crumbs } = this.props;

    const {
      dailyNewsletter,
      dailyNewsletterPrices,
      articles,
      errors,
      hasArticlesWithoutSection
    } = this.state;

    const isLive = _.get(dailyNewsletter, 'live', false);
    const sendingStatus = _.get(dailyNewsletter, 'sending_status', '');
    const unpublishedPriceSeries = _.get(
      dailyNewsletter,
      'unpublished_price_series',
      ''
    );

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content maxWidth crumbs={crumbs}>
            {!_.isNil(dailyNewsletter) && (
              <ContentEditionWarning
                user={user}
                contentType={
                  EDITION_WARNING_CONTENT_TYPES.DAILY_STEEL_NEWSLETTER
                }
                contentId={dailyNewsletter.id}
              />
            )}

            <Flex spaceBetween>
              <Header as="h1">Edit daily newsletter</Header>
              <Flex>
                <Button onClick={this.openPermanentPrices}>
                  View Permanent Prices
                </Button>
                <Segment>
                  <p>
                    Sending status: <strong>{sendingStatus}</strong>
                  </p>
                </Segment>
              </Flex>
            </Flex>
            {errors &&
              errors.map((error, index) => (
                <Error key={index}>{error.message}</Error>
              ))}

            {!(dailyNewsletter && articles) && (
              <Segment>
                <Loader />
              </Segment>
            )}

            {dailyNewsletter && articles && (
              <DisableableElement
                disabled={
                  !hasPermission(user, [PERMISSIONS.CHANGE_NEWSLETTERS])
                }>
                <div className={styles.Split}>
                  <div>
                    {isLive && (
                      <NewsletterLiveValues newsletter={dailyNewsletter} />
                    )}
                    {!isLive && (
                      <DailyNewsletterUpdateForm
                        hasArticlesWithoutSection={hasArticlesWithoutSection}
                        dailyNewsletter={dailyNewsletter}
                        fetchDailyNewsletter={this.fetchDailyNewsletter}
                        fetchDailyNewsletterPrices={
                          this.fetchDailyNewsletterPrices
                        }
                      />
                    )}
                    <SendAndPreview
                      newsletter={dailyNewsletter}
                      type="daily"
                      user={user}
                    />
                    <DownloadPdfForm newsletter={dailyNewsletter} />
                  </div>
                  <div>
                    {!isLive && (
                      <>
                        {hasArticlesWithoutSection && (
                          <Segment color="red" inverted>
                            There are unassigned articles.
                          </Segment>
                        )}

                        {!_.isEmpty(unpublishedPriceSeries) && (
                          <Segment color="red" inverted>
                            The following price series: '
                            {unpublishedPriceSeries}' are not published.
                          </Segment>
                        )}

                        <ArticleTable
                          dailyNewsletter={dailyNewsletter}
                          articles={articles}
                          fetchDailyNewsletter={this.fetchDailyNewsletter}
                        />
                      </>
                    )}
                    <Sections
                      dailyNewsletter={dailyNewsletter}
                      dailyNewsletterPrices={dailyNewsletterPrices}
                      articles={articles}
                      fetchDailyNewsletter={this.fetchDailyNewsletter}
                      fetchDailyNewsletterPrices={
                        this.fetchDailyNewsletterPrices
                      }
                    />
                  </div>
                </div>
              </DisableableElement>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(
  PermissionsRequired([PERMISSIONS.VIEW_NEWSLETTERS])(
    withRouter(DailyNewsletterDetail)
  )
);
