import React from 'react';

import { Field, SelectField } from 'components';
const EVENT_TYPES = ['Conference', 'Networking', 'Webinar', 'Training'];

class EventTypeSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = EVENT_TYPES.map(type => {
      return {
        key: type,
        value: type,
        label: type
      };
    });

    return (
      <Field
        name={name || 'eventType'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default EventTypeSelect;
