import React from 'react';

import { Button as SemanticButton } from 'semantic-ui-react';

const GREEN_BUTTON = {
  color: 'white',
  backgroundColor: '#24d6a3'
};

const RED_BUTTON = {
  color: 'white',
  backgroundColor: '#ff4861'
};

const BLUE_BUTTON = {
  color: 'white',
  backgroundColor: '#70bbfd'
};

const ORANGE_BUTTON = {
  color: 'white',
  backgroundColor: '#ffa500'
};

const GREY_BUTTON = {
  color: '#000000CC',
  backgroundColor: '#e0e1e2'
};

const getBtnColor = color => {
  switch (color) {
    case 'green':
      return GREEN_BUTTON;
    case 'red':
      return RED_BUTTON;

    case 'blue':
      return BLUE_BUTTON;

    case 'orange':
      return ORANGE_BUTTON;

    case 'grey':
      return GREY_BUTTON;

    default:
      return GREEN_BUTTON;
  }
};

const Button = ({ children, capital, color, style, ...props }) => {
  const btnColors = getBtnColor(color);

  return (
    <SemanticButton
      style={{
        textTransform: capital && 'uppercase',
        ...btnColors,
        ...style
      }}
      {...props}>
      {children}
    </SemanticButton>
  );
};

export default Button;
