import React from 'react';
import { withRouter } from 'react-router-dom';

import {
  Page,
  Flex,
  Header,
  Button,
  LoginRequired,
  ListViewEssentials
} from 'components';
import { PermissionsRequired, PERMISSIONS, VisibleIf } from 'permissions';
import moment from 'moment';

import { оnlineUsersList } from './sdk';

import { OnlineUsersTable } from './components';

import { notifySuccess } from 'utils/notifications';

class OnlineUsersList extends React.Component {
  state = {
    data: [],
    isDataLoaded: false,
    isRefreshing: false,
    lastRefreshedTimestamp: null
  };

  componentDidMount() {
    this.fetchData();
    this.getCurrentTime();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data && !this.state.isRefreshing) {
      this.fetchData();
    }
  }

  getCurrentTime = () => {
    const lastRefreshedTimestamp = moment().format('YYYY-MM-DD HH:mm:ss');
    this.setState({ lastRefreshedTimestamp: lastRefreshedTimestamp });
  };

  handleRefreshClick = () => {
    this.setState({ isDataLoaded: true });
    this.fetchData();
  };

  fetchData = async () => {
    this.setState({ isRefreshing: true });
    const { data, success } = await оnlineUsersList();
    if (success) {
      this.setState({ data: data, isRefreshing: false });
      if (this.state.isDataLoaded) {
        this.getCurrentTime();
        notifySuccess('Refreshed successfully');
        this.setState({ isDataLoaded: false });
      }
    }
  };

  render() {
    const { user, sort, orderBy } = this.props;
    const { data, lastRefreshedTimestamp } = this.state;
    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content>
            <Flex spaceBetween style={{ flexWrap: 'wrap' }}>
              <Header>Who's online</Header>
              <VisibleIf
                user={this.props.user}
                permissions={[PERMISSIONS.CREATE_STAFF]}>
                <Flex style={{ gap: '15px', alignItems: 'baseline' }}>
                  <Header as="h4">
                    Last Refreshed: {lastRefreshedTimestamp}
                  </Header>
                  <Button size="large" onClick={this.handleRefreshClick}>
                    Refresh
                  </Button>
                </Flex>
              </VisibleIf>
            </Flex>
            {data && (
              <OnlineUsersTable
                onlineUsers={data}
                sort={sort}
                orderBy={orderBy}
              />
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: оnlineUsersList,
  limit: null
})(
  LoginRequired(
    PermissionsRequired([PERMISSIONS.VIEW_STAFF])(withRouter(OnlineUsersList))
  )
);
