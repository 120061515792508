import React from 'react';

import { withRouter } from 'react-router-dom';

import { Form, Header, Segment } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  InputField,
  FormRow,
  UpdateButtonInRow,
  UnsavedFormPrompt,
  GlossaryCategorySelect,
  FileField,
  CheckboxField,
  GlossaryTermsSelect,
  RichTextAreaField,
  CreatedUpdatedFormValues,
  DeleteModal
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { glossaryTermUpdateSchema } from './utils';
import { glossaryTermUpdate } from './sdk';
import { glossaryTermDelete } from '../../../sdk';
import { GlossaryTermList } from 'pages';
class GlossaryTermUpdateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { glossaryTerm } = this.props;

    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await glossaryTermUpdate(glossaryTerm.id, data);

    setSubmitting(false);

    if (success) {
      notifySuccess('Glossary Term updated.');
      this.props.fetchGlossaryTerm();
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  render() {
    const { glossaryTerm } = this.props;

    const {
      created_at: createdAt,
      created_by: createdBy,
      updated_at: updatedAt,
      updated_by: updatedBy
    } = glossaryTerm;

    const initialValues = {
      title: glossaryTerm.title,
      slug: glossaryTerm.slug,
      isPublished: glossaryTerm.is_published,
      image: glossaryTerm.image,
      category: glossaryTerm.category,
      text: glossaryTerm.text,
      relatedTerms: glossaryTerm.related_terms
    };

    const nonFormValues = { createdAt, createdBy, updatedAt, updatedBy };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={glossaryTermUpdateSchema}
        onSubmit={this.handleSubmit}>
        {({ values, handleSubmit, isSubmitting, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty} />
            <UpdateButtonInRow
              subject="Glossary Term"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
            <FormRow>
              <Field
                width={6}
                name="title"
                label="Title"
                required
                component={InputField}
              />
              <Field
                width={6}
                name="slug"
                label="Slug"
                required
                component={InputField}
              />
              <Field
                width={4}
                name="isPublished"
                label="Is published"
                component={CheckboxField}
              />
            </FormRow>
            <FormRow>
              <Field
                width={4}
                name="image"
                label="Image"
                component={FileField}
              />
              <GlossaryCategorySelect
                width={6}
                name="category"
                label="Glossary Category"
              />
            </FormRow>
            <FormRow>
              <Field
                width={12}
                component={RichTextAreaField}
                label="Text"
                name="text"
              />
            </FormRow>
            <FormRow>
              <GlossaryTermsSelect
                width={8}
                name="relatedTerms"
                label="Related Terms"
                excludeOptions={[glossaryTerm.id]}
              />
            </FormRow>
            <CreatedUpdatedFormValues values={nonFormValues} />
            <UpdateButtonInRow
              subject="Glossary Term"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
            <Segment color="red">
              <Header as="h3">Dangerous Actions</Header>
              <DeleteModal
                itemId={glossaryTerm.id}
                objName="Glossary Term"
                deleteButtonText="Delete Glossary Term"
                objDetails={`${glossaryTerm.title} with ID: ${glossaryTerm.id}`}
                sdk={glossaryTermDelete}
                successRedirect={GlossaryTermList}
              />
            </Segment>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(GlossaryTermUpdateForm);
