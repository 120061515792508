import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const registrationAttemptDetail = async id => {
  const url = `${BASE_URL}/auth/registration-attempts/${id}/`;

  return await requestSdk(() => get(url));
};

export const createClientWithRegistrationAttempt = async (id, data) => {
  const url = `${BASE_URL}/auth/registration-attempts/${id}/finish/`;

  return await requestSdk(() => post(url, data));
};

export const clientCreate = async data => {
  const url = `${BASE_URL}/crm/clients/create/`;

  return await requestSdk(() => post(url, data));
};
