import React from 'react';
import _ from 'lodash';

import { Table, Icon } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import { notifySuccess, notifyErrors } from 'utils/notifications';

import { Button, YesNo } from 'components';

import { PriceSeriesDetail } from 'pages';

import { pageUrl } from 'config/routes';

class PriceRow extends React.Component {
  deletePrice = async () => {
    const { success, errors } = await this.props.priceRemoveSdk(
      this.props.price.id
    );
    if (success) {
      notifySuccess('Price removed.');
      this.props.fetchPermanentPrices();
      return;
    } else {
      notifyErrors(errors);
    }
  };

  render() {
    const { price } = this.props;

    return (
      <Table.Row>
        <Table.Cell textAlign="center">{price.order + 1}</Table.Cell>
        <Table.Cell>
          <Link
            to={pageUrl(PriceSeriesDetail, {
              priceSeriesId: _.get(price, 'price.id')
            })}>
            {_.get(price, 'price.title')}
          </Link>
        </Table.Cell>
        <Table.Cell>
          <YesNo value={price.displayed} />
        </Table.Cell>
        <Table.Cell width={1} textAlign="center">
          <Button color="red" onClick={this.deletePrice} icon>
            <Icon name="trash" />
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default PriceRow;
