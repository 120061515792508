import React from 'react';

import { Field, SelectField } from 'components';
import { relatedProductList } from './sdk';

class RelatedProductsSelect extends React.Component {
  state = {
    relatedProducts: []
  };
  async componentDidMount() {
    const { data, success } = await relatedProductList();

    if (success) {
      this.setState({ relatedProducts: data });
    }
  }

  render() {
    const { name, ...rest } = this.props;
    const { relatedProducts } = this.state;

    const options = relatedProducts.map(relatedProduct => {
      return {
        key: relatedProduct.id,
        value: relatedProduct.id,
        label: relatedProduct.title
      };
    });

    return (
      <Field
        name={name || 'relatedProduct'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default RelatedProductsSelect;
