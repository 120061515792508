import React from 'react';

import { withRouter } from 'react-router-dom';

import {
  Page,
  Header,
  Flex,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Button
} from 'components';

import { pageUrl } from 'config/routes';

import { BannerCreate } from 'pages';

import { bannerList } from './sdk';

import { BannerTable } from './components';

class BannerList extends React.Component {
  createBanner = () => {
    const location = pageUrl(BannerCreate);

    this.props.history.push(location);
  };

  render() {
    const {
      user,
      data,
      limit,
      count,
      page,
      changePage,
      changeRowsPerPage,
      orderBy,
      sort
    } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content maxWidth>
            <Flex spaceBetween>
              <Header>Banners</Header>
              <Button size="large" onClick={this.createBanner}>
                Create Banner
              </Button>
            </Flex>
            {data && (
              <>
                <BannerTable
                  orderBy={orderBy}
                  sort={sort}
                  banners={data}
                  pagination={
                    <Pagination
                      limit={limit}
                      count={count}
                      page={page}
                      changePage={changePage}
                      changeRowsPerPage={changeRowsPerPage}
                    />
                  }
                />
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: bannerList
})(LoginRequired(withRouter(BannerList)));
