import _ from 'lodash';
import React from 'react';

import { Segment, Header, Form, Table, Button } from 'semantic-ui-react';

import { Formik } from 'formik';

import { InvoiceItemSelect, PromoCodeSelect } from 'components';

import { invoiceItemSchema } from './utils';

import { applyInvoiceItemDiscount } from './sdk';

import { notifySuccess } from 'utils/notifications';

class PromoCodeApplicationModal extends React.Component {
  state = {
    invoiceItemId: null,
    promoCode: null
  };

  handleInvoiceItemChange = invoiceItem => {
    if (invoiceItem !== null) {
      this.setState({ invoiceItemId: invoiceItem.target.key });
    }
  };

  handlePromoCodeChange = promoCOde => {
    if (promoCOde !== null) {
      this.setState({ promoCode: promoCOde.target });
    }
  };

  applyDiscountForInvoiceItem = async () => {
    const { fetchInvoice } = this.props;
    const data = {
      promo_code: this.state.promoCode.pk
    };

    const { success } = await applyInvoiceItemDiscount(
      this.state.invoiceItemId,
      data
    );

    if (success) {
      notifySuccess(`Promocode applied`);
      fetchInvoice();
      this.props.onClose();
    }
  };

  render() {
    // TODO: Check Selects for state items
    const invoiceId = this.props.invoiceId;
    return (
      <Segment>
        <Header as="h2">Select Invoice Item</Header>
        <Formik
          onSubmit={this.handleSubmit}
          validationSchema={invoiceItemSchema}>
          {({ isSubmitting, onSubmit }) => (
            <Form>
              <InvoiceItemSelect
                onChange={this.handleInvoiceItemChange}
                width={6}
                invoiceId={invoiceId}
                name="item"
                label="Invoice Items"
              />
              {this.state.invoiceItemId && (
                <Header as="h2">Select Promo Code</Header>
              )}
              {this.state.invoiceItemId && (
                <PromoCodeSelect
                  onChange={this.handlePromoCodeChange}
                  width={6}
                  itemId={this.state.invoiceItemId}
                  name="code"
                  label="Promo Codes"
                />
              )}
              {this.state.promoCode && (
                <Header as="h2">Discount Information</Header>
              )}
              {this.state.promoCode && (
                <Table definition collapsing>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Code</Table.Cell>
                      <Table.Cell>
                        {_.get(this.state.promoCode, 'label')}{' '}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Discount Type</Table.Cell>
                      <Table.Cell>
                        {_.get(this.state.promoCode, 'discountType')}{' '}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Currency</Table.Cell>
                      <Table.Cell>
                        {_.get(this.state.promoCode, 'currency')}{' '}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Amount</Table.Cell>
                      <Table.Cell>
                        {_.get(this.state.promoCode, 'amount')}{' '}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              )}
              {this.state.promoCode && (
                <Form.Field width={4}>
                  <Button
                    fluid
                    type="submit"
                    onClick={this.applyDiscountForInvoiceItem}
                    color="blue"
                    disabled={isSubmitting}>
                    Apply Discount
                  </Button>
                </Form.Field>
              )}
            </Form>
          )}
        </Formik>
      </Segment>
    );
  }
}
export default PromoCodeApplicationModal;
