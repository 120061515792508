import React from 'react';
import _ from 'lodash';
import { FastField as Field, TransferListField } from 'components';

import { priceSeriesProductList } from './sdk';

class PriceSeriesProductSelect extends React.Component {
  state = {
    priceSeriesProducts: []
  };

  async componentDidMount() {
    const { isPowerMaterial } = this.props;
    let params = {};

    if (isPowerMaterial) {
      params = { is_power_material_related: isPowerMaterial };
    }
    const { data, success } = await priceSeriesProductList(params);

    if (success) {
      this.setState({
        priceSeriesProducts: data.map(priceSeriesProduct => {
          return {
            key: priceSeriesProduct.id,
            value: priceSeriesProduct.id,
            label: priceSeriesProduct.title
          };
        })
      });
    }
  }

  render() {
    const { name, isCustom, ...rest } = this.props;
    const { priceSeriesProducts } = this.state;

    const options = priceSeriesProducts;

    if (!_.isEmpty(options)) {
      rest['options'] = options;
    }

    return (
      <Field
        name={name || 'priceSeriesProduct'}
        component={TransferListField}
        {...rest}
      />
    );
  }
}

export default PriceSeriesProductSelect;
