import { BASE_URL } from 'sdk/urls';
import { post, requestSdk } from 'sdk';

export const removeClientFromCorporateMasterAccount = async (
  clientId,
  data
) => {
  const url = `${BASE_URL}/crm/clients/${clientId}/cm-accounts/remove/`;

  return await requestSdk(() => post(url, data));
};
