import React from 'react';
import _ from 'lodash';
import { Field, SelectField } from 'components';

const currentYear = new Date().getFullYear();

const YEARS_OPTIONS = _.range(currentYear - 10, currentYear + 10);

class YearSelect extends React.Component {
  render() {
    const {
      name,
      excludeOptions = null,
      customOptions = null,
      isClearable = true,
      ...rest
    } = this.props;

    let yearOptions = YEARS_OPTIONS;

    if (!_.isNil(customOptions)) {
      yearOptions = customOptions;
    }

    if (excludeOptions) {
      yearOptions = yearOptions.filter(
        option => _.indexOf(excludeOptions, option.value) < 0
      );
    }

    const options = yearOptions.map(item => {
      return {
        key: item,
        value: item,
        label: item
      };
    });

    return (
      <Field
        name={name || 'year'}
        component={SelectField}
        options={options}
        isClearable={isClearable}
        {...rest}
      />
    );
  }
}

export default YearSelect;
