import React from 'react';

import { Formik } from 'formik';

import { Form, Segment, Header } from 'semantic-ui-react';

import { handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import {
  Field,
  FormRow,
  RichTextAreaField,
  UpdateButtonInRow,
  VideoSelect,
  InputField,
  EventSelect,
  ActiveEventTransferSelect,
  PastEventTransferSelect
} from 'components';

import { isIndexPage } from '../../utils';

class ContentForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    setSubmitting(true);

    const { errors, success } = await this.props.pageUpdate(
      this.props.page.id,
      values
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Page Content updated successfully!');
      this.props.fetchPage();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const { page } = this.props;
    const {
      custom_html,
      videos,
      training_main_course,
      training_courses
    } = page;

    const isIndex = isIndexPage(page.identifier);

    let trainingCoursesInitial = null;

    if (isIndex) {
      trainingCoursesInitial = {
        ...training_courses,
        active_courses: training_courses.active_courses.map(
          course => course.id
        ),
        past_courses: training_courses.past_courses.map(course => course.id)
      };
    }

    const initialValues = {
      custom_html,
      videos,
      training_main_course,
      training_courses: trainingCoursesInitial
    };

    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        enableReinitialize={true}>
        {({ handleSubmit, isSubmitting }) => {
          return (
            <Form>
              {!isIndex && (
                <Segment>
                  <FormRow>
                    <Field
                      name="custom_html"
                      component={RichTextAreaField}
                      label="Custom HTML"
                    />
                  </FormRow>
                </Segment>
              )}

              {isIndex && (
                <>
                  <Segment>
                    <Header as="h4">Videos</Header>
                    <FormRow>
                      <Field
                        name="videos"
                        label="Videos"
                        component={VideoSelect}
                        enableOrdering
                      />
                    </FormRow>
                  </Segment>
                  <Segment>
                    <Header as="h4">Training Main Course</Header>
                    <FormRow>
                      <Field
                        name="training_main_course.title"
                        label="Title"
                        component={InputField}
                      />
                    </FormRow>
                    <FormRow>
                      <Field
                        name="training_main_course.main_course"
                        label="Main Course"
                        component={EventSelect}
                        enableOrdering
                      />
                    </FormRow>
                  </Segment>
                  <Segment>
                    <Header as="h4">Training Courses</Header>
                    <FormRow>
                      <Field
                        name="training_courses.title"
                        label="Title"
                        component={InputField}
                      />
                      <Field
                        name="training_courses.active_courses_title"
                        label="Active Courses Title"
                        component={InputField}
                      />
                    </FormRow>
                    <FormRow>
                      <Field
                        name="training_courses.active_courses"
                        label="Active Training Courses"
                        component={ActiveEventTransferSelect}
                        currentOptions={training_courses.active_courses}
                        enableOrdering
                      />
                    </FormRow>
                    <FormRow>
                      <Field
                        name="training_courses.past_courses_title"
                        label="Past Courses Title"
                        component={InputField}
                      />
                    </FormRow>
                    <FormRow>
                      <Field
                        name="training_courses.past_courses"
                        label="Past Training Courses"
                        component={PastEventTransferSelect}
                        currentOptions={training_courses.past_courses}
                        enableOrdering
                      />
                    </FormRow>
                  </Segment>
                </>
              )}

              <UpdateButtonInRow
                subject="Page Content"
                onClick={handleSubmit}
                disabled={isSubmitting}
              />
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default ContentForm;
