import _ from 'lodash';
import * as yup from 'yup';

export const isSelected = (productId, selectedProducts) => {
  return !!_.find(selectedProducts, product => product.id === productId);
};

export const productValidationSchema = yup.object().shape({
  dateStart: yup
    .string()
    .nullable()
    .required('Scheduled date is required.')
});
