import React from 'react';
import cx from 'classnames';
import _ from 'lodash';
import File from 'react-dropzone';
import { Icon, Button, Segment } from 'semantic-ui-react';

import { Error, Flex } from 'components';
import { BASE_URL } from 'sdk/urls';
import { handleFileRejections } from 'utils/files';

import { FilerSelectModal } from './components';
import { fileUpload } from './sdk';
import styles from './styles.module.css';

class FileField extends React.Component {
  state = {
    filerSelectModal: false
  };

  onDrop = async (acceptedFiles, fileRejections) => {
    const { field, form, disabled = false, accept } = this.props;

    handleFileRejections(fileRejections, accept);

    if (disabled || !acceptedFiles[0]) return;

    const { success, data, errors } = await fileUpload(acceptedFiles[0]);

    if (success) {
      if (this.props.onFileAttach) {
        this.props.onFileAttach(data.id).then(success => {
          if (success) {
            form.setFieldValue(field.name, data.id);
          } else {
            form.setFieldValue(field.name, null);
          }
        });
        return;
      }

      form.setFieldValue(field.name, data.id);
    } else {
      const errorMessage = errors.map(error => error.message).join(', ');
      form.setFieldError(field.name, errorMessage);
    }
  };

  removeFile = () => {
    const { field, form } = this.props;
    if (this.props.onFileDelete) {
      this.props.onFileDelete();
    }

    form.setFieldValue(field.name, null);
  };

  buildPreviewUrl = value => {
    const url = `${BASE_URL}/common/files/${value}/preview/`;
    return url;
  };

  openSelectModal = () => {
    this.setState({ filerSelectModal: true });
  };

  closeSelectModal = () => {
    this.setState({ filerSelectModal: false });
  };

  onFileSelect = fileId => {
    const { field, form } = this.props;
    if (this.props.onFileAttach) {
      this.props.onFileAttach(fileId).then(success => {
        if (success) {
          form.setFieldValue(field.name, fileId);
        } else {
          form.setFieldValue(field.name, null);
        }
      });
      return;
    }
    form.setFieldValue(field.name, fileId);
  };

  render() {
    const { filerSelectModal } = this.state;

    const {
      multiple,
      form,
      field,
      placeholder,
      label,
      small = false,
      maxSize,
      accept
    } = this.props;

    const { value } = field;

    const hasErrors =
      _.get(form.errors, field.name) && _.get(form.touched, field.name);

    return (
      <div className={styles.Container}>
        {label && <label>{label}</label>}
        {_.isNil(value) && (
          <Icon
            size="large"
            link
            color="blue"
            name="search"
            onClick={this.openSelectModal}
            className={styles.uploadIcon}
          />
        )}
        {!_.isNil(value) && (
          <>
            <Segment
              className={cx(styles.segment, {
                [styles.small]: small
              })}>
              <span className={styles.links}>
                <a
                  className={styles.previewLink}
                  target="_blank"
                  href={this.buildPreviewUrl(value)}
                  rel="noopener noreferrer">
                  <Icon
                    name="file pdf"
                    size={'large'}
                    className={
                      (styles.previewIcon, { [styles.smallPreviewIcon]: small })
                    }
                  />
                  Preview
                </a>
                <span className={styles.removeLink} onClick={this.removeFile}>
                  <Icon color="red" name="delete" size={'large'} />
                </span>
              </span>
              <Flex>
                <File
                  onDrop={this.onDrop}
                  multiple={multiple}
                  {...(maxSize && { maxSize: maxSize })}
                  {...(accept && { accept: accept })}>
                  {({ getRootProps, getInputProps }) => (
                    <span className={styles.uploadFile} {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Button
                        color="grey"
                        basic
                        className={cx(styles.uploadBtn, {
                          [styles.smallUploadBtn]: small
                        })}>
                        <Icon name="upload" color="grey" />
                        {!small && 'Upload'}
                      </Button>
                    </span>
                  )}
                </File>
                <Icon link name="search" onClick={this.openSelectModal} />
              </Flex>
            </Segment>
          </>
        )}
        {_.isNil(value) && (
          <File
            onDrop={this.onDrop}
            multiple={multiple}
            {...(maxSize && { maxSize: maxSize })}
            {...(accept && { accept: accept })}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div
                    className={cx(styles.File, {
                      [styles.hasErrors]: hasErrors
                    })}>
                    <Icon name="upload" color="grey" size="big" />
                    <div>{placeholder || 'Drag & drop file here'}</div>
                  </div>
                </div>
              </section>
            )}
          </File>
        )}
        <div>
          {hasErrors && <Error>{_.get(form.errors, field.name)}</Error>}
        </div>
        {filerSelectModal && (
          <FilerSelectModal
            onClose={this.closeSelectModal}
            onFileSelect={this.onFileSelect}
          />
        )}
      </div>
    );
  }
}

export default FileField;
