import React from 'react';

import { Button as SemanticButton } from 'semantic-ui-react';

import { Modal } from 'components';

const OpenModalButtonControlled = ({
  children,
  color,
  modal,
  toggleModal,
  isModalOpen,
  ...rest
}) => {
  return (
    <>
      <SemanticButton
        size="large"
        color={color || 'blue'}
        onClick={toggleModal}
        {...rest}>
        {children}
      </SemanticButton>
      {isModalOpen && (
        <Modal header={modal.header} onClose={toggleModal} {...modal.props}>
          {modal.render({ onClose: toggleModal })}
        </Modal>
      )}
    </>
  );
};

export default OpenModalButtonControlled;
