import React from 'react';

import { Header, Icon, Modal, Table } from 'semantic-ui-react';

import { Flex, NonFormValuesDisplay } from 'components';

import PresaleGroupRateForm from '../PresaleGroupRateForm';
import { formatDate } from 'utils';

const PresaleGroupRateModal = ({
  presale,
  course,
  currencies,
  groupRates,
  fetchGroupRates,
  onClose
}) => {
  const dateItems = [
    {
      label: 'Start date',
      value: formatDate(presale.start_date)
    },
    {
      label: 'End date',
      value: formatDate(presale.end_date)
    }
  ];

  return (
    <Modal open={true} size="large" centered={false}>
      <Modal.Header>
        <Flex spaceBetween>
          <Header as="h2" style={{ margin: 0 }}>
            Manage Group Rates for Presale {presale.title}
          </Header>
          <Icon name="close" onClick={onClose} />
        </Flex>
      </Modal.Header>
      <Modal.Content>
        <NonFormValuesDisplay items={dateItems}></NonFormValuesDisplay>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Delegates</Table.HeaderCell>
              {currencies.map(currency => (
                <Table.HeaderCell key={currency.id}>
                  Price in {currency.code}
                </Table.HeaderCell>
              ))}
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {groupRates.map(groupRate => (
              <PresaleGroupRateForm
                key={groupRate.id}
                groupRate={groupRate}
                fetchGroupRates={fetchGroupRates}
                course={course}
                presale={presale}
                currencies={currencies}
              />
            ))}
            <PresaleGroupRateForm
              groupRate={null}
              fetchGroupRates={fetchGroupRates}
              course={course}
              presale={presale}
              currencies={currencies}
            />
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>
  );
};

export default PresaleGroupRateModal;
