import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const courseAddPresale = async (courseId, data) => {
  const url = `${BASE_URL}/courses/${courseId}/add-presale/`;

  return await requestSdk(() => post(url, data));
};

export const courseUpdatePresale = async (courseId, data) => {
  const url = `${BASE_URL}/courses/${courseId}/update-presale/`;

  return await requestSdk(() => post(url, data));
};

export const courseRemovePresale = async (courseId, data) => {
  const url = `${BASE_URL}/courses/${courseId}/remove-presale/`;

  return await requestSdk(() => post(url, data));
};
