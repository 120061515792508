import React, { useCallback } from 'react';

import { Formik } from 'formik';
import { Form } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { MailshotGroupDetail } from 'pages';
import { FROM_EMAIL_OPTIONS } from 'pages/MailshotGroup/constants';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import {
  Field,
  InputField,
  FormRow,
  CreateButtonInRow,
  SelectField,
  UnsavedFormPrompt
} from 'components';

import { mailshotGroupCreate } from './sdk';
import {
  INITIAL_VALUES as initialValues,
  MailshotGroupValidationSchema
} from './utils';

const MailshotGroupCreateForm = ({ history }) => {
  const handleSubmit = useCallback(
    async (values, actions) => {
      const { setSubmitting, setFieldError } = actions;

      const formData = prepareFormData(values);

      setSubmitting(true);

      const { errors, success, data } = await mailshotGroupCreate(formData);

      if (success) {
        notifySuccess('Mailshot Group created.');
        history.push(
          pageUrl(MailshotGroupDetail, { mailshotGroupId: data.id })
        );
        return;
      }

      handleFormErrors(errors, setFieldError);
      setSubmitting(false);
    },
    [history]
  );

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={MailshotGroupValidationSchema}>
      {({ isSubmitting, handleSubmit, dirty }) => (
        <Form>
          <UnsavedFormPrompt when={dirty && !isSubmitting} />
          <FormRow>
            <Field
              name="fromEmail"
              label="From email:"
              component={SelectField}
              options={FROM_EMAIL_OPTIONS}
              required
              isClearable
            />
            <Field
              required
              name="title"
              label="Title:"
              component={InputField}
            />
          </FormRow>
          <CreateButtonInRow onClick={handleSubmit} disabled={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(MailshotGroupCreateForm);
