import React from 'react';

import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { formatDate, formatDateForApi } from 'utils';

class PriceSeriesMissingDataTable extends React.Component {
  getPriceSeriesUpdateUrl = (priceSeriesId, date, frequency) => {
    const url = pageUrl(this.props.updatePage);

    if (date) {
      return `${url}?id=${priceSeriesId}&date=${date}&frequency=${frequency}`;
    }

    return `${url}?id=${priceSeriesId}&frequency=${frequency}`;
  };

  render() {
    const { priceSeries, startDate, endDate, frequency } = this.props;

    return (
      <Table celled selectable compact={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Price Series Item</Table.HeaderCell>
            <Table.HeaderCell>Latest available data</Table.HeaderCell>
            <Table.HeaderCell>Date for latest available data</Table.HeaderCell>
            <Table.HeaderCell>
              To be filled in {formatDate(startDate)} - {formatDate(endDate)}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {priceSeries &&
            priceSeries.map(price => (
              <Table.Row key={price.id}>
                <Table.Cell>{price.custom_title}</Table.Cell>
                <Table.Cell>
                  <Link
                    to={this.getPriceSeriesUpdateUrl(
                      price.id,
                      price.latest_date,
                      frequency
                    )}>
                    {price.latest_value
                      ? price.latest_value
                      : 'No previous data'}
                  </Link>
                </Table.Cell>
                <Table.Cell>{price.latest_date}</Table.Cell>
                <Table.Cell>
                  <Link
                    to={this.getPriceSeriesUpdateUrl(
                      price.id,
                      formatDateForApi(startDate),
                      frequency
                    )}>
                    Edit
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    );
  }
}

export default PriceSeriesMissingDataTable;
