import React from 'react';

import { useHistory } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  InputField,
  FormRow,
  CreateButtonInRow,
  UnsavedFormPrompt,
  UniSelect
} from 'components';
import { notifySuccess } from 'utils/notifications';
import { handleFormErrors, prepareFormData } from 'utils/forms';
import { populateSlug } from 'utils';

import { pageUrl } from 'config/routes';
import { CompanyV2List } from 'pages';

import { companyV2CreateSchema } from './utils';
import { companyV2Create } from './sdk';

const CompanyV2CreateForm = () => {
  let history = useHistory();

  const handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;
    const data = prepareFormData(values);
    setSubmitting(true);
    const { errors, success } = await companyV2Create(data);
    if (success) {
      notifySuccess('Company created.');
      history.push(pageUrl(CompanyV2List));
    } else {
      handleFormErrors(errors, setFieldError);
    }
    setSubmitting(false);
  };

  const initialValues = {
    title: '',
    slug: '',
    product: ''
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={companyV2CreateSchema}
      onSubmit={handleSubmit}>
      {({ values, handleSubmit, isSubmitting, setFieldValue, dirty }) => (
        <Form>
          <UnsavedFormPrompt when={dirty && !isSubmitting} />
          <CreateButtonInRow onClick={handleSubmit} disabled={isSubmitting} />
          <FormRow>
            <Field
              width={4}
              name="title"
              label="Title"
              required
              component={InputField}
              onChange={(e, data) => {
                populateSlug(data, setFieldValue);
              }}
            />
            <Field
              width={4}
              name="slug"
              label="Slug"
              required
              component={InputField}
            />
            <UniSelect
              width={5}
              name="product"
              label="Select Product"
              source="globalProducts"
              isMulti
            />
          </FormRow>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyV2CreateForm;
