import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const emailStatsBuilder = emailType => {
  return async () => {
    const url = `${BASE_URL}/email-dashboard/stats/${emailType}/`;

    return await requestSdk(() => get(url));
  };
};

export const getNewsletterIssueStats = productSlug => {
  return async () => {
    const url = `${BASE_URL}/email-dashboard/v2/stats/${productSlug}/daily/`;

    return await requestSdk(() => get(url));
  };
};
