import React from 'react';

import { withRouter } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  InputField,
  FormRow,
  UpdateButtonInRow,
  UnsavedFormPrompt,
  UniSelect
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { productUpdateSchema } from './utils';
import { productUpdate } from './sdk';

class ProductUpdateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { product } = this.props;

    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await productUpdate(product.id, data);

    setSubmitting(false);

    if (success) {
      notifySuccess('Product updated.');
      this.props.fetchProduct();
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  render() {
    const { product } = this.props;

    const initialValues = {
      title: product.title,
      shortTitle: product.short_title,
      abbreviation: product.abbreviation,
      priceProductGroup: product.price_product_group
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={productUpdateSchema}
        onSubmit={this.handleSubmit}>
        {({ values, handleSubmit, isSubmitting, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty} />

            <UpdateButtonInRow onClick={handleSubmit} disabled={isSubmitting} />
            <FormRow>
              <Field
                width={4}
                name="title"
                label="Title"
                required
                component={InputField}
              />
              <Field
                width={4}
                name="shortTitle"
                label="Short title"
                required
                component={InputField}
              />
              <Field
                width={4}
                name="abbreviation"
                label="Abbreviation"
                required
                component={InputField}
              />
              <UniSelect
                width={4}
                name="priceProductGroup"
                label="Product Group"
                source="priceSerieProductGroups"
              />
            </FormRow>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(ProductUpdateForm);
