import React from 'react';

import { Field, SelectField } from 'components';
import { currencyList } from './sdk';

class CurrencySelect extends React.Component {
  state = {
    currencies: []
  };
  async componentDidMount() {
    const { data, success } = await currencyList();

    if (success) {
      this.setState({
        currencies: data.map(currency => ({
          key: currency.id,
          value: currency.id,
          label: currency.code
        }))
      });
    }
  }

  render() {
    const { name, isClearable = true, ...rest } = this.props;
    const { currencies } = this.state;

    const options = currencies;

    return (
      <Field
        name={name || 'currency'}
        component={SelectField}
        options={options}
        isClearable={isClearable}
        {...rest}
      />
    );
  }
}

export default CurrencySelect;
