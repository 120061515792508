import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Header, Segment, Grid, Button } from 'semantic-ui-react';

import { Page } from 'components';
import { notifyErrors } from 'utils/notifications';

import { PERMISSIONS } from 'permissions';
import { withLoggedUser, withPermissions } from 'hooks';
import { pageUrl } from 'config/routes';
import { RecipientGroups, ContentSection } from 'pages';
import SectionColumn from './column';
import { newsletterDetail } from './sdk';

function NewsletterSetup({ user, crumbs }) {
  const { productSlug, newsletterType } = useParams();
  const [newsletterId, setNewsletterId] = useState(null);
  const [groupBy, setGroupBy] = useState('region');

  const history = useHistory();

  const navigateToSection = () => {
    history.push(pageUrl(ContentSection, { productSlug, newsletterId }));
  };
  const navigateToRecepientGroups = () => {
    history.push(pageUrl(RecipientGroups, { productSlug, newsletterType }));
  };

  const getNewsletter = useCallback(async () => {
    const { errors, success, data } = await newsletterDetail(
      productSlug,
      newsletterType
    );
    if (success) {
      setNewsletterId(data.id);
    } else {
      notifyErrors(errors);
    }
  }, [productSlug, newsletterType]);

  useEffect(() => {
    getNewsletter();
  }, [getNewsletter]);

  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content crumbs={crumbs}>
          <Segment>
            <Header as="h1">
              Setup {newsletterType} {productSlug} Newsletter
            </Header>
          </Segment>
          <Segment>
            <Button onClick={navigateToSection}>Add Section</Button>
            {groupBy === 'region' ? (
              <Button onClick={() => setGroupBy('group')}>
                Switch to Product Type Group version
              </Button>
            ) : (
              <Button onClick={() => setGroupBy('region')}>
                Switch Region version
              </Button>
            )}
            <Button onClick={navigateToRecepientGroups}>
              Manage Recipient Groups
            </Button>
          </Segment>
          {newsletterId && (
            <Segment>
              <Grid columns={3} divided>
                <Grid.Row>
                  <SectionColumn
                    newsletterId={newsletterId}
                    columnId={1}
                    groupBy={groupBy}
                  />
                  <SectionColumn
                    newsletterId={newsletterId}
                    columnId={2}
                    groupBy={groupBy}
                  />
                  <SectionColumn
                    newsletterId={newsletterId}
                    columnId={3}
                    groupBy={groupBy}
                  />
                </Grid.Row>
              </Grid>
            </Segment>
          )}
        </Page.Content>
      </Page.Body>
    </Page>
  );
}

export default withLoggedUser(
  withPermissions([PERMISSIONS.CREATE_NEWSLETTERS])(NewsletterSetup)
);
