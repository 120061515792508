import _ from 'lodash';

import React from 'react';

import { Segment } from 'semantic-ui-react';

import { PermissionsRequired, PERMISSIONS } from 'permissions';

import {
  Page,
  LoginRequired,
  Flex,
  Header,
  ListViewEssentials,
  Filters as SharedFilters,
  Loader,
  CursorPagination,
  NoDataMessage
} from 'components';

import { FILTERS } from './utils';

import { rssAccessLogList } from './sdk';
import { AccessLogTable } from './components';

class CorporateRssFeedAccessLog extends React.Component {
  render() {
    const {
      user,
      data,
      nextCursor,
      previousCursor,
      pageSize,
      changePage,
      changeRowsPerPage,
      filters,
      filterBy,
      clear,
      sort,
      orderBy,
      isFetching
    } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content>
            <Flex spaceBetween>
              <Header>Corporate RSS Feed Access Log</Header>
            </Flex>
            <>
              <SharedFilters
                filters={filters}
                filterBy={filterBy}
                clearFilters={clear}
                layout={FILTERS}
              />
              {isFetching && (
                <Segment>
                  <Loader />
                </Segment>
              )}
              {_.isEmpty(data) && !isFetching && <NoDataMessage />}
              {!_.isEmpty(data) && !isFetching && (
                <AccessLogTable
                  accessLogs={data}
                  sort={sort}
                  orderBy={orderBy}
                  isFetching={isFetching}
                  pagination={
                    <CursorPagination
                      nextCursor={nextCursor}
                      previousCursor={previousCursor}
                      pageSize={pageSize}
                      changePage={changePage}
                      changeRowsPerPage={changeRowsPerPage}
                    />
                  }
                />
              )}
            </>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: rssAccessLogList,
  cursorPagination: true
})(
  LoginRequired(
    PermissionsRequired([PERMISSIONS.VIEW_CORPORATE_RSS_LOGS])(
      CorporateRssFeedAccessLog
    )
  )
);
