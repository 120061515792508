import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';
import { stringifyParams } from 'utils';

export const renewalClientList = async params => {
  const url = `${BASE_URL}/crm/clients/renewal-snapshots/`;

  return await requestSdk(() => get(url, params));
};

export const renewalClientDelete = async renewalId => {
  const url = `${BASE_URL}/crm/renewals/${renewalId}/delete/`;

  return await requestSdk(() => post(url));
};

export const getDownloadRenewalsExportUrl = (filters, sort) =>
  `${BASE_URL}/crm/clients/renewal-snapshots/export/?${stringifyParams({
    ...filters,
    ...sort
  })}`;
