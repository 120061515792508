import React from 'react';

import logo from './assets/logo.png';

const Logo = ({ className, onClick, style = {} }) => (
  <span onClick={onClick}>
    <img style={style} src={logo} className={className} alt="Kallanish logo" />
  </span>
);

export default Logo;
