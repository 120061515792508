import {
  InputField,
  DateTimeField,
  CountrySelect,
  WebsiteRegistrationTypeSelect,
  TitleOptionSelect,
  YesNoSelect,
  AdminSelect
} from 'components';

export const FILTERS = [
  [
    {
      name: 'email',
      emptyValue: '',
      placeholder: 'Search by email..',
      component: InputField,
      label: 'Email',
      width: 4
    },
    {
      name: 'title',
      transform: value => parseInt(value, 10),
      fieldComponent: TitleOptionSelect,
      label: 'Title',
      width: 2
    },
    {
      name: 'first_name',
      emptyValue: '',
      placeholder: 'Search by first name',
      component: InputField,
      label: 'First name',
      width: 4
    },
    {
      name: 'last_name',
      emptyValue: '',
      placeholder: 'Search by last name',
      component: InputField,
      label: 'Last name',
      width: 4
    }
  ],
  [
    {
      name: 'registration_type',
      emptyValue: null,
      fieldComponent: WebsiteRegistrationTypeSelect,
      label: 'Registration Type',
      width: 4
    },
    {
      name: 'finished',
      emptyValue: null,
      fieldComponent: YesNoSelect,
      label: 'Is Finished',
      width: 2
    },

    {
      name: 'finished_by',
      emptyValue: null,
      transform: value => parseInt(value, 10),

      component: AdminSelect,
      label: 'Finished By',
      width: 3
    },
    {
      name: 'attempt_time_from',
      emptyValue: null,
      component: DateTimeField,
      label: 'Attempt Time From',
      width: 3
    },
    {
      name: 'attempt_time_to',
      emptyValue: null,
      component: DateTimeField,
      label: 'Attempt Time To',
      width: 3
    }
  ],
  [
    {
      name: 'job_title',
      emptyValue: '',
      placeholder: 'Search by job title..',
      component: InputField,
      label: 'Job Title',
      width: 4
    },
    {
      name: 'company_name',
      emptyValue: '',
      placeholder: 'Search by company name..',
      component: InputField,
      label: 'Company name',
      width: 4
    },
    {
      name: 'country',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: CountrySelect,
      label: 'Country',
      width: 4
    },
    {
      name: 'city',
      emptyValue: '',
      placeholder: 'Search by city',
      component: InputField,
      label: 'City',
      width: 4
    }
  ]
];
