import React from 'react';

import { Field, SelectField } from 'components';
const FREQUENCIES = [
  'Daily',
  'Weekly',
  'Monthly',
  'Quarterly',
  'Yearly',
  'Biannual'
];

class PriceSeriesFrequencySelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = FREQUENCIES.map(frequency => {
      return {
        key: frequency,
        value: frequency,
        label: frequency
      };
    });

    return (
      <Field
        name={name || 'priceSeriesFrequency'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default PriceSeriesFrequencySelect;
