import React from 'react';

import { BASE_BACKEND_URL } from 'sdk/urls';

const Image = ({ url, width, height, alt, link = false }) => {
  let imageUrl = url;

  if (imageUrl.startsWith('/')) {
    imageUrl = `${BASE_BACKEND_URL}${imageUrl}`;
  }

  const image = <img src={imageUrl} width={width} height={height} alt={alt} />;

  if (link) {
    return (
      <a href={imageUrl} target="_blank" rel="noopener noreferrer">
        {image}
      </a>
    );
  }
  return image;
};

export default Image;
