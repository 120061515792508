import { post, requestSdk } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const mailshotGroupUpdate = async (mailshotGroupId, data) => {
  const url = `${BASE_URL}/emails/mailshot-groups/${mailshotGroupId}/update/`;

  return await requestSdk(() => post(url, data));
};

export const mailshotGroupSaveAsNew = async (mailshotGroupId, data) => {
  const url = `${BASE_URL}/emails/mailshot-groups/${mailshotGroupId}/save-as-new/`;

  return await requestSdk(() => post(url, data));
};
