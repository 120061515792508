import React from 'react';
import _ from 'lodash';

import { withRouter } from 'react-router-dom';

import {
  Header,
  Page,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Flex,
  Button,
  NoDataMessage,
  Filters as SharedFilters
} from 'components';

import { pageUrl } from 'config/routes';

import { InvoiceDetail } from 'pages';

import { notifySuccess, notifyErrors } from 'utils/notifications';
import { PermissionsRequired, PERMISSIONS } from 'permissions';

import { invoicesList, invoiceCreate } from './sdk';

import { InvoiceTable } from './components';

import { FILTERS } from './utils';

class InvoiceList extends React.Component {
  onAddInvoiceClick = async clientId => {
    const { success, data, errors } = await invoiceCreate({ client: clientId });

    if (success) {
      notifySuccess('Invoice created.');
      const invoiceId = data.invoice_id;
      this.props.history.push(pageUrl(InvoiceDetail, { invoiceId }));
    } else {
      notifyErrors(errors);
    }
  };

  render() {
    const {
      user,
      data,
      limit,
      count,
      page,
      changePage,
      changeRowsPerPage,
      filters,
      filterBy,
      orderBy,
      sort,
      clear,
      crumbs
    } = this.props;

    const clientId = filters['client_id'];

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            {clientId && (
              <Flex spaceBetween>
                <Header>Invoices</Header>
                <Button onClick={() => this.onAddInvoiceClick(clientId)}>
                  Add Invoice
                </Button>
              </Flex>
            )}

            {!clientId && <Header>Invoices</Header>}
            {data && (
              <>
                <SharedFilters
                  filters={filters}
                  filterBy={filterBy}
                  clearFilters={clear}
                  count={count}
                  layout={FILTERS}
                />
                {_.isEmpty(data) && <NoDataMessage />}
                {!_.isEmpty(data) && (
                  <InvoiceTable
                    sort={sort}
                    invoices={data}
                    orderBy={orderBy}
                    clientId={clientId}
                    pagination={
                      <Pagination
                        limit={limit}
                        count={count}
                        page={page}
                        changePage={changePage}
                        changeRowsPerPage={changeRowsPerPage}
                      />
                    }
                  />
                )}
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: invoicesList
})(
  LoginRequired(
    PermissionsRequired([PERMISSIONS.VIEW_INVOICES])(withRouter(InvoiceList))
  )
);
