import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { Button, Form } from 'semantic-ui-react';

import { MailshotDetail } from 'pages';
import { pageUrl } from 'config/routes';

import {
  ConfirmationModal,
  CorporateMasterAccountsSelect,
  FormRow
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess, notifyErrors } from 'utils/notifications';

import {
  corporateMasterAccountAdd,
  clientConvertToCorporateMaster
} from './sdk';
import { cmAccountValidationSchema } from './utils';

class CorporateMasterAccountAddForm extends React.Component {
  state = { isConverting: false, isConfirmationModalOpen: false };

  openConfirmationModal = () => {
    this.setState({ isConfirmationModalOpen: true });
  };

  closeConfirmationModal = () => {
    this.setState({ isConfirmationModalOpen: false });
  };

  getMailshotDetailUrl = mailshotId => pageUrl(MailshotDetail, { mailshotId });

  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const { client, fetchClient } = this.props;
    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await corporateMasterAccountAdd(
      client.id,
      data
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Added to CM account.');
      fetchClient();
      return;
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  handleConvert = async () => {
    this.setState({ isConverting: true });

    const { client, fetchClient } = this.props;

    const { errors, success } = await clientConvertToCorporateMaster(client.id);

    if (success) {
      this.setState({ isConverting: false });

      notifySuccess('Client successfully converted to Corporate Master.');
      fetchClient();

      return;
    } else {
      notifyErrors(errors);
    }
    this.setState({ isConverting: false });
  };

  render() {
    const { client } = this.props;

    const isPartOfOpenMailshots = !_.isEmpty(client.open_mailshots_in);

    const initialValues = {
      cmAccount: null
    };

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        validationSchema={cmAccountValidationSchema}>
        {({ handleSubmit, isSubmitting }) => (
          <>
            <Form>
              <FormRow alignEnd>
                <Form.Field>
                  <Field
                    name="cmAccount"
                    component={CorporateMasterAccountsSelect}
                    label="Choose corporate master account"
                  />
                </Form.Field>
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  primary
                  disabled={isSubmitting || this.state.isConverting}>
                  Add to CM
                </Button>
                <Button
                  onClick={this.openConfirmationModal}
                  type="button"
                  color="green"
                  disabled={isSubmitting || this.state.isConverting}>
                  Convert to CM
                </Button>
              </FormRow>
            </Form>
            <ConfirmationModal
              title="Conversion confirmation"
              message={
                isPartOfOpenMailshots ? (
                  <div>
                    This client is present in the following "Open" mailshots:{' '}
                    {client.open_mailshots_in.map((mailshotId, idx) => (
                      <>
                        <Link
                          key={mailshotId}
                          to={this.getMailshotDetailUrl(mailshotId)}>
                          {mailshotId}
                        </Link>
                        {idx !== client.open_mailshots_in.length - 1 && ', '}
                      </>
                    ))}
                    .
                    <br />
                    To prevent emails to this client from bouncing in the
                    future, we will remove them from the specified mailshots.
                  </div>
                ) : (
                  "You're about to convert this client to Corporate Master."
                )
              }
              open={this.state.isConfirmationModalOpen}
              onClose={this.closeConfirmationModal}
              handleConfirmation={this.handleConvert}
              handleDecline={this.closeConfirmationModal}
            />
          </>
        )}
      </Formik>
    );
  }
}

export default CorporateMasterAccountAddForm;
