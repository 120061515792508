import React from 'react';

import { Segment, Header, Form, Button } from 'semantic-ui-react';

import { Formik } from 'formik';

import { UniSelect } from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';

import { notifySuccess } from 'utils/notifications';

import { salesTErritoryStateUpdate } from './sdk';

import { buildInitialValues } from './utils';

class StatesModal extends React.Component {
  state = {
    currentStates: null,
    salesTerritory: null
  };

  handleCloseCallBack() {
    this.props.onClose();
  }

  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    setSubmitting(true);

    const { errors, success } = await salesTErritoryStateUpdate(
      this.state.salesTerritory.id,
      prepareFormData(values)
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Sales Territory updated.');
      this.props.refetch();
      this.handleCloseCallBack();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  componentDidMount() {
    this.setState({
      salesTerritory: this.props.extraData,
      currentStates: this.props.currentStates
    });
  }

  render() {
    const territoryTitle = this.props.extraData.title;

    const { initialValues } = buildInitialValues(this.props.currentStates);

    return (
      <Segment>
        <Header as="h2">Select States for {territoryTitle}</Header>
        <Formik initialValues={initialValues} onSubmit={this.handleSubmit}>
          {({ handleSubmit, isSubmitting, onSubmit }) => (
            <Form>
              <UniSelect
                width={6}
                name="states"
                label="Territory States"
                source="salesTerritoryStates"
                transferList={true}
              />
              {this.state.currentStates && (
                <Form.Field width={4}>
                  <Button
                    fluid
                    type="submit"
                    onClick={handleSubmit}
                    color="blue">
                    Submit
                  </Button>
                </Form.Field>
              )}
            </Form>
          )}
        </Formik>
      </Segment>
    );
  }
}
export default StatesModal;
