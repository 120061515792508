import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const contentSectionsList = async params => {
  const url = `${BASE_URL}/newsletters/v2/content-sections/`;

  return await requestSdk(() => get(url, params));
};

export const contentSectionsReorder = async data => {
  const url = `${BASE_URL}/newsletters/v2/content-sections/reorder/`;

  return await requestSdk(() => post(url, data));
};

export const contentSectionDelete = async sdkArgs => {
  const { itemId } = sdkArgs;
  const url = `${BASE_URL}/newsletters/v2/content-sections/${itemId}/delete/`;

  return await requestSdk(() => post(url));
};
