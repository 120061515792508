import * as yup from 'yup';

export const recipientGroupSchema = yup.object().shape({
  subscription: yup.string().required('Subscription is required.'),
  subscriptionStatus: yup.string().required('Subscription Status is required.'),
  articleGrouping: yup.string().required('Article Grouping is required.'),
  newsletterSubject: yup
    .string()
    .nullable()
    .notRequired()
});
