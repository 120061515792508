import React from 'react';

import { Field, SelectField } from 'components';
const INVOICE_STATUSES = [
  'Paid',
  'Not paid',
  'Cancelled',
  'Payment promised',
  'Unknown'
];

class InvoiceStatusSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = INVOICE_STATUSES.map(invoiceStatus => ({
      key: invoiceStatus,
      value: invoiceStatus,
      label: invoiceStatus
    }));

    return (
      <Field
        name={name || 'invoice_status'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default InvoiceStatusSelect;
