import React from 'react';

import _ from 'lodash';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';

import { formatDateTime } from 'utils';

import { ClientDetail } from 'pages';

import { pageUrl } from 'config/routes';

import { FormRow } from 'components';

import styles from './styles.module.css';

const DisplayItem = ({ label, value, onClick }) => (
  <div className={styles.item}>
    <label>{label}:</label>
    <div
      onClick={onClick}
      className={cx({ [styles.clicky]: !_.isNil(onClick) })}>
      {value ? value : 'None'}
    </div>
  </div>
);

class NonFormValuesDisplay extends React.Component {
  redirectToClientDetail = client =>
    this.props.history.push(pageUrl(ClientDetail, { clientId: client.id }));

  render() {
    const { items, className } = this.props;

    return (
      <>
        <FormRow className={cx(styles.container, className)}>
          {items.map(item => (
            <DisplayItem
              label={item.label}
              value={item.value}
              onClick={
                item.redirectToClient
                  ? () => this.redirectToClientDetail(item.redirectToClient)
                  : item.onClick
              }
            />
          ))}
        </FormRow>
      </>
    );
  }
}

NonFormValuesDisplay = withRouter(NonFormValuesDisplay);

const CreatedUpdatedFormValues = ({
  values,
  itemsBefore = [],
  itemsAfter = []
}) => {
  const items = [
    ...itemsBefore,
    {
      label: 'Created by',
      value: _.get(values.createdBy, 'email', 'N/A'),
      redirectToClient: values.createdBy
    },
    {
      label: 'Created at',
      value: formatDateTime(values.createdAt)
    },
    {
      label: 'Updated by',
      value: _.get(values.updatedBy, 'email', 'N/A'),
      redirectToClient: values.updatedBy
    },
    {
      label: 'Updated at',
      value: formatDateTime(values.updatedAt)
    },
    ...itemsAfter
  ];

  return <NonFormValuesDisplay items={items} />;
};

export { NonFormValuesDisplay, CreatedUpdatedFormValues };
