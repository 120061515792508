import React, { useState, useCallback, useEffect } from 'react';
import { Table, Header, Segment, Icon, Form } from 'semantic-ui-react';
import { useLocation, useParams, useHistory } from 'react-router-dom';

import moment from 'moment';

import { Formik } from 'formik';
import * as yup from 'yup';

import { withLoggedUser, withPermissions } from 'hooks';

import {
  Page,
  Flex,
  Field,
  DateField,
  Button,
  FormRow,
  ExternalLinkButton
} from 'components';

import { notifyErrors } from 'utils/notifications';
import { PERMISSIONS } from 'permissions';
import {
  priceIndexChart,
  priceIndexDetail,
  priceSeries,
  downloadSpreadsheet,
  priceLatestValue
} from './sdk';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

function PriceIndexChart({ user, crumbs }) {
  const location = useLocation();
  const history = useHistory();

  const { priceIndexesId, productSlug } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const initialStartDate = queryParams.get('start_date');
  const initialEndDate = queryParams.get('end_date');
  const [chartData, setChartData] = useState([]);
  const [indexData, setIndexData] = useState([]);
  const [choices, setChoices] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(
    initialStartDate || ''
  );
  const [selectedEndDate, setSelectedEndDate] = useState(initialEndDate || '');
  const [priceValues, setPriceValues] = useState(null);

  const validationSchema = yup.object().shape({
    start_date: yup.date().required('Start date is required'),
    end_date: yup.date().required('End date is required')
  });

  const fetchPriceSeries = useCallback(async () => {
    const { data, errors, success } = await priceSeries(productSlug);

    if (success) {
      setChoices(data);
    } else {
      notifyErrors(errors);
    }
  }, [productSlug]);

  const fetchPriceIndex = useCallback(async () => {
    const { data, errors, success } = await priceIndexDetail(priceIndexesId);

    if (success) {
      setIndexData(data);
    } else {
      notifyErrors(errors);
    }
  }, [priceIndexesId]);

  const fetchIndexesChart = useCallback(
    async (startDate, endDate) => {
      const { data, errors, success } = await priceIndexChart(priceIndexesId, {
        start_date: startDate,
        end_date: endDate
      });

      if (success) {
        data.values.forEach(item => {
          item.date = moment(item.date).format('DD/MM/YYYY');
        });
        setChartData(data);
      } else {
        notifyErrors(errors);
      }
    },
    [priceIndexesId]
  );
  const fetchPriceValues = useCallback(async () => {
    const dataValues = {
      currency: indexData?.currency.id,
      unit: indexData?.unit.id,
      index_type: indexData?.type,
      prices: indexData?.prices
        .filter(item => item.type === 'price')
        .map(item => item.price_id)
        .join(',')
    };

    const { data, errors, success } = await priceLatestValue(dataValues);

    if (success) {
      setPriceValues(data);
    } else {
      notifyErrors(errors);
    }
  }, [
    indexData.currency,
    indexData.unit,
    indexData.type,
    indexData.prices,
    setPriceValues
  ]);

  useEffect(() => {
    fetchIndexesChart(selectedStartDate, selectedEndDate);
  }, [fetchIndexesChart, selectedStartDate, selectedEndDate]);

  useEffect(() => {
    fetchPriceIndex(priceIndexesId);
    fetchPriceSeries(productSlug);
  }, [fetchPriceIndex, priceIndexesId, fetchPriceSeries, productSlug]);

  useEffect(() => {
    if (indexData) {
      fetchPriceValues();
    }
  }, [
    indexData,
    fetchPriceValues,
    indexData.currency,
    indexData.unit,
    indexData.type,
    indexData.prices
  ]);

  const handleSumbit = values => {
    setSelectedStartDate(values.start_date);
    setSelectedEndDate(values.end_date);
    fetchIndexesChart(values.start_date, values.end_date);

    const searchParams = new URLSearchParams();
    searchParams.set('start_date', values.start_date);
    searchParams.set('end_date', values.end_date);
    history.push({
      search: searchParams.toString()
    });
  };

  return (
    <>
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Flex spaceBetween>
                <Header as="h1">Price Indexes Chart</Header>
                <ExternalLinkButton
                  style={{
                    paddingLeft: '0.7em',
                    paddingRight: '0.7em'
                  }}
                  url={downloadSpreadsheet(
                    priceIndexesId,
                    selectedStartDate,
                    selectedEndDate
                  )}>
                  <Icon name="download" />
                  Spreadsheet
                </ExternalLinkButton>
              </Flex>
              <Header as="h3">Title: {indexData.title}</Header>
              <Formik
                initialValues={{
                  start_date: selectedStartDate || '',
                  end_date: selectedEndDate || ''
                }}
                validationSchema={validationSchema}
                onSubmit={handleSumbit}>
                {({ values, handleSubmit }) => (
                  <Form style={{ margin: '25px 0' }}>
                    <FormRow style={{ alignItems: 'center' }}>
                      <Field
                        required
                        width={4}
                        name="start_date"
                        label="Start date:"
                        component={DateField}
                      />
                      <Field
                        required
                        width={4}
                        name="end_date"
                        label="End date:"
                        component={DateField}
                      />
                      <Button
                        type="submit"
                        primary
                        onClick={handleSubmit}
                        style={{ height: '36px', marginLeft: '15px' }}>
                        Update Chart
                      </Button>
                    </FormRow>
                  </Form>
                )}
              </Formik>
              <Header
                as="h3"
                style={{ display: 'flex', justifyContent: 'center' }}>
                {indexData.title}
              </Header>
              <ResponsiveContainer width="95%" height={400}>
                <LineChart data={chartData.values}>
                  <XAxis dataKey="date" />
                  <YAxis
                    width={80}
                    tickFormatter={value =>
                      `${value} ${
                        chartData.index_currency
                          ? chartData.index_currency
                          : '%'
                      }`
                    }
                  />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip
                    formatter={(value, name, props) => [
                      `${indexData.title} ${value} ${
                        chartData.index_currency
                          ? chartData.index_currency
                          : '%'
                      }`
                    ]}
                  />
                  <Line
                    type="monotone"
                    dot={false}
                    dataKey="value"
                    stroke="#3f983f"
                  />
                </LineChart>
              </ResponsiveContainer>
              <Table celled style={{ margin: '20px 10px' }}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Title</Table.HeaderCell>
                    <Table.HeaderCell>Original Value</Table.HeaderCell>
                    <Table.HeaderCell>Converted Value</Table.HeaderCell>
                    <Table.HeaderCell>%</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {indexData.prices?.map(object => {
                    const latestPriceValue =
                      object.type === 'price' && priceValues
                        ? priceValues.find(
                            option => option.price?.id === object.price_id
                          )
                        : null;

                    return (
                      <Table.Row key={object.order}>
                        <Table.Cell>
                          {object.type === 'price'
                            ? choices.find(
                                option => option.id === object.price_id
                              )?.display_as
                            : object.manual_component_name}
                        </Table.Cell>
                        <Table.Cell>
                          {object.type === 'price' ? (
                            <>
                              {latestPriceValue?.original_value}{' '}
                              {latestPriceValue?.price.currency}/
                              {latestPriceValue?.price.unit}
                            </>
                          ) : (
                            object.manual_component_value
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {object.type === 'price' ? (
                            <>
                              {latestPriceValue?.latest_value}{' '}
                              {indexData.currency.code}/{indexData.unit.title}
                            </>
                          ) : (
                            ''
                          )}
                        </Table.Cell>
                        <Table.Cell>{object.percent}</Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>

                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell
                      style={{
                        backgroundColor: 'transparent',
                        fontWeight: '700'
                      }}>
                      Total percentage
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      colSpan={2}
                      style={{
                        backgroundColor: 'transparent',
                        fontWeight: '700'
                      }}></Table.HeaderCell>
                    <Table.HeaderCell
                      style={{
                        fontWeight: '700',
                        backgroundColor: '#77dd77'
                      }}>
                      100%
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    </>
  );
}

export default withLoggedUser(
  withPermissions([PERMISSIONS.CREATE_PRICES])(PriceIndexChart)
);
