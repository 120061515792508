import React from 'react';

import _ from 'lodash';

import { Table, Checkbox } from 'semantic-ui-react';

import { Link } from 'react-router-dom';
import { TableFooter, YesNo, AddRemoveButton, Button } from 'components';

import { pageUrl } from 'config/routes';

import { ClientDetail } from 'pages';

import { formatDate } from 'utils';
import { buildFilters } from 'utils/filters';

import {
  mailshotAddClient,
  mailshotRemoveClient,
  mailshotAddClients,
  mailshotRemoveClients
} from './sdk';
import { notifySuccess, notifyErrors } from 'utils/notifications';

import { clientIdList } from '../../sdk';

class ClientTable extends React.Component {
  state = {
    selectAllOnPage: false,
    selectAllGlobal: false,
    selectedClients: [],
    selectionInProgress: false
  };

  setInitialState = (cb = () => {}) => {
    this.setState(
      {
        selectAllOnPage: false,
        selectAllGlobal: false,
        selectedClients: [],
        selectionInProgress: false
      },
      cb
    );
  };

  componentDidUpdate(prevProps) {
    const currentClientIds = this.props.clients.map(client => client.id);
    const nextClientIds = prevProps.clients.map(client => client.id);
    const { location } = this.props;
    if (location.search) {
      const prevLocationSearch = prevProps.location.search;
      const currentLocationSearch = location.search;
      const pagePattern = /page=(\d+)/;

      const prevWithoutPage = prevLocationSearch.replace(pagePattern, '');
      const currentWithoutPage = currentLocationSearch.replace(pagePattern, '');

      if (
        prevWithoutPage !== currentWithoutPage &&
        !(prevLocationSearch === '' && currentWithoutPage === '?') &&
        !_.isEqual(currentClientIds, nextClientIds)
      ) {
        this.setInitialState();
      } else if (prevWithoutPage !== currentWithoutPage) {
        this.setState({ selectAllOnPage: false, selectAllGlobal: false });
      }
    }
  }

  refetch = () => {
    const { refetch } = this.props;

    this.setInitialState(refetch);
  };

  toggleClientSelect = (data, clientId) => {
    const { checked } = data;

    if (checked) {
      this.setState(prevState => {
        return {
          selectedClients: [...prevState.selectedClients, clientId]
        };
      });
    } else {
      this.setState(prevState => {
        return {
          selectedClients: prevState.selectedClients.filter(
            id => id !== clientId
          )
        };
      });
    }
  };

  toggleSelectAll = data => {
    const { checked } = data;
    const { clients } = this.props;
    let selectedClients = [];

    if (checked) {
      selectedClients = clients.map(client => client.id);
    }

    this.setState(prevState => {
      return {
        selectedClients,
        selectAllOnPage: !prevState.selectAllOnPage
      };
    });
    if (!checked && this.state.selectAllGlobal) {
      this.setState(prevState => ({
        selectAllGlobal: !prevState.selectAllGlobal
      }));
    }
  };

  toggleSelectAllGlobal = async () => {
    if (!this.state.selectAllGlobal) {
      const { mailshot } = this.props;
      const { data, success } = await clientIdList(
        mailshot.id,
        buildFilters({ ...this.props.filters })
      );

      if (success) {
        this.setState(prevState => ({
          clientsAllGlobal: data
        }));
      }

      const selectedClients = this.state.clientsAllGlobal;

      this.setState(prevState => ({
        selectedClients,
        selectAllGlobal: !prevState.selectAllGlobal,
        selectAllOnPage: !prevState.selectAllOnPage
      }));
    } else {
      this.setState(prevState => ({
        selectedClients: [],
        selectAllGlobal: !prevState.selectAllGlobal,
        selectAllOnPage: !prevState.selectAllOnPage
      }));
    }
  };

  getClientDetailUrl = clientId => {
    return pageUrl(ClientDetail, { clientId });
  };

  addSelectedClients = async () => {
    const { mailshot, filters, count } = this.props;
    const { selectedClients, selectAllGlobal } = this.state;

    let data = {
      clients: selectedClients
    };

    if (selectAllGlobal) {
      data = {
        ...buildFilters(filters),
        add_all_selected: true
      };
    }

    const { success, errors } = await mailshotAddClients(mailshot.id, data);

    const clientCount = selectAllGlobal ? count : selectedClients.length;

    if (success) {
      notifySuccess(
        `${clientCount} clients added to mailshot ${mailshot.title}`
      );
      this.refetch();
    } else {
      notifyErrors(errors);
    }
  };

  removeSelectedClients = async () => {
    const { mailshot, filters, count } = this.props;
    const { selectedClients, selectAllGlobal } = this.state;

    let data = {
      clients: selectedClients
    };

    if (selectAllGlobal) {
      data = {
        ...buildFilters(filters),
        remove_all_selected: true
      };
    }

    const { success, errors } = await mailshotRemoveClients(mailshot.id, data);

    const clientCount = selectAllGlobal ? count : selectedClients.length;

    if (success) {
      notifySuccess(
        `${clientCount} clients removed from mailshot ${mailshot.title}`
      );
      this.refetch();
    } else {
      notifyErrors(errors);
    }
  };

  addOrRemove = (clientId, inMailshot) => {
    if (!inMailshot) {
      this.addToSelected(clientId);
    } else {
      this.removeFromSelected(clientId);
    }
  };

  addToSelected = async clientId => {
    const { mailshot } = this.props;

    const { success, errors } = await mailshotAddClient(mailshot.id, {
      client: clientId
    });

    if (success) {
      notifySuccess(`Client ${clientId} added to mailshot ${mailshot.title}`);
      this.refetch();
    } else {
      notifyErrors(errors);
    }
  };

  removeFromSelected = async clientId => {
    const { mailshot } = this.props;

    const { success, errors } = await mailshotRemoveClient(mailshot.id, {
      client: clientId
    });

    if (success) {
      notifySuccess(
        `Client ${clientId} removed from mailshot ${mailshot.title}`
      );
      this.refetch();
    } else {
      notifyErrors(errors);
    }
  };

  isSelected = clientId => {
    const { selectedClients } = this.state;

    return !_.isUndefined(_.find(selectedClients, id => id === clientId));
  };

  render() {
    const { clients, orderBy, pagination, count, mailshot } = this.props;
    const {
      selectedClients,
      selectAllGlobal,
      selectionInProgress
    } = this.state;

    const isMailshotInPreparationStatus =
      mailshot && mailshot.sending_status === 'Preparation';

    return (
      <div>
        <Button
          onClick={this.addSelectedClients}
          disabled={
            selectedClients.length === 0 || isMailshotInPreparationStatus
          }>
          Add selected clients
        </Button>
        <Button
          onClick={this.removeSelectedClients}
          disabled={
            selectedClients.length === 0 || isMailshotInPreparationStatus
          }
          color="red">
          Remove selected clients
        </Button>
        <Button
          onClick={this.toggleSelectAllGlobal}
          disabled={selectionInProgress}
          style={{
            backgroundColor: selectAllGlobal
              ? 'rgb(255, 72, 97)'
              : 'rgb(36, 214, 163)'
          }}
          loading={selectionInProgress}>
          {!selectAllGlobal && `Select all ${count} clients`}
          {selectAllGlobal && `Unselect all ${count} clients`}
        </Button>
        <Table celled selectable compact={true}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                singleLine
                style={{ fontWeight: '400' }}
                colspan="14">
                {pagination}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>
                <Checkbox
                  onChange={(event, data) => this.toggleSelectAll(data)}
                  checked={
                    (!_.isNil(clients) &&
                      selectedClients.length === clients.length) ||
                    this.state.selectAllGlobal
                  }
                />
              </Table.HeaderCell>
              <Table.HeaderCell onClick={() => orderBy('id')}>
                Id
              </Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>First name</Table.HeaderCell>
              <Table.HeaderCell>Last name</Table.HeaderCell>
              <Table.HeaderCell>Company</Table.HeaderCell>
              <Table.HeaderCell>Registered on</Table.HeaderCell>
              <Table.HeaderCell>Region</Table.HeaderCell>
              <Table.HeaderCell>Country</Table.HeaderCell>
              <Table.HeaderCell>Priority</Table.HeaderCell>
              <Table.HeaderCell>Registration Type</Table.HeaderCell>
              <Table.HeaderCell>Subscription</Table.HeaderCell>
              <Table.HeaderCell>Part of Mailshot</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {clients.map(client => (
              <Table.Row key={client.id}>
                <Table.HeaderCell>
                  <Checkbox
                    onChange={(event, data) =>
                      this.toggleClientSelect(data, client.id)
                    }
                    checked={this.isSelected(client.id)}
                  />
                </Table.HeaderCell>
                <Table.Cell>
                  <Link to={this.getClientDetailUrl(client.id)}>
                    {client.id}
                  </Link>
                </Table.Cell>
                <Table.Cell>{client.email}</Table.Cell>
                <Table.Cell width={1}>{client.first_name}</Table.Cell>
                <Table.Cell width={1}>{client.last_name}</Table.Cell>
                <Table.Cell>{client.client_profile.company_name}</Table.Cell>
                <Table.Cell>{formatDate(client.created_at)}</Table.Cell>
                <Table.Cell>{client.client_profile.region}</Table.Cell>
                <Table.Cell>{client.client_profile.country}</Table.Cell>
                <Table.Cell>{client.client_profile.priority}</Table.Cell>
                <Table.Cell>
                  {_.get(client, 'client_profile.registration_type') || 'N/A'}
                </Table.Cell>
                <Table.Cell>
                  <YesNo value={!client.client_profile.unsubscribed} />
                </Table.Cell>
                <Table.Cell>
                  <YesNo value={client.in_mailshot} />
                </Table.Cell>
                <Table.Cell>
                  <AddRemoveButton
                    add={!client.in_mailshot}
                    onClick={() =>
                      this.addOrRemove(client.id, client.in_mailshot)
                    }
                    disabled={isMailshotInPreparationStatus}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <TableFooter>{pagination}</TableFooter>
        </Table>
      </div>
    );
  }
}

export default ClientTable;
