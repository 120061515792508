import React from 'react';

import { pageUrl } from 'config/routes';

import { TradeExcelCreate } from 'pages';

import {
  Page,
  Flex,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Button
} from 'components';

import { tradeExcelList } from './sdk';

import { TradeExcelTable } from './components';

class TradeExcelList extends React.Component {
  render() {
    const {
      user,
      data,
      count,
      page,
      limit,
      sort,
      orderBy,
      changePage,
      changeRowsPerPage,
      history
    } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content>
            <Flex spaceBetween>
              <Header>Trade excels</Header>
              <Button
                primary
                size="large"
                onClick={() => history.push(pageUrl(TradeExcelCreate))}>
                Create trade excel
              </Button>
            </Flex>
            {data && (
              <>
                <TradeExcelTable
                  tradeExcels={data}
                  sort={sort}
                  orderBy={orderBy}
                  pagination={
                    <Pagination
                      limit={limit}
                      count={count}
                      page={page}
                      changePage={changePage}
                      changeRowsPerPage={changeRowsPerPage}
                    />
                  }
                />
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: tradeExcelList
})(LoginRequired(TradeExcelList));
