import React from 'react';

import _ from 'lodash';

import { Link } from 'react-router-dom';

import { Statistic, Segment, Header, Table } from 'semantic-ui-react';

import { Flex } from 'components';

import { pageUrl } from 'config/routes';

import { SalesManagerPipelineDashboard } from 'pages';

const Statistics = ({ statistics }) => {
  const fullStatistics = statistics.full_aggregations;

  const perProduct = !_.isNil(statistics.per_product);

  const groupedStatistics = perProduct
    ? statistics.per_product
    : statistics.per_sales_member;

  return (
    <>
      <Flex flexStart style={{ alignItems: 'flex-start' }}>
        <Segment inverted color="black" style={{ margin: '0 20px 0 0' }}>
          <Statistic inverted size="mini">
            <Statistic.Label>Total</Statistic.Label>
            <Statistic.Value>
              £ {fullStatistics.total.toFixed(0)}
            </Statistic.Value>
          </Statistic>
        </Segment>
        <Segment color="green" style={{ margin: '0 20px 0 0' }}>
          <Statistic color="green" size="mini">
            <Statistic.Label>
              <Header as="div" color="green">
                Paid
              </Header>
            </Statistic.Label>
            <Statistic.Value>
              £ {fullStatistics.paid_total.toFixed(0)}
            </Statistic.Value>
          </Statistic>
        </Segment>
        <Segment color="blue" style={{ margin: '0 20px 0 0' }}>
          <Statistic color="blue" size="mini">
            <Statistic.Label>
              <Header as="div" color="blue">
                Payment Promised/On route
              </Header>
            </Statistic.Label>
            <Statistic.Value>
              £ {fullStatistics.promised_on_route_total.toFixed(0)}
            </Statistic.Value>
          </Statistic>
        </Segment>

        <Segment color="purple" style={{ margin: '0 20px 0 0' }}>
          <Statistic color="purple" size="mini">
            <Statistic.Label>
              <Header as="div" color="purple">
                Not Booked, over 50%
              </Header>
            </Statistic.Label>
            <Statistic.Value>
              £ {fullStatistics.not_booked_over_half_certainty_total.toFixed(0)}
            </Statistic.Value>
          </Statistic>
        </Segment>

        <Segment color="red" style={{ margin: '0 20px 0 0' }}>
          <Statistic color="red" size="mini">
            <Statistic.Label>
              <Header as="div" color="red">
                PNR
              </Header>
            </Statistic.Label>
            <Statistic.Value>
              £{' '}
              {fullStatistics.pnr_total.toFixed(0) > 0
                ? `- ${fullStatistics.pnr_total.toFixed(0)}`
                : fullStatistics.pnr_total.toFixed(0)}
            </Statistic.Value>
          </Statistic>
        </Segment>
      </Flex>
      <Table celled definition compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>Total</Table.HeaderCell>

            <Table.HeaderCell>Paid</Table.HeaderCell>
            <Table.HeaderCell>On Route</Table.HeaderCell>
            <Table.HeaderCell>Payment Promised</Table.HeaderCell>
            <Table.HeaderCell>Quoted</Table.HeaderCell>
            <Table.HeaderCell>Interested</Table.HeaderCell>
            <Table.HeaderCell>Evaluator</Table.HeaderCell>
            <Table.HeaderCell>Prospect</Table.HeaderCell>
            <Table.HeaderCell>No Status</Table.HeaderCell>
            <Table.HeaderCell>Delayed</Table.HeaderCell>
            <Table.HeaderCell>Cancelled</Table.HeaderCell>
            <Table.HeaderCell>PNR</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row textAlign="center">
            <Table.Cell textAlign="left">Total</Table.Cell>
            <Table.Cell>
              {_.sumBy(groupedStatistics, 'total').toFixed(0)}
            </Table.Cell>
            <Table.Cell>
              {_.sumBy(groupedStatistics, 'paid_total').toFixed(0)}
            </Table.Cell>
            <Table.Cell>
              {_.sumBy(groupedStatistics, 'payment_on_route_total').toFixed(0)}
            </Table.Cell>
            <Table.Cell>
              {_.sumBy(groupedStatistics, 'payment_promised_total').toFixed(0)}
            </Table.Cell>
            <Table.Cell>
              {_.sumBy(groupedStatistics, 'quoted_total').toFixed(0)}
            </Table.Cell>
            <Table.Cell>
              {_.sumBy(groupedStatistics, 'interested_total').toFixed(0)}
            </Table.Cell>
            <Table.Cell>
              {_.sumBy(groupedStatistics, 'evaluator_total').toFixed(0)}
            </Table.Cell>
            <Table.Cell>
              {_.sumBy(groupedStatistics, 'prospect_total').toFixed(0)}
            </Table.Cell>
            <Table.Cell>
              {_.sumBy(groupedStatistics, 'no_status_total').toFixed(0)}
            </Table.Cell>
            <Table.Cell>
              {_.sumBy(groupedStatistics, 'delayed_total').toFixed(0)}
            </Table.Cell>
            <Table.Cell>
              {_.sumBy(groupedStatistics, 'cancelled_total').toFixed(0)}
            </Table.Cell>
            <Table.Cell>
              {_.sumBy(groupedStatistics, 'pnr_total').toFixed(0)}
            </Table.Cell>
          </Table.Row>
          {groupedStatistics.map(data => (
            <Table.Row textAlign="center">
              <Table.Cell textAlign="left">
                {perProduct ? (
                  <div>{data.product_title}</div>
                ) : (
                  <Link
                    to={{
                      pathname: pageUrl(SalesManagerPipelineDashboard, {
                        managerId: data.sales_member.id
                      }),
                      state: { ...data.sales_member }
                    }}>
                    {data.sales_member.first_name} {data.sales_member.last_name}
                  </Link>
                )}
              </Table.Cell>
              <Table.Cell>{data.total.toFixed(0)}</Table.Cell>
              <Table.Cell>{data.paid_total.toFixed(0)}</Table.Cell>
              <Table.Cell>{data.payment_on_route_total.toFixed(0)}</Table.Cell>
              <Table.Cell>{data.payment_promised_total.toFixed(0)}</Table.Cell>
              <Table.Cell>{data.quoted_total.toFixed(0)}</Table.Cell>
              <Table.Cell>{data.interested_total.toFixed(0)}</Table.Cell>
              <Table.Cell>{data.evaluator_total.toFixed(0)}</Table.Cell>
              <Table.Cell>{data.prospect_total.toFixed(0)}</Table.Cell>
              <Table.Cell>{data.no_status_total.toFixed(0)}</Table.Cell>
              <Table.Cell>{data.delayed_total.toFixed(0)}</Table.Cell>
              <Table.Cell>{data.cancelled_total.toFixed(0)}</Table.Cell>
              <Table.Cell>{data.pnr_total.toFixed(0)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default Statistics;
