import React from 'react';

import { Button } from 'components';
import { openInNewTab } from 'utils';

const ExternalLinkButton = ({ url, children, ...rest }) => (
  <Button onClick={() => openInNewTab(url)} {...rest}>
    {children}
  </Button>
);

export default ExternalLinkButton;
