import { requestSdk } from 'sdk';
import { BASE_URL } from 'sdk/urls';
import axios from 'axios';

export const tradeExcelCreate = async data => {
  const url = `${BASE_URL}/editorials/trade-excels/create/`;

  const sdk = () =>
    axios.post(url, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
      withCredentials: true
    });

  return await requestSdk(() => sdk());
};
