import _ from 'lodash';

import React, { useCallback, useState } from 'react';

import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { Form, Button } from 'semantic-ui-react';

import { FROM_EMAIL_OPTIONS } from 'pages/MailshotGroup/constants';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';
import {
  Field,
  UpdateButton,
  TextAreaField,
  InputField,
  SelectField,
  FormRow,
  UnsavedFormPrompt,
  ErrorsAndButtonsInRow,
  Flex,
  CreatedUpdatedFormValues,
  BaseRichTextAreaField
} from 'components';

import { mailshotValidationSchema } from './utils';
import { mailshotGroupUpdate, mailshotGroupSaveAsNew } from './sdk';
import { pageUrl } from 'config/routes';
import { MailshotGroupDetail } from 'pages';

const MailshotGroupUpdateForm = ({ mailshotGroup, fetchMailshotGroup }) => {
  const [savingAsNew, setSavingAsNew] = useState(false);
  const history = useHistory();

  const handleSaveAsNew = useCallback(
    async (values, actions) => {
      const postData = _.pickBy(values, _.negate(_.isNil));

      const { setSubmitting, setFieldError } = actions;

      const { errors, success, data } = await mailshotGroupSaveAsNew(
        mailshotGroup.id,
        prepareFormData(postData)
      );

      setSubmitting(false);
      setSavingAsNew(false);

      if (success) {
        notifySuccess('Mailshot Group created.');
        history.push(
          pageUrl(MailshotGroupDetail, { mailshotGroupId: data.id })
        );
        return;
      }

      handleFormErrors(errors, setFieldError);
    },
    [setSavingAsNew, mailshotGroup, history]
  );

  const handleSubmit = useCallback(
    async (values, actions) => {
      const data = _.pickBy(values, _.negate(_.isNil));

      const { setSubmitting, setFieldError } = actions;

      if (savingAsNew) {
        return handleSaveAsNew(values, actions);
      }

      const { errors, success } = await mailshotGroupUpdate(
        mailshotGroup.id,
        prepareFormData(data)
      );

      setSubmitting(false);

      if (success) {
        notifySuccess('Mailshot Group has been updated successfully.');
        fetchMailshotGroup();
        return;
      }

      handleFormErrors(errors, setFieldError);
    },
    [fetchMailshotGroup, mailshotGroup.id, handleSaveAsNew, savingAsNew]
  );

  const nonFormValues = {
    createdAt: mailshotGroup.created_at,
    createdBy: mailshotGroup.created_by,
    updatedAt: mailshotGroup.updated_at,
    updatedBy: mailshotGroup.updated_by
  };

  const initialValues = {
    ..._.pick(mailshotGroup, ['title', 'html', 'css']),
    fromEmail: mailshotGroup.from_email,
    plainText: mailshotGroup.plain_text
  };

  return (
    <Formik
      enableReinitialize={true}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={mailshotValidationSchema}>
      {({ handleSubmit, isSubmitting, dirty, errors, touched }) => {
        return (
          <Form>
            <UnsavedFormPrompt when={dirty} />
            <FormRow>
              <Field
                required
                name="title"
                label="Title:"
                component={InputField}
              />
              <Field
                name="fromEmail"
                label="From email:"
                component={SelectField}
                options={FROM_EMAIL_OPTIONS}
                required
                isClearable
              />
            </FormRow>

            <Field
              required
              name="html"
              label="HTML"
              config={{
                height: '600px',
                readOnly: false,
                extraPlugins: 'image2'
              }}
              component={BaseRichTextAreaField}
            />
            <Field
              name="plainText"
              label="Plain text"
              style={{ minHeight: '350px' }}
              component={TextAreaField}
            />
            <Field component={TextAreaField} name="css" label="CSS" />
            <CreatedUpdatedFormValues values={nonFormValues} />
            <ErrorsAndButtonsInRow touched={touched} errors={errors}>
              <Flex>
                <Button
                  type="button"
                  size="large"
                  color="green"
                  onClick={event => {
                    setSavingAsNew(true);
                    handleSubmit(event);
                  }}
                  disabled={isSubmitting}>
                  Save As New
                </Button>
                <UpdateButton
                  subject="Mailshot Group"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                />
              </Flex>
            </ErrorsAndButtonsInRow>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MailshotGroupUpdateForm;
