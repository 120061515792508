import React from 'react';

import { withRouter } from 'react-router-dom';

import { Button } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import {
  TrackingComments,
  TrackingSubscriptionTrialEvents,
  TrackingUnsubscriptionEvents,
  TrackingSubscriptionEvents,
  TrackingMarketReportEvents,
  TrackingCoursesEvents,
  TrackingFAQSubmissions
} from 'pages';

import { Flex } from 'components';
import { TrackingFeedback } from 'pages/FormsDashboard';

class Navigation extends React.Component {
  redirectTo = (page, search) => {
    this.props.history.push({
      pathname: pageUrl(page),
      search: search
    });
  };

  render() {
    const currentMonthAndYearQueryParams = `month=${new Date().getMonth() +
      1}&year=${new Date().getFullYear()}`;

    return (
      <Flex>
        <Button onClick={() => this.redirectTo(TrackingComments)}>
          Comments
        </Button>
        <Button onClick={() => this.redirectTo(TrackingFeedback)}>
          Feedback
        </Button>
        <Button
          onClick={() =>
            this.redirectTo(
              TrackingSubscriptionTrialEvents,
              currentMonthAndYearQueryParams
            )
          }>
          Trial Requests
        </Button>
        <Button
          onClick={() =>
            this.redirectTo(
              TrackingSubscriptionEvents,
              currentMonthAndYearQueryParams
            )
          }>
          Subscription Requests
        </Button>
        <Button onClick={() => this.redirectTo(TrackingUnsubscriptionEvents)}>
          Unsubscription Requests
        </Button>
        <Button onClick={() => this.redirectTo(TrackingMarketReportEvents)}>
          Market Report
        </Button>
        <Button onClick={() => this.redirectTo(TrackingCoursesEvents)}>
          Events
        </Button>
        <Button onClick={() => this.redirectTo(TrackingFAQSubmissions)}>
          FAQ Submissions
        </Button>
      </Flex>
    );
  }
}

export default withRouter(Navigation);
