import React from 'react';

import {
  Page,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Filters as SharedFilters
} from 'components';

import { articleChangesList } from './sdk';

import { ArticleChangesTable } from './components';

import { FILTERS as LAYOUT_FILTERS } from './utils';

class ArticleChangesList extends React.Component {
  render() {
    const {
      user,
      data,
      limit,
      count,
      page,
      changePage,
      changeRowsPerPage,
      filters,
      clear,
      filterBy,
      orderBy,
      sort
    } = this.props;
    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content>
            <Header>Article Changes</Header>
            <SharedFilters
              filters={filters}
              clearFilters={clear}
              filterBy={filterBy}
              count={count}
              layout={LAYOUT_FILTERS}
            />
            {data && (
              <ArticleChangesTable
                sort={sort}
                orderBy={orderBy}
                articleChanges={data}
                pagination={
                  <Pagination
                    limit={limit}
                    count={count}
                    page={page}
                    changePage={changePage}
                    changeRowsPerPage={changeRowsPerPage}
                  />
                }
              />
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: articleChangesList
})(LoginRequired(ArticleChangesList));
