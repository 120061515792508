import React, { useCallback } from 'react';

import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { Form } from 'semantic-ui-react';
import _ from 'lodash';

import {
  Field,
  InputField,
  UpdateButtonInRow,
  PermissionSelect,
  AdminTransferSelect,
  UnsavedFormPrompt
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';
import { preparePermissions, prepareInitialPermissions } from 'permissions';

import { groupUpdateSchema } from './schema';
import { adminGroupUpdate } from './sdk';

const GroupsAndPermissionsUpdateForm = ({ data, fetchAdminGroup }) => {
  let { id, name, permissions, members } = data;
  permissions = prepareInitialPermissions(permissions);
  const initialValues = {
    name,
    permissions,
    members: _.map(members, 'id')
  };

  const handleGroupUpdate = useCallback(
    async (values, actions) => {
      const { setSubmitting, setFieldError } = actions;

      setSubmitting(true);

      let data = prepareFormData(values);
      const permissions = preparePermissions(data);
      data['permissions'] = permissions;

      const { errors, success } = await adminGroupUpdate(id, data);

      setSubmitting(false);

      if (success) {
        notifySuccess('Group has been updated successfully.');
        fetchAdminGroup();
      } else {
        handleFormErrors(errors, setFieldError);
      }
    },
    [fetchAdminGroup, id]
  );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={groupUpdateSchema}
      onSubmit={handleGroupUpdate}>
      {({ handleSubmit, isSubmitting, dirty }) => (
        <Form>
          <UnsavedFormPrompt when={dirty} />
          <Field
            width={6}
            required
            component={InputField}
            name="name"
            label="Group name"
          />
          <PermissionSelect
            required
            width={10}
            name="permissions"
            label="Permissions"
          />
          <AdminTransferSelect
            required
            width={10}
            name="members"
            label="Members"
          />
          <UpdateButtonInRow onClick={handleSubmit} disabled={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(GroupsAndPermissionsUpdateForm);
