import {
  InputField,
  CurrencySelect,
  OrderStatusSelect,
  DateField
} from 'components';

export const FILTERS = [
  [
    {
      name: 'order_date_from',
      emptyValue: null,
      component: DateField,
      label: 'Order date from'
    },
    {
      name: 'order_date_to',
      emptyValue: null,
      component: DateField,
      label: 'Order date to'
    }
  ],
  [
    {
      name: 'total_from',
      emptyValue: '',
      component: InputField,
      label: 'Total from',
      type: 'number',
      placeholder: 'Total from'
    },
    {
      name: 'total_to',
      emptyValue: '',
      component: InputField,
      label: 'Total to',
      type: 'number',
      placeholder: 'Total to'
    }
  ],
  [
    {
      name: 'client_name',
      emptyValue: '',
      component: InputField,
      label: 'Client name',
      placeholder: 'Search by client name'
    },
    {
      name: 'status',
      emptyValue: null,
      fieldComponent: OrderStatusSelect,
      label: 'Status'
    },
    {
      name: 'currency',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: CurrencySelect,
      label: 'Currency'
    }
  ]
];
