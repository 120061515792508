import React from 'react';

import { withRouter } from 'react-router-dom';

import { Header, Segment } from 'semantic-ui-react';

import { WEBSITE_URL } from 'sdk/urls';

import {
  Page,
  LoginRequired,
  Error,
  DisplayItem,
  Flex,
  ExternalLink,
  Button
} from 'components';

import { pageDetail, pageUpdate } from './sdk';

import { BannerForm, MetaDataForm, ContentForm } from './components';

import {
  STEEL_INDEX_PAGE_IDENTIFIER,
  POWER_MATERIALS_INDEX_PAGE_IDENTIFIER
} from './constants';
import TextForm from './components/TextForm';

const CONTENT_MANAGABLE_PAGES = [
  STEEL_INDEX_PAGE_IDENTIFIER,
  POWER_MATERIALS_INDEX_PAGE_IDENTIFIER,
  'events/conferences/conference-presentations',
  'holiday-publishing-schedule',
  'more-about-kallanish/meet-team',
  'whitelist',
  'advertising',
  'join-the-team',
  'more-about-kallanish/contact-us'
];

const TEXT_MANAGABLE_PAGES = [
  'steel-news/free-content',
  'steel-news/premium-content',
  'steel-news/kallanish-steel-live',

  'steel-news/market-reports/flat-products',
  'steel-news/market-reports/long-products',
  'steel-news/market-reports/raw-materials',
  'steel-news/market-reports/tubes-pipes',
  'steel-news/market-reports/semis',
  'steel-news/market-reports/freight',
  'steel-news/market-reports/exchanges-financial',

  'steel-news/regions/europe',
  'steel-news/regions/asia',
  'steel-news/regions/north-america',
  'steel-news/regions/middle-east',
  'steel-news/regions/south-america',

  'power-material-news/free-content',
  'power-material-news/premium-content',

  'power-material-news/market-reports/battery-materials',
  'power-material-news/market-reports/electric-vehicles',
  'power-material-news/market-reports/hydrogen',
  'power-material-news/market-reports/fixed-storage',
  'power-material-news/market-reports/research-development',
  'power-material-news/market-reports/policy-finance-and-regulation',

  'power-material-news/regions/europe',
  'power-material-news/regions/asia',
  'power-material-news/regions/north-america',
  'power-material-news/regions/middle-east',
  'power-material-news/regions/south-america'
];

class PageDetail extends React.Component {
  state = {
    errors: null,
    page: null
  };

  fetchPage = async () => {
    const { pageId } = this.props.match.params;

    const { data, errors, success } = await pageDetail(pageId);

    if (success) {
      this.setState({ page: data });
    } else {
      this.setState({ errors });
    }
  };

  pageUpdate = async (pageId, data) => {
    return await pageUpdate(pageId, data);
  };

  componentDidMount() {
    this.fetchPage();
  }

  render() {
    const { user, crumbs } = this.props;

    const { page, errors } = this.state;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Flex spaceBetween>
              <Header as="h1">Edit page</Header>
              {page && (
                <ExternalLink
                  url={
                    `${WEBSITE_URL}/` +
                    (page.identifier ? `${page.identifier}/` : '')
                  }>
                  <Button size="large" color="green">
                    Visit on site
                  </Button>
                </ExternalLink>
              )}
            </Flex>

            {errors &&
              errors.map((error, index) => (
                <Error key={index}>{error.message}</Error>
              ))}

            {page && (
              <>
                <Segment>
                  <Flex flexStart>
                    <DisplayItem label="ID" value={page.id} />
                    <DisplayItem label="Title" value={page.title} />
                    <DisplayItem label="Slug" value={page.identifier} />
                  </Flex>
                </Segment>
                <Segment>
                  <BannerForm
                    page={page}
                    fetchPage={this.fetchPage}
                    pageUpdate={this.pageUpdate}
                  />
                </Segment>
                {CONTENT_MANAGABLE_PAGES.includes(page.identifier) && (
                  <Segment>
                    <ContentForm
                      page={page}
                      fetchPage={this.fetchPage}
                      pageUpdate={this.pageUpdate}
                    />
                  </Segment>
                )}
                {TEXT_MANAGABLE_PAGES.includes(page.identifier) && (
                  <Segment>
                    <TextForm
                      page={page}
                      fetchPage={this.fetchPage}
                      pageUpdate={this.pageUpdate}
                    />
                  </Segment>
                )}

                <Segment>
                  <MetaDataForm
                    page={page}
                    fetchPage={this.fetchPage}
                    pageUpdate={this.pageUpdate}
                  />
                </Segment>
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(withRouter(PageDetail));
