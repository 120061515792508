import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const contentEditionInformation = async (contentType, contentId) => {
  const url = `${BASE_URL}/common/content-edition-information/`;

  return await requestSdk(() =>
    post(url, {
      content_type: contentType,
      content_id: contentId
    })
  );
};
