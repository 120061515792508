import React from 'react';

import { Field, SelectField } from 'components';

import { faqCategoriesList } from './sdk';

class FaqCategorySelect extends React.Component {
  state = {
    faqCategories: []
  };

  async componentDidMount() {
    const { data, success } = await faqCategoriesList();

    if (success) {
      this.setState({ faqCategories: data });
    }
  }

  render() {
    const { name, ...rest } = this.props;
    const { faqCategories } = this.state;

    const options = faqCategories.map(category => {
      return {
        key: category.id,
        value: category.id,
        label: category.title
      };
    });

    return (
      <Field
        name={name || 'faqCategory'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default FaqCategorySelect;
