import _ from 'lodash';
import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import {
  Page,
  Header,
  LoginRequired,
  ListViewEssentials,
  Flex,
  ExternalLinkButton,
  Tooltip
} from 'components';

import { PermissionsRequired, PERMISSIONS } from 'permissions';
import { POWER_MATERIAL_SLUG } from 'domainConstants';

import { notifyErrors } from 'utils/notifications';

import { PriceSeriesMovementTable } from './components';

import { priceMovementList, PRICE_MOVEMENT_EXPORT_URL } from './sdk';

function PriceMovementList({ user, crumbs }) {
  const { productSlug } = useParams();

  const [priceMovementData, setPriceMovementData] = useState(null);

  const fetchMovementData = useCallback(async () => {
    const { data, errors, success } = await priceMovementList(productSlug);

    if (success) {
      setPriceMovementData(data);
    } else {
      notifyErrors(errors);
    }
  }, [productSlug]);

  useEffect(() => {
    fetchMovementData();
  }, [fetchMovementData]);

  const hasOnlyDailyPrices = useMemo(
    () =>
      priceMovementData &&
      _.every(priceMovementData, price => price.frequency === 'Daily'),
    [priceMovementData]
  );

  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content crumbs={crumbs}>
          <Flex spaceBetween>
            <Header>Prices Movement</Header>
            <Flex>
              <ExternalLinkButton
                url={PRICE_MOVEMENT_EXPORT_URL(productSlug)}
                color="green">
                Export to excel
              </ExternalLinkButton>

              {productSlug === POWER_MATERIAL_SLUG && (
                <Tooltip
                  on="hover"
                  disabled={hasOnlyDailyPrices}
                  content="Weekly export is available only for daily prices"
                  trigger={
                    <div>
                      <ExternalLinkButton
                        color="green"
                        url={PRICE_MOVEMENT_EXPORT_URL(productSlug, true)}
                        disabled={!hasOnlyDailyPrices}>
                        Export to weekly excel
                      </ExternalLinkButton>
                    </div>
                  }
                />
              )}
            </Flex>
          </Flex>
          {priceMovementData && (
            <>
              <PriceSeriesMovementTable priceSeries={priceMovementData} />
            </>
          )}
        </Page.Content>
      </Page.Body>
    </Page>
  );
}

export default ListViewEssentials({
  sdk: priceMovementList
})(
  LoginRequired(
    PermissionsRequired([PERMISSIONS.VIEW_PRICES])(PriceMovementList)
  )
);
