import React from 'react';
import _ from 'lodash';

import { Field, FastField, SelectField } from 'components';

const ARTICLE_SECTIONS = [
  'Asia & Pacific',
  'Europe',
  'Middle East & Africa',
  'Americas',
  'Corporate & Other',
  'Global'
];

class PMArticleSectionSelect extends React.Component {
  state = {
    options: ARTICLE_SECTIONS.map(section => {
      return {
        key: section,
        value: section,
        label: section
      };
    })
  };

  render() {
    const { name, fast = false, ...rest } = this.props;

    const { options } = this.state;

    let Component = Field;
    if (fast) {
      Component = FastField;
    }

    if (!_.isEmpty(options)) {
      rest['options'] = options;
    }

    return (
      <Component
        name={name || 'articleSection'}
        component={SelectField}
        isClearable
        {...rest}
      />
    );
  }
}

export default PMArticleSectionSelect;
