import React from 'react';

import _ from 'lodash';

const buildEmailAttributes = (subject, content) => {
  const mailSubject = !_.isNil(subject) ? encodeURIComponent(subject) : null;
  const mailBody = !_.isNil(content) ? encodeURIComponent(content) : null;

  let attributes = '';

  if (!_.isNil(mailSubject)) {
    attributes += `?subject=${mailSubject}`;
  }

  if (!_.isNil(mailBody)) {
    attributes += `&body=${mailBody}`;
  }

  return attributes;
};

const EmailLink = ({ email, subject, content, children }) => {
  const emailAttributes = buildEmailAttributes(subject, content);

  return <a href={`mailto:${email}${emailAttributes}`}>{children}</a>;
};

export default EmailLink;
