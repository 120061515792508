import React from 'react';

import _ from 'lodash';

import { Formik } from 'formik';

import {
  Field,
  DateField,
  InputField,
  UnsavedFormPrompt,
  UniSelect
} from 'components';

import { notifySuccess, notifyErrors } from 'utils/notifications';
import { prepareFormData, handleFormErrors } from 'utils/forms';

import { Table, Button } from 'semantic-ui-react';

import { courseAddPromoCodeSchema } from './schema';

import {
  itemCreatePromoCode,
  itemUpdatePromoCode,
  itemRemovePromoCode
} from './sdk';

import styles from './styles.module.css';

class PromoCodesForm extends React.Component {
  handleSubmit = (values, actions) => {
    const { promocode } = this.props;

    if (_.isNil(promocode)) {
      this.createPromoCode(values, actions);
    } else {
      this.updatePromoCode(values, actions);
    }
  };

  removePromoCode = async () => {
    const { promocode, fetchPromoCodes, fetchPromoCodesUsage } = this.props;

    if (
      !window.confirm(
        'Removing this Promo Code will also remove the Website Usage records related to it. Are you sure you want to continue?'
      )
    ) {
      return;
    }

    const data = {};

    const { success, errors } = await itemRemovePromoCode(
      promocode.id,
      prepareFormData(data)
    );

    if (success) {
      notifySuccess(`Promocode ${promocode.name} removed.`);
      fetchPromoCodes();
      fetchPromoCodesUsage();
    }

    if (!success) {
      notifyErrors(errors);
    }
  };

  createPromoCode = async (values, actions) => {
    const { course, fetchPromoCodes } = this.props;

    const { setSubmitting, setFieldError, resetForm } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { success, errors } = await itemCreatePromoCode(
      course.item.id,
      prepareFormData(data)
    );

    setSubmitting(false);

    if (success) {
      resetForm();
      notifySuccess(`Promo code  '${values.name}' added.`);
      fetchPromoCodes();
    }

    if (!success) {
      handleFormErrors(errors, setFieldError);
    }
  };

  updatePromoCode = async (values, actions) => {
    const { promocode, fetchPromoCodes } = this.props;

    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { success, errors } = await itemUpdatePromoCode(
      promocode.id,
      prepareFormData(data)
    );

    setSubmitting(false);

    if (success) {
      notifySuccess(`Promocode for ${values.name} updated.`);
      fetchPromoCodes();
    }

    if (!success) {
      handleFormErrors(errors, setFieldError);
    }
  };

  getInitialValues = promocode => {
    const initialValues = {
      name: promocode ? promocode.name : '',
      description: promocode ? promocode.description : '',
      discount_type: promocode ? promocode.discount_type : '',
      amount: promocode ? promocode.amount : 0,
      start_date: promocode ? promocode.start_date : null,
      end_date: promocode ? promocode.end_date : null,
      limit: promocode ? promocode.limit : 0
    };
    return initialValues;
  };

  render() {
    const { promocode } = this.props;

    let initialValues = this.getInitialValues(promocode);

    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        validationSchema={courseAddPromoCodeSchema}
        enableReinitialize={true}>
        {({ values, handleSubmit, isSubmitting, dirty }) => (
          <>
            <UnsavedFormPrompt when={dirty} formName="PromoCode form" />
            <Table.Row>
              <Table.Cell>
                <Field required name="name" component={InputField} />{' '}
              </Table.Cell>
              <Table.Cell>
                <Field required name="description" component={InputField} />{' '}
              </Table.Cell>
              <Table.Cell>
                <UniSelect
                  width={8}
                  name="discount_type"
                  source="promoCodeDiscountType"
                />
              </Table.Cell>
              <Table.Cell>
                <Field required name="amount" component={InputField} />
              </Table.Cell>
              <Table.Cell>
                <Field name="start_date" component={DateField} />
              </Table.Cell>
              <Table.Cell>
                <Field name="end_date" component={DateField} />
              </Table.Cell>

              {promocode && (
                <Table.Cell textAlign="center" className={styles.UpdateButtons}>
                  <Button
                    onClick={handleSubmit}
                    type="submit"
                    color="blue"
                    disabled={isSubmitting}>
                    Update
                  </Button>
                  <Button
                    basic
                    color="grey"
                    type="submit"
                    onClick={this.removePromoCode}
                    disabled={isSubmitting}>
                    Remove
                  </Button>
                </Table.Cell>
              )}

              {!promocode && (
                <Table.Cell textAlign="center">
                  <Button
                    onClick={handleSubmit}
                    type="submit"
                    color="blue"
                    disabled={isSubmitting}>
                    Add
                  </Button>
                </Table.Cell>
              )}
            </Table.Row>
          </>
        )}
      </Formik>
    );
  }
}

export default PromoCodesForm;
