import React from 'react';

import _ from 'lodash';

import { withRouter } from 'react-router-dom';

import { Table, Icon } from 'semantic-ui-react';

import {
  Button,
  Field,
  DateField,
  InputField,
  PipelineEngagementSelect,
  CheckboxField
} from 'components';

import { notifyErrors, notifySuccess } from 'utils/notifications';

import { pipelineItemRemove } from './sdk';

class PipelineItemForm extends React.Component {
  deleteItem = async item => {
    const { fetchItems } = this.props;

    if (!window.confirm('Are you sure you want to clear this item?')) {
      return;
    }

    const { errors, success } = await pipelineItemRemove(item.id);

    if (success) {
      notifySuccess('Item cleared.');
    } else {
      notifyErrors(errors);
    }

    fetchItems();
  };

  render() {
    const { item, index } = this.props;

    const inDashboard = item.inDashboard;

    return (
      <Table.Row>
        <Table.Cell
          style={{
            width: '50px',
            padding: '16.5px',
            textAlign: 'center'
          }}>
          <Field
            component={CheckboxField}
            name={`pipelineItems.${index}.inDashboard`}
          />
        </Table.Cell>
        <Table.Cell width={2}>{item.title}</Table.Cell>
        <Table.Cell disabled={!inDashboard}>
          <PipelineEngagementSelect
            name={`pipelineItems.${index}.pipelineEngagement`}
          />
        </Table.Cell>
        <Table.Cell width={2} disabled={!inDashboard}>
          <Field
            component={DateField}
            name={`pipelineItems.${index}.forecastedDate`}
          />
        </Table.Cell>
        <Table.Cell width={1} disabled={!inDashboard}>
          <Field
            component={InputField}
            name={`pipelineItems.${index}.forecastedValueUsd`}
          />
        </Table.Cell>
        <Table.Cell width={1} disabled={!inDashboard}>
          <Field
            component={InputField}
            name={`pipelineItems.${index}.forecastedValueGbp`}
          />
        </Table.Cell>
        <Table.Cell width={1} disabled={!inDashboard}>
          <Field
            component={InputField}
            name={`pipelineItems.${index}.forecastedValueEur`}
          />
        </Table.Cell>
        <Table.Cell width={1} disabled={!inDashboard}>
          <Field
            component={InputField}
            name={`pipelineItems.${index}.certainty`}
            type="number"
          />
        </Table.Cell>
        <Table.Cell width={3} disabled={!inDashboard}>
          <Field component={InputField} name={`pipelineItems.${index}.notes`} />
        </Table.Cell>
        <Table.Cell width={1}>
          {!_.isNil(item.id) && (
            <Button color="red" onClick={() => this.deleteItem(item)} icon>
              <Icon name="trash" />
            </Button>
          )}
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default withRouter(PipelineItemForm);
