import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const priceSeriesForUpdateList = async (frequency, params) => {
  const url = `${BASE_URL}/prices/series/for-update/${frequency}/`;

  return await requestSdk(() => get(url, params));
};

export const pmPriceSeriesForUpdateList = async (frequency, params) => {
  const queryParams = { ...params, is_power_material_related: true };

  const url = `${BASE_URL}/prices/series/for-update/${frequency}/`;

  return await requestSdk(() => get(url, queryParams));
};
