import { DateField, ClientReminderCreatorSelect, InputField } from 'components';

export const FILTERS = [
  [
    {
      name: 'sent_date_from',
      emptyValue: null,
      component: DateField,
      label: 'Send date from',
      width: 4
    },
    {
      name: 'sent_date_to',
      emptyValue: null,
      component: DateField,
      label: 'Send date to',
      width: 4
    },
    {
      name: 'created_by',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: ClientReminderCreatorSelect,
      label: 'Created by',
      width: 6
    }
  ],
  [
    {
      name: 'description',
      emptyValue: '',
      component: InputField,
      label: 'Description',
      width: 10
    }
  ]
];
