import React from 'react';

import _ from 'lodash';

import { Table, Statistic, Segment } from 'semantic-ui-react';

import styles from './styles.module.css';

class Statistics extends React.Component {
  filterByAccountManager = salesMemberId => {
    window.scrollTo(0, 0);

    this.props.filterBy({
      ...this.props.filters,
      account_manager: salesMemberId
    });
  };

  render() {
    const { statistics } = this.props;

    const perManagerStatistics = statistics.per_manager_statistics || [];

    return (
      <Segment compact>
        <Statistic size="mini" style={{ marginLeft: '30px' }}>
          <Statistic.Label>New</Statistic.Label>
          <Statistic.Value>£ {statistics.new_total.toFixed(0)}</Statistic.Value>
        </Statistic>
        <Statistic size="mini">
          <Statistic.Label>Repeat</Statistic.Label>
          <Statistic.Value>
            £ {statistics.repeat_total.toFixed(0)} (
            {statistics.upsell_total &&
              `Upsale ${statistics.upsell_total.toFixed(0)}`}
            )
          </Statistic.Value>
        </Statistic>
        <Statistic size="mini">
          <Statistic.Label>Conferences</Statistic.Label>
          <Statistic.Value>
            £ {statistics.conferences_total.toFixed(0)}
          </Statistic.Value>
        </Statistic>
        <Table celled compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>Total</Table.HeaderCell>

              <Table.HeaderCell>New</Table.HeaderCell>
              <Table.HeaderCell>Repeat</Table.HeaderCell>
              <Table.HeaderCell>Conferences</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row textAlign="center">
              <Table.Cell textAlign="left">Total</Table.Cell>
              <Table.Cell>
                {_.sumBy(perManagerStatistics, 'total').toFixed(0)}
              </Table.Cell>
              <Table.Cell>
                {_.sumBy(perManagerStatistics, 'new_total').toFixed(0)}
              </Table.Cell>
              <Table.Cell>
                {_.sumBy(perManagerStatistics, 'repeat_total').toFixed(0)}
              </Table.Cell>
              <Table.Cell>
                {_.sumBy(perManagerStatistics, 'conferences_total').toFixed(0)}
              </Table.Cell>
            </Table.Row>
            {perManagerStatistics.map(data => (
              <Table.Row textAlign="center">
                <Table.Cell textAlign="left">
                  <div
                    className={styles.LinkDiv}
                    onClick={() =>
                      this.filterByAccountManager(data.sales_member.id)
                    }>
                    {data.sales_member.first_name} {data.sales_member.last_name}
                  </div>
                </Table.Cell>
                <Table.Cell>{data.total.toFixed(0)}</Table.Cell>
                <Table.Cell>{data.new_total.toFixed(0)}</Table.Cell>
                <Table.Cell>{data.repeat_total.toFixed(0)}</Table.Cell>
                <Table.Cell>{data.conferences_total.toFixed(0)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

export default Statistics;
