import React from 'react';

import { Table } from 'semantic-ui-react';

import _ from 'lodash';

import {
  SortableHeader,
  TableFooter,
  OpenModalButton,
  StaffProfileModal
} from 'components';
import StatesModal from 'components/StatesModal';

const SalesTerritoryTable = ({
  salesTerritories,
  pagination,
  sort,
  orderBy,
  refetch
}) => (
  <Table celled selectable compact>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell singleLine style={{ fontWeight: '400' }} colspan="7">
          {pagination}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <SortableHeader sort={sort} orderBy={orderBy} field="sales_territory">
          Sales Territory
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="country">
          Country
        </SortableHeader>
        <Table.HeaderCell>Active records</Table.HeaderCell>
        <Table.HeaderCell>Subscribers</Table.HeaderCell>
        <SortableHeader sort={sort} orderBy={orderBy} field="account_manager">
          Account Manager
        </SortableHeader>
        <Table.HeaderCell>Assign Staff Profile</Table.HeaderCell>
        <Table.HeaderCell>Assign States</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {salesTerritories.map(territory => (
        <Table.Row key={territory.id}>
          <Table.Cell>{territory.title}</Table.Cell>
          <Table.Cell>{territory.country.title}</Table.Cell>
          <Table.Cell>{territory.active_records}</Table.Cell>
          <Table.Cell>{territory.subscribers}</Table.Cell>

          {!_.isNil(territory.staff_profile) && (
            <Table.Cell>
              {territory.staff_profile.first_name}{' '}
              {territory.staff_profile.last_name}
            </Table.Cell>
          )}
          {_.isNil(territory.staff_profile) && <Table.Cell>N/A</Table.Cell>}
          <Table.Cell textAlign="center">
            <OpenModalButton
              key={territory.id}
              color="blue"
              modal={{
                header: 'Assign Territory Staff Profile',
                render: props => (
                  <StaffProfileModal
                    refetch={refetch}
                    extraData={territory}
                    currentStaffProfile={territory.staff_profile}
                    {...props}
                  />
                )
              }}>
              Assign
            </OpenModalButton>
          </Table.Cell>
          <Table.Cell textAlign="center">
            <OpenModalButton
              key={territory.id}
              color="blue"
              modal={{
                header: 'Assign Territory States',
                render: props => (
                  <StatesModal
                    refetch={refetch}
                    extraData={territory}
                    currentStates={territory.states}
                    {...props}
                  />
                )
              }}>
              Assign
            </OpenModalButton>
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default SalesTerritoryTable;
