import React from 'react';

import _ from 'lodash';

import { Formik } from 'formik';

import { Form, Table } from 'semantic-ui-react';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess, notifyErrors } from 'utils/notifications';

import {
  Button,
  Field,
  FormRow,
  InputField,
  RichTextAreaField,
  CheckboxField,
  ExternalLink
} from 'components';

import {
  clientGetLatestWeeklyNewsletterIssue,
  clientSendLatestWeeklyNewsletterIssue
} from './sdk';

import { sendLatestIssueSchema } from './schema';

class WeeklyNewsletters extends React.Component {
  state = {
    latestIssue: null
  };

  close = () => {
    this.props.onClose(this.clearState);
  };

  async componentDidMount() {
    const { product, client, weeklyUrlFragment } = this.props;

    const {
      success,
      data,
      errors
    } = await clientGetLatestWeeklyNewsletterIssue(
      client.id,
      product.id,
      weeklyUrlFragment
    );

    if (success) {
      this.setState({ latestIssue: data });
    } else {
      notifyErrors(errors);
    }
  }

  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const { client, product, weeklyUrlFragment } = this.props;
    const data = prepareFormData(values);

    setSubmitting(true);

    const { success, errors } = await clientSendLatestWeeklyNewsletterIssue(
      client.id,
      product.id,
      data,
      weeklyUrlFragment
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Latest issue sent.');
      this.close();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const { latestIssue } = this.state;

    const initialValues = {
      subject: _.get(latestIssue, 'title', ''),
      html: _.get(latestIssue, 'html', ''),
      samplePdf: false,
      pdf: false
    };

    return (
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={sendLatestIssueSchema}
          onSubmit={this.handleSubmit}>
          {({ values, handleSubmit, isSubmitting }) => (
            <Form>
              <FormRow>
                <Field
                  required
                  label="Subject"
                  component={InputField}
                  name="subject"
                />
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={isSubmitting}>
                  Send
                </Button>
              </FormRow>
              <FormRow>
                <Field label="HTML" component={RichTextAreaField} name="html" />
              </FormRow>
              <FormRow>
                <Table basic="very" celled collapsing>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Select</Table.HeaderCell>
                      <Table.HeaderCell>Issue</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  {latestIssue && (
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          <Field name="samplePdf" component={CheckboxField} />
                        </Table.Cell>
                        <Table.Cell>
                          <ExternalLink url={latestIssue.sample_pdf}>
                            Sample PDF
                          </ExternalLink>
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          <Field name="pdf" component={CheckboxField} />
                        </Table.Cell>
                        <Table.Cell>
                          <ExternalLink url={latestIssue.pdf}>
                            Full PDF
                          </ExternalLink>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  )}
                </Table>
              </FormRow>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default WeeklyNewsletters;
