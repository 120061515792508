import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const staffUpdate = async (userId, data) => {
  const url = `${BASE_URL}/users/staff-members/${userId}/update/`;

  return await requestSdk(() => post(url, data));
};

export const checkSalesTerritories = async data => {
  const url = `${BASE_URL}/users/sales-territories/fetch-account-manager/`;

  return await requestSdk(() => post(url, data));
};
