import React, { useState, useCallback, useEffect, useMemo } from 'react';

import _ from 'lodash';

import { Formik } from 'formik';
import { withRouter, useParams } from 'react-router-dom';
import { Form } from 'semantic-ui-react';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { pluralize } from 'utils/string';

import {
  Field,
  TextAreaField,
  InputField,
  RichTextAreaField,
  YesNoSelect,
  FormRow,
  UnsavedFormPrompt,
  ErrorsAndUpdateButtonInRow,
  NonFormValuesDisplay,
  FileField,
  UniSelect,
  ConfirmationModal
} from 'components';
import { notifySuccess } from 'utils/notifications';

import { staffUpdate, checkSalesTerritories } from './sdk';

import {
  buildInitialValues,
  staffValidationSchema,
  getNonFormItems
} from './utils';

const StaffUpdateForm = ({ data, fetchStaffMember }) => {
  const { initialValues, nonFormValues } = buildInitialValues(data);
  const { userId } = useParams();

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [
    openConfirmationNotActiveModal,
    setOpenConfirmationNotActiveModal
  ] = useState(false);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState(
    null
  );
  const [
    confirmationNotActiveModalMessage,
    setConfirmationNotActiveModalMessage
  ] = useState(null);
  const [salesTerritoriesFiltered, setSalesTerritoriesFiltered] = useState([]);

  const confirmationModalTitle = useMemo(() => {
    const defaultTitle = 'Overriding existing Account';
    const managerPart = pluralize('Manager', salesTerritoriesFiltered.length);

    return `${defaultTitle} ${managerPart}!`;
  }, [salesTerritoriesFiltered]);

  const confirmationNotActiveModalTitle = useMemo(() => {
    const notActiveTitle = 'Deactivating Staff Member';
    return notActiveTitle;
  }, []);

  const handleModalDecline = useCallback(setSubmitting => {
    setSubmitting(false);
  }, []);

  const onCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  const onCloseConfirmationNotActiveModal = () => {
    setOpenConfirmationNotActiveModal(false);
  };

  const handleTerritoryExistingManagers = useCallback(() => {
    let message = 'The account manager ';
    message += salesTerritoriesFiltered.map(territory => {
      return (
        ` for ${territory.title} is ` +
        `${territory.staff_profile.first_name} ${territory.staff_profile.last_name}`
      );
    });

    const manager = pluralize('manager', salesTerritoriesFiltered.length);

    message += `. Continuing would override current account ${manager}!`;
    setConfirmationModalMessage(message);

    setOpenConfirmationModal(true);
  }, [salesTerritoriesFiltered]);

  const handleNotActiveStaffMember = useCallback(() => {
    setConfirmationNotActiveModalMessage(
      'Deactivating this staff member will remove them as the account manager for all associated clients, leaving these clients without an account manager.'
    );
    setOpenConfirmationNotActiveModal(true);
  }, []);

  const submitData = useCallback(
    async (values, { setSubmitting, setFieldError }) => {
      if (setSubmitting) {
        setSubmitting(true);

        const { errors, success } = await staffUpdate(
          userId,
          prepareFormData(values)
        );

        setSubmitting(false);

        if (success) {
          notifySuccess('Staff member is updated.');
          fetchStaffMember();
        } else {
          handleFormErrors(errors, setFieldError);
        }
      }
    },
    [fetchStaffMember, userId]
  );
  const handleStaffMemberUpdate = useCallback(
    async (values, actions) => {
      const valuesToSubmit = {
        sales_territories: values.salesTerritories
      };
      const { data, success } = await checkSalesTerritories(valuesToSubmit);

      let filteredData = [];
      if (success) {
        filteredData = data.filter(
          territory => territory.staff_profile.id !== parseInt(userId)
        );
      }
      setSalesTerritoriesFiltered(filteredData);
      if (initialValues.isActive && !values.isActive) {
        handleNotActiveStaffMember();
        return;
      }
      if (_.isEmpty(filteredData)) {
        submitData(values, actions);
      }
    },
    [userId, submitData, handleNotActiveStaffMember, initialValues.isActive]
  );

  useEffect(() => {
    if (!_.isEmpty(salesTerritoriesFiltered)) {
      handleTerritoryExistingManagers();
    }
  }, [salesTerritoriesFiltered, handleTerritoryExistingManagers]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={handleStaffMemberUpdate}
      validationSchema={staffValidationSchema}>
      {({
        handleSubmit,
        isSubmitting,
        dirty,
        touched,
        errors,
        setSubmitting,
        setFieldError,
        values
      }) => (
        <Form>
          <UnsavedFormPrompt when={dirty} />
          <FormRow>
            <Field
              required
              component={InputField}
              name="username"
              label="Username:"
              width={4}
            />
            <Field
              required
              component={InputField}
              name="email"
              label="Email:"
              width={4}
            />
            <YesNoSelect name="isActive" label="Active:" width={2} />
          </FormRow>
          <FormRow>
            <Field
              name="image"
              width={4}
              label="Profile Image"
              component={FileField}
            />
            <Field
              required
              component={InputField}
              name="firstName"
              label="First Name:"
              width={4}
            />
            <Field
              required
              component={InputField}
              name="lastName"
              label="Last Name:"
              width={4}
            />
            <Field
              component={InputField}
              name="slug"
              label="Slug"
              width={4}
              disabled
            />
          </FormRow>
          <FormRow>
            <Field
              width={4}
              name="location"
              component={InputField}
              label="Location"
            />
            <Field
              width={6}
              name="twitter"
              component={InputField}
              label="Twitter URL"
            />
            <Field
              width={6}
              name="linkedin"
              component={InputField}
              label="LinkedIn URL"
            />
          </FormRow>
          <Field
            name="description"
            component={RichTextAreaField}
            label="Description"
          />
          <Field
            component={RichTextAreaField}
            name="signatureHtml"
            label="Signature HTML"
          />
          <Field
            component={TextAreaField}
            name="signatureText"
            label="Signature text:"
          />
          <UniSelect
            width={8}
            name="salesTerritories"
            label="Sales Territories"
            source="salesTerritories"
            transferList={true}
          />
          {!_.isNil(confirmationNotActiveModalMessage) &&
            !_.isNil(setSubmitting) && (
              <ConfirmationModal
                title={confirmationNotActiveModalTitle}
                message={confirmationNotActiveModalMessage}
                open={openConfirmationNotActiveModal}
                onClose={onCloseConfirmationNotActiveModal}
                handleConfirmation={() =>
                  submitData(values, { setSubmitting, setFieldError })
                }
                handleDecline={() => handleModalDecline(setSubmitting)}
              />
            )}
          {!_.isNil(confirmationModalMessage) && !_.isNil(setSubmitting) && (
            <ConfirmationModal
              title={confirmationModalTitle}
              message={confirmationModalMessage}
              open={openConfirmationModal}
              onClose={onCloseConfirmationModal}
              handleConfirmation={() =>
                submitData(values, { setSubmitting, setFieldError })
              }
              handleDecline={() => handleModalDecline(setSubmitting)}
            />
          )}
          <NonFormValuesDisplay items={getNonFormItems(nonFormValues)} />
          <ErrorsAndUpdateButtonInRow
            errors={errors}
            touched={touched}
            subject="Staff Member"
            onClick={handleSubmit}
            disabled={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(StaffUpdateForm);
