import _ from 'lodash';
import React from 'react';

import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import {
  TableFooter,
  SortableHeader,
  DeleteButton,
  AddToFavouritesButton
} from 'components';

import { pageUrl } from 'config/routes';

import { EmailTemplateDetail } from 'pages';
import { EMAIL_TEMPLATE_TRIGGER } from 'pages/EmailTemplate/constants';

import {
  emailTemplateAddToFavourites,
  emailTemplateRemove,
  emailTemplateRemoveFromFavourites
} from './sdk';

import styles from './styles.module.css';

const deleteEmailTemplate = async (templateId, refetch) => {
  if (!window.confirm('Are you sure you want to delete this email template?')) {
    return;
  }
  const { success } = await emailTemplateRemove(templateId);
  if (success) {
    refetch();
  }
};

const EmailTemplateTable = ({
  data,
  sort,
  orderBy,
  pagination,
  refetch,
  user
}) => (
  <Table celled selectable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell singleLine style={{ fontWeight: '400' }} colspan="5">
          {pagination}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <SortableHeader sort={sort} orderBy={orderBy} field="title">
          Title
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="status">
          Status
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="trigger">
          Trigger
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="template_type">
          Type
        </SortableHeader>
        {user.is_superuser && <Table.HeaderCell>Delete</Table.HeaderCell>}
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {data.map(emailTemplate => (
        <Table.Row key={emailTemplate.id} className={styles.tableRow}>
          <Table.Cell>
            <AddToFavouritesButton
              initialIsFavouredState={emailTemplate.is_favoured}
              entityName="email template"
              addToFavouritesHandler={() =>
                emailTemplateAddToFavourites(emailTemplate.id)
              }
              removeFromFavouritesHandler={() =>
                emailTemplateRemoveFromFavourites(emailTemplate.id)
              }
            />{' '}
            <Link
              to={pageUrl(EmailTemplateDetail, {
                emailTemplateId: emailTemplate.id
              })}>
              {emailTemplate.title}
            </Link>
          </Table.Cell>
          <Table.Cell>{emailTemplate.status}</Table.Cell>
          <Table.Cell>{emailTemplate.trigger}</Table.Cell>
          <Table.Cell>{emailTemplate.template_type}</Table.Cell>
          {user.is_superuser && (
            <Table.Cell width={2}>
              {_.includes(
                [
                  EMAIL_TEMPLATE_TRIGGER.MANUAL,
                  EMAIL_TEMPLATE_TRIGGER.CUSTOM,
                  EMAIL_TEMPLATE_TRIGGER.WELCOME_EMAIL
                ],
                emailTemplate.trigger
              ) ? (
                <DeleteButton
                  onClick={() => {
                    deleteEmailTemplate(emailTemplate.id, refetch);
                  }}
                />
              ) : (
                'N/A'
              )}
            </Table.Cell>
          )}
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default EmailTemplateTable;
