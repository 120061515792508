import _ from 'lodash';

import React from 'react';
import { Header, Segment } from 'semantic-ui-react';

import { Page, LoginRequired, ListViewEssentials } from 'components';

import { PermissionsRequired, PERMISSIONS } from 'permissions';

import { pricesMissingDataList } from './sdk';

import { PriceTable } from './components';

class PriceSeriesMissingDataList extends React.Component {
  render() {
    const { user, data, crumbs } = this.props;

    return (
      <Page user={user} crumbs={crumbs}>
        <Page.Body>
          <Page.Content>
            <Header as="h1">Missing Prices Data</Header>
            {data &&
              data.map(ps => (
                <Segment key={ps.frequency}>
                  <Header as="h2">
                    {_.capitalize(ps.frequency)} Price Series
                  </Header>
                  <PriceTable
                    priceSeries={ps.price_series[0]}
                    startDate={ps.start_date}
                    endDate={ps.end_date}
                    frequency={_.capitalize(ps.frequency)}
                  />
                </Segment>
              ))}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: pricesMissingDataList
})(
  LoginRequired(
    PermissionsRequired([PERMISSIONS.VIEW_PRICES])(PriceSeriesMissingDataList)
  )
);
