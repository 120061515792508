import React from 'react';
import _ from 'lodash';

import { Formik } from 'formik';

import { Form, Checkbox } from 'semantic-ui-react';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { Field, InputField, FileField, CreateButtonInRow } from 'components';

import { subscriptionBannerCreate } from './sdk';
import { bannerCreateSchema } from './schema';

class CreateBannerModal extends React.Component {
  state = {
    checkedStatuses: []
  };

  toggleStatus = status => {
    this.setState(prevState => ({
      checkedStatuses: _.xor(prevState.checkedStatuses, [status])
    }));
  };

  close = () => {
    this.props.onClose();
  };

  handleSubmit = async (values, actions) => {
    const { subscription, fetchSubscription } = this.props;
    const { setSubmitting, setFieldError } = actions;

    const data = {
      ...values,
      statuses: this.state.checkedStatuses
    };

    setSubmitting(true);

    const { success, errors } = await subscriptionBannerCreate(
      subscription.id,
      prepareFormData(data)
    );

    setSubmitting(false);

    if (success) {
      notifySuccess(`Banner is created.`);
      fetchSubscription();
      this.props.onClose();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const initialValues = {
      image: null,
      url: ''
    };

    const { checkedStatuses } = this.state;

    const { statuses } = this.props;

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        validationSchema={bannerCreateSchema}>
        {({ values, handleSubmit, isSubmitting }) => (
          <Form>
            <Field
              required
              label="Image"
              width={6}
              name="image"
              small
              component={FileField}
            />

            <Field
              required
              width={8}
              label="URL"
              name="url"
              component={InputField}
            />
            {statuses.map(status => (
              <div>
                <Checkbox
                  label={status.title}
                  onChange={() => this.toggleStatus(status.id)}
                  checked={_.indexOf(checkedStatuses, status.id) > -1}
                />
              </div>
            ))}
            <CreateButtonInRow onClick={handleSubmit} disabled={isSubmitting} />
          </Form>
        )}
      </Formik>
    );
  }
}

export default CreateBannerModal;
