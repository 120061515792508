import React from 'react';

import { Table } from 'semantic-ui-react';

import styles from './styles.module.css';

export default function StaffAggregatesTable({ staffAggregates, totals }) {
  return (
    <div className={styles.TableWrapper}>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Account Manager</Table.HeaderCell>
            <Table.HeaderCell>No. of Clients</Table.HeaderCell>
            <Table.HeaderCell>No. of Total Edits</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {staffAggregates.map((data, index) => (
            <Table.Row
              key={index}
              style={{
                backgroundColor: 'transparent'
              }}>
              <Table.Cell>{data.staff_name}</Table.Cell>
              <Table.Cell>{data.num_clients}</Table.Cell>
              <Table.Cell>{data.num_edits}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer className={styles.TableFooter}>
          <Table.Row>
            <Table.HeaderCell className={styles.Bolded}>Total</Table.HeaderCell>
            <Table.HeaderCell className={styles.Bolded}>
              {totals.total_clients}
            </Table.HeaderCell>
            <Table.HeaderCell className={styles.Bolded}>
              {totals.total_edits}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
}
