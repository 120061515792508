import { InputField } from 'components';

export const FILTERS = [
  [
    {
      name: 'search',
      emptyValue: '',
      component: InputField,
      label: 'General search',
      placeholder: 'Search...',
      width: 5
    }
  ]
];
