import React from 'react';

import _ from 'lodash';

import { Table } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { ClientDetail, FeedbackList } from 'pages';

import { formatDateTime } from 'utils';

import { OpenModalButton, TableFooter, SortableHeader } from 'components';

import FeedbackDetailsModal from './FeedbackDetailsModal';

const FeedbackTable = ({
  data,
  pagination,
  sort,
  orderBy,
  averageRating,
  refetch
}) => (
  <Table celled selectable compact={true}>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell singleLine style={{ fontWeight: '400' }} colspan="11">
          {pagination}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <SortableHeader sort={sort} orderBy={orderBy} field="feedback_id">
          Feedback ID
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="client_id">
          Client ID
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="client_first_name">
          First Name
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="client_last_name">
          Last Name
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="client_comapny">
          Comapny Name
        </SortableHeader>
        <SortableHeader
          sort={sort}
          orderBy={orderBy}
          field="client_account_manager">
          Account Manager
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="created_at">
          Created at
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="published_at">
          Published at
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="published_at">
          Rating
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="product">
          Product
        </SortableHeader>
        <Table.HeaderCell>Extra Data</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {data.map(feedback => (
        <Table.Row key={feedback.id}>
          <Table.Cell width={1}>
            {feedback.id && (
              <Link to={`${pageUrl(FeedbackList)}?feedback_id=${feedback.id}`}>
                {feedback.id}
              </Link>
            )}
          </Table.Cell>

          <Table.Cell width={1}>
            {feedback.created_by && (
              <Link
                to={pageUrl(ClientDetail, {
                  clientId: feedback.created_by.id
                })}>
                {feedback.created_by.id}
              </Link>
            )}
          </Table.Cell>
          <Table.Cell width={1}>
            {_.get(feedback, 'created_by.first_name')}
          </Table.Cell>
          <Table.Cell width={1}>
            {_.get(feedback, 'created_by.last_name')}
          </Table.Cell>
          <Table.Cell width={2}>
            {_.get(feedback, 'created_by.company_name')}{' '}
          </Table.Cell>
          <Table.Cell width={2}>
            {_.get(feedback, 'created_by.account_manager.first_name')}{' '}
            {_.get(feedback, 'created_by.account_manager.last_name')}
          </Table.Cell>

          <Table.Cell width={2}>
            {formatDateTime(feedback.created_at)}
          </Table.Cell>

          <Table.Cell width={2}>
            {formatDateTime(feedback.published_at)}
          </Table.Cell>

          <Table.Cell width={1}>{_.get(feedback, 'rating')} </Table.Cell>

          <Table.Cell width={2}>
            {_.get(feedback, 'feedback_product.name')}
          </Table.Cell>

          <Table.Cell width={2}>
            <OpenModalButton
              key={feedback.id}
              size="medium"
              color="green"
              modal={{
                header: 'Extra Data',
                render: props => (
                  <FeedbackDetailsModal feedback={feedback} {...props} />
                )
              }}>
              Show Details
            </OpenModalButton>
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default FeedbackTable;
