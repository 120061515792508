import React from 'react';

import { Formik } from 'formik';

import { Form } from 'semantic-ui-react';

import {
  Button,
  Field,
  FormRow,
  InputField,
  RichTextAreaField,
  TextAreaField,
  YesNoSelect,
  EmailVariables
} from 'components';
import { handleFormErrors, prepareFormData } from 'utils/forms';

import { notifySuccess, notifyErrors } from 'utils/notifications';

import { emailTemplateDetail, presetEmailSend } from './sdk';

import { sendPresetEmailValidationSchema } from './utils';

class SendPresetEmailModal extends React.Component {
  state = {
    emailTemplate: null
  };

  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { client } = this.props;

    const { errors, success } = await presetEmailSend(client.id, data);

    setSubmitting(false);

    if (success) {
      notifySuccess('Preset email sent.');
      this.props.onSubmitCallback();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  async componentDidMount() {
    const { emailTemplate } = this.props;
    const { success, data, errors } = await emailTemplateDetail(emailTemplate);

    if (success) {
      this.setState({ emailTemplate: data });
    } else {
      notifyErrors(errors);
    }
  }

  render() {
    const { emailTemplate } = this.state;
    const { client } = this.props;

    const email = client.email;

    let initialValues = {};

    if (emailTemplate) {
      initialValues = {
        emailTemplate: this.props.emailTemplate,
        subject: emailTemplate.subject,
        isTest: false,
        cc: '',
        fromName: emailTemplate.from_name,
        fromEmail: emailTemplate.from_email,
        html: emailTemplate.html,
        plainText: emailTemplate.plain_text,
        css: emailTemplate.css,
        testEmail: ''
      };
    }

    return (
      <>
        {emailTemplate && (
          <Formik
            enableReinitialize={true}
            validationSchema={sendPresetEmailValidationSchema}
            onSubmit={this.handleSubmit}
            initialValues={initialValues}>
            {({ values, handleSubmit, isSubmitting }) => (
              <Form>
                <FormRow flexEnd>
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isSubmitting}>
                    Send
                  </Button>
                </FormRow>
                <FormRow>
                  <input type="hidden" name="emailTemplate" />
                  <Field
                    required
                    label="Subject"
                    name="subject"
                    component={InputField}
                  />
                  <Field
                    required
                    label="Send to"
                    name="isTest"
                    component={YesNoSelect}
                    noLabel={email}
                    yesLabel="Send test email"
                    isClearable={false}
                  />
                  {values.isTest && (
                    <Field
                      required
                      label="Email"
                      name="testEmail"
                      component={InputField}
                    />
                  )}
                  <Field label="CC" name="cc" component={InputField} />
                </FormRow>
                <FormRow>
                  <Field
                    required
                    label="From name"
                    name="fromName"
                    component={InputField}
                  />
                  <Field
                    required
                    label="From email"
                    name="fromEmail"
                    component={InputField}
                  />
                </FormRow>
                <FormRow>
                  <Field
                    label="HTML"
                    name="html"
                    component={RichTextAreaField}
                  />
                </FormRow>
                <FormRow>
                  <Field
                    label="Plain text"
                    name="plainText"
                    component={TextAreaField}
                  />
                </FormRow>
                <FormRow>
                  <Field label="CSS" name="css" component={TextAreaField} />
                </FormRow>
              </Form>
            )}
          </Formik>
        )}
        <EmailVariables />
      </>
    );
  }
}

export default SendPresetEmailModal;
