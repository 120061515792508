import React, { useState } from 'react';

import _ from 'lodash';
import { Table } from 'semantic-ui-react';

import { CreateButton, DeleteButton, DeleteModal, Modal } from 'components';
import { formatDate } from 'utils';
import { deleteSendingInstance } from '../SendingInstanceForm/sdk';
import SendingInstanceForm from '../SendingInstanceForm';

function SendingInstancesTable({ instance, detailSdk, refetch }) {
  const [objToDelete, setObjToDelete] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedInstance, setSelectedInstance] = useState(null);

  const clearInstances = () => {
    setObjToDelete(null);
    setSelectedInstance(null);
    setModalOpen(false);
    refetch();
  };

  const addInstance = () => {
    const cleanFields = [
      'id',
      'pdf',
      'sample_pdf',
      'datetime',
      'sending_instances',
      'tag'
    ];
    let editableObj = {};

    _.map(instance, (value, key) => {
      if (_.includes(cleanFields, key)) {
        editableObj[key] = null;
      } else {
        editableObj[key] = value;
      }
    });
    editableObj['parent'] = instance.id;
    editableObj['editor_approved'] = false;
    editableObj['marketing_approved'] = false;

    setSelectedInstance(editableObj);
    setModalOpen(true);
  };

  const editInstance = async id => {
    const { data, success } = await detailSdk(id);

    if (success) {
      setSelectedInstance(data);
      setModalOpen(true);
    }
  };

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Tag</Table.HeaderCell>
          <Table.HeaderCell>Date to go out</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>
            <CreateButton
              size="tiny"
              icon
              inverted
              floated="right"
              disabled={instance.sending_instances.length === 2}
              onClick={() => addInstance()}>
              Add instance
            </CreateButton>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row key={instance.id}>
          <Table.Cell>{instance.tag ? instance.tag : 'N/A'}</Table.Cell>
          <Table.Cell>{formatDate(instance.datetime)}</Table.Cell>
          <Table.Cell>{instance.sending_status}</Table.Cell>
        </Table.Row>
        {instance.sending_instances.map(obj => (
          <Table.Row key={obj.id}>
            <Table.Cell>{obj.tag ? obj.tag : 'N/A'}</Table.Cell>
            <Table.Cell>{formatDate(obj.datetime)}</Table.Cell>
            <Table.Cell>{obj.sending_status}</Table.Cell>
            <Table.Cell>
              <CreateButton
                size="tiny"
                floated="right"
                icon
                disabled={obj.sending_status === 'Sent'}
                onClick={() => editInstance(obj.id)}>
                Edit
              </CreateButton>
              <DeleteModal
                key={`delete-modal-${obj.id}`}
                displayButton={false}
                itemId={obj.id}
                open={objToDelete === obj.id}
                objName="Sending instance"
                deleteButtonText="Delete sending instance"
                objDetails={`${obj.title} with ID: ${obj.id}`}
                sdk={deleteSendingInstance}
                onClose={() => clearInstances()}
              />
              <DeleteButton
                size="tiny"
                icon
                inverted
                floated="right"
                disabled={obj.sending_status === 'Sent'}
                onClick={() => {
                  setObjToDelete(obj.id);
                }}>
                Delete
              </DeleteButton>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <SendingInstanceForm
          instance={selectedInstance}
          onSave={() => clearInstances()}
        />
      </Modal>
    </Table>
  );
}

export default SendingInstancesTable;
