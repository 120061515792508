import React from 'react';

import { Header, Segment } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { Page, LoginRequired } from 'components';
import { PERMISSIONS, PermissionsRequired } from 'permissions';

import { StaffCreateForm } from './components';

const StaffCreate = ({ user, crumbs }) => (
  <Page user={user}>
    <Page.Body>
      <Page.Content crumbs={crumbs}>
        <Segment>
          <Header as="h1">Create Staff Member</Header>
          <StaffCreateForm user={user} />
        </Segment>
      </Page.Content>
    </Page.Body>
  </Page>
);

export default LoginRequired(
  PermissionsRequired([PERMISSIONS.CREATE_STAFF])(withRouter(StaffCreate))
);
