import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const priceLatestValue = async data => {
  const url = `${BASE_URL}/prices/indexes/prices/latest-values/`;
  return await requestSdk(() => get(url, data));
};

export const priceLatestIndex = async data => {
  const url = `${BASE_URL}/prices/indexes/latest-value/`;

  return await requestSdk(() => post(url, data));
};
