import React from 'react';

import _ from 'lodash';

import { Formik } from 'formik';

import { Header, Segment, Form, Table } from 'semantic-ui-react';

import { FormRow, UpdateButtonInRow, Field, FileField } from 'components';

import { notifySuccess, notifyErrors } from 'utils/notifications';
import { prepareFormData } from 'utils/forms';

import { dataImportSchema } from './utils';
import { courseAttendeesImport } from './sdk';

class AttendeeImportModal extends React.Component {
  state = {
    unregisteredEmails: []
  };

  handleSubmit = async (values, actions) => {
    const { courseId } = this.props;
    const { setSubmitting } = actions;

    setSubmitting(true);

    const { errors, data, success } = await courseAttendeesImport(
      courseId,
      prepareFormData(values)
    );

    if (success) {
      notifySuccess('Attendees have been imported.');

      if (_.isEmpty(data)) {
        this.close();
        return;
      }

      this.setState({ unregisteredEmails: data }, () => setSubmitting(false));
    } else {
      notifyErrors(errors);
      setSubmitting(false);

      this.close();
    }
  };

  close = () => {
    this.props.onClose();
  };

  render() {
    const initialValues = {
      dataFile: null
    };

    return (
      <>
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={initialValues}
          validationSchema={dataImportSchema}>
          {({ values, handleSubmit, isSubmitting }) => (
            <Form>
              <FormRow>
                <Field
                  required
                  name="dataFile"
                  component={FileField}
                  label="CSV"
                  accept={{ 'text/csv': ['.csv'] }}
                />
              </FormRow>
              <UpdateButtonInRow
                onClick={handleSubmit}
                label="Import"
                disabled={isSubmitting}
              />
            </Form>
          )}
        </Formik>
        <Segment>
          <Header as="h4">
            The imported CSV file should have the following format:
          </Header>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Email Address</Table.HeaderCell>
                <Table.HeaderCell>Attended</Table.HeaderCell>
                <Table.HeaderCell>...</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>example@kallanish.com</Table.Cell>
                <Table.Cell>Yes</Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>example2@kallanish.com</Table.Cell>
                <Table.Cell>No</Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Segment>
        <Header as="h4" color="red">
          The "Email Address" and "Attended" columns are required for the
          import. The CSV can contain other columns besides the ones in the
          example. All other columns except "Email Address" and "Attended" will
          be ignored.
        </Header>
        {!_.isEmpty(this.state.unregisteredEmails) && (
          <>
            <Header as="h4">Unregistered Emails:</Header>
            <Segment>
              {this.state.unregisteredEmails.map(email => (
                <div>{email}</div>
              ))}
            </Segment>
          </>
        )}
      </>
    );
  }
}

export default AttendeeImportModal;
