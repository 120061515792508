import React from 'react';

import { withRouter } from 'react-router-dom';

import { Header, Segment } from 'semantic-ui-react';

import { Page, LoginRequired } from 'components';
import { notifyErrors } from 'utils/notifications';

import { faqCategoryDetail } from './sdk';

import { FaqCategoryUpdateForm } from './components';

class FaqCategoryDetail extends React.Component {
  state = {
    faqCategory: null
  };

  fetchFaqCategory = async () => {
    const { faqCategoryId } = this.props.match.params;

    const { data, errors, success } = await faqCategoryDetail(faqCategoryId);

    if (success) {
      this.setState({ faqCategory: data });
    } else {
      notifyErrors(errors);
    }
  };

  componentDidMount() {
    this.fetchFaqCategory();
  }

  render() {
    const { user, crumbs } = this.props;

    const { faqCategory } = this.state;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Header as="h1">Edit FAQ Category</Header>

            {faqCategory && (
              <Segment>
                <FaqCategoryUpdateForm
                  faqCategory={faqCategory}
                  fetchFaqCategory={this.fetchFaqCategory}
                />
              </Segment>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(withRouter(FaqCategoryDetail));
