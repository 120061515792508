import React from 'react';

import { Header, Segment } from 'semantic-ui-react';

import { withLoggedUser } from 'hooks';

import { Page } from 'components';

import { CompanyV2CreateForm } from './components';

const CompanyV2Create = ({ user, crumbs }) => {
  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content crumbs={crumbs}>
          <Segment>
            <Header as="h1">Create Company</Header>
            <CompanyV2CreateForm />
          </Segment>
        </Page.Content>
      </Page.Body>
    </Page>
  );
};

export default withLoggedUser(CompanyV2Create);
