import React from 'react';

import { Loader as SemanticLoader } from 'semantic-ui-react';

const Loader = ({ as, ...props }) => {
  return (
    <SemanticLoader
      as={as}
      size="large"
      active
      inline="centered"
      content="Loading"
      {...props}
    />
  );
};

export default Loader;
