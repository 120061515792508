import _ from 'lodash';

import { slugified } from 'utils';

export const prepareNewCompanies = (companies, productSlug) => {
  const notCreated = _.filter(companies, function(n) {
    return !_.isInteger(n);
  });

  const newCompanies = notCreated.map(company => {
    return { title: company, slug: slugified(company), product: [productSlug] };
  });

  return newCompanies;
};
