export const EVENT_STATUSES = {
  speaker: 'Speaker',
  specialGuests: 'Special Guest',
  specialGuestsVirtual: 'Special Guest Virtual',
  registered: 'Registered (invoice sent)',
  paid: 'Paid Delegate',
  paidVirtual: 'Paid Delegate Virtual',
  sponsor: 'Sponsor',
  staff: 'Staff',
  press: 'Press'
};

export const SALES_EVENT_STATUSES = {
  pendingRegistration: 'Pending Registration',
  interested: 'Interested',
  contacted: 'Contacted',
  notInterested: 'Contacted - Not Interested',
  cantMakeIt: "Can't make it"
};

export const INVOICE_STATUSES = {
  paid: 'Paid',
  notPaid: 'Not paid',
  cancelled: 'Cancelled',
  paymentPromised: 'Payment promised',
  unknown: 'Unknown'
};
