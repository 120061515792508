import _ from 'lodash';
import { parseQueryString, stringifyParams } from 'utils';

export const getFiltersFromUrl = search => {
  const urlParams = parseQueryString(search);

  const omitKeys = ['order', 'count', 'page', 'limit', 'offset'];

  const filterParams = _.omit(urlParams, omitKeys);

  return filterParams;
};

export const stringifyFilters = filters => {
  const p = value => _.isNil(value) || value === '';

  const paramsWithValue = _.pickBy(filters, _.negate(p));

  return stringifyParams(paramsWithValue);
};
