import React, { useRef } from 'react';

import cx from 'classnames';

import _ from 'lodash';

import { Table, Input, Button, Icon, Popup } from 'semantic-ui-react';

import { Flex, LinkButton } from 'components';

import { DisableableElement } from 'permissions';

import {
  isDiffAbove10Percent,
  isLowGreaterThanHigh,
  compare,
  getAverage
} from 'pages/PriceSeries/utils';

import styles from './styles.module.css';

const SINGLE_PRICE = 'Single Price';
const RANGE = 'Range';

const PriceSeriesDataComment = ({
  open,
  onOpen,
  onClose,
  priceSeries,
  onPriceComment
}) => {
  const data = _.get(priceSeries, 'data');

  const isEdit = data && data.comment !== '';

  const label = isEdit ? 'Edit comment' : 'Add comment';
  const comment = data && data.comment ? data.comment : '';

  const textAreaInput = useRef(null);

  const onButtonClick = () => {
    onPriceComment(priceSeries, textAreaInput.current.value);
  };

  return (
    <Popup
      on="click"
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      content={
        <div className={styles.Cell}>
          <textarea
            style={{ minHeight: 100, maxHeight: 300 }}
            defaultValue={comment}
            ref={textAreaInput}
          />
          <Button onClick={onButtonClick}>Save</Button>
        </div>
      }
      trigger={
        <LinkButton>
          {isEdit ? (
            <strong className={styles.EditComment}>{label}</strong>
          ) : (
            label
          )}
        </LinkButton>
      }
    />
  );
};

class PriceSeriesData extends React.Component {
  state = {
    inEditMode: false,
    inCommentMode: false,
    value1: null,
    value2: null
  };

  componentDidMount() {
    const { value1, value2 } = this.getValuesFromProps();
    this.setState({ value1, value2 });
  }

  getValuesFromProps = () => {
    const { data } = this.props;

    const hasData = !_.isNil(data.data);

    const value1 = (hasData && data.data.value_1) || null;
    const value2 = (hasData && data.data.value_2) || null;

    return { value1, value2 };
  };

  resetState = () => {
    this.setState({ inEditMode: false, inCommentMode: false });
  };

  onPriceSave = () => {
    const hasWarning = this.hasWarning();
    const hasError = this.hasError();

    if (hasError) {
      let result = window.confirm('Low is greater than high. Are you sure?');
      if (!result) {
        return;
      }
    }

    if (hasWarning) {
      let result = window.confirm('The price is above 10% diff. Are you sure?');
      if (!result) {
        return;
      }
    }

    const { data, onPriceSave } = this.props;
    const { value1, value2 } = this.state;

    onPriceSave(data, value1 || null, value2 || null, this.resetState);
  };

  onPriceComment = (priceSeries, comment) => {
    const { onPriceComment } = this.props;

    onPriceComment(priceSeries, comment, this.resetState);
  };

  onInputChange = (value, event, stateKey) => {
    const inputValue = event.target.value;

    const hasChange = !compare(value, inputValue);
    const { inEditMode } = this.state;

    if (hasChange && !inEditMode) {
      this.setState({ inEditMode: true });
    } else if (!hasChange && inEditMode) {
      this.setState({ inEditMode: false });
    }

    this.setState({ [stateKey]: inputValue });
  };

  onCommentOpen = () => {
    this.setState({ inCommentMode: true });
  };

  onCommentClose = () => {
    this.setState({ inCommentMode: false });
  };

  hasWarning = () => {
    const { data, warnings } = this.props;
    const { value1, value2 } = this.state;

    const average = getAverage(value1, value2);

    const isAbove10Percent =
      data.mid_val !== 0 && isDiffAbove10Percent(data.mid_val, average);
    const includedInWarnings = (warnings || []).includes(data.date);

    const hasWarning = isAbove10Percent || includedInWarnings;

    return hasWarning;
  };

  hasError = () => {
    const { value1, value2 } = this.state;
    return isLowGreaterThanHigh(value1, value2);
  };

  render() {
    const { data, disabled } = this.props;
    const { inEditMode, inCommentMode } = this.state;

    const { value1, value2 } = this.getValuesFromProps();

    const hasWarning = this.hasWarning();
    const hasError = this.hasError();

    return (
      <Table.Cell
        className={cx({
          [styles.Warning]: hasWarning,
          [styles.Error]: hasError
        })}>
        <DisableableElement disabled={disabled}>
          <div className={styles.Cell}>
            {data.format === SINGLE_PRICE && (
              <Input
                key={`single-${value1}`}
                className={styles.Input}
                defaultValue={value1}
                onChange={event => this.onInputChange(value1, event, 'value1')}
              />
            )}
            {data.format === RANGE && (
              <div className={styles.Range}>
                <Flex>Low</Flex>
                <Input
                  key={`range-value1-${value1}`}
                  className={styles.Input}
                  defaultValue={value1}
                  onChange={event =>
                    this.onInputChange(value1, event, 'value1')
                  }
                />
                <Flex>High</Flex>
                <Input
                  key={`range-value2-${value2}`}
                  className={styles.Input}
                  defaultValue={value2}
                  onChange={event =>
                    this.onInputChange(value2, event, 'value2')
                  }
                />
              </div>
            )}
            {inEditMode && (
              <Button
                className={styles.SaveButton}
                icon
                color="green"
                onClick={this.onPriceSave}>
                <Icon name="check" />
              </Button>
            )}
          </div>
          <PriceSeriesDataComment
            open={inCommentMode}
            onOpen={this.onCommentOpen}
            onClose={this.onCommentClose}
            priceSeries={data}
            onPriceComment={this.onPriceComment}
          />
        </DisableableElement>
      </Table.Cell>
    );
  }
}

export default PriceSeriesData;
