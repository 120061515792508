import _ from 'lodash';

import React from 'react';

import { Field, FastField, AsyncSelectField } from 'components';

import { companyList } from './sdk';

class CompanyNewSelect extends React.Component {
  loadOptions = async (productSlug, inputValue, callback) => {
    if (inputValue.length < 3) {
      callback([]);
      return;
    }

    const { data } = await companyList(productSlug, inputValue);
    const options = data.map(company => {
      return {
        key: company.id,
        value: company.id,
        label: company.title
      };
    });
    callback(options);
  };

  render() {
    const { name, fast = false, productSlug, ...rest } = this.props;
    let Component = Field;
    if (fast) {
      Component = FastField;
    }

    return (
      <Component
        name={name || 'company'}
        component={AsyncSelectField}
        loadOptions={_.partial(this.loadOptions, productSlug)}
        isClearable
        {...rest}
      />
    );
  }
}

export default CompanyNewSelect;
