import React, { useState, useEffect, useCallback } from 'react';

import _ from 'lodash';
import { Formik } from 'formik';

import { Form } from 'semantic-ui-react';

import { handleFormErrors, prepareFormData } from 'utils/forms';

import { notifySuccess, notifyErrors } from 'utils/notifications';

import {
  Button,
  Html,
  Field,
  FormRow,
  InputField,
  RichTextAreaField
} from 'components';

import {
  clientGetLatestNewsletterIssue,
  clientSendLatestNewsletterIssue
} from './sdk';

import { sendLatestIssueSchema } from './schema';

function NewsletterIssue({
  product,
  client,
  productSlug,
  newsletterTypeSlug,
  onClose
}) {
  const [latestIssue, setLatestIssue] = useState(null);

  const fetchNewsletter = useCallback(async () => {
    const { success, data, errors } = await clientGetLatestNewsletterIssue(
      client.id,
      product.id,
      productSlug,
      newsletterTypeSlug
    );

    if (success) {
      setLatestIssue(data);
    } else {
      notifyErrors(errors);
    }
  }, [client.id, product.id, productSlug, newsletterTypeSlug]);

  const handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);
    setSubmitting(true);

    const { success, errors } = await clientSendLatestNewsletterIssue(
      client.id,
      product.id,
      data,
      productSlug,
      newsletterTypeSlug
    );

    setSubmitting(false);

    if (success) {
      setLatestIssue(null);
      notifySuccess('Latest issue sent.');
      onClose();
      return;
    }
    handleFormErrors(errors, setFieldError);
  };

  useEffect(() => {
    fetchNewsletter();
  }, [fetchNewsletter]);

  const initialValues = {
    subject: _.get(latestIssue, 'title', ''),
    html: _.get(latestIssue, 'html', ''),
    samplePdf: false,
    pdf: false
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={sendLatestIssueSchema}
        onSubmit={handleSubmit}>
        {({ values, handleSubmit, isSubmitting }) => (
          <Form>
            <FormRow>
              <Field
                required
                label="Subject"
                component={InputField}
                name="subject"
              />
              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={isSubmitting}>
                Send
              </Button>
            </FormRow>
            <FormRow>
              <Field label="HTML" component={RichTextAreaField} name="html" />
            </FormRow>
          </Form>
        )}
      </Formik>
      {latestIssue && <Html>{latestIssue.html}</Html>}
    </div>
  );
}

export default NewsletterIssue;
