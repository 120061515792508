import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Grid } from 'semantic-ui-react';
import { SortableList } from 'components';
import { pageUrl } from 'config/routes';
import { ContentSection } from 'pages';
import {
  contentSectionsList,
  contentSectionsReorder,
  contentSectionDelete
} from './sdk';
import { notifyErrors } from 'utils/notifications';

function SectionColumn({ newsletterId, columnId, groupBy }) {
  const { productSlug } = useParams();
  const [sections, setSections] = useState(null);

  const history = useHistory();

  const onSortEnd = ({ items }) => {
    contentSectionsReorder(items);
  };

  const getSections = useCallback(
    async groupBy => {
      const params = {
        newsletter_id: newsletterId,
        column: columnId,
        group_by: groupBy
      };
      const { errors, success, data } = await contentSectionsList(params);
      if (success) {
        setSections(data);
      } else {
        notifyErrors(errors);
      }
    },
    [newsletterId, columnId]
  );

  const handleEdit = sectionId => {
    history.push(
      pageUrl(ContentSection, { productSlug, newsletterId, sectionId })
    );
  };

  const handleDelete = () => {
    getSections(groupBy);
  };

  useEffect(() => {
    getSections(groupBy);
  }, [getSections, groupBy]);

  return (
    <Grid.Column>
      {sections && (
        <SortableList
          items={sections}
          onSortEnd={onSortEnd}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          deleteSdk={contentSectionDelete}
        />
      )}
    </Grid.Column>
  );
}
export default SectionColumn;
