import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const videoDetail = async id => {
  const url = `${BASE_URL}/cms/videos/${id}/`;

  return await requestSdk(() => get(url));
};

export const videoUpdate = async (id, data) => {
  const url = `${BASE_URL}/cms/video/${id}/update/`;

  return await requestSdk(() => post(url, data));
};
