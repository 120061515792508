import React from 'react';

import { Segment, Statistic } from 'semantic-ui-react';

const AverageRating = ({ rating }) => (
  <Segment compact>
    <Statistic size="mini" color="green">
      <Statistic.Label>Average Rating</Statistic.Label>
      <Statistic.Value>{rating}</Statistic.Value>
    </Statistic>
  </Segment>
);

export default AverageRating;
