import React from 'react';
import _ from 'lodash';
import { Field, FastField, SelectField } from 'components';

const ARTICLE_STATUSES = [
  'Work In Progress',
  'Ready for Editing',
  'Editing in Progress',
  'Ready for Publication',
  'Live'
];

class ArticleStatusSelect extends React.Component {
  state = {
    options: ARTICLE_STATUSES.map(status => ({
      key: status,
      value: status,
      label: status
    }))
  };

  render() {
    const { name, fast = false, ...rest } = this.props;

    const { options } = this.state;

    let Component = Field;
    if (fast) {
      Component = FastField;
    }

    if (!_.isEmpty(options)) {
      rest['options'] = options;
    }

    return (
      <Component
        name={name || 'articleStatus'}
        component={SelectField}
        isClearable
        {...rest}
      />
    );
  }
}

export default ArticleStatusSelect;
