import React from 'react';

import { ExternalLink } from 'components';

import { BASE_URL } from 'sdk/urls';

const FileManagerLink = ({ children }) => (
  <ExternalLink url={`${BASE_URL}/auth/from-dashboard/filer/`}>
    {children}
  </ExternalLink>
);

export default FileManagerLink;
