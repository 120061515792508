import React from 'react';
import _ from 'lodash';

import { Accordion, List, Icon } from 'semantic-ui-react';

import { Link, Flex } from 'components';
import { pageUrl } from 'config/routes';
import { ClientDetail } from 'pages';
import { formatDateTime } from 'utils';

import LinkForm from './LinkForm';

class LinkedUsersSection extends React.Component {
  state = { activeIndex: null };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { client, fetchClient } = this.props;
    const { activeIndex } = this.state;

    const linkedClient = _.get(client.client_profile.linked_client, 'id');

    const linkedClientNames = `${_.get(
      client.client_profile.linked_client,
      'first_name',
      ''
    )} ${_.get(client.client_profile.linked_client, 'last_name', '')}`;

    const linkedClientCompany = _.get(
      client.client_profile.linked_client,
      'company_name'
    );

    const reversedClients = client.reversed_links;

    const reversedClientsIds = _.map(client.reversed_links, 'id');

    return (
      <Accordion exclusive={false} styled>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={this.handleClick}>
          <Icon name="dropdown" />
          Linked Client{' '}
          {linkedClient && (
            <>
              {' - '}
              <Link
                url={pageUrl(ClientDetail, {
                  clientId: linkedClient
                })}>
                {linkedClient} {linkedClientNames}
              </Link>{' '}
              ({linkedClientCompany}){' '}
            </>
          )}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <LinkForm client={client} fetchClient={fetchClient} />
          {linkedClient && (
            <Flex flexStart>
              <span>
                Last updated at:{' '}
                <b>{formatDateTime(client.linked_client_updated_at)}</b>
              </span>
              (from {client.linked_client_updated_by})
            </Flex>
          )}
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 1}
          index={1}
          onClick={this.handleClick}>
          <Icon name="dropdown" />
          Reversed Links {!_.isEmpty(reversedClientsIds) && ' - '}
          {reversedClientsIds.map(id => (
            <Link
              url={pageUrl(ClientDetail, {
                clientId: id
              })}>
              {id}{' '}
            </Link>
          ))}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1}>
          <List divided relaxed ordered>
            {_.map(reversedClients, client => (
              <List.Item>
                <List.Header>
                  <Link
                    url={pageUrl(ClientDetail, {
                      clientId: client.id
                    })}>
                    {client.id}
                  </Link>{' '}
                  {client.first_name} {client.last_name}
                </List.Header>
              </List.Item>
            ))}
          </List>
          {_.isEmpty(reversedClients) && 'There are no reversed links yet.'}
        </Accordion.Content>
      </Accordion>
    );
  }
}

export default LinkedUsersSection;
