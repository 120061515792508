import React from 'react';
import _ from 'lodash';

import { Table, Icon } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import { notifySuccess, notifyErrors } from 'utils/notifications';

import { Button, YesNo } from 'components';

import { pageUrl } from 'config/routes';

import { PriceSerieDetail } from 'pages';

function PriceRow({
  productSlug,
  price,
  order,
  section,
  priceRemoveSdk,
  fetchPermanentPrices
}) {
  const deletePrice = async () => {
    const data = {
      section_slug: section.internal_slug,
      price: price.id
    };

    const { success, errors } = await priceRemoveSdk(productSlug, data);
    if (success) {
      notifySuccess('Price removed.');
      fetchPermanentPrices();
      return;
    } else {
      notifyErrors(errors);
    }
  };

  return (
    <Table.Row>
      <Table.Cell textAlign="center">{order + 1}</Table.Cell>
      <Table.Cell>
        <Link
          to={pageUrl(PriceSerieDetail, {
            productSlug,
            priceSeriesId: _.get(price, 'id')
          })}>
          {_.get(price, 'display_as')}
        </Link>
      </Table.Cell>
      <Table.Cell>
        <YesNo value={section.enabled} />
      </Table.Cell>
      <Table.Cell width={1} textAlign="center">
        <Button color="red" onClick={deletePrice} icon>
          <Icon name="trash" />
        </Button>
      </Table.Cell>
    </Table.Row>
  );
}

export default PriceRow;
