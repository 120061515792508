import React from 'react';
import _ from 'lodash';

import { Header, Modal, Label } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { Button, Tooltip } from 'components';

import SelectAccountManagerModal from './components/SelectAccountManagerModal';
import SelectCompanyNameModal from './components/SelectCompanyNameModal';
import AssignSubscriptionModal from './components/AssignSubscriptionModal';
import AssignTagModal from './components/AssignTagModal';
import ToggleAutoEmailsModal from './components/ToggleAutoEmailsModal';
import ToggleUnsubscribedModal from './components/ToggleUnsubscribedModal';
import TogglePowerMaterialsUnsubscribedModal from './components/TogglePowerMaterialsUnsubscribedModal';

const LIMITS = {
  CHANGE_ACCOUNT_MANAGER: 2000,
  CHANGE_COMPANY_NAME: 100,
  ASSIGN_SUBSCRIPTION: 1000,
  ASSIGN_TAG: 1000,
  TOGGLE_AUTO_EMAILS: 2000,
  TOGGLE_UNSUBSCRIBED: 2000,
  TOGGLE_POWER_MATERIALS_UNSUBSCRIBED: 2000
};

class BulkActionsModal extends React.Component {
  state = {
    modalOpen: false,
    isOpenSelectAccountManagerModal: false,
    isOpenSelectCompanyNameModal: false,
    isOpenAssignSubscriptionModal: false,
    isOpenAssignTagModal: false,
    isOpenToggleAutoEmailsModal: false,
    isOpenToggleUnsubscribedModal: false,
    isOpenTogglePowerMaterialsUnsubscribed: false
  };

  handleOpen = () => {
    this.setState({ modalOpen: true });
    this.props.onOpen();
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
    this.props.onClose();
  };

  toggleIsOpenSelectAccountManagerModal = () => {
    this.setState(prevState => ({
      isOpenSelectAccountManagerModal: !prevState.isOpenSelectAccountManagerModal
    }));
  };

  toggleIsOpenSelectCompanyNameModal = () => {
    this.setState(prevState => ({
      isOpenSelectCompanyNameModal: !prevState.isOpenSelectCompanyNameModal
    }));
  };

  toggleIsOpenAssignSubscriptionModal = () => {
    this.setState(prevState => ({
      isOpenAssignSubscriptionModal: !prevState.isOpenAssignSubscriptionModal
    }));
  };

  toggleIsOpenAssignTagModal = () => {
    this.setState(prevState => ({
      isOpenAssignTagModal: !prevState.isOpenAssignTagModal
    }));
  };

  toggleIsOpenToggleAutoEmailsModal = () => {
    this.setState(prevState => ({
      isOpenToggleAutoEmailsModal: !prevState.isOpenToggleAutoEmailsModal
    }));
  };

  toggleIsOpenToggleUnsubscribedModal = () => {
    this.setState(prevState => ({
      isOpenToggleUnsubscribedModal: !prevState.isOpenToggleUnsubscribedModal
    }));
  };

  toggleIsOpenTogglePowerMaterialsUnsubscribedModal = () => {
    this.setState(prevState => ({
      isOpenTogglePowerMaterialsUnsubscribed: !prevState.isOpenTogglePowerMaterialsUnsubscribed
    }));
  };

  render() {
    const { selectedClients, updateOnSuccess } = this.props;
    const title = 'Bulk Actions for selected clients';
    return (
      <>
        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          size="tiny"
          trigger={
            <Button
              data-testid="bulk-actions-btn"
              color="green"
              onClick={this.handleOpen}
              disabled={_.isEmpty(selectedClients)}>
              {title}
            </Button>
          }>
          <Header data-testid="bulk-actions-modal-title" content={title} />
          <Modal.Content>
            <Label
              data-testid="bulk-actions-modal-selected-clients"
              color="blue"
              basic
              size="large">
              Selected clients: {selectedClients.length}
            </Label>
            <Tooltip
              disabled={selectedClients.length <= LIMITS.CHANGE_ACCOUNT_MANAGER}
              on="hover"
              position="bottom center"
              content={`Max allowed clients for this bulk action is ${LIMITS.CHANGE_ACCOUNT_MANAGER}`}
              trigger={
                <div style={{ margin: '15px 0' }}>
                  <Button
                    fluid
                    color="green"
                    style={{ fontSize: '16px' }}
                    disabled={
                      selectedClients.length > LIMITS.CHANGE_ACCOUNT_MANAGER
                    }
                    onClick={this.toggleIsOpenSelectAccountManagerModal}>
                    Change Account Manager
                  </Button>
                </div>
              }
            />
            <Tooltip
              disabled={selectedClients.length <= LIMITS.CHANGE_COMPANY_NAME}
              on="hover"
              position="bottom center"
              content={`Max allowed clients for this bulk action is ${LIMITS.CHANGE_COMPANY_NAME}`}
              trigger={
                <div style={{ margin: '15px 0' }}>
                  <Button
                    fluid
                    color="green"
                    style={{ fontSize: '16px' }}
                    disabled={
                      selectedClients.length > LIMITS.CHANGE_COMPANY_NAME
                    }
                    onClick={this.toggleIsOpenSelectCompanyNameModal}>
                    Change Company Name
                  </Button>
                </div>
              }
            />
            <Tooltip
              disabled={selectedClients.length <= LIMITS.ASSIGN_SUBSCRIPTION}
              on="hover"
              position="bottom center"
              content={`Max allowed clients for this bulk action is ${LIMITS.ASSIGN_SUBSCRIPTION}`}
              trigger={
                <div style={{ margin: '15px 0' }}>
                  <Button
                    fluid
                    color="green"
                    style={{ fontSize: '16px' }}
                    disabled={
                      selectedClients.length > LIMITS.ASSIGN_SUBSCRIPTION
                    }
                    onClick={this.toggleIsOpenAssignSubscriptionModal}>
                    Assign Subscription
                  </Button>
                </div>
              }
            />
            <Tooltip
              disabled={selectedClients.length <= LIMITS.ASSIGN_TAG}
              on="hover"
              position="bottom center"
              content={`Max allowed clients for this bulk action is ${LIMITS.ASSIGN_TAG}`}
              trigger={
                <div style={{ margin: '15px 0' }}>
                  <Button
                    fluid
                    data-testid="bulk-actions-modal-assign-tags-btn"
                    color="green"
                    style={{ fontSize: '16px' }}
                    disabled={selectedClients.length > LIMITS.ASSIGN_TAG}
                    onClick={this.toggleIsOpenAssignTagModal}>
                    Assign Tags
                  </Button>
                </div>
              }
            />
            <Tooltip
              disabled={selectedClients.length <= LIMITS.TOGGLE_AUTO_EMAILS}
              on="hover"
              position="bottom center"
              content={`Max allowed clients for this bulk action is ${LIMITS.TOGGLE_AUTO_EMAILS}`}
              trigger={
                <div style={{ margin: '15px 0' }}>
                  <Button
                    fluid
                    data-testid="bulk-actions-modal-change-auto-emails-btn"
                    color="green"
                    style={{ fontSize: '16px' }}
                    disabled={
                      selectedClients.length > LIMITS.TOGGLE_AUTO_EMAILS
                    }
                    onClick={this.toggleIsOpenToggleAutoEmailsModal}>
                    Toggle Auto Emails
                  </Button>
                </div>
              }
            />
            <Tooltip
              disabled={selectedClients.length <= LIMITS.TOGGLE_UNSUBSCRIBED}
              on="hover"
              position="bottom center"
              content={`Max allowed clients for this bulk action is ${LIMITS.TOGGLE_UNSUBSCRIBED}`}
              trigger={
                <div style={{ margin: '15px 0' }}>
                  <Button
                    fluid
                    data-testid="bulk-actions-modal-toggle-unsubscribed-btn"
                    color="green"
                    style={{ fontSize: '16px' }}
                    disabled={
                      selectedClients.length > LIMITS.TOGGLE_UNSUBSCRIBED
                    }
                    onClick={this.toggleIsOpenToggleUnsubscribedModal}>
                    Toggle Unsubscribed
                  </Button>
                </div>
              }
            />
            <Tooltip
              disabled={
                selectedClients.length <=
                LIMITS.TOGGLE_POWER_MATERIALS_UNSUBSCRIBED
              }
              on="hover"
              position="bottom center"
              content={`Max allowed clients for this bulk action is ${LIMITS.TOGGLE_POWER_MATERIALS_UNSUBSCRIBED}`}
              trigger={
                <div style={{ margin: '15px 0' }}>
                  <Button
                    fluid
                    data-testid="bulk-actions-modal-toggle-power-materials-unsubscribed-btn"
                    color="green"
                    style={{ fontSize: '16px' }}
                    disabled={
                      selectedClients.length >
                      LIMITS.TOGGLE_POWER_MATERIALS_UNSUBSCRIBED
                    }
                    onClick={
                      this.toggleIsOpenTogglePowerMaterialsUnsubscribedModal
                    }>
                    Toggle Power Materials Unsubscribed
                  </Button>
                </div>
              }
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              data-testid="bulk-actions-modal-close-btn"
              color="red"
              inverted
              onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Actions>
        </Modal>
        {this.state.isOpenSelectAccountManagerModal && (
          <SelectAccountManagerModal
            onClose={this.toggleIsOpenSelectAccountManagerModal}
            selectedClients={selectedClients}
            onSuccess={data => {
              updateOnSuccess(data);
              this.handleClose();
            }}
          />
        )}
        {this.state.isOpenSelectCompanyNameModal && (
          <SelectCompanyNameModal
            onClose={this.toggleIsOpenSelectCompanyNameModal}
            selectedClients={selectedClients}
            onSuccess={data => {
              updateOnSuccess(data);
              this.handleClose();
            }}
          />
        )}
        {this.state.isOpenAssignSubscriptionModal && (
          <AssignSubscriptionModal
            onClose={this.toggleIsOpenAssignSubscriptionModal}
            selectedClients={selectedClients}
            onSuccess={data => {
              updateOnSuccess(data);
              this.handleClose();
            }}
          />
        )}
        {this.state.isOpenAssignTagModal && (
          <AssignTagModal
            onClose={this.toggleIsOpenAssignTagModal}
            selectedClients={selectedClients}
            onSuccess={data => {
              updateOnSuccess(data);
              this.handleClose();
            }}
          />
        )}
        {this.state.isOpenToggleAutoEmailsModal && (
          <ToggleAutoEmailsModal
            onClose={this.toggleIsOpenToggleAutoEmailsModal}
            selectedClients={selectedClients}
            onSuccess={data => {
              updateOnSuccess(data);
              this.handleClose();
            }}
          />
        )}
        {this.state.isOpenToggleUnsubscribedModal && (
          <ToggleUnsubscribedModal
            onClose={this.toggleIsOpenToggleUnsubscribedModal}
            selectedClients={selectedClients}
            onSuccess={data => {
              updateOnSuccess(data);
              this.handleClose();
            }}
          />
        )}
        {this.state.isOpenTogglePowerMaterialsUnsubscribed && (
          <TogglePowerMaterialsUnsubscribedModal
            onClose={this.toggleIsOpenTogglePowerMaterialsUnsubscribedModal}
            selectedClients={selectedClients}
            onSuccess={data => {
              updateOnSuccess(data);
              this.handleClose();
            }}
          />
        )}
      </>
    );
  }
}

export default withRouter(BulkActionsModal);
