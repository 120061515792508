import React from 'react';

import { Link } from 'react-router-dom';

import { Table, Segment } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { PriceSerieDetail } from 'pages';

import { Header } from 'components';

const TopPriceTable = ({ priceData, actionType }) => (
  <Segment compact style={{ margin: '0 1.5rem 0 0' }}>
    <Header>Top Price Series by {actionType}s</Header>
    <Table celled compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Price</Table.HeaderCell>

          <Table.HeaderCell>Total Times {actionType}ed</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {priceData.map(price => (
          <Table.Row>
            <Table.Cell>
              <Link
                to={pageUrl(PriceSerieDetail, {
                  productSlug: price.product_slug,
                  priceSeriesId: price.id
                })}>
                {price.display_as}
              </Link>
            </Table.Cell>
            <Table.Cell>
              {actionType === 'Open'
                ? price.total_price_opens
                : price.total_price_downloads}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  </Segment>
);

export default TopPriceTable;
