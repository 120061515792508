import React, { useEffect, useState, useCallback } from 'react';
import { NotFound } from 'pages';

import { pageUrl } from 'config/routes';

import { hasPermissions } from 'permissions/utils';

const withPermissions = permissions => Component => props => {
  const [permitted, setPermitted] = useState(false);

  const { user, history: push } = props;

  const isPermitted = useCallback(() => {
    let userHasPermissions = hasPermissions(user, permissions);

    if (!userHasPermissions) {
      push(pageUrl(NotFound));
    }
    setPermitted(userHasPermissions);
  }, [push, user]);

  useEffect(() => {
    isPermitted();
  }, [isPermitted]);

  return permitted ? <Component {...props} /> : null;
};

export default withPermissions;
