import { DateField } from 'components';

export const FILTERS = [
  [
    {
      name: 'date_from',
      emptyValue: null,
      component: DateField,
      label: 'Date from',
      width: 4
    },
    {
      name: 'date_to',
      emptyValue: null,
      component: DateField,
      label: 'Date to',
      width: 4
    }
  ]
];
