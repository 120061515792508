import * as yup from 'yup';

import { formatDate } from 'utils';
import 'utils/yup';

export const buildInitialValues = data => {
  const {
    username,
    email,
    image,
    twitter,
    description,
    linkedin,
    location,
    slug,
    first_name: firstName,
    last_name: lastName,
    signature_html: signatureHtml,
    signature_text: signatureText,
    last_login: lastLogin,
    date_joined: dateJoined,
    is_active: isActive,
    sales_territories
  } = data;

  const initialValues = {
    username,
    email,
    firstName,
    lastName,
    isActive,
    signatureHtml,
    signatureText,
    image,
    twitter,
    description,
    linkedin,
    location,
    slug,
    salesTerritories: sales_territories.map(st => st.id)
  };

  const nonFormValues = {
    lastLogin,
    dateJoined
  };
  return { initialValues, nonFormValues };
};

export const staffValidationSchema = yup.object().shape({
  email: yup
    .string()
    .strongEmail('Invalid email.')
    .required('Email is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  username: yup.string().required('Username is required')
});

export const getNonFormItems = values => [
  { label: 'Last login', value: formatDate(values.lastLogin) },
  { label: 'Date joined', value: formatDate(values.dateJoined) }
];
