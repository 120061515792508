import React from 'react';

import { Field, SelectField } from 'components';

import { regionList } from './sdk';

class RegionSelect extends React.Component {
  state = {
    regions: []
  };

  async componentDidMount() {
    const { data, success } = await regionList();

    if (success) {
      this.setState({ regions: data });
    }
  }

  render() {
    const { name, isCustom, ...rest } = this.props;
    const { regions } = this.state;

    const options = regions.map(region => {
      return {
        key: region.id,
        value: region.id,
        label: region.title
      };
    });

    return (
      <Field
        name={name || 'region'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default RegionSelect;
