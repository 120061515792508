import React from 'react';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import { Segment, Table, Dimmer, Loader } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { RenewalClientListV2 } from 'pages';

class SnapshotsStatsTable extends React.Component {
  render() {
    const { data, filters } = this.props;

    const month = _.get(filters, 'renewal_month');

    return (
      <Segment>
        {_.isNil(data) && (
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        )}
        {!_.isNil(data) && (
          <Table celled selectable definition={true}>
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell textAlign="right">
                  Renewal State
                </Table.HeaderCell>
                <Table.HeaderCell>Count</Table.HeaderCell>
                <Table.HeaderCell>Converted to GBP</Table.HeaderCell>
                <Table.HeaderCell>Upsell</Table.HeaderCell>
                <Table.HeaderCell>Upsell %</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {_.map(data, (value, key) => (
                <Table.Row textAlign="center">
                  <Table.Cell width={2} textAlign="right">
                    {key}
                  </Table.Cell>
                  <Table.Cell>
                    <Link
                      to={{
                        pathname: pageUrl(RenewalClientListV2),
                        search:
                          month &&
                          `?renewal_month=${month}&renewal_state=${key}`
                      }}>
                      {value.count}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    {!_.isNil(value.past_converted_value)
                      ? value.past_converted_value.toFixed(0)
                      : 0}
                  </Table.Cell>
                  <Table.Cell>
                    {!_.isNil(value.upsell_value)
                      ? value.upsell_value.toFixed(0)
                      : 0}{' '}
                  </Table.Cell>
                  <Table.Cell>
                    {!_.isNil(value.upsell_percentage)
                      ? value.upsell_percentage.toFixed(0)
                      : 0}
                    %
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </Segment>
    );
  }
}

export default withRouter(SnapshotsStatsTable);
