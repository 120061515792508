import React from 'react';

import _ from 'lodash';

import cx from 'classnames';

import { withRouter } from 'react-router-dom';

import { Header, Segment, Divider, Button } from 'semantic-ui-react';

import {
  Page,
  LoginRequired,
  Flex,
  ContentEditionWarning,
  OpenModalButton
} from 'components';

import {
  PermissionsRequired,
  VisibleIf,
  DisableableElement,
  hasPermissions,
  PERMISSIONS
} from 'permissions';

import { pageUrl } from 'config/routes';

import {
  InvoiceList,
  AutoCustomEmailLogList,
  MailshotEmailLogList,
  DailyNewsletterEmailLogListV2,
  TrackingComments,
  TrackingFeedback
} from 'pages';

import { notifySuccess, notifyErrors } from 'utils/notifications';

import {
  EDITION_WARNING_CONTENT_TYPES,
  STEEL_SLUG,
  POWER_MATERIAL_SLUG
} from 'domainConstants';

import {
  clientDetail,
  clientSendResetPassword,
  clientRemoveFromSuppression
} from './sdk';

import {
  ClientUpdateForm,
  ProductAddForm,
  Products,
  NonCorporateMasterClientProfileForm,
  CorporateMasterClientProfileForm,
  ClientReminderForm,
  ClientReminders,
  SendPresetEmailForm,
  ClientBreadcrumb,
  LinkedUsersSection,
  ClientInfoTable,
  PasswordTokensModal
} from './components';

import styles from './styles.module.css';
import ClientSnapshotModal from './components/ClientSnapshotModal';

class ClientDetail extends React.Component {
  state = {
    client: null
  };

  fetchClient = async () => {
    const { clientId } = this.props.match.params;

    const { data, errors, success } = await clientDetail(clientId);

    if (success) {
      this.setState({ client: data });
    } else {
      notifyErrors(errors);
    }
  };

  sendResetPassword = async () => {
    const { clientId } = this.props.match.params;

    const { errors, success } = await clientSendResetPassword(clientId);

    if (success) {
      notifySuccess('Reset password email sent');
    } else {
      notifyErrors(errors);
    }
  };

  removeFromSuppression = async () => {
    const { clientId } = this.props.match.params;

    const { data, errors, success } = await clientRemoveFromSuppression(
      clientId
    );

    if (success) {
      notifySuccess(data.text);
    } else {
      notifyErrors(errors);
    }
  };

  redirectTo = (page, params) => {
    this.props.history.push({
      pathname: pageUrl(page, params),
      search: `?client=${this.state.client.id}`
    });
  };

  openClientInvoicesUrl = clientId => {
    this.props.history.push({
      pathname: pageUrl(InvoiceList),
      search: `?client_id=${clientId}`
    });
  };

  openClientComments = clientId => {
    this.props.history.push({
      pathname: pageUrl(TrackingComments),
      search: `?created_by_id=${clientId}`
    });
  };

  openClientFeedback = clientId => {
    this.props.history.push({
      pathname: pageUrl(TrackingFeedback),
      search: `?user_id=${clientId}`
    });
  };

  componentDidMount() {
    this.fetchClient();
  }

  componentDidUpdate(prevProps, prevState) {
    const { clientId } = this.props.match.params;
    const { client } = prevState;

    if (client && clientId.toString() !== client.id.toString()) {
      this.fetchClient();
    }
  }

  getClientId = client => {
    if (client.client_profile.is_corporate_master_profile) {
      return `CM ${client.id}`;
    }

    if (client.client_profile.corporate_master_account) {
      return `C ${client.id}`;
    }

    return `${client.id}`;
  };

  render() {
    const { user, crumbs } = this.props;

    const { client } = this.state;

    const isCorporateMasterProfile =
      client && client.client_profile.is_corporate_master_profile;

    const canChangeClient = hasPermissions(user, [PERMISSIONS.CHANGE_CLIENTS]);

    const hasComments = _.get(client, 'has_comments', false);

    const hasFeedback = _.get(client, 'has_feedback', false);

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content
            crumbs={crumbs}
            additionalContent={<ClientBreadcrumb client={client} />}>
            {!_.isNil(client) && (
              <ContentEditionWarning
                user={user}
                contentType={EDITION_WARNING_CONTENT_TYPES.CLIENT}
                contentId={client.id}
              />
            )}

            <Flex spaceBetween>
              <Header as="h1">Edit client</Header>
              <div>
                {hasComments && (
                  <Button onClick={() => this.openClientComments(client.id)}>
                    Comments
                  </Button>
                )}
                {hasFeedback && (
                  <Button onClick={() => this.openClientFeedback(client.id)}>
                    Feedback
                  </Button>
                )}
                <VisibleIf
                  user={user}
                  permissions={[PERMISSIONS.VIEW_INVOICES]}>
                  <Button onClick={() => this.openClientInvoicesUrl(client.id)}>
                    Invoices
                  </Button>
                </VisibleIf>
                <Button
                  onClick={() =>
                    this.redirectTo(DailyNewsletterEmailLogListV2, {
                      productSlug: STEEL_SLUG
                    })
                  }>
                  Newsletters
                </Button>
                <Button
                  onClick={() =>
                    this.redirectTo(DailyNewsletterEmailLogListV2, {
                      productSlug: POWER_MATERIAL_SLUG
                    })
                  }>
                  PM Newsletters
                </Button>
                <Button onClick={() => this.redirectTo(MailshotEmailLogList)}>
                  Mailshots
                </Button>
                <Button onClick={() => this.redirectTo(AutoCustomEmailLogList)}>
                  Auto
                </Button>
              </div>
            </Flex>
            {!_.isNil(client) && (
              <Flex spaceBetween style={{ gap: '8px' }}>
                {/* TODO: Disabled for deployment */}

                <LinkedUsersSection
                  client={client}
                  fetchClient={this.fetchClient}
                />

                <Flex alignStart>
                  <VisibleIf
                    user={user}
                    permissions={[PERMISSIONS.SEND_EMAILS_TO_CLIENTS]}>
                    <Flex
                      style={{
                        alignItems: 'stretch'
                      }}>
                      <Button color="red" onClick={this.removeFromSuppression}>
                        Remove from SES suppression list
                      </Button>
                      <OpenModalButton
                        inverted
                        size="small"
                        color="red"
                        modal={{
                          header: 'Password Reset Tokens',
                          render: props => (
                            <PasswordTokensModal client={client} {...props} />
                          )
                        }}>
                        View Password Tokens
                      </OpenModalButton>
                    </Flex>
                  </VisibleIf>

                  <Flex
                    style={{
                      flexDirection: 'column',
                      gap: '4px',
                      alignItems: 'stretch'
                    }}>
                    <VisibleIf
                      user={user}
                      permissions={[PERMISSIONS.SEND_EMAILS_TO_CLIENTS]}>
                      <Button color="red" onClick={this.sendResetPassword}>
                        Send reset password
                      </Button>
                    </VisibleIf>
                    <VisibleIf
                      user={user}
                      permissions={[PERMISSIONS.CHANGE_CLIENTS]}>
                      <OpenModalButton
                        size="small"
                        modal={{
                          header: 'Client Snapshot',
                          render: props => (
                            <ClientSnapshotModal clientId={client.id} />
                          )
                        }}>
                        Manage snapshot
                      </OpenModalButton>
                    </VisibleIf>
                  </Flex>
                </Flex>
              </Flex>
            )}
            {client && (
              <>
                <Segment
                  className={cx({
                    [styles.Unsubscribed]: client.client_profile.unsubscribed
                  })}>
                  <ClientInfoTable client={client} />
                </Segment>

                <VisibleIf
                  user={user}
                  permissions={[PERMISSIONS.VIEW_PRODUCTS]}>
                  <DisableableElement
                    disabled={
                      !hasPermissions(user, [
                        PERMISSIONS.CHANGE_CLIENTS,
                        PERMISSIONS.CREATE_PRODUCTS
                      ])
                    }>
                    <Segment>
                      <ProductAddForm
                        client={client}
                        fetchClient={this.fetchClient}
                      />
                    </Segment>
                  </DisableableElement>

                  <Products
                    user={user}
                    client={client}
                    fetchClient={this.fetchClient}
                  />
                </VisibleIf>
                <Segment>
                  <Header as="h2">
                    Client information {this.getClientId(client)}
                  </Header>
                  <DisableableElement disabled={!canChangeClient}>
                    <ClientUpdateForm
                      client={client}
                      fetchClient={this.fetchClient}
                      user={user}
                    />
                  </DisableableElement>
                </Segment>
                <DisableableElement disabled={!canChangeClient}>
                  {!isCorporateMasterProfile && (
                    <NonCorporateMasterClientProfileForm
                      client={client}
                      fetchClient={this.fetchClient}
                    />
                  )}

                  {isCorporateMasterProfile && (
                    <CorporateMasterClientProfileForm
                      client={client}
                      fetchClient={this.fetchClient}
                    />
                  )}
                </DisableableElement>

                <VisibleIf
                  user={user}
                  permissions={[PERMISSIONS.SEND_EMAILS_TO_CLIENTS]}>
                  <SendPresetEmailForm client={client} />
                </VisibleIf>

                <DisableableElement disabled={!canChangeClient}>
                  <Segment>
                    <Header as="h2">Client reminders</Header>
                    <ClientReminderForm
                      client={client}
                      fetchClient={this.fetchClient}
                    />
                    <Divider />
                    {_.isEmpty(client.client_reminders) && (
                      <div>
                        There are no client reminders for this client yet. Add
                        one from above.
                      </div>
                    )}

                    {!_.isEmpty(client.client_reminders) && (
                      <ClientReminders
                        client={client}
                        fetchClient={this.fetchClient}
                      />
                    )}
                  </Segment>
                </DisableableElement>
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(
  withRouter(PermissionsRequired([PERMISSIONS.VIEW_CLIENTS])(ClientDetail))
);
