import { useState } from 'react';

import { Button, Header, Icon, Modal, Table } from 'semantic-ui-react';
import { Flex, NoDataMessage, SortableHeader } from 'components/index';
import { notifyErrors } from 'utils/notifications';
import { useSortable } from 'utils/sort';

import { emailLogStatisticIPAggregation } from './sdk';

function TableWrapper({ children }) {
  return (
    <div
      style={{
        maxHeight: '350px',
        overflowY: 'auto',
        display: 'block',
        width: '100%'
      }}>
      {children}
    </div>
  );
}

const EmailLogStatisticIPAggregationModal = ({ logType, log }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [aggregationData, setAggregationData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { data, sort, handleSortUpdate } = useSortable({
    defaultData: aggregationData
  });

  const fetchEmailLogStatisticIPAggregation = async () => {
    setModalOpen(true);
    setLoading(true);

    const { data, errors, success } = await emailLogStatisticIPAggregation(
      logType,
      log.id
    );

    if (success) {
      setAggregationData(data);
    } else {
      notifyErrors(errors);
    }
    setLoading(false);
  };

  return (
    <Modal
      open={modalOpen}
      size="small"
      centered={false}
      trigger={
        <Button
          color="blue"
          onClick={fetchEmailLogStatisticIPAggregation}
          disabled={log.statistic_logs_count === 0}>
          View IP Aggregation
        </Button>
      }>
      <Modal.Header>
        <Flex spaceBetween>
          <Header as="h2" style={{ margin: 0 }}>
            IP Aggregation
          </Header>
          <Icon name="close" onClick={() => setModalOpen(false)} />
        </Flex>
      </Modal.Header>
      <Modal.Content>
        {!loading && data.length > 0 && (
          <TableWrapper>
            <Table celled selectable>
              <Table.Header style={{ position: 'sticky', zIndex: 1, top: 0 }}>
                <Table.Row>
                  <Table.HeaderCell>IP Address</Table.HeaderCell>
                  <SortableHeader
                    sort={sort}
                    orderBy={handleSortUpdate}
                    field="count">
                    Count
                  </SortableHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.map((log, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{log.ip_address}</Table.Cell>
                    <Table.Cell>{log.count}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </TableWrapper>
        )}
        {!loading && data.length === 0 && <NoDataMessage />}
      </Modal.Content>
    </Modal>
  );
};

export default EmailLogStatisticIPAggregationModal;
