import React from 'react';

import { Formik } from 'formik';

import {
  Header,
  Segment,
  Form,
  Table,
  Modal,
  Button,
  Icon
} from 'semantic-ui-react';

import { FormRow, UpdateButton, Field, FileField, Flex } from 'components';

import { notifySuccess, notifyErrors } from 'utils/notifications';
import { prepareFormData } from 'utils/forms';

import { dataImportSchema } from './utils';
import { priceSeriesDataImport } from './sdk';

class PriceSeriesDataImportModal extends React.Component {
  handleSubmit = async (values, actions) => {
    const { priceSeriesId } = this.props;
    const { setSubmitting } = actions;

    const confirmationMessage =
      'This action will remove all existing historical data for the price.\n Are you sure you want to continue?';

    if (!window.confirm(confirmationMessage)) {
      setSubmitting(false);
      return;
    }

    setSubmitting(true);

    const { errors, success } = await priceSeriesDataImport(
      priceSeriesId,
      prepareFormData(values)
    );

    if (success) {
      notifySuccess('Price series data import started.');
      this.close();

      return;
    } else {
      notifyErrors(errors);
      setSubmitting(false);

      this.close();
    }
  };

  close = () => {
    this.props.onClose();
  };

  render() {
    const initialValues = {
      dataFile: null
    };

    return (
      <Modal open={true} size="large" centered={false}>
        <Modal.Header>
          <Flex spaceBetween>
            <Header as="h2">Price Series Data Import</Header>
            <Icon name="close" onClick={this.close} />
          </Flex>
        </Modal.Header>
        <Modal.Content>
          <Segment color="red">
            <Header as="h5" color="red">
              This action will remove all existing historical data for the Price
              Serie! The data will be backed up as a CSV file and sent to the
              importing staff member via email before being overwriten.
            </Header>
          </Segment>
          <Formik
            onSubmit={this.handleSubmit}
            initialValues={initialValues}
            validationSchema={dataImportSchema}>
            {({ values, handleSubmit, isSubmitting }) => (
              <Form>
                <FormRow>
                  <Field
                    required
                    name="dataFile"
                    component={FileField}
                    label="CSV"
                  />
                </FormRow>
                <FormRow flexEnd>
                  <UpdateButton
                    onClick={handleSubmit}
                    label="Begin Import"
                    disabled={isSubmitting}
                  />
                </FormRow>
              </Form>
            )}
          </Formik>
          <Segment>
            <Header as="h4">
              The imported CSV file should have the following format:
            </Header>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Value 1</Table.HeaderCell>
                  <Table.HeaderCell>Value 2</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>01-Jan-20</Table.Cell>
                  <Table.Cell>50.10</Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>01-Feb-20</Table.Cell>
                  <Table.Cell>450</Table.Cell>
                  <Table.Cell>470</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Header as="h4" color="red">
              A backup CSV from the automated email to importing staff members
              is also accepted.
            </Header>
          </Segment>
        </Modal.Content>

        <Modal.Actions>
          <Button color="red" onClick={this.close}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default PriceSeriesDataImportModal;
