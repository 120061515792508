import _ from 'lodash';
import React from 'react';

import { Segment, Header } from 'semantic-ui-react';

import { notifySuccess, notifyErrors } from 'utils/notifications';
import { SortableList } from 'components';

function ArticlesSortableContainer({
  newsletter,
  section,
  articles,
  fetchNewsletterContentSections,
  newsletterOrderSection,
  articleSection = null,
  articleGroup = null
}) {
  const onSortEnd = async ({ items }) => {
    const data = {
      articles: _.map(items, 'id'),
      section_slug: section,
      article_group: articleGroup,
      article_section: articleSection
    };

    const postData = _.pickBy(data, _.negate(_.isNil));

    const { success, errors } = await newsletterOrderSection(
      newsletter.id,
      postData
    );

    if (success) {
      fetchNewsletterContentSections();
      notifySuccess('Articles reordered.');
    } else {
      notifyErrors(errors);
    }
  };

  const items = articles.map(article => ({
    // SortableList requires title prop
    title: article.full_headline,
    ...article
  }));

  return (
    <>
      {section === 'selected-articles' && newsletter.version === 'V1' ? (
        <div>
          This newsletter uses an older version of the template. To preview, use
          the Preview or Send buttons in the Send / Preview Newsletter section.
        </div>
      ) : (
        <SortableList
          disabled={newsletter.live}
          items={items}
          onSortEnd={onSortEnd}
        />
      )}
    </>
  );
}

const ArticlesSection = ({ sectionName, color, ...props }) => {
  return (
    <Segment color={color}>
      <Header as="div" size="tiny">
        {sectionName}
      </Header>
      <ArticlesSortableContainer {...props} />
    </Segment>
  );
};

export default ArticlesSection;
