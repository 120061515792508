import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const weeklyEditionOpenCount = async (editionType, params) => {
  const url = `${BASE_URL}/newsletters/${editionType}/open-count/`;

  return await requestSdk(() => get(url, params));
};

export const reportEditionOpenCount = async (editionType, params) => {
  const url = `${BASE_URL}/reports/${editionType}/open-count/`;

  return await requestSdk(() => get(url, params));
};
