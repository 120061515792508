import _ from 'lodash';

import { MonthSelect, DateField } from 'components';

export const calculateRenewalVolume = data => {
  const bookedRenewalsCount = _.get(data, 'Booked', []).count;

  const paidRenewalsCount = _.get(data, 'Paid', []).count;

  const totalRenewalsCount = _.sumBy(
    _.map(data, (value, key) => value),
    'count'
  );

  let volumePercentageResult = 0;

  if (totalRenewalsCount > 0) {
    volumePercentageResult = (
      ((bookedRenewalsCount + paidRenewalsCount) / totalRenewalsCount) *
      100
    ).toFixed(0);
  }

  const volumeResult = `${bookedRenewalsCount +
    paidRenewalsCount} / ${totalRenewalsCount}`;

  return { volumeResult, volumePercentageResult };
};

export const calculateRenewalValue = data => {
  const bookedRenewalsValue = _.get(data, 'Booked', []).converted_value;

  const paidRenewalsValue = _.get(data, 'Paid', []).converted_value;

  const totalRenewalsValue = _.sumBy(
    _.map(data, (value, key) => value),
    'past_converted_value'
  );

  let valuePercentageResult = 0;

  if (totalRenewalsValue > 0) {
    valuePercentageResult = (
      ((bookedRenewalsValue + paidRenewalsValue) / totalRenewalsValue) *
      100
    ).toFixed(0);
  }
  const valueResult = `${(bookedRenewalsValue + paidRenewalsValue).toFixed(
    0
  )} / ${totalRenewalsValue.toFixed(0)}`;

  return { valueResult, valuePercentageResult };
};

export const FILTERS = [
  [
    {
      name: 'renewal_month',
      emptyValue: null,
      fieldComponent: MonthSelect,
      transform: value => parseInt(value, 10),
      label: 'Month',
      width: 3
    },
    {
      name: 'start_date',
      emptyValue: null,
      component: DateField,
      label: 'Start Date',
      width: 3
    },
    {
      name: 'end_date',
      emptyValue: null,
      component: DateField,
      label: 'End Date',
      width: 3
    }
  ]
];
