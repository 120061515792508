import React from 'react';

import { withRouter } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import { pageUrl } from 'config/routes';

import { ClientReminderList } from 'pages';

import { handleFormErrors, prepareFormData } from 'utils/forms';

import { clientReminderCreateSchema } from './schema';
import { clientReminderCreate } from './sdk';

import {
  Field,
  FormRow,
  DateField,
  InputField,
  CheckboxField,
  HourSelect,
  ReminderSelect,
  CreateButtonInRow,
  UnsavedFormPrompt
} from 'components';

class ClientReminderCreateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);
    const { errors, success } = await clientReminderCreate(data);

    if (success) {
      this.props.history.push(pageUrl(ClientReminderList));
      return;
    }
    setSubmitting(false);

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const initialValues = {
      sentDate: null,
      time: 8,
      description: '',
      isDone: false,
      isSent: false,
      client: '',
      reminder: null
    };

    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        validationSchema={clientReminderCreateSchema}>
        {({ values, handleSubmit, isSubmitting, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty && !isSubmitting} />
            <FormRow>
              <Field
                required
                width={4}
                name="sentDate"
                component={DateField}
                label="Sent date"
              />

              <HourSelect
                required
                width={3}
                name="time"
                label="Time:"
                isClearable={false}
              />
            </FormRow>

            <Field
              required
              name="description"
              width={12}
              component={InputField}
              label="Description"
            />

            <FormRow>
              <Field
                required
                name="reminder"
                component={ReminderSelect}
                label="Reminder"
                width={5}
              />

              <Field
                required
                width={4}
                name="client"
                component={InputField}
                label="Client id"
              />
              <Field
                width={2}
                name="isDone"
                component={CheckboxField}
                label="Is done"
              />

              <Field
                width={2}
                name="isSent"
                component={CheckboxField}
                label="Is sent"
              />
            </FormRow>
            <CreateButtonInRow onClick={handleSubmit} disabled={isSubmitting} />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(ClientReminderCreateForm);
