import React from 'react';

import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import { formatDateTime } from 'utils';
import { FormRow } from 'components';

import styles from './styles.module.css';

const DisplayItem = ({ label, value }) => (
  <div className={styles.item}>
    <label>{label}</label>
    <div>{value ? value : 'None'}</div>
  </div>
);

class NonFormValuesDisplay extends React.Component {
  render() {
    const {
      created_at: createdAt,
      created_by,
      updated_at: updatedAt,
      updated_by
    } = this.props.faq;

    const createdBy = `${_.get(created_by, 'first_name', '')} ${_.get(
      created_by,
      'last_name',
      ''
    )}`;

    const updatedBy = `${_.get(updated_by, 'first_name', 'N/A')} ${_.get(
      updated_by,
      'last_name',
      ''
    )}`;

    return (
      <>
        <FormRow className={styles.container}>
          <DisplayItem label="Created by:" value={createdBy} />
          <DisplayItem label="Created at:" value={formatDateTime(createdAt)} />
          <DisplayItem label="Updated by:" value={updatedBy} />
          <DisplayItem label="Updated at:" value={formatDateTime(updatedAt)} />
        </FormRow>
      </>
    );
  }
}
export default withRouter(NonFormValuesDisplay);
