import React from 'react';

import _ from 'lodash';

import { Form } from 'semantic-ui-react';

import { useParams } from 'react-router-dom';

import { Formik } from 'formik';

import { UniSelect } from 'components';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import {
  addArticleToNewsletterSection,
  removeArticleFromNewsletterSection
} from '../sdk';

import styles from './styles.module.css';

function ProductTypeGroupForm({
  article,
  newsletter,
  fetchNewsletterContentSections,
  articleProductTypeGroup
}) {
  const { productSlug } = useParams();

  const handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    setSubmitting(true);

    let data = {
      ...prepareFormData(values),
      section_slug: 'product-type-group-articles',
      keep_in_section_slug: 'region-articles'
    };

    let sdk = addArticleToNewsletterSection;

    if (_.isNil(values.articleGroup)) {
      data['article_group'] = articleProductTypeGroup;
      sdk = removeArticleFromNewsletterSection;
    }

    const { errors, success } = await sdk(newsletter.id, data);

    setSubmitting(false);

    if (success) {
      if (_.isNil(values.articleGroup)) {
        notifySuccess(
          `${article.full_headline} removed from product type group.`
        );
      } else {
        notifySuccess(`${article.full_headline} added to product type group.`);
      }
      fetchNewsletterContentSections();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  const onChange = submitForm => {
    // https://github.com/jaredpalmer/formik/issues/1218
    setTimeout(submitForm);
  };

  const initialValues = {
    article: article.id,
    articleGroup: articleProductTypeGroup
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={handleSubmit}>
      {({ values, submitForm, isSubmitting, setFieldValue }) => (
        <Form className={styles.Grid}>
          <input type="hidden" name="article" />
          <UniSelect
            name="articleGroup"
            productSlug={productSlug}
            source="articleGroups"
            className={styles.SmallerSelect}
            onChange={() => onChange(submitForm)}
          />
        </Form>
      )}
    </Formik>
  );
}

export default ProductTypeGroupForm;
