import React, { useState } from 'react';

import { ChoiceToggle, Flex } from 'components';

import { PriceSeriesTable, PriceSpreadsTable } from './components';

const SHOW_PRICE_SERIES = 'price_series';
const SHOW_PRICE_SPREADS = 'price_spreads';

export default function ClientSnapshotModal({ clientId }) {
  const [selectedView, setSelectedView] = useState(SHOW_PRICE_SERIES);

  return (
    <Flex style={{ flexDirection: 'column', gap: '10px' }}>
      <ChoiceToggle
        leftOption={{
          predicate: selectedView === SHOW_PRICE_SERIES,
          onClick: () => setSelectedView(SHOW_PRICE_SERIES),
          text: 'Price Series'
        }}
        rightOption={{
          predicate: selectedView === SHOW_PRICE_SPREADS,
          onClick: () => setSelectedView(SHOW_PRICE_SPREADS),
          text: 'Price Spreads'
        }}
      />
      {selectedView === SHOW_PRICE_SERIES && (
        <PriceSeriesTable clientId={clientId} />
      )}
      {selectedView === SHOW_PRICE_SPREADS && (
        <PriceSpreadsTable clientId={clientId} />
      )}
    </Flex>
  );
}
