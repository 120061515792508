import React from 'react';

import cx from 'classnames';

import styles from './styles.module.css';

const Error = ({ children, className, ...props }) => (
  <div className={cx(styles.error, className)} {...props}>
    {children}
  </div>
);

export default Error;
