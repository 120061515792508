import { coerceStringToBoolean } from 'utils';
import {
  InputField,
  YesNoSelect,
  SubscriptionSelect,
  SubscriptionStatusSelect,
  AdminSelect,
  RegionSelect,
  CountrySelect,
  DateField,
  UniSelect
} from 'components';

import _ from 'lodash';

import { transformValuesForMultiselect } from 'utils/filters';

export const FILTERS = [
  [
    {
      name: 'start_date',
      emptyValue: null,
      component: DateField,
      label: 'Start Date'
    },
    {
      name: 'end_date',
      emptyValue: null,
      component: DateField,
      label: 'End Date'
    },
    {
      name: 'client',
      emptyValue: '',
      component: InputField,
      label: 'Client ID'
    },
    {
      name: 'opened',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Is Opened'
    },
    {
      name: 'opens_from_count',
      emptyValue: '',
      component: InputField,
      label: '# of Opens from',
      type: 'number',
      width: 8
    },
    {
      name: 'opens_to_count',
      emptyValue: '',
      component: InputField,
      label: '# of Opens to',
      type: 'number',
      width: 8
    }
  ],
  [
    {
      name: 'mail_target',
      emptyValue: '',
      component: InputField,
      label: `Newsletter Issue ID`
    },
    {
      name: 'recipient',
      emptyValue: '',
      component: InputField,
      label: 'Recipient email'
    },
    {
      name: 'company',
      emptyValue: '',
      component: InputField,
      label: 'Company'
    }
  ],
  [
    {
      name: 'region',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: RegionSelect,
      label: 'Region'
    },
    {
      name: 'countries',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: CountrySelect,
      label: 'Country',
      props: () => ({
        isMulti: true
      })
    }
  ],
  [
    {
      name: 'territory_manager',
      emptyValue: null,
      fieldComponent: UniSelect,
      label: 'Sales Territory Manager',
      width: 5,
      props: ({ setFieldValue }) => ({
        source: 'salesTerritoryStaffProfiles',
        onChange: event => {
          const extraData = _.get(event, 'target.extraData');
          const sales_territories = _.get(
            event,
            'target.extraData.sales_territories'
          );

          if (
            !_.isNil(event) &&
            !_.isNil(extraData) &&
            !_.isNil(sales_territories)
          ) {
            setFieldValue(
              'countries',
              sales_territories.map(country => country.id)
            );
          }
        }
      })
    }
  ],
  [
    {
      name: 'account_manager',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: AdminSelect,
      label: 'Account manager'
    },
    {
      name: 'subscription',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: SubscriptionSelect,
      label: 'Subscription'
    },
    {
      name: 'subscription_status',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: SubscriptionStatusSelect,
      label: 'Subscription Status'
    }
  ]
];
