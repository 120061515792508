import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const clientQuickApprove = async (clientId, data) => {
  const url = `${BASE_URL}/crm/clients/${clientId}/quick-approve/`;

  return await requestSdk(() => post(url, data));
};

export const clientGetWelcomeEmailForStatus = async (
  clientId,
  itemId,
  statusId
) => {
  const url = `${BASE_URL}/crm/clients/${clientId}/products/subscription/${itemId}/${statusId}/welcome-email-template/`;

  return await requestSdk(() => get(url));
};
