import React from 'react';
import _ from 'lodash';
import { Table, TableCell } from 'semantic-ui-react';

import { Formik } from 'formik';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { Button, Field, CheckboxField, UniSelect } from 'components';

import { permamentPriceSchema } from './utils';

function CreatePriceForm({
  productSlug,
  permanentPriceCreateSdk,
  prices,
  section,
  fetchPermanentPrices
}) {
  const handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError, resetForm } = actions;

    const data = {
      ...prepareFormData(values),
      section_slug: section.internal_slug
    };

    setSubmitting(true);

    const { success, errors } = await permanentPriceCreateSdk(
      productSlug,
      data
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Choice created.');
      fetchPermanentPrices();
      resetForm();
      return;
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  const initialValues = {
    price: null,
    displayed: false
  };
  const priceSeriesIds = _.map(prices, 'id');

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={permamentPriceSchema}>
      {({ isSubmitting, handleSubmit }) => (
        <Table.Row>
          <Table.Cell width={1} />
          <Table.Cell>
            <UniSelect
              name="price"
              excludeOptionsIds={priceSeriesIds}
              source="priceSeriesAll"
            />
          </Table.Cell>
          <TableCell width={2} textAlign="center">
            <Field name="displayed" component={CheckboxField} />
          </TableCell>
          <Table.Cell width={3} textAlign="center">
            <Button
              color="green"
              disabled={isSubmitting}
              onClick={handleSubmit}>
              Create
            </Button>
          </Table.Cell>
        </Table.Row>
      )}
    </Formik>
  );
}

export default CreatePriceForm;
