import React from 'react';

import { Link } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { ProductDetail } from 'pages';

import { Table } from 'semantic-ui-react';

import { TableFooter, SortableHeader } from 'components';

import { formatDateTime } from 'utils';

class ProductTable extends React.Component {
  render() {
    const { products, sort, orderBy, pagination } = this.props;

    return (
      <Table celled selectable compact={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              singleLine
              style={{ fontWeight: '400' }}
              colspan="6">
              {pagination}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <SortableHeader sort={sort} orderBy={orderBy} field="id">
              ID
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="title">
              Product
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="short_title">
              Short title
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="abbreviation">
              Abbreviation
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="created_at">
              Created At
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="updated_at">
              Updated At
            </SortableHeader>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {products.map(product => (
            <Table.Row key={product.id}>
              <Table.Cell>{product.id}</Table.Cell>
              <Table.Cell>
                <Link to={pageUrl(ProductDetail, { productId: product.id })}>
                  {product.title}
                </Link>
              </Table.Cell>
              <Table.Cell>{product.short_title}</Table.Cell>
              <Table.Cell>{product.abbreviation}</Table.Cell>
              <Table.Cell>{formatDateTime(product.created_at)}</Table.Cell>
              <Table.Cell>{formatDateTime(product.updated_at)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <TableFooter>{pagination}</TableFooter>
      </Table>
    );
  }
}

export default ProductTable;
