import React from 'react';

import { Prompt } from 'react-router-dom';

const UnsavedFormPrompt = ({ when, formName = null }) => {
  const additionalTitle = formName ? ` in ${formName}` : '';
  return (
    <Prompt
      when={when}
      message={`You have an unsaved changes${additionalTitle}. Do you want to continue anyway?`}
    />
  );
};

export default UnsavedFormPrompt;
