import _ from 'lodash';
import React from 'react';
import { FastField as Field, TransferListField } from 'components';
import { adminList } from './sdk';

class AdminSelect extends React.Component {
  state = {
    admins: []
  };
  async componentDidMount() {
    const { data, success } = await adminList();

    if (success) {
      this.setState({
        admins: data.map(admin => {
          return {
            key: admin.id,
            value: admin.id,
            label: `${admin.first_name} ${admin.last_name}`
          };
        })
      });
    }
  }

  render() {
    const { name, isCustom, ...rest } = this.props;
    const { admins } = this.state;

    const options = admins;

    if (!_.isEmpty(options)) {
      rest['options'] = options;
    }

    return (
      <Field name={name || 'admin'} component={TransferListField} {...rest} />
    );
  }
}

export default AdminSelect;
