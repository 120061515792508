import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const passwordResetTokenList = async clientId => {
  const url = `${BASE_URL}/auth/password-reset-tokens/${clientId}/`;

  return await requestSdk(() => get(url));
};

export const setUserPassword = async (clientId, data) => {
  const url = `${BASE_URL}/auth/set-user-password/${clientId}/`;

  return await requestSdk(() => post(url, data));
};
