import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const presaleGroupRateCreate = async (courseId, presaleId, data) => {
  const url = `${BASE_URL}/courses/${courseId}/presales/${presaleId}/group-rates/create/`;

  return await requestSdk(() => post(url, data));
};

export const presaleGroupRateList = async (courseId, presaleId) => {
  const url = `${BASE_URL}/courses/${courseId}/presales/${presaleId}/group-rates/`;

  return await requestSdk(() => get(url));
};

export const presaleGroupRateUpdate = async (
  courseId,
  presaleId,
  groupRateId,
  data
) => {
  const url = `${BASE_URL}/courses/${courseId}/presales/${presaleId}/group-rates/${groupRateId}/update/`;

  return await requestSdk(() => post(url, data));
};

export const presaleGroupRateDelete = async (
  courseId,
  presaleId,
  groupRateId
) => {
  const url = `${BASE_URL}/courses/${courseId}/presales/${presaleId}/group-rates/${groupRateId}/delete/`;

  return await requestSdk(() => post(url));
};
