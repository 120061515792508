import React from 'react';
import _ from 'lodash';

import { withRouter } from 'react-router-dom';

import { Modal, Icon, Header, Table, Label, Button } from 'semantic-ui-react';

import { Flex } from 'components';

import { pageUrl } from 'config/routes';

import { ClientCreateFromRegistrationAttempt } from 'pages';

import { formatDateTime } from 'utils';

class RegistrationAttemptDetailsModal extends React.Component {
  close = () => {
    this.props.close();
  };

  redirectToCreateClient = () => {
    const url = pageUrl(ClientCreateFromRegistrationAttempt, {
      registrationAttemptId: this.props.item.id
    });

    this.props.history.push(url);
  };

  render() {
    const { item } = this.props;

    return (
      <Modal open={true} size="small" centered={false}>
        <Modal.Header>
          <Flex spaceBetween style={{ alignItems: 'flex-start' }}>
            <Flex flexStart style={{ alignItems: 'flex-start' }}>
              <Header as="h2">Registration Attempt Details</Header>
              <Label
                basic
                color={item.finished ? 'green' : 'red'}
                style={{ marginLeft: '15px', textTransform: 'uppercase' }}>
                {item.finished ? 'complete' : 'incomplete'}
              </Label>
            </Flex>
            <Icon name="close" onClick={this.close} />
          </Flex>
          <Flex spaceBetween style={{ alignItems: 'flex-start' }}>
            <Header as="h3" color={item.finished ? 'green' : 'red'}>
              {item.email}
            </Header>
            {!item.finished && (
              <Button color="green" onClick={this.redirectToCreateClient}>
                Register
              </Button>
            )}
          </Flex>
        </Modal.Header>
        <Modal.Content>
          <Table definition collapsing>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Attempt time</Table.Cell>
                <Table.Cell>{formatDateTime(item.created_at)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Last updated at</Table.Cell>
                <Table.Cell>{formatDateTime(item.updated_at)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Registration type</Table.Cell>
                <Table.Cell>{item.registration_type}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Names</Table.Cell>
                <Table.Cell>
                  {item.first_name} {item.last_name}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Job title</Table.Cell>
                <Table.Cell>{item.job_title}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Company name</Table.Cell>
                <Table.Cell>{item.company_name}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Phone</Table.Cell>
                <Table.Cell>{item.phone}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Email type</Table.Cell>
                <Table.Cell>{item.email_type}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>City</Table.Cell>
                <Table.Cell>{item.city}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Country</Table.Cell>
                <Table.Cell>{item.country}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Postcode</Table.Cell>
                <Table.Cell>{item.postcode}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Address 1</Table.Cell>
                <Table.Cell>{item.address_1}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Address 2</Table.Cell>
                <Table.Cell>{item.address_2}</Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Business Activity</Table.Cell>
                <Table.Cell>{item.business_activity}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Number of readers</Table.Cell>
                <Table.Cell>{item.number_of_readers}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Payment method type</Table.Cell>
                <Table.Cell>{item.payment_method_type}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Finished by</Table.Cell>
                <Table.Cell>
                  {_.get(item, 'finished_by.first_name')}{' '}
                  {_.get(item, 'finished_by.last_name')}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>IP Address</Table.Cell>
                <Table.Cell>{item.ip_addresses.join(', ')}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Location</Table.Cell>
                <Table.Cell>{item.location}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Modal.Content>
      </Modal>
    );
  }
}

export default withRouter(RegistrationAttemptDetailsModal);
