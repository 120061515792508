import React from 'react';

import { Field, SelectField } from 'components';

class TagSelect extends React.Component {
  render() {
    const { name, tags, ...rest } = this.props;

    const { field } = rest;

    const options =
      field.value &&
      field.value.map(tag => {
        return {
          key: tag,
          value: tag,
          label: tag
        };
      });

    return (
      <Field
        name={name || 'tags'}
        component={SelectField}
        options={options}
        isClearable
        isCreatable
        isMulti
        {...rest}
      />
    );
  }
}

export default TagSelect;
