import _ from 'lodash';
import { useCallback, useState } from 'react';

import { Button } from 'semantic-ui-react';

import { Tooltip } from 'components';

import { notifyError, notifySuccess } from 'utils/notifications';

export const AddToFavouritesButton = ({
  entityName,
  initialIsFavouredState,
  addToFavouritesHandler,
  removeFromFavouritesHandler
}) => {
  const [isFavoured, setIsFavoured] = useState(initialIsFavouredState);
  const [submitting, setSubmitting] = useState(false);

  const addToFavourites = useCallback(async () => {
    setSubmitting(true);
    const { success, errors } = await addToFavouritesHandler();

    if (success) {
      notifySuccess(`${_.capitalize(entityName)} added to favourites.`);
      setIsFavoured(true);
    }

    if (!_.isEmpty(errors)) {
      notifyError(`Couldn't add ${entityName} to favourites.`);
    }

    setSubmitting(false);
  }, [addToFavouritesHandler, entityName]);

  const removeFromFavourites = useCallback(async () => {
    setSubmitting(true);
    const { success, errors } = await removeFromFavouritesHandler();

    if (success) {
      notifySuccess(`${_.capitalize(entityName)} removed from favourites.`);
      setIsFavoured(false);
    }

    if (!_.isEmpty(errors)) {
      notifyError(`Couldn't remove ${entityName} from favourites.`);
    }

    setSubmitting(false);
  }, [removeFromFavouritesHandler, entityName]);

  const onClick = isFavoured ? removeFromFavourites : addToFavourites;
  const content = isFavoured ? 'Remove from favourites' : 'Add to favourites';
  const color = isFavoured ? 'gold' : 'grey';

  return (
    <Tooltip
      disabled={submitting}
      on="hover"
      content={content}
      trigger={
        <Button
          disabled={submitting}
          icon="favorite"
          onClick={onClick}
          style={{
            color: color,
            padding: 0,
            background: 'none',
            border: 'none'
          }}
        />
      }
    />
  );
};

export default AddToFavouritesButton;
