import _ from 'lodash';

import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const companyList = async (productSlug, search) => {
  const encodedQuery = encodeURIComponent(search);
  let url = `${BASE_URL}/common/companies-new/?search=${encodedQuery}&paginate=false`;
  if (!_.isNil(productSlug)) {
    url = `${BASE_URL}/common/companies-new/?product=${productSlug}&search=${encodedQuery}&paginate=false`;
  }

  return await requestSdk(() => get(url));
};
