import _ from 'lodash';
import React, { useState, useCallback, useEffect } from 'react';

import { Header, Segment } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';

import { PRICE_INDEXES_DETAILS_URL } from 'sdk/urls';

import { withLoggedUser, withPermissions } from 'hooks';

import { Page, Button, Flex, ExternalLink } from 'components';
import { notifyErrors } from 'utils/notifications';

import { PERMISSIONS, DisableableElement, hasPermission } from 'permissions';

import { PriceIndexesUpdateForm } from './components';

import { priceIndexDetail } from './sdk';

function PriceIndexDetail({ user, crumbs }) {
  const [priceSeries, setPriceSeries] = useState(null);

  const { priceIndexesId } = useParams();

  const fetchPriceSeries = useCallback(async () => {
    const { data, errors, success } = await priceIndexDetail(priceIndexesId);

    if (success) {
      setPriceSeries(data);
    } else {
      notifyErrors(errors);
    }
  }, [priceIndexesId]);

  useEffect(() => {
    fetchPriceSeries();
  }, [fetchPriceSeries]);

  return (
    <>
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Flex spaceBetween>
                <Header as="h1">Price Index Detail</Header>
                {priceSeries && (
                  <div>
                    <ExternalLink
                      url={PRICE_INDEXES_DETAILS_URL(
                        priceSeries.main_group_slug,
                        priceSeries.slug
                      )}>
                      <Button color="green" size="large">
                        View In Website
                      </Button>
                    </ExternalLink>
                  </div>
                )}
              </Flex>
              {!_.isNil(priceSeries) && (
                <DisableableElement
                  disabled={!hasPermission(user, PERMISSIONS.CHANGE_PRICES)}>
                  <PriceIndexesUpdateForm
                    priceSeries={priceSeries}
                    fetchPriceSeries={fetchPriceSeries}
                  />
                </DisableableElement>
              )}
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    </>
  );
}

export default withLoggedUser(
  withPermissions([PERMISSIONS.CREATE_PRICES])(PriceIndexDetail)
);
