import React from 'react';

import { Table } from 'semantic-ui-react';

import { TableFooter, SortableHeader } from 'components';
import { formatDateTime } from 'utils';

class AccessLogTable extends React.Component {
  render() {
    const { accessLogs, orderBy, pagination, sort } = this.props;

    return (
      <>
        <Table celled compact={true}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                singleLine
                style={{ fontWeight: '400' }}
                colspan="3">
                {pagination}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <SortableHeader sort={sort} orderBy={orderBy} field="source">
                Source
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="viewed_by">
                Viewed By
              </SortableHeader>
              <SortableHeader sort={sort} orderBy={orderBy} field="created_at">
                Accessed At
              </SortableHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {accessLogs.map((accessLog, index) => (
              <Table.Row key={index}>
                <Table.Cell>{accessLog.source}</Table.Cell>
                <Table.Cell>{accessLog.viewed_by}</Table.Cell>
                <Table.Cell>{formatDateTime(accessLog.created_at)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <TableFooter>{pagination}</TableFooter>
        </Table>
      </>
    );
  }
}

export default AccessLogTable;
