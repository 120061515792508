import { Radio } from 'semantic-ui-react';

import { AdminSelect, Filters, InputField, UniSelect } from 'components';

export const FILTERS = [
  [
    {
      name: 'search',
      emptyValue: '',
      component: InputField,
      label: 'General Search',
      placeholder: 'Search by title, display name or slug'
    },
    {
      name: 'principal_owner',
      emptyValue: null,
      component: AdminSelect,
      transform: value => parseInt(value, 10),
      label: 'Principal owner'
    },
    {
      name: 'region',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      component: UniSelect,
      label: 'Region',
      props: () => ({
        source: 'priceSerieRegions'
      })
    },
    {
      name: 'frequency',
      emptyValue: '',
      component: UniSelect,
      label: 'Frequency',
      props: () => ({
        source: 'priceSerieFrequencies'
      })
    }
  ]
];

export default function PriceSerieFilters({
  filters,
  count,
  addFilters,
  filterBy,
  clear
}) {
  return (
    <div style={{ width: '100%', zIndex: 2 }}>
      <Radio
        toggle
        label="Show price series in snapshot only"
        checked={filters?.in_snapshot_only || false}
        onChange={(e, { checked }) => addFilters({ in_snapshot_only: checked })}
      />
      <Filters
        filters={filters}
        count={count}
        filterBy={filterBy}
        clearFilters={clear}
        layout={FILTERS}
      />
    </div>
  );
}
