import React from 'react';

import { Field, SelectField } from 'components';

const ITEMS = ['Fixed Date', 'Custom Date'];

class CourseDateTypeSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = ITEMS.map(item => {
      return {
        key: item,
        value: item,
        label: item
      };
    });

    return (
      <Field
        name={name || 'courseDateType'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default CourseDateTypeSelect;
