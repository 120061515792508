import React from 'react';

import _ from 'lodash';

import { Form, Segment, Button, Table } from 'semantic-ui-react';

import { Formik } from 'formik';

import { UniSelect, YesNoSelect } from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';

import { notifySuccess } from 'utils/notifications';

import { feedbackUpdate } from '../sdk';

function FeedbackDetailsModal({ feedback, refetch, onClose }) {
  const initialValues = {
    isPublished: feedback.is_published,
    displayedLocations: feedback.displayed_locations.map(
      location => location.id
    )
  };

  const handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    setSubmitting(true);

    const { errors, success } = await feedbackUpdate(
      feedback.id,
      prepareFormData(values)
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Feedback updated.');
      refetch();
      onClose();
    }
    handleFormErrors(errors, setFieldError);
  };

  return (
    <Segment>
      <Table definition collapsing>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Text</Table.Cell>
            <Table.Cell>{_.get(feedback, 'text')}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Formik onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit, isSubmitting, onSubmit }) => (
          <Form>
            <Form.Field>
              <YesNoSelect
                width={4}
                name="isPublished"
                label="Is Published"
                yesLabel="Yes"
                noLabel="No"
              />
            </Form.Field>

            <Form.Field>
              <UniSelect
                width={8}
                name="displayedLocations"
                label="Pages"
                source="feedbackVisibilityPage"
                transferList={true}
              />
            </Form.Field>

            <Form.Field width={4}>
              <Button
                fluid
                type="submit"
                onClick={handleSubmit}
                color="blue"
                disabled={isSubmitting}>
                Submit
              </Button>
            </Form.Field>
          </Form>
        )}
      </Formik>
    </Segment>
  );
}

export default FeedbackDetailsModal;
