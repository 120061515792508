import { requestSdk, post, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const moveRenewal = async (id, data) => {
  const url = `${BASE_URL}/crm/renewals/${id}/move/`;

  return await requestSdk(() => post(url, data));
};

export const renewalClientPredictedExpirationDate = async id => {
  const url = `${BASE_URL}/crm/renewals/${id}/predicted-expiration-date/`;

  return await requestSdk(() => get(url));
};
