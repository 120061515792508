import React from 'react';

import { Formik, Field } from 'formik';

import { Segment } from 'semantic-ui-react';

import {
  buildInitialValuesFromFilters,
  transformValuesForMultiselect
} from 'utils/filters';

import {
  PriceSeriesCategorySelect,
  PriceSeriesProductSelect,
  PriceSeriesRegionSelect,
  PriceSeriesFrequencySelect,
  DateField,
  FilterButtons
} from 'components';

import styles from './styles.module.css';

const FILTERS = [
  {
    name: 'category',
    emptyValue: null,
    transform: transformValuesForMultiselect
  },
  {
    name: 'product',
    emptyValue: null,
    transform: transformValuesForMultiselect
  },
  {
    name: 'region',
    emptyValue: null,
    transform: transformValuesForMultiselect
  },
  {
    name: 'frequency',
    emptyValue: ''
  },
  {
    name: 'date',
    emptyValue: null
  }
];

class Filters extends React.Component {
  submitSearch = values => {
    this.props.filterBy(values);
  };

  resetSearch = resetForm => {
    const { clearFilters } = this.props;

    resetForm();
    clearFilters();
  };

  render() {
    const { filters } = this.props;

    const initialValues = buildInitialValuesFromFilters(filters, FILTERS);

    return (
      <Segment>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={this.submitSearch}>
          {({ values, handleSubmit, resetForm }) => (
            <>
              <div>
                <div className={styles.Row}>
                  <PriceSeriesCategorySelect
                    isPowerMaterial={this.props.isPowerMaterial}
                    name="category"
                    label="Category"
                    isMulti={true}
                  />
                  <PriceSeriesProductSelect
                    name="product"
                    label="Product"
                    isPowerMaterial={this.props.isPowerMaterial}
                    isMulti={true}
                  />
                  <PriceSeriesRegionSelect
                    name="region"
                    label="Region"
                    isMulti={true}
                  />
                  <PriceSeriesFrequencySelect
                    name="frequency"
                    label="Frequency"
                  />
                  <Field component={DateField} name="date" label="Date" />
                </div>
              </div>

              <FilterButtons
                searchAction={handleSubmit}
                resetAction={() => this.resetSearch(resetForm)}
              />
            </>
          )}
        </Formik>
      </Segment>
    );
  }
}

export default Filters;
