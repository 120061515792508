import React from 'react';

import { Button } from 'semantic-ui-react';

import { trackEvent } from 'components/EventTracking';

import styles from './styles.module.css';

const FilterButtons = props => {
  const { searchAction, resetAction, advancedAction, advanced } = props;

  const onSearchClick = () => {
    trackEvent({
      name: 'SEARCH',
      data: {
        path: window.location.pathname
      }
    });

    searchAction();
  };

  return (
    <div className={styles.container}>
      <Button size="large" primary onClick={onSearchClick}>
        Search
      </Button>
      <Button basic size="large" color="black" onClick={resetAction}>
        Clear filters
      </Button>
      {advancedAction && (
        <Button basic size="large" color="black" onClick={advancedAction}>
          {!advanced ? 'Show Advanced' : 'Hide Advanced'}
        </Button>
      )}
    </div>
  );
};

export default FilterButtons;
