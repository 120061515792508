import React from 'react';

import { Button, Icon } from 'semantic-ui-react';

class AddRemoveButton extends React.Component {
  render() {
    const { add, ...rest } = this.props;

    const buttonColor = add ? 'olive' : 'yellow';

    return (
      <Button color={buttonColor} {...rest}>
        {add ? <Icon name="plus" fitted /> : <Icon name="times" fitted />}
      </Button>
    );
  }
}

export default AddRemoveButton;
