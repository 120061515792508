import _ from 'lodash';
import { transformValuesForMultiselect } from 'utils/filters';
import { coerceStringToBoolean } from 'utils';
import {
  InputField,
  YesNoSelect,
  ClientProfileTypeSelect,
  PrioritySelect,
  SubscriptionSelect,
  SubscriptionStatusSelect,
  UnsubscribeMethodSelect,
  DateField,
  AdminSelect,
  UpcomingEventSelect,
  PastEventSelect,
  EventStatusSelect,
  SubIndustrySelect,
  IndustrySelect,
  BusinessActivitySelect,
  EmailTypeSelect,
  RegistrationTypeSelect,
  CommoditySelect,
  CountrySelect,
  RegionSelect,
  ProductStatusSelect,
  MarketDataReportRelatedProductSelect,
  ArticleGroupSelect,
  UniSelect,
  ClientTagsSelectField
} from 'components';
import { CorporateMasterAccountsSelect } from 'components/fields';

export const LAYOUT_FILTERS = [
  [
    {
      name: 'search',
      emptyValue: '',
      component: InputField,
      label: 'General Search',
      placeholder: 'Search...',
      width: 8
    },
    {
      name: 'id',
      emptyValue: '',
      component: InputField,
      label: 'Client ID',
      width: 4
    },
    {
      name: 'has_admin_updates',
      emptyValue: null,
      transform: coerceStringToBoolean,
      component: YesNoSelect,
      label: 'Has admin updates',
      width: 4
    }
  ],
  [
    {
      name: 'email',
      emptyValue: '',
      component: InputField,
      label: 'Email address',
      width: 4
    },
    {
      name: 'first_name',
      emptyValue: '',
      component: InputField,
      label: 'First name',
      width: 3
    },
    {
      name: 'last_name',
      emptyValue: '',
      component: InputField,
      label: 'Last name',
      width: 3
    },
    {
      name: 'job_title',
      emptyValue: '',
      component: InputField,
      label: 'Job Title',
      width: 3
    },
    {
      name: 'has_company_name',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Has Company name',
      width: 2
    },
    {
      name: 'company_name',
      emptyValue: '',
      component: InputField,
      label: 'Company name',
      width: 3
    }
  ],
  [
    {
      name: 'is_active',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'User Status',
      props: () => ({
        yesLabel: 'Active',
        noLabel: 'Inactive'
      }),
      width: 2
    },
    {
      name: 'cm',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: ClientProfileTypeSelect,
      label: 'CM filter',
      width: 2
    },
    {
      name: 'account_manager',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: AdminSelect,
      label: 'Account Manager',
      width: 4
    },
    {
      name: 'c_accounts_for_cm',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      component: CorporateMasterAccountsSelect,
      label: 'Show children for CM:',
      props: () => ({
        isMulti: true
      }),
      width: 5
    },
    {
      name: 'priority',
      emptyValue: null,
      fieldComponent: PrioritySelect,
      label: 'Priority',
      props: () => ({
        isMulti: true
      }),
      width: 3
    }
  ],
  [
    {
      name: 'subscription',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: SubscriptionSelect,
      label: 'Subscription',
      props: () => ({
        isMulti: true,
        additionalOptions: [
          {
            key: 'no-service',
            value: 0,
            label: 'No service'
          }
        ]
      }),
      width: 4
    },
    {
      name: 'subscription_status',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: SubscriptionStatusSelect,
      label: 'Subscription status',
      props: () => ({
        isMulti: true
      }),
      width: 4
    },
    {
      name: 'unsubscribed_at',
      emptyValue: null,
      component: DateField,
      label: 'Unsubscribed At',
      width: 2
    },
    {
      name: 'unsubscribed',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Un/Subscribed',
      props: () => ({
        yesLabel: 'Unsubscribed only',
        noLabel: 'Subscribed only'
      }),
      width: 3
    },
    {
      name: 'unsubscribed_method',
      emptyValue: null,
      fieldComponent: UnsubscribeMethodSelect,
      label: 'Unsubscribed Method',
      width: 3
    }
  ],
  [
    {
      name: 'subscription_starts_from',
      emptyValue: null,
      component: DateField,
      label: 'Subscription Starts from',
      width: 3,
      testId: 'crm-filter-subscription-starts-from'
    },
    {
      name: 'subscription_starts_to',
      emptyValue: null,
      component: DateField,
      label: 'Subscription Starts to',
      width: 3,
      testId: 'crm-filter-subscription-starts-to'
    },
    {
      name: 'subscription_expires_from',
      emptyValue: null,
      component: DateField,
      label: 'Subscription Expires from',
      width: 3,
      testId: 'crm-filter-subscription-expires-from'
    },
    {
      name: 'subscription_expires_to',
      emptyValue: null,
      component: DateField,
      label: 'Subscription Expires to',
      width: 3,
      testId: 'crm-filter-subscription-expires-to'
    },
    {
      name: 'uses_mobile_app',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Uses mobile app',
      width: 2
    },
    {
      name: 'has_my_snapshot_prices',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Has my snapshot prices',
      width: 3
    }
  ]
];

export const ADVANCED_LAYOUT_FILTERS = [
  [
    {
      name: 'upcoming_event',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: UpcomingEventSelect,
      label: 'Upcoming event',
      props: () => ({
        isMulti: true
      })
    },
    {
      name: 'past_event',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: PastEventSelect,
      label: 'Past event',
      props: () => ({
        isMulti: true
      })
    },
    {
      name: 'event_status',
      emptyValue: [],
      fieldComponent: EventStatusSelect,
      label: 'Event status',
      props: () => ({
        isMulti: true
      })
    },
    {
      name: 'attended_event',
      emptyValue: [],
      fieldComponent: YesNoSelect,
      label: 'Attended event'
    }
  ],
  [
    {
      name: 'industry',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: IndustrySelect,
      label: 'Industry',
      props: ({ setFieldValue }) => ({
        isMulti: true,
        onChange: () => setFieldValue('sub_industry', [])
      }),
      width: 5
    },

    {
      name: 'sub_industry',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: SubIndustrySelect,
      label: 'Sub-Industry',
      props: ({ values }) => ({
        isMulti: true,
        disabled: _.isEmpty(values.industry),
        industry: values.industry
      }),
      width: 5
    },
    {
      name: 'business_activity',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: BusinessActivitySelect,
      label: 'Business activity',
      props: () => ({
        isMulti: true
      }),
      width: 5
    }
  ],
  [
    {
      name: 'countries',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: CountrySelect,
      label: 'Country',
      props: () => ({
        isMulti: true
      }),
      width: 5
    },
    {
      name: 'is_in_sanctioned_country',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Not/Sanctioned countries',
      width: 4,
      props: () => ({
        yesLabel: 'Sanctioned only',
        noLabel: 'Not sanctioned only'
      })
    },
    {
      name: 'has_country',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Has country',
      width: 2
    },
    {
      name: 'states',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: UniSelect,
      label: 'State',
      props: () => ({
        isMulti: true,
        source: 'salesTerritoryStates'
      }),
      width: 5
    },
    {
      name: 'regions',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: RegionSelect,
      label: 'Region',
      props: () => ({
        isMulti: true
      }),
      width: 5
    },
    {
      name: 'commodities',
      emptyValue: [],
      fieldComponent: CommoditySelect,
      label: 'Commodity',
      props: () => ({
        isMulti: true
      }),
      width: 5
    }
  ],
  [
    {
      name: 'territory_manager',
      emptyValue: null,
      fieldComponent: UniSelect,
      label: 'Sales Territory Manager',
      width: 5,
      props: ({ setFieldValue }) => ({
        source: 'salesTerritoryStaffProfiles',
        onChange: event => {
          const extraData = _.get(event, 'target.extraData');
          const salesTerritories = _.get(
            event,
            'target.extraData.sales_territories'
          );

          let states = [];
          for (let saleTerritory of salesTerritories) {
            states.push(...saleTerritory.states);
          }

          if (!_.isNil(event) && !_.isNil(extraData)) {
            if (!_.isNil(salesTerritories)) {
              setFieldValue(
                'countries',
                salesTerritories.map(country => country.id)
              );
            }
            if (!_.isNil(states)) {
              setFieldValue(
                'states',
                states.map(state => state.id)
              );
            }
          }
        }
      })
    }
  ],
  [
    {
      name: 'interested_in_events',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Interested in events',
      width: 2
    },
    {
      name: 'interested_in_news',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Interested in news',
      width: 2
    },
    {
      name: 'interested_in_reports',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Interested in reports',
      width: 2
    }
  ],
  [
    {
      name: 'registration_type',
      emptyValue: '',
      fieldComponent: RegistrationTypeSelect,
      label: 'Registration type',
      width: 2
    },
    {
      name: 'email_type',
      emptyValue: '',
      fieldComponent: EmailTypeSelect,
      label: 'Email type',
      width: 2
    }
  ],
  [
    {
      name: 'address',
      emptyValue: '',
      component: InputField,
      label: 'Address',
      width: 4
    },
    {
      name: 'state_or_province',
      emptyValue: '',
      component: InputField,
      label: 'State or province',
      width: 3
    },
    {
      name: 'phone',
      emptyValue: '',
      component: InputField,
      label: 'Phone',
      width: 3
    },
    {
      name: 'fax',
      emptyValue: '',
      component: InputField,
      label: 'Fax',
      width: 4
    }
  ],
  [
    {
      name: 'city',
      emptyValue: '',
      component: InputField,
      label: 'City (town)',
      width: 4
    },
    {
      name: 'postcode',
      emptyValue: '',
      component: InputField,
      label: 'Postcode',
      width: 3
    },
    {
      name: 'mobile',
      emptyValue: '',
      component: InputField,
      label: 'Mobile',
      width: 3
    },
    {
      name: 'website',
      emptyValue: '',
      component: InputField,
      label: 'Website',
      width: 4
    }
  ],
  [
    {
      name: 'product',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: MarketDataReportRelatedProductSelect,
      label: 'Product',
      width: 5
    },
    {
      name: 'product_status',
      emptyValue: null,
      fieldComponent: ProductStatusSelect,
      label: 'Product status',
      width: 4
    },
    {
      name: 'group_articles_by',
      emptyValue: null,
      fieldComponent: ArticleGroupSelect,
      label: 'Group articles by',
      width: 4
    }
  ],
  [
    {
      name: 'has_comments',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Has Comments',
      width: 4
    },
    {
      name: 'has_feedback',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Has Feedback',
      width: 4
    }
  ],
  [
    {
      name: 'tags',
      emptyValue: [],
      component: ClientTagsSelectField,
      label: 'Contains Tags',
      props: () => ({ isCreatable: false }),
      width: 4
    }
  ]
];

export const EXCLUDE_LAYOUT_FILTERS = [
  [
    {
      name: 'search__not',
      emptyValue: '',
      component: InputField,
      label: 'General Search',
      placeholder: 'Search...',
      width: 8
    },
    {
      name: 'id__not',
      emptyValue: '',
      component: InputField,
      label: 'Client ID',
      width: 4
    },
    {
      name: 'has_admin_updates__not',
      emptyValue: null,
      transform: coerceStringToBoolean,
      component: YesNoSelect,
      label: 'Has admin updates',
      width: 4
    }
  ],
  [
    {
      name: 'email__not',
      emptyValue: '',
      component: InputField,
      label: 'Email Address',
      width: 4
    },
    {
      name: 'first_name__not',
      emptyValue: '',
      component: InputField,
      label: 'First Name',
      width: 3
    },
    {
      name: 'last_name__not',
      emptyValue: '',
      component: InputField,
      label: 'Last Name',
      width: 3
    },
    {
      name: 'job_title__not',
      emptyValue: '',
      component: InputField,
      label: 'Job Title',
      width: 3
    },
    {
      name: 'has_company_name__not',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Has Company name',
      width: 2
    },
    {
      name: 'company_name__not',
      emptyValue: '',
      component: InputField,
      label: 'Company Name',
      width: 3
    }
  ],
  [
    {
      name: 'is_active__not',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'User Status',
      props: () => ({
        yesLabel: 'Active',
        noLabel: 'Inactive'
      }),
      width: 2
    },
    {
      name: 'cm__not',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: ClientProfileTypeSelect,
      label: 'CM Filter',
      width: 2
    },
    {
      name: 'account_manager__not',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: AdminSelect,
      label: 'Account Manager',
      width: 4
    },
    {
      name: 'c_accounts_for_cm__not',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      component: CorporateMasterAccountsSelect,
      label: 'Show Children for CM:',
      props: () => ({
        isMulti: true
      }),
      width: 5
    },
    {
      name: 'priority__not',
      emptyValue: null,
      fieldComponent: PrioritySelect,
      label: 'Priority',
      props: () => ({
        isMulti: true
      }),
      width: 3
    }
  ],
  [
    {
      name: 'subscription__not',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: SubscriptionSelect,
      label: 'Subscription',
      props: () => ({
        isMulti: true,
        additionalOptions: [
          {
            key: 'no-service',
            value: 0,
            label: 'No service'
          }
        ]
      }),
      width: 4
    },
    {
      name: 'subscription_status__not',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: SubscriptionStatusSelect,
      label: 'Subscription Status',
      props: () => ({
        isMulti: true
      }),
      width: 4
    },
    {
      name: 'unsubscribed_at__not',
      emptyValue: null,
      component: DateField,
      label: 'Unsubscribed At',
      width: 2
    },
    {
      name: 'unsubscribed__not',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Un/Subscribed',
      props: () => ({
        yesLabel: 'Unsubscribed only',
        noLabel: 'Subscribed only'
      }),
      width: 3
    },
    {
      name: 'unsubscribed_method__not',
      emptyValue: null,
      fieldComponent: UnsubscribeMethodSelect,
      label: 'Unsubscribed Method',
      width: 3
    }
  ],
  [
    {
      name: 'subscription_starts_from__not',
      emptyValue: null,
      component: DateField,
      label: 'Subscription Starts from',
      width: 3
    },
    {
      name: 'subscription_starts_to__not',
      emptyValue: null,
      component: DateField,
      label: 'Subscription Starts to',
      width: 3
    },
    {
      name: 'subscription_expires_from__not',
      emptyValue: null,
      component: DateField,
      label: 'Subscription Expires from',
      width: 3
    },
    {
      name: 'subscription_expires_to__not',
      emptyValue: null,
      component: DateField,
      label: 'Subscription Expires to',
      width: 3
    },
    {
      name: 'uses_mobile_app__not',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Uses mobile app',
      width: 2
    },
    {
      name: 'has_my_snapshot_prices__not',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Has my snapshot prices',
      width: 3
    }
  ],
  [
    {
      name: 'upcoming_event__not',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: UpcomingEventSelect,
      label: 'Upcoming Event',
      props: () => ({
        isMulti: true
      })
    },
    {
      name: 'past_event__not',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: PastEventSelect,
      label: 'Past Event',
      props: () => ({
        isMulti: true
      })
    },
    {
      name: 'event_status__not',
      emptyValue: [],
      fieldComponent: EventStatusSelect,
      label: 'Event Status',
      props: () => ({
        isMulti: true
      })
    },
    {
      name: 'attended_event__not',
      emptyValue: [],
      fieldComponent: YesNoSelect,
      label: 'Attended event'
    }
  ],
  [
    {
      name: 'industry__not',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: IndustrySelect,
      label: 'Industry',
      props: ({ setFieldValue }) => ({
        isMulti: true,
        onChange: () => setFieldValue('sub_industry__not', [])
      }),
      width: 5
    },

    {
      name: 'sub_industry__not',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: SubIndustrySelect,
      label: 'Sub-Industry',
      props: ({ values }) => ({
        isMulti: true,
        disabled: _.isEmpty(values.industry__not),
        industry: values.industry__not
      }),
      width: 5
    },
    {
      name: 'business_activity__not',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: BusinessActivitySelect,
      label: 'Business Activity',
      props: () => ({
        isMulti: true
      }),
      width: 5
    }
  ],
  [
    {
      name: 'countries__not',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: CountrySelect,
      label: 'Country',
      props: () => ({
        isMulti: true
      }),
      width: 5
    },
    {
      name: 'states__not',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: UniSelect,
      label: 'State',
      props: () => ({
        isMulti: true,
        source: 'salesTerritoryStates'
      }),
      width: 5
    },
    {
      name: 'regions__not',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: RegionSelect,
      label: 'Region',
      props: () => ({
        isMulti: true
      }),
      width: 5
    },
    {
      name: 'commodities__not',
      emptyValue: [],
      fieldComponent: CommoditySelect,
      label: 'Commodity',
      props: () => ({
        isMulti: true
      }),
      width: 5
    }
  ],
  [
    {
      name: 'territory_manager__not',
      emptyValue: null,
      fieldComponent: UniSelect,
      label: 'Sales Territory Manager',
      width: 5,
      props: ({ setFieldValue }) => ({
        source: 'salesTerritoryStaffProfiles',
        onChange: event => {
          const extraData = _.get(event, 'target.extraData');
          const salesTerritories = _.get(
            event,
            'target.extraData.sales_territories'
          );

          let states = [];
          for (let saleTerritory of salesTerritories) {
            states.push(...saleTerritory.states);
          }

          if (!_.isNil(event) && !_.isNil(extraData)) {
            if (!_.isNil(salesTerritories)) {
              setFieldValue(
                'countries__not',
                salesTerritories.map(country => country.id)
              );
            }
            if (!_.isNil(states)) {
              setFieldValue(
                'states__not',
                states.map(state => state.id)
              );
            }
          }
        }
      })
    }
  ],
  [
    {
      name: 'interested_in_events__not',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Interested in events',
      width: 2
    },
    {
      name: 'interested_in_news__not',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Interested in news',
      width: 2
    },
    {
      name: 'interested_in_reports__not',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Interested in reports',
      width: 2
    }
  ],
  [
    {
      name: 'registration_type__not',
      emptyValue: '',
      fieldComponent: RegistrationTypeSelect,
      label: 'Registration type',
      width: 2
    },
    {
      name: 'email_type__not',
      emptyValue: '',
      fieldComponent: EmailTypeSelect,
      label: 'Email type',
      width: 2
    }
  ],
  [
    {
      name: 'address__not',
      emptyValue: '',
      component: InputField,
      label: 'Address',
      width: 4
    },
    {
      name: 'state_or_province__not',
      emptyValue: '',
      component: InputField,
      label: 'State or Province',
      width: 3
    },
    {
      name: 'phone__not',
      emptyValue: '',
      component: InputField,
      label: 'Phone',
      width: 3
    },
    {
      name: 'fax__not',
      emptyValue: '',
      component: InputField,
      label: 'Fax',
      width: 4
    }
  ],
  [
    {
      name: 'city__not',
      emptyValue: '',
      component: InputField,
      label: 'City (town)',
      width: 4
    },
    {
      name: 'postcode__not',
      emptyValue: '',
      component: InputField,
      label: 'Postcode',
      width: 3
    },
    {
      name: 'mobile__not',
      emptyValue: '',
      component: InputField,
      label: 'Mobile',
      width: 3
    },
    {
      name: 'website__not',
      emptyValue: '',
      component: InputField,
      label: 'Website',
      width: 4
    }
  ],
  [
    {
      name: 'product__not',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: MarketDataReportRelatedProductSelect,
      label: 'Product',
      width: 5
    },
    {
      name: 'product_status__not',
      emptyValue: null,
      fieldComponent: ProductStatusSelect,
      label: 'Product Status',
      width: 4
    },
    {
      name: 'group_articles_by__not',
      emptyValue: null,
      fieldComponent: ArticleGroupSelect,
      label: 'Group Articles by',
      width: 4
    }
  ],
  [
    {
      name: 'has_comments__not',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Has Comments',
      width: 4
    },
    {
      name: 'has_feedback__not',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Has Feedback',
      width: 4
    }
  ],
  [
    {
      name: 'tags__not',
      emptyValue: [],
      component: ClientTagsSelectField,
      label: 'Contains Tags',
      props: () => ({ isCreatable: false }),
      width: 4
    }
  ]
];
