import React from 'react';

import _ from 'lodash';

import { Field, SelectField } from 'components';

const TIME = _.range(0, 24);

class ClientReminderTimeSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = TIME.map(time => {
      return {
        key: time,
        value: time,
        label: time
      };
    });

    return (
      <Field
        name={name || 'clientReminderTimeSelect'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default ClientReminderTimeSelect;
