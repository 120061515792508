import React from 'react';
import _ from 'lodash';
import { Formik } from 'formik';

import { Segment, Form, Statistic } from 'semantic-ui-react';

import { buildInitialValuesFromFilters } from 'utils/filters';

import {
  DateField,
  Field,
  FormRow,
  FilterButtons,
  InputField,
  CurrencySelect,
  RegionSelect,
  CountrySelect,
  SubscriptionSelect,
  SubscriptionStatusSelect,
  AdminSelect,
  Flex,
  RenewalStateSelect,
  MonthSelect,
  YearSelect
} from 'components';

import { FILTERS } from './utils';

class Filters extends React.Component {
  submitSearch = values => {
    this.props.filterBy(values);
  };

  resetSearch = resetForm => {
    const { clearFilters } = this.props;

    resetForm();
    clearFilters();
  };

  render() {
    const { filters, count, stats } = this.props;

    const symbol = '£';

    const initialValues = buildInitialValuesFromFilters(filters, FILTERS);

    const currentYear = new Date().getFullYear();

    return (
      <Segment>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={this.submitSearch}>
          {({ values, handleSubmit, resetForm }) => (
            <Form>
              <FormRow>
                <Field
                  width={2}
                  name="start_date"
                  component={DateField}
                  label="Start Date"
                />
                <Field
                  width={2}
                  name="end_date"
                  component={DateField}
                  label="End Date"
                />
                <SubscriptionSelect
                  width={5}
                  name="subscription"
                  label="Subscription Service"
                />
                <SubscriptionStatusSelect
                  width={5}
                  name="subscription_status"
                  label="Subscription Status"
                />
              </FormRow>
              <FormRow>
                <CurrencySelect
                  width={2}
                  name="past_invoice_currency"
                  label="Invoice Currency"
                />
                <CurrencySelect
                  width={2}
                  name="convert_to"
                  label="Convert to"
                />
                <RegionSelect width={4} name="region" label="Region" />
                <CountrySelect width={4} name="country" label="Country" />
                <AdminSelect
                  width={4}
                  name="account_manager"
                  label="Account Manager"
                />
              </FormRow>
              <FormRow>
                <RenewalStateSelect
                  width={3}
                  name="renewal_state"
                  label="Renewals State"
                />
                <MonthSelect
                  width={3}
                  name="renewal_month"
                  label="Renewals Month"
                />
                <YearSelect
                  width={3}
                  name="renewal_year"
                  label="Renewals Year"
                  customOptions={_.range(currentYear, currentYear + 10)}
                />
                <Field
                  width={5}
                  name="company_name"
                  component={InputField}
                  label="Company name"
                />
              </FormRow>
              <Flex spaceBetween>
                <Flex>
                  <Statistic.Group size="tiny" style={{ margin: 0 }}>
                    <Statistic color="green">
                      <Statistic.Value>{count}</Statistic.Value>
                      <Statistic.Label>Renewal Clients</Statistic.Label>
                    </Statistic>
                  </Statistic.Group>
                  <Statistic.Group size="tiny" style={{ margin: 0 }}>
                    <Statistic color="green">
                      <Statistic.Value>
                        {!_.isNil(stats.past_total)
                          ? `${symbol} ${stats.past_total.toFixed(0)}`
                          : 'N/A'}
                      </Statistic.Value>
                      <Statistic.Label>Total Past</Statistic.Label>
                    </Statistic>
                  </Statistic.Group>{' '}
                  <Statistic.Group size="tiny" style={{ margin: 0 }}>
                    <Statistic color="green">
                      <Statistic.Value>
                        {stats.future_paid_count}
                        {!_.isNil(stats.future_paid_total) &&
                          `(${symbol} ${stats.future_paid_total.toFixed(0)})`}
                      </Statistic.Value>
                      <Statistic.Label>Future Paid</Statistic.Label>
                    </Statistic>
                  </Statistic.Group>{' '}
                  <Statistic.Group size="tiny" style={{ margin: 0 }}>
                    <Statistic color="green">
                      <Statistic.Value>
                        {stats.future_booked_count}
                        {!_.isNil(stats.future_booked_total) &&
                          `(${symbol} ${stats.future_booked_total.toFixed(0)})`}
                      </Statistic.Value>
                      <Statistic.Label>Future Booked</Statistic.Label>
                    </Statistic>
                  </Statistic.Group>{' '}
                  <Statistic.Group size="tiny" style={{ margin: 0 }}>
                    <Statistic color="green">
                      <Statistic.Value>
                        {!_.isNil(stats.upsell_total_amount)
                          ? `${symbol} ${stats.upsell_total_amount.toFixed(
                              0
                            )} (${stats.upsell_total_percentage.toFixed(0)}%)`
                          : 'N/A'}
                      </Statistic.Value>
                      <Statistic.Label>Upsells</Statistic.Label>
                    </Statistic>
                  </Statistic.Group>
                </Flex>
                <FilterButtons
                  searchAction={handleSubmit}
                  resetAction={() => this.resetSearch(resetForm)}
                />
              </Flex>
            </Form>
          )}
        </Formik>
      </Segment>
    );
  }
}

export default Filters;
