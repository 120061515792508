import React from 'react';

import { Link, useParams } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { PriceSpreadV2Detail, PriceSerieDetail } from 'pages';

import { formatDate } from 'utils';

const getPriceSpreadDetailUrl = (productSlug, priceSpreadId) => {
  return pageUrl(PriceSpreadV2Detail, { productSlug, priceSpreadId });
};

const getPriceSeriesDetailUrl = (productSlug, priceSeriesId) => {
  return pageUrl(PriceSerieDetail, { productSlug, priceSeriesId });
};

const formatPriceSpreadValue = priceSpread => {
  if (!priceSpread.latest_value) {
    return 'N/A';
  }

  return `${priceSpread.latest_value.toFixed(2)} ${
    priceSpread.price_serie_1.currency
  }/${priceSpread.price_serie_1.unit}`;
};

const PriceSpreadV2MovementTable = ({ priceSpreads }) => {
  const { productSlug } = useParams();

  return (
    <Table celled selectable compact={true}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Latest Update</Table.HeaderCell>
          <Table.HeaderCell>Product</Table.HeaderCell>
          <Table.HeaderCell>Latest Price</Table.HeaderCell>
          <Table.HeaderCell>Change</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {priceSpreads.map(priceSpread => (
          <Table.Row key={priceSpread.id}>
            <Table.Cell>
              {formatDate(priceSpread.price_serie_1.last_update_on)}
            </Table.Cell>
            <Table.Cell>
              <Link to={getPriceSpreadDetailUrl(productSlug, priceSpread.id)}>
                {priceSpread.title}
              </Link>
            </Table.Cell>
            <Table.Cell>
              <Link
                to={getPriceSeriesDetailUrl(
                  productSlug,
                  priceSpread.price_serie_1.id
                )}>
                {formatPriceSpreadValue(priceSpread)}
              </Link>
            </Table.Cell>
            <Table.Cell>
              {(priceSpread.latest_value - priceSpread.previous_value).toFixed(
                2
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default PriceSpreadV2MovementTable;
