import React from 'react';

import { withRouter } from 'react-router-dom';

import { Segment } from 'semantic-ui-react';

import { Header, Page, LoginRequired, ListViewEssentials } from 'components';

import { SubscriptionsTable } from './components';

import { subscriptionList } from './sdk';

const DEFAULT_LIMIT = 25;

class SubscriptionList extends React.Component {
  render() {
    const { user, data } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content>
            <Segment>
              <Header>Subscriptions</Header>

              {data && <SubscriptionsTable items={data} />}
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: subscriptionList,
  limit: DEFAULT_LIMIT
})(LoginRequired(withRouter(SubscriptionList)));
