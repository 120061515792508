import _ from 'lodash';
import React from 'react';

import { Table, Label } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import { TableFooter, SortableHeader, Flex } from 'components';

import { pageUrl } from 'config/routes';

import { MailshotDetail, MailshotEmailLogList } from 'pages';

import { formatDateTime } from 'utils';

const MailshotTable = ({ mailshots, sort, orderBy, pagination }) => (
  <Table celled selectable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell singleLine style={{ fontWeight: '400' }} colspan="9">
          {pagination}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <SortableHeader sort={sort} orderBy={orderBy} field="title">
          Title
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="created_by">
          Created by
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="created_at">
          Date created
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="scheduled_date">
          Scheduled for
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="date_sent">
          Sent Date
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="status">
          Status
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="sending_status">
          Sending Status
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="total_users">
          Clients
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="total_opened">
          Opened
        </SortableHeader>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {mailshots.map(mailshot => (
        <Table.Row key={mailshot.id}>
          <Table.Cell width={4}>
            <Link to={pageUrl(MailshotDetail, { mailshotId: mailshot.id })}>
              <Flex flexStart style={{ gap: '4px' }}>
                {mailshot.is_in_group && <Label color="teal">Group</Label>}
                {mailshot.title}
              </Flex>
            </Link>
          </Table.Cell>
          <Table.Cell width={2}>
            {_.get(mailshot.created_by, 'email', 'N/A')}
          </Table.Cell>
          <Table.Cell>{formatDateTime(mailshot.created_at)}</Table.Cell>
          <Table.Cell>
            {(mailshot.scheduled_date &&
              formatDateTime(mailshot.scheduled_date)) ||
              'N/A'}
          </Table.Cell>
          <Table.Cell>
            {(mailshot.date_sent && formatDateTime(mailshot.date_sent)) ||
              'N/A'}
          </Table.Cell>
          <Table.Cell>{mailshot.status}</Table.Cell>
          <Table.Cell>{mailshot.sending_status}</Table.Cell>
          <Table.Cell>{mailshot.total_users}</Table.Cell>
          <Table.Cell>
            <Link
              to={`${pageUrl(MailshotEmailLogList)}?mail_target=${
                mailshot.id
              }&opened=true`}>
              {mailshot.total_opened}
            </Link>
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default MailshotTable;
