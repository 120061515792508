import React from 'react';
import _ from 'lodash';

import { pageUrl } from 'config/routes';
import { ClientCreate, RenewalClientListV2 } from 'pages';

import {
  Page,
  Flex,
  Header,
  Button,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Filters
} from 'components';

import {
  PermissionsRequired,
  VisibleIf,
  PERMISSIONS,
  DisableableElement,
  hasPermission
} from 'permissions';

import {
  LAYOUT_FILTERS,
  ADVANCED_LAYOUT_FILTERS,
  EXCLUDE_LAYOUT_FILTERS
} from 'components/ClientFilters2';

import { clientList } from './sdk';

import { ClientTable, ImportClientsModal } from './components';

class ClientList extends React.Component {
  state = {
    isOpenImportClientsModal: false
  };

  componentDidUpdate(prevProps) {
    const locationChanged = this.props.location !== prevProps.location;

    if (
      locationChanged &&
      _.get(this.props.location, 'state.fromNavigation', false)
    ) {
      this.props.initialize();
    }
  }

  toggleIsOpenImportClientsModal = () => {
    this.setState(prevState => ({
      isOpenImportClientsModal: !prevState.isOpenImportClientsModal
    }));
  };

  render() {
    const {
      user,
      data,
      count,
      page,
      limit,
      filters,
      sort,
      filterBy,
      addFilters,
      orderBy,
      clear,
      changePage,
      changeRowsPerPage,
      history,
      isFetching,
      location,
      hasExcludedFilters,
      excludedCount,
      totalCount,
      refetch
    } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content maxWidth>
            <Flex spaceBetween>
              <Header>Clients</Header>
              <div>
                <VisibleIf
                  user={user}
                  permissions={[PERMISSIONS.CREATE_CLIENTS]}>
                  <Button
                    size="large"
                    onClick={() => history.push(pageUrl(ClientCreate))}>
                    Create Client
                  </Button>
                </VisibleIf>
                <Button
                  size="large"
                  onClick={() => history.push(pageUrl(RenewalClientListV2))}>
                  Renewals Sheet
                </Button>
                <VisibleIf
                  user={user}
                  permissions={[PERMISSIONS.IMPORT_CLIENTS_FROM_SPREADSHEET]}>
                  <Button
                    color="green"
                    size="large"
                    onClick={this.toggleIsOpenImportClientsModal}>
                    Import Clients
                  </Button>
                </VisibleIf>
              </div>
            </Flex>
            <DisableableElement
              disabled={!hasPermission(user, PERMISSIONS.SEARCH_CLIENTS)}>
              <Filters
                filters={filters}
                count={count}
                filterBy={filterBy}
                clearFilters={clear}
                layout={LAYOUT_FILTERS}
                advancedLayout={ADVANCED_LAYOUT_FILTERS}
                excludeLayout={EXCLUDE_LAYOUT_FILTERS}
                hasExcludedFilters={hasExcludedFilters}
                excludedCount={excludedCount}
                totalCount={totalCount}
              />
            </DisableableElement>
            <ClientTable
              isFetching={isFetching}
              clients={data}
              sort={sort}
              orderBy={orderBy}
              user={user}
              addFilters={addFilters}
              count={count}
              filters={filters}
              location={location}
              refetch={refetch}
              pagination={
                <Pagination
                  limit={limit}
                  count={count}
                  page={page}
                  changePage={changePage}
                  changeRowsPerPage={changeRowsPerPage}
                />
              }
            />
            {this.state.isOpenImportClientsModal && (
              <ImportClientsModal
                onClose={this.toggleIsOpenImportClientsModal}
                refetch={refetch}
              />
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: clientList
})(LoginRequired(PermissionsRequired([PERMISSIONS.VIEW_CLIENTS])(ClientList)));
