import React from 'react';

import { Header, Segment } from 'semantic-ui-react';

import { Flex, Button } from 'components';

import PresentationModal from './PresentationModal';

class Events extends React.Component {
  state = {
    isPresentationsModalOpen: false,
    event: null
  };

  openPresentationModal = event => {
    this.setState({
      isPresentationsModalOpen: true,
      event
    });
  };

  closeModal = () => {
    this.setState({ isPresentationsModalOpen: false, event: null });
  };

  render() {
    const { isPresentationsModalOpen } = this.state;

    const { events = [], title, style = {}, trainer } = this.props;

    return (
      <>
        <Segment style={style}>
          <Header>{title}</Header>
          {events.map(event => (
            <Flex spaceBetween>
              <div>{event.title}</div>
              <Button onClick={() => this.openPresentationModal(event)}>
                Add Presentation
              </Button>
            </Flex>
          ))}
        </Segment>
        {isPresentationsModalOpen && (
          <PresentationModal
            event={this.state.event}
            onClose={this.closeModal}
            fetchTrainer={this.props.fetchTrainer}
            trainer={trainer}
          />
        )}
      </>
    );
  }
}
export default Events;
