import React from 'react';

import { Field, SelectField } from 'components';
import { priceSeriesRegionList } from './sdk';

class PriceSeriesRegionSelect extends React.Component {
  state = {
    priceSeriesRegions: []
  };

  async componentDidMount() {
    const { data, success } = await priceSeriesRegionList();

    if (success) {
      this.setState({ priceSeriesRegions: data });
    }
  }

  render() {
    const { name, ...rest } = this.props;
    const { priceSeriesRegions } = this.state;

    const options = priceSeriesRegions.map(priceSeriesRegion => {
      return {
        key: priceSeriesRegion.id,
        value: priceSeriesRegion.id,
        label: priceSeriesRegion.title
      };
    });

    return (
      <Field
        name={name || 'priceSeriesRegion'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default PriceSeriesRegionSelect;
