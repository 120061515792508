import React from 'react';
import _ from 'lodash';

import { withRouter, Link } from 'react-router-dom';
import { Menu, Icon, Divider } from 'semantic-ui-react';

import { VisibleIf } from 'permissions';

import { getLink } from 'components/Navigation/utils';

import styles from './styles.module.css';

class MenuItem extends React.Component {
  state = {
    isOpenDropdown: false
  };

  toggleDropdown = () => {
    this.setState(prevState => ({
      isOpenDropdown: !prevState.isOpenDropdown
    }));
  };

  redirectOrOpenDropdown = () => {
    const { link } = this.props;

    if (link) {
      this.redirectTo(link);
    } else {
      this.toggleDropdown();
    }
  };

  redirectTo = link => {
    const url = getLink(link);
    this.props.history.push(url);
  };

  shouldDisplayItem = (user, menuItem) => {
    if (menuItem.superUserRequired && !_.get(user, 'is_superuser', false)) {
      return false;
    }

    return true;
  };

  render() {
    const { isOpenDropdown } = this.state;

    const { title, items = [], user } = this.props;

    return (
      <Menu.Item as="a" style={{ textAlign: 'left' }}>
        <div onClick={this.redirectOrOpenDropdown} className={styles.itemTitle}>
          {title}
          {!_.isEmpty(items) && (
            <Icon name={isOpenDropdown ? 'angle up' : 'angle down'} />
          )}
        </div>

        {isOpenDropdown &&
          items.map(
            item =>
              this.shouldDisplayItem(user, item) && (
                <VisibleIf
                  user={user}
                  permissions={item.permissions ? item.permissions : []}>
                  <Divider style={{ margin: '5px 0' }} />
                  {item.link && (
                    <div className={styles.itemLink}>
                      <Link
                        to={getLink(item.link, item.params)}
                        search={item.queryParams || ''}
                        state={{ fromNavigation: true }}>
                        {item.title}
                      </Link>
                    </div>
                  )}
                  {!item.link && item.action && (
                    <div
                      className={styles.itemLink}
                      onClick={() => item.action()}>
                      {item.title}
                    </div>
                  )}
                </VisibleIf>
              )
          )}
      </Menu.Item>
    );
  }
}

export default withRouter(MenuItem);
