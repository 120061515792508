import React, { useEffect, useState, useCallback } from 'react';

import { useParams } from 'react-router-dom';

import { Header, Segment } from 'semantic-ui-react';

import { notifyErrors } from 'utils/notifications';

import { withLoggedUser } from 'hooks';

import { Page } from 'components';

import { companyV2Detail } from './sdk';

import { CompanyV2UpdateForm } from './components';

const CompanyV2Detail = ({ user, crumbs }) => {
  const [company, setCompany] = useState(null);

  const { companyId } = useParams();

  const fetchCompany = useCallback(async () => {
    const { data, errors, success } = await companyV2Detail(companyId);
    if (success) {
      setCompany(data);
    } else {
      notifyErrors(errors);
    }
  }, [companyId]);

  useEffect(() => {
    fetchCompany();
  }, [fetchCompany]);

  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content crumbs={crumbs}>
          <Header as="h1">Edit Company</Header>
          {company && (
            <Segment>
              <CompanyV2UpdateForm
                company={company}
                fetchCompany={fetchCompany}
              />
            </Segment>
          )}
        </Page.Content>
      </Page.Body>
    </Page>
  );
};

export default withLoggedUser(CompanyV2Detail);
