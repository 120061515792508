import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { Segment, Table, Dimmer, Loader } from 'semantic-ui-react';
import _ from 'lodash';

import { TableFooter } from 'components';

import { pageUrl } from 'config/routes';

import { PriceSerieDetail } from 'pages';

import { PERMISSIONS, hasPermission, DisableableElement } from 'permissions';

import { getDatePriceArray } from './utils';

function PriceSeriesTable({
  prices,
  dates,
  pagination,
  user,
  approvePrice,
  currentFilters
}) {
  const { productSlug } = useParams();

  const approved = _.get(currentFilters, 'approved', false);

  const isLoading = _.isNil(prices) || _.isNil(dates);

  const isTableVisible = !isLoading && !_.isEmpty(prices);

  const canUserPublish = hasPermission(user, PERMISSIONS.PUBLISH_PRICES);

  return (
    <Segment>
      {isLoading && (
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      )}
      {isTableVisible && (
        <Table celled compact striped selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                singleLine
                style={{ fontWeight: '400' }}
                colspan={dates.length + 3}>
                {pagination}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Price Name</Table.HeaderCell>

              {dates.map(date => (
                <Table.HeaderCell key={`header-${date}`}>
                  {date}
                </Table.HeaderCell>
              ))}

              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Editor</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <DisableableElement disabled={!canUserPublish}>
              {prices.map(price => (
                <Table.Row>
                  <Table.Cell>
                    <Link
                      to={pageUrl(PriceSerieDetail, {
                        productSlug,
                        priceSeriesId: price.id
                      })}>
                      {price.display_as || price.title}
                    </Link>
                  </Table.Cell>

                  {getDatePriceArray(price, dates, productSlug).map(
                    dataRepresentation => (
                      <Table.Cell>{dataRepresentation}</Table.Cell>
                    )
                  )}
                  <Table.Cell>
                    {approved && 'Approved'}
                    {!approved && (
                      <Link onClick={() => approvePrice(price)}>Publish</Link>
                    )}
                  </Table.Cell>
                  <Table.Cell>{price.last_approved_by}</Table.Cell>
                </Table.Row>
              ))}
            </DisableableElement>
          </Table.Body>
          <TableFooter>{pagination}</TableFooter>
        </Table>
      )}
    </Segment>
  );
}

export default PriceSeriesTable;
