import React from 'react';
import _ from 'lodash';

import {
  Page,
  Header,
  ListViewEssentials,
  Pagination,
  Filters as SharedFilters
} from 'components';

import { withLoggedUser, withPermissions } from 'hooks';
import { PERMISSIONS } from 'permissions';

import { notifySuccess, notifyErrors } from 'utils/notifications';

import { useParams } from 'react-router-dom';

import { PriceSeriesTable } from './components';

import { priceSeriesForPublishList, priceSeriesApprove } from './sdk';

import { FILTERS as LAYOUT_FILTERS } from './utils';

function PriceSeriePublish({
  user,
  data,
  limit,
  count,
  page,
  changePage,
  filters,
  clear,
  filterBy,
  crumbs,
  refetch
}) {
  const { productSlug } = useParams();
  const approvePrice = async price => {
    const { success, errors } = await priceSeriesApprove(price.id);
    if (success) {
      notifySuccess(`${price.display_as} approved successfully!`);
      refetch();
    } else {
      notifyErrors(errors);
    }
  };

  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content crumbs={crumbs}>
          <Header>Price Series Publish</Header>
          <SharedFilters
            filters={filters}
            count={count}
            filterBy={filterBy}
            clearFilters={clear}
            layout={LAYOUT_FILTERS(productSlug)}
          />
          <PriceSeriesTable
            {...data}
            user={user}
            currentFilters={filters}
            approvePrice={approvePrice}
            pagination={
              <Pagination
                limit={limit}
                count={count}
                page={page}
                changePage={changePage}
                rowsPerPageEnabled={false}
              />
            }
          />
        </Page.Content>
      </Page.Body>
    </Page>
  );
}

export default ListViewEssentials({
  sdk: props => {
    const { productSlug } = props.match.params;
    return _.partial(priceSeriesForPublishList, productSlug);
  },
  passPropsToSdk: true
})(
  withLoggedUser(withPermissions([PERMISSIONS.VIEW_PRICES])(PriceSeriePublish))
);
