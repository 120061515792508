import _ from 'lodash';
import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const budgetDashboardApi = async params => {
  let year = _.get(params, 'year');

  if (!year) {
    year = new Date().getFullYear();
  }
  const url = `${BASE_URL}/crm/budget/${year}/`;

  return await requestSdk(() => get(url));
};
