import React from 'react';
import _ from 'lodash';

import { Divider } from 'semantic-ui-react';

import { formatDate } from 'utils';
import { Flex } from 'components';

import { getPresalePrice } from './utils';

const PresalePrices = ({ event }) =>
  event.presales.map((presale, index) => (
    <>
      {0 !== index && <Divider style={{ margin: '0.3rem 0' }} />}
      <Flex spaceBetween>
        <div>
          <div>
            <b>{presale.title}</b>
          </div>{' '}
          <div>({formatDate(presale.start_date)})</div>
          <div>({formatDate(presale.end_date)})</div>
        </div>
        {!_.isEmpty(presale.prices) ? (
          <b>{getPresalePrice(event, presale)}</b>
        ) : (
          'N/A'
        )}
      </Flex>
    </>
  ));

export default PresalePrices;
