import React from 'react';

import { Field, FastField, AsyncSelectField } from 'components';

import { companyList } from './sdk';

class CompanySelect extends React.Component {
  loadOptions = async (inputValue, callback) => {
    if (inputValue.length < 3) {
      callback([]);
      return;
    }

    const { data } = await companyList(
      inputValue,
      this.props.isPowerMaterialRelated
    );
    const options = data.map(company => {
      return {
        key: company.id,
        value: company.id,
        label: company.title
      };
    });
    callback(options);
  };

  render() {
    const { name, fast = false, ...rest } = this.props;

    let Component = Field;
    if (fast) {
      Component = FastField;
    }

    return (
      <Component
        name={name || 'company'}
        component={AsyncSelectField}
        loadOptions={this.loadOptions}
        isClearable
        {...rest}
      />
    );
  }
}

export default CompanySelect;
