import React from 'react';

import { Segment, Header } from 'semantic-ui-react';

import { prepareFormData } from 'utils/forms';
import { notifySuccess, notifyErrors } from 'utils/notifications';
import { SortableList } from 'components';

class ArticlesSortableContainer extends React.Component {
  state = {
    articles: []
  };

  onSortEnd = async ({ item, oldIndex, newIndex }) => {
    const article = item;
    const {
      newsletter,
      section,
      fetchNewsletter,
      newsletterOrderArticle
    } = this.props;

    const articleId = article.id;

    const data = {
      article: articleId,
      oldIndex,
      newIndex
    };

    const { success, errors } = await newsletterOrderArticle(
      newsletter.id,
      section,
      prepareFormData(data)
    );

    if (success) {
      fetchNewsletter();
      notifySuccess('Articles reordered.');
    } else {
      notifyErrors(errors);
    }
  };

  render() {
    const { articles, newsletter } = this.props;

    const items = articles.map(article => ({
      // SortableList requires title prop
      title: article.full_headline,
      ...article
    }));

    return (
      <SortableList
        disabled={newsletter.live}
        items={items}
        onSortEnd={this.onSortEnd}
      />
    );
  }
}

const ArticlesSection = ({ sectionName, color, ...props }) => {
  return (
    <Segment color={color}>
      <Header as="div" size="tiny">
        {sectionName}
      </Header>
      <ArticlesSortableContainer {...props} />
    </Segment>
  );
};

export default ArticlesSection;
