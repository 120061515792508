import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const clientPipelineItems = async (id, params) => {
  const url = `${BASE_URL}/crm/pipelines/for-client/${id}/`;

  return await requestSdk(() => get(url, params));
};

export const updatePipelineItems = async (clientId, data) => {
  const url = `${BASE_URL}/crm/pipelines/for-client/${clientId}/update/`;

  return await requestSdk(() => post(url, data));
};
