import _ from 'lodash';
import React from 'react';

import { Login } from 'pages';

import { pageUrl } from 'config/routes';

import { getNextUrl } from 'utils';

import { me, logout } from 'sdk';

const LoginRequired = WrappedComponent => {
  class HOC extends React.Component {
    state = {
      user: null
    };

    componentDidMount() {
      this.fetchMe();
    }

    redirectToLogin = () => {
      const { pathname } = this.props.history.location;
      const nextUrl = getNextUrl(pageUrl(Login), pathname);

      this.props.history.push(nextUrl);
    };

    fetchMe = async () => {
      const { data } = await me();

      if (!_.isEmpty(data)) {
        this.setState({ user: data });
      } else {
        logout();
        this.redirectToLogin();
      }
    };

    render() {
      const { match, ...rest } = this.props;
      const { user } = this.state;

      if (user) {
        return <WrappedComponent match={match} user={user} {...rest} />;
      }
      return null;
    }
  }

  return HOC;
};

export default LoginRequired;
