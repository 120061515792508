import React from 'react';

import _ from 'lodash';

import { Field, SelectField } from 'components';

import { industryList } from './sdk';

class IndustrySelect extends React.Component {
  state = {
    industries: []
  };
  async componentDidMount() {
    const { data, success } = await industryList();

    if (success) {
      this.setState({ industries: _.orderBy(data, 'title') });
    }
  }

  render() {
    const { name, ...rest } = this.props;
    const { industries } = this.state;

    const options = industries.map(industry => {
      return {
        key: industry.id,
        value: industry.id,
        label: industry.title
      };
    });

    return (
      <Field
        name={name || 'industry'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default IndustrySelect;
