import React from 'react';

import _ from 'lodash';

import { Field, SelectField } from 'components';

import { subIndustryList } from './sdk';

class SubIndustrySelect extends React.Component {
  state = {
    industries: []
  };

  fetchSubIndustries = async () => {
    const { industry } = this.props;

    if (_.isNil(industry) || (_.isArray(industry) && _.isEmpty(industry))) {
      this.setState({ industries: [] });
      return;
    }

    const { data, success } = await subIndustryList(industry);

    if (success) {
      this.setState({ industries: _.orderBy(data, 'title') });
    }
  };

  componentDidMount() {
    this.fetchSubIndustries();
  }

  componentDidUpdate(prevProps) {
    if (this.props.industry !== prevProps.industry) {
      this.fetchSubIndustries();
    }
  }

  render() {
    const { name, ...rest } = this.props;
    const { industries } = this.state;

    const options = industries.map(industry => {
      return {
        key: industry.id,
        value: industry.id,
        label: industry.title
      };
    });

    return (
      <Field
        name={name || 'industry'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default SubIndustrySelect;
