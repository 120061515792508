import React from 'react';

import ReactDiffViewer from 'react-diff-viewer-continued';

import { Button, Header, Modal } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

class ChangesModal extends React.Component {
  state = { modalOpen: false };

  handleOpen = () => {
    this.setState({ modalOpen: true });
    this.props.onOpen();
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
    this.props.onClose();
  };
  render() {
    const { oldValue, newValue } = this.props;
    const title = 'Text Changes';
    return (
      <Modal
        open={this.state.modalOpen}
        onClose={this.handleClose}
        trigger={
          <Button color="green" onClick={this.handleOpen} icon="eye"></Button>
        }
        size="large">
        <Header content={title} />
        <Modal.Content>
          <ReactDiffViewer
            oldValue={oldValue}
            newValue={newValue}
            splitView={true}
            hideLineNumbers={true}
            disableWordDiff={false}
            leftTitle="Old text"
            rightTitle="New text"
          />
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" inverted onClick={this.handleClose}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default withRouter(ChangesModal);
