import {
  SelectField,
  DateField,
  InputField,
  SubscriptionSelect,
  SubscriptionStatusSelect,
  AdminSelect,
  RegionSelect,
  CountrySelect
} from 'components';

const limitOptions = [
  {
    key: 1,
    value: 1,
    label: 'Latest'
  },
  {
    key: 5,
    value: 5,
    label: 'Last 5'
  },
  {
    key: 10,
    value: 10,
    label: 'Last 10'
  }
];

export const FILTERS = [
  [
    {
      name: 'issues',
      emptyValue: 1,
      transform: value => parseInt(value, 10),
      component: SelectField,
      label: 'Limit',
      props: () => ({
        options: limitOptions
      })
    },
    {
      name: 'date_from',
      emptyValue: null,
      component: DateField,
      label: 'Date from'
    },
    {
      name: 'date_to',
      emptyValue: null,
      component: DateField,
      label: 'Date to'
    }
  ],
  [
    {
      name: 'client_email',
      emptyValue: '',
      component: InputField,
      label: 'Client Email'
    },
    {
      name: 'first_name',
      emptyValue: '',
      component: InputField,
      label: 'First name'
    },
    {
      name: 'last_name',
      emptyValue: '',
      component: InputField,
      label: 'Last name'
    },
    {
      name: 'company_name',
      emptyValue: '',
      component: InputField,
      label: 'Company name'
    },
    {
      name: 'client_id',
      emptyValue: '',
      component: InputField,
      label: 'Client ID'
    }
  ],
  [
    {
      name: 'subscription',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: SubscriptionSelect,
      label: 'Subscription'
    },
    {
      name: 'subscription_status',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: SubscriptionStatusSelect,
      label: 'Subscription Status'
    },
    {
      name: 'account_manager',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: AdminSelect,
      label: 'Account Manager'
    }
  ],
  [
    {
      name: 'region',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: RegionSelect,
      label: 'Region'
    },
    {
      name: 'country',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: CountrySelect,
      label: 'Country'
    }
  ]
];
