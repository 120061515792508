import React from 'react';

import {
  Page,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Filters as SharedFilters
} from 'components';

import {
  PermissionsRequired,
  PERMISSIONS,
  hasPermission,
  DisableableElement
} from 'permissions';

import { linkedClientsList } from './sdk';

import { ClientTable } from './components';
import { FILTERS } from './utils';

class LinkedClientsList extends React.Component {
  render() {
    const {
      user,
      data,
      count,
      page,
      limit,
      filters,
      sort,
      filterBy,
      orderBy,
      clear,
      changePage,
      changeRowsPerPage,
      isFetching,
      crumbs,
      refetch
    } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content maxWidth crumbs={crumbs}>
            <Header>Linked Clients</Header>
            <DisableableElement
              disabled={!hasPermission(user, PERMISSIONS.SEARCH_CLIENTS)}>
              <SharedFilters
                filters={filters}
                count={count}
                filterBy={filterBy}
                clearFilters={clear}
                layout={FILTERS}
              />
            </DisableableElement>
            <ClientTable
              isFetching={isFetching}
              clients={data}
              sort={sort}
              orderBy={orderBy}
              user={user}
              refetch={refetch}
              pagination={
                <Pagination
                  limit={limit}
                  count={count}
                  page={page}
                  changePage={changePage}
                  changeRowsPerPage={changeRowsPerPage}
                />
              }
            />
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: linkedClientsList
})(
  LoginRequired(
    PermissionsRequired([PERMISSIONS.VIEW_CLIENTS])(LinkedClientsList)
  )
);
