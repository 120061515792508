import _ from 'lodash';
import React from 'react';

import { Field, SelectField } from 'components';

import { InvoiceItemWithPromoCodeList } from './sdk';

class InvoiceItemSelect extends React.Component {
  state = {
    options: []
  };

  async componentDidMount() {
    const { data, success } = await InvoiceItemWithPromoCodeList(
      this.props.invoiceId
    );

    if (success) {
      this.setState({
        options: data.map(invoiceItem => ({
          key: invoiceItem.pk,
          value: invoiceItem.content_manager_item.id,
          label: invoiceItem.description,
          itemId: invoiceItem.pk
        }))
      });
    }
  }

  render() {
    const { name, excludeOptions, ...rest } = this.props;
    let options = this.state.options;

    if (!_.isEmpty(excludeOptions)) {
      options = _.filter(
        options,
        option => _.findIndex(excludeOptions, { id: option.itemId }) < 0
      );
    }

    return (
      <Field
        name={name || 'item'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default InvoiceItemSelect;
