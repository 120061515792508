import * as yup from 'yup';
import moment from 'moment';

export const validationSchema = yup.object().shape({
  title: yup.string().required('This field is required.'),
  shortTitle: yup.string().required('This field is required.'),
  product: yup.string().required('This field is required.'),
  incoTerms: yup.string().required('This field is required.'),
  format: yup.string().required('This field is required.'),
  unit: yup.string().required('This field is required.'),
  frequency: yup.string().required('This field is required.'),
  currency: yup.string().required('This field is required.'),
  scale: yup.string().required('This field is required.'),
  decimalPlaces: yup
    .number()
    .required('This field is required.')
    .min(0),
  date: yup.string().required('This field is required.'),
  region: yup.string().required('This field is required.'),
  priceDataSource: yup
    .string()
    .nullable()
    .required('This field is required.'),
  priceDataSymbols: yup.string().when('priceDataSource', {
    is: value => value !== 'Manual',
    then: schema => schema.required('This field is required.')
  })
});

export const DEFAULT_INITIAL_VALUES = {
  showOnHomepage: false,
  showInRightColumn: false,
  title: '',
  shortTitle: '',
  displayAs: '',
  slug: '',
  product: '',
  region: '',
  region2: '',
  incoTerms: '',
  format: '',
  unit: '',
  frequency: '',
  currency: '',
  scale: '',
  decimalPlaces: 0,
  date: moment().format('YYYY-MM-DD'),
  access: [],
  articleRegions: [],
  articlePrices: [],
  articleProducts: [],
  articleAuthors: [],
  text: '',
  metaTitle: '',
  metaKeywords: '',
  metaDescription: '',
  metaStandout: '',
  priceDataSource: 'Manual',
  priceDataSymbols: ''
};

export const getInitialValues = isPowerMaterial => {
  let initialValues = DEFAULT_INITIAL_VALUES;

  if (isPowerMaterial) {
    initialValues = {
      ...initialValues,
      availableToSteelSubscribers: false,
      availableToSteelLiveSubscribers: false,
      mainGroup: ''
    };
  } else {
    initialValues = {
      ...initialValues,
      category: ''
    };
  }

  return initialValues;
};
