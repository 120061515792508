import _ from 'lodash';
import * as yup from 'yup';

export const INITIAL_VALUES = {
  question: '',
  description: '',
  pollType: 's',
  dateStart: null,
  dateEnd: null,
  choices: ['']
};

export const pollSchema = yup.object().shape({
  question: yup.string().required('Question is required.'),
  pollType: yup.string().required('This field is required.'),
  dateStart: yup
    .date()
    .nullable()
    .required('This field is required'),
  dateEnd: yup
    .date()
    .nullable()
    .required('This field is required')
});

export const filterEmptyChoices = choices =>
  _.filter(choices, choice => !_.isEmpty(choice));
