import _ from 'lodash';
import {
  DateField,
  SubscriptionSelect,
  SubscriptionStatusSelect,
  CurrencySelect,
  RenewalStateSelect,
  MonthSelect,
  YearSelect,
  InputField,
  AdminSelect,
  RegionSelect,
  CountrySelect
} from 'components';

export const getCurrency = (currencies, id) =>
  _.get(
    _.head(_.filter(currencies, curr => curr.id === parseInt(id))),
    'code',
    ''
  );

const currentYear = new Date().getFullYear();

export const FILTERS = [
  [
    {
      name: 'start_date',
      emptyValue: null,
      component: DateField,
      label: 'Start Date',
      width: 2
    },
    {
      name: 'end_date',
      emptyValue: null,
      component: DateField,
      label: 'End Date',
      width: 2
    },
    {
      name: 'subscription',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: SubscriptionSelect,
      label: 'Subscription Service',
      width: 5
    },
    {
      name: 'subscription_status',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: SubscriptionStatusSelect,
      label: 'Subscription Status',
      width: 5
    }
  ],
  [
    {
      name: 'past_invoice_currency',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: CurrencySelect,
      label: 'Invoice Currency',
      width: 2
    },
    {
      name: 'convert_to',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: CurrencySelect,
      label: 'Convert To',
      width: 2
    },
    {
      name: 'region',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: RegionSelect,
      label: 'Region',
      width: 4
    },
    {
      name: 'country',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: CountrySelect,
      label: 'Country',
      width: 4
    },
    {
      name: 'account_manager',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: AdminSelect,
      label: 'Account Manager',
      width: 4
    }
  ],
  [
    {
      name: 'renewal_state',
      emptyValue: null,
      fieldComponent: RenewalStateSelect,
      label: 'Renewal Stats',
      width: 3
    },
    {
      name: 'renewal_month',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: MonthSelect,
      label: 'Renewal Month',
      width: 3
    },
    {
      name: 'renewal_year',
      emptyValue: currentYear,
      transform: value => parseInt(value, 10),
      fieldComponent: YearSelect,
      label: 'Renewal Year',
      width: 3,
      props: () => ({
        customOptions: _.range(currentYear - 1, currentYear + 10)
      })
    },
    {
      name: 'company_name',
      emptyValue: '',
      component: InputField,
      label: 'Company name',
      width: 5
    }
  ]
];
