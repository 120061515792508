import * as yup from 'yup';

import { getToday } from 'utils/forms';

export const DEFAULT_INITIAL_VALUES = {
  title: '',
  slug: '',
  dateActivation: getToday(),
  isSteelReport: false,
  isCustomReport: false,
  image: null,
  thumb: null,
  file: null,
  sample: null,
  textHtml: '',
  relatedProducts: [],
  tags: []
};

export const marketDataReportCreateSchema = yup.object().shape({
  title: yup.string().required('Title is required.'),
  slug: yup.string().required('Slug is required.'),
  dateActivation: yup.string().required('Date of activation is required.'),
  tags: yup
    .array()
    .of(yup.string().max(100, 'Tag must be at most 100 characters long.'))
});
