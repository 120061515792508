import React from 'react';

import { withRouter } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  InputField,
  FormRow,
  CreateButtonInRow,
  UnsavedFormPrompt,
  RichTextAreaField,
  CheckboxField,
  FaqCategorySelect,
  FaqSelect
} from 'components';

import { notifySuccess } from 'utils/notifications';
import { handleFormErrors, prepareFormData } from 'utils/forms';
import { populateSlug } from 'utils';
import { pageUrl } from 'config/routes';
import { FaqList } from 'pages';

import { faqCreateSchema } from './utils';
import { faqCreate } from './sdk';

class FaqCreateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await faqCreate(data);

    if (success) {
      notifySuccess('FAQ created.');
      this.props.history.push(pageUrl(FaqList));
    } else {
      handleFormErrors(errors, setFieldError);
    }
    setSubmitting(false);
  };

  render() {
    const initialValues = {
      question: '',
      slug: '',
      answer: '',
      category: '',
      isPublished: false,
      relatedFaqs: []
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={faqCreateSchema}
        onSubmit={this.handleSubmit}>
        {({ values, handleSubmit, isSubmitting, setFieldValue, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty && !isSubmitting} />

            <CreateButtonInRow onClick={handleSubmit} disabled={isSubmitting} />
            <FormRow>
              <Field
                width={6}
                name="question"
                label="Question"
                required
                component={InputField}
                onChange={(e, data) => {
                  populateSlug(data, setFieldValue);
                }}
              />
              <Field
                required
                width={6}
                name="slug"
                label="Slug"
                component={InputField}
              />
              <Field
                width={4}
                name="isPublished"
                label="Is published"
                component={CheckboxField}
              />
            </FormRow>
            <FormRow>
              <FaqCategorySelect
                width={6}
                name="category"
                label="FAQ Category"
              />
            </FormRow>
            <FormRow>
              <Field
                width={12}
                component={RichTextAreaField}
                label="Answer"
                name="answer"
              />
            </FormRow>
            <FormRow>
              <FaqSelect width={8} name="relatedFaqs" label="Related FAQs" />
            </FormRow>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(FaqCreateForm);
