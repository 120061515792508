import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const dailyNewsletterAddRegionArticle = async (
  dailyNewsletterId,
  data
) => {
  const url = `${BASE_URL}/newsletters/daily/${dailyNewsletterId}/add-region/`;

  return await requestSdk(() => post(url, data));
};
