import React from 'react';

import _ from 'lodash';

import { withRouter } from 'react-router-dom';

import { Segment, Header } from 'semantic-ui-react';

import { Page, LoginRequired, Flex, ContentEditionWarning } from 'components';
import {
  DisableableElement,
  hasPermission,
  PERMISSIONS,
  PermissionsRequired
} from 'permissions';

import { EDITION_WARNING_CONTENT_TYPES } from 'domainConstants';

import { weeklyPowerMaterialsDetail, weeklyPowerMaterialsUpdate } from './sdk';

import { WeeklyPowerMaterialsUpdateForm } from './components';

class WeeklyPowerMaterialsDetail extends React.Component {
  state = {
    data: null
  };

  componentDidMount() {
    this.fetchWeeklyPowerMaterialsDetail();
  }

  fetchWeeklyPowerMaterialsDetail = async () => {
    const { id } = this.props.match.params;

    const { data, errors, success } = await weeklyPowerMaterialsDetail(id);

    if (success) {
      this.setState({ data: data });
    } else {
      this.setState({ errors });
    }
  };

  render() {
    const { user, crumbs } = this.props;

    const { data } = this.state;
    const sendingStatus = _.get(data, 'sending_status', '');

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            {!_.isNil(data) && (
              <ContentEditionWarning
                user={user}
                contentType={
                  EDITION_WARNING_CONTENT_TYPES.WEEKLY_POWER_MATERIALS
                }
                contentId={data.id}
              />
            )}

            <Segment>
              <Flex spaceBetween>
                <Header>Edit Weekly Power Materials</Header>
                <Segment>
                  <p>
                    Sending status: <strong>{sendingStatus}</strong>
                  </p>
                </Segment>
              </Flex>
              {!_.isNil(data) && (
                <DisableableElement
                  disabled={
                    !hasPermission(
                      user,
                      PERMISSIONS.CHANGE_WEEKLY_POWER_MATERIALS
                    )
                  }>
                  <WeeklyPowerMaterialsUpdateForm
                    data={data}
                    fetchWeeklyPowerMaterialsDetail={
                      this.fetchWeeklyPowerMaterialsDetail
                    }
                    subject="Weekly Power Materials"
                    updateSdk={weeklyPowerMaterialsUpdate}
                  />
                </DisableableElement>
              )}
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default withRouter(
  LoginRequired(
    PermissionsRequired([PERMISSIONS.VIEW_WEEKLY_POWER_MATERIALS])(
      WeeklyPowerMaterialsDetail
    )
  )
);
