import React from 'react';

import { Field, SelectField } from 'components';

const UNSUBSCRIBE_METHODS = ['Manual', 'Auto'];

class UnsubscribemethodSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = UNSUBSCRIBE_METHODS.map(method => {
      return {
        key: method,
        value: method,
        label: method
      };
    });

    return (
      <Field
        name={name || 'unsubscribeMethod'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default UnsubscribemethodSelect;
