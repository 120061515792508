import React from 'react';

import _ from 'lodash';

import { Header, Segment } from 'semantic-ui-react';

import { withRouter } from 'react-router-dom';

import { Page, LoginRequired, Flex } from 'components';

import { trainerDetail } from './sdk';

import { TrainerUpdateForm } from './components';

class TrainerDetail extends React.Component {
  state = {
    errors: null,
    trainer: null
  };

  fetchTrainer = async () => {
    const { trainerId } = this.props.match.params;

    const { data, errors, success } = await trainerDetail(trainerId);

    if (success) {
      this.setState({
        trainer: data
      });
    } else {
      this.setState({ errors });
    }
  };

  componentDidMount() {
    this.fetchTrainer();
  }

  render() {
    const { user, crumbs } = this.props;

    const { trainer } = this.state;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Flex spaceBetween>
                <Header as="h1">Speaker Detail</Header>
              </Flex>
              {!_.isNil(trainer) && (
                <TrainerUpdateForm
                  data={trainer}
                  fetchTrainer={this.fetchTrainer}
                />
              )}
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(withRouter(TrainerDetail));
