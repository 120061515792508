import _ from 'lodash';

import { parseQueryString } from 'utils';

const calculateOffset = (page, limit) => {
  return (parseInt(page) - 1) * limit;
};

export const PAGINATION_KEY = 'pagination';

export const INITIAL_PAGINATION_STATE = {
  count: 0,
  page: 1,
  limit: null,
  offset: null
};
export const INITIAL_CURSOR_PAGINATION_STATE = {
  currentCursor: null,
  nextCursor: null,
  previousCursor: null,
  pageSize: null
};

export const getInitialPagination = limit => {
  const { page } = INITIAL_PAGINATION_STATE;

  const offset = calculateOffset(page, limit);

  return {
    [PAGINATION_KEY]: { ...INITIAL_PAGINATION_STATE, offset, limit }
  };
};
export const getInitialCursorPagination = pageSize => {
  return {
    [PAGINATION_KEY]: { ...INITIAL_CURSOR_PAGINATION_STATE, pageSize }
  };
};

export const getPagination = state => {
  return state[PAGINATION_KEY];
};

export const updatePagination = (state, newPagination) => {
  const oldPagination = getPagination(state);

  const obj = {
    ...oldPagination,
    ...newPagination
  };

  const { page, limit } = obj;
  const offset = calculateOffset(page, limit);

  obj.offset = offset;

  return obj;
};
export const updateCursorPagination = (state, newPagination) => {
  const oldPagination = getPagination(state);

  return {
    ...oldPagination,
    ...newPagination
  };
};

export const getPaginationFromUrl = (url, limit) => {
  const urlParams = parseQueryString(url);
  const result = getInitialPagination(limit)[PAGINATION_KEY];

  Object.keys(INITIAL_PAGINATION_STATE).forEach(paginationKey => {
    if (urlParams[paginationKey]) {
      result[paginationKey] = parseInt(urlParams[paginationKey], 10);
    }
  });

  result.offset = calculateOffset(result.page, result.limit);

  return result;
};

export const getCursorPaginationFromUrl = (url, pageSize) => {
  const urlParams = parseQueryString(url);
  const initialPagination = getInitialCursorPagination(pageSize)[
    PAGINATION_KEY
  ];

  if (urlParams.page_size) {
    return {
      ...initialPagination,
      pageSize: parseInt(urlParams.page_size, 10)
    };
  }

  return initialPagination;
};

export const buildPagination = pagination => {
  const { limit, offset } = pagination;

  return {
    limit,
    offset
  };
};

export const buildCursorPagination = pagination => {
  const { currentCursor, pageSize } = pagination;
  return { cursor: currentCursor, page_size: pageSize };
};

export const buildPaginationForFrontendUrl = pagination => {
  const { page, limit } = pagination;
  return { page, limit };
};

export const getTotalPagesCount = (totalCount, itemsPerPageCount) => {
  let totalPagesCount = totalCount / itemsPerPageCount;

  if (_.isInteger(totalPagesCount)) {
    return totalPagesCount;
  }

  return _.floor(totalPagesCount + 1);
};

export const normalizeCursorPaginationResponse = data => ({
  nextCursor: _.get(data, 'next_cursor', null),
  previousCursor: _.get(data, 'previous_cursor', null),
  pageSize: _.get(data, 'page_size', null)
});
