import React from 'react';

import { Field, SelectField } from 'components';

const EMAIL_TYPES = ['Plain Text', 'HTML', 'PDF'];

class EmailTypeSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = EMAIL_TYPES.map(emailType => {
      return {
        key: emailType,
        value: emailType,
        label: emailType
      };
    });

    return (
      <Field
        name={name || 'emailType'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default EmailTypeSelect;
