import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

const COMMON_PARAMS =
  'paginate=False&status=Ready&exclude_trigger=Welcome%20email';

export const emailTemplateList = async () => {
  const url = `${BASE_URL}/emails/email-templates/?${COMMON_PARAMS}`;

  return await requestSdk(() => get(url));
};

export const emailTemplateListFavourites = async () => {
  const url = `${BASE_URL}/emails/email-templates?${COMMON_PARAMS}&favourites_only=True`;

  return await requestSdk(() => get(url));
};

export const emailTemplateListEvents = async () => {
  const url = `${BASE_URL}/emails/email-templates/?${COMMON_PARAMS}&template_type=Event`;

  return await requestSdk(() => get(url));
};

export const emailTemplateListSubscriptions = async () => {
  const url = `${BASE_URL}/emails/email-templates/?${COMMON_PARAMS}&template_type=Subscription`;

  return await requestSdk(() => get(url));
};

export const emailTemplateListOther = async () => {
  const url = `${BASE_URL}/emails/email-templates/?${COMMON_PARAMS}&template_type=Other`;

  return await requestSdk(() => get(url));
};
