import React from 'react';

import _ from 'lodash';

import { Segment, Header, Table } from 'semantic-ui-react';

import { formatDateTime } from 'utils';

import { YesNo, InstantNewsModal } from 'components';

import OpenModalButton from 'components/OpenModalButton';

import { getArticleData } from 'components/InstantNewsModal/utils';

import { getInstantNewTotalCount } from './utils';

class InstantNewsTable extends React.Component {
  render() {
    const {
      article,
      energyArticle,
      powerMaterialArticle,
      webArticle,
      fetchArticle
    } = this.props;

    const { articleData, choosenArticle } = getArticleData(
      article,
      energyArticle,
      powerMaterialArticle,
      webArticle
    );
    const news = _.get(choosenArticle, 'instant_news');
    const stats = _.get(
      choosenArticle,
      'instant_news_aggregations.targets',
      []
    );

    return (
      <Segment>
        <Header as="h2">Breaking news/Trending now items</Header>
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Subject</Table.HeaderCell>
              <Table.HeaderCell>Ready</Table.HeaderCell>

              <Table.HeaderCell>Send At</Table.HeaderCell>
              <Table.HeaderCell>Total emails count</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {news.map(item => (
              <Table.Row>
                <Table.Cell width={7}>{item.subject}</Table.Cell>
                <Table.Cell width={2}>
                  <YesNo value={item.is_ready} />
                </Table.Cell>
                <Table.Cell width={2} textAlign="center">
                  {formatDateTime(item.send_at)}
                </Table.Cell>
                <Table.Cell width={2} textAlign="center">
                  {getInstantNewTotalCount(item, stats)}
                </Table.Cell>
                <Table.Cell width={2}>
                  <OpenModalButton
                    modal={{
                      header: 'Send News Alert',
                      render: props => (
                        <InstantNewsModal
                          newsType={item.news_type}
                          instantNew={item.id}
                          articleData={articleData}
                          refetch={fetchArticle}
                          {...props}
                        />
                      )
                    }}>
                    View Details
                  </OpenModalButton>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

export default InstantNewsTable;
