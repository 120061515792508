import React from 'react';

import { Field, SelectField } from 'components';

const PRIORITIES = [
  'A',
  'AA',
  'AAA',
  'AAAA',
  'AAAAA',
  'Special',
  'Press Friend',
  'Press Foe',
  'Tried to re-register'
];

class PrioritySelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = PRIORITIES.map(priority => {
      return {
        key: priority,
        value: priority,
        label: priority
      };
    });

    return (
      <Field
        name={name || 'priority'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default PrioritySelect;
