import React from 'react';

import { Field, TransferListField } from 'components';
import { webArticleTopicList } from './sdk';

class WebArticleTopicSelect extends React.Component {
  state = {
    articleTopics: []
  };

  async componentDidMount() {
    const { productSlug } = this.props;
    const { data, success } = await webArticleTopicList(productSlug);

    if (success) {
      this.setState({ articleTopics: data });
    }
  }

  render() {
    const { name, isCustom, ...rest } = this.props;
    const { articleTopics } = this.state;

    const options = articleTopics.map(articleTopic => {
      return {
        key: articleTopic.id,
        value: articleTopic.id,
        label: articleTopic.title
      };
    });

    return (
      <Field
        name={name || 'articleTopic'}
        component={TransferListField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default WebArticleTopicSelect;
