import React from 'react';

import { withRouter } from 'react-router-dom';

import { Header, Segment } from 'semantic-ui-react';

import { Page, LoginRequired } from 'components';
import { notifyErrors } from 'utils/notifications';

import { countryDetail } from './sdk';

import { CountryUpdateForm } from './components';

class CountryDetail extends React.Component {
  state = {
    country: null
  };

  fetchCountry = async () => {
    const { countryId } = this.props.match.params;

    const { data, errors, success } = await countryDetail(countryId);

    if (success) {
      this.setState({ country: data });
    } else {
      notifyErrors(errors);
    }
  };

  componentDidMount() {
    this.fetchCountry();
  }

  render() {
    const { user, crumbs } = this.props;

    const { country } = this.state;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Header as="h1">Edit Country</Header>

            {country && (
              <Segment>
                <CountryUpdateForm
                  country={country}
                  fetchCountry={this.fetchCountry}
                />
              </Segment>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(withRouter(CountryDetail));
