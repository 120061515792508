import React from 'react';

import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { BannerDetail } from 'pages';

import { formatDateTime } from 'utils';

import {
  Admin,
  TableFooter,
  SortableHeader,
  YesNo,
  Image,
  LinkButton
} from 'components';

import { openBannerInNewTab } from './utils';
import styles from './styles.module.css';

const BannerTable = ({ banners, sort, orderBy, pagination }) => (
  <Table celled selectable compact>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell singleLine style={{ fontWeight: '400' }} colspan="10">
          {pagination}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <Table.HeaderCell>Thumbnail</Table.HeaderCell>
        <SortableHeader sort={sort} orderBy={orderBy} field="title">
          Title
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="url">
          URL
        </SortableHeader>
        <Table.HeaderCell>On home page</Table.HeaderCell>
        <SortableHeader sort={sort} orderBy={orderBy} field="impressions">
          Impressions
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="clicks">
          Clicks
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="created_by">
          Created by
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="created_at">
          Created at
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="updated_by">
          Updated by
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="updated_at">
          Updated at
        </SortableHeader>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {banners.map(banner => (
        <Table.Row key={banner.id}>
          <Table.Cell styles={{ width: '150px' }}>
            <Link to={pageUrl(BannerDetail, { bannerId: banner.id })}>
              <Image
                url={banner.image_url}
                width="150"
                height="150"
                alt={banner.id}
              />
            </Link>
          </Table.Cell>
          <Table.Cell width={2}>{banner.title}</Table.Cell>
          <Table.Cell width={3} className={styles.breakWords}>
            <LinkButton onClick={() => openBannerInNewTab(banner.url)}>
              {banner.url}
            </LinkButton>
          </Table.Cell>
          <Table.Cell width={1}>
            <YesNo value={banner.home_page_banner} />
          </Table.Cell>
          <Table.Cell width={1}>{banner.impressions}</Table.Cell>
          <Table.Cell width={1}>{banner.clicks}</Table.Cell>
          <Table.Cell width={2}>
            <Admin value={banner.created_by} />
          </Table.Cell>
          <Table.Cell width={1}>{formatDateTime(banner.created_at)}</Table.Cell>
          <Table.Cell width={2}>
            <Admin value={banner.updated_by} />
          </Table.Cell>
          <Table.Cell width={1}>{formatDateTime(banner.updated_at)}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default BannerTable;
