import _ from 'lodash';
import React from 'react';

import { Field, SelectField, FormRow } from 'components';

import { InvoiceItemPromoCodes } from './sdk';

class PromoCodeSelect extends React.Component {
  state = {
    options: []
  };

  fetchPromoCodes = async () => {
    const { data, success } = await InvoiceItemPromoCodes(this.props.itemId);

    if (success) {
      this.setState({
        options: data.map(promoCode => ({
          key: promoCode.pk,
          pk: promoCode.pk,
          value: promoCode.pk,
          label: promoCode.name,
          discountType: promoCode.discount_type,
          amount: promoCode.amount,
          currency: promoCode.currency.code
        }))
      });
    }
  };

  async componentDidMount() {
    await this.fetchPromoCodes();
  }

  componentDidUpdate(prevProps) {
    var currentItemId = this.props.itemId;

    if (prevProps.itemId !== currentItemId) {
      this.fetchPromoCodes();
    }
  }

  render() {
    const { name, excludeOptions, ...rest } = this.props;
    var options = this.state.options;

    if (!_.isEmpty(excludeOptions)) {
      options = _.filter(
        options,
        option => _.findIndex(excludeOptions, { id: option.itemId }) < 0
      );
    }
    return (
      <FormRow>
        {!options && 'No Promo Codes were found for this Invoice Item'}
        {options && (
          <Field
            name={name || 'code'}
            component={SelectField}
            options={options}
            isClearable
            {...rest}
          />
        )}
      </FormRow>
    );
  }
}

export default PromoCodeSelect;
