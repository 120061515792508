import React from 'react';

import _ from 'lodash';

import { withRouter } from 'react-router-dom';

import { Button, Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  FormRow,
  DateField,
  InputField,
  FileField,
  RichTextAreaField,
  CheckboxField,
  TagSelect,
  MarketDataReportRelatedProductSelect,
  UpdateButton,
  UpdateButtonInRow,
  UnsavedFormPrompt
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';

import {
  prepareCurrencies,
  buildCurrencyKey
} from 'pages/MarketDataReport/utils';

import {
  marketDataReportUpdate,
  currencyList,
  marketDataReportPublish,
  marketDataReportUnpublish
} from './sdk';

import { marketDataReportUpdateSchema, getInitialValues } from './utils';
import { notifySuccess, notifyErrors } from 'utils/notifications';

class MarketDataReportForm extends React.Component {
  state = {
    currencies: []
  };

  async componentDidMount() {
    const { success, data } = await currencyList();

    if (success) {
      this.setState({ currencies: data });
    }
  }

  publishReport = async () => {
    const { marketDataReport, fetchMarketDataReport } = this.props;

    const { success, errors } = await marketDataReportPublish(
      marketDataReport.id
    );

    if (success) {
      notifySuccess('Report published.');
      fetchMarketDataReport();
      return;
    }

    notifyErrors(errors);
  };

  unpublishReport = async () => {
    const { marketDataReport, fetchMarketDataReport } = this.props;

    const { success, errors } = await marketDataReportUnpublish(
      marketDataReport.id
    );

    if (success) {
      notifySuccess('Report unpublished.');
      fetchMarketDataReport();
      return;
    }

    notifyErrors(errors);
  };

  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;
    const { marketDataReport, fetchMarketDataReport } = this.props;

    const prices = prepareCurrencies(values);

    const data = prepareFormData(values);
    data.prices = prices;

    setSubmitting(true);

    const { errors, success } = await marketDataReportUpdate(
      marketDataReport.id,
      data
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Market data report updated.');
      fetchMarketDataReport();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const { marketDataReport } = this.props;

    const { currencies } = this.state;

    const initialValues = getInitialValues(marketDataReport);

    currencies.forEach(currency => {
      const currencyValue = _.find(
        marketDataReport.item.prices,
        price => price.currency.toString() === currency.id.toString()
      );

      initialValues[buildCurrencyKey(currency)] =
        (currencyValue && currencyValue.amount) || 0;
    });

    const isPublished = marketDataReport.is_published;

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={marketDataReportUpdateSchema}
        onSubmit={this.handleSubmit}>
        {({ handleSubmit, isSubmitting, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty} />
            <FormRow flexEnd={true}>
              {!isPublished && (
                <Button color="green" onClick={this.publishReport}>
                  Publish
                </Button>
              )}
              {isPublished && (
                <Button color="yellow" onClick={this.unpublishReport}>
                  Unpublish
                </Button>
              )}
              <UpdateButton
                subject="Market Data Report"
                onClick={handleSubmit}
                disabled={isSubmitting}
              />
            </FormRow>
            <FormRow widths="equal">
              <Field
                required
                name="title"
                component={InputField}
                label="Title"
              />

              <Field required name="slug" component={InputField} label="Slug" />

              <Field
                required
                name="dateActivation"
                component={DateField}
                label="Date Of Activation"
              />
              <Field
                name="isSteelReport"
                component={CheckboxField}
                label="Is Steel Report"
              />
              <Field
                name="isCustomReport"
                component={CheckboxField}
                label="Is Custom Report"
              />
            </FormRow>

            <FormRow widths="equal">
              <Field name="image" component={FileField} label="Image" />
              <Field name="thumb" component={FileField} label="Thumb" />
              <Field name="file" component={FileField} label="File" />
              <Field name="sample" component={FileField} label="Sample" />
            </FormRow>

            <FormRow>
              {currencies.map(currency => (
                <Field
                  key={currency.id}
                  name={buildCurrencyKey(currency)}
                  label={`Price in ${currency.code}`}
                  component={InputField}
                />
              ))}
            </FormRow>

            <Field
              width={16}
              name="relatedProducts"
              label="Related Products"
              component={MarketDataReportRelatedProductSelect}
              isMulti
            />
            <Field width={16} name="tags" label="Tags" component={TagSelect} />

            <FormRow>
              <Field
                name="textHtml"
                component={RichTextAreaField}
                label="Text"
              />
            </FormRow>
            <UpdateButtonInRow
              subject="Market Data Report"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(MarketDataReportForm);
