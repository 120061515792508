import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const emailTemplateRemove = async templateId => {
  const url = `${BASE_URL}/emails/email-templates/${templateId}/delete/`;

  return await requestSdk(() => post(url));
};

export const emailTemplateAddToFavourites = async templateId => {
  const url = `${BASE_URL}/emails/email-templates/${templateId}/add-to-favourites/`;

  return await requestSdk(() => post(url));
};

export const emailTemplateRemoveFromFavourites = async templateId => {
  const url = `${BASE_URL}/emails/email-templates/${templateId}/remove-from-favourites/`;

  return await requestSdk(() => post(url));
};
