import React from 'react';

import { Field, SelectField } from 'components';

const STATUSES = ['Event', 'Subscription', 'Other'];

const EmailTemplateTypeSelect = ({ name, ...rest }) => {
  const options = STATUSES.map(status => {
    return {
      value: status,
      label: status,
      key: status
    };
  });

  return (
    <Field
      name={name || 'emailTemplateType'}
      component={SelectField}
      options={options}
      isClearable
      {...rest}
    />
  );
};

export default EmailTemplateTypeSelect;
