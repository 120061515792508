import React from 'react';
import _ from 'lodash';

import moment from 'moment';

import {
  Modal,
  Icon,
  Header,
  Form,
  Loader,
  Dimmer,
  Button,
  Message,
  MessageHeader
} from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Flex,
  FormRow,
  UpdateButtonInRow,
  FixedDurationsSelect,
  Field,
  CheckboxField,
  Link,
  ChoiceToggle
} from 'components';
import { ClientDetail } from 'pages';
import { pageUrl } from 'config/routes';
import { TRIAL_STATUS_ID, SHORT_TRIAL_STATUS_ID } from 'pages/Client/constants';

import { formatDateForApi } from 'utils';
import { notifySuccess, notifyErrors } from 'utils/notifications';
import { prepareFormData, getToday } from 'utils/forms';
import { pluralize } from 'utils/string';

import { getSendLatestIssuePropsFromProduct } from 'pages/Client/Detail/components/Products/components/LatestIssueModal/utils';

import { clientQuickApprove, clientGetWelcomeEmailForStatus } from './sdk';

/*
Default trial durations for the different types of trials
  - For `Trial` - 1 month
  - For `Short Trial` - 2 weeks
*/
const STATUS_TO_DEFAULT_DURATION = {
  [TRIAL_STATUS_ID]: moment().add(1, 'month'),
  [SHORT_TRIAL_STATUS_ID]: moment().add(2, 'weeks')
};

class QuickApproveModal extends React.Component {
  state = {
    modalOpen: false,
    selectedStatus: TRIAL_STATUS_ID,
    welcomeEmailTemplates: null,
    welcomeEmailsWithMissingData: []
  };

  fetchWelcomeEmailTemplates = async () => {
    const { selectedStatus } = this.state;
    const { client, products } = this.props;
    this.setState({ welcomeEmailsWithMissingData: [] });
    await _.forEach(products, async product => {
      const { data, success, errors } = await clientGetWelcomeEmailForStatus(
        client.id,
        product.item.id,
        selectedStatus
      );

      if (success) {
        this.setState(prevState => ({
          welcomeEmailTemplates: {
            ...prevState.welcomeEmailTemplates,
            [product.item.id]: {
              data: data,
              productTitle: product.item.title
            }
          }
        }));
        if (_.isNull(data) || _.isNull(data.id)) {
          this.setState(prevState => ({
            welcomeEmailsWithMissingData: [
              ...prevState.welcomeEmailsWithMissingData,
              product.item.title
            ]
          }));
        } else {
          this.setState({ welcomeEmailTemplatesWithMissingData: [] });
        }
      } else {
        notifyErrors(errors);
      }
    });
  };
  close = () => {
    this.setState({ modalOpen: false });
    this.props.onClose();
  };
  handleOpen = () => {
    this.setState({ modalOpen: true });
    this.fetchWelcomeEmailTemplates();
    this.props.onOpen();
  };

  handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const formattedDate = getToday();
    const { client, products } = this.props;
    const checkedProducts = products.filter(
      product => values[product.item.title]
    );

    const promises = _.map(checkedProducts, async product => {
      const productDescription = { description: product.item.title };
      const {
        productSlug,
        newsletterTypeSlug
      } = getSendLatestIssuePropsFromProduct(productDescription);

      const data = {
        clientItem: product.id,
        dateStart: formattedDate,
        dateEnd: values.end_date,
        status: this.state.selectedStatus,
        productTypeSlug: productSlug,
        newsletterTypeSlug
      };
      const { errors, success } = await clientQuickApprove(
        client.id,
        prepareFormData(data)
      );

      if (success) {
        notifySuccess(`${productDescription.description} updated.`);
      } else {
        notifyErrors(errors);
      }
    });

    await Promise.all(promises);
    this.props.refetch();
    setSubmitting(false);
  };

  handleStatusChange = value => {
    this.setState({ selectedStatus: value }, this.fetchWelcomeEmailTemplates);
  };

  render() {
    const { products, client } = this.props;
    const {
      selectedStatus,
      welcomeEmailTemplates,
      welcomeEmailsWithMissingData
    } = this.state;
    const isWelcomeEmailFetched = !_.isNull(welcomeEmailTemplates);

    const initialValues = {
      end_date: formatDateForApi(STATUS_TO_DEFAULT_DURATION[selectedStatus]),
      ..._.fromPairs(_.map(products, product => [product.item.title, true]))
    };

    return (
      <Modal
        open={this.state.modalOpen}
        onClose={this.close}
        size="tiny"
        centered={false}
        trigger={
          <Button color="blue" onClick={this.handleOpen}>
            Approve
          </Button>
        }>
        <Modal.Header>
          <Flex spaceBetween style={{ flexDirection: 'row' }}>
            <Flex style={{ gap: '15px' }}>
              <Header as="h2" style={{ margin: 0 }}>
                Quick Approve
              </Header>
              <Link url={pageUrl(ClientDetail, { clientId: client.id })}>
                {client.first_name} {client.last_name}
              </Link>
            </Flex>
            <Icon name="close" onClick={this.close} />
          </Flex>
        </Modal.Header>
        <Modal.Content>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={this.handleSubmit}>
            {({ handleSubmit, isSubmitting, values }) => (
              <>
                <Form>
                  <FormRow>
                    <ChoiceToggle
                      leftOption={{
                        predicate: selectedStatus === TRIAL_STATUS_ID,
                        onClick: () => this.handleStatusChange(TRIAL_STATUS_ID),
                        text: 'Trial'
                      }}
                      rightOption={{
                        predicate: selectedStatus === SHORT_TRIAL_STATUS_ID,
                        onClick: () =>
                          this.handleStatusChange(SHORT_TRIAL_STATUS_ID),
                        text: 'Short Trial'
                      }}
                    />
                  </FormRow>
                  <FormRow>
                    <FixedDurationsSelect
                      name="end_date"
                      label="Fixed Durations"
                    />
                  </FormRow>
                  <FormRow style={{ margin: '20px 0', fontSize: '16px' }}>
                    <label styles={{ fontSize: '16px' }}>
                      Subscriptions in <strong>Pending for Trial</strong> status
                    </label>
                  </FormRow>
                  {products.map(product => (
                    <FormRow>
                      <Field
                        key={product.item.id}
                        name={product.item.title}
                        label={product.item.title}
                        component={CheckboxField}
                      />
                    </FormRow>
                  ))}
                  {isWelcomeEmailFetched &&
                    welcomeEmailsWithMissingData.length > 0 && (
                      <Message style={{ marginTop: 0 }}>
                        <MessageHeader>
                          No welcome email configured for{' '}
                          {welcomeEmailsWithMissingData.join(', ')}{' '}
                          {pluralize(
                            'subscription',
                            welcomeEmailsWithMissingData.length
                          )}
                        </MessageHeader>
                      </Message>
                    )}
                  <Flex flexStart style={{ gap: '40px' }}>
                    <label style={{ fontSize: '13px', fontStyle: 'italic' }}>
                      * On Approve welcome email and latest issue will be sent
                      automatically for selected subscriptions.
                    </label>
                    <UpdateButtonInRow
                      onClick={handleSubmit}
                      label="Approve"
                      disabled={
                        isSubmitting ||
                        _.isNull(selectedStatus) ||
                        _.isNull(values.end_date) ||
                        !_.some(_.values(values), value => value === true)
                      }
                    />
                  </Flex>
                </Form>
                {isSubmitting && (
                  <Dimmer active inverted>
                    <Loader inverted>Submitting</Loader>
                  </Dimmer>
                )}
              </>
            )}
          </Formik>
        </Modal.Content>
      </Modal>
    );
  }
}

export default QuickApproveModal;
