import React from 'react';

import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { Form } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { EmailTemplateList } from 'pages';
import { EMAIL_TEMPLATE_TRIGGER } from 'pages/EmailTemplate/constants';
import { SHORT_TRIAL_STATUS_ID, TRIAL_STATUS_ID } from 'pages/Client/constants';

import {
  Field,
  EmailTriggerSelect,
  EmailTemplateStatusSelect,
  RichTextAreaField,
  InputField,
  TextAreaField,
  FormRow,
  SubscriptionSelect,
  SubscriptionStatusSelect,
  EmailTriggerConditionPeriodSelect,
  EmailTriggerSendWhenSelect,
  ErrorsAndCreateButtonInRow,
  UnsavedFormPrompt,
  EmailTemplateTypeSelect
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { emailTemplateCreateSchema } from './schema';
import { emailTemplateCreate } from './sdk';

import styles from './styles.module.css';

class EmailTemplateCreateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await emailTemplateCreate(data);

    if (success) {
      notifySuccess('Email template has been created.');
      this.props.history.push(pageUrl(EmailTemplateList));
      return;
    }

    if (!success) {
      handleFormErrors(errors, setFieldError);
    }

    setSubmitting(false);
  };

  render() {
    const initialValues = {
      status: 'Draft',
      trigger: EMAIL_TEMPLATE_TRIGGER.MANUAL,
      fromName: '',
      fromEmail: '',
      mobileNotification: '',
      title: '',
      subject: '',
      html: '',
      plainText: '',
      css: '',

      subscription: null,
      days: 1,
      conditionPeriod: 'Before',
      sendWhen: 'Start date',
      subscriptionStatus: null
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={emailTemplateCreateSchema}
        onSubmit={this.handleSubmit}>
        {({ values, handleSubmit, isSubmitting, dirty, errors, touched }) => (
          <Form className={styles.form}>
            <UnsavedFormPrompt when={dirty && !isSubmitting} />
            <FormRow widths="equal">
              <EmailTemplateStatusSelect
                required
                name="status"
                label="Status"
              />

              <EmailTriggerSelect required name="trigger" label="Trigger" />
              <EmailTemplateTypeSelect
                required
                name="templateType"
                label="Type"
              />
            </FormRow>
            {(values.trigger === EMAIL_TEMPLATE_TRIGGER.CUSTOM ||
              values.trigger === EMAIL_TEMPLATE_TRIGGER.WELCOME_EMAIL) && (
              <FormRow widths="equal">
                <Field
                  label="Subscription"
                  name="subscription"
                  component={SubscriptionSelect}
                />
                {values.trigger === EMAIL_TEMPLATE_TRIGGER.CUSTOM && (
                  <>
                    <Field label="Days" name="days" component={InputField} />
                    <Field
                      label="Condition Period"
                      name="conditionPeriod"
                      component={EmailTriggerConditionPeriodSelect}
                    />
                    <Field
                      label="Send when"
                      name="sendWhen"
                      component={EmailTriggerSendWhenSelect}
                    />
                  </>
                )}
                <Field
                  label="Condition"
                  name="condition"
                  component={SubscriptionStatusSelect}
                  {...(values.trigger ===
                    EMAIL_TEMPLATE_TRIGGER.WELCOME_EMAIL && {
                    only: [SHORT_TRIAL_STATUS_ID, TRIAL_STATUS_ID]
                  })}
                />
              </FormRow>
            )}
            <FormRow widths="equal">
              <Field
                required
                component={InputField}
                name="fromName"
                label="From name"
              />

              <Field
                required
                component={InputField}
                type="email"
                name="fromEmail"
                label="From email"
              />
            </FormRow>
            <FormRow widths="equal">
              <Field
                required
                component={InputField}
                name="title"
                label="Title"
              />
            </FormRow>
            <FormRow widths="equal">
              <Field
                required
                component={InputField}
                name="subject"
                label="Subject"
              />
            </FormRow>
            <FormRow widths="equal">
              <Field
                component={InputField}
                name="mobileNotification"
                label="Mobile notification"
              />
            </FormRow>

            <FormRow widths="equal">
              <Field
                required
                component={RichTextAreaField}
                name="html"
                label="HTML"
              />
            </FormRow>
            <FormRow widths="equal">
              <Field
                required
                component={TextAreaField}
                name="plainText"
                label="Plain text"
              />
            </FormRow>
            <FormRow widths="equal">
              <Field component={TextAreaField} name="css" label="CSS" />
            </FormRow>
            <ErrorsAndCreateButtonInRow
              errors={errors}
              touched={touched}
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(EmailTemplateCreateForm);
