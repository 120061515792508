import React from 'react';
import _ from 'lodash';
import { Field, SelectField } from 'components';

const MONTH_OPTIONS = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 }
];

class MonthSelect extends React.Component {
  render() {
    const {
      name,
      excludeOptions = null,
      isClearable = true,
      ...rest
    } = this.props;

    let monthOptions = MONTH_OPTIONS;

    if (excludeOptions) {
      monthOptions = monthOptions.filter(
        option => _.indexOf(excludeOptions, option.value) < 0
      );
    }

    const options = monthOptions.map(item => {
      return {
        key: item.value,
        value: item.value,
        label: item.label
      };
    });

    return (
      <Field
        name={name || 'discountType'}
        component={SelectField}
        options={options}
        isClearable={isClearable}
        {...rest}
      />
    );
  }
}

export default MonthSelect;
