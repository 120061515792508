import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';
import { POWER_MATERIAL_SLUG } from 'domainConstants';

export const priceIndexesByCategoryList = async (categoryId, productSlug) => {
  let categoryParam = { category: categoryId };
  if (productSlug === POWER_MATERIAL_SLUG) {
    categoryParam = { main_group: categoryId };
  }

  const params = {
    paginate: false,
    product_type: productSlug,
    order: 'by_category',
    ...categoryParam
  };

  const url = `${BASE_URL}/prices/indexes/`;
  return await requestSdk(() => get(url, params));
};

export const orderIndexesForCategory = async data => {
  const url = `${BASE_URL}/prices/indexes/set-order/`;

  return await requestSdk(() => post(url, data));
};
