import _ from 'lodash';
import React from 'react';

import { pageUrl } from 'config/routes';

import { NewsletterCreate } from 'pages';

import {
  Page,
  Header,
  ListViewEssentials,
  Pagination,
  Button,
  Flex
} from 'components';
import { getProductName } from 'utils';

import { VisibleIf, PERMISSIONS } from 'permissions';
import { withLoggedUser, withPermissions } from 'hooks';

import { newsletterList } from './sdk';

import { NewsletterTable } from './components';

import { useParams } from 'react-router-dom';

function NewsletterList({
  user,
  data,
  limit,
  count,
  page,
  changePage,
  changeRowsPerPage,
  sort,
  orderBy,
  crumbs,
  history
}) {
  const { productSlug } = useParams();
  const createNewsletter = () => {
    const location = pageUrl(NewsletterCreate, { productSlug });

    history.push(location);
  };
  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content crumbs={crumbs}>
          <Flex spaceBetween>
            <Header>Daily {getProductName(productSlug)} Newsletters</Header>
            <VisibleIf
              user={user}
              permissions={[PERMISSIONS.CREATE_NEWSLETTERS]}>
              <Button size="large" onClick={createNewsletter}>
                Create newsletter
              </Button>
            </VisibleIf>
          </Flex>
          {data && (
            <NewsletterTable
              sort={sort}
              orderBy={orderBy}
              dailyNewsletters={data}
              pagination={
                <Pagination
                  limit={limit}
                  count={count}
                  page={page}
                  changePage={changePage}
                  changeRowsPerPage={changeRowsPerPage}
                />
              }
            />
          )}
        </Page.Content>
      </Page.Body>
    </Page>
  );
}

export default ListViewEssentials({
  sdk: props => {
    const { productSlug } = props.match.params;
    return _.partial(newsletterList, productSlug);
  },
  passPropsToSdk: true
})(
  withLoggedUser(
    withPermissions([PERMISSIONS.VIEW_NEWSLETTERS])(NewsletterList)
  )
);
