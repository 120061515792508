import React from 'react';
import {
  adminList,
  subscriptionStatusList,
  subscriptionList,
  currencyList
} from 'sdk/shared';

class FetchInitials extends React.Component {
  async componentDidMount() {
    await adminList();
    await subscriptionStatusList();
    await subscriptionList();
    await currencyList();
  }

  render() {
    return null;
  }
}

export default FetchInitials;
