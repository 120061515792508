import React from 'react';
import { Button } from 'semantic-ui-react';

import _ from 'lodash';

import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { DeleteModal } from 'components';
import styles from './styles.module.css';

const SortableItem = sortableElement(
  ({
    value,
    handleEdit,
    handleDelete,
    itemId,
    deleteSdk,
    sdkExtraArgs,
    deleteButtonText,
    pressDelay,
    widthClass,
    ...rest
  }) => (
    <div className={styles.SortableItem}>
      <div className={`${styles.SortableValue} ${styles[widthClass]}`}>
        {value ? value : '-'}
      </div>
      {_.isFunction(deleteSdk) ? (
        <DeleteModal
          floated="right"
          itemId={itemId}
          sdk={deleteSdk}
          sdkExtraArgs={sdkExtraArgs}
          size="mini"
          inverted={true}
          objDetails={`${value} with ID: ${itemId}`}
          onClose={handleDelete}
          deleteButtonText={deleteButtonText}
          {...rest}
        />
      ) : null}
      {_.isFunction(handleEdit) ? (
        <Button
          key={`button-edit-${itemId}`}
          color="blue"
          inverted
          floated="right"
          size="mini"
          {...rest}
          onClick={() => handleEdit(itemId)}>
          Edit
        </Button>
      ) : null}
    </div>
  )
);

const NonSortableItem = ({
  value,
  handleEdit,
  handleDelete,
  itemId,
  deleteSdk,
  sdkExtraArgs,
  deleteButtonText,
  widthClass,
  ...rest
}) => (
  <div className={styles.NonSortableItem}>
    <div className={`${styles.SortableValue} ${styles[widthClass]}`}>
      {value ? value : '-'}
    </div>
    {_.isFunction(deleteSdk) ? (
      <DeleteModal
        floated="right"
        itemId={itemId}
        sdk={deleteSdk}
        sdkExtraArgs={sdkExtraArgs}
        size="mini"
        inverted={true}
        objDetails={`${value} with ID: ${itemId}`}
        onClose={handleDelete}
        deleteButtonText={deleteButtonText}
        {...rest}
      />
    ) : null}
    {_.isFunction(handleEdit) ? (
      <Button
        key={`button-edit-${itemId}`}
        color="blue"
        inverted
        floated="right"
        size="mini"
        {...rest}
        onClick={() => handleEdit(itemId)}>
        Edit
      </Button>
    ) : null}
  </div>
);

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

class SortableList extends React.Component {
  state = {
    items: []
  };

  componentDidMount() {
    const { items } = this.props;
    this.setState({ items });
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.items, this.props.items)) {
      this.setState({ items: this.props.items });
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const item = this.state.items[oldIndex];

    if (oldIndex === newIndex) {
      return;
    }

    const { onSortEnd } = this.props;
    // const item = this.state.items[oldIndex];

    this.setState(
      ({ items }) => ({
        items: arrayMoveImmutable(items, oldIndex, newIndex)
      }),
      () => onSortEnd({ item, oldIndex, newIndex, items: this.state.items })
    );
  };

  render() {
    const { items } = this.state;

    const {
      disabled,
      handleEdit,
      handleDelete,
      deleteSdk,
      sdkExtraArgs,
      deleteButtonText,
      onSortEnd,
      ...rest
    } = this.props;

    let Item = SortableItem;

    if (disabled) {
      Item = NonSortableItem;
    }

    let valueWidthClass = 'width100';
    if (_.isFunction(deleteSdk) && _.isFunction(handleEdit)) {
      valueWidthClass = 'width65';
    } else if (_.isFunction(deleteSdk) || _.isFunction(handleEdit)) {
      valueWidthClass = 'width80';
    }

    return (
      <SortableContainer
        onSortEnd={this.onSortEnd}
        useWindowAsScrollContainer={true}>
        {items.map((item, index) => (
          <Item
            key={`item-${item.title}-${index}`}
            index={index}
            value={item.title}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            deleteSdk={deleteSdk}
            sdkExtraArgs={sdkExtraArgs}
            deleteButtonText={deleteButtonText}
            itemId={item.id}
            distance={10}
            pressDelay={200}
            widthClass={valueWidthClass}
            {...rest}
          />
        ))}
      </SortableContainer>
    );
  }
}

export default SortableList;
