import React from 'react';
import _ from 'lodash';
import { Table, TableCell } from 'semantic-ui-react';

import { Formik } from 'formik';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import {
  Button,
  Field,
  CheckboxField,
  PriceSeriesSingleSelect
} from 'components';

import { permamentPriceSchema } from './utils';

class CreatePriceForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError, resetForm } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { success, errors } = await this.props.permanentPriceCreateSdk(data);

    setSubmitting(false);

    if (success) {
      notifySuccess('Choice created.');
      this.props.fetchPermanentPrices();
      resetForm();
      return;
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  render() {
    const initialValues = {
      price: null,
      displayed: true
    };

    const priceSeriesIds = _.map(this.props.prices, price => price.price.id);

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        validationSchema={permamentPriceSchema}>
        {({ isSubmitting, handleSubmit }) => (
          <Table.Row>
            <Table.Cell width={1} />
            <Table.Cell>
              <PriceSeriesSingleSelect
                excludeOptions={priceSeriesIds}
                name="price"
                isPowerMaterial={this.props.isPowerMaterial}
              />
            </Table.Cell>
            <TableCell width={2} textAlign="center">
              <Field name="displayed" component={CheckboxField} />
            </TableCell>
            <Table.Cell width={3} textAlign="center">
              <Button
                color="green"
                disabled={isSubmitting}
                onClick={handleSubmit}>
                Create
              </Button>
            </Table.Cell>
          </Table.Row>
        )}
      </Formik>
    );
  }
}

export default CreatePriceForm;
