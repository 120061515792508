import React from 'react';

import { withRouter } from 'react-router-dom';

import { Button, Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import { Field, FormRow, InputField } from 'components';
import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { sendTestSchema } from './schema';
import { sendTestEmail } from './sdk';

class SendTestEmail extends React.Component {
  handleSubmit = async (values, actions) => {
    const { instantNew } = this.props;

    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await sendTestEmail(instantNew, data);

    setSubmitting(false);

    if (success) {
      notifySuccess('Test email sent.');
      return;
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  render() {
    const initialValues = {
      client: ''
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={sendTestSchema}
        onSubmit={this.handleSubmit}>
        {({ values, handleSubmit, isSubmitting }) => (
          <Form>
            <FormRow alignEnd style={{ marginBottom: 0 }}>
              <Field
                width={12}
                required
                component={InputField}
                name="client"
                placeholder="Email"
                label="Send test email to:"
              />
              <Form.Field width={4}>
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  color="green"
                  disabled={isSubmitting || !this.props.instantNew}>
                  Send
                </Button>
              </Form.Field>
            </FormRow>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(SendTestEmail);
