import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { FaqDetail } from 'pages';

import { YesNo, TableFooter, SortableHeader, DeleteModal } from 'components';

import { Table } from 'semantic-ui-react';

import { formatDateTime } from 'utils';

import { faqDelete } from './sdk';

class FaqTable extends React.Component {
  render() {
    const { faqs, pagination, sort, orderBy, refetch } = this.props;

    return (
      <Table celled selectable compact={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              singleLine
              style={{ fontWeight: '400' }}
              colspan="8">
              {pagination}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <SortableHeader sort={sort} orderBy={orderBy} field="question">
              Question
            </SortableHeader>
            <Table.HeaderCell>FAQ Category</Table.HeaderCell>
            <Table.HeaderCell>Is published</Table.HeaderCell>
            <SortableHeader sort={sort} orderBy={orderBy} field="created_by">
              Created by
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="created_at">
              Create time
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="updated_by">
              Updated by
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="updated_at">
              Updated time
            </SortableHeader>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {faqs.map(faq => (
            <Table.Row key={faq.id}>
              <Table.Cell width={5}>
                <Link to={pageUrl(FaqDetail, { faqId: faq.id })}>
                  {faq.question}
                </Link>
              </Table.Cell>
              <Table.Cell>{_.get(faq, 'category.title', '')}</Table.Cell>
              <Table.Cell>
                <YesNo value={faq.is_published} />
              </Table.Cell>
              <Table.Cell>
                {_.get(faq, 'created_by.first_name')}{' '}
                {_.get(faq, 'created_by.last_name')}
              </Table.Cell>
              <Table.Cell>{formatDateTime(faq.created_at)}</Table.Cell>
              <Table.Cell>
                {_.get(faq, 'updated_by.first_name')}{' '}
                {_.get(faq, 'updated_by.last_name')}
              </Table.Cell>
              <Table.Cell>{formatDateTime(faq.updated_at)}</Table.Cell>
              <Table.Cell>
                <DeleteModal
                  itemId={faq.id}
                  objName="question"
                  sdk={faqDelete}
                  size="mini"
                  inverted={true}
                  objDetails={`${faq.question} with ID: ${faq.id}`}
                  onClose={refetch}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <TableFooter>{pagination}</TableFooter>
      </Table>
    );
  }
}

export default FaqTable;
