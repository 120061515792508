import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const currencyList = async () => {
  const url = `${BASE_URL}/common/currencies/`;

  return await requestSdk(() => get(url));
};

export const marketDataReportUpdate = async (marketDataReportId, data) => {
  const url = `${BASE_URL}/reports/market-data/${marketDataReportId}/update/`;

  return await requestSdk(() => post(url, data));
};

export const marketDataReportPublish = async marketDataReportId => {
  const url = `${BASE_URL}/reports/market-data/${marketDataReportId}/publish/`;

  return await requestSdk(() => post(url));
};

export const marketDataReportUnpublish = async marketDataReportId => {
  const url = `${BASE_URL}/reports/market-data/${marketDataReportId}/unpublish/`;

  return await requestSdk(() => post(url));
};
