import React from 'react';

import _ from 'lodash';
import { Link, useParams } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { ClientDetail, PriceSerieDetail } from 'pages';

import { TableFooter, SortableHeader } from 'components';
import { formatDate } from 'utils';

function PriceSeriesTable({ data, sort, orderBy, pagination }) {
  const { productSlug } = useParams();

  return (
    <Table celled selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            singleLine
            style={{ fontWeight: '400' }}
            colSpan="12">
            {pagination}
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <SortableHeader sort={sort} orderBy={orderBy} field="category">
            Category
          </SortableHeader>
          <Table.HeaderCell>Display as</Table.HeaderCell>
          <SortableHeader sort={sort} orderBy={orderBy} field="product">
            Product
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="region">
            Region
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="inco_terms">
            INCO Terms
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="currency">
            Currency
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="unit">
            Unit
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="frequency">
            Frequency
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="updated_by">
            Updated by
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="updated_at">
            Updated at
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="principal_owner">
            Principal owner
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="status">
            Status
          </SortableHeader>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {data.map(priceSerie => (
          <Table.Row key={priceSerie.id}>
            <Table.Cell>
              <Link
                to={pageUrl(PriceSerieDetail, {
                  productSlug: productSlug,
                  priceSeriesId: priceSerie.id
                })}>
                {priceSerie.group}
              </Link>
            </Table.Cell>
            <Table.Cell width={3}>{priceSerie.display_as}</Table.Cell>
            <Table.Cell>{priceSerie.product}</Table.Cell>
            <Table.Cell>
              {_.join(
                _.map(priceSerie.regions, region => region.title),
                ', '
              )}
            </Table.Cell>
            <Table.Cell>{priceSerie.inco_terms}</Table.Cell>
            <Table.Cell>{priceSerie.currency}</Table.Cell>
            <Table.Cell>{priceSerie.unit}</Table.Cell>
            <Table.Cell>{priceSerie.frequency}</Table.Cell>
            <Table.Cell>{priceSerie.updated_by}</Table.Cell>
            <Table.Cell>{formatDate(priceSerie.updated_at)}</Table.Cell>
            <Table.Cell>
              {priceSerie.principal_owner ? (
                <Link
                  to={pageUrl(ClientDetail, {
                    clientId: priceSerie.principal_owner.id
                  })}>
                  {priceSerie.principal_owner.first_name}{' '}
                  {priceSerie.principal_owner.last_name}
                </Link>
              ) : (
                'N/A'
              )}
            </Table.Cell>
            <Table.Cell>{priceSerie.status}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <TableFooter>{pagination}</TableFooter>
    </Table>
  );
}

export default PriceSeriesTable;
