import React from 'react';

import { withRouter } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { FaqCategoryCreate } from 'pages';

import {
  Page,
  Flex,
  Header,
  LoginRequired,
  ListViewEssentials,
  Button
} from 'components';

import { faqCategoryList } from './sdk';

import { FaqCategoriesTable } from './components';

const DEFAULT_LIMIT = 20;

class FaqCategoryList extends React.Component {
  onCategoryCreateClick = () => {
    const url = pageUrl(FaqCategoryCreate);
    this.props.history.push(url);
  };

  render() {
    const { user, data, sort, orderBy, crumbs, refetch } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Flex spaceBetween>
              <Header>FAQ Categories</Header>
              <Button onClick={this.onCategoryCreateClick}>Create</Button>
            </Flex>
            {data && (
              <FaqCategoriesTable
                categories={data}
                sort={sort}
                orderBy={orderBy}
                refetch={refetch}
              />
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: faqCategoryList,
  limit: DEFAULT_LIMIT
})(LoginRequired(withRouter(FaqCategoryList)));
