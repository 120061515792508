import React from 'react';
import _ from 'lodash';

import { Field, FormRow } from 'components';

const FilterRow = ({ row, formikBag }) => (
  <FormRow>
    {row.map(field => {
      let fieldProps = {
        name: field.name,
        label: field.label,
        placeholder: field.placeholder,
        width: field.width,
        type: field.type,
        'data-testid': field.testId
      };

      fieldProps = _.pickBy(fieldProps, (value, key) => value !== undefined);

      if (field.props) {
        let props = field.props(formikBag);
        fieldProps = { ...fieldProps, ...props };
      }

      if (field.component) {
        return <Field component={field.component} {...fieldProps} />;
      }

      if (field.fieldComponent) {
        const Component = field.fieldComponent;

        return <Component {...fieldProps} />;
      }
      return null;
    })}
  </FormRow>
);

export default FilterRow;
