import * as yup from 'yup';

export const clientReminderCreateSchema = yup.object().shape({
  sentDate: yup
    .date()
    .nullable()
    .required('Date is required.'),
  time: yup
    .number()
    .nullable()
    .required('Time is required.'),
  description: yup
    .string()
    .nullable()
    .required('Description is required.')
});
