import React from 'react';

import _ from 'lodash';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import { FormRow, CreateButton, MonthSelect, YearSelect } from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { snapshotCreate } from './sdk';

import { getExcludeMonths, snapshotCreateSchema } from './utils';

class SnapshotCreateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError, resetForm } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await snapshotCreate(data);

    setSubmitting(false);

    if (success) {
      notifySuccess(`Snapshot created successfully`);
      this.props.fetchClients();
      resetForm();
      return;
    }
    handleFormErrors(errors, setFieldError);
  };

  render() {
    const { excludeMonthsYears } = this.props;

    const currentYear = new Date().getFullYear();

    const initialValues = {
      year: currentYear,
      month: ''
    };

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        validationSchema={snapshotCreateSchema}>
        {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
          <Form style={{ width: '100%' }}>
            <FormRow alignEnd>
              <YearSelect
                width={3}
                name="year"
                label="Year"
                isClearable={false}
                customOptions={_.range(currentYear, currentYear + 10)}
              />
              <MonthSelect
                width={3}
                name="month"
                label="Month"
                excludeOptions={getExcludeMonths({
                  year: values.year,
                  data: excludeMonthsYears
                })}
              />
              <Form.Field width={3}>
                <CreateButton onClick={handleSubmit} disabled={isSubmitting}>
                  Create Monthly Snapshot
                </CreateButton>
              </Form.Field>
            </FormRow>
          </Form>
        )}
      </Formik>
    );
  }
}

export default SnapshotCreateForm;
