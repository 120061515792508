import { Flex } from 'components';
import React from 'react';

import {
  Pagination as SemanticPagination,
  PaginationItem as SemanticPaginationItem
} from 'semantic-ui-react';

import { getTotalPagesCount } from 'utils/pagination';

import { GoToPageInputField, RowsPerPageSelect } from './components';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from './constants';

class Pagination extends React.Component {
  changePage = pageNumber => {
    this.props.changePage(pageNumber);
  };

  changeRowsPerPage = rowsPerPage => {
    this.props.changeRowsPerPage(rowsPerPage);
  };

  render() {
    const {
      count,
      limit,
      page,
      rowsPerPageEnabled = true,
      rowsPerPageOptions = DEFAULT_ROWS_PER_PAGE_OPTIONS
    } = this.props;

    const totalPages = getTotalPagesCount(count, limit);

    return (
      <Flex flexEnd style={{ gap: '1rem' }}>
        <GoToPageInputField
          goToPageHandler={this.changePage}
          totalPages={totalPages}
        />

        {rowsPerPageEnabled && (
          <RowsPerPageSelect
            value={limit}
            options={rowsPerPageOptions}
            onChange={(e, data) => this.changeRowsPerPage(data.value)}
          />
        )}

        <SemanticPagination
          onPageChange={(e, data) => this.changePage(data.activePage)}
          boundaryRange={1}
          activePage={parseInt(page)}
          siblingRange={1}
          totalPages={totalPages}
          // Remove << and >>
          firstItem={null}
          lastItem={null}
        />
      </Flex>
    );
  }
}

export const CursorPagination = ({
  nextCursor,
  previousCursor,
  pageSize,
  changePage,
  changeRowsPerPage,
  rowsPerPageEnabled = true,
  rowsPerPageOptions = DEFAULT_ROWS_PER_PAGE_OPTIONS
}) => {
  return (
    <Flex flexEnd style={{ gap: '1rem' }}>
      {rowsPerPageEnabled && (
        <RowsPerPageSelect
          value={pageSize}
          options={rowsPerPageOptions}
          onChange={(e, data) => changeRowsPerPage(data.value)}
        />
      )}

      <SemanticPagination
        boundaryRange={0}
        siblingRange={0}
        totalPages={0}
        prevItem={null}
        nextItem={null}
        firstItem={itemProps => (
          <SemanticPaginationItem
            disabled={!previousCursor}
            onClick={() => changePage(previousCursor)}>
            «
          </SemanticPaginationItem>
        )}
        lastItem={itemProps => (
          <SemanticPaginationItem
            disabled={!nextCursor}
            onClick={() => changePage(nextCursor)}>
            »
          </SemanticPaginationItem>
        )}
      />
    </Flex>
  );
};

export default Pagination;
