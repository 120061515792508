import React from 'react';

import { Icon } from 'semantic-ui-react';
import { BASE_URL } from 'sdk/urls';

const buildPreviewUrl = value => {
  const url = `${BASE_URL}/common/files/${value}/preview/`;
  return url;
};

const FilePreview = ({ fileId }) => {
  return (
    <a target="_blank" href={buildPreviewUrl(fileId)} rel="noopener noreferrer">
      <Icon name="file pdf" />
      Preview
    </a>
  );
};

export default FilePreview;
