import React from 'react';

import _ from 'lodash';

const withEventType = ({ eventType = null }) => {
  return WrappedComponent => {
    class HigherOrderComponent extends React.Component {
      render() {
        return (
          !_.isNil(eventType) &&
          (<WrappedComponent eventType={eventType} {...this.props} /> || null)
        );
      }
    }

    return HigherOrderComponent;
  };
};

export default withEventType;
