import React from 'react';

import Routes from 'config/routes';

import { ToastContainer } from 'react-toastify';

import FetchInitials from 'components/FetchInitials';

const AUTO_CLOSE_DURATION_IN_MS =
  process.env.REACT_APP_TOAST_AUTO_CLOSE_DURATION_IN_MS || 3000;

class App extends React.Component {
  render() {
    return (
      <>
        <FetchInitials />
        <Routes />
        <ToastContainer
          position="top-right"
          autoClose={AUTO_CLOSE_DURATION_IN_MS}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover={false}
          theme="colored"
        />
      </>
    );
  }
}

export default App;
