import _ from 'lodash';

import React from 'react';

import { Button, Header, Modal } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { pageUrl } from 'config/routes';
import { notifySuccess, notifyError } from 'utils/notifications';

/*
 * Documentation:
 * https://github.com/kallanish/kallanish-frontend/wiki/DeleteModal
 * https://whimsical.com/deletemodal-Mow7fn9oqf2vENC14ASn32
 */

class DeleteModal extends React.Component {
  state = { modalOpen: false };

  handleOpen = () => {
    this.setState({ modalOpen: true });
    if (this.props.onOpen) {
      this.props.onOpen();
    }
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  handleSuccess = () => {
    this.setState({ modalOpen: false });
    if (this.props.onSuccess) {
      this.props.onSuccess();
    }
  };

  handleSubmit = async () => {
    const { itemId, sdk, objName, successRedirect, sdkExtraArgs } = this.props;
    const { errors, success } = await sdk({ ...sdkExtraArgs, itemId });

    if (success) {
      notifySuccess(`${objName} deleted.`);
      this.handleSuccess();
      if (successRedirect) {
        this.props.history.push(pageUrl(successRedirect));
      }
    } else {
      notifyError(errors);
    }
    this.handleClose();
  };

  render() {
    const {
      objName,
      objDetails,
      size,
      inverted,
      deleteButtonText,
      floated,
      open,
      displayButton
    } = this.props;

    return (
      <Modal
        trigger={
          displayButton ? (
            <Button
              color="red"
              onClick={this.handleOpen}
              size={size}
              floated={floated}
              inverted={inverted}>
              {deleteButtonText}
            </Button>
          ) : null
        }
        // External handler can open the modal
        open={_.isNil(open) ? this.state.modalOpen : open}
        onClose={this.handleClose}
        basic
        size="small">
        <Header icon="archive" content={`Delete ${objName}`} />
        <Modal.Content>
          <p>Are you sure you want to delete {objName.toLowerCase()} ?</p>
          <p>{objDetails}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color="red" inverted onClick={this.handleClose}>
            No
          </Button>
          <Button color="green" inverted onClick={this.handleSubmit}>
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

DeleteModal.defaultProps = {
  size: 'medium',
  objName: 'Item',
  objDetails: '',
  displayButton: true,
  deleteButtonText: 'Delete'
};

export default withRouter(DeleteModal);
