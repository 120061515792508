import React from 'react';

import { Table, Segment } from 'semantic-ui-react';

import { TableFooter, Loader, YesNo } from 'components';

import { formatDateTime } from 'utils';

class EmailTable extends React.Component {
  render() {
    const { suppressions, pagination, isFetching } = this.props;

    return (
      <>
        <Table celled selectable compact={true}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                singleLine
                style={{ fontWeight: '400' }}
                colspan="4">
                {pagination}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Reason</Table.HeaderCell>
              <Table.HeaderCell>Occurrence</Table.HeaderCell>
              <Table.HeaderCell>New</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          {!isFetching && (
            <>
              <Table.Body>
                {suppressions.map(suppression => (
                  <Table.Row key={suppression.id}>
                    <Table.Cell>{suppression.email}</Table.Cell>
                    <Table.Cell>{suppression.reason}</Table.Cell>
                    <Table.Cell>
                      {formatDateTime(suppression.last_aws_suppression_update)}
                    </Table.Cell>
                    <Table.Cell>
                      <YesNo value={suppression.is_new} />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              <TableFooter>{pagination}</TableFooter>
            </>
          )}
        </Table>
        {isFetching && (
          <Segment>
            <Loader />
          </Segment>
        )}
      </>
    );
  }
}

export default EmailTable;
