import _ from 'lodash';
import cx from 'classnames';
import React from 'react';

import { Form } from 'semantic-ui-react';

import styles from './styles.module.css';

const currentYear = new Date().getFullYear();

class YearMonthForm extends React.Component {
  handleChange = e => {
    const { year, month } = e.target.form;
    this.props.onChange(new Date(year.value, month.value));
  };

  render() {
    const { date, localeUtils } = this.props;

    const months = localeUtils.getMonths();

    const years = _.range(currentYear - 10, currentYear + 20);

    return (
      <div className={cx('DayPicker-Caption', styles.DayPickerCaption)}>
        <Form className={cx(styles.dayPickerCaptionInner)}>
          <select
            className={styles.dayPickerMonth}
            name="month"
            onChange={this.handleChange}
            value={date.getMonth()}>
            {months.map((month, i) => (
              <option key={month} value={i}>
                {month}
              </option>
            ))}
          </select>
          <select
            className={styles.dayPickerYear}
            name="year"
            onChange={this.handleChange}
            value={date.getFullYear()}>
            {years.map(year => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </Form>
      </div>
    );
  }
}

export default YearMonthForm;
