import React from 'react';

const withEditionDetails = ({ editionDetails }) => {
  return WrappedComponent => {
    class HigherOrderComponent extends React.Component {
      render() {
        return (
          <WrappedComponent editionDetails={editionDetails} {...this.props} />
        );
      }
    }

    return HigherOrderComponent;
  };
};

export default withEditionDetails;
