import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const clientDeleteReminder = async (clientId, data) => {
  const url = `${BASE_URL}/crm/clients/${clientId}/reminder/delete/`;

  return await requestSdk(() => post(url, data));
};

export const clientMarkReminderAsDone = async (clientId, data) => {
  const url = `${BASE_URL}/crm/clients/${clientId}/reminder/mark-as-done/`;

  return await requestSdk(() => post(url, data));
};
