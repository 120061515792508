import _ from 'lodash';

import moment from 'moment';

import { v4 as uuidv4 } from 'uuid';

import * as yup from 'yup';

export const areAllItemsSelected = (allItems, selectedItems) =>
  _.isEqual(_.map(allItems, 'id'), selectedItems);

const convertToInitialValues = items =>
  _.map(items, item => ({
    id: item.id,
    pipelineEngagement: item.pipeline_engagement,
    forecastedDate: item.forecasted_date,
    forecastedValueUsd: item.forecasted_value_usd,
    forecastedValueGbp: item.forecasted_value_gbp,
    forecastedValueEur: item.forecasted_value_eur,
    notes: item.notes || '',
    inDashboard: item.in_dashboard,
    title: item.client_item_title,
    clientItem: item.client_item,
    certainty: item.certainty,
    key: uuidv4()
  }));

export const preparePipelineItems = (products, data) => {
  const pipelineItemsClientItemIds = _.map(data, 'client_item');
  const productsWithoutPipelineItem = _.filter(
    products,
    product => _.indexOf(pipelineItemsClientItemIds, product.id) < 0
  );

  const productsToPipelineItems = _.map(
    productsWithoutPipelineItem,
    product => ({
      in_dashboard: false,
      client_item_title: product.description,
      pipeline_engagement: 'No Status',
      client_item: product.id,
      forecasted_value_eur: null,
      forecasted_value_gbp: null,
      forecasted_value_usd: null,
      certainty: null
    })
  );

  return convertToInitialValues([...data, ...productsToPipelineItems]);
};

export const prepareParams = data => {
  const pipelineItem = _.first(data);

  return {
    forecastedMonth: parseInt(moment(pipelineItem.forecasted_date).format('M')),
    forecastedYear: parseInt(
      moment(pipelineItem.forecasted_date).format('YYYY')
    ),
    defaultOnEmpty: true
  };
};

export const pipelineItemsSchema = yup.object().shape({
  pipelineItems: yup.array().of(
    yup.object().shape({
      forecastedDate: yup
        .string()
        .nullable()
        .when('inDashboard', {
          is: true,
          then: schema => schema.required('Required.')
        }),
      pipelineEngagement: yup
        .string()
        .nullable()
        .required('Pipeline Engagement is required.')
    })
  )
});
