import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const priceIndexChart = async (id, params) => {
  const url = `${BASE_URL}/prices/indexes/${id}/values/`;
  return await requestSdk(() => get(url, params));
};

export const priceIndexDetail = async id => {
  const url = `${BASE_URL}/prices/indexes/${id}/`;
  return await requestSdk(() => get(url));
};

export const priceSeries = async productSlug => {
  const params = {
    paginate: false,
    product_type: productSlug
  };

  const url = `${BASE_URL}/prices/series/v2/`;
  return await requestSdk(() => get(url, params));
};

export const downloadSpreadsheet = (id, start_date, end_date) =>
  `${BASE_URL}/prices/indexes/${id}/spreadsheet/?start_date=${start_date}&end_date=${end_date}`;

export const priceLatestValue = async data => {
  const url = `${BASE_URL}/prices/indexes/prices/latest-values/`;
  return await requestSdk(() => get(url, data));
};
