import React from 'react';
import { withRouter } from 'react-router-dom';

import _ from 'lodash';

import { Menu } from 'semantic-ui-react';

import { Logo } from 'components';
import { VisibleIf } from 'permissions';

import { getLink } from 'components/Navigation/utils';

import Profile from './Profile';
import Dropdown from './Dropdown';

import styles from './styles.module.scss';

class DesktopNavigation extends React.Component {
  changeLocationTo = location => {
    this.props.history.push(location);
  };

  shouldDisplayItem = (user, menuItem) => {
    if (menuItem.superUserRequired && !_.get(user, 'is_superuser', false)) {
      return false;
    }

    return true;
  };

  render() {
    const { user = null, navigationItems } = this.props;

    const menuItems = navigationItems;

    return (
      <div className={styles.NavigationWrapper}>
        <div className={styles.container}>
          <div className={styles.leftNavItems}>
            <Logo className={styles.logo} white />
            <Menu className={styles.Menu}>
              {menuItems.map(
                (menuItem, index) =>
                  this.shouldDisplayItem(user, menuItem) && (
                    <React.Fragment key={index}>
                      {menuItem.items && (
                        <Dropdown
                          title={menuItem.title}
                          menuItem={menuItem}
                          user={user}
                        />
                      )}
                      {menuItem.link && (
                        <VisibleIf
                          user={user}
                          permissions={
                            menuItem.permissions ? menuItem.permissions : []
                          }>
                          <Menu.Item
                            className="link item"
                            onClick={() =>
                              this.changeLocationTo(getLink(menuItem.link))
                            }
                            style={{ height: '56px' }}>
                            {menuItem.title}
                          </Menu.Item>
                        </VisibleIf>
                      )}
                    </React.Fragment>
                  )
              )}
            </Menu>
          </div>
          <div>{user && <Profile user={user} />}</div>
        </div>
      </div>
    );
  }
}

export default withRouter(DesktopNavigation);
