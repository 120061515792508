import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const pricesMissingDataList = async (frequency, productSlug) => {
  let params = { product_type: productSlug };

  const url = `${BASE_URL}/prices/series/v2/missing/${frequency}/`;

  return await requestSdk(() => get(url, params));
};
