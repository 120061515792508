import React from 'react';
import { Table } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import { SortableHeader, YesNo } from 'components';
import { pageUrl } from 'config/routes';
import { StaffDetail } from 'pages';

const StaffTable = ({ staffMembers, sort, orderBy }) => (
  <Table celled selectable compact>
    <Table.Header>
      <Table.Row>
        <SortableHeader sort={sort} orderBy={orderBy} field="email">
          Email address
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="first_name">
          First name
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="last_name">
          Last name
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="is_superuser">
          Superuser status
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="is_active">
          Active
        </SortableHeader>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {staffMembers.map(member => (
        <Table.Row key={member.id}>
          <Table.Cell>
            <Link to={pageUrl(StaffDetail, { userId: member.id })}>
              {member.email}
            </Link>
          </Table.Cell>
          <Table.Cell>{member.first_name}</Table.Cell>
          <Table.Cell>{member.last_name}</Table.Cell>
          <Table.Cell>
            <YesNo value={member.is_superuser} />
          </Table.Cell>
          <Table.Cell>
            <YesNo value={member.is_active} />
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
);

export default StaffTable;
