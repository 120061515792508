import React from 'react';

import { withRouter } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  FormRow,
  InputField,
  UpdateButtonInRow,
  PollTypeSelect,
  DateTimeField,
  UnsavedFormPrompt
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { pollUpdate } from './sdk';

import { getInitialValues, pollSchema } from './utils';

class PollUpdateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { fetchPoll, poll } = this.props;

    const { setSubmitting, setFieldError } = actions;

    let data = prepareFormData(values);

    data = {
      ...data,
      poll: {
        question: data.question,
        description: data.description
      }
    };

    setSubmitting(true);

    const { errors, success } = await pollUpdate(poll.id, data);

    setSubmitting(false);

    if (success) {
      fetchPoll();
      notifySuccess('Poll updated.');
      return;
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  render() {
    const { poll } = this.props;

    const initialValues = getInitialValues(poll);

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={pollSchema}
        onSubmit={this.handleSubmit}>
        {({ values, handleSubmit, isSubmitting, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty} />

            <Field
              required
              width={16}
              name="question"
              component={InputField}
              label="Question"
            />

            <Field
              width={16}
              name="description"
              component={InputField}
              label="Description"
            />

            <FormRow>
              <PollTypeSelect
                width={4}
                required
                name="pollType"
                label="Poll type"
              />
              <Field
                required
                width={4}
                name="dateStart"
                component={DateTimeField}
                label="Date start:"
              />

              <Field
                required
                width={4}
                name="dateEnd"
                component={DateTimeField}
                label="Date end:"
              />
            </FormRow>
            <UpdateButtonInRow
              subject="Poll"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(PollUpdateForm);
