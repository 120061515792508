import React from 'react';

import { createMedia } from '@artsy/fresnel';

import { clientPendingTrialCount } from 'sdk/shared';

import { DesktopNavigation, MobileNavigation } from './components';

import { getMenuItems } from './constants';

class Navigation extends React.Component {
  state = {
    pendingForTrialCount: 0
  };

  componentDidMount() {
    this.fetchClientPendingForTrialCount();
  }

  fetchClientPendingForTrialCount = async () => {
    const { success, data } = await clientPendingTrialCount();

    if (success) {
      this.setState({ pendingForTrialCount: data.count });
    }
  };

  render() {
    const { user } = this.props;

    const navigationItems = getMenuItems(
      user,
      this.state.pendingForTrialCount || 0
    );

    const AppMedia = createMedia({
      breakpoints: {
        mobile: 320,
        tablet: 768,
        computer: 992,
        largeScreen: 1200,
        widescreen: 1920
      }
    });
    const mediaStyles = AppMedia.createMediaStyle();
    const { Media, MediaContextProvider } = AppMedia;

    return (
      <>
        <style>{mediaStyles}</style>
        <MediaContextProvider>
          <Media greaterThanOrEqual="computer">
            <DesktopNavigation
              navigationItems={navigationItems}
              {...this.props}
            />
          </Media>

          <Media lessThan="computer">
            <MobileNavigation
              navigationItems={navigationItems}
              {...this.props}
            />
          </Media>
        </MediaContextProvider>
      </>
    );
  }
}

export default Navigation;
