import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const clientGetLatestDailyNewsletterIssue = async (
  clientId,
  clientItemId
) => {
  const url = `${BASE_URL}/crm/clients/${clientId}/get-latest-issue/daily/${clientItemId}/`;

  return await requestSdk(() => get(url));
};

export const clientSendLatestDailyNewsletterIssue = async (
  clientId,
  clientItemId,
  data
) => {
  const url = `${BASE_URL}/crm/clients/${clientId}/send-latest-issue/daily/${clientItemId}/`;

  return await requestSdk(() => post(url, data));
};
