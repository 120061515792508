import _ from 'lodash';
import React from 'react';

import { Formik } from 'formik';

import { useHistory, useParams } from 'react-router-dom';

import { Form, Divider } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { PriceSerieList } from 'pages';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { populateSlug } from 'utils';
import { notifySuccess } from 'utils/notifications';

import {
  Field,
  InputField,
  FormRow,
  ErrorsAndCreateButtonInRow,
  CheckboxField,
  AdminSelect,
  RegionSelect,
  UnsavedFormPrompt,
  UniSelect
} from 'components';

import { priceSeriesCreate } from './sdk';

import { DEFAULT_INITIAL_VALUES, validationSchema } from './utils';
import { HYDROGEN_SLUG } from 'domainConstants';

function PriceSeriesCreateForm() {
  const { productSlug } = useParams();
  const history = useHistory();

  const handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = {
      ...prepareFormData(values),
      regions: _.without([values.region, values.region2], null)
    };

    setSubmitting(true);

    const { errors, success } = await priceSeriesCreate(productSlug, data);

    if (success) {
      notifySuccess('Price series created.', {
        onOpen: history.push(pageUrl(PriceSerieList, { productSlug }))
      });
      return;
    }

    handleFormErrors(errors, setFieldError);
    setSubmitting(false);
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={DEFAULT_INITIAL_VALUES}
      validationSchema={validationSchema}>
      {({
        isSubmitting,
        handleSubmit,
        setFieldValue,
        values,
        dirty,
        touched,
        errors
      }) => (
        <Form>
          <UnsavedFormPrompt when={dirty && !isSubmitting} />
          <ErrorsAndCreateButtonInRow
            errors={errors}
            touched={touched}
            onClick={handleSubmit}
            disabled={isSubmitting}
          />
          <FormRow>
            <Field
              width={2}
              name="activeInWebsite"
              label="Show on home page"
              component={CheckboxField}
            />

            <Field
              width={2}
              name="activeInNewsletters"
              label="Show in right column"
              component={CheckboxField}
            />
          </FormRow>
          <FormRow>
            <Field
              required
              width={6}
              name="title"
              label="Title:"
              component={InputField}
              onChange={(e, data) => {
                populateSlug(data, setFieldValue);
              }}
            />
            <Field
              required
              width={4}
              name="shortTitle"
              label="Short title:"
              component={InputField}
            />
          </FormRow>
          <FormRow>
            <Field width={6} name="slug" label="Slug:" component={InputField} />
            <Field
              width={4}
              name="displayAs"
              label="Display as:"
              component={InputField}
            />
          </FormRow>
          <FormRow>
            <UniSelect
              required
              width={5}
              name="groupId"
              label="Category:"
              source="priceSerieCategories"
              productSlug={productSlug}
            />

            <UniSelect
              required
              width={5}
              name="product"
              label="Product:"
              source="priceSerieProducts"
              productSlug={productSlug}
            />
          </FormRow>
          <FormRow>
            <UniSelect
              required
              width={5}
              name="region"
              label="Region:"
              source="priceSerieRegions"
            />

            <UniSelect
              width={5}
              name="region2"
              label="Region 2:"
              source="priceSerieRegions"
            />
          </FormRow>
          <FormRow widths="equal">
            <UniSelect
              required
              name="incoTerms"
              label="INCO terms:"
              source="priceSerieIncoTerms"
            />

            <UniSelect
              required
              name="format"
              label="Format:"
              source="priceSerieFormats"
            />

            <UniSelect
              required
              name="unit"
              label="Unit:"
              source="priceSerieUnits"
            />

            <UniSelect
              required
              name="frequency"
              label="Frequency:"
              source="priceSerieFrequencies"
            />
          </FormRow>
          <FormRow>
            <UniSelect
              required
              width={2}
              name="currency"
              label="Currency:"
              source="priceSerieCurrencies"
            />

            <UniSelect
              required
              width={2}
              name="scale"
              label="Scale:"
              source="priceSerieScales"
            />
            <Field
              required
              width={3}
              name="decimalPlaces"
              label="Decimal places:"
              component={InputField}
              type="number"
            />
          </FormRow>
          <FormRow>
            <UniSelect
              required
              width={4}
              name="priceDataSource"
              label="Price Data Source"
              source="priceSerieDataSources"
            />
            {values['priceDataSource'] &&
              values['priceDataSource'] !== 'Manual' && (
                <Field
                  width={4}
                  name="externalSourceSymbol"
                  label="Price Data External Symbols:"
                  component={InputField}
                  required
                />
              )}
          </FormRow>
          <FormRow>
            <AdminSelect
              width={4}
              name="principalOwner"
              label="Principal owner"
            />
          </FormRow>
          <AdminSelect
            width={16}
            name="access"
            isMulti={true}
            label="Access:"
            enableSelectAll
          />
          <RegionSelect
            isMulti={true}
            name="articleRegions"
            label="Region for articles:"
            source="regions"
            enableSelectAll
          />
          <UniSelect
            isMulti={true}
            disabled={productSlug === HYDROGEN_SLUG}
            name="articleCategories"
            label="Prices for articles:"
            source="priceSerieCategories"
            productSlug={productSlug}
            enableSelectAll
          />
          <UniSelect
            isMulti={true}
            disabled={productSlug === HYDROGEN_SLUG}
            name="articleProducts"
            label="Products for articles:"
            source="priceSerieProducts"
            productSlug={productSlug}
            enableSelectAll
          />
          <AdminSelect
            isMulti={true}
            name="articleAuthors"
            label="Authors for articles:"
            enableSelectAll
          />
          <Divider />
          <FormRow widths="equal">
            <Field name="metaTitle" label="Meta Title" component={InputField} />

            <Field
              name="metaKeywords"
              label="Meta Keywords"
              component={InputField}
            />

            <Field
              name="metaDescription"
              label="Meta Description"
              component={InputField}
            />

            <Field
              name="metaStandout"
              label="Meta Standout"
              component={InputField}
            />
          </FormRow>
          <ErrorsAndCreateButtonInRow
            errors={errors}
            touched={touched}
            onClick={handleSubmit}
            disabled={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
}

export default PriceSeriesCreateForm;
