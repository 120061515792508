import _ from 'lodash';

export const getNonSelectedOptions = (options, values) =>
  _.filter(options, option =>
    _.isEmpty(_.filter(values, value => value === option.value))
  );

export const getSelectedOptions = (options, values) => {
  let selectedOptions = [];

  _.forEach(values, id => {
    let option = _.find(options, option => option.value === id);
    selectedOptions = _.concat(selectedOptions, option);
  });

  return selectedOptions;
};

export const isOptionSelected = (values, value) =>
  _.indexOf(values, value) > -1;
