import _ from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';

import { Modal, Icon, Header, Table, Button } from 'semantic-ui-react';

import { Flex, SortableHeader, ExternalLinkButton } from 'components';

import { formatDateTime } from 'utils';
import { useSortable } from 'utils/sort';

import { getPromoCodesDownloadUsageUrl } from './sdk';

const PromoCodesUsageModal = ({ course, promocodesUsage, onClose }) => {
  const defaultData = useMemo(
    () =>
      _.map(promocodesUsage, ({ time_of_usage, ...rest }) => ({
        ...rest,
        // Transform `time_of_usage` to an actual `Date` object so that the sorting behaves as expected.
        time_of_usage: moment(time_of_usage).toDate()
      })),
    [promocodesUsage]
  );

  const { data, sort, handleSortUpdate } = useSortable({
    defaultData: defaultData
  });

  return (
    <Modal open={true} size="large" centered={false}>
      <Modal.Header>
        <Flex spaceBetween>
          <Header as="h2" style={{ margin: 0 }}>
            {course.title} Website Promo Code Usage
          </Header>
          <Icon name="close" onClick={onClose} />
        </Flex>
      </Modal.Header>
      <Modal.Content>
        <Flex flexEnd>
          <div>
            <ExternalLinkButton
              color="blue"
              url={getPromoCodesDownloadUsageUrl(course.item.id)}>
              Export
            </ExternalLinkButton>
          </div>
        </Flex>
        <div
          style={{ maxHeight: '60vh', overflowY: 'auto', marginTop: '15px' }}>
          <Table celled selectable>
            <Table.Header style={{ position: 'sticky', top: 0, zIndex: 1 }}>
              <Table.Row>
                <SortableHeader
                  sort={sort}
                  orderBy={handleSortUpdate}
                  field="code"
                  width={2}>
                  Code
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={handleSortUpdate}
                  field="discount"
                  width={2}>
                  Discount
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={handleSortUpdate}
                  field="client_id"
                  width={2}>
                  Client ID
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={handleSortUpdate}
                  field="client_email"
                  width={3}>
                  Client Email
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={handleSortUpdate}
                  field="time_of_usage"
                  width={2}>
                  Time Of Usage
                </SortableHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.map(promocode => (
                <Table.Row>
                  <Table.Cell width={2}>{promocode.code}</Table.Cell>
                  <Table.Cell width={2}>
                    {promocode.discount}
                    {promocode.discount_type === '%' && '%'}
                  </Table.Cell>
                  <Table.Cell width={2}>{promocode.client_id}</Table.Cell>
                  <Table.Cell width={3}>{promocode.client_email}</Table.Cell>
                  <Table.Cell width={2}>
                    {formatDateTime(promocode.time_of_usage)}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={onClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PromoCodesUsageModal;
