import { InputField, FaqCategorySelect } from 'components';
import { transformValuesForMultiselect } from 'utils/filters';

export const FILTERS = [
  [
    {
      name: 'search',
      emptyValue: '',
      component: InputField,
      label: 'Search',
      width: 5
    },
    {
      name: 'categories',
      emptyValue: null,
      transform: transformValuesForMultiselect,
      component: FaqCategorySelect,
      label: 'Category',
      props: () => ({
        isMulti: true
      }),
      width: 5
    }
  ]
];
