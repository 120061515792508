import React from 'react';

import _ from 'lodash';

import {
  Flex,
  LinkButton,
  OpenModalButton,
  InstantNewsModal
} from 'components';

import { scrollToElement } from 'utils';

import {
  BREAKING_NEWS,
  TRENDING_NEWS,
  getArticleData
} from 'components/InstantNewsModal/utils';

const InstantNewsHeadline = ({
  article,
  energyArticle,
  powerMaterialArticle,
  webArticle,
  fetchArticle
}) => {
  const { articleData, choosenArticle } = getArticleData(
    article,
    energyArticle,
    powerMaterialArticle,
    webArticle
  );

  return (
    <Flex>
      {!_.isEmpty(choosenArticle.instant_news) && (
        <LinkButton
          style={{ marginRight: '20px' }}
          onClick={() => scrollToElement('instant-news-table')}>
          Instant news ({choosenArticle.instant_news.length})
        </LinkButton>
      )}
      <OpenModalButton
        color="green"
        modal={{
          header: 'Send News Alert',
          render: props => (
            <InstantNewsModal
              newsType={TRENDING_NEWS.value}
              articleData={articleData}
              refetch={fetchArticle}
              {...props}
            />
          )
        }}>
        Send as {TRENDING_NEWS.label}
      </OpenModalButton>

      <OpenModalButton
        color="green"
        modal={{
          header: 'Send News Alert',
          render: props => (
            <InstantNewsModal
              newsType={BREAKING_NEWS.value}
              articleData={articleData}
              refetch={fetchArticle}
              {...props}
            />
          )
        }}>
        Send as {BREAKING_NEWS.label}
      </OpenModalButton>
    </Flex>
  );
};

export default InstantNewsHeadline;
