import React from 'react';
import { Checkbox } from 'semantic-ui-react';

import styles from './styles.module.css';

const CheckboxField = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  onChange = () => {},
  ...props // label
}) => {
  return (
    <div className={styles.fieldWrapper}>
      <Checkbox
        {...props}
        onChange={(e, data) => {
          onChange(e, data);
          setFieldValue(field.name, data.checked);
          setFieldTouched(field.name);
        }}
        checked={field.value}
      />
      {touched[field.name] && errors[field.name] && (
        <div className={styles.error}>{errors[field.name]}</div>
      )}
    </div>
  );
};

export default CheckboxField;
