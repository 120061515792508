import _ from 'lodash';
import React from 'react';

import { Table } from 'semantic-ui-react';

import { TableFooter } from 'components';

import { formatDate } from 'utils';

const CurrencyRateTable = ({
  currencyRates,
  priceSeriesCurrencies,
  orderBy,
  pagination,
  ...props
}) => (
  <Table celled selectable compact={true} size="large">
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell
          singleLine
          colspan={`${priceSeriesCurrencies?.length + 1}`}
          style={{ fontWeight: '400' }}>
          {pagination}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row textAlign="center" verticalAlign="middle">
        <Table.HeaderCell>Date</Table.HeaderCell>
        {_.map(priceSeriesCurrencies, currency => (
          <Table.HeaderCell key={currency.code}>
            EUR/{currency.code}
          </Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {_.map(currencyRates, currencyRate => (
        <Table.Row key={currencyRate.id}>
          <Table.Cell>{formatDate(currencyRate.date)}</Table.Cell>
          {_.map(priceSeriesCurrencies, currency => (
            <Table.HeaderCell key={currency.code}>
              {_.get(currencyRate.eur_to, currency.code)}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default CurrencyRateTable;
