import _ from 'lodash';
import React from 'react';

import { Field, SelectField } from 'components';
import { subscriptionList } from 'sdk/shared';

class SubscriptionSelect extends React.Component {
  state = {
    subscriptions: []
  };

  async componentDidMount() {
    const { data, success } = await subscriptionList();

    if (success) {
      this.setState({
        subscriptions: data.map(subscription => {
          return {
            key: subscription.id,
            value: subscription.id,
            label: subscription.title,
            itemId: subscription.item_id
          };
        })
      });
    }
  }

  render() {
    const { name, additionalOptions, excludeOptions, ...rest } = this.props;
    const { subscriptions } = this.state;

    let options = subscriptions;

    if (!_.isEmpty(excludeOptions)) {
      options = _.filter(
        options,
        option => _.findIndex(excludeOptions, { id: option.itemId }) < 0
      );
    }
    if (!_.isEmpty(additionalOptions)) {
      options = _.concat(options, additionalOptions);
    }

    return (
      <Field
        name={name || 'subscription'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default SubscriptionSelect;
