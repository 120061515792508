import React from 'react';

import { Field, SelectField } from 'components';

const PIPELINE_ENGAGEMENT_OPTIONS = [
  'No Status',
  'Prospect',
  'Evaluator',
  'Interested',
  'Quoted',
  'Payment Promised',
  'Payment On Route',
  'Paid',
  'Cancelled',
  'Delayed',
  'PNR'
];

class PipelineEngagementSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = PIPELINE_ENGAGEMENT_OPTIONS.map(item => {
      return {
        key: item,
        value: item,
        label: item
      };
    });

    return (
      <Field
        name={name || 'pipelineEngagement'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default PipelineEngagementSelect;
