export const getDefaultInitialValues = () => ({
  username: '',
  email: '',
  autoEmails: true,
  unsubscribed: false,
  firstName: '',
  lastName: '',
  jobTitle: '',
  companyName: '',
  adminComments: '',
  address_1: '',
  address_2: '',
  city: '',
  postcode: '',
  stateOrProvince: '',
  website: '',
  phone: '',
  fax: '',
  mobile: '',
  linkedinProfile: '',
  personalStatement: '',
  priority: null,
  title: null,
  country: null,
  emailType: 'HTML',
  registrationType: null,
  businessActivity: null,
  commodity: null,
  industry: null,
  tags: []
});

export const getInitialValuesFromRegistrationAttempt = registrationAttempt => ({
  ...getDefaultInitialValues(),
  email: registrationAttempt.email,
  firstName: registrationAttempt.first_name || '',
  lastName: registrationAttempt.last_name || '',
  jobTitle: registrationAttempt.job_title || '',
  companyName: registrationAttempt.company_name || '',
  address_1: registrationAttempt.address_1 || '',
  address_2: registrationAttempt.address_2 || '',
  city: registrationAttempt.city || '',
  country: registrationAttempt.country,
  postcode: registrationAttempt.postcode || '',
  phone: registrationAttempt.phone || '',
  title: registrationAttempt.title,
  emailType: registrationAttempt.email_type || 'HTML',
  businessActivity: registrationAttempt.business_activity
});
