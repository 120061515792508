import _ from 'lodash';
import React from 'react';

import { Field, SelectField } from 'components';
import { EMAIL_TEMPLATE_TRIGGER } from 'pages/EmailTemplate/constants';

class EmailTriggerSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = _.values(EMAIL_TEMPLATE_TRIGGER).map(status => {
      return {
        value: status,
        label: status,
        key: status
      };
    });

    return (
      <Field
        name={name || 'emailTrigger'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default EmailTriggerSelect;
