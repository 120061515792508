import React from 'react';

import _ from 'lodash';

const withLogType = ({ logType = null, mailTargetName = null }) => {
  return WrappedComponent => {
    class HigherOrderComponent extends React.Component {
      render() {
        return (
          !_.isNil(logType) &&
          ((
            <WrappedComponent
              logType={logType}
              mailTargetName={mailTargetName}
              {...this.props}
            />
          ) ||
            null)
        );
      }
    }

    return HigherOrderComponent;
  };
};

export default withLogType;
