import React, { useCallback } from 'react';

import { Dimmer, Header, Icon, Loader, Modal, Form } from 'semantic-ui-react';
import { Formik } from 'formik';

import { ChoiceToggle, Flex, FormRow, UpdateButtonInRow } from 'components';
import { notifyErrors, notifySuccess } from 'utils/notifications';

import { clientsTogglePowerMaterialsUnsubscribed } from './sdk';

export default function ToggleUnsubscribedModal({
  selectedClients,
  onClose,
  onSuccess
}) {
  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      const confirm = window.confirm(
        `Are you sure you want to confirm 'Power Materials Unsubscribed' status for selected clients?`
      );

      if (!confirm) {
        setSubmitting(false);
        return;
      }

      setSubmitting(true);

      const { errors, success } = await clientsTogglePowerMaterialsUnsubscribed(
        {
          unsubscribed: values.unsubscribed,
          clients: selectedClients
        }
      );

      if (success) {
        notifySuccess(
          `'Power Materials Unsubscribed' preference successfully changed.`
        );
        onSuccess();
        onClose();
      } else {
        notifyErrors(errors);
      }
      setSubmitting(false);
    },
    [onClose, onSuccess, selectedClients]
  );

  return (
    <Modal open={true} size="small" centered={false}>
      <Modal.Header>
        <Flex spaceBetween>
          <Header
            data-testid="toggle-power-materials-unsubscribed-modal-title"
            as="h2"
            style={{ margin: 0 }}>
            Toggle Power Materials Unsubscribed
          </Header>
          <Icon
            data-testid="toggle-power-materials-unsubscribed-modal-close-btn"
            name="close"
            onClick={onClose}
          />
        </Flex>
      </Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={{ unsubscribed: undefined }}
          onSubmit={handleSubmit}>
          {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
            <>
              <Form>
                <FormRow>
                  <ChoiceToggle
                    leftOption={{
                      predicate: values.unsubscribed === true,
                      onClick: () => setFieldValue('unsubscribed', true),
                      text: 'Turn On Power Materials Unsubscribed'
                    }}
                    rightOption={{
                      predicate: values.unsubscribed === false,
                      onClick: () => setFieldValue('unsubscribed', false),
                      text: 'Turn Off Power Materials Unsubscribed'
                    }}
                  />
                </FormRow>

                <UpdateButtonInRow
                  data-testid="toggle-power-materials-unsubscribed-modal-assign-btn"
                  onClick={handleSubmit}
                  subject="Power Materials Unsubscribed"
                  disabled={isSubmitting || values.unsubscribed === undefined}
                />
              </Form>

              {isSubmitting && (
                <Dimmer active inverted>
                  <Loader inverted>Submitting</Loader>
                </Dimmer>
              )}
            </>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
}
