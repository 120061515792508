import React from 'react';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import { Segment, Table } from 'semantic-ui-react';

import { SortableHeader, TableFooter } from 'components';

import { pageUrl } from 'config/routes';

import { ClientDetail } from 'pages';

import { formatDate } from 'utils';

import { getStatusColor, getTypeColor } from './utils';

class PipelineItemsTable extends React.Component {
  render() {
    const { data, sort, orderBy, pagination } = this.props;

    return (
      <Segment>
        {!_.isNil(data) && (
          <Table celled selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  singleLine
                  style={{ fontWeight: '400' }}
                  colspan="11">
                  {pagination}
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <SortableHeader sort={sort} orderBy={orderBy} field="client_id">
                  ID
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={orderBy}
                  field="account_manager">
                  Account Manager
                </SortableHeader>
                <SortableHeader sort={sort} orderBy={orderBy} field="company">
                  Company
                </SortableHeader>
                <SortableHeader sort={sort} orderBy={orderBy} field="country">
                  Country
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={orderBy}
                  field="pipeline_type">
                  Type
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={orderBy}
                  field="invoice_type">
                  Invoice type
                </SortableHeader>

                <SortableHeader sort={sort} orderBy={orderBy} field="product">
                  Product
                </SortableHeader>

                <SortableHeader
                  sort={sort}
                  orderBy={orderBy}
                  field="forecasted_value">
                  Value(GBP)
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={orderBy}
                  field="pipeline_engagement">
                  Status
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={orderBy}
                  field="forecasted_date">
                  Forecasted Date
                </SortableHeader>
                <SortableHeader sort={sort} orderBy={orderBy} field="certainty">
                  Certainty
                </SortableHeader>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {_.map(data, item => (
                <Table.Row>
                  <Table.Cell>
                    <Link
                      to={pageUrl(ClientDetail, { clientId: item.client.id })}>
                      {item.client.id}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    {_.get(
                      item,
                      'client.client_profile.account_manager.first_name'
                    )}{' '}
                    {_.get(
                      item,
                      'client.client_profile.account_manager.last_name'
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {_.get(item, 'client.client_profile.company_name')}
                  </Table.Cell>
                  <Table.Cell>
                    {_.get(item, 'client.client_profile.country.title')}
                  </Table.Cell>
                  <Table.Cell
                    style={{
                      backgroundColor: getTypeColor(item.pipeline_type)
                    }}>
                    {item.pipeline_type}
                  </Table.Cell>
                  <Table.Cell>{_.get(item, 'invoice_item.type')}</Table.Cell>
                  <Table.Cell>
                    {_.get(item, 'client_item.item.title') ||
                      _.get(item, 'item.title')}
                  </Table.Cell>

                  <Table.Cell>
                    {item.forecasted_value_gbp &&
                      item.forecasted_value_gbp.toFixed(0)}
                  </Table.Cell>
                  <Table.Cell
                    style={{
                      backgroundColor: getStatusColor(item.pipeline_engagement)
                    }}>
                    {item.pipeline_engagement}
                  </Table.Cell>
                  <Table.Cell>{formatDate(item.forecasted_date)}</Table.Cell>
                  <Table.Cell>{item.certainty}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <TableFooter>{pagination}</TableFooter>
          </Table>
        )}
      </Segment>
    );
  }
}

export default withRouter(PipelineItemsTable);
