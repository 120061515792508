import React from 'react';

import _ from 'lodash';

import { Link } from 'react-router-dom';
import { Dropdown as SemanticDropdown } from 'semantic-ui-react';

import { VisibleIf } from 'permissions';

import { getLink } from 'components/Navigation/utils';

import styles from './styles.module.scss';

const shouldDisplayItem = (user, menuItem) => {
  if (menuItem.superUserRequired && !_.get(user, 'is_superuser', false)) {
    return false;
  }

  return true;
};

const DropdownLinkItem = ({ to, children, search = '' }) => {
  return (
    <div className={styles.dropdownItem}>
      <Link
        to={{ pathname: to, search: search, state: { fromNavigation: true } }}
        className={styles.link}>
        {children}
      </Link>
    </div>
  );
};
const Dropdown = ({ title, menuItem, user }) => (
  <SemanticDropdown
    text={title}
    pointing
    className="link item"
    style={{ height: '56px' }}>
    <SemanticDropdown.Menu>
      {menuItem.items.map(
        (item, index) =>
          shouldDisplayItem(user, item) && (
            <VisibleIf
              key={index}
              user={user}
              permissions={item.permissions ? item.permissions : []}>
              {item.link && (
                <DropdownLinkItem
                  key={index}
                  to={getLink(item.link, item.params)}
                  search={item.queryParams}>
                  {item.title}
                </DropdownLinkItem>
              )}
              {!item.link && item.action && (
                <div key={index} className={styles.dropdownItem}>
                  <span className={styles.link} onClick={() => item.action()}>
                    {item.title}
                  </span>
                </div>
              )}
            </VisibleIf>
          )
      )}
    </SemanticDropdown.Menu>
  </SemanticDropdown>
);

export default Dropdown;
