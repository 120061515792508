import React from 'react';

import _ from 'lodash';

import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';

import { Form } from 'semantic-ui-react';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import {
  Field,
  UpdateButtonInRow,
  InputField,
  FormRow,
  CheckboxField,
  FileField,
  UnsavedFormPrompt,
  CreatedUpdatedFormValues
} from 'components';

import { bannerUpdate } from './sdk';

import { bannerUpdateSchema } from './utils';

class BannerUpdateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const data = _.pickBy(values, _.negate(_.isNil));

    const { fetchBanner } = this.props;
    const { bannerId } = this.props.match.params;

    const { setSubmitting, setFieldError } = actions;

    const { errors, success } = await bannerUpdate(
      bannerId,
      prepareFormData(data)
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Banner has been updated successfully.');
      fetchBanner();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const {
      title,
      image,
      url,
      open_in_new_window: openInNewWindow,
      home_page_banner: homePageBanner,
      created_at: createdAt,
      created_by: createdBy,
      updated_at: updatedAt,
      updated_by: updatedBy
    } = this.props.data;

    const initialValues = {
      title,
      url,
      openInNewWindow,
      homePageBanner,
      image
    };

    const nonFormValues = {
      createdAt,
      createdBy,
      updatedAt,
      updatedBy
    };

    return (
      <Formik
        enableReinitialize={true}
        onSubmit={this.handleSubmit}
        validationSchema={bannerUpdateSchema}
        initialValues={initialValues}>
        {({ handleSubmit, isSubmitting, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty} />

            <FormRow>
              <Field
                required
                width={6}
                name="title"
                label="Title:"
                component={InputField}
              />
              <Field width={6} name="url" label="URL:" component={InputField} />
            </FormRow>
            <Field
              width={6}
              required
              name="image"
              label="Image:"
              component={FileField}
            />

            <Field
              name="openInNewWindow"
              label="Open in new window:"
              component={CheckboxField}
            />

            <Field
              name="homePageBanner"
              label="Home page"
              component={CheckboxField}
            />
            <CreatedUpdatedFormValues values={nonFormValues} />
            <UpdateButtonInRow
              subject="Banner"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(BannerUpdateForm);
