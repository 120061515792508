import _ from 'lodash';
import React from 'react';

import { Field, SelectField } from 'components';

import { eventList } from './sdk';

class EventSelect extends React.Component {
  state = {
    options: []
  };

  async componentDidMount() {
    const { data, success } = await eventList();

    if (success) {
      this.setState({
        options: data.map(event => ({
          key: event.id,
          value: event.id,
          label: event.title,
          itemId: event.item_id
        }))
      });
    }
  }

  render() {
    const { name, excludeOptions, ...rest } = this.props;
    let options = this.state.options;

    if (!_.isEmpty(excludeOptions)) {
      options = _.filter(
        options,
        option => _.findIndex(excludeOptions, { id: option.itemId }) < 0
      );
    }

    return (
      <Field
        name={name || 'event'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default EventSelect;
