import React, { useCallback } from 'react';

import { Modal, Icon, Header, Form, Loader, Dimmer } from 'semantic-ui-react';

import { Formik } from 'formik';

import { Flex, FormRow, UpdateButtonInRow, ChoiceToggle } from 'components';

import { notifySuccess, notifyErrors } from 'utils/notifications';

import { clientsToggleAutoEmails } from './sdk';

export default function ToggleAutoEmailsModal({
  selectedClients,
  onClose,
  onSuccess
}) {
  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      const confirm = window.confirm(
        `Are you sure you want to change the 'Auto Emails' preference of these clients?`
      );

      if (!confirm) {
        setSubmitting(false);
        return;
      }

      setSubmitting(true);

      const { errors, success } = await clientsToggleAutoEmails({
        auto_emails: values.auto_emails,
        clients: selectedClients
      });

      if (success) {
        notifySuccess("'Auto emails' preference successfully changed.");
        onSuccess();
        onClose();
      } else {
        notifyErrors(errors);
      }
      setSubmitting(false);
    },
    [onClose, onSuccess, selectedClients]
  );

  return (
    <Modal open={true} size="tiny" centered={false}>
      <Modal.Header>
        <Flex spaceBetween>
          <Header as="h2" style={{ margin: 0 }}>
            Toggle Auto Emails
          </Header>
          <Icon name="close" onClick={onClose} />
        </Flex>
      </Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={{ auto_emails: undefined }}
          onSubmit={handleSubmit}>
          {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
            <>
              <Form>
                <FormRow>
                  <ChoiceToggle
                    leftOption={{
                      predicate: values.auto_emails === true,
                      onClick: () => setFieldValue('auto_emails', true),
                      text: 'Receive Auto Emails'
                    }}
                    rightOption={{
                      predicate: values.auto_emails === false,
                      onClick: () => setFieldValue('auto_emails', false),
                      text: 'Do Not Receive Auto Emails'
                    }}
                  />
                </FormRow>

                <UpdateButtonInRow
                  onClick={handleSubmit}
                  subject="Auto Emails"
                  disabled={isSubmitting || values.auto_emails === undefined}
                />
              </Form>

              {isSubmitting && (
                <Dimmer active inverted>
                  <Loader inverted>Submitting</Loader>
                </Dimmer>
              )}
            </>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
}
