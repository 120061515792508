import React from 'react';

import { Table } from 'semantic-ui-react';

import { formatDate } from 'utils';

import { Admin, Button, YesNo } from 'components';

import { notifySuccess, notifyErrors } from 'utils/notifications';

import { clientDeleteReminder, clientMarkReminderAsDone } from './sdk';

class ClientReminders extends React.Component {
  deleteReminder = async reminder => {
    const { client, fetchClient } = this.props;

    const data = {
      reminder: reminder.id
    };

    const { success, errors } = await clientDeleteReminder(client.id, data);

    if (success) {
      notifySuccess('Reminder deleted.');
      fetchClient();
      return;
    } else {
      notifyErrors(errors);
    }
  };

  markReminderAsDone = async reminder => {
    const { client, fetchClient } = this.props;

    const data = {
      reminder: reminder.id
    };

    const { success, errors } = await clientMarkReminderAsDone(client.id, data);

    if (success) {
      notifySuccess('Reminder marked as done.');
      fetchClient();
      return;
    } else {
      notifyErrors(errors);
    }
  };

  render() {
    const { client } = this.props;
    const reminders = client.client_reminders;

    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Time</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Created By</Table.HeaderCell>
            <Table.HeaderCell>Done</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {reminders.map(reminder => (
            <Table.Row key={reminder.id}>
              <Table.Cell>{formatDate(reminder.sent_date)}</Table.Cell>
              <Table.Cell>{reminder.time}</Table.Cell>
              <Table.Cell>{reminder.description}</Table.Cell>
              <Table.Cell>
                <Admin value={reminder.created_by} />
              </Table.Cell>
              <Table.Cell>
                <YesNo value={reminder.is_done} />
              </Table.Cell>
              <Table.Cell width={3}>
                {!reminder.is_done && (
                  <Button onClick={() => this.markReminderAsDone(reminder)}>
                    Done
                  </Button>
                )}
                <Button
                  color="red"
                  onClick={() => this.deleteReminder(reminder)}>
                  Delete
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}

export default ClientReminders;
