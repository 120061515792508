import React, { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';

import { Header, Segment, Divider } from 'semantic-ui-react';

import { withRouter } from 'react-router-dom';

import {
  Page,
  LoginRequired,
  Flex,
  EmailVariables,
  ContentEditionWarning
} from 'components';

import { PermissionsRequired, PERMISSIONS } from 'permissions';

import { EDITION_WARNING_CONTENT_TYPES } from 'domainConstants';

import { notifyError } from 'utils/notifications';
import {
  MailshotGroupItemsTable,
  MailshotGroupUpdateForm,
  MailshotGroupLinks,
  MailshotGroupAddLink
} from './components';

import { mailshotGroupDetail, mailshotGroupItems } from './sdk';

const MailshotGroupDetail = ({ user, crumbs, match, history }) => {
  const { mailshotGroupId } = match.params;

  const [mailshotGroup, setMailshotGroup] = useState(null);
  const [mailshotGroupItemsList, setMailshotGroupItemsList] = useState([]);

  const fetchMailshotGroup = useCallback(async () => {
    const { data, errors, success } = await mailshotGroupDetail(
      mailshotGroupId
    );

    if (success) {
      setMailshotGroup(data);
    } else {
      notifyError(errors);
    }
  }, [mailshotGroupId]);

  const fetchMailshotGroupItems = useCallback(async () => {
    const { data, errors, success } = await mailshotGroupItems(mailshotGroupId);

    if (success) {
      setMailshotGroupItemsList(data);
    } else {
      notifyError(errors);
    }
  }, [mailshotGroupId]);

  useEffect(() => {
    fetchMailshotGroup();
    fetchMailshotGroupItems();
  }, [fetchMailshotGroup, fetchMailshotGroupItems]);

  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content crumbs={crumbs}>
          {!_.isNil(mailshotGroup) && (
            <ContentEditionWarning
              user={user}
              contentType={EDITION_WARNING_CONTENT_TYPES.MAILSHOT}
              contentId={mailshotGroup.id}
            />
          )}

          <Segment>
            {!_.isNil(mailshotGroup) && (
              <MailshotGroupItemsTable
                mailshotGroup={mailshotGroup}
                user={user}
                history={history}
                mailshotGroupItemsList={mailshotGroupItemsList}
                fetchMailshotGroupItems={fetchMailshotGroupItems}
              />
            )}
          </Segment>

          <Segment>
            <Flex spaceBetween>
              <Header as="h1">Mailshot Group Detail </Header>
            </Flex>
            {!_.isNil(mailshotGroup) && (
              <MailshotGroupUpdateForm
                mailshotGroup={mailshotGroup}
                fetchMailshotGroup={fetchMailshotGroup}
              />
            )}
          </Segment>
          <Segment>
            <EmailVariables />
          </Segment>
          {!_.isNil(mailshotGroup) && (
            <Segment>
              <Header as="h3">Mailshot Group Links</Header>
              {!_.isEmpty(mailshotGroup.links) && (
                <MailshotGroupLinks links={mailshotGroup.links} />
              )}
              {_.isEmpty(mailshotGroup.links) && (
                <div>There are no mailshot group links yet.</div>
              )}
              <Divider />
              <MailshotGroupAddLink
                mailshot={mailshotGroup}
                fetchMailshotGroup={fetchMailshotGroup}
                mailshotGroupItemsLength={mailshotGroupItemsList.length}
              />
            </Segment>
          )}
        </Page.Content>
      </Page.Body>
    </Page>
  );
};

export default LoginRequired(
  PermissionsRequired([PERMISSIONS.VIEW_MAILSHOTS])(
    withRouter(MailshotGroupDetail)
  )
);
