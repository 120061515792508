import { YesNoSelect } from 'components';
import { InputField } from 'components';

import { coerceStringToBoolean } from 'utils';

export const FILTERS = [
  [
    {
      name: 'is_superuser',
      emptyValue: null,
      transform: coerceStringToBoolean,
      component: YesNoSelect,
      label: 'Is Superuser',
      width: 3
    },
    {
      name: 'is_active',
      emptyValue: null,
      transform: coerceStringToBoolean,
      component: YesNoSelect,
      label: 'Is Active',
      width: 3
    },
    {
      name: 'email',
      emptyValue: '',
      component: InputField,
      label: 'Email address',
      width: 4
    }
  ]
];
