import React from 'react';

import _ from 'lodash';

import { Formik } from 'formik';

import {
  Field,
  DateField,
  InputField,
  UnsavedFormPrompt,
  Flex
} from 'components';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess, notifyErrors, notifyError } from 'utils/notifications';

import { Table, Button } from 'semantic-ui-react';

import { courseAddPresaleSchema } from './schema';
import {
  courseAddPresale,
  courseUpdatePresale,
  courseRemovePresale
} from './sdk';

import { PresaleGroupRateModal } from '../index';
import { presaleGroupRateList } from '../PresaleGroupRateModal/sdk';

const buildCurrencyKey = currency => `currency_${currency.id}`;

const isCurrencyKey = value => value.startsWith('currency_');
const extractCurrencyId = value => value.split('currency_')[1];

export const prepareCurrencies = values => {
  const result = [];

  const currencies = _.mapKeys(
    _.pickBy(values, (value, key) => isCurrencyKey(key)),
    (value, key) => extractCurrencyId(key)
  );

  _.forEach(currencies, (value, key) => {
    const currency = {
      currency: key,
      value: value
    };

    result.push(currency);
  });

  return result;
};

class PresaleForm extends React.Component {
  state = { isOpenGroupRateModal: false, groupRates: [] };

  fetchGroupRates = async () => {
    const { data, errors, success } = await presaleGroupRateList(
      this.props.course.id,
      this.props.presale.id
    );
    if (success) {
      this.setState({ groupRates: data });
    } else {
      notifyErrors(errors);
    }
  };

  openGroupRateModal = () => {
    this.setState({ isOpenGroupRateModal: true });
    this.fetchGroupRates();
  };

  closeGroupRateModal = () => {
    this.setState({ isOpenGroupRateModal: false });
  };

  handleSubmit = (values, actions) => {
    const { presale } = this.props;

    if (_.isNil(presale)) {
      this.createPresale(values, actions);
    } else {
      this.updatePresale(values, actions);
    }
  };

  getDefaultCurrency = () => {
    return this.props.currencies.find(
      currency => currency.id === this.props.course.default_currency
    );
  };

  isDateOverlapping = (filteredPresales, values) => {
    return filteredPresales.some(presale => {
      if (_.isNil(presale.start_date)) {
        return values.start_date <= presale.end_date;
      }

      const startDateOverlap =
        values.start_date >= presale.start_date &&
        values.start_date <= presale.end_date;
      const endDateOverlap =
        values.end_date >= presale.start_date &&
        values.end_date <= presale.end_date;

      return startDateOverlap || endDateOverlap;
    });
  };

  isEmptyForDefaultCurrency = values => {
    const defaultCurrencyId = this.props.course.default_currency;
    return Number(values[buildCurrencyKey({ id: defaultCurrencyId })]) === 0;
  };

  removePresale = async () => {
    const { presale, course, fetchCourse } = this.props;

    if (
      !window.confirm(
        'Removing this Presale will also remove the Usage records related to it. Are you sure you want to continue?'
      )
    ) {
      return;
    }

    const data = { presale: presale.id };

    const { success, errors } = await courseRemovePresale(
      course.id,
      prepareFormData(data)
    );

    if (success) {
      notifySuccess(`Presale for ${presale.title} removed.`);
      fetchCourse();
    }

    if (!success) {
      notifyErrors(errors);
    }
  };

  updatePresale = async (values, actions) => {
    const { presale, allPresales, course, fetchCourse } = this.props;

    const { setSubmitting, setFieldError } = actions;

    if (
      !window.confirm(
        'Updating this Presale will also change the Usage records related to it. Are you sure you want to continue?'
      )
    ) {
      setSubmitting(false);
      return;
    }

    const filteredPresales = allPresales.filter(p => p !== presale); // Remove to-be-updated presale from the list of all presales
    if (this.isDateOverlapping(filteredPresales, values)) {
      if (
        !window.confirm(
          'The Presale period overlaps with another presale. This may affect View Usage data. Are you sure you want to continue?'
        )
      ) {
        setSubmitting(false);
        return;
      }
    }

    if (this.isEmptyForDefaultCurrency(values)) {
      const defaultCurrency = this.getDefaultCurrency();
      notifyError(
        `Please enter a price for the default currency (${defaultCurrency.code}).`
      );
      setSubmitting(false);
      return;
    }

    const currencies = prepareCurrencies(values);

    const data = prepareFormData(values);

    data.start_date = values.start_date;
    data.currencies = currencies;
    data.presale = presale.id;

    setSubmitting(true);

    const { success, errors } = await courseUpdatePresale(
      course.id,
      prepareFormData(data)
    );

    setSubmitting(false);

    if (success) {
      notifySuccess(`Presale for ${values.title} updated.`);
      fetchCourse();
    }

    if (!success) {
      handleFormErrors(errors, setFieldError);
    }
  };

  createPresale = async (values, actions) => {
    const { course, fetchCourse, allPresales } = this.props;

    const { setSubmitting, setFieldError, resetForm } = actions;

    if (this.isDateOverlapping(allPresales, values)) {
      if (
        !window.confirm(
          'The Presale period overlaps with another presale. This may affect View Usage data. Are you sure you want to continue?'
        )
      ) {
        setSubmitting(false);
        return;
      }
    }

    if (this.isEmptyForDefaultCurrency(values)) {
      const defaultCurrency = this.getDefaultCurrency();
      notifyError(
        `Please enter a price for the default currency (${defaultCurrency.code}).`
      );
      setSubmitting(false);
      return;
    }

    const currencies = prepareCurrencies(values);

    const data = prepareFormData(values);

    data.currencies = currencies;

    setSubmitting(true);

    const { success, errors } = await courseAddPresale(
      course.id,
      prepareFormData(data)
    );

    setSubmitting(false);

    if (success) {
      notifySuccess(`Presale for ${values.title} added.`);
      resetForm();
      fetchCourse();
    }

    if (!success) {
      handleFormErrors(errors, setFieldError);
    }
  };

  getCreateInitialValues = () => {
    const { currencies } = this.props;

    const initialValues = {
      start_date: null,
      end_date: null,
      title: ''
    };

    currencies.forEach(currency => {
      initialValues[buildCurrencyKey(currency)] = 0;
    });

    return initialValues;
  };

  getUpdateInitialValues = () => {
    const { presale, currencies } = this.props;

    const initialValues = {
      start_date: presale.start_date,
      end_date: presale.end_date,
      title: presale.title
    };

    currencies.forEach(currency => {
      const presalePrice = _.find(
        presale.prices,
        price => price.currency.toString() === currency.id.toString()
      );

      initialValues[buildCurrencyKey(currency)] =
        (presalePrice && presalePrice.value) || 0;
    });

    return initialValues;
  };

  render() {
    const { presale, currencies } = this.props;

    let initialValues = null;

    if (_.isNil(presale)) {
      initialValues = this.getCreateInitialValues();
    } else {
      initialValues = this.getUpdateInitialValues();
    }

    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        validationSchema={courseAddPresaleSchema}
        enableReinitialize={true}>
        {({ values, handleSubmit, isSubmitting, dirty }) => (
          <>
            <UnsavedFormPrompt when={dirty} formName="Presale form" />

            <Table.Row>
              <Table.Cell>
                <Field required name="start_date" component={DateField} />
              </Table.Cell>
              <Table.Cell>
                <Field required name="end_date" component={DateField} />
              </Table.Cell>
              <Table.Cell>
                <Field required name="title" component={InputField} />{' '}
              </Table.Cell>
              {currencies.map(currency => (
                <Table.Cell key={currency.id}>
                  <Field
                    name={buildCurrencyKey(currency)}
                    component={InputField}
                  />
                </Table.Cell>
              ))}
              {presale && (
                <Table.Cell textAlign="center">
                  <Flex>
                    <Button
                      onClick={handleSubmit}
                      type="submit"
                      color="blue"
                      disabled={isSubmitting}>
                      Update
                    </Button>
                    <Button
                      basic
                      color="grey"
                      onClick={this.removePresale}
                      type="submit"
                      disabled={isSubmitting}>
                      Remove
                    </Button>
                  </Flex>
                  <Button
                    color="green"
                    onClick={this.openGroupRateModal}
                    style={{ marginTop: '10px' }}
                    disabled={isSubmitting}>
                    Manage Group Rates
                  </Button>
                </Table.Cell>
              )}

              {!presale && (
                <Table.Cell textAlign="center">
                  <Button
                    onClick={handleSubmit}
                    type="submit"
                    color="blue"
                    disabled={isSubmitting}>
                    Add
                  </Button>
                </Table.Cell>
              )}
            </Table.Row>
            {this.state.isOpenGroupRateModal && (
              <PresaleGroupRateModal
                presale={presale}
                course={this.props.course}
                currencies={currencies}
                groupRates={this.state.groupRates}
                fetchGroupRates={this.fetchGroupRates}
                onClose={this.closeGroupRateModal}
              />
            )}
          </>
        )}
      </Formik>
    );
  }
}

export default PresaleForm;
