import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const priceIndexList = async (productSlug, params) => {
  params['product_type'] = productSlug;

  const url = `${BASE_URL}/prices/indexes/`;

  return await requestSdk(() => get(url, params));
};
