import React, { useCallback } from 'react';

import { withRouter, useHistory } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  InputField,
  FormRow,
  ErrorsAndCreateButtonInRow,
  RichTextAreaField,
  TextAreaField,
  UnsavedFormPrompt,
  FileField
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { pageUrl } from 'config/routes';

import { StaffList } from 'pages';

import { staffCreate } from './sdk';

import { staffCreateSchema } from './utils';

const initialValues = {
  email: '',
  firstName: '',
  lastName: '',
  signatureHtml: '',
  signatureText: '',
  image: null,
  twitter: '',
  description: '',
  linkedin: '',
  location: ''
};

const StaffCreateForm = () => {
  const history = useHistory();

  const handleStaffMemberAdd = useCallback(
    async (values, actions) => {
      const { setSubmitting, setFieldError } = actions;

      const data = prepareFormData(values);

      setSubmitting(true);
      const { errors, success } = await staffCreate(data);

      if (success) {
        notifySuccess(`Staff member added successfully`);
        history.push(pageUrl(StaffList));
      } else {
        handleFormErrors(errors, setFieldError);
        setSubmitting(false);
      }
    },
    [history]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={staffCreateSchema}
      onSubmit={handleStaffMemberAdd}>
      {({ values, handleSubmit, isSubmitting, dirty, errors, touched }) => (
        <Form>
          <UnsavedFormPrompt when={dirty && !isSubmitting} />
          <FormRow>
            <Field
              required
              width={6}
              name="email"
              component={InputField}
              label="Email"
            />
            <Field
              required
              width={5}
              name="firstName"
              component={InputField}
              label="First Name:"
            />
            <Field
              required
              width={5}
              name="lastName"
              component={InputField}
              label="Last Name:"
            />
          </FormRow>
          <Field
            name="image"
            width={4}
            label="Profile Image"
            component={FileField}
          />
          <FormRow>
            <Field
              width={4}
              name="location"
              component={InputField}
              label="Location"
            />
            <Field
              width={6}
              name="twitter"
              component={InputField}
              label="Twitter URL"
            />
            <Field
              width={6}
              name="linkedin"
              component={InputField}
              label="LinkedIn URL"
            />
          </FormRow>
          <Field
            name="description"
            component={RichTextAreaField}
            label="Description"
          />

          <Field
            name="signatureHtml"
            label="Signature HTML"
            component={RichTextAreaField}
          />
          <Field
            name="signatureText"
            label="Signature Text"
            component={TextAreaField}
          />
          <ErrorsAndCreateButtonInRow
            errors={errors}
            touched={touched}
            onClick={handleSubmit}
            disabled={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(StaffCreateForm);
