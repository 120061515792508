export const BASE_BACKEND_URL = process.env.REACT_APP_ALTERNATIVE_BACKEND_URL;
export const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL;

export const BACKEND_URL = `${BASE_BACKEND_URL}/en`;

export const BASE_URL = `${BACKEND_URL}/api`;

export const LOGIN_API_URL = `${BASE_URL}/auth/admin/login/`;

export const LOGOUT_API_URL = `${BASE_URL}/auth/logout/`;

export const ME_API_URL = `${BASE_URL}/auth/me/`;

export const PRICE_MOVEMENT_EXPORT_URL = `${BACKEND_URL}/api/prices/series/price-movement/export/`;

export const PRICE_SPREAD_DETAILS_URL = slug =>
  `${WEBSITE_URL}/price-spreads/spread-details/${slug}/`;
export const PRICE_SPREAD_V2_DETAILS_URL = (mainGroup, slug) =>
  `${WEBSITE_URL}/prices/list/${mainGroup}/price-spreads/${slug}/`;

export const PRICE_SPREADS_URL = `${BACKEND_URL}/price-spreads/`;
export const PRICE_SPREAD_V2_LIST_URL = mainGroup =>
  `${BACKEND_URL}/prices/list/${mainGroup}/price-spreads/`;

export const PRICE_SPREADS_DOWNLOAD_URL = `${BASE_URL}/prices/series/price-spreads/download-latest-data/`;
export const PRICE_SPREADS_V2_DOWNLOAD_URL = productSlug =>
  `${BASE_URL}/prices/spreads/v2/${productSlug}/download-latest-data/`;

export const PRICE_SERIES_DETAILS_URL = slug =>
  `${WEBSITE_URL}/steel-prices/price-details/${slug}/`;

export const PRICE_SERIE_DETAILS_URL = slug =>
  `${WEBSITE_URL}/prices/details/${slug}/`;

export const PRICE_INDEXES_DETAILS_URL = (mainGroup, slug) =>
  `${WEBSITE_URL}/prices/list/${mainGroup}/price-indexes/${slug}/`;
