import _ from 'lodash';

import { Field, SelectField } from 'components';
import { EMAIL_TEMPLATE_TYPES } from '../../constants';

const OPTIONS = EMAIL_TEMPLATE_TYPES.map(emailTemplateType => ({
  key: emailTemplateType,
  value: emailTemplateType,
  label: _.startCase(emailTemplateType.replace('-', ' '))
}));

const EmailTemplateTypeSelect = ({ name, isCustom, ...rest }) => {
  return (
    <Field
      name={name || 'emailTemplateType'}
      component={SelectField}
      options={OPTIONS}
      {...rest}
    />
  );
};

export default EmailTemplateTypeSelect;
