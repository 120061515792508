import { requestSdk, post, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const newsletterDownloadPdf = async (newsletterId, data) => {
  const url = `${BASE_URL}/newsletters/daily/${newsletterId}/create-printable/`;

  return await requestSdk(() => post(url, data));
};

export const checkPdfStatus = async id => {
  const url = `${BASE_URL}/newsletters/daily/${id}/pdf-status/`;

  return await requestSdk(() => get(url));
};

export const downloadPdfApi = async id => {
  const url = `${BASE_URL}/newsletters/daily/${id}/pdf-download/`;

  return await requestSdk(() => get(url));
};
