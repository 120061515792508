import _ from 'lodash';
import * as yup from 'yup';

export const defaultInitialValues = {
  invoiceDate: null,
  invoicePaidOn: null,
  invoiceStatus: null,
  invoiceTermsAndConditions: null,
  eventTermsAndConditions: null,
  linkedInvoice: null,
  pipelineEngagement: null,
  pipelineForecastedDate: null,
  contactInfo: {
    name: '',
    email: '',
    companyName: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    postcode: '',
    province: '',
    country: null,
    contact: null,
    commission1: null,
    commission2: null
  },
  paymentInfo: {
    taxName: '',
    taxValue: 0,
    withVat: false,
    vat: '',
    poNumber: '',
    currency: null,
    paymentMethodType: null,
    validFor: '',
    bankAccount: null,
    creditNote: null,
    paymentReceived: null,
    type: null,
    months: '',
    combinedQuantity: 1,
    syndication: false
  }
};

export const getInitialValuesFromInvoice = invoice => ({
  invoiceDate: invoice.invoice_date,
  invoicePaidOn: invoice.invoice_paid_on,
  invoiceStatus: invoice.invoice_status,
  invoiceTermsAndConditions: invoice.invoice_terms_and_conditions,
  eventTermsAndConditions: invoice.event_terms_and_conditions,
  linkedInvoice: invoice.linked_invoice,
  // TODO: disable for prod deploy
  pipelineEngagement: invoice.pipeline_engagement,
  pipelineForecastedDate: invoice.pipeline_forecasted_date,
  contactInfo: {
    name: invoice.contact_info.name,
    email: invoice.contact_info.email,
    companyName: invoice.contact_info.company_name,
    phone: invoice.contact_info.phone,
    address1: invoice.contact_info.address_1,
    address2: invoice.contact_info.address_2,
    city: invoice.contact_info.city,
    postcode: invoice.contact_info.postcode,
    stateOrProvince: invoice.contact_info.state_or_province,
    country: _.get(invoice, 'contact_info.country.id', null),
    contact: _.get(invoice, 'contact_info.contact.id', null),
    commission1: _.get(invoice, 'contact_info.commission_1.id', null),
    commission2: _.get(invoice, 'contact_info.commission_2.id', null)
  },
  paymentInfo: {
    taxName: invoice.payment_info.tax_name,
    taxValue: invoice.payment_info.tax_value,
    withVat: invoice.payment_info.with_vat,
    vat: invoice.payment_info.vat,
    poNumber: invoice.payment_info.po_number,
    currency: _.get(invoice, 'payment_info.currency.id', null),
    paymentMethodType: invoice.payment_info.payment_method_type,

    validFor: invoice.payment_info.valid_for,
    bankAccount: invoice.payment_info.bank_account,
    creditNote: invoice.payment_info.credit_note,
    paymentReceived: invoice.payment_info.payment_received,
    type: invoice.payment_info.type,
    serviceDescription: invoice.payment_info.service_description,
    discountName: invoice.payment_info.discount_name,
    months: invoice.payment_info.months,
    combinedQuantity: invoice.payment_info.combined_quantity,
    syndication: invoice.payment_info.syndication
  }
});

export const invoiceUpdateSchema = yup.object().shape({
  invoiceStatus: yup
    .string()
    .nullable()
    .required('Invoice Status is required.'),
  // TODO: disable for prod deploy
  pipelineEngagement: yup
    .string()
    .nullable()
    .required('Pipeline engagement is required.'),
  pipelineForecastedDate: yup
    .string()
    .nullable()
    .required('Pipeline forecasted date is required.'),
  paymentInfo: yup.object().shape({
    taxValue: yup
      .number()
      .typeError('Tax value must be a number')
      .required('Tax value is required'),
    serviceDescription: yup.string().when('type', {
      is: 'Combined',
      then: schema => schema.required('Service description is required.')
    }),

    combinedQuantity: yup.string().when('type', {
      is: 'Combined',
      then: schema => schema.required('Number of users is required.')
    }),
    paymentReceived: yup
      .string()
      .nullable()
      .required('Payment received is required.')
  })
});
