import React from 'react';
import { Formik } from 'formik';
import { Form } from 'semantic-ui-react';

import { InputField, Field, CreateButton, FormRow, Header } from 'components';
import { notifySuccess } from 'utils/notifications';
import { handleFormErrors } from 'utils/forms';

import { mailshotAddLink } from './sdk';
import { mailshotLinkSchema } from './utils';

import styles from './styles.module.css';

class MailshotAddLink extends React.Component {
  addLink = async (values, actions) => {
    const { setSubmitting, setFieldError, resetForm } = actions;

    setSubmitting(true);

    const mailshot = this.props.mailshot.id;

    const { success, errors } = await mailshotAddLink(mailshot, values);

    setSubmitting(false);

    if (success) {
      notifySuccess('Link added successfully.');
      this.props.fetchMailshot();
      resetForm();
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  render() {
    const { isInGroup } = this.props;
    const initialValues = { link: '' };

    return (
      <>
        <Header as="h4">Create Link</Header>
        <Formik
          onSubmit={this.addLink}
          initialValues={initialValues}
          validationSchema={mailshotLinkSchema}>
          {({ handleSubmit, isSubmitting, isValid }) => (
            <Form>
              <FormRow>
                <Field
                  required
                  width={5}
                  name="link"
                  component={InputField}
                  disabled={isInGroup}
                />
                <div className={styles.createButtonContainer}>
                  <CreateButton
                    onClick={handleSubmit}
                    className={styles.createButton}
                    disabled={isSubmitting || isInGroup || !isValid}
                    subject="Link"
                  />
                </div>
              </FormRow>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default MailshotAddLink;
