import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const currencyList = async () => {
  const url = `${BASE_URL}/common/currencies/`;

  return await requestSdk(() => get(url));
};

export const marketDataReportCreate = async data => {
  const url = `${BASE_URL}/reports/market-data/create/`;

  return await requestSdk(() => post(url, data));
};
