import React from 'react';

import { withRouter } from 'react-router-dom';
import { Header, Segment } from 'semantic-ui-react';

import { Page, LoginRequired } from 'components';
import { PERMISSIONS, PermissionsRequired } from 'permissions';

import { EmailTemplateCreateForm } from './components';

class EmailTemplateCreate extends React.Component {
  render() {
    const { user, crumbs } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Header as="h1">Create Email template</Header>
              <EmailTemplateCreateForm />
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(
  PermissionsRequired([PERMISSIONS.CREATE_MAILSHOTS])(
    withRouter(EmailTemplateCreate)
  )
);
