import React from 'react';

import { pageUrl } from 'config/routes';

import { VideoGalleryCreate } from 'pages';

import {
  Page,
  Button,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Flex,
  Header
} from 'components';

import { videosList } from './sdk';

import { VideoGalleryTable } from './components';

class VideoGalleryList extends React.Component {
  render() {
    const {
      user,
      data,
      count,
      page,
      limit,
      sort,
      orderBy,
      changePage,
      changeRowsPerPage
    } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content>
            <Flex spaceBetween>
              <Header>Video Gallery</Header>
              <Button
                size="large"
                onClick={() =>
                  this.props.history.push(pageUrl(VideoGalleryCreate))
                }>
                Add Video
              </Button>
            </Flex>
            {data && (
              <>
                <VideoGalleryTable
                  data={data}
                  sort={sort}
                  orderBy={orderBy}
                  pagination={
                    <Pagination
                      limit={limit}
                      count={count}
                      page={page}
                      changePage={changePage}
                      changeRowsPerPage={changeRowsPerPage}
                    />
                  }
                />
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: videosList
})(LoginRequired(VideoGalleryList));
