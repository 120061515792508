import _ from 'lodash';
import React from 'react';

import { Field, SelectField } from 'components';

import { advertisementList } from './sdk';

class AdvertisementSelect extends React.Component {
  state = {
    options: []
  };

  async componentDidMount() {
    const { data, success } = await advertisementList();

    if (success) {
      this.setState({
        options: data.map(item => ({
          key: item.id,
          value: item.id,
          label: item.title,
          itemId: item.item_id
        }))
      });
    }
  }

  render() {
    const { name, excludeOptions = [], ...rest } = this.props;
    let options = this.state.options;

    if (!_.isEmpty(excludeOptions)) {
      options = _.filter(
        options,
        option => _.findIndex(excludeOptions, { id: option.itemId }) < 0
      );
    }

    return (
      <Field
        name={name || 'advertisement'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default AdvertisementSelect;
