import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  title: yup.string().required('This field is required.'),
  slug: yup.string().required('This field is required.'),
  currency: yup.string().required('This field is required.'),
  type: yup.string().required('This field is required.'),
  unit: yup.string().required('This field is required.'),
  groupId: yup.string().required('This field is required.')
});

export const DEFAULT_INITIAL_VALUES = {
  title: '',
  prices: [],
  isFree: false,
  slug: '',
  currency: '',
  type: '',
  unit: '',
  groupId: '',
  manual_components: [],
  activeInWebsite: false
};
