import { coerceStringToBoolean } from 'utils';

export const FILTERS = [
  {
    name: 'reason',
    emptyValue: ''
  },
  {
    name: 'batch_date',
    emptyValue: null
  },
  {
    name: 'is_new',
    emptyValue: '',
    transform: coerceStringToBoolean
  }
];
