import { DateTimeField } from 'components';

export const FILTERS = [
  [
    {
      name: 'attempt_time_from',
      emptyValue: null,
      component: DateTimeField,
      label: 'Attempt Time From',
      width: 4
    },
    {
      name: 'attempt_time_to',
      emptyValue: null,
      component: DateTimeField,
      label: 'Attempt Time To',
      width: 4
    }
  ]
];
