import React from 'react';

import { Table } from 'semantic-ui-react';

import { CorporateMasterAccount } from './components';

class CorporateMasterAccountsTable extends React.Component {
  render() {
    const { corporateProfiles, client, fetchClient } = this.props;

    return (
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>First Name</Table.HeaderCell>
            <Table.HeaderCell>Last Name</Table.HeaderCell>
            <Table.HeaderCell>Job Title</Table.HeaderCell>
            <Table.HeaderCell>E-mail</Table.HeaderCell>
            <Table.HeaderCell>City</Table.HeaderCell>
            <Table.HeaderCell>Country</Table.HeaderCell>
            <Table.HeaderCell>Subscription status</Table.HeaderCell>
            <Table.HeaderCell>Subscription expiry date</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {corporateProfiles.map(corporateProfile => (
            <CorporateMasterAccount
              key={corporateProfile.id}
              corporateProfile={corporateProfile}
              client={client}
              fetchClient={fetchClient}
            />
          ))}
        </Table.Body>
      </Table>
    );
  }
}

export default CorporateMasterAccountsTable;
