import _ from 'lodash';
import React from 'react';
import { arrayMoveImmutable } from 'array-move';

import { SortableList, UpdateButtonInRow, Flex } from 'components';

import { orderPermanentPrices } from './sdk';
import { notifySuccess } from 'utils/notifications';

class OrderPricesModal extends React.Component {
  state = {
    options: _.map(this.props.prices, price => ({
      ...price,
      title: price.price.title
    }))
  };

  confirmOrder = async () => {
    const data = { permanent_prices: _.map(this.state.options, 'id') };

    const { success } = await orderPermanentPrices(data);

    if (success) {
      notifySuccess('Prices are reordered successfully.');
      this.props.refetch();
      this.props.onClose();
    }
  };

  onSortEnd = async ({ item, oldIndex, newIndex }) => {
    this.setState(prevState => ({
      options: arrayMoveImmutable(prevState.options, oldIndex, newIndex)
    }));
  };

  render() {
    const { options } = this.state;

    return (
      <>
        {options && <SortableList items={options} onSortEnd={this.onSortEnd} />}
        <Flex flexEnd>
          <UpdateButtonInRow
            onClick={this.confirmOrder}
            subject="Prices Order"
          />
        </Flex>
      </>
    );
  }
}

export default OrderPricesModal;
