import _ from 'lodash';
import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

import { formatDateForApi } from 'utils';

import { getFirstAndLastDayOfMonthAndYear } from './utils';

export const invoicesList = async params => {
  if (
    !_.has(params, 'invoice_paid_date_from') &&
    !_.has(params, 'invoice_paid_date_to')
  ) {
    const invoicePaidMonth = _.get(params, 'invoice_paid_month', null);
    const invoicePaidYear = _.get(params, 'invoice_paid_year', null);

    let firstDay = null;
    let lastDay = null;

    if (_.isNil(invoicePaidMonth) && !_.isNil(invoicePaidYear)) {
      firstDay = getFirstAndLastDayOfMonthAndYear('1', invoicePaidYear)
        .firstDay;
      lastDay = getFirstAndLastDayOfMonthAndYear('12', invoicePaidYear).lastDay;
    }

    if (!_.isNil(invoicePaidMonth) && !_.isNil(invoicePaidYear)) {
      const periodData = getFirstAndLastDayOfMonthAndYear(
        invoicePaidMonth,
        invoicePaidYear
      );

      firstDay = periodData.firstDay;
      lastDay = periodData.lastDay;
    }

    if (!_.isNil(firstDay) && !_.isNil(lastDay)) {
      params['invoice_paid_date_from'] = formatDateForApi(firstDay);
      params['invoice_paid_date_to'] = formatDateForApi(lastDay);
    }
  }

  const url = `${BASE_URL}/crm/invoices/dashboard/`;

  return await requestSdk(() => get(url, params));
};
