import React from 'react';

import { Formik } from 'formik';

import {
  Field,
  DiscountSelect,
  InputField,
  UnsavedFormPrompt
} from 'components';

import { Table, Button } from 'semantic-ui-react';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { invoiceUpdateInvoiceItem, invoiceRemoveInvoiceItem } from './sdk';
import { invoiceItemUpdateSchema } from './utils';

class InvoiceItemForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { invoice, fetchInvoice, invoiceItem } = this.props;

    const { setSubmitting, setFieldError } = actions;

    const postData = {
      ...prepareFormData(values),
      invoice_item: invoiceItem.id
    };

    setSubmitting(true);

    const { success, errors } = await invoiceUpdateInvoiceItem(
      invoice.id,
      postData
    );
    setSubmitting(false);

    if (success) {
      notifySuccess('Invoice item updated.');
      fetchInvoice();
    }

    if (!success) {
      handleFormErrors(errors, setFieldError);
    }
  };

  removeInvoiceItem = async () => {
    const { invoice, fetchInvoice, invoiceItem } = this.props;

    const postData = {
      invoice_item: invoiceItem.id
    };

    const { success } = await invoiceRemoveInvoiceItem(invoice.id, postData);

    if (success) {
      notifySuccess('Invoice item deleted.');
      fetchInvoice();
    }
  };

  render() {
    const { invoiceItem } = this.props;

    const initialValues = { ...invoiceItem };

    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={invoiceItemUpdateSchema}>
        {({ handleSubmit, isSubmitting, dirty }) => (
          <>
            <UnsavedFormPrompt when={dirty} formName="Invoice item form" />
            <Table.Row>
              <Table.Cell width={4}>
                <Field
                  name="description"
                  component={InputField}
                  disabled={true}
                />
              </Table.Cell>
              <Table.Cell width={1}>
                <Field name="quantity" component={InputField} />
              </Table.Cell>
              <Table.Cell>
                <Field
                  name="months"
                  component={InputField}
                  disabled={!(invoiceItem.itemType === 'Subscription')}
                />
              </Table.Cell>
              <Table.Cell width={1}>
                <Field name="price" component={InputField} />
              </Table.Cell>
              <Table.Cell width={2}>
                <DiscountSelect name="discountType" />
              </Table.Cell>
              <Table.Cell>
                <Field name="discountName" component={InputField} />
              </Table.Cell>
              <Table.Cell width={1}>
                <Field name="discountValue" component={InputField} />
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                style={{ padding: '0.15em', minWidth: '130px' }}>
                <Button
                  color="blue"
                  onClick={handleSubmit}
                  type="submit"
                  disabled={isSubmitting}
                  style={{ padding: '10px 6px' }}>
                  Update
                </Button>
                <Button
                  basic
                  color="grey"
                  onClick={this.removeInvoiceItem}
                  type="submit"
                  disabled={isSubmitting}
                  style={{ padding: '10px 5px' }}>
                  Remove
                </Button>
              </Table.Cell>
            </Table.Row>
          </>
        )}
      </Formik>
    );
  }
}

export default InvoiceItemForm;
