import React from 'react';

import _ from 'lodash';
import { Header } from 'semantic-ui-react';

import { Page, ListViewEssentials, Flex } from 'components';

import { PERMISSIONS } from 'permissions';
import { withLoggedUser, withPermissions } from 'hooks';

import { PriceSpreadV2MovementTable } from './components';
import { priceSpreadV2MovementList } from './sdk';

const PriceSpreadV2Movement = ({ user, crumbs, data }) => {
  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content crumbs={crumbs}>
          <Flex spaceBetween>
            <Header as="h1">Prices Spread Movement</Header>
          </Flex>
          {data && <PriceSpreadV2MovementTable priceSpreads={data} />}
        </Page.Content>
      </Page.Body>
    </Page>
  );
};

export default ListViewEssentials({
  sdk: props => {
    const { productSlug } = props.match.params;
    return _.partial(priceSpreadV2MovementList, productSlug);
  },
  passPropsToSdk: true
})(
  withLoggedUser(
    withPermissions([PERMISSIONS.VIEW_PRICES])(PriceSpreadV2Movement)
  )
);
