import React, { useState } from 'react';
import _ from 'lodash';

import { useParams, useHistory } from 'react-router-dom';

import { Form, Header, Table, Segment } from 'semantic-ui-react';

import { Formik } from 'formik';

import { pageUrl } from 'config/routes';

import { WebArticleList } from 'pages';

import { POWER_MATERIAL_SLUG } from 'domainConstants';

import {
  Field,
  FastField,
  InputField,
  CheckboxField,
  FormRow,
  RichTextAreaField,
  CompanyNewSelect,
  FileField,
  TagSelect,
  DateTimeField,
  UnsavedFormPrompt,
  ErrorsAndCreateButtonInRow,
  UniSelect,
  WebArticlePricesUpdateModal,
  Tooltip,
  TextAreaField
} from 'components';

import { AdminSelect, CountrySelect, RegionSelect } from 'pages/Article/fields';

import { populateSlug, slugified } from 'utils';
import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';
import { prepareNewCompanies } from 'pages/WebArticle/utils';

import { articleCreateSchema } from './schema';
import { articleCreate } from './sdk';

function WebArticleCreateForm() {
  const { productSlug } = useParams();
  const history = useHistory();

  const [createPriceModalOpen, setCreatePriceModalOpen] = useState(false);
  const [createdArticle, setCreatedArticle] = useState(null);

  const onClosePricesModal = () => {
    setCreatePriceModalOpen(false);
    history.push(pageUrl(WebArticleList, { productSlug }));
  };

  const handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    var formData = prepareFormData(values);

    setSubmitting(true);
    // prepare companies data
    formData.new_companies = prepareNewCompanies(
      formData.related_companies,
      productSlug
    );
    formData.related_companies = _.filter(
      formData.related_companies,
      _.isInteger
    );

    formData.insert_in_newsletter_type_slug = 'daily';
    formData.insert_in_newsletter_sections = [
      'region-articles',
      'product-type-group-articles'
    ];

    const { errors, success, data } = await articleCreate(
      productSlug,
      formData
    );

    const article = {
      price_categories: data.price_categories,
      price_products: data.price_products,
      price_series_regions: data.price_series_regions
    };
    if (success) {
      notifySuccess('Article created.');
      setCreatedArticle(article);
      setCreatePriceModalOpen(true);
    } else {
      handleFormErrors(errors, setFieldError);
    }

    setSubmitting(false);
  };

  const { text, fullHeadline } = _.get(
    history.location.state,
    'sourceArticle',
    {}
  );

  const initialValues = {
    fullHeadline: fullHeadline || '',
    marketingHeadline: '',
    slug: !_.isEmpty(fullHeadline) ? slugified(fullHeadline) : '',
    text: text || '',
    summary: '',
    tableIcon: false,
    graphIcon: false,
    imageIcon: false,
    notRfp: true,
    embargoed: false,
    push: false,
    free: false,
    featured: false,
    relatedCompanies: [],
    regions: [],
    countries: [],
    authors: [],
    priceCategories: [],
    priceProducts: [],
    articleTopics: [],
    status: null,
    type: null,
    section: null,
    tags: [],
    image: null,
    publishDate: null,
    metaTitle: '',
    metaDescription: '',
    metaKeywords: '',
    metaStandout: '',
    byKallanishTeam: false,
    byKallanishAsia: false
  };

  const handleFreeChange = (value, setFieldValue) => {
    setFieldValue('free', value);

    // If `Free` is unmarked - unmark `Featured` automatically
    if (!value) {
      setFieldValue('featured', false);
    }
  };

  const handleFeaturedChange = (value, setFieldValue) => {
    setFieldValue('featured', value);

    // If `Featured` is marked - mark `Free` automatically
    if (value) {
      setFieldValue('free', true);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={articleCreateSchema}
      onSubmit={handleSubmit}>
      {({
        handleSubmit,
        isSubmitting,
        setFieldValue,
        values,
        dirty,
        errors,
        touched
      }) => (
        <Form>
          <UnsavedFormPrompt
            when={dirty && !isSubmitting && !createPriceModalOpen}
          />
          {createdArticle && createPriceModalOpen && (
            <WebArticlePricesUpdateModal
              productSlug={productSlug}
              article={createdArticle}
              caller={'article'}
              open={() => setCreatePriceModalOpen(true)}
              onClose={onClosePricesModal}
            />
          )}
          <ErrorsAndCreateButtonInRow
            errors={errors}
            touched={touched}
            onClick={handleSubmit}
            disabled={isSubmitting}
          />
          <FastField
            required
            name="fullHeadline"
            component={InputField}
            label="Full headline"
            onChange={(e, data) => {
              populateSlug(data, setFieldValue);
            }}
          />

          <FastField
            required
            name="marketingHeadline"
            component={InputField}
            label="Marketing headline"
          />

          <FormRow>
            <FastField
              width={4}
              name="publishDate"
              component={DateTimeField}
              label="Publish Date"
            />
            <FastField
              required
              width={4}
              name="slug"
              component={InputField}
              label="Slug"
            />
            <UniSelect
              width={4}
              name="type"
              label="Type"
              productSlug={productSlug}
              source="articleLabels"
              required
            />
            <UniSelect
              width={4}
              name="status"
              label="Status"
              productSlug={productSlug}
              source="articleStatus"
              required
            />
          </FormRow>
          <FormRow>
            <AdminSelect required width={8} name="authors" label="Authors" />
            <Tooltip
              on="hover"
              content="Display 'By Kallanish Team' in the authors section and hide all authors' names"
              trigger={
                <Field
                  width={2}
                  name="byKallanishTeam"
                  label="By Kallanish team"
                  component={CheckboxField}
                />
              }
            />
            <Tooltip
              on="hover"
              content="Display 'By Kallanish Asia' in the authors section and hide Asian authors' names"
              disabled={values.byKallanishTeam}
              trigger={
                <Field
                  width={2}
                  name="byKallanishAsia"
                  label="By Kallanish Asia"
                  component={CheckboxField}
                  disabled={values.byKallanishTeam}
                />
              }
            />
          </FormRow>
          <UniSelect
            width={8}
            name="articleTopics"
            label="Topics"
            productSlug={productSlug}
            source="articleTopics"
            transferList={true}
          />
          {productSlug === POWER_MATERIAL_SLUG && (
            <FastField
              name="summary"
              component={TextAreaField}
              label="Summary"
              config={{ height: '500px' }}
            />
          )}
          <FastField
            name="text"
            component={RichTextAreaField}
            label="Text"
            config={{ height: '500px' }}
          />

          <FormRow>
            <FastField
              width={2}
              name="tableIcon"
              component={CheckboxField}
              label="Table Icon"
            />

            <FastField
              width={2}
              name="graphIcon"
              component={CheckboxField}
              label="Graph Icon"
            />

            <FastField
              width={2}
              name="imageIcon"
              component={CheckboxField}
              label="Image Icon"
            />
          </FormRow>

          <FormRow>
            <Field width={4} name="image" component={FileField} label="Image" />
          </FormRow>
          <FormRow>
            <FastField
              width={3}
              name="notRfp"
              component={CheckboxField}
              label="Do not display RFP"
            />
            <Tooltip
              on="hover"
              content="Display this free article in the featured section in the website."
              trigger={
                <FastField
                  width={2}
                  name="featured"
                  component={CheckboxField}
                  label="Featured"
                  checked={values.featured}
                  onChange={(_unused, { checked }) =>
                    handleFeaturedChange(checked, setFieldValue)
                  }
                />
              }
            />
            <FastField
              width={2}
              name="free"
              component={CheckboxField}
              label="Free"
              checked={values.free}
              onChange={(_unused, { checked }) =>
                handleFreeChange(checked, setFieldValue)
              }
            />

            <FastField
              width={2}
              name="embargoed"
              component={CheckboxField}
              label="Embargoed"
            />

            <FastField
              name="push"
              component={CheckboxField}
              label="Push headline to website"
            />
          </FormRow>
          <FormRow>
            <UniSelect
              width={4}
              name="section"
              label="Publish in section"
              productSlug={productSlug}
              source="articleSections"
            />
            <UniSelect
              width={4}
              name="group"
              label="Publish in group"
              productSlug={productSlug}
              source="articleGroups"
            />
            <CompanyNewSelect
              fast={true}
              width={8}
              isMulti={true}
              isCreatable={true}
              name="relatedCompanies"
              label="Companies"
              productSlug={productSlug}
            />
          </FormRow>
          <RegionSelect width={8} name="regions" label="Regions" />
          <CountrySelect width={8} name="countries" label="Countries" />

          <UniSelect
            width={8}
            name="priceCategories"
            label="Prices"
            productSlug={productSlug}
            source="priceSerieCategories"
            transferList={true}
          />

          <UniSelect
            width={8}
            name="priceProducts"
            label="Products"
            productSlug={productSlug}
            source="priceSerieProducts"
            transferList={true}
          />

          <Header as="h3">Tags</Header>
          <FastField width={16} name="tags" component={TagSelect} />
          <Segment>
            <Header as="h2">Meta fields</Header>

            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Title</Table.HeaderCell>
                  <Table.HeaderCell>Description</Table.HeaderCell>
                  <Table.HeaderCell>Keywords</Table.HeaderCell>
                  <Table.HeaderCell>Standout</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <Field name="metaTitle" component={InputField} />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name="metaDescription" component={InputField} />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name="metaKeywords" component={InputField} />
                  </Table.Cell>
                  <Table.Cell>
                    <Field name="metaStandout" component={InputField} />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>

          <ErrorsAndCreateButtonInRow
            errors={errors}
            touched={touched}
            onClick={handleSubmit}
            disabled={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
}

export default WebArticleCreateForm;
