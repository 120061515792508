import * as yup from 'yup';

export const getInitialValues = poll => ({
  question: poll.poll.question,
  description: poll.poll.description,
  pollType: poll.poll_type,
  dateStart: poll.date_start,
  dateEnd: poll.date_end
});

export const pollSchema = yup.object().shape({
  question: yup.string().required('Question is required.'),
  pollType: yup.string().required('This field is required.'),

  dateStart: yup
    .date()
    .nullable()
    .required('This field is required'),
  dateEnd: yup
    .date()
    .nullable()
    .required('This field is required')
});
