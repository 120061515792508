import React from 'react';
import cx from 'classnames';

import SortableList from 'components/SortableList2';

import { prepareFormData } from 'utils/forms';
import { notifyErrors, notifySuccess } from 'utils/notifications';

import { subscriptionOrderBanner } from './sdk';

import BannerForm from './BannerForm';

import styles from './styles.module.scss';

class BannersTable extends React.Component {
  onSortEnd = async ({ item, oldIndex, newIndex }) => {
    const banner = item;
    const { subscription, fetchSubscription } = this.props;

    const data = {
      banner: banner.id,
      oldIndex,
      newIndex
    };

    const { success, errors } = await subscriptionOrderBanner(
      subscription.id,
      prepareFormData(data)
    );

    if (success) {
      fetchSubscription();
      notifySuccess('Banners reordered.');
    } else {
      notifyErrors(errors);
    }
  };

  render() {
    const {
      items,
      subscriptionStatuses,
      subscription,
      fetchSubscription
    } = this.props;

    return (
      <>
        <div className={styles.table}>
          <div className={cx(styles.tableRow, styles.header)}>
            <div className={cx(styles.tableCell, styles.header)}></div>
            <div className={cx(styles.tableCell, styles.header)}>
              Banner thumbnail
            </div>
            <div className={cx(styles.tableCell, styles.header)}>
              Image (200x73) file
            </div>
            <div className={cx(styles.tableCell, styles.header)}>URL</div>
            {subscriptionStatuses.map(status => (
              <div
                key={status.id}
                className={cx(styles.tableCell, styles.header)}>
                {status.title}
              </div>
            ))}
            <div className={cx(styles.tableCell, styles.header)} />
          </div>
          <SortableList
            onSortEnd={this.onSortEnd}
            items={items}
            useDragHandle={true}
            itemComponent={({ item }) => (
              <BannerForm
                banner={item}
                statuses={subscriptionStatuses}
                subscription={subscription}
                fetchSubscription={fetchSubscription}
              />
            )}
          />
        </div>
      </>
    );
  }
}

export default BannersTable;
