import React from 'react';
import _ from 'lodash';

import { Modal, Icon, Header, Form, Loader, Dimmer } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Flex,
  FormRow,
  UpdateButtonInRow,
  Field,
  InputField
} from 'components';

import { notifySuccess, notifyErrors } from 'utils/notifications';

import { clientsUpdateCompanyName } from './sdk';

class SelectCompanyNameModal extends React.Component {
  close = () => {
    this.props.onClose();
  };

  handleSubmit = async (values, { setSubmitting }) => {
    const confirm = window.confirm(
      `Are you sure you want to change the company name of these clients?`
    );

    if (!confirm) {
      setSubmitting(false);
      return;
    }

    setSubmitting(true);

    const { errors, success } = await clientsUpdateCompanyName({
      company_name: values.company_name,
      clients: this.props.selectedClients
    });

    if (success) {
      notifySuccess('Company name successfully changed.');
      this.props.onSuccess();
      this.close();
    } else {
      notifyErrors(errors);
    }
    setSubmitting(false);
  };

  render() {
    return (
      <Modal open={true} size="tiny" centered={false}>
        <Modal.Header>
          <Flex spaceBetween>
            <Header as="h2" style={{ margin: 0 }}>
              Change Company Name
            </Header>
            <Icon name="close" onClick={this.close} />
          </Flex>
        </Modal.Header>
        <Modal.Content>
          <Formik
            initialValues={{ company_name: '' }}
            onSubmit={this.handleSubmit}>
            {({ handleSubmit, isSubmitting, values }) => (
              <>
                <Form>
                  <FormRow>
                    <Field
                      name="company_name"
                      component={InputField}
                      label="Company Name"
                    />
                  </FormRow>

                  <UpdateButtonInRow
                    onClick={handleSubmit}
                    subject="Company Name"
                    disabled={isSubmitting || _.isEmpty(values.company_name)}
                  />
                </Form>

                {isSubmitting && (
                  <Dimmer active inverted>
                    <Loader inverted>Submitting</Loader>
                  </Dimmer>
                )}
              </>
            )}
          </Formik>
        </Modal.Content>
      </Modal>
    );
  }
}

export default SelectCompanyNameModal;
