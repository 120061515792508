import React from 'react';

import { withRouter } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  InputField,
  FormRow,
  UpdateButtonInRow,
  RegionSelect,
  UnsavedFormPrompt
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { countryUpdateSchema } from './utils';
import { countryUpdate } from './sdk';

class CountryUpdateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { country } = this.props;

    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await countryUpdate(country.id, data);

    setSubmitting(false);

    if (success) {
      notifySuccess('Country updated.');
      this.props.fetchCountry();
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  render() {
    const { country } = this.props;

    const initialRegion = country.region ? country.region.id : null;

    const initialValues = {
      title: country.title,
      region: initialRegion
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={countryUpdateSchema}
        onSubmit={this.handleSubmit}>
        {({ values, handleSubmit, isSubmitting, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty} />

            <UpdateButtonInRow onClick={handleSubmit} disabled={isSubmitting} />
            <FormRow>
              <Field
                width={4}
                name="title"
                label="Title"
                required
                component={InputField}
              />
              <RegionSelect width={4} name="region" label="Region" />
            </FormRow>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(CountryUpdateForm);
