import React from 'react';

import { BASE_BACKEND_URL } from 'sdk/urls';

const ExternalLink = ({ url, children }) => {
  let linkUrl = url;

  if (linkUrl.startsWith('/')) {
    linkUrl = `${BASE_BACKEND_URL}${linkUrl}`;
  }

  return (
    <a href={linkUrl} target="_blank" rel="noopener noreferrer">
      {children || linkUrl}
    </a>
  );
};

export default ExternalLink;
