import React from 'react';

import { Field, SelectField } from 'components';

const FORMATS = ['Single Price', 'Range'];

class PriceSeriesFormatSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = FORMATS.map(format => {
      return {
        key: format,
        value: format,
        label: format
      };
    });

    return (
      <Field
        name={name || 'priceSeriesFormat'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default PriceSeriesFormatSelect;
