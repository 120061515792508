import React from 'react';

import { Header, Segment } from 'semantic-ui-react';

import { useParams } from 'react-router-dom';
import { getProductName } from 'utils';
import { Page } from 'components';

import { PERMISSIONS } from 'permissions';
import { withLoggedUser, withPermissions } from 'hooks';

import NewsletterCreateForm from './form';

function NewsletterCreate({ user, crumbs }) {
  const { productSlug } = useParams();

  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content crumbs={crumbs}>
          <Segment>
            <Header as="h1">
              Create Daily {getProductName(productSlug)} Newsletter
            </Header>
            <NewsletterCreateForm productSlug={productSlug} />
          </Segment>
        </Page.Content>
      </Page.Body>
    </Page>
  );
}

export default withLoggedUser(
  withPermissions([PERMISSIONS.CREATE_NEWSLETTERS])(NewsletterCreate)
);
