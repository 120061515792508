import React from 'react';
import { arrayMoveImmutable } from 'array-move';

import { Modal, Icon, Header } from 'semantic-ui-react';

import { SortableList, UpdateButtonInRow, Flex } from 'components';

import { addTitleToOptions } from './utils';

class OrderSelectedOptionsModal extends React.Component {
  state = {
    options: addTitleToOptions(this.props.options)
  };

  close = () => {
    this.props.onClose();
  };

  confirmOrder = () => {
    this.props.orderSelectedOptions(this.state.options);
  };

  onSortEnd = async ({ item, oldIndex, newIndex }) => {
    this.setState(prevState => ({
      options: arrayMoveImmutable(prevState.options, oldIndex, newIndex)
    }));
  };

  render() {
    const { options } = this.state;
    const { label } = this.props;

    return (
      <Modal open={true} size="large" centered={false}>
        <Modal.Header>
          <Flex spaceBetween>
            <Header as="h2">Order {label}</Header>
            <Icon name="close" onClick={this.close} />
          </Flex>
        </Modal.Header>
        <Modal.Content>
          {options && (
            <SortableList items={options} onSortEnd={this.onSortEnd} />
          )}
          <Flex flexEnd>
            <UpdateButtonInRow
              onClick={this.confirmOrder}
              subject={`${label} Order`}
            />
          </Flex>
        </Modal.Content>
      </Modal>
    );
  }
}

export default OrderSelectedOptionsModal;
