import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const marketDataReportDetail = async id => {
  const url = `${BASE_URL}/reports/market-data/${id}/`;

  return await requestSdk(() => get(url));
};

export const marketDataReportUpdateMeta = async (marketDataReportId, data) => {
  const url = `${BASE_URL}/reports/market-data/${marketDataReportId}/update-meta/`;

  return await requestSdk(() => post(url, data));
};
