import React from 'react';
import { Table } from 'semantic-ui-react';

const ImportClientsHeaderRow = ({ hasLineNumbers }) => (
  <Table.Header>
    <Table.Row>
      {hasLineNumbers && <Table.HeaderCell>1</Table.HeaderCell>}
      <Table.HeaderCell>Username</Table.HeaderCell>
      <Table.HeaderCell>Email address</Table.HeaderCell>
      <Table.HeaderCell>Priority</Table.HeaderCell>
      <Table.HeaderCell>Admin comments</Table.HeaderCell>
      <Table.HeaderCell>Title</Table.HeaderCell>
      <Table.HeaderCell>First name</Table.HeaderCell>
      <Table.HeaderCell>Last name</Table.HeaderCell>
      <Table.HeaderCell>Job title</Table.HeaderCell>
      <Table.HeaderCell>Company name</Table.HeaderCell>
      <Table.HeaderCell>Address 1</Table.HeaderCell>
      <Table.HeaderCell>Address 2</Table.HeaderCell>
      <Table.HeaderCell>City (town)</Table.HeaderCell>
      <Table.HeaderCell>Postcode</Table.HeaderCell>
      <Table.HeaderCell>State or province</Table.HeaderCell>
      <Table.HeaderCell>Country</Table.HeaderCell>
      <Table.HeaderCell>State</Table.HeaderCell>
      <Table.HeaderCell>Website</Table.HeaderCell>
      <Table.HeaderCell>Phone</Table.HeaderCell>
      <Table.HeaderCell>Fax</Table.HeaderCell>
      <Table.HeaderCell>Mobile</Table.HeaderCell>
      <Table.HeaderCell>LinkedIn</Table.HeaderCell>
      <Table.HeaderCell>Email type</Table.HeaderCell>
      <Table.HeaderCell>Account Manager</Table.HeaderCell>
      <Table.HeaderCell>Business Activity</Table.HeaderCell>
      <Table.HeaderCell>Commodity</Table.HeaderCell>
      <Table.HeaderCell>Industry</Table.HeaderCell>
      <Table.HeaderCell>Sub-Industry</Table.HeaderCell>
      <Table.HeaderCell>Tags</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
);

export default ImportClientsHeaderRow;
