import React from 'react';

import _ from 'lodash';
import { Formik } from 'formik';
import { Form } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';
import { SHORT_TRIAL_STATUS_ID, TRIAL_STATUS_ID } from 'pages/Client/constants';
import { EMAIL_TEMPLATE_TRIGGER } from 'pages/EmailTemplate/constants';

import {
  Field,
  FormRow,
  RichTextAreaField,
  TextAreaField,
  InputField,
  EmailTemplateStatusSelect,
  EmailTriggerSelect,
  SubscriptionSelect,
  SubscriptionStatusSelect,
  EmailTriggerConditionPeriodSelect,
  EmailTriggerSendWhenSelect,
  UnsavedFormPrompt,
  ErrorsAndUpdateButtonInRow,
  CreatedUpdatedFormValues,
  EmailTemplateTypeSelect
} from 'components';

import { emailTemplateUpdate } from './sdk';
import { emailTemplateUpdateSchema } from './schema';

class EmailTemplateUpdateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const data = _.pickBy(values, _.negate(_.isNil));

    const { fetchEmailTemplate } = this.props;
    const { emailTemplateId } = this.props.match.params;

    const { setSubmitting, setFieldError } = actions;

    const { errors, success } = await emailTemplateUpdate(
      emailTemplateId,
      prepareFormData(data)
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Email Template has been updated successfully.');
      fetchEmailTemplate();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const {
      status,
      trigger,
      template_type: templateType,
      from_name: fromName,
      from_email: fromEmail,
      mobile_notification: mobileNotification,
      title,
      subject,
      html,
      plain_text: plainText,
      css,
      created_by: createdBy,
      created_at: createdAt,
      updated_by: updatedBy,
      updated_at: updatedAt,
      condition_period: conditionPeriod,
      send_when: sendWhen,
      subscription,
      days,
      condition
    } = this.props.data;

    const initialValues = {
      status,
      trigger,
      templateType,
      fromName,
      fromEmail,
      mobileNotification,
      title,
      subject,
      html,
      plainText,
      css,
      subscription,
      days,
      conditionPeriod,
      sendWhen,
      condition
    };

    const nonFormValues = {
      createdBy,
      createdAt,
      updatedBy,
      updatedAt
    };

    return (
      <Formik
        enableReinitialize={true}
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        validationSchema={emailTemplateUpdateSchema}>
        {({ values, handleSubmit, isSubmitting, dirty, errors, touched }) => (
          <Form>
            <UnsavedFormPrompt when={dirty} />
            <FormRow widths="equal">
              <EmailTemplateStatusSelect
                required
                name="status"
                label="Status"
              />

              <EmailTriggerSelect required name="trigger" label="Trigger" />
              <EmailTemplateTypeSelect
                required
                name="templateType"
                label="Type"
              />
            </FormRow>
            {(values.trigger === EMAIL_TEMPLATE_TRIGGER.CUSTOM ||
              values.trigger === EMAIL_TEMPLATE_TRIGGER.WELCOME_EMAIL) && (
              <FormRow widths="equal">
                <Field
                  label="Subscription"
                  name="subscription"
                  component={SubscriptionSelect}
                />
                {values.trigger === EMAIL_TEMPLATE_TRIGGER.CUSTOM && (
                  <>
                    <Field label="Days" name="days" component={InputField} />
                    <Field
                      label="Condition Period"
                      name="conditionPeriod"
                      component={EmailTriggerConditionPeriodSelect}
                    />
                    <Field
                      label="Send when"
                      name="sendWhen"
                      component={EmailTriggerSendWhenSelect}
                    />
                  </>
                )}

                <Field
                  label="Condition"
                  name="condition"
                  component={SubscriptionStatusSelect}
                  {...(values.trigger ===
                    EMAIL_TEMPLATE_TRIGGER.WELCOME_EMAIL && {
                    only: [SHORT_TRIAL_STATUS_ID, TRIAL_STATUS_ID]
                  })}
                />
              </FormRow>
            )}
            <FormRow widths="equal">
              <Field
                required
                component={InputField}
                name="fromName"
                label="From name"
              />

              <Field
                required
                component={InputField}
                type="email"
                name="fromEmail"
                label="From email"
              />
            </FormRow>
            <Field required component={InputField} name="title" label="Title" />
            <Field
              required
              component={InputField}
              name="subject"
              label="Subject"
            />
            <Field
              component={InputField}
              name="mobileNotification"
              label="Mobile notification"
            />

            <Field
              required
              component={RichTextAreaField}
              name="html"
              label="HTML"
            />
            <Field
              required
              component={TextAreaField}
              name="plainText"
              label="Plain text"
            />
            <Field component={TextAreaField} name="css" label="CSS" />
            <CreatedUpdatedFormValues values={nonFormValues} />
            <ErrorsAndUpdateButtonInRow
              errors={errors}
              touched={touched}
              subject="Email Template"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(EmailTemplateUpdateForm);
