import React from 'react';

import { Header, Segment } from 'semantic-ui-react';

import { Page } from 'components';

import { withLoggedUser, withPermissions } from 'hooks';

import { PERMISSIONS } from 'permissions';

import { PriceSeriesCreateForm } from './components';

function PriceSerieCreate({ user, crumbs }) {
  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content crumbs={crumbs}>
          <Segment>
            <Header as="h1">Create Price Series</Header>
            <PriceSeriesCreateForm />
          </Segment>
        </Page.Content>
      </Page.Body>
    </Page>
  );
}

export default withLoggedUser(
  withPermissions([PERMISSIONS.CREATE_PRICES])(PriceSerieCreate)
);
