import React from 'react';

import _ from 'lodash';

import { Field, SelectField } from 'components';

import { priceListForPermanentPrices } from './sdk';

class PriceSeriesSingleSelectForSteelNewsletters extends React.Component {
  state = {
    priceSeries: []
  };

  async componentDidMount() {
    const { data, success } = await priceListForPermanentPrices();

    if (success) {
      this.setState({ priceSeries: data });
    }
  }

  render() {
    const {
      name,
      isPowerMaterial,
      excludeOptions = null,
      ...rest
    } = this.props;
    const { priceSeries } = this.state;

    let priceSeriesOptions = priceSeries;

    if (excludeOptions) {
      priceSeriesOptions = _.filter(
        priceSeriesOptions,
        option => _.indexOf(excludeOptions, option.id) < 0
      );
    }

    const options = priceSeriesOptions.map(singlePriceSeries => {
      return {
        key: singlePriceSeries.id,
        value: singlePriceSeries.id,
        label: singlePriceSeries.display_as
      };
    });

    return (
      <>
        <Field
          name={name || 'priceSeries'}
          component={SelectField}
          options={options}
          {...rest}
        />
      </>
    );
  }
}

export default PriceSeriesSingleSelectForSteelNewsletters;
