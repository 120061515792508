import React from 'react';

import _ from 'lodash';

import { Field, AsyncSelectField } from 'components';

import { energyTagList } from './sdk';

class EnergyTagSelect extends React.Component {
  state = {
    energyTags: []
  };

  fetchTags = async (search, callback = null) => {
    const { data, success } = _.isNil(search)
      ? await energyTagList()
      : await energyTagList(search);

    let energyTags = [];

    if (success) {
      energyTags = _.get(data, 'results', []).map(tag => {
        return {
          key: tag.id,
          value: tag.id,
          label: tag.name
        };
      });
      this.setState({ energyTags });
    }

    if (!_.isNil(callback)) {
      callback(energyTags);
    }

    return energyTags;
  };

  async componentDidMount() {
    const { customEnergyTags } = this.props;

    if (!_.isNil(customEnergyTags)) {
      this.setState({
        energyTags: customEnergyTags
      });

      return;
    }

    await this.fetchTags();
  }

  render() {
    const { name, ...rest } = this.props;
    const { energyTags } = this.state;

    return (
      <Field
        name={name || 'energyTag'}
        component={AsyncSelectField}
        isClearable
        defaultOptions={energyTags}
        loadOptions={_.debounce(this.fetchTags, 700)}
        {...rest}
      />
    );
  }
}

export default EnergyTagSelect;
