import React, { useState } from 'react';
import { Formik } from 'formik';

import { pageUrl } from 'config/routes';

import { useHistory } from 'react-router-dom';

import { useParams } from 'react-router-dom';

import { Form, Divider } from 'semantic-ui-react';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { populateSlug } from 'utils';
import { notifySuccess } from 'utils/notifications';

import {
  Field,
  InputField,
  FormRow,
  ErrorsAndCreateButtonInRow,
  UnsavedFormPrompt,
  UniSelect,
  YesNoSelect,
  PriceIndexesTable,
  IndexTypeSelect,
  CheckboxField
} from 'components';

import { priceIndexesCreate } from './sdk';

import { DEFAULT_INITIAL_VALUES, validationSchema } from './utils';

import { PriceIndexList } from 'pages';

function PriceIndexesCreateForm() {
  const { productSlug } = useParams();
  const [choices, setChoices] = useState([]);
  const [additionalOptions, setAdditionalOptions] = useState([]);
  const [totalPercent, setTotalPercent] = useState(0);
  const [currencies, setCurrencies] = useState([]);
  const [units, setUnits] = useState([]);
  let history = useHistory();

  const handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    if (totalPercent !== 100) {
      setSubmitting(false);
      return;
    }
    const prices = values.prices.map(selectedOptionId => ({
      price: selectedOptionId,
      percent: values[`percent_${selectedOptionId}`]
    }));

    const manual_components = additionalOptions.map(option => ({
      name: option.manual_component_name,
      value: option.manual_component_value,
      percent: option.percent
    }));
    const cleanedValues = { ...values };
    Object.keys(cleanedValues).forEach(key => {
      if (key.startsWith('percent_')) {
        delete cleanedValues[key];
      }
    });
    const data = {
      ...prepareFormData({
        ...cleanedValues,
        prices,
        manual_components
      })
    };

    setSubmitting(true);

    const { errors, success } = await priceIndexesCreate(productSlug, data);

    if (success) {
      notifySuccess('Price Index created.');
      return history.push(
        pageUrl(PriceIndexList, { productSlug: productSlug })
      );
    }

    handleFormErrors(errors, setFieldError);
    setSubmitting(false);
  };
  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={DEFAULT_INITIAL_VALUES}
      validationSchema={validationSchema}>
      {({
        isSubmitting,
        handleSubmit,
        setFieldValue,
        values,
        dirty,
        touched,
        errors
      }) => (
        <Form>
          <UnsavedFormPrompt when={dirty && !isSubmitting} />
          <ErrorsAndCreateButtonInRow
            errors={errors}
            touched={touched}
            onClick={handleSubmit}
            disabled={isSubmitting}
          />
          <FormRow>
            <Field
              width={7}
              name="activeInWebsite"
              label="Show on home page"
              component={CheckboxField}
            />
          </FormRow>
          <FormRow>
            <Field
              required
              width={7}
              name="title"
              label="Title:"
              component={InputField}
              onChange={(e, data) => {
                populateSlug(data, setFieldValue);
              }}
            />
            <Field width={7} name="slug" label="Slug:" component={InputField} />
          </FormRow>
          <FormRow>
            <UniSelect
              required
              width={7}
              name="currency"
              label="Currency:"
              source="priceSerieCurrencies"
              apiSuccessCallback={currencies => {
                setCurrencies(currencies);
              }}
            />
            <IndexTypeSelect
              width={7}
              required
              name="type"
              label="Index Type:"
            />
          </FormRow>
          <FormRow>
            <Field
              width={7}
              name="isFree"
              required
              component={YesNoSelect}
              isClearable={false}
              label="Is Free:"
            />

            <UniSelect
              required
              width={7}
              name="unit"
              label="Unit:"
              source="priceSerieUnits"
              apiSuccessCallback={units => {
                setUnits(units);
              }}
            />
          </FormRow>
          <FormRow>
            <UniSelect
              required
              width={7}
              name="groupId"
              label="Category:"
              source="priceSerieCategories"
              productSlug={productSlug}
            />
          </FormRow>
          <FormRow>
            <UniSelect
              name="prices"
              label="Price series"
              isMulti
              closeMenuOnSelect={false}
              transferList={true}
              source="priceSeries"
              productSlug={productSlug}
              apiSuccessCallback={choices => {
                setChoices(choices);
              }}
            />
          </FormRow>
          <FormRow>
            <PriceIndexesTable
              name="manualPrices"
              values={values}
              choices={choices}
              additionalOptions={additionalOptions}
              setAdditionalOptions={setAdditionalOptions}
              setTotalPercent={setTotalPercent}
              currencies={currencies}
              units={units}
            />
          </FormRow>
          <FormRow>
            {totalPercent !== 100 && (
              <div
                style={{
                  color: 'red',
                  margin: '15px',
                  justifyContent: 'flex-end'
                }}>
                Total percentage should be 100%
              </div>
            )}
          </FormRow>
          <Divider />
          <FormRow widths="equal">
            <Field name="metaTitle" label="Meta Title" component={InputField} />

            <Field
              name="metaKeywords"
              label="Meta Keywords"
              component={InputField}
            />

            <Field
              name="metaDescription"
              label="Meta Description"
              component={InputField}
            />

            <Field
              name="metaStandout"
              label="Meta Standout"
              component={InputField}
            />
          </FormRow>
          <ErrorsAndCreateButtonInRow
            errors={errors}
            touched={touched}
            onClick={handleSubmit}
            disabled={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
}

export default PriceIndexesCreateForm;
