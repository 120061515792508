import _ from 'lodash';

export const getValueDateAsObj = value => {
  if (!value) {
    return null;
  }

  const dateObj = new Date(value);

  return !_.startsWith(dateObj, 'Invalid') ? dateObj : null;
};
