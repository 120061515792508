import React from 'react';

import cx from 'classnames';

import styles from './styles.module.css';

const Flex = ({
  className,
  children,
  spaceBetween = false,
  flexStart = false,
  flexEnd = false,
  alignStart = false,
  alignEnd = false,
  style = {}
}) => (
  <div
    className={cx(
      styles.Flex,
      { [styles.spaceBetween]: spaceBetween },
      { [styles.flexStart]: flexStart },
      { [styles.flexEnd]: flexEnd },
      { [styles.alignStart]: alignStart },
      { [styles.alignEnd]: alignEnd },
      className
    )}
    style={style}>
    {children}
  </div>
);

export default Flex;
