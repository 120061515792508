import * as yup from 'yup';
import _ from 'lodash';
import { prepareFormData } from 'utils/forms';

const getMetaFieldData = (fields, type) =>
  _.get(
    _.find(fields, item => item.meta_field_type.meta_type === type),
    'meta_data',
    ''
  );

export const getMetaFields = data => ({
  metaTitle: getMetaFieldData(data.price_index_fields, 'Title'),
  metaDescription: getMetaFieldData(
    data.price_index_fields,
    'Meta Description'
  ),
  metaKeywords: getMetaFieldData(data.price_index_fields, 'Meta Keywords'),
  metaStandout: getMetaFieldData(data.price_index_fields, 'Meta Standout')
});

export const getInitialValues = data => ({
  activeInWebsite: data.active_in_website,
  title: data.title,
  slug: data.slug,
  currency: data.currency.id,
  is_free: data.is_free,
  type: data.type,
  unit: data.unit.id,
  groupId: data.group_id,
  prices: _.chain(data.prices)
    .filter({ type: 'price' })
    .map('price_id')
    .value(),
  updatedAt: data.updated_at,
  updatedBy: data.updated_by,
  createdAt: data.created_at,
  createdBy: data.created_by,
  ...getMetaFields(data)
});

export const preparePostData = data => ({
  ...prepareFormData(data)
});

export const validationSchema = yup.object().shape({
  title: yup.string().required('This field is required.'),
  currency: yup
    .string()
    .nullable()
    .required('This field is required.'),
  slug: yup.string().required('This field is required.'),
  type: yup.string().required('This field is required.'),
  unit: yup.string().required('This field is required.'),
  groupId: yup.string().required('This field is required.')
});
