import React from 'react';
import _ from 'lodash';

import { withRouter } from 'react-router-dom';

import {
  Page,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Flex,
  NoDataMessage,
  Filters as SharedFilters,
  Button
} from 'components';

import { PERMISSIONS, PermissionsRequired } from 'permissions';

import { MailshotGroupTable } from './components';
import { FILTERS } from './utils';

import { mailshotGroupList } from './sdk';
import { pageUrl } from 'config/routes';

import { MailshotList } from 'pages/Mailshot';
import { MailshotGroupCreate } from '..';

const MailshotGroupList = ({
  user,
  data,
  count,
  page,
  limit,
  filters,
  sort,
  filterBy,
  orderBy,
  clear,
  changePage,
  changeRowsPerPage,
  history
}) => (
  <Page user={user}>
    <Page.Body>
      <Page.Content>
        <Flex spaceBetween>
          <Header as="h1">Mailshot Groups</Header>
          <Flex>
            <Button
              color="grey"
              size="large"
              onClick={() => history.push(pageUrl(MailshotList))}>
              View Mailshots
            </Button>
            <Button
              size="large"
              onClick={() => history.push(pageUrl(MailshotGroupCreate))}>
              Create Mailshot Group
            </Button>
          </Flex>
        </Flex>
        {data && (
          <>
            <SharedFilters
              filters={filters}
              clearFilters={clear}
              filterBy={filterBy}
              layout={FILTERS}
              count={count}
            />
            {_.isEmpty(data) && <NoDataMessage />}
            {!_.isEmpty(data) && (
              <MailshotGroupTable
                mailshotGroups={data}
                sort={sort}
                orderBy={orderBy}
                pagination={
                  <Pagination
                    limit={limit}
                    count={count}
                    page={page}
                    changePage={changePage}
                    changeRowsPerPage={changeRowsPerPage}
                  />
                }
              />
            )}
          </>
        )}
      </Page.Content>
    </Page.Body>
  </Page>
);

export default withRouter(
  ListViewEssentials({
    sdk: mailshotGroupList
  })(
    LoginRequired(
      PermissionsRequired([PERMISSIONS.VIEW_MAILSHOTS])(
        withRouter(MailshotGroupList)
      )
    )
  )
);
