import _ from 'lodash';

const calculateVotesCount = (choice, vote_set) =>
  _.filter(vote_set, vote => vote.choice === choice).length;

const calculatePercentage = (votesCount, vote_set) =>
  !_.isEmpty(vote_set) ? ((votesCount / vote_set.length) * 100).toFixed(2) : 0;

export const getResults = ({ choice_set, vote_set }) =>
  _.map(choice_set, choice => {
    let votesCount = calculateVotesCount(choice.id, vote_set);

    return {
      ...choice,
      votesCount,
      percentage: calculatePercentage(votesCount, vote_set)
    };
  });
