import React from 'react';

import { Formik } from 'formik';

import { Modal, Icon, Header, Form } from 'semantic-ui-react';

import {
  Button,
  FormRow,
  InputField,
  FileManagerLink,
  Field
} from 'components';

import { fileSelectSchema } from './schema';

import styles from './styles.module.css';

const REGEX = /\/admin\/filer\/(file|image)\/(?<fileId>\d+)\/change\//;
const EXAMPLE_URL =
  'https://example.kallanish.com/admin/filer/file/7363/change/';

const ERROR = 'Given URL does not match a filer URL.';

const parseFileId = url => {
  const match = url.match(REGEX);

  if (!match) {
    return false;
  }

  const {
    groups: { fileId }
  } = match;

  if (!fileId) {
    return false;
  }

  return fileId;
};

class FilerSelectModal extends React.Component {
  close = () => {
    this.props.onClose();
  };

  handleSubmit = (values, actions) => {
    const { setFieldError, setSubmitting } = actions;

    setSubmitting(true);

    const fileId = parseFileId(values.file);

    if (!fileId) {
      setFieldError('file', ERROR);
      setSubmitting(false);
      return;
    }

    setSubmitting(false);

    const { onFileSelect } = this.props;

    onFileSelect(fileId);

    this.close();
  };

  validateFile = value => {
    const fileId = parseFileId(value);

    if (!fileId) {
      return ERROR;
    }
  };

  render() {
    const initialValues = {
      file: ''
    };

    return (
      <Modal open={true} size="small" centered={false}>
        <Modal.Header>
          <div className={styles.header}>
            <Header as="h2">Select file</Header>
            <Icon name="close" onClick={this.close} />
          </div>
        </Modal.Header>
        <Modal.Content>
          <p>
            Paste URL from the <FileManagerLink>file manager</FileManagerLink>.
            Example:<strong>{EXAMPLE_URL}</strong>
          </p>
          <Formik
            enableReinitialize={true}
            validationSchema={fileSelectSchema}
            onSubmit={this.handleSubmit}
            initialValues={initialValues}>
            {({
              values,
              handleSubmit,
              isSubmitting,
              isValid,
              setFieldError
            }) => (
              <Form>
                <FormRow>
                  <Field
                    width={6}
                    label="File URL"
                    name="file"
                    component={InputField}
                    validate={this.validateFile}
                  />
                  <Button
                    disabled={isSubmitting || !isValid}
                    type="submit"
                    color="blue"
                    onClick={handleSubmit}>
                    Select
                  </Button>
                </FormRow>
              </Form>
            )}
          </Formik>
        </Modal.Content>

        <Modal.Actions>
          <Button color="red" onClick={this.close}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default FilerSelectModal;
