import React from 'react';

import { withRouter } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { FaqCreate } from 'pages';

import {
  Page,
  Flex,
  Header,
  LoginRequired,
  ListViewEssentials,
  Button,
  Filters as SharedFilters,
  Pagination
} from 'components';

import { faqList } from './sdk';

import { FaqTable } from './components';

import { FILTERS } from './utils';

class FaqList extends React.Component {
  onFaqCreateClick = () => {
    const url = pageUrl(FaqCreate);
    this.props.history.push(url);
  };

  render() {
    const {
      user,
      data,
      sort,
      orderBy,
      crumbs,
      filters,
      clear,
      filterBy,
      count,
      page,
      changePage,
      changeRowsPerPage,
      limit,
      refetch
    } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Flex spaceBetween>
              <Header>FAQs</Header>
              <Button onClick={this.onFaqCreateClick}>Create</Button>
            </Flex>
            {data && (
              <>
                <SharedFilters
                  filters={filters}
                  clearFilters={clear}
                  filterBy={filterBy}
                  layout={FILTERS}
                  count={count}
                />

                <FaqTable
                  faqs={data}
                  sort={sort}
                  orderBy={orderBy}
                  refetch={refetch}
                  pagination={
                    <Pagination
                      limit={limit}
                      count={count}
                      page={page}
                      changePage={changePage}
                      changeRowsPerPage={changeRowsPerPage}
                    />
                  }
                />
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: faqList
})(LoginRequired(withRouter(FaqList)));
