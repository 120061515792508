import React from 'react';

import { withRouter } from 'react-router-dom';

import { Form, Header, Segment, Button, Icon } from 'semantic-ui-react';

import { Formik, FieldArray } from 'formik';

import { pageUrl } from 'config/routes';

import { PollList } from 'pages';

import {
  Field,
  FormRow,
  InputField,
  CreateButtonInRow,
  DateTimeField,
  PollTypeSelect,
  Page,
  LoginRequired,
  UnsavedFormPrompt
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { pollCreate } from './sdk';

import { INITIAL_VALUES, pollSchema, filterEmptyChoices } from './utils';

class PollCreateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData({
      ...values,
      choices: filterEmptyChoices(values.choices)
    });

    setSubmitting(true);

    const { errors, success } = await pollCreate(data);

    if (success) {
      notifySuccess('Poll created.');
      this.props.history.push(pageUrl(PollList));
      setSubmitting(false);
      return;
    } else {
      handleFormErrors(errors, setFieldError);
      setSubmitting(false);
    }
  };

  render() {
    const { user, crumbs } = this.props;
    const initialValues = INITIAL_VALUES;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Header>Create Poll</Header>
              <Formik
                initialValues={initialValues}
                validationSchema={pollSchema}
                onSubmit={this.handleSubmit}>
                {({ values, handleSubmit, isSubmitting, dirty }) => (
                  <Form>
                    <UnsavedFormPrompt when={dirty && !isSubmitting} />

                    <Field
                      required
                      width={16}
                      name="question"
                      component={InputField}
                      label="Question"
                    />

                    <Field
                      width={16}
                      name="description"
                      component={InputField}
                      label="Description"
                    />

                    <FormRow>
                      <PollTypeSelect
                        width={4}
                        required
                        name="pollType"
                        label="Poll type"
                      />
                      <Field
                        required
                        width={4}
                        name="dateStart"
                        component={DateTimeField}
                        label="Date start:"
                      />

                      <Field
                        required
                        width={4}
                        name="dateEnd"
                        component={DateTimeField}
                        label="Date end:"
                      />
                    </FormRow>

                    <Header>Choices</Header>
                    <FieldArray
                      name="choices"
                      render={arrayHelpers => (
                        <>
                          {values.choices &&
                            values.choices.length > 0 &&
                            values.choices.map((friend, index) => (
                              <div key={index}>
                                <FormRow>
                                  <Field width={5} name={`choices.${index}`} />
                                  <Button
                                    inverted
                                    color="red"
                                    type="button"
                                    onClick={() => arrayHelpers.remove(index)}
                                    icon>
                                    <Icon name="remove circle" />
                                  </Button>
                                </FormRow>
                              </div>
                            ))}

                          <Button
                            color="green"
                            type="button"
                            onClick={() => arrayHelpers.push('')}>
                            Add choice
                          </Button>
                        </>
                      )}
                    />

                    <CreateButtonInRow
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    />
                  </Form>
                )}
              </Formik>
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(withRouter(PollCreateForm));
