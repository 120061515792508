import React from 'react';

import { Table } from 'semantic-ui-react';

import PriceRow from './PriceRow';
import CreatePriceForm from './CreatePriceForm';

class PermanentPrices extends React.Component {
  render() {
    const { prices } = this.props;

    return (
      <Table celled style={{ maxWidth: '900px' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Order</Table.HeaderCell>
            <Table.HeaderCell>Price</Table.HeaderCell>
            <Table.HeaderCell>Visible</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {prices.map((price, index) => (
            <PriceRow
              key={index}
              price={price}
              fetchPermanentPrices={this.props.fetchPermanentPrices}
            />
          ))}
          <CreatePriceForm
            prices={prices}
            fetchPermanentPrices={this.props.fetchPermanentPrices}
          />
        </Table.Body>
      </Table>
    );
  }
}

export default PermanentPrices;
