import React, { useCallback, useMemo } from 'react';

import _ from 'lodash';
import cx from 'classnames';
import { default as ReactSelect } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { Error, Button } from 'components';

import styles from './styles.module.css';

const Select = ({
  label,
  className,
  form,
  field,
  options,
  onChange,
  isCreatable,
  disabled,
  enableSelectAll = false,
  ...props
}) => {
  const hasErrors =
    _.get(form.touched, field.name) && _.get(form.errors, field.name);

  const { isMulti } = props;

  let value;

  if (isMulti) {
    value = _.filter(options, option => {
      return (_.castArray(field.value) || []).indexOf(option.value) !== -1;
    });
  } else {
    value = _.filter(options, option => option.value === field.value);
  }

  let Component = ReactSelect;

  if (isCreatable) {
    Component = CreatableSelect;
  }

  const extractValue = useCallback(
    data => {
      if (isMulti) {
        return _.map(data || [], 'value');
      }

      return _.get(data, 'value', null);
    },
    [isMulti]
  );

  const handleChange = useCallback(
    data => {
      const value = extractValue(data);

      if (isMulti) {
        form.setFieldValue(field.name, value);
        form.setFieldTouched(field.name);

        if (onChange) {
          onChange({ target: data }, form.values);
        }
        return;
      }

      form.setFieldValue(field.name, value);
      form.setFieldTouched(field.name);

      if (onChange) {
        if (_.isNil(data)) {
          onChange(null, form.values);
          return;
        }

        const fieldData = {
          ...data
        };

        onChange({ target: fieldData }, form.values);
      }
    },
    [field.name, form, onChange, isMulti, extractValue]
  );

  const selectAll = useCallback(() => handleChange(options), [
    handleChange,
    options
  ]);

  const deselectAll = useCallback(() => handleChange(null), [handleChange]);

  const allAreSelected = useMemo(() => {
    if (isMulti) {
      return _.isEqual(_.sortBy(field.value), _.sortBy(extractValue(options)));
    }

    return false;
  }, [field.value, isMulti, options, extractValue]);

  return (
    <div className={styles.container}>
      {label && !enableSelectAll && <label>{label}</label>}
      {label && enableSelectAll && (
        <div className={styles.withSelectAll}>
          <label>{label}</label>
          <Button
            disabled={disabled}
            color={allAreSelected ? 'red' : 'green'}
            size="small"
            className={styles.selectAllButton}
            onClick={allAreSelected ? deselectAll : selectAll}>
            {allAreSelected ? 'Deselect All' : 'Select All'}
          </Button>
        </div>
      )}
      <Component
        {...props}
        isDisabled={disabled}
        onChange={handleChange}
        value={value}
        options={options}
        className={cx(styles.select, className, {
          [styles.hasErrors]: hasErrors
        })}
        theme={theme => ({
          ...theme,
          borderRadius: 0
        })}
      />
      {hasErrors && (
        <Error className={styles.error}>{_.get(form.errors, field.name)}</Error>
      )}
    </div>
  );
};

export default Select;
