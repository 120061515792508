import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const courseDetail = async courseId => {
  const url = `${BASE_URL}/courses/${courseId}/`;

  return await requestSdk(() => get(url));
};

export const currencyList = async () => {
  const url = `${BASE_URL}/common/currencies/`;

  return await requestSdk(() => get(url));
};

export const promoCodeList = async itemId => {
  const url = `${BASE_URL}/crm/items/promo-codes/${itemId}/`;

  return await requestSdk(() => get(url));
};

export const courseUpdateMeta = async (courseId, data) => {
  const url = `${BASE_URL}/courses/${courseId}/update-meta/`;

  return await requestSdk(() => post(url, data));
};
