import _ from 'lodash';

import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import { Segment, Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  FormRow,
  AdminSelect,
  YesNoSelect,
  UniSelect,
  DateTimeField,
  UpdateButtonInRow,
  UnsavedFormPrompt,
  TextAreaField,
  InputField
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { newsletterUpdateSchema } from './schema';

import { newsletterUpdate } from './sdk';

import { STEEL_SLUG, POWER_MATERIAL_SLUG } from 'domainConstants';

function NewsletterUpdateForm({
  newsletter,
  fetchNewsletter,
  fetchNewsletterPrices,
  hasArticlesWithoutSection
}) {
  const { productSlug } = useParams();

  const [upcomingCourse, setUpcomingCourse] = useState(null);

  const handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    if (
      values.editorApproved &&
      values.marketingApproved &&
      hasArticlesWithoutSection
    ) {
      const confirm = window.confirm(
        'There are unassigned articles. Are you sure you want to proceed?'
      );

      if (!confirm) {
        setSubmitting(false);
        return;
      }
    }

    const data = {
      ...prepareFormData(values),
      prices_section_slug:
        productSlug === POWER_MATERIAL_SLUG ? 'pm-issue-prices' : 'issue-prices'
    };

    setSubmitting(true);

    const { errors, success } = await newsletterUpdate(newsletter.id, data);

    setSubmitting(false);

    if (success) {
      fetchNewsletter();
      fetchNewsletterPrices();
      notifySuccess('Newsletter updated.');
      return;
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  const initialValues = {
    datetime: newsletter.datetime,
    editor: newsletter.editor.id,
    marketer: newsletter.marketer.id,
    prices: newsletter.prices.map(ps => ps.id),
    editorApproved: newsletter.editor_approved,
    marketingApproved: newsletter.marketing_approved,
    defaultCourse: _.isNil(newsletter.default_course)
      ? upcomingCourse
      : newsletter.default_course,
    weeklyReportUrl: newsletter.weekly_report_url,
    weeklySidebarText: newsletter.weekly_sidebar_text
  };

  const productSlugWithHydrogen = `${productSlug},hydrogen`;

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={newsletterUpdateSchema}
      onSubmit={handleSubmit}>
      {({ values, handleSubmit, isSubmitting, dirty }) => (
        <Segment>
          <Form>
            <UnsavedFormPrompt when={dirty} />
            <Field
              required
              name="editor"
              component={AdminSelect}
              label="Editor"
              isClearable={false}
            />

            <Field
              required
              name="marketer"
              component={AdminSelect}
              label="Marketer"
              isClearable={false}
            />

            <FormRow widths="equal">
              <Field
                name="editorApproved"
                component={YesNoSelect}
                label="Editor Approved"
                isClearable={false}
              />

              <Field
                name="marketingApproved"
                component={YesNoSelect}
                label="Marketer Approved"
                isClearable={false}
              />
            </FormRow>

            <Field
              required
              width={10}
              name="datetime"
              component={DateTimeField}
              label="Date to go Out"
            />
            {productSlug === STEEL_SLUG && (
              <UniSelect
                width={16}
                name="defaultCourse"
                label="Selected event"
                source="upcomingEvents"
                apiSuccessCallback={upcomingCourses => {
                  if (
                    _.isArray(upcomingCourses) &&
                    !_.isEmpty(upcomingCourses)
                  ) {
                    setUpcomingCourse(upcomingCourses[0].id);
                  }
                }}
                params={{ order: 'date_start' }}
              />
            )}
            {productSlug === POWER_MATERIAL_SLUG && (
              <>
                <Field
                  width={16}
                  name="weeklyReportUrl"
                  component={InputField}
                  label="Weekly Report URL"
                />
                <Field
                  width={16}
                  name="weeklySidebarText"
                  component={TextAreaField}
                  label="Weekly Sidebar Text"
                />
              </>
            )}
            <UniSelect
              name="prices"
              label="Price series"
              isMulti
              closeMenuOnSelect={false}
              transferList={true}
              source="priceSeries"
              productSlug={productSlugWithHydrogen}
            />

            <UpdateButtonInRow
              subject="Daily Newsletter"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </Form>
        </Segment>
      )}
    </Formik>
  );
}

export default NewsletterUpdateForm;
