import React from 'react';

import _ from 'lodash';

import { Link } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { ClientDetail } from 'pages';

import { Table, List } from 'semantic-ui-react';

import { formatDateTime } from 'utils';

import { feedbackRemove } from './sdk';
import {
  TableFooter,
  YesNo,
  DeleteButton,
  OpenModalButton,
  SortableHeader
} from 'components';

import ManageFeedbackModal from './ManageFeedbackModal';

const deleteFeedback = async (feedbackId, refetch) => {
  const { success } = await feedbackRemove(feedbackId);
  if (success) {
    refetch();
  }
};

const FeedbackTable = ({ feedback, sort, orderBy, pagination, refetch }) => (
  <Table celled selectable compact={true}>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell singleLine style={{ fontWeight: '400' }} colspan="12">
          {pagination}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <SortableHeader sort={sort} orderBy={orderBy} field="client_id">
          Client ID
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="client_email">
          Client Email
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="product">
          Product
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="created_at">
          Created at
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="published_at">
          Published at
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="rating">
          Rating
        </SortableHeader>
        <Table.HeaderCell>Display Option</Table.HeaderCell>
        <SortableHeader sort={sort} orderBy={orderBy} field="is_published">
          Is Published
        </SortableHeader>
        <Table.HeaderCell>Displayed Locations</Table.HeaderCell>
        <SortableHeader sort={sort} orderBy={orderBy} field="order">
          Order
        </SortableHeader>
        <Table.HeaderCell>Manage</Table.HeaderCell>
        <Table.HeaderCell>Delete</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {feedback.map(feedback => (
        <Table.Row key={feedback.id}>
          <Table.Cell>
            {feedback.created_by && (
              <Link
                to={pageUrl(ClientDetail, {
                  clientId: feedback.created_by.id
                })}>
                {feedback.created_by.id}
              </Link>
            )}
          </Table.Cell>

          <Table.Cell>{_.get(feedback, 'created_by.email')}</Table.Cell>
          <Table.Cell>{_.get(feedback, 'feedback_product.name')}</Table.Cell>
          <Table.Cell>{formatDateTime(feedback.created_at)}</Table.Cell>
          <Table.Cell>{formatDateTime(feedback.published_at)}</Table.Cell>
          <Table.Cell>{_.get(feedback, 'rating')}</Table.Cell>
          <Table.Cell>{_.get(feedback, 'display_option')}</Table.Cell>
          <Table.Cell>
            <YesNo value={feedback.is_published} />
          </Table.Cell>
          <Table.Cell>
            {!_.isEmpty(_.get(feedback, 'displayed_locations')) && (
              <List>
                {_.get(feedback, 'displayed_locations').map(location => (
                  <List.Item>
                    {location.identifier} {location.title}
                  </List.Item>
                ))}
              </List>
            )}
          </Table.Cell>
          <Table.Cell>{_.get(feedback, 'order')}</Table.Cell>
          <Table.Cell>
            <OpenModalButton
              key={feedback.id}
              size="medium"
              color="green"
              modal={{
                header: 'Manage Feedback',
                render: props => (
                  <ManageFeedbackModal
                    refetch={refetch}
                    feedback={feedback}
                    {...props}
                  />
                )
              }}>
              Manage
            </OpenModalButton>
          </Table.Cell>
          <Table.Cell>
            <DeleteButton
              onClick={() => {
                deleteFeedback(feedback.id, refetch);
              }}
            />
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default FeedbackTable;
