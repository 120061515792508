import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const clientItemSetAvailability = async (clientId, data) => {
  const url = `${BASE_URL}/crm/clients/${clientId}/products/set-availability/`;

  return await requestSdk(() => post(url, data));
};

export const getDownloadChildrenEmailsUrl = (clientId, clientItemId) =>
  `${BASE_URL}/crm/clients/${clientId}/download-children-emails/${clientItemId}/`;
