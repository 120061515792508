import React from 'react';

import { Button as SemanticButton } from 'semantic-ui-react';

import { FormRow } from 'components';

const DeleteButton = ({ capital, style, children, ...props }) => {
  return (
    <SemanticButton
      style={{
        textTransform: capital && 'uppercase',
        ...style
      }}
      type="submit"
      size="large"
      color="red"
      {...props}>
      {children || 'Delete'}
    </SemanticButton>
  );
};

const DeleteButtonInRow = props => (
  <FormRow flexEnd>
    <DeleteButton {...props} />
  </FormRow>
);

export { DeleteButton, DeleteButtonInRow };
