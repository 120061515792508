import React from 'react';

import { corporateMasterAccountsList } from './sdk';

import { SelectField, Field } from 'components';

class CorporateMasterAccountsSelect extends React.Component {
  state = {
    accounts: []
  };

  async componentDidMount() {
    const { data, success } = await corporateMasterAccountsList();

    if (success) {
      this.setState({ accounts: data });
    }
  }

  render() {
    const { name, ...rest } = this.props;
    const { accounts } = this.state;

    const options = accounts.map(account => {
      return {
        key: account.id,
        value: account.id,
        label: account.company_name || account.email
      };
    });

    return (
      <Field
        name={name || 'corporateMasterAccountsSelect'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default CorporateMasterAccountsSelect;
