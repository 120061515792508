import _ from 'lodash';

import React from 'react';

import { withRouter } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import { pageUrl } from 'config/routes';

import { DailyNewsletterList } from 'pages';

import {
  Field,
  FormRow,
  AdminSelect,
  YesNoSelect,
  PriceSeriesSelect,
  DateTimeField,
  CreateButtonInRow,
  UnsavedFormPrompt,
  UniSelect
} from 'components';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { dailyNewsletterCreateSchema } from './schema';
import { dailyNewsletterCreate } from './sdk';

class DailyNewsletterCreateForm extends React.Component {
  state = {
    upcomingCourse: null
  };

  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await dailyNewsletterCreate(data);

    if (success) {
      notifySuccess('Daily newsletter created.');
      this.props.history.push(pageUrl(DailyNewsletterList));
      return;
    }

    handleFormErrors(errors, setFieldError);
    setSubmitting(false);
  };

  render() {
    const { upcomingCourse } = this.state;

    const initialValues = {
      datetime: null,
      editor: null,
      marketer: null,
      priceSeries: [],
      editorApproved: false,
      marketingApproved: false,
      defaultCourse: upcomingCourse
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={dailyNewsletterCreateSchema}
        enableReinitialize={true}
        onSubmit={this.handleSubmit}>
        {({ values, handleSubmit, isSubmitting, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty && !isSubmitting} />
            <FormRow>
              <Field
                width={5}
                required
                name="editor"
                component={AdminSelect}
                label="Editor"
              />

              <Field
                width={3}
                name="editorApproved"
                component={YesNoSelect}
                label="Editor Approved"
                disabled={true}
              />

              <Field
                width={5}
                required
                name="marketer"
                component={AdminSelect}
                label="Marketer"
              />

              <Field
                width={3}
                name="marketingApproved"
                component={YesNoSelect}
                label="Marketer Approved"
                disabled={true}
              />
            </FormRow>

            <FormRow>
              <Field
                required
                width={5}
                name="datetime"
                component={DateTimeField}
                label="Date to go Out"
              />
              <UniSelect
                width={8}
                name="defaultCourse"
                label="Selected event"
                source="upcomingEvents"
                apiSuccessCallback={upcomingCourses => {
                  if (
                    _.isArray(upcomingCourses) &&
                    !_.isEmpty(upcomingCourses)
                  ) {
                    this.setState({ upcomingCourse: upcomingCourses[0].id });
                  }
                }}
                params={{ order: 'date_start' }}
              />
            </FormRow>

            <Field
              name="priceSeries"
              component={PriceSeriesSelect}
              label="Price series"
              isMulti
            />

            <CreateButtonInRow onClick={handleSubmit} disabled={isSubmitting} />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(DailyNewsletterCreateForm);
