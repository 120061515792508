import React from 'react';

import { Header, Segment } from 'semantic-ui-react';

import { Page, LoginRequired } from 'components';
import { PERMISSIONS, PermissionsRequired } from 'permissions';

import { GroupsAndPermissionsCreateForm } from './components';

const GroupsAndPermissionsCreate = ({ user, crumbs }) => (
  <Page user={user}>
    <Page.Body>
      <Page.Content crumbs={crumbs}>
        <Segment>
          <Header as="h1">Create group and permissions</Header>
          <GroupsAndPermissionsCreateForm />
        </Segment>
      </Page.Content>
    </Page.Body>
  </Page>
);

export default LoginRequired(
  PermissionsRequired([PERMISSIONS.CREATE_GROUPS])(GroupsAndPermissionsCreate)
);
