import React from 'react';
import _ from 'lodash';

import { Table, Segment, Header } from 'semantic-ui-react';
import styles from './styles.module.css';
import ImportClientsHeaderRow from '../ImportClientsHeaderRow';

const ImportClientsTable = ({ clientsData }) => {
  const hasErrors = errors => {
    for (const key in errors) {
      if (errors[key].length > 0) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <Segment>
        <Table celled className={styles.TableScroll}>
          <ImportClientsHeaderRow hasLineNumbers={true} />
          <Table.Body>
            {clientsData.map((client, index) => (
              <Table.Row
                key={index}
                style={{
                  backgroundColor: hasErrors(client.errors)
                    ? '#FF4861'
                    : 'transparent'
                }}>
                <Table.Cell>{index + 2}</Table.Cell>
                <Table.Cell>{client.data.username}</Table.Cell>
                <Table.Cell>{client.data.email}</Table.Cell>
                <Table.Cell>{client.data.priority}</Table.Cell>
                <Table.Cell>{client.data.admin_comments}</Table.Cell>
                <Table.Cell>{client.data.title}</Table.Cell>
                <Table.Cell>{client.data.first_name}</Table.Cell>
                <Table.Cell>{client.data.last_name}</Table.Cell>
                <Table.Cell>{client.data.job_title}</Table.Cell>
                <Table.Cell>{client.data.company_name}</Table.Cell>
                <Table.Cell>{client.data.address_1}</Table.Cell>
                <Table.Cell>{client.data.address_2}</Table.Cell>
                <Table.Cell>{client.data.city}</Table.Cell>
                <Table.Cell>{client.data.postcode}</Table.Cell>
                <Table.Cell>{client.data.state_or_province}</Table.Cell>
                <Table.Cell>{client.data.country}</Table.Cell>
                <Table.Cell>{client.data.state}</Table.Cell>
                <Table.Cell>{client.data.website}</Table.Cell>
                <Table.Cell>{client.data.phone}</Table.Cell>
                <Table.Cell>{client.data.fax}</Table.Cell>
                <Table.Cell>{client.data.mobile}</Table.Cell>
                <Table.Cell>{client.data.linkedin_profile}</Table.Cell>
                <Table.Cell>{client.data.email_type}</Table.Cell>
                <Table.Cell>{client.data.account_manager}</Table.Cell>
                <Table.Cell>{client.data.business_activity}</Table.Cell>
                <Table.Cell>{client.data.commodity}</Table.Cell>
                <Table.Cell>{client.data.industry}</Table.Cell>
                <Table.Cell>{client.data.sub_industry}</Table.Cell>
                <Table.Cell>
                  {client.additional_data.tags.join(', ')}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
      {clientsData.some(data => Object.keys(data.errors).length > 0) && (
        <Segment className={styles.ErrorsContainer}>
          {clientsData.map((data, index) => {
            const hasErrors = Object.keys(data.errors).length > 0;

            if (hasErrors) {
              return (
                <Header key={index} as="h4" style={{ color: '#FF4861' }}>
                  Line {index + 2} has errors:
                  <ul>
                    {Object.keys(data.errors).map(field =>
                      data.errors[field].map((error, errorIndex) => (
                        <li key={errorIndex}>
                          <span>{_.startCase(field)}</span> - {error}
                        </li>
                      ))
                    )}
                  </ul>
                </Header>
              );
            }
            return null;
          })}
        </Segment>
      )}
    </>
  );
};

export default ImportClientsTable;
