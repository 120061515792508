import React from 'react';

import { Table } from 'semantic-ui-react';

import PriceRow from './PriceRow';
import CreatePriceForm from './CreatePriceForm';

function PermanentPricesTable({
  fetchPermanentPrices,
  section,
  productSlug,
  prices,
  priceRemoveSdk,
  permanentPriceCreateSdk
}) {
  const sharedProps = {
    productSlug,
    section,
    fetchPermanentPrices
  };
  return (
    <Table celled style={{ maxWidth: '900px' }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Order</Table.HeaderCell>
          <Table.HeaderCell>Price</Table.HeaderCell>
          <Table.HeaderCell>Visible</Table.HeaderCell>
          <Table.HeaderCell>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {prices.map((price, index) => (
          <PriceRow
            key={index}
            order={index}
            price={price}
            priceRemoveSdk={priceRemoveSdk}
            {...sharedProps}
          />
        ))}
        <CreatePriceForm
          permanentPriceCreateSdk={permanentPriceCreateSdk}
          prices={prices}
          {...sharedProps}
        />
      </Table.Body>
    </Table>
  );
}

export default PermanentPricesTable;
