import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const invoiceUpdateInvoiceItem = async (id, data) => {
  const url = `${BASE_URL}/crm/invoices/${id}/update-item/`;
  return await requestSdk(() => post(url, data));
};

export const invoiceRemoveInvoiceItem = async (id, data) => {
  const url = `${BASE_URL}/crm/invoices/${id}/remove-item/`;
  return await requestSdk(() => post(url, data));
};
