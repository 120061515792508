import React from 'react';

import { Link } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { CompanyV2Detail } from 'pages';

import { Table } from 'semantic-ui-react';

import { TableFooter, SortableHeader } from 'components';

import { formatDateTime } from 'utils';

import _ from 'lodash';

const CompanyV2Table = ({ companies, sort, orderBy, pagination }) => {
  return (
    <Table celled selectable compact={true}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            singleLine
            style={{ fontWeight: '400' }}
            colspan="5">
            {pagination}
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <SortableHeader sort={sort} orderBy={orderBy} field="title">
            Company title
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="slug">
            Slug
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="created_at">
            Created At
          </SortableHeader>
          <SortableHeader sort={sort} orderBy={orderBy} field="updated_at">
            Updated At
          </SortableHeader>
          <Table.HeaderCell field="product">Global Products</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {companies.map(company => (
          <Table.Row key={company.id}>
            <Table.Cell>
              <Link to={pageUrl(CompanyV2Detail, { companyId: company.id })}>
                {company.title}
              </Link>
            </Table.Cell>
            <Table.Cell>{company.slug}</Table.Cell>
            <Table.Cell>{formatDateTime(company.created_at)}</Table.Cell>
            <Table.Cell>{formatDateTime(company.updated_at)}</Table.Cell>
            <Table.Cell>
              {_.join(
                company.product.map(product => product.name, ', '),
                ', '
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <TableFooter>{pagination}</TableFooter>
    </Table>
  );
};

export default CompanyV2Table;
