import _ from 'lodash';

export const calculateTotalSubscription = subscriptions => {
  let total = 0;
  _.forEach(subscriptions, (data, subscription) => {
    total += data.new_total + data.repeat_total;
  });
  return total;
};

export const calculatePartialSubscriptionTotal = (subscriptions, key) => {
  let result = 0;

  _.forEach(subscriptions, (data, subscription) => {
    result += data[key];
  });

  return result;
};

export const calculatePercentageFromTarget = (accumulated, target) => {
  let result = (accumulated / target) * 100;

  return result.toFixed(0);
};
