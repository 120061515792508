import React from 'react';

import { Field, SelectField } from 'components';

const PRODUCT_STATUSES = [
  'Pending',
  'Invoiced',
  'Paid',
  'Answered',
  'Trial',
  'Trial Expired',
  'Special',
  'Unknown'
];

class ProductStatusSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = PRODUCT_STATUSES.map(status => {
      return {
        key: status,
        value: status,
        label: status
      };
    });

    return (
      <Field
        name={name || 'productStatus'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default ProductStatusSelect;
