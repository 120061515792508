import * as yup from 'yup';

export const getInitialValues = marketDataReport => ({
  title: marketDataReport.title,
  slug: marketDataReport.slug,
  dateActivation: marketDataReport.date_activation,
  isSteelReport: marketDataReport.is_steel_report,
  isCustomReport: marketDataReport.is_custom_report,
  image: marketDataReport.image,
  thumb: marketDataReport.thumb,
  file: marketDataReport.file,
  sample: marketDataReport.sample,
  textHtml: marketDataReport.text_html,
  relatedProducts: marketDataReport.related_products,
  tags: marketDataReport.tags.map(t => t.tag)
});

export const marketDataReportUpdateSchema = yup.object().shape({
  title: yup.string().required('Title is required.'),
  slug: yup.string().required('Slug is required.'),
  tags: yup
    .array()
    .of(yup.string().max(100, 'Tag must be at most 100 characters long.'))
});
