import _ from 'lodash';

export const getInvoiceItemsFor = (invoiceItems, type) => {
  const invoiceItemsForRepors = _.filter(
    invoiceItems,
    invoice => _.get(invoice, 'item.item_type') === type
  );

  return _.map(invoiceItemsForRepors, 'item');
};
