import React from 'react';
import { Table } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { TradeExcelDetail } from 'pages';

import { TableFooter, YesNo, Admin } from 'components';

import { formatDateTime } from 'utils';
import { Link } from 'react-router-dom';

class TradeExcelTable extends React.Component {
  getTradeExcelUrl = tradeExcelId => {
    return pageUrl(TradeExcelDetail, { tradeExcelId });
  };

  render() {
    const { tradeExcels, orderBy, pagination } = this.props;

    return (
      <Table celled selectable compact={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              singleLine
              style={{ fontWeight: '400' }}
              colspan="6">
              {pagination}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Excel File</Table.HeaderCell>
            <Table.HeaderCell onClick={() => orderBy('is_published')}>
              Published
            </Table.HeaderCell>
            <Table.HeaderCell onClick={() => orderBy('created_by')}>
              Created by
            </Table.HeaderCell>
            <Table.HeaderCell onClick={() => orderBy('created_at')}>
              Create time
            </Table.HeaderCell>
            <Table.HeaderCell onClick={() => orderBy('updated_by')}>
              Updated by
            </Table.HeaderCell>
            <Table.HeaderCell onClick={() => orderBy('updated_at')}>
              Update time
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {tradeExcels.map(tradeExcel => (
            <Table.Row key={tradeExcel.id}>
              <Table.Cell>
                <Link to={this.getTradeExcelUrl(tradeExcel.id)}>
                  {tradeExcel.name}
                </Link>
              </Table.Cell>
              <Table.Cell>
                <YesNo value={tradeExcel.is_published} />
              </Table.Cell>
              <Table.Cell>
                <Admin value={tradeExcel.created_by} />
              </Table.Cell>
              <Table.Cell>{formatDateTime(tradeExcel.created_at)}</Table.Cell>
              <Table.Cell>
                <Admin value={tradeExcel.updated_by} />
              </Table.Cell>
              <Table.Cell>{formatDateTime(tradeExcel.updated_at)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <TableFooter>{pagination}</TableFooter>
      </Table>
    );
  }
}

export default TradeExcelTable;
