import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const articleDetail = async (productSlug, id) => {
  const url = `${BASE_URL}/articles/${productSlug}/${id}/`;

  return await requestSdk(() => get(url));
};

export const articleUpdateMeta = async (articleId, data) => {
  const url = `${BASE_URL}/articles/hydrogen/${articleId}/update-meta/`;

  return await requestSdk(() => post(url, data));
};

export const globalProductsList = async () => {
  const url = `${BASE_URL}/common/global-products/`;

  return await requestSdk(() => get(url));
};

export const articlePushToProduct = async (productSlug, articleId, data) => {
  const url = `${BASE_URL}/articles/${productSlug}/${articleId}/push-to-product/`;

  return await requestSdk(() => post(url, data));
};
