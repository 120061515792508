import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const priceSeriesDetail = async id => {
  const url = `${BASE_URL}/prices/series/price-series/${id}/`;

  return await requestSdk(() => get(url));
};

// TODO use it when use shared update form
export const priceSeriesUpdate = async (id, data) => {
  const url = `${BASE_URL}/prices/series/price-series/${id}/update/`;

  return await requestSdk(() => post(url, data));
};
