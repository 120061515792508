import _ from 'lodash';
import React from 'react';

import { useParams } from 'react-router-dom';

import { Segment, Button, Form, Header } from 'semantic-ui-react';

import { Formik } from 'formik';

import { openInNewTab, stringifyParams } from 'utils';

import { BACKEND_URL } from 'sdk/urls';

import { Field, FormRow, InputField, UniSelect } from 'components';
import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { createNewsletterSendTestSchema } from './schema';
import { newsletterSendTest } from './sdk';

import { POWER_MATERIAL_SLUG } from 'domainConstants';

function SendAndPreview({ newsletter }) {
  const { productSlug } = useParams();

  const newsletterSendTestSchema = createNewsletterSendTestSchema(productSlug);

  const handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    let data = prepareFormData(values);

    data = _.pickBy(data, _.negate(_.isNil));

    setSubmitting(true);

    const { errors, success } = await newsletterSendTest(newsletter.id, data);

    setSubmitting(false);

    if (success) {
      notifySuccess('Test email sent.');
      return;
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  const openPreview = (values, setTouched) => {
    setTouched({
      sendTo: true,
      recipientGroup: true
    });

    if (
      productSlug === POWER_MATERIAL_SLUG &&
      (!values.sendTo || !values.recipientGroup)
    ) {
      return;
    }
    let params = _.pickBy(values, _.negate(_.isNil));

    params = prepareFormData(params);

    const url = `${BACKEND_URL}/newsletters/${
      newsletter.id
    }/preview/?${stringifyParams(params)}`;

    openInNewTab(url);
  };

  const initialValues = {
    sendTo: '',
    recipientGroup: null,
    region: null,
    emailType: null
  };

  return (
    <Segment>
      <Header as="h3">Send / Preview Newsletter</Header>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={newsletterSendTestSchema}
        onSubmit={handleSubmit}>
        {({ values, handleSubmit, isSubmitting, setTouched }) => (
          <Form>
            <FormRow widths="equal">
              <Field
                required
                component={InputField}
                name="sendTo"
                placeholder="Email"
                label="Email"
              />
              <UniSelect
                required={productSlug === POWER_MATERIAL_SLUG}
                name="recipientGroup"
                label="Recipient Group"
                source="recipientGroups"
                productSlug={productSlug}
              />
            </FormRow>
            <FormRow alignEnd>
              <UniSelect name="region" label="Region" source="regions" />
              <UniSelect
                name="emailType"
                label="Email Type"
                source="emailTypes"
              />
              <Button
                onClick={handleSubmit}
                type="submit"
                primary
                disabled={isSubmitting}>
                Send
              </Button>
              <Button onClick={() => openPreview(values, setTouched)}>
                Preview
              </Button>
            </FormRow>
          </Form>
        )}
      </Formik>
    </Segment>
  );
}

export default SendAndPreview;
