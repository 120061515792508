import React from 'react';

import { withRouter } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { DailyNewsletterCreate } from 'pages';

import {
  Page,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Button,
  Flex
} from 'components';

import { VisibleIf, PERMISSIONS, PermissionsRequired } from 'permissions';

import { dailyNewsletterList } from './sdk';

import { DailyNewsletterTable } from './components';

class DailyNewsletterList extends React.Component {
  createNewsletter = () => {
    const location = pageUrl(DailyNewsletterCreate);

    this.props.history.push(location);
  };

  render() {
    const {
      user,
      data,
      limit,
      count,
      page,
      changePage,
      changeRowsPerPage,
      sort,
      orderBy,
      crumbs
    } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Flex spaceBetween>
              <Header>Daily newsletters</Header>
              <VisibleIf
                user={user}
                permissions={[PERMISSIONS.CREATE_NEWSLETTERS]}>
                <Button size="large" onClick={this.createNewsletter}>
                  Create newsletter
                </Button>
              </VisibleIf>
            </Flex>
            {data && (
              <DailyNewsletterTable
                sort={sort}
                orderBy={orderBy}
                dailyNewsletters={data}
                pagination={
                  <Pagination
                    limit={limit}
                    count={count}
                    page={page}
                    changePage={changePage}
                    changeRowsPerPage={changeRowsPerPage}
                  />
                }
              />
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: dailyNewsletterList
})(
  LoginRequired(
    PermissionsRequired([PERMISSIONS.VIEW_NEWSLETTERS])(
      withRouter(DailyNewsletterList)
    )
  )
);
