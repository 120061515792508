import _ from 'lodash';
import React from 'react';

import { Field, SelectField } from 'components';
import { subscriptionStatusList } from 'sdk/shared';

class SubscriptionStatusSelect extends React.Component {
  state = {
    subscriptionStatuses: []
  };

  async componentDidMount() {
    const { data, success } = await subscriptionStatusList();

    if (success) {
      this.setState({ subscriptionStatuses: data });
    }
  }

  render() {
    const { name, only = [], ...rest } = this.props;
    const { subscriptionStatuses } = this.state;

    let options = subscriptionStatuses.map(subscriptionStatus => {
      return {
        key: subscriptionStatus.id,
        value: subscriptionStatus.id,
        label: subscriptionStatus.title
      };
    });

    if (!_.isEmpty(only)) {
      options = options.filter(({ key }) => _.includes(only, key));
    }

    return (
      <Field
        name={name || 'subscriptionStatus'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default SubscriptionStatusSelect;
