import React, { useCallback, useEffect, useState } from 'react';

import { Dimmer, Segment } from 'semantic-ui-react';
import { notifyError } from 'utils/notifications';

import Loader from 'components/Loader';
import { buildFilters } from 'utils/filters';

import { StaffAggregatesTable } from './components';

import { clientProfileLogsExportPreview } from '../../sdk';

export default function ClientProfileLogsReportModal({ filters }) {
  const [aggregates, setAggregates] = useState({
    staff_aggregates: [],
    totals: {}
  });
  const [isLoading, setIsLoading] = useState(false);

  const fetchExportPreview = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await clientProfileLogsExportPreview(
        buildFilters(filters)
      );

      setAggregates(data);
    } catch (error) {
      notifyError("Couldn't fetch export preview");
    } finally {
      setIsLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchExportPreview();
  }, [fetchExportPreview]);

  const { staff_aggregates: staffAggregates, totals } = aggregates;

  return (
    <Segment style={{ minHeight: '550px' }}>
      {isLoading ? (
        <Dimmer active inverted>
          <Loader size="big" active inverted>
            Fetching export preview
          </Loader>
        </Dimmer>
      ) : (
        <StaffAggregatesTable
          staffAggregates={staffAggregates}
          totals={totals}
        />
      )}
    </Segment>
  );
}
