import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const clientDetail = async id => {
  const url = `${BASE_URL}/crm/clients/${id}/`;

  return await requestSdk(() => get(url));
};

export const clientSendResetPassword = async id => {
  const url = `${BASE_URL}/crm/clients/${id}/reset-password/`;

  return await requestSdk(() => post(url));
};

export const clientRemoveFromSuppression = async id => {
  const url = `${BASE_URL}/crm/clients/${id}/unblock-email/`;

  return await requestSdk(() => post(url));
};
