import React from 'react';
import _ from 'lodash';
import cx from 'classnames';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { Error, Flex, DatePicker } from 'components';
import styles from './styles.module.css';

const BACKEND_FORMAT = 'YYYY-MM-DD';

class DateField2 extends React.Component {
  state = {
    key: `initial-${uuidv4()}`
  };

  setNewKey = () => {
    // we set different key because of https://github.com/gpbl/react-day-picker/issues/815
    this.setState({ key: uuidv4() });
  };

  componentDidUpdate(prevProps) {
    //  current field value can be null only if reset action is running
    if (!_.isNil(prevProps.field.value) && _.isNil(this.props.field.value)) {
      this.setNewKey();
    }
  }

  render() {
    const {
      label,
      className,
      form: { values, setFieldValue, setFieldTouched, ...form },
      field,
      inputProps = {},
      withErrorMessage = true,
      dayPickerProps = {}, // selectedDays, disabledDays
      onChangeHook = value => null,
      ...props
    } = this.props;
    const hasErrors =
      _.get(form.touched, field.name) && _.get(form.errors, field.name);

    return (
      <div className={cx(styles.container, className)}>
        {label && <label>{label}</label>}

        <DatePicker
          key={this.state.key}
          onChange={value => {
            setFieldValue(field.name, value);
            setFieldTouched(field.name);
            onChangeHook(value);
          }}
          value={field.value}
          inputProps={{
            ...inputProps,
            ...props,
            className: cx(styles.field, {
              [styles.hasErrors]: hasErrors
            })
          }}
          dayPickerProps={dayPickerProps}
        />

        <Flex spaceBetween>
          {withErrorMessage && hasErrors && (
            <Error className={styles.error}>
              {_.get(form.errors, field.name)}
            </Error>
          )}
          <span
            className={styles.todayBtn}
            onClick={() => {
              let today = moment().format(BACKEND_FORMAT);
              setFieldValue(field.name, today);
              onChangeHook(today);
              // reset key if current value is not null
              if (!_.isNil(field.value)) {
                this.setNewKey();
              }
            }}>
            Today
          </span>
        </Flex>
      </div>
    );
  }
}
export default DateField2;
