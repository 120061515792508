import React from 'react';

import { withRouter } from 'react-router-dom';

import { Header, Segment } from 'semantic-ui-react';

import { Page, LoginRequired, Error } from 'components';

import { chinaSteelReportDetail } from './sdk';

import { ChinaSteelReportForm } from './components';

class ChinaSteelReport extends React.Component {
  state = {
    errors: null,
    chinaSteelReport: null
  };

  fetchChinaSteelReport = async () => {
    const { reportId } = this.props.match.params;

    const { data, errors, success } = await chinaSteelReportDetail(reportId);

    if (success) {
      this.setState({ chinaSteelReport: data });
    } else {
      this.setState({ errors });
    }
  };

  componentDidMount() {
    this.fetchChinaSteelReport();
  }

  render() {
    const { user, crumbs } = this.props;

    const { chinaSteelReport, errors } = this.state;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Header as="h1">Change report</Header>
              {errors &&
                errors.map((error, index) => (
                  <Error key={index}>{error.message}</Error>
                ))}
              {chinaSteelReport && (
                <ChinaSteelReportForm
                  chinaSteelReport={chinaSteelReport}
                  fetchChinaSteelReport={this.fetchChinaSteelReport}
                />
              )}
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(withRouter(ChinaSteelReport));
