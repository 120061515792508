import React from 'react';
import _ from 'lodash';

import { Statistic } from 'semantic-ui-react';

const AdditionalStatistics = ({ stats }) => {
  const symbol = '£';
  return (
    <>
      <Statistic.Group size="tiny" style={{ margin: 0 }}>
        <Statistic color="green">
          <Statistic.Value>
            {!_.isNil(stats.past_total)
              ? `${symbol} ${stats.past_total.toFixed(0)}`
              : 'N/A'}
          </Statistic.Value>
          <Statistic.Label>Total Past</Statistic.Label>
        </Statistic>
      </Statistic.Group>{' '}
      <Statistic.Group size="tiny" style={{ margin: 0 }}>
        <Statistic color="green">
          <Statistic.Value>
            {stats.future_paid_count}
            {!_.isNil(stats.future_paid_total) &&
              `(${symbol} ${stats.future_paid_total.toFixed(0)})`}
          </Statistic.Value>
          <Statistic.Label>Future Paid</Statistic.Label>
        </Statistic>
      </Statistic.Group>{' '}
      <Statistic.Group size="tiny" style={{ margin: 0 }}>
        <Statistic color="green">
          <Statistic.Value>
            {stats.future_booked_count}
            {!_.isNil(stats.future_booked_total) &&
              `(${symbol} ${stats.future_booked_total.toFixed(0)})`}
          </Statistic.Value>
          <Statistic.Label>Future Booked</Statistic.Label>
        </Statistic>
      </Statistic.Group>{' '}
      <Statistic.Group size="tiny" style={{ margin: 0 }}>
        <Statistic color="green">
          <Statistic.Value>
            {!_.isNil(stats.upsell_total_amount)
              ? `${symbol} ${stats.upsell_total_amount.toFixed(
                  0
                )} (${stats.upsell_total_percentage.toFixed(0)}%)`
              : 'N/A'}
          </Statistic.Value>
          <Statistic.Label>Upsells</Statistic.Label>
        </Statistic>
      </Statistic.Group>
    </>
  );
};

export default AdditionalStatistics;
