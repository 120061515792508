import React from 'react';

import { withRouter } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  InputField,
  FormRow,
  CreateButton,
  UnsavedFormPrompt,
  Flex
} from 'components';
import { notifySuccess } from 'utils/notifications';
import { handleFormErrors, prepareFormData } from 'utils/forms';

import { pageUrl } from 'config/routes';

import { FaqCategoryList } from 'pages';

import { faqCategoryCreateSchema } from './utils';
import { faqCategoryCreate } from './sdk';

class FaqCategoryCreateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await faqCategoryCreate(data);

    if (success) {
      notifySuccess('FAQ category created.');
      this.props.history.push(pageUrl(FaqCategoryList));
    } else {
      handleFormErrors(errors, setFieldError);
    }
    setSubmitting(false);
  };

  render() {
    const initialValues = {
      title: ''
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={faqCategoryCreateSchema}
        onSubmit={this.handleSubmit}>
        {({ handleSubmit, isSubmitting, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty && !isSubmitting} />

            <FormRow style={{ alignItems: 'flex-end' }}>
              <Field
                width={6}
                name="title"
                label="Title"
                required
                component={InputField}
              />
              <Flex style={{ alignItems: 'flex-end' }}>
                <CreateButton onClick={handleSubmit} disabled={isSubmitting} />
              </Flex>
            </FormRow>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(FaqCategoryCreateForm);
