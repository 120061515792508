import * as yup from 'yup';

export const videoCreateSchema = yup.object().shape({
  title: yup.string().required('Title is required.'),
  slug: yup.string().required('Slug is required.'),
  videoUrl: yup.string().required('Video URL is required.'),
  tags: yup
    .array()
    .of(yup.string().max(100, 'Tag must be at most 100 characters long.'))
});
