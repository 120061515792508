import React from 'react';

import _ from 'lodash';

import { Error } from 'components';
import { handleFileRejections } from 'utils/files';
import { BASE_BACKEND_URL } from 'sdk/urls';

import styles from './styles.module.css';
import { UploadWithPreview, UploadFile } from './components';

class DjangoFileField extends React.Component {
  onDrop = async (acceptedFiles, fileRejections) => {
    const { field, form, accept } = this.props;

    handleFileRejections(fileRejections, accept);

    if (!acceptedFiles[0]) return;

    form.setFieldValue(field.name, acceptedFiles[0]);
  };

  buildPreviewUrl = value => {
    let url = value;

    if (url.startsWith('/')) {
      url = `${BASE_BACKEND_URL}${value}`;
    }

    return url;
  };

  render() {
    const {
      multiple,
      form,
      field,
      placeholder,
      label,
      fileUrl,
      accept,
      maxSize
    } = this.props;

    const { value } = field;

    const hasErrors =
      _.get(form.errors, field.name) && _.get(form.touched, field.name);

    return (
      <div className={styles.Container}>
        {label && <label>{label}</label>}
        {_.isNil(value) && !_.isNil(fileUrl) && (
          <UploadWithPreview
            fileUrl={this.buildPreviewUrl(fileUrl)}
            onDrop={this.onDrop}
            multiple={multiple}
            accept={accept}
            maxSize={maxSize}
          />
        )}
        {!_.isNil(value) && (
          <UploadWithPreview
            onDrop={this.onDrop}
            multiple={multiple}
            accept={accept}
            maxSize={maxSize}
          />
        )}
        {_.isNil(value) && _.isNil(fileUrl) && (
          <UploadFile
            onDrop={this.onDrop}
            multiple={multiple}
            placeholder={placeholder}
            accept={accept}
            maxSize={maxSize}
          />
        )}
        <div>
          {hasErrors && <Error>{_.get(form.errors, field.name)}</Error>}
        </div>
      </div>
    );
  }
}

export default DjangoFileField;
