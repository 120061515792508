import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const createInstantNew = async data => {
  const url = `${BASE_URL}/editorials/instant_news/create/`;

  return await requestSdk(() => post(url, data));
};

export const updateInstantNew = async (id, data) => {
  const url = `${BASE_URL}/editorials/instant_news/${id}/update/`;

  return await requestSdk(() => post(url, data));
};

export const changeReadyStatus = async (id, data) => {
  const url = `${BASE_URL}/editorials/instant_news/${id}/mark-ready/`;

  return await requestSdk(() => post(url, data));
};
