import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const clientUpdate = async (clientId, data) => {
  const url = `${BASE_URL}/crm/clients/update/${clientId}/`;

  return await requestSdk(() => post(url, data));
};

export const clientAdminCommentsArchiveGet = async clientId => {
  const url = `${BASE_URL}/crm/clients/${clientId}/admin-comments-archive/`;

  return await requestSdk(() => get(url));
};

export const clientAdminCommentsArchiveUpdate = async (clientId, data) => {
  const url = `${BASE_URL}/crm/clients/${clientId}/admin-comments-archive/`;

  return await requestSdk(() => post(url, data));
};
