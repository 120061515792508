import React from 'react';
import _ from 'lodash';

import { withRouter } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import {
  Header,
  Page,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Button,
  Flex,
  NoDataMessage,
  Filters as SharedFilters
} from 'components';

import { PERMISSIONS, PermissionsRequired, VisibleIf } from 'permissions';
import { WeeklyPowerMaterialsTable } from './components';

import { weeklyPowerMaterialsList } from './sdk';

import { FILTERS as LAYOUT_FILTERS } from './utils';

import WeeklyPowerMaterialsCreate from '../Create';
import WeeklyPowerMaterialsDetail from '../Detail';

class WeeklyPowerMaterialsList extends React.Component {
  createWeeklyPowerMaterials = () => {
    const location = pageUrl(WeeklyPowerMaterialsCreate);
    this.props.history.push(location);
  };

  render() {
    const {
      user,
      data,
      limit,
      count,
      page,
      changePage,
      changeRowsPerPage,
      filters,
      clear,
      filterBy,
      orderBy,
      sort,
      crumbs
    } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content maxWidth crumbs={crumbs}>
            <Flex spaceBetween>
              <Header>Weekly Power Materials</Header>
              <VisibleIf
                user={user}
                permission={PERMISSIONS.CREATE_WEEKLY_POWER_MATERIALS}>
                <Button size="large" onClick={this.createWeeklyPowerMaterials}>
                  Create Weekly Power Materials
                </Button>
              </VisibleIf>
            </Flex>
            {data && (
              <>
                <SharedFilters
                  filters={filters}
                  clearFilters={clear}
                  filterBy={filterBy}
                  count={count}
                  layout={LAYOUT_FILTERS}
                />
                {_.isEmpty(data) && <NoDataMessage />}
                {!_.isEmpty(data) && (
                  <WeeklyPowerMaterialsTable
                    items={data}
                    orderBy={orderBy}
                    sort={sort}
                    detailPage={WeeklyPowerMaterialsDetail}
                    pagination={
                      <Pagination
                        limit={limit}
                        count={count}
                        page={page}
                        changePage={changePage}
                        changeRowsPerPage={changeRowsPerPage}
                      />
                    }
                  />
                )}
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: weeklyPowerMaterialsList
})(
  LoginRequired(
    PermissionsRequired([PERMISSIONS.VIEW_WEEKLY_POWER_MATERIALS])(
      withRouter(WeeklyPowerMaterialsList)
    )
  )
);
