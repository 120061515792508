import React from 'react';

import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { PollDetail } from 'pages';

import { formatDateTime } from 'utils';

import { TableFooter, YesNo } from 'components';

import { POLL_TYPE_CHOICES } from 'pages/Poll/constants';

const PollTable = ({ polls, pagination }) => (
  <Table celled selectable compact>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell singleLine style={{ fontWeight: '400' }} colspan="7">
          {pagination}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <Table.HeaderCell>Question</Table.HeaderCell>
        <Table.HeaderCell>Type</Table.HeaderCell>
        <Table.HeaderCell>Active</Table.HeaderCell>
        <Table.HeaderCell>Count choices</Table.HeaderCell>
        <Table.HeaderCell>Count total votes</Table.HeaderCell>
        <Table.HeaderCell>Date Start</Table.HeaderCell>
        <Table.HeaderCell>Date End</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {polls.map(poll => (
        <Table.Row key={poll.id}>
          <Table.Cell width={6}>
            <Link to={pageUrl(PollDetail, { pollId: poll.id })}>
              {poll.poll.question}
            </Link>
          </Table.Cell>
          <Table.Cell textAlign="center">
            {POLL_TYPE_CHOICES[poll.poll_type]}
          </Table.Cell>
          <Table.Cell textAlign="center">
            <YesNo value={poll.is_active} />
          </Table.Cell>
          <Table.Cell width={2} textAlign="center">
            {poll.poll.count_choices}
          </Table.Cell>
          <Table.Cell width={2} textAlign="center">
            {poll.poll.count_total_votes}
          </Table.Cell>

          <Table.Cell>{formatDateTime(poll.date_start)}</Table.Cell>
          <Table.Cell>{formatDateTime(poll.date_end)}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default PollTable;
