import React from 'react';

import { TransferListField, Field } from 'components';

import { permissionList } from './sdk';

class PermissionSelect extends React.Component {
  state = {
    permissions: []
  };

  async componentDidMount() {
    const { data, success } = await permissionList();

    if (success) {
      const permissions = data.map(perm => {
        return {
          key: perm,
          value: perm,
          label: perm
        };
      });

      this.setState({ permissions: permissions });
    }
  }

  render() {
    const { name, ...rest } = this.props;
    const { permissions } = this.state;

    return (
      <Field
        name={name || 'permission'}
        component={TransferListField}
        options={permissions}
        {...rest}
      />
    );
  }
}

export default PermissionSelect;
