import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { requestSdk, get } from 'sdk';
import { BASE_BACKEND_URL, BASE_URL } from 'sdk/urls';

export const fileUpload = async file => {
  const originalFileName = file.name;
  const extension = originalFileName.split('.').pop();

  const fileName = `${uuidv4()}.${extension}`;

  const formData = new FormData();
  formData.append('file', file, fileName);

  const url = `${BASE_BACKEND_URL}/admin/filer/clipboard/operations/upload/no_folder/?qqfile=${fileName}`;

  const sdk = () =>
    axios.post(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });

  const response = await requestSdk(sdk);

  if (response.success) {
    const { success, data } = await fileDetail(fileName);

    return { success, data, errors: null };
  } else {
    return { success: false, errors: response.errors };
  }
};

export const fileDetail = async fileName => {
  const url = `${BASE_URL}/common/files/${fileName}/`;

  return await requestSdk(() => get(url));
};
