import { hasPermissions, hasHacksoftPermission } from 'permissions/utils';

export const VisibleIf = ({ user, permissions, children }) => {
  const condition = hasPermissions(user, permissions);

  return condition ? children : null;
};

export const VisibleIfSuperuser = ({ user, children }) => {
  return user && user.is_superuser ? children : null;
};

export const VisibleIfHacksoft = ({ user, children }) => {
  return user && hasHacksoftPermission(user) ? children : null;
};
