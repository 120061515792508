import React from 'react';

import _ from 'lodash';
import { Field, Formik } from 'formik';
import { Button, Table } from 'semantic-ui-react';

import { Flex, InputField, SelectField } from 'components';
import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifyErrors, notifySuccess } from 'utils/notifications';

import { presaleAddGroupRateSchema } from './schema';
import { prepareCurrencies } from '../PresaleForm/index';
import {
  presaleGroupRateCreate,
  presaleGroupRateDelete,
  presaleGroupRateUpdate
} from '../PresaleGroupRateModal/sdk';

const buildCurrencyKey = currency => `currency_${currency.id}`;

const DELEGATES = [2, 3, 4, 5];

const PresaleGroupRateForm = ({
  course,
  presale,
  groupRate,
  fetchGroupRates,
  currencies
}) => {
  const getCreateInitialValues = () => {
    const initialValues = {
      delegates: ''
    };

    currencies.forEach(currency => {
      initialValues[buildCurrencyKey(currency)] = 0;
    });

    return initialValues;
  };

  const getUpdateInitialValues = () => {
    const initialValues = {
      delegates: groupRate.delegates
    };

    currencies.forEach(currency => {
      const price = _.find(
        groupRate.prices,
        price => price.currency.toString() === currency.id.toString()
      );

      initialValues[buildCurrencyKey(currency)] = (price && price.value) || 0;
    });

    return initialValues;
  };

  const createGroupRate = async (values, actions) => {
    const { setSubmitting, setFieldError, resetForm } = actions;

    const currencies = prepareCurrencies(values);
    const data = prepareFormData(values);
    data.currencies = currencies;

    setSubmitting(true);

    const { success, errors } = await presaleGroupRateCreate(
      course.id,
      presale.id,
      data
    );

    setSubmitting(false);

    if (success) {
      notifySuccess(`Group Rate for Presale ${presale.title} added.`);
      fetchGroupRates();
      resetForm();
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  const updateGroupRate = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const currencies = prepareCurrencies(values);
    const data = prepareFormData(values);
    data.currencies = currencies;

    setSubmitting(true);

    const { success, errors } = await presaleGroupRateUpdate(
      course.id,
      presale.id,
      groupRate.id,
      data
    );

    setSubmitting(false);

    if (success) {
      notifySuccess(`Group Rate for Presale ${presale.title} updated.`);
      fetchGroupRates();
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  const deleteGroupRate = async () => {
    const confirm = window.confirm(
      `Are you sure you want to remove this group rate?`
    );

    if (!confirm) {
      return;
    }

    const { success, errors } = await presaleGroupRateDelete(
      course.id,
      presale.id,
      groupRate.id
    );

    if (success) {
      notifySuccess(`Group Rate for Presale ${presale.title} removed.`);
      fetchGroupRates();
    } else {
      notifyErrors(errors);
    }
  };

  const handleSubmit = (values, actions) => {
    if (_.isNil(groupRate)) {
      createGroupRate(values, actions);
    } else {
      updateGroupRate(values, actions);
    }
  };

  let initialValues = null;
  if (_.isNil(groupRate)) {
    initialValues = getCreateInitialValues();
  } else {
    initialValues = getUpdateInitialValues();
  }

  const options = DELEGATES.map(delegate => {
    return {
      key: delegate,
      value: delegate,
      label: delegate
    };
  });

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={presaleAddGroupRateSchema}>
      {({ isSubmitting, handleSubmit, values }) => (
        <>
          <Table.Row>
            <Table.Cell width={2}>
              <Field
                required
                name="delegates"
                options={options}
                component={SelectField}></Field>
            </Table.Cell>
            {currencies.map(currency => (
              <Table.Cell key={currency.id}>
                <Field
                  name={buildCurrencyKey(currency)}
                  component={InputField}></Field>
              </Table.Cell>
            ))}
            {groupRate && (
              <Table.Cell textAlign="center">
                <Flex>
                  <Button
                    onClick={handleSubmit}
                    type="submit"
                    color="blue"
                    disabled={isSubmitting}>
                    Update
                  </Button>
                  <Button
                    onClick={deleteGroupRate}
                    color="red"
                    type="submit"
                    disabled={isSubmitting}>
                    Remove
                  </Button>
                </Flex>
              </Table.Cell>
            )}
            {!groupRate && (
              <Table.Cell textAlign="center">
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  color="green"
                  disabled={isSubmitting}>
                  Create
                </Button>
              </Table.Cell>
            )}
          </Table.Row>
        </>
      )}
    </Formik>
  );
};

export default PresaleGroupRateForm;
