import React from 'react';

import { Table } from 'semantic-ui-react';

class UnitConversionRatesTable extends React.Component {
  render() {
    const { unitRates } = this.props;
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>First Unit</Table.HeaderCell>
            <Table.HeaderCell>Second Unit</Table.HeaderCell>
            <Table.HeaderCell>Rate</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {unitRates.map(unitRate => (
            <Table.Row key={unitRate.id}>
              <Table.Cell width={1}>{unitRate.unit_first.title}</Table.Cell>
              <Table.Cell width={1}>{unitRate.unit_second.title}</Table.Cell>
              <Table.Cell width={1}>{unitRate.conversion_rate}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}

export default UnitConversionRatesTable;
