import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const clientGetLatestWeeklyNewsletterIssue = async (
  clientId,
  clientItemId,
  weeklyUrlFragment
) => {
  const url = `${BASE_URL}/crm/clients/${clientId}/get-latest-issue/${weeklyUrlFragment}/${clientItemId}/`;

  return await requestSdk(() => get(url));
};

export const clientSendLatestWeeklyNewsletterIssue = async (
  clientId,
  clientItemId,
  data,
  weeklyUrlFragment
) => {
  const url = `${BASE_URL}/crm/clients/${clientId}/send-latest-issue/${weeklyUrlFragment}/${clientItemId}/`;

  return await requestSdk(() => post(url, data));
};
