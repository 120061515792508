import {
  InputField,
  YesNoSelect,
  AdminSelect,
  EmailTemplateTypeSelect
} from 'components';
import { coerceStringToBoolean } from 'utils';

import styles from './styles.module.css';

export const FILTERS_LAYOUT = [
  [
    {
      name: 'title',
      emptyValue: '',
      component: InputField,
      label: 'Title'
    },
    {
      name: 'subject',
      emptyValue: '',
      component: InputField,
      label: 'Subject'
    },
    {
      name: 'from_name',
      emptyValue: '',
      component: InputField,
      label: 'From Name'
    }
  ],
  [
    {
      name: 'template_type',
      label: 'Type',
      emptyValue: null,
      fieldComponent: EmailTemplateTypeSelect,
      props: () => ({
        className: styles.elevate
      })
    },
    {
      name: 'favourites_only',
      label: 'Show favourites only',
      component: YesNoSelect,
      transform: coerceStringToBoolean,
      emptyValue: false,
      props: () => ({
        isClearable: false,
        className: styles.elevate
      })
    },
    {
      name: 'created_by',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: AdminSelect,
      label: 'Created By',
      props: () => ({
        className: styles.elevate
      })
    },
    {
      name: 'updated_by',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: AdminSelect,
      label: 'Updated By',
      props: () => ({
        className: styles.elevate
      })
    }
  ]
];
