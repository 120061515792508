import { requestSdk, post, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const getPdfUrl = async (id, params) => {
  const url = `${BASE_URL}/newsletters/v2/${id}/printable/`;

  return await requestSdk(() => get(url, params));
};

export const generatePdf = async (id, data) => {
  const url = `${BASE_URL}/newsletters/v2/${id}/printable/`;

  return await requestSdk(() => post(url, data));
};
