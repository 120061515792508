import React from 'react';

import { Table } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import { TableFooter, SortableHeader } from 'components';

import { formatDateTime } from 'utils';

import { ClientLoginsList } from 'pages';

import { getClientDetailUrl } from 'pages/Client/utils';
import { pageUrl } from 'config/routes';

const ClientLoginsTable = ({ clientLogins, orderBy, pagination, sort }) => (
  <Table celled selectable compact={true}>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell singleLine style={{ fontWeight: '400' }} colspan="8">
          {pagination}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <SortableHeader sort={sort} orderBy={orderBy} field="client">
          Client Id
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="email">
          Email
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="first_name">
          First name
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="last_name">
          Last name
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="company_name">
          Company Name
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="login_count">
          Number of logins
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="last_login">
          Last Login
        </SortableHeader>
        <Table.HeaderCell />
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {clientLogins.map(clientLogin => (
        <Table.Row>
          <Table.Cell>
            <Link to={getClientDetailUrl(clientLogin.id)}>
              {clientLogin.id}
            </Link>
          </Table.Cell>
          <Table.Cell>{clientLogin.email}</Table.Cell>
          <Table.Cell>{clientLogin.first_name}</Table.Cell>
          <Table.Cell>{clientLogin.last_name}</Table.Cell>
          <Table.Cell>{clientLogin.company_name}</Table.Cell>
          <Table.Cell>{clientLogin.login_count}</Table.Cell>
          <Table.Cell>{formatDateTime(clientLogin.last_login)}</Table.Cell>
          <Table.Cell>
            <Link
              to={{
                pathname: pageUrl(ClientLoginsList, {
                  userId: clientLogin.id
                })
              }}>
              View All Logins
            </Link>
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default ClientLoginsTable;
