import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const itemCreatePromoCode = async (itemId, data) => {
  const url = `${BASE_URL}/crm/items/promo-codes/${itemId}/create/`;

  return await requestSdk(() => post(url, data));
};

export const itemUpdatePromoCode = async (promoCodeId, data) => {
  const url = `${BASE_URL}/crm/items/promo-codes/${promoCodeId}/update/`;

  return await requestSdk(() => post(url, data));
};

export const itemRemovePromoCode = async (promoCodeId, data) => {
  const url = `${BASE_URL}/crm/items/promo-codes/${promoCodeId}/delete/`;

  return await requestSdk(() => post(url, data));
};
