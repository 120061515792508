import React from 'react';

import { Field, SelectField } from 'components';

const NEWS_OPTIONS = [
  { label: 'Breaking News', value: 'Breaking News' },
  { label: 'Trending Now', value: 'Trending Now' }
];

class InstantNewsTypeSelect extends React.Component {
  render() {
    const { name, isClearable = true, ...rest } = this.props;

    const options = NEWS_OPTIONS.map(item => {
      return {
        key: item.value,
        value: item.value,
        label: item.label
      };
    });

    return (
      <Field
        name={name || 'instantNewsType'}
        component={SelectField}
        options={options}
        isClearable={isClearable}
        {...rest}
      />
    );
  }
}

export default InstantNewsTypeSelect;
