import _ from 'lodash';
import React from 'react';

import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import styles from './styles.module.css';

const SortableItem = sortableElement(({ item, itemComponent }) => {
  const ItemComponent = itemComponent;
  return (
    <div className={styles.SortableItem}>
      <ItemComponent item={item} />
    </div>
  );
});

const NonSortableItem = ({ value }) => (
  <div className={styles.NonSortableItem}>{value}</div>
);

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

class SortableList extends React.Component {
  state = {
    items: []
  };

  componentDidMount() {
    const { items } = this.props;
    this.setState({ items });
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.items, this.props.items)) {
      this.setState({ items: this.props.items });
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const item = this.state.items[oldIndex];

    if (oldIndex === newIndex) {
      return;
    }

    const { onSortEnd } = this.props;

    this.setState(
      ({ items }) => ({
        items: arrayMoveImmutable(items, oldIndex, newIndex)
      }),
      () => onSortEnd({ item, oldIndex, newIndex })
    );
  };

  render() {
    const { items } = this.state;

    const { disabled, itemComponent } = this.props;

    let Item = SortableItem;

    if (disabled) {
      Item = NonSortableItem;
    }

    return (
      <SortableContainer
        onSortEnd={this.onSortEnd}
        useWindowAsScrollContainer={true}
        useDragHandle={true}>
        {items.map((item, index) => (
          <Item
            key={`item-${item.title}-${index}`}
            index={index}
            item={item}
            itemComponent={itemComponent}
          />
        ))}
      </SortableContainer>
    );
  }
}

export default SortableList;
