import React, { useCallback, useState, useEffect } from 'react';

import _ from 'lodash';

import {
  Segment,
  Header,
  Table,
  Dimmer,
  Loader,
  Button
} from 'semantic-ui-react';

import { useParams } from 'react-router-dom';

import { Flex } from 'components';

import { PriceSerieDetail } from 'pages';
import { pageUrl } from 'config/routes';
import { hasPermission, PERMISSIONS } from 'permissions';

import { notifyErrors } from 'utils/notifications';

import { buildFilters } from 'utils/filters';

import { priceSerieForUpdateList } from './sdk';

import { PriceSeriesData } from './components';

import { getPricesData } from './utils';

function PriceSeriesFrequencyTable({
  frequency,
  user,
  filters,
  filterBy,
  disabled,
  steps
}) {
  const { productSlug } = useParams();

  const [loading, setLoading] = useState(false);
  const [priceSeriesHeaders, setPriceSeriesHeaders] = useState([]);
  const [priceSeriesDataRows, setPriceSeriesDataRows] = useState([]);

  const fetchPriceSeriesForUpdate = useCallback(async () => {
    setLoading(true);

    const { data, errors, success } = await priceSerieForUpdateList(
      frequency,
      buildFilters({ ...filters, product_type: productSlug })
    );

    if (success) {
      const { headers, pricesData } = getPricesData(data, frequency);

      setPriceSeriesHeaders(headers);
      setPriceSeriesDataRows(pricesData);
    } else {
      notifyErrors(errors);
    }
    setLoading(false);
  }, [frequency, filters, productSlug]);

  useEffect(() => {
    fetchPriceSeriesForUpdate();
  }, [fetchPriceSeriesForUpdate]);

  const canUserUpdate =
    !disabled && hasPermission(user, PERMISSIONS.CHANGE_PRICES);

  const passFilters = f => {
    const _filterBy = dateFilters => filterBy({ ...filters, ...dateFilters });

    return () => f(filters, _filterBy);
  };

  const {
    onSmallStepBack,
    onBigStepBack,
    onSmallStepForward,
    onBigStepForward
  } = steps || {};

  if (_.isEmpty(priceSeriesDataRows)) {
    return null;
  }

  return (
    <Segment>
      <Flex spaceBetween>
        <Header>{_.capitalize(frequency)} Price Series</Header>

        <div>
          <Button
            icon="angle double left"
            onClick={passFilters(onBigStepBack)}
          />
          <Button icon="angle left" onClick={passFilters(onSmallStepBack)} />

          <Button
            icon="angle right"
            onClick={passFilters(onSmallStepForward)}
          />
          <Button
            icon="angle double right"
            onClick={passFilters(onBigStepForward)}
          />
        </div>
      </Flex>

      {loading && (
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      )}

      <Table celled compact striped selectable definition>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {_.capitalize(frequency)} Price Series
            </Table.HeaderCell>
            {priceSeriesHeaders.map(header => (
              <Table.HeaderCell key={header}>{header}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {priceSeriesDataRows.map((row, index) => (
            <Table.Row key={index}>
              <Table.Cell width={4}>
                {canUserUpdate ? (
                  <a
                    href={pageUrl(PriceSerieDetail, {
                      productSlug,
                      priceSeriesId: row[0].id
                    })}
                    target="_blank"
                    rel="noreferrer">
                    {row[0].title}
                  </a>
                ) : (
                  row[0].title
                )}
              </Table.Cell>
              {row.slice(1).map((item, index) => (
                <PriceSeriesData
                  key={`${item.date}:${item.id}`}
                  priceSeries={item}
                  disabled={!canUserUpdate}
                  fetchForFrequency={fetchPriceSeriesForUpdate}
                  user={user}
                  frequency={frequency}
                />
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Segment>
  );
}

export default PriceSeriesFrequencyTable;
