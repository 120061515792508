import { coerceStringToBoolean } from 'utils';
import {
  InputField,
  YesNoSelect,
  SubscriptionSelect,
  SubscriptionStatusSelect,
  AdminSelect,
  RegionSelect,
  CountrySelect
} from 'components';

export const FILTERS = mailTarget => [
  [
    {
      name: 'client',
      emptyValue: '',
      component: InputField,
      label: 'Client ID'
    },
    {
      name: 'opened',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Is Opened',
      width: 7
    },
    {
      name: 'opens_from_count',
      emptyValue: '',
      component: InputField,
      label: '# of Opens from',
      type: 'number',
      width: 4
    },
    {
      name: 'opens_to_count',
      emptyValue: '',
      component: InputField,
      label: '# of Opens to',
      type: 'number',
      width: 4
    }
  ],
  [
    {
      name: 'mail_target',
      emptyValue: '',
      component: InputField,
      label: `${mailTarget} ID`
    },
    {
      name: 'recipient',
      emptyValue: '',
      component: InputField,
      label: 'Recipient email'
    },
    {
      name: 'company',
      emptyValue: '',
      component: InputField,
      label: 'Company'
    }
  ],
  [
    {
      name: 'region',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: RegionSelect,
      label: 'Region'
    },
    {
      name: 'country',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: CountrySelect,
      label: 'Country'
    },
    {
      name: 'city',
      emptyValue: '',
      component: InputField,
      label: 'City'
    },
    {
      name: 'state',
      emptyValue: '',
      component: InputField,
      label: 'State or Province'
    },
    {
      name: 'postcode',
      emptyValue: '',
      component: InputField,
      label: 'Postcode'
    }
  ],
  // {
  //   name: 'object_id',
  //   emptyValue: ''
  // },
  [
    {
      name: 'account_manager',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: AdminSelect,
      label: 'Account manager'
    },
    {
      name: 'subscription',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: SubscriptionSelect,
      label: 'Subscription'
    },
    {
      name: 'subscription_status',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: SubscriptionStatusSelect,
      label: 'Subscription Status'
    }
  ]
];
