import React, { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';

import { Header, Segment, Button, Divider } from 'semantic-ui-react';

import { withLoggedUser, withPermissions } from 'hooks';
import { Page, Loader, Flex } from 'components';
import OpenModalButton from 'components/OpenModalButton';
import { notifyErrors, notifySuccess } from 'utils/notifications';

import { PERMISSIONS } from 'permissions';

import {
  PermanentPricesTable,
  SectionUpdateForm,
  OrderPricesModal
} from './components';
import {
  newsletterPermanentPricesSectionsList,
  permanentPricesSectionAddContent,
  permanentPricesSectionRemoveContent,
  sectionUpdate
} from './sdk';

import { useParams } from 'react-router-dom';

function PermanentPrices({ user, crumbs }) {
  const [permanentPricesSections, setPermanentPricesSections] = useState(null);

  const { productSlug } = useParams();

  const fetchPermanentPrices = useCallback(async () => {
    const {
      data,
      errors,
      success
    } = await newsletterPermanentPricesSectionsList(productSlug);

    if (success) {
      setPermanentPricesSections(data.results);
    } else {
      notifyErrors(errors);
    }
  }, [productSlug]);

  useEffect(() => {
    fetchPermanentPrices();
  }, [fetchPermanentPrices]);

  const showOrHidePrices = async permanentPriceSection => {
    const { enabled } = permanentPriceSection;

    const postData = {
      section_slug: permanentPriceSection.internal_slug,
      enabled: !enabled
    };
    const { errors, success } = await sectionUpdate(productSlug, postData);

    if (success) {
      const label = enabled ? 'hide' : 'show';
      notifySuccess(`Successfully ${label} permament prices.`);
      fetchPermanentPrices();
    } else {
      notifyErrors(errors);
    }
  };

  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content crumbs={crumbs}>
          <Header as="h1">Permanent Prices Sections</Header>

          {_.isNil(permanentPricesSections) && <Loader />}
          {!_.isNil(permanentPricesSections) &&
            permanentPricesSections.map(permanentPricesSection => (
              <Segment compact style={{ minWidth: '900px' }}>
                <Header as="h3">{permanentPricesSection.display_name}</Header>
                <SectionUpdateForm
                  productSlug={productSlug}
                  section={permanentPricesSection}
                  refetch={fetchPermanentPrices}
                  sectionUpdate={sectionUpdate}
                />
                <Divider />
                <Flex spaceBetween>
                  <Header as="h3">Prices</Header>
                  <Flex>
                    <Button
                      color={permanentPricesSection.enabled ? 'red' : 'green'}
                      disabled={_.isEmpty(
                        _.get(permanentPricesSection, 'section_data.prices')
                      )}
                      onClick={() => showOrHidePrices(permanentPricesSection)}>
                      {permanentPricesSection.enabled ? 'Hide' : 'Show'} prices
                    </Button>
                    <OpenModalButton
                      size="small"
                      color="yellow"
                      disabled={_.isEmpty(
                        _.get(permanentPricesSection, 'section_data.prices')
                      )}
                      modal={{
                        header: 'Order Prices',
                        render: props => (
                          <OrderPricesModal
                            prices={_.get(
                              permanentPricesSection,
                              'section_data.prices'
                            )}
                            refetch={fetchPermanentPrices}
                            productSlug={productSlug}
                            section={permanentPricesSection}
                            {...props}
                          />
                        )
                      }}>
                      Order Prices
                    </OpenModalButton>
                  </Flex>
                </Flex>
                <PermanentPricesTable
                  section={permanentPricesSection}
                  prices={_.get(
                    permanentPricesSection,
                    'section_data.prices',
                    []
                  )}
                  fetchPermanentPrices={fetchPermanentPrices}
                  permanentPriceCreateSdk={permanentPricesSectionAddContent}
                  productSlug={productSlug}
                  priceRemoveSdk={permanentPricesSectionRemoveContent}
                />
              </Segment>
            ))}
        </Page.Content>
      </Page.Body>
    </Page>
  );
}

export default withLoggedUser(
  withPermissions([PERMISSIONS.VIEW_NEWSLETTERS])(PermanentPrices)
);
