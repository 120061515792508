import React from 'react';

import moment from 'moment';

import { Segment, Header, Divider } from 'semantic-ui-react';

import { InvoiceCommentForm } from './components';

import styles from './styles.module.css';

class InvoiceComments extends React.Component {
  render() {
    const { invoice, fetchInvoice } = this.props;

    const comments = invoice.comments;

    return (
      <Segment>
        <Header as="h2">Invoice Comments</Header>
        {comments.map(comment => (
          <>
            <div className={styles.commentWrapper}>
              <div className={styles.label}>Comment</div>
              <Segment>{comment.comment}</Segment>
              <div className={styles.label}> Created by: </div>
              <div>{comment.created_by.email}</div>
              <div className={styles.label}>Created on: </div>
              <div>{moment(comment.created_at).format('DD/MM/YYYY')}</div>
            </div>
            <Divider />
          </>
        ))}
        <InvoiceCommentForm invoice={invoice} fetchInvoice={fetchInvoice} />
      </Segment>
    );
  }
}

export default InvoiceComments;
