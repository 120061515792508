import React from 'react';

import _ from 'lodash';

import { Field, SelectField } from 'components';

class NumberRangeSelect extends React.Component {
  render() {
    const { name, from, to, ...rest } = this.props;

    const x = from || 0;
    const y = (to || 10) + 1;

    const options = _.range(x, y).map(time => {
      return {
        key: time,
        value: time,
        label: time
      };
    });

    return (
      <Field
        name={name || 'numberRangeSelect'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default NumberRangeSelect;
