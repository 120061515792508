import * as yup from 'yup';
import { getToday } from 'utils/forms';

export const DEFAULT_INITIAL_VALUES = {
  title: '',
  slug: '',
  titleWeb: '',
  image: null,
  file: null,
  applicationFile: null,
  duration: '',
  durationType: 'Days',
  dateType: 'Fixed Date',
  dateStart: getToday(),
  taxName: '',
  taxValue: null,
  dateActivation: getToday(),
  dateCustom: '',
  country: null,
  city: '',
  topic: null,
  subject: null,
  videoTitle: '',
  video: null,
  trainersTitle: '',
  trainers: [],
  isConference: false,
  isFree: false,
  trainersTextOnTop: '',
  trainersTextOnBottom: '',
  agendaTextOnTop: '',
  agendaTextOnBottom: '',
  attendeesText: '',
  sponsorship: null,
  hotelBookingForm: null,
  hotelBookingLink: null,
  beforeAttendeesText: '',
  afterAttendeesText: '',
  showCurrentAttendeeList: false,
  previousEvents: [],
  eventType: null,
  isRegistrationOpen: true,
  registrationType: 'Standard',
  customRegistrationUrl: ''
};

export const courseFormSchema = yup.object().shape(
  {
    title: yup.string().required('Title is required.'),
    slug: yup.string().required('Slug is required.'),
    titleWeb: yup.string().required('Event name is required.'),
    duration: yup
      .number()
      .typeError('Please enter a valid number')
      .when('durationType', ([durationType], schema) => {
        return schema.required(`Number of ${durationType} is required.`);
      }),
    taxName: yup
      .string()
      .nullable()
      .when('taxValue', {
        is: taxValue => taxValue && taxValue !== 0,
        then: schema => schema.required('Tax name is required.')
      }),
    taxValue: yup
      .number()
      .min(1)
      .nullable()
      .when('taxName', {
        is: taxName => taxName && taxName.length !== 0,
        then: schema => schema.required('Tax value is required.').max(100)
      }),
    dateStart: yup.string().required('Date start is required.'),
    dateActivation: yup.string().required('Activation date is required.'),
    eventType: yup
      .string()
      .nullable()
      .required('Event Type is required.'),
    customRegistrationUrl: yup
      .string()
      .nullable()
      .when('registrationType', {
        is: 'Custom',
        then: schema => schema.required('Custom registration url is required.')
      })
  },
  [['taxName', 'taxValue']]
);
