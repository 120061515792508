import * as yup from 'yup';
import 'utils/yup';

export const clientCreateSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required.')
    .strongEmail('Invalid email.'),
  priority: yup
    .string()
    .nullable()
    .required('Priority is required'),
  commodity: yup
    .string()
    .nullable()
    .required('Commodity is required'),
  industry: yup
    .string()
    .nullable()
    .required('Industry is required')
});
