import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const mailshotGroupDetail = async id => {
  const url = `${BASE_URL}/emails/mailshot-groups/${id}/`;

  return await requestSdk(() => get(url));
};

export const mailshotGroupItems = async id => {
  const url = `${BASE_URL}/emails/mailshot-groups/${id}/items/`;

  return await requestSdk(() => get(url));
};
