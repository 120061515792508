import React from 'react';

import { withRouter } from 'react-router-dom';

import { Header, Segment } from 'semantic-ui-react';

import { Page, LoginRequired } from 'components';

import { FaqCreateForm } from './components';

class FaqCreate extends React.Component {
  render() {
    const { user, crumbs } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Header as="h1">Create FAQ</Header>
              <FaqCreateForm />
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(withRouter(FaqCreate));
