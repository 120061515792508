import React from 'react';

import { Field, SelectField } from 'components';

const ENERGY_GROUPS = [
  'Natural Gas',
  'Petrochemicals',
  'Renewables',
  'LNG',
  'Pipelines & Infrastructure',
  'Corporate & Other'
];

class EnergyGroupSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = ENERGY_GROUPS.map(group => {
      return {
        key: group,
        value: group,
        label: group
      };
    });

    return (
      <Field
        name={name || 'energyGroup'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default EnergyGroupSelect;
