import React from 'react';

import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { formatDate } from 'utils';

class PriceSeriesMovementTable extends React.Component {
  getPriceSeriesDetailUrl = priceSeriesId => {
    return pageUrl(this.props.detailPage, { priceSeriesId });
  };

  getPriceSeriesUpdateUrl = priceSeries => {
    return {
      pathname: pageUrl(this.props.updatePage),
      search: `?id=${priceSeries.price_series.id}&date=${priceSeries.date}`
    };
  };

  render() {
    const { priceSeries } = this.props;
    return (
      <Table celled selectable compact={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Latest Update</Table.HeaderCell>
            <Table.HeaderCell>Product Group</Table.HeaderCell>
            <Table.HeaderCell>Product</Table.HeaderCell>
            <Table.HeaderCell>Frequency</Table.HeaderCell>
            <Table.HeaderCell>Latest Price</Table.HeaderCell>
            <Table.HeaderCell>Change</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {priceSeries.map(price => (
            <Table.Row key={price.id}>
              <Table.Cell>{formatDate(price.date)}</Table.Cell>
              <Table.Cell>{price.price_series.category}</Table.Cell>
              <Table.Cell>
                <Link to={this.getPriceSeriesDetailUrl(price.price_series.id)}>
                  {price.price_series.title}
                </Link>
              </Table.Cell>
              <Table.Cell>{price.price_series.frequency}</Table.Cell>
              <Table.Cell>
                <Link to={this.getPriceSeriesUpdateUrl(price)}>
                  {price.value_1}
                  {price.value_2 && -price.value_2}{' '}
                  {price.price_series.formatted_metrics}
                </Link>
              </Table.Cell>
              <Table.Cell>{price.diff}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}

export default PriceSeriesMovementTable;
