import React from 'react';

import { Segment, Table } from 'semantic-ui-react';

import { Admin } from 'components';

import { formatDateTime } from 'utils';

const NewsletterLiveValues = ({ newsletter }) => (
  <Segment>
    <Table basic="very" celled collapsing>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Date to go out</Table.Cell>
          <Table.Cell>{formatDateTime(newsletter.datetime)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Editor</Table.Cell>
          <Table.Cell>
            <Admin value={newsletter.editor} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Marketer</Table.Cell>
          <Table.Cell>
            <Admin value={newsletter.marketer} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Selected event</Table.Cell>
          <Table.Cell>{newsletter.default_course_title || 'N/A'}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Created by</Table.Cell>
          <Table.Cell>
            <Admin value={newsletter.created_by} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Created at</Table.Cell>
          <Table.Cell>{formatDateTime(newsletter.created_at)}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Updated by</Table.Cell>
          <Table.Cell>
            <Admin value={newsletter.updated_by} />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Updated at</Table.Cell>
          <Table.Cell>{formatDateTime(newsletter.updated_at)}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  </Segment>
);

export default NewsletterLiveValues;
