import React from 'react';

import _ from 'lodash';

import { Header, Segment, Button, Label, Divider } from 'semantic-ui-react';

import { withRouter, Link } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import {
  MailshotClients,
  MailshotSelectedClients,
  MailshotGroupDetail
} from 'pages';

import {
  Page,
  LoginRequired,
  Flex,
  EmailVariables,
  EmailStatistics,
  ContentEditionWarning,
  Tooltip
} from 'components';

import {
  PermissionsRequired,
  PERMISSIONS,
  DisableableElement,
  hasPermission
} from 'permissions';

import { notifySuccess, notifyErrors } from 'utils/notifications';

import { EDITION_WARNING_CONTENT_TYPES } from 'domainConstants';

import { mailshotDetail, mailshotUpdateStatus } from './sdk';

import { MailshotUpdateForm, MailshotLinks } from './components';
import { MailshotAddLink } from './components';

import { isDraftStatus } from './utils';

import styles from './styles.module.css';

class MailshotDetail extends React.Component {
  state = {
    errors: null,
    mailshot: null
  };

  fetchMailshot = async () => {
    const { mailshotId } = this.props.match.params;

    const { data, errors, success } = await mailshotDetail(mailshotId);

    if (success) {
      this.setState({
        mailshot: data
      });
    } else {
      this.setState({ errors });
    }
  };

  componentDidMount() {
    this.fetchMailshot();
  }

  goToSelectUsers = () => {
    const { mailshotId } = this.props.match.params;

    const url = `${pageUrl(MailshotClients, {
      mailshotId
    })}?unsubscribed=false&is_in_sanctioned_country=false`;

    this.props.history.push(url);
  };

  goToSelectedUsers = () => {
    const { mailshotId } = this.props.match.params;

    const url = pageUrl(MailshotSelectedClients, { mailshotId });

    this.props.history.push(url);
  };

  changeStatus = async () => {
    const { mailshot } = this.state;
    const status = isDraftStatus(mailshot) ? 'Ready' : 'Draft';

    const confirm = window.confirm(
      `Are you sure you want to change the status to ${status}?`
    );

    if (!confirm) {
      return;
    }

    const postData = {
      status
    };

    const { success, errors } = await mailshotUpdateStatus(
      mailshot.id,
      postData
    );

    if (success) {
      this.fetchMailshot();
      notifySuccess('Mailshot preparation started.');
    } else {
      notifyErrors(errors);
    }
  };

  render() {
    const { user, crumbs } = this.props;

    const { mailshot } = this.state;

    const hasCMUsers = mailshot && !_.isEmpty(mailshot.cm_users);
    const isDraft = mailshot && isDraftStatus(mailshot);
    const isInGroup = mailshot && !_.isNull(mailshot.mailshot_group_item);

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            {!_.isNil(mailshot) && (
              <ContentEditionWarning
                user={user}
                contentType={EDITION_WARNING_CONTENT_TYPES.MAILSHOT}
                contentId={mailshot.id}
              />
            )}

            <Segment>
              <Flex spaceBetween>
                <Header as="h1">Mailshot Detail </Header>
                <div className={styles.btnsRow}>
                  {mailshot && (
                    <>
                      <Button
                        basic
                        color="teal"
                        onClick={this.goToSelectedUsers}>
                        Selected users
                      </Button>
                      {mailshot.status !== 'Sent' && (
                        <Button
                          color="teal"
                          onClick={this.goToSelectUsers}
                          disabled={
                            !hasPermission(user, PERMISSIONS.CHANGE_MAILSHOTS)
                          }>
                          Select users
                        </Button>
                      )}
                      <Tooltip
                        disabled={!hasCMUsers || !isDraft}
                        on="hover"
                        content="Remove CM accounts to be able to mark the mailshot as ready."
                        trigger={
                          <div>
                            <Button
                              color="red"
                              onClick={this.changeStatus}
                              disabled={hasCMUsers && isDraft}>
                              Mark as {isDraft ? 'Ready' : 'Draft'}
                            </Button>
                          </div>
                        }
                      />
                    </>
                  )}
                </div>
              </Flex>
              {!_.isNil(mailshot) && (
                <DisableableElement
                  disabled={!hasPermission(user, PERMISSIONS.CHANGE_MAILSHOTS)}>
                  <span className={styles.statusTitle}>Status:</span>
                  <Label color="black" style={{ marginRight: '20px' }}>
                    {mailshot.status}
                  </Label>

                  <span className={styles.statusTitle}>Sending status:</span>
                  <Label color="black">{mailshot.sending_status}</Label>

                  {hasCMUsers && isDraft ? (
                    <Segment color="red" inverted>
                      CM clients found! Please, remove them from the selected
                      clients list in order to be able to mark the mailshot as
                      ready. Clients: {mailshot.cm_users.join(', ')}
                    </Segment>
                  ) : (
                    undefined
                  )}
                  {isInGroup && (
                    <Segment color="teal" inverted>
                      Mailshot is part of a group:{' '}
                      <Link
                        to={pageUrl(MailshotGroupDetail, {
                          mailshotGroupId:
                            mailshot.mailshot_group_item.mailshot_group.id
                        })}
                        className={styles.link}>
                        {mailshot.mailshot_group_item.mailshot_group.title}
                      </Link>
                    </Segment>
                  )}

                  <Divider />

                  <MailshotUpdateForm
                    data={mailshot}
                    fetchMailshot={this.fetchMailshot}
                  />
                </DisableableElement>
              )}
            </Segment>
            <Segment className={styles.Grid}>
              {!_.isNil(mailshot) && (
                <EmailStatistics
                  data={{
                    total: mailshot.total_emails,
                    sent: mailshot.total_sent,
                    opened: mailshot.total_opened,
                    bounced: mailshot.total_bounced,
                    totalClicks: mailshot.total_clicks,
                    uniqueClicks: mailshot.unique_clicks,
                    ctr: mailshot.ctr,
                    uctr: mailshot.uctr,
                    openRate: mailshot.open_rate
                  }}
                />
              )}
              <EmailVariables />
            </Segment>
            {!_.isNil(mailshot) && (
              <Segment>
                <Header as="h3">Mailshot Links</Header>
                {!_.isEmpty(mailshot.links) && (
                  <MailshotLinks links={mailshot.links} />
                )}
                {_.isEmpty(mailshot.links) && (
                  <div>There is no mailshot links yet.</div>
                )}
                <Divider />
                <MailshotAddLink
                  mailshot={mailshot}
                  fetchMailshot={this.fetchMailshot}
                  isInGroup={isInGroup}
                />
              </Segment>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(
  PermissionsRequired([PERMISSIONS.VIEW_MAILSHOTS])(withRouter(MailshotDetail))
);
