import React from 'react';

import { Field, TransferListField } from 'components';
import { courseTrainerList } from './sdk';

class CourseTrainerSelect extends React.Component {
  state = {
    courseTrainers: []
  };
  async componentDidMount() {
    const { data, success } = await courseTrainerList();

    if (success) {
      this.setState({ courseTrainers: data });
    }
  }

  render() {
    const { name, ...rest } = this.props;
    const { courseTrainers } = this.state;

    const options = courseTrainers.map(courseTrainer => {
      return {
        key: courseTrainer.id,
        value: courseTrainer.id,
        label: courseTrainer.name
      };
    });

    return (
      <Field
        name={name || 'courseTrainer'}
        component={TransferListField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default CourseTrainerSelect;
