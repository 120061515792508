import React from 'react';

import _ from 'lodash';

import { Header, Segment } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { ClientDetail } from 'pages';

import { CorporateMasterAccountAddForm } from './components';

class NonCorporateMasterClientProfileForm extends React.Component {
  getClientDetailUrl = clientId => {
    return pageUrl(ClientDetail, { clientId });
  };

  render() {
    const { client, fetchClient } = this.props;
    const corporateMasterAccount = _.get(
      client,
      'client_profile.corporate_master_account',
      null
    );

    return (
      <Segment>
        {!_.isNil(corporateMasterAccount) && (
          <>
            <Header>Linked CM account</Header>
            <div>
              CM Account:
              <a href={this.getClientDetailUrl(corporateMasterAccount.id)}>
                {corporateMasterAccount.id}
              </a>
            </div>
          </>
        )}
        {_.isNil(corporateMasterAccount) && (
          <CorporateMasterAccountAddForm
            client={client}
            fetchClient={fetchClient}
          />
        )}
      </Segment>
    );
  }
}

export default NonCorporateMasterClientProfileForm;
