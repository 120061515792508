import React from 'react';

import { Field, SelectField } from 'components';
import { priceSeriesCategoryList } from './sdk';

class PriceSeriesCategorySelect extends React.Component {
  state = {
    priceSeriesCategories: []
  };

  async componentDidMount() {
    const { isPowerMaterial } = this.props;
    let params = {};

    if (isPowerMaterial) {
      params = { is_power_material_related: isPowerMaterial };
    }
    const { data, success } = await priceSeriesCategoryList(params);

    if (success) {
      this.setState({ priceSeriesCategories: data });
    }
  }

  render() {
    const { name, isCustom, ...rest } = this.props;
    const { priceSeriesCategories } = this.state;

    const options = priceSeriesCategories.map(priceSeriesCategory => {
      return {
        key: priceSeriesCategory.id,
        value: priceSeriesCategory.id,
        label: priceSeriesCategory.title
      };
    });

    return (
      <Field
        name={name || 'priceSeriesCategory'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default PriceSeriesCategorySelect;
