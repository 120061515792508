import _ from 'lodash';
import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const saveRecipientGroup = async data => {
  let url = `${BASE_URL}/newsletters/v2/${data.product_type}/recipient-groups/create/`;
  if (!_.isNil(data.id)) {
    url = `${BASE_URL}/newsletters/v2/recipient-groups/${data.id}/update/`;
  }
  return await requestSdk(() => post(url, data));
};

export const deleteRecipientGroup = async sdkArgs => {
  const { itemId } = sdkArgs;
  let url = `${BASE_URL}/newsletters/v2/recipient-groups/${itemId}/delete/`;
  return await requestSdk(() => post(url, {}));
};
