import React from 'react';

import { Header, Segment } from 'semantic-ui-react';

import { withRouter } from 'react-router-dom';

import { Page, LoginRequired } from 'components';

import { MarketDataReportCreateForm } from './components';

class MarketDataReportCreate extends React.Component {
  render() {
    const { user, crumbs } = this.props;
    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Header as="h1">Add Market Data and Report</Header>
              <MarketDataReportCreateForm />
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(withRouter(MarketDataReportCreate));
