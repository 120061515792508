import React from 'react';

import { withRouter } from 'react-router-dom';

import _ from 'lodash';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import { pageUrl } from 'config/routes';

import { TradeExcelList } from 'pages';

import {
  Field,
  FormRow,
  DjangoFileField,
  CheckboxField,
  CreateButtonInRow,
  UnsavedFormPrompt
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';

import { tradeExcelCreate } from './sdk';
import { tradeExcelCreateSchema } from './utils';

class TradeExcelCreateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    const formData = new FormData();

    _.mapKeys(data, (value, key) => formData.append(key, value));

    setSubmitting(true);

    const { errors, success } = await tradeExcelCreate(formData);

    if (success) {
      this.props.history.push(pageUrl(TradeExcelList));
      return;
    }

    handleFormErrors(errors, setFieldError);
    setSubmitting(false);
  };

  render() {
    const initialValues = {
      tradeExcel: null,
      isPublished: false
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        validationSchema={tradeExcelCreateSchema}>
        {({ values, handleSubmit, isSubmitting, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty && !isSubmitting} />
            <FormRow widths="equal">
              <Field
                required
                name="tradeExcel"
                component={DjangoFileField}
                label="Trade excel"
                accept={{
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
                    '.xlsx'
                  ],
                  'application/vnd.ms-excel': ['.xls']
                }}
              />
            </FormRow>

            <FormRow widths="equal">
              <Field
                name="isPublished"
                component={CheckboxField}
                label="Published"
              />
            </FormRow>
            <CreateButtonInRow onClick={handleSubmit} disabled={isSubmitting} />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(TradeExcelCreateForm);
