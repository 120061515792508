import _ from 'lodash';

export const getLinkedClientData = client => ({
  linkedClientId: _.get(client.client_profile.linked_client, 'id'),
  linkedClientNames: `${_.get(
    client.client_profile.linked_client,
    'first_name',
    ''
  )} ${_.get(client.client_profile.linked_client, 'last_name', '')}`,
  linkedClientCompany: _.get(
    client.client_profile.linked_client,
    'company_name'
  )
});
