import _ from 'lodash';

import { notifyError } from 'utils/notifications';

export const handleFileRejections = (fileRejections, accept) => {
  const acceptedExtensions = _.join(_.flatten(_.values(accept)), ', ');

  for (const rejection of fileRejections) {
    for (const error of rejection.errors) {
      if (error.code === 'file-invalid-type') {
        notifyError(
          `File has invalid extension. It should be one of: ${acceptedExtensions}`
        );
      } else {
        notifyError(error.message);
      }
    }
  }
};
