import { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { notifyErrors, notifySuccess } from 'utils/notifications';
import { pageUrl } from 'config/routes';
import { PriceSpreadV2List } from 'pages';

import { priceSpreadV2Detail, priceSpreadV2Delete } from './sdk';

export const usePriceSpread = history => {
  const [priceSpread, setPriceSpread] = useState(null);

  const { priceSpreadId, productSlug } = useParams();

  const fetchPriceSpread = useCallback(() => {
    priceSpreadV2Detail(priceSpreadId).then(({ data, errors, success }) => {
      if (success) {
        setPriceSpread(data);
      } else {
        notifyErrors(errors);
        setPriceSpread(null);
      }
    });
  }, [priceSpreadId]);

  useEffect(() => {
    fetchPriceSpread();
  }, [fetchPriceSpread]);

  const deletePriceSpread = useCallback(() => {
    priceSpreadV2Delete(priceSpreadId).then(({ errors, success }) => {
      if (success) {
        notifySuccess('Price spread deleted.');
        history.push(pageUrl(PriceSpreadV2List, { productSlug }));
      } else {
        notifyErrors(errors);
      }
    });
  }, [priceSpreadId, productSlug, history]);

  return [priceSpread, setPriceSpread, deletePriceSpread];
};
