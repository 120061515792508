import React from 'react';

import { Divider } from 'semantic-ui-react';

import { populateSlug, noop } from 'utils';

import {
  Field,
  FormRow,
  InputField,
  UniSelect,
  CheckboxField,
  UnsavedFormPrompt
} from 'components';

const PriceSpreadV2Form = ({
  FormikProps: { dirty, setFieldValue, isSubmitting },
  productSlug,
  onTitleChange = noop
}) => {
  return (
    <>
      <UnsavedFormPrompt when={dirty && !isSubmitting} />
      <FormRow>
        <Field
          required
          width={6}
          name="title"
          label="Title:"
          component={InputField}
          onChange={onTitleChange}
        />

        <Field
          required
          width={4}
          name="shortTitle"
          label="Short title:"
          component={InputField}
        />
      </FormRow>
      <FormRow>
        <Field
          required
          width={6}
          name="slug"
          label="Slug:"
          component={InputField}
          onChange={(e, data) => {
            populateSlug(data, setFieldValue);
          }}
        />
        <Field
          required
          width={4}
          name="displayAs"
          label="Display as:"
          component={InputField}
        />
        <Field
          required
          width={2}
          name="activeInWebsite"
          label="Show on homepage"
          component={CheckboxField}
        />
      </FormRow>
      <FormRow>
        <UniSelect
          required
          width={6}
          name="priceSerie1"
          label="Price Serie 1:"
          source="priceSeries"
          productSlug={productSlug}
        />

        <UniSelect
          required
          width={6}
          name="priceSerie2"
          label="Price Serie 2:"
          source="priceSeries"
          productSlug={productSlug}
        />
      </FormRow>
      <Divider />
      <FormRow widths="equal">
        <Field name="metaTitle" label="Meta Title" component={InputField} />

        <Field
          name="metaKeywords"
          label="Meta Keywords"
          component={InputField}
        />

        <Field
          name="metaDescription"
          label="Meta Description"
          component={InputField}
        />

        <Field
          name="metaStandout"
          label="Meta Standout"
          component={InputField}
        />
      </FormRow>
    </>
  );
};

export default PriceSpreadV2Form;
