import { DateField, AuthorSelect, SelectField } from 'components';

import moment from 'moment';

import { formatDateForApi } from 'utils';

const slugOptions = [
  {
    key: 'Steel',
    value: 'Steel',
    label: 'Steel'
  },
  {
    key: 'PowerMaterials',
    value: 'Power Materials',
    label: 'Power Materials'
  }
];

export const FILTERS = [
  [
    {
      name: 'author',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: AuthorSelect,
      label: 'Author',
      width: 5
    },
    {
      name: 'editor',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: AuthorSelect,
      label: 'Edited By',
      width: 5
    },
    {
      name: 'article_type',
      emptyValue: '',
      component: SelectField,
      label: 'Article Type',
      width: 4,
      props: () => ({
        options: slugOptions,
        isClearable: true
      })
    }
  ],
  [
    {
      name: 'start',
      init: formatDateForApi(moment().startOf('month')),
      component: DateField,
      label: 'Date from',
      width: 4
    },
    {
      name: 'end',
      emptyValue: formatDateForApi(moment().endOf('month')),
      component: DateField,
      label: 'Date to',
      width: 4
    }
  ]
];
