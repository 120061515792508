import _ from 'lodash';

import React from 'react';

import { withRouter } from 'react-router-dom';

import { Segment, Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  FormRow,
  AdminSelect,
  YesNoSelect,
  PriceSeriesSelect,
  DateTimeField,
  UpdateButtonInRow,
  UnsavedFormPrompt,
  UniSelect
} from 'components';
import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { dailyNewsletterUpdateSchema } from './schema';
import { dailyNewsletterUpdate } from './sdk';

class DailyNewsletterUpdateForm extends React.Component {
  state = {
    upcomingCourse: null
  };

  handleSubmit = async (values, actions) => {
    const {
      dailyNewsletter,
      fetchDailyNewsletter,
      fetchDailyNewsletterPrices,
      hasArticlesWithoutSection
    } = this.props;

    const { setSubmitting, setFieldError } = actions;

    if (
      values.editorApproved &&
      values.marketingApproved &&
      hasArticlesWithoutSection
    ) {
      const confirm = window.confirm(
        'There are unassigned articles. Are you sure you want to proceed?'
      );

      if (!confirm) {
        setSubmitting(false);
        return;
      }
    }

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await dailyNewsletterUpdate(
      dailyNewsletter.id,
      data
    );

    setSubmitting(false);

    if (success) {
      fetchDailyNewsletter();
      fetchDailyNewsletterPrices();
      notifySuccess('Daily newsletter updated.');
      return;
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  render() {
    const { dailyNewsletter } = this.props;
    const { upcomingCourse } = this.state;

    const initialValues = {
      datetime: dailyNewsletter.datetime,
      editor: dailyNewsletter.editor.id,
      marketer: dailyNewsletter.marketer.id,
      priceSeries: dailyNewsletter.price_series.map(ps => ps.id),
      editorApproved: dailyNewsletter.editor_approved,
      marketingApproved: dailyNewsletter.marketing_approved,
      defaultCourse: _.isNil(dailyNewsletter.default_course)
        ? upcomingCourse
        : dailyNewsletter.default_course
    };

    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={dailyNewsletterUpdateSchema}
        onSubmit={this.handleSubmit}>
        {({ values, handleSubmit, isSubmitting, dirty }) => (
          <Segment>
            <Form>
              <UnsavedFormPrompt when={dirty} />
              <Field
                required
                name="editor"
                component={AdminSelect}
                label="Editor"
                isClearable={false}
              />

              <Field
                required
                name="marketer"
                component={AdminSelect}
                label="Marketer"
                isClearable={false}
              />

              <FormRow widths="equal">
                <Field
                  name="editorApproved"
                  component={YesNoSelect}
                  label="Editor Approved"
                  isClearable={false}
                />

                <Field
                  name="marketingApproved"
                  component={YesNoSelect}
                  label="Marketer Approved"
                  isClearable={false}
                />
              </FormRow>

              <Field
                required
                width={10}
                name="datetime"
                component={DateTimeField}
                label="Date to go Out"
              />

              <UniSelect
                width={16}
                name="defaultCourse"
                label="Selected event"
                source="upcomingEvents"
                params={{ order: 'date_start' }}
                apiSuccessCallback={upcomingCourses => {
                  if (
                    _.isArray(upcomingCourses) &&
                    !_.isEmpty(upcomingCourses)
                  ) {
                    this.setState({ upcomingCourse: upcomingCourses[0].id });
                  }
                }}
              />

              <Field
                name="priceSeries"
                component={PriceSeriesSelect}
                label="Price series"
                isMulti
                closeMenuOnSelect={false}
              />

              <UpdateButtonInRow
                subject="Daily Newsletter"
                onClick={handleSubmit}
                disabled={isSubmitting}
              />
            </Form>
          </Segment>
        )}
      </Formik>
    );
  }
}

export default withRouter(DailyNewsletterUpdateForm);
