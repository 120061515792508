import React from 'react';

import _ from 'lodash';

import { Table } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { ClientDetail } from 'pages';

import { formatDateTime } from 'utils';

import { TableFooter, YesNo } from 'components';

import { SUBSCRIPTIONS } from 'pages/FormsDashboard/constants';

import { hasSubscription } from './utils';

const TrackingSubscriptionEventsTable = ({ data, pagination }) => (
  <Table celled selectable compact={true}>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell
          singleLine
          colspan={`${_.keys(SUBSCRIPTIONS).length + 5}`}
          style={{ fontWeight: '400' }}>
          {pagination}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <Table.HeaderCell>Client id</Table.HeaderCell>
        <Table.HeaderCell>Names</Table.HeaderCell>
        <Table.HeaderCell>Company Name</Table.HeaderCell>
        <Table.HeaderCell>Account Manager</Table.HeaderCell>
        <Table.HeaderCell>Date</Table.HeaderCell>
        {_.keys(SUBSCRIPTIONS).map(sub => (
          <Table.HeaderCell>{sub}</Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {data.map(log => (
        <Table.Row key={log.id}>
          <Table.Cell width={1}>
            {log.user && (
              <Link
                to={pageUrl(ClientDetail, {
                  clientId: log.user.id
                })}>
                {log.user.id}
              </Link>
            )}
            {!log.user && 'N/A'}
          </Table.Cell>
          <Table.Cell width={2}>
            {_.get(log, 'user.first_name')} {_.get(log, 'user.last_name')}
          </Table.Cell>
          <Table.Cell width={2}>{_.get(log, 'user.company_name')}</Table.Cell>
          <Table.Cell width={2}>
            {_.get(log, 'user.account_manager.first_name')}{' '}
            {_.get(log, 'user.account_manager.last_name')}
          </Table.Cell>

          <Table.Cell>{formatDateTime(log.created_at)}</Table.Cell>
          {_.keys(SUBSCRIPTIONS).map(sub => (
            <Table.Cell>
              <YesNo value={hasSubscription(log, sub)} />
            </Table.Cell>
          ))}
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default TrackingSubscriptionEventsTable;
