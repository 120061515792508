import * as yup from 'yup';

import { formatDateTime } from 'utils';

export const MAILSHOT_EMAIL_OPTIONS = [
  {
    key: 'news',
    value: 'news@kallanish.com',
    label: 'news@kallanish.com'
  },
  {
    key: 'marketing',
    value: 'marketing@kallanish.com',
    label: 'marketing@kallanish.com'
  }
];

export const mailshotValidationSchema = yup.object().shape({
  scheduledDate: yup
    .string()
    .nullable()
    .required('Scheduled date is required.'),
  fromName: yup.string().required('Sender name is required.'),
  fromEmail: yup
    .string()
    .nullable()
    .required('Sender email is required.'),
  title: yup.string().required('Mailshot title is required.'),
  subject: yup.string().required('Subject is required.')
});

export const getNonFormItems = values => [
  {
    label: 'Date sent',
    value: formatDateTime(values.dateSent)
  },
  {
    label: 'Return path',
    value: values.returnPath
  },
  {
    label: 'Status',
    value: values.status
  }
];
