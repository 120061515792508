import React from 'react';
import _ from 'lodash';

import { withRouter } from 'react-router-dom';

import { Segment } from 'semantic-ui-react';

import {
  Page,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Loader
} from 'components';
import { pageUrl } from 'config/routes';
import { NotFound } from 'pages';

import { newsletterReceiversList } from './sdk';

import { NewsletterReceiversTable, Filters } from './components';

const DEFAULT_LIMIT = 50;

const NEWSLETTER_PARAMS_TO_TITLE = {
  na: 'North America',
  // TODO remove this code when energy is finally stop
  // energy: 'Energy',
  daily: 'Daily',
  'weekly-steel': 'Weekly Steel',
  'weekly-seasm': 'Weekly SEASM',
  'power-material': 'Power Materials',
  hydrogen: 'Daily Hydrogen'
};

class NewsletterReceiversList extends React.Component {
  componentDidMount() {
    const { newsletter } = this.props.match.params;

    if (_.indexOf(_.keys(NEWSLETTER_PARAMS_TO_TITLE), newsletter) < 0) {
      this.props.history.push(pageUrl(NotFound));
    }
  }

  render() {
    const {
      user,
      data,
      limit,
      count,
      page,
      changePage,
      changeRowsPerPage,
      orderBy,
      sort,
      filters,
      clear,
      filterBy,
      match: {
        params: { newsletter }
      }
    } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content>
            <Header>
              {NEWSLETTER_PARAMS_TO_TITLE[newsletter]} Newsletter Receivers
            </Header>
            <Filters
              filters={filters}
              count={count}
              clearFilters={clear}
              filterBy={filterBy}
            />
            {!data && (
              <Segment>
                <Loader />
              </Segment>
            )}
            {data && (
              <>
                <NewsletterReceiversTable
                  sort={sort}
                  orderBy={orderBy}
                  data={data}
                  pagination={
                    <Pagination
                      limit={limit}
                      count={count}
                      page={page}
                      changePage={changePage}
                      changeRowsPerPage={changeRowsPerPage}
                    />
                  }
                />
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: props => {
    const { newsletter } = props.match.params;
    return _.partial(newsletterReceiversList, newsletter);
  },
  passPropsToSdk: true,
  limit: DEFAULT_LIMIT
})(LoginRequired(withRouter(NewsletterReceiversList)));
