import React from 'react';

import { Field, SelectField } from 'components';
import { articleTopicList } from './sdk';

class ArticleTopicSelect extends React.Component {
  state = {
    articleTopics: []
  };
  async componentDidMount() {
    const { data, success } = await articleTopicList();

    if (success) {
      this.setState({ articleTopics: data });
    }
  }

  render() {
    const { name, isCustom, ...rest } = this.props;
    const { articleTopics } = this.state;

    const options = articleTopics.map(articleTopic => {
      return {
        key: articleTopic.id,
        value: articleTopic.id,
        label: articleTopic.title
      };
    });

    return (
      <Field
        name={name || 'articleTopic'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default ArticleTopicSelect;
