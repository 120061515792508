import React from 'react';

import { Field, SelectField } from 'components';
const PAYMENT_TYPES = ['Itemized', 'Combined'];

class PaymentTypeSelect extends React.Component {
  render() {
    const { name, isClearable = true, ...rest } = this.props;

    const options = PAYMENT_TYPES.map(item => {
      return {
        key: item,
        value: item,
        label: item
      };
    });

    return (
      <Field
        name={name || 'paymentType'}
        component={SelectField}
        options={options}
        isClearable={isClearable}
        {...rest}
      />
    );
  }
}

export default PaymentTypeSelect;
