import React from 'react';

import _ from 'lodash';

const Admin = ({ value }) => {
  let name = 'N/A';

  if (!_.isNil(value)) {
    name = value.email;
  }

  return <span>{name}</span>;
};

export default Admin;
