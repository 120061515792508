import React from 'react';

import { withRouter } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { ChinaSteelReportCreate } from 'pages';

import {
  Page,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Flex,
  Button
} from 'components';

import { chinaSteelList } from './sdk';

import { ChinaSteelTable } from './components';

class ChinaSteelList extends React.Component {
  onCreateClick = () => {
    this.props.history.push(pageUrl(ChinaSteelReportCreate));
  };
  render() {
    const {
      user,
      data,
      limit,
      count,
      page,
      changePage,
      changeRowsPerPage,
      sort,
      orderBy,
      crumbs
    } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Flex spaceBetween>
              <Header>China Steel</Header>
              <Button onClick={this.onCreateClick}>Create</Button>
            </Flex>
            {data && (
              <ChinaSteelTable
                reports={data}
                sort={sort}
                orderBy={orderBy}
                pagination={
                  <Pagination
                    limit={limit}
                    count={count}
                    page={page}
                    changePage={changePage}
                    changeRowsPerPage={changeRowsPerPage}
                  />
                }
              />
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: chinaSteelList
})(LoginRequired(withRouter(ChinaSteelList)));
