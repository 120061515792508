import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const feedbackRemove = async feedbackId => {
  const url = `${BASE_URL}/forms-dashboard/tracking-events/feedback/${feedbackId}/delete/`;

  return await requestSdk(() => post(url));
};

export const feedbackUpdate = async (feedbackId, data) => {
  const url = `${BASE_URL}/forms-dashboard/tracking-events/feedback/${feedbackId}/update/`;

  return await requestSdk(() => post(url, data));
};
