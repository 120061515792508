import {
  InputField,
  ArticleStatusSelect,
  AuthorSelect,
  YesNoSelect,
  DateField,
  RegionSelect,
  CountrySelect,
  InstantNewsTypeSelect,
  UniSelect
} from 'components';

import { transformValuesForMultiselect } from 'utils/filters';
import { coerceStringToBoolean } from 'utils';

export const FILTERS = productSlug => [
  [
    {
      name: 'search',
      emptyValue: '',
      component: InputField,
      placeholder: 'Search ...',
      label: 'Search words',
      width: 8
    },
    {
      name: 'status',
      emptyValue: null,
      fieldComponent: ArticleStatusSelect,
      label: 'Status',
      width: 3
    }
  ],
  [
    {
      name: 'author',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: AuthorSelect,
      label: 'Author',
      width: 5
    },
    {
      name: 'type',
      emptyValue: null,
      fieldComponent: UniSelect,
      label: 'Type',
      width: 3,
      props: () => ({
        productSlug: productSlug,
        source: 'articleLabels'
      })
    },
    {
      name: 'is_free',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Access',
      width: 3,
      props: () => ({
        yesLabel: 'Free',
        noLabel: 'Paid'
      })
    },
    {
      name: 'instant_news',
      emptyValue: null,
      fieldComponent: InstantNewsTypeSelect,
      label: 'Instant News Type',
      width: 6
    }
  ],
  [
    {
      name: 'date_from',
      emptyValue: null,
      component: DateField,
      label: 'Date from',
      width: 4
    },
    {
      name: 'date_to',
      emptyValue: null,
      component: DateField,
      label: 'Date to',
      width: 4
    },
    {
      name: 'prices',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: UniSelect,
      label: 'Prices',
      width: 8,
      props: () => ({
        productSlug: productSlug,
        source: 'priceSerieCategories',
        isMulti: true
      })
    }
  ],
  [
    {
      name: 'regions',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: RegionSelect,
      label: 'Regions',
      props: () => ({
        isMulti: true
      })
    },

    {
      name: 'countries',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: CountrySelect,
      label: 'Countries',
      props: () => ({
        isMulti: true
      })
    }
  ],
  [
    {
      name: 'products',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: UniSelect,
      label: 'Products',
      props: () => ({
        productSlug: productSlug,
        source: 'priceSerieProducts',
        isMulti: true
      })
    },
    {
      name: 'article_topics',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: UniSelect,
      label: 'Article Topics',
      props: () => ({
        productSlug: productSlug,
        source: 'articleTopics',
        isMulti: true
      })
    }
  ]
];
