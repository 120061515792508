export const PENDING_FOR_TRIAL_STATUS_ID = 1;
export const TRIAL_STATUS_ID = 2;
export const SHORT_TRIAL_STATUS_ID = 3;
export const PAYMENT_CHASING_STATUS_ID = 4;
export const SUBSCRIBER_STATUS_ID = 5;
export const PAYMENT_PROMISED_STATUS_ID = 6;
export const SPECIAL_STATUS_ID = 7;
export const PAYMENT_NOT_RENEWED_STATUS_ID = 8;
export const REFUSED_STATUS_ID = 9;
export const DELETED_STATUS_ID = 10;
export const SPECIAL_NEWSLETTER_RECEIVER_STATUS_ID = 12;
export const PENDING_FOR_SUBSCRIPTION_STATUS_ID = 13;
export const HEADLINES_STATUS_ID = 14;
export const PROMOTION_STATUS_ID = 15;
