import React from 'react';

import { Field, SelectField } from 'components';
const PAYMENT_METHOD_TYPES = [
  'By credit card',
  'VISA Credit Card',
  'Mastercard Credit Card',
  'American Express',
  'By bank transfer',
  'By cheque',
  'Cash (in hand)'
];

class PaymentMethodTypeSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = PAYMENT_METHOD_TYPES.map(group => {
      return {
        key: group,
        value: group,
        label: group
      };
    });

    return (
      <Field
        name={name || 'paymentMethodType'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default PaymentMethodTypeSelect;
