import React from 'react';
import _ from 'lodash';

import { withRouter } from 'react-router-dom';

import { Segment, Header, Tab } from 'semantic-ui-react';

import { Button } from 'components';

import { hasPermission, PERMISSIONS } from 'permissions';

import { pageUrl } from 'config/routes';
import { InvoiceDetail } from 'pages';
import { notifySuccess, notifyErrors } from 'utils/notifications';

import { prepareFormData } from 'utils/forms';

import { ProductsTable, PipelineItems } from './components';
import {
  removeProduct,
  addProduct,
  addToSelectedProducts,
  prepareClientAddInvoiceValues,
  getProducts,
  getTabPane,
  removeFromSelectedProducts,
  prepareParams
} from './utils';
import { clientAddProduct, clientPipelineItems } from './sdk';

const PIPELINE_ITEMS_DEFAULT_PARAMS = {
  forecastedMonth: new Date().getMonth() + 1,
  forecastedYear: new Date().getFullYear(),
  defaultOnEmpty: true
};

class ProductTable extends React.Component {
  state = {
    selectedProducts: [],
    products: [],
    panes: [],
    pipelineItemsInDashboard: [],
    pipelineParams: PIPELINE_ITEMS_DEFAULT_PARAMS
  };

  componentDidMount() {
    this.fetchInitialPipelineItems();
  }

  componentDidUpdate(prevProps) {
    const currentClientItems = this.props.client.client_items;
    const prevClientItems = prevProps.client.client_items;

    if (
      currentClientItems.length !== prevClientItems.length ||
      !_.isEqual(currentClientItems, prevClientItems)
    ) {
      this.setCurrentProductsTabs();
    }
  }

  fetchInitialPipelineItems = async () => {
    const { client } = this.props;

    const preparedParams = prepareFormData(PIPELINE_ITEMS_DEFAULT_PARAMS);

    const { data, errors, success } = await clientPipelineItems(
      client.id,
      preparedParams
    );

    if (success) {
      let pipelineParams = this.state.pipelineParams;

      if (!_.isEmpty(data)) {
        pipelineParams = prepareParams(data);
      }
      this.updatePipelineTitleTab(data, pipelineParams);
    } else {
      notifyErrors(errors);
    }
  };

  updatePipelineTitleTab = (pipelineItems, pipelineParams) => {
    this.setState({ pipelineItems, pipelineParams }, () =>
      this.setCurrentProductsTabs()
    );
  };

  setCurrentProductsTabs = () => {
    const { client } = this.props;
    const { pipelineItems, pipelineParams } = this.state;

    const {
      products,
      subscriptions,
      events,
      reports,
      advertisements
    } = getProducts(client);

    let panes = [
      getTabPane({
        title: 'Subscriptions',
        items: subscriptions,
        renderFunction: this.getProductsTable
      }),
      getTabPane({
        title: 'Events',
        items: events,
        renderFunction: this.getProductsTable
      }),
      getTabPane({
        title: 'Reports',
        items: reports,
        renderFunction: this.getProductsTable
      }),
      getTabPane({
        title: 'Advertisements',
        items: advertisements,
        renderFunction: this.getProductsTable
      }),
      // TODO: disable for prod deploy
      getTabPane({
        title: 'Pipeline',
        items: _.filter(pipelineItems, { in_dashboard: true }),
        renderFunction: () => (
          <PipelineItems
            client={this.props.client}
            products={products}
            updatePipelineItems={this.updatePipelineTitleTab}
            initialPipelineItems={pipelineItems}
            pipelineParams={pipelineParams}
          />
        )
      })
    ];

    this.setState({
      products,
      panes
    });
  };

  getProductsTable = products => {
    const { client, fetchClient } = this.props;
    const { selectedProducts } = this.state;
    return (
      <ProductsTable
        client={client}
        products={products}
        selectedProducts={selectedProducts}
        fetchClient={fetchClient}
        user={this.props.user}
        toggleSelectAll={this.toggleSelectAll}
        toggleProductSelect={this.toggleProductSelect}
        addProduct={this.addProduct}
      />
    );
  };

  refetch = () => {
    const { refetch } = this.props;

    this.setState({ selectedProducts: [] }, refetch);
  };

  toggleProductSelect = (data, productId, productType) => {
    const { checked } = data;
    let { selectedProducts } = this.state;

    if (checked) {
      selectedProducts = addProduct(productId, productType, selectedProducts);
    } else {
      selectedProducts = removeProduct(productId, selectedProducts);
    }
    this.setState({ selectedProducts });
  };

  toggleSelectAll = (data, products) => {
    const { checked } = data;
    let { selectedProducts } = this.state;

    const items = products.map(function(el) {
      return { id: el.itemId, type: el.type };
    });

    if (checked) {
      selectedProducts = addToSelectedProducts(items, selectedProducts);
    } else {
      selectedProducts = removeFromSelectedProducts(items, selectedProducts);
    }
    this.setState({ selectedProducts });
  };

  addSelectedProducts = async () => {
    const { client } = this.props;
    const { selectedProducts } = this.state;

    const products = prepareClientAddInvoiceValues(selectedProducts);

    const { data, success, errors } = await clientAddProduct(
      client.id,
      products
    );

    if (success) {
      notifySuccess(
        `${selectedProducts.length} products added to invoice ${data.invoice_id}`
      );
      this.props.history.push(
        pageUrl(InvoiceDetail, { invoiceId: data.invoice_id })
      );
      this.refetch();
    } else {
      notifyErrors(errors);
    }
  };

  render() {
    const { selectedProducts, products, panes } = this.state;

    return (
      <Segment>
        <Header as="h2">Products</Header>
        {_.isEmpty(products) && <p>Client has no products yet.</p>}
        {!_.isEmpty(products) && (
          <>
            <Tab panes={panes} />

            <Button
              style={{ marginTop: '15px' }}
              onClick={this.addSelectedProducts}
              disabled={
                selectedProducts.length === 0 ||
                !hasPermission(this.props.user, PERMISSIONS.CHANGE_INVOICES)
              }>
              Add selected to invoice
            </Button>
          </>
        )}
      </Segment>
    );
  }
}

export default withRouter(ProductTable);
