import React from 'react';

import ReactDiffViewer from 'react-diff-viewer-continued';

import { Button, Header, Modal, Label } from 'semantic-ui-react';

import { Flex } from 'components';

import { openInNewTab, stringifyParams, formatDateTime } from 'utils';

import { pageUrl } from 'config/routes';

import { ArticleChangesList } from 'pages';

import { withRouter } from 'react-router-dom';

import { articleChangesList } from 'pages/ArticleChanges/List/sdk';

class ArticleHistoryModal extends React.Component {
  state = {
    modalOpen: false,
    articleHistory: null,
    changeIndex: null
  };

  handleOpen = () => {
    this.setState({ modalOpen: true });
    this.props.onOpen();
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
    this.props.onClose();
  };

  fetchArticleHistory = async articleId => {
    const { data, errors } = await articleChangesList({ article: articleId });
    if (data) {
      this.setState({ articleHistory: data.results });
      this.setState({ changeIndex: 0 });
    } else {
      this.setState({ errors });
    }
  };

  render() {
    const { articleId } = this.props;
    const { articleHistory, changeIndex } = this.state;

    const title = 'Article Changes';
    const openModal = () => {
      this.fetchArticleHistory(articleId);
      this.handleOpen();
    };

    const openArticleChanges = () => {
      const params = {
        article: articleId
      };
      const url = `${pageUrl(ArticleChangesList)}/?${stringifyParams(params)}`;
      openInNewTab(url);
    };

    const newStyles = {
      diffRemoved: {
        overflowX: 'auto',
        maxWidth: 300
      },
      diffAdded: {
        overflowX: 'auto',
        maxWidth: 300
      }
    };

    return (
      <Modal
        open={this.state.modalOpen}
        onClose={this.handleClose}
        trigger={
          <Button color="green" size="large" onClick={() => openModal()}>
            Article History
          </Button>
        }
        size="large">
        {articleHistory && (
          <>
            <Header content={title} />
            <Modal.Content>
              {articleHistory.length > 0 && articleHistory[changeIndex] && (
                <>
                  <Flex spaceBetween style={{ marginBottom: '20px' }}>
                    <Button onClick={() => openArticleChanges()}>
                      See all changes
                    </Button>
                    <Flex flexEnd>
                      <Button
                        disabled={!articleHistory[changeIndex + 1]}
                        onClick={() =>
                          this.setState({ changeIndex: changeIndex + 1 })
                        }>
                        Previous
                      </Button>
                      <Button
                        disabled={!articleHistory[changeIndex - 1]}
                        onClick={() =>
                          this.setState({ changeIndex: changeIndex - 1 })
                        }>
                        Next
                      </Button>
                    </Flex>
                  </Flex>
                  <Label>
                    Updated by: {articleHistory[changeIndex].updated_by.email}{' '}
                  </Label>
                  <Label style={{ marginBottom: '20px' }}>
                    Updated at:{' '}
                    {formatDateTime(articleHistory[changeIndex].updated_at)}
                  </Label>
                  {articleHistory[changeIndex].old_status &&
                    articleHistory[changeIndex].new_status && (
                      <ReactDiffViewer
                        oldValue={articleHistory[changeIndex].old_status}
                        newValue={articleHistory[changeIndex].new_status}
                        splitView={true}
                        hideLineNumbers={true}
                        disableWordDiff={true}
                        leftTitle="Old Status"
                        rightTitle="New Status"
                      />
                    )}
                  <br />

                  {articleHistory[changeIndex].old_text &&
                    articleHistory[changeIndex].new_text && (
                      <ReactDiffViewer
                        styles={newStyles}
                        oldValue={articleHistory[changeIndex].old_text}
                        newValue={articleHistory[changeIndex].new_text}
                        splitView={true}
                        hideLineNumbers={true}
                        disableWordDiff={false}
                        leftTitle="Old Text"
                        rightTitle="New Text"
                      />
                    )}
                </>
              )}
              {articleHistory.length === 0 && (
                <Header as="h2">No existing article history</Header>
              )}
            </Modal.Content>
            <Modal.Actions>
              <Button color="red" inverted onClick={this.handleClose}>
                Close
              </Button>
            </Modal.Actions>
          </>
        )}
      </Modal>
    );
  }
}

export default withRouter(ArticleHistoryModal);
