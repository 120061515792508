import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const invoiceSendDetails = async () => {
  const url = `${BASE_URL}/crm/invoices/send-details/`;

  return await requestSdk(() => get(url));
};

export const invoiceSend = async (invoiceId, data) => {
  const url = `${BASE_URL}/crm/invoices/${invoiceId}/send/`;

  return await requestSdk(() => post(url, data));
};
