import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const clientList = async params => {
  const url = `${BASE_URL}/crm/clients/`;

  return await requestSdk(() => get(url, params));
};

export const clientIdList = async params => {
  const url = `${BASE_URL}/crm/clients/client-id-list/`;

  return await requestSdk(() => get(url, params));
};
