import * as yup from 'yup';

export const dailyNewsletterCreateSchema = yup.object().shape({
  datetime: yup
    .string()
    .nullable()
    .required('Date to go out is required.'),
  editor: yup
    .string()
    .nullable()
    .required('Editor is required.'),
  marketer: yup
    .string()
    .nullable()
    .required('Marketer is required.')
});
