import React from 'react';

import _ from 'lodash';

import cx from 'classnames';

import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';

import { Error } from 'components';

import styles from './styles.module.css';

class AsyncSelectField extends React.Component {
  render() {
    const {
      label,
      className,
      form,
      field,
      loadOptions,
      onChange,
      options,
      defaultValue,
      ...props
    } = this.props;

    const hasErrors =
      _.get(form.touched, field.name) && _.get(form.errors, field.name);

    let Component = AsyncSelect;

    if (this.props.isCreatable) {
      Component = AsyncCreatableSelect;
    }

    return (
      <div className={cx(styles.container, className)}>
        <label>{label}</label>
        <Component
          {...props}
          defaultValue={defaultValue}
          placeholder="Type to search ..."
          isDisabled={props.disabled}
          cacheOptions
          loadOptions={loadOptions}
          onChange={data => {
            const isMulti = props.isMulti;

            if (isMulti) {
              const value = (data || []).map(selected => selected.value);
              form.setFieldValue(field.name, value);
              form.setFieldTouched(field.name);

              if (onChange) {
                onChange({ target: data }, form.values);
              }
              return;
            }

            form.setFieldValue(field.name, _.get(data, 'value', null));
            form.setFieldTouched(field.name);

            if (onChange) {
              if (_.isNil(data)) {
                onChange(null, form.values);
                return;
              }

              const fieldData = {
                ...data
              };

              onChange({ target: fieldData }, form.values);
            }
          }}
          className={styles.select}
          theme={theme => ({
            ...theme,
            borderRadius: 0
          })}
        />
        {hasErrors && (
          <Error className={styles.error}>
            {_.get(form.errors, field.name)}
          </Error>
        )}
      </div>
    );
  }
}

export default AsyncSelectField;
