import React from 'react';

import { Modal, Icon, Header, Form } from 'semantic-ui-react';
import { Formik } from 'formik';
import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import {
  Field,
  FormRow,
  InputField,
  RichTextAreaField,
  FileField,
  Flex,
  CreateButtonInRow
} from 'components';
import { coursePresentationCreate } from './sdk';

class PresentationModal extends React.Component {
  state = {};

  close = () => {
    this.props.onClose();
  };

  createPresentation = async (values, actions) => {
    const { event, trainer, fetchTrainer } = this.props;

    const { setSubmitting, setFieldError } = actions;

    const postData = {
      ...prepareFormData(values),
      trainer: trainer.id
    };

    setSubmitting(true);

    const { errors, success } = await coursePresentationCreate(
      event.id,
      postData
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Successfully create course presentation.');
      fetchTrainer();
      this.close();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const { event } = this.props;

    const initialValues = {};

    return (
      <Modal open={true} size="large" centered={false}>
        <Modal.Header>
          <Flex spaceBetween>
            <Header as="h2">Add Presentation to {event.title}</Header>
            <Icon name="close" onClick={this.close} />
          </Flex>
        </Modal.Header>
        <Modal.Content>
          <Formik
            onSubmit={this.createPresentation}
            initialValues={initialValues}
            // validationSchema={trainerUpdateSchema}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form>
                <Field
                  name="title"
                  label="Presentation title"
                  component={InputField}
                  required
                />
                <Field
                  name="summary"
                  label="Presentation Summary"
                  component={RichTextAreaField}
                  required
                />
                <FormRow>
                  <Field
                    width={5}
                    name="presentationPpt"
                    component={FileField}
                    label="Presentation Files - ppt"
                    required
                  />

                  <Field
                    width={5}
                    name="presentationPdf"
                    component={FileField}
                    label="Presentation Files - pdf"
                    required
                  />
                </FormRow>
                <CreateButtonInRow
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                />
              </Form>
            )}
          </Formik>
        </Modal.Content>
      </Modal>
    );
  }
}

export default PresentationModal;
