import * as yup from 'yup';
import _ from 'lodash';
import { prepareFormData, prepareInitialValues } from 'utils/forms';

const getMetaFieldData = (fields, type) =>
  _.get(
    _.find(fields, item => item.meta_field_type.meta_type === type),
    'meta_data',
    ''
  );

export const getMetaFields = data => ({
  metaTitle: getMetaFieldData(data.price_serie_fields, 'Title'),
  metaDescription: getMetaFieldData(
    data.price_serie_fields,
    'Meta Description'
  ),
  metaKeywords: getMetaFieldData(data.price_serie_fields, 'Meta Keywords'),
  metaStandout: getMetaFieldData(data.price_serie_fields, 'Meta Standout')
});

export const getInitialValues = data => ({
  ...prepareInitialValues(data),
  region: _.get(data, 'regions[0]'),
  region2: _.get(data, 'regions[1]', null),
  principalOwner: _.get(data, 'principal_owner.id', null),
  ...getMetaFields(data)
});

export const preparePostData = data => ({
  ...prepareFormData(data),
  regions: _.without([data.region, data.region2], null)
});

export const validationSchema = yup.object().shape({
  title: yup.string().required('This field is required.'),
  shortTitle: yup.string().required('This field is required.'),
  groupId: yup
    .string()
    .nullable()
    .required('This field is required.'),
  product: yup
    .string()
    .nullable()
    .required('This field is required.'),
  incoTerms: yup
    .string()
    .nullable()
    .required('This field is required.'),
  format: yup
    .string()
    .nullable()
    .required('This field is required.'),
  unit: yup
    .string()
    .nullable()
    .required('This field is required.'),
  frequency: yup
    .string()
    .nullable()
    .required('This field is required.'),
  currency: yup
    .string()
    .nullable()
    .required('This field is required.'),
  scale: yup
    .string()
    .nullable()
    .required('This field is required.'),
  decimalPlaces: yup
    .number()
    .required('This field is required.')
    .min(0),
  region: yup
    .string()
    .nullable()
    .required('This field is required.'),
  status: yup
    .string()
    .nullable()
    .required('This field is required.'),
  priceDataSource: yup
    .string()
    .nullable()
    .required('This field is required.'),
  externalSourceSymbol: yup
    .string()
    .nullable()
    .when('priceDataSource', {
      is: value => value !== 'Manual',
      then: schema => schema.required('This field is required.')
    })
});
