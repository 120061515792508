import React from 'react';

import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import { Icon, Table } from 'semantic-ui-react';
import { Admin, YesNo, ExternalLink, Tooltip } from 'components';
import { pageUrl } from 'config/routes';
import {
  ClientProfileLogList,
  ClientLoginsList,
  TrackedArticlesList,
  TrackedPriceSeries
} from 'pages';

import { formatDateTime, openInNewTab, stringifyParams } from 'utils';

import styles from './styles.module.css';

class ClientInfoTable extends React.Component {
  openClientProfileLog = clientId => {
    this.props.history.push({
      pathname: pageUrl(ClientProfileLogList),
      search: `?client_id=${clientId}`
    });
  };

  openClientLogins = clientId => {
    openInNewTab(pageUrl(ClientLoginsList, { userId: clientId }));
  };

  openClientPrices = (clientId, actionType) => {
    const params = {
      user: clientId,
      action_type: actionType
    };
    const url = `${pageUrl(TrackedPriceSeries)}/?${stringifyParams(params)}`;
    openInNewTab(url);
  };

  openClientArticles = clientId => {
    const params = {
      user: clientId
    };
    const url = `${pageUrl(TrackedArticlesList)}/?${stringifyParams(params)}`;
    openInNewTab(url);
  };

  render() {
    const { client } = this.props;
    const yesNoColor = _.get(client, 'client_profile.unsubscribed', false)
      ? 'black'
      : null;

    return (
      <Table basic="very" celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Joined</Table.HeaderCell>
            <Table.HeaderCell>Last Login</Table.HeaderCell>

            <Table.HeaderCell># Logins</Table.HeaderCell>
            <Table.HeaderCell>Unique IPs</Table.HeaderCell>
            <Table.HeaderCell>Last Edit</Table.HeaderCell>
            <Table.HeaderCell>Prices series accessed</Table.HeaderCell>
            <Table.HeaderCell>Articles accessed</Table.HeaderCell>
            <Table.HeaderCell>Avatar</Table.HeaderCell>
            <Table.HeaderCell>My snapshot</Table.HeaderCell>
            <Table.HeaderCell>Mobile app</Table.HeaderCell>
            <Table.HeaderCell>Mobile last</Table.HeaderCell>
            <Table.HeaderCell>Subscribed</Table.HeaderCell>
            {client.client_profile.unsubscribed && (
              <>
                <Table.HeaderCell>Unsubscribed at</Table.HeaderCell>
              </>
            )}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>{formatDateTime(client.date_joined)}</Table.Cell>
            <Table.Cell>{formatDateTime(client.last_login)}</Table.Cell>
            <Table.Cell>
              <div
                className={styles.LinkDiv}
                onClick={() => this.openClientLogins(client.id)}>
                {client.logins.toString()}
              </div>
            </Table.Cell>
            <Table.Cell>
              <div
                className={styles.LinkDiv}
                onClick={() => this.openClientLogins(client.id)}>
                {client.unique_ips}
              </div>
            </Table.Cell>
            <Table.Cell>
              <div>
                <div>{formatDateTime(client.client_profile.updated_at)}</div>
                {_.get(client, 'client_profile.updated_by', false) ? (
                  <div
                    className={styles.LinkDiv}
                    onClick={() => this.openClientProfileLog(client.id)}>
                    <Admin value={client.client_profile.updated_by} />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </Table.Cell>
            <Table.Cell>
              <div>
                <div>
                  <Tooltip
                    on="hover"
                    content={`Opened: ${client.tracked_price_series_open}`}
                    trigger={
                      <span>
                        <Icon name="eye" />
                        <span
                          className={styles.LinkDiv}
                          onClick={() =>
                            this.openClientPrices(client.id, 'Open')
                          }>
                          {client.tracked_price_series_open}
                        </span>
                      </span>
                    }
                  />
                </div>
                <div>
                  <Tooltip
                    on="hover"
                    content={`Downloaded: ${client.tracked_price_series_download}`}
                    trigger={
                      <span>
                        <Icon name="download" />
                        <span
                          className={styles.LinkDiv}
                          onClick={() =>
                            this.openClientPrices(client.id, 'Download')
                          }>
                          {client.tracked_price_series_download}
                        </span>
                      </span>
                    }
                  />
                </div>
              </div>
            </Table.Cell>
            <Table.Cell>
              <div
                className={styles.LinkDiv}
                onClick={() => this.openClientArticles(client.id)}>
                {client.article_search}
              </div>
            </Table.Cell>
            <Table.Cell>
              {_.get(client, 'client_profile.avatar', false) ? (
                <ExternalLink url={client.client_profile.avatar}>
                  Preview
                </ExternalLink>
              ) : (
                'N/A'
              )}
            </Table.Cell>
            <Table.Cell>
              <YesNo
                value={client.client_profile.has_my_snapshot_prices}
                checkColor={yesNoColor}
                crossColor={yesNoColor}
              />
            </Table.Cell>
            <Table.Cell>
              <YesNo
                value={client.client_profile.uses_mobile_app}
                checkColor={yesNoColor}
                crossColor={yesNoColor}
              />
            </Table.Cell>
            <Table.Cell>{client.client_profile.mobile_last_seen_at}</Table.Cell>
            <Table.Cell>
              <YesNo
                value={!client.client_profile.unsubscribed}
                checkColor={yesNoColor}
                crossColor={yesNoColor}
              />
            </Table.Cell>
            {client.client_profile.unsubscribed && (
              <>
                <Table.Cell>
                  <div>
                    <div>
                      {formatDateTime(client.client_profile.unsubscribed_at)}
                    </div>
                    <div>({client.client_profile.unsubscribed_method})</div>
                  </div>
                </Table.Cell>
              </>
            )}
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}
export default withRouter(ClientInfoTable);
