import React, { useState } from 'react';
import { Formik } from 'formik';
import {
  Header,
  Segment,
  Form,
  Modal,
  Icon,
  Dimmer,
  Loader,
  Button
} from 'semantic-ui-react';
import {
  FormRow,
  Field,
  FileField,
  Flex,
  ExternalLinkButton
} from 'components';
import { notifySuccess, notifyErrors } from 'utils/notifications';
import { processClients, importClients } from './sdk';
import { ImportClientsFormatTable, ImportClientsTable } from './components';

const ImportClientsModal = props => {
  const [isFileAttached, setIsFileAttached] = useState(false);
  const [clientsData, setClientsData] = useState([]);
  const [fileId, setFileId] = useState(null);
  const [hasErrors, setHasErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    const { errors, success } = await importClients(fileId);

    if (success) {
      notifySuccess('Clients have been imported.');
      props.refetch();
      props.onClose();
    } else {
      notifyErrors(errors);
      props.onClose();
    }
  };

  const onClientsFileAttach = async fileId => {
    setIsLoading(true);
    const { errors, data, success } = await processClients(fileId);

    if (success) {
      setIsFileAttached(true);
      setClientsData(data);
      setFileId(fileId);
      setIsLoading(false);

      let noErrors = true;
      for (const client of data) {
        if (Object.keys(client.errors).length > 0) {
          noErrors = false;
          break;
        }
      }

      setHasErrors(!noErrors);
      return true;
    } else {
      notifyErrors(errors);
      setIsLoading(false);
      setIsFileAttached(false);
      setClientsData([]);
      setFileId(null);
      return false;
    }
  };

  const onClientsFileDelete = () => {
    setIsFileAttached(false);
    setClientsData([]);
    setFileId(null);
  };

  return (
    <Modal open={true} size="large" centered={false}>
      <Modal.Header>
        <Flex spaceBetween style={{ height: '40px' }}>
          <Header as="h2" style={{ marginBottom: 0 }}>
            Import Clients
          </Header>
          <Icon name="close" onClick={props.onClose} />
        </Flex>
      </Modal.Header>
      <Modal.Content>
        <>
          <Formik onSubmit={handleSubmit} initialValues={{ dataFile: null }}>
            {() => (
              <Form>
                <FormRow>
                  <Field
                    required
                    name="dataFile"
                    component={FileField}
                    label="Excel file"
                    onFileAttach={onClientsFileAttach}
                    onFileDelete={onClientsFileDelete}
                    maxSize={5 * 1024 * 1024} // 5MB
                    accept={{
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
                        '.xlsx'
                      ]
                    }}
                  />
                </FormRow>
              </Form>
            )}
          </Formik>
          {isLoading && (
            <Dimmer active inverted>
              <Loader inverted>
                We're currently processing the file. Please, do not close this
                page.
              </Loader>
            </Dimmer>
          )}
          {!isLoading && !isFileAttached && (
            <>
              <Segment>
                <Header as="h4">
                  The imported Excel file should have the following format:
                </Header>
                <ImportClientsFormatTable />
              </Segment>
              <Flex spaceBetween>
                <Flex
                  alignStart
                  style={{
                    gap: '5px',
                    marginTop: '5px',
                    flexDirection: 'column',
                    color: '#FF4861',
                    fontSize: '15px',
                    fontWeight: 700
                  }}>
                  <div>
                    The upload must contain the same columns shown in the
                    Example table.
                  </div>
                  <div>The upload is limited to 1000 clients per file.</div>
                  <div>
                    The "Email" and "Username" are required for each client.
                  </div>
                </Flex>
                <ExternalLinkButton
                  color="blue"
                  style={{
                    padding: '1em 1.5em',
                    gap: '10px'
                  }}
                  url="https://media.kallanish.com/filer_public/16/dd/16ddf872-13a9-42ba-9808-b9475f9f1665/client_import_sample.xlsx">
                  Download Sample File
                </ExternalLinkButton>
              </Flex>
            </>
          )}
          {!isLoading && isFileAttached && (
            <ImportClientsTable clientsData={clientsData} />
          )}
        </>
      </Modal.Content>
      <Modal.Actions>
        <Button size="large" color="red" onClick={props.onClose}>
          Close
        </Button>
        <Button
          type="submit"
          size="large"
          primary
          disabled={!isFileAttached || hasErrors}
          onClick={handleSubmit}>
          Import
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ImportClientsModal;
