import _ from 'lodash';
import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const clientProfileLogList = async params => {
  const url = `${BASE_URL}/crm/client-profile-logs/`;

  return await requestSdk(() => get(url, params));
};

export const clientProfileLogsExportPreview = async params => {
  const url = `${BASE_URL}/crm/client-profile-logs/export/preview/`;

  return await requestSdk(() => get(url, params));
};

export const clientProfileLogsExportDownloadUrl = filters => {
  const url = `${BASE_URL}/crm/client-profile-logs/export/download/`;

  if (!_.isEmpty(filters)) {
    const params = new URLSearchParams(filters);

    return `${url}?${params.toString()}`;
  }

  return url;
};
