import React from 'react';

import {
  Page,
  Flex,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Filters as SharedFilters
} from 'components';

import { loginAttemptList } from './sdk';

import { LoginAttemptTable } from './components';

import { FILTERS as LAYOUT_FILTERS } from './utils';

const LoginAttemptList = ({
  user,
  data,
  count,
  page,
  limit,
  filters,
  sort,
  filterBy,
  orderBy,
  clear,
  changePage,
  changeRowsPerPage,
  isFetching
}) => (
  <Page user={user}>
    <Page.Body>
      <Page.Content>
        <Flex flexStart>
          <Header>Admin Login Attempts</Header>
        </Flex>
        <SharedFilters
          filters={filters}
          filterBy={filterBy}
          clearFilters={clear}
          count={count}
          layout={LAYOUT_FILTERS}
        />
        <LoginAttemptTable
          isFetching={isFetching}
          loginAttempts={data}
          sort={sort}
          orderBy={orderBy}
          pagination={
            <Pagination
              limit={limit}
              count={count}
              page={page}
              changePage={changePage}
              changeRowsPerPage={changeRowsPerPage}
            />
          }
        />
      </Page.Content>
    </Page.Body>
  </Page>
);

export default ListViewEssentials({
  sdk: loginAttemptList
})(LoginRequired(LoginAttemptList));
