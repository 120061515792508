import React from 'react';

import { Field, TransferListField } from 'components';
import { bannerList } from './sdk';

class BannersSelect extends React.Component {
  state = {
    banners: []
  };
  async componentDidMount() {
    const { data, success } = await bannerList({ paginate: false });

    if (success) {
      this.setState({ banners: data });
    }
  }

  render() {
    const { name, ...rest } = this.props;
    const { banners } = this.state;
    const options = banners.map(banner => {
      return {
        key: banner.id,
        value: banner.id,
        label: banner.title
      };
    });

    return (
      <Field
        name={name || 'banner'}
        component={TransferListField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default BannersSelect;
