import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const priceSeriesDataCreate = async (priceSeriesId, data) => {
  const url = `${BASE_URL}/prices/series/${priceSeriesId}/data/create/`;

  return await requestSdk(() => post(url, data));
};

export const priceSeriesDataCommentCreate = async (priceSeriesId, data) => {
  const url = `${BASE_URL}/prices/series/${priceSeriesId}/data/comment/`;

  return await requestSdk(() => post(url, data));
};
