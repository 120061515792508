import React from 'react';

import _ from 'lodash';

import { Formik, Form, Field } from 'formik';

import { ArticleSectionSelect } from 'components';

import { prepareFormData, handleFormErrors } from 'utils/forms';

import { dailyNewsletterAddRegionArticle } from './sdk';

import styles from './styles.module.css';
import { notifySuccess } from 'utils/notifications';

class RegionForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { article, dailyNewsletter, fetchDailyNewsletter } = this.props;

    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await dailyNewsletterAddRegionArticle(
      dailyNewsletter.id,
      data
    );

    setSubmitting(false);

    if (success) {
      if (_.isNil(data.region)) {
        notifySuccess(`${article.full_headline} removed from regions.`);
      } else {
        notifySuccess(`${article.full_headline} added to ${data.region}.`);
      }
      fetchDailyNewsletter();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  getArticleRegion = () => {
    const { dailyNewsletter, article } = this.props;

    return _.get(
      _.find(dailyNewsletter.sections, section => {
        return _.find(section.articles, { id: article.id });
      }),
      'section',
      null
    );
  };

  onChange = submitForm => {
    // https://github.com/jaredpalmer/formik/issues/1218
    setTimeout(submitForm);
  };

  render() {
    const articleRegion = this.getArticleRegion();

    const { article } = this.props;

    const initialValues = {
      article: article.id,
      region: articleRegion
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={this.handleSubmit}>
        {({ values, submitForm, isSubmitting, setFieldValue }) => (
          <Form className={styles.Grid}>
            <input type="hidden" name="article" />
            <Field
              name="region"
              component={ArticleSectionSelect}
              className={styles.SmallerSelect}
              onChange={() => this.onChange(submitForm)}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default RegionForm;
