import React from 'react';
import _ from 'lodash';

import { withRouter } from 'react-router-dom';

import { Table, List } from 'semantic-ui-react';

import { formatDateTime } from 'utils';

function FeedbackDetailsModal({ feedback }) {
  return (
    <>
      <Table definition collapsing>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={2}>Feedback Product</Table.Cell>
            <Table.Cell>{_.get(feedback, 'feedback_product.name')}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Client, Company</Table.Cell>
            <Table.Cell>
              {_.get(feedback, 'created_by.first_name')}{' '}
              {_.get(feedback, 'created_by.last_name')},{' '}
              {_.get(feedback, 'created_by.company_name')}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Email</Table.Cell>
            <Table.Cell>{_.get(feedback, 'created_by.email')}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Phone</Table.Cell>
            <Table.Cell>{_.get(feedback, 'created_by.phone')}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Created at</Table.Cell>
            <Table.Cell>
              {formatDateTime(_.get(feedback, 'created_at'))}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Rating</Table.Cell>
            <Table.Cell>{_.get(feedback, 'rating')}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Text</Table.Cell>
            <Table.Cell>{_.get(feedback, 'text')}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Display Option</Table.Cell>
            <Table.Cell>{_.get(feedback, 'display_option')}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Is Published</Table.Cell>
            <Table.Cell>
              {_.get(feedback, 'is_published') === false && 'No'}
              {_.get(feedback, 'is_published') === true && 'Yes'}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Published at</Table.Cell>
            <Table.Cell>{formatDateTime(feedback.published_at)}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Displayed Locations</Table.Cell>
            <Table.Cell>
              {!_.isEmpty(_.get(feedback, 'displayed_locations')) && (
                <List>
                  {_.get(feedback, 'displayed_locations').map(location => (
                    <List.Item>
                      {location.title} {location.identifier}
                    </List.Item>
                  ))}
                </List>
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}

export default withRouter(FeedbackDetailsModal);
