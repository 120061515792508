import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  title: yup.string().required('This field is required.'),
  shortTitle: yup.string().required('This field is required.'),
  slug: yup.string().required('This field is required.'),
  priceSerie1: yup
    .string()
    .nullable()
    .required('This field is required.'),
  priceSerie2: yup
    .string()
    .nullable()
    .required('This field is required.'),
  displayAs: yup.string().required('This field is required.'),
  showOnHomepage: yup.boolean().required('This field is required.')
});

export const DEFAULT_INITIAL_VALUES = {
  showOnHomepage: false,
  title: '',
  shortTitle: '',
  displayAs: '',
  slug: '',
  metaTitle: '',
  metaKeywords: '',
  metaDescription: '',
  metaStandout: '',
  priceSerie1: null,
  priceSerie2: null
};
