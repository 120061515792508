import React from 'react';

import { Field, SelectField } from 'components';
const REPORT_STATUSES = [
  'Pending',
  'Invoiced',
  'Paid',
  'Answered',
  'Trial',
  'Trial Expired',
  'Special'
];

class ReportStatusSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = REPORT_STATUSES.map(reportStatus => {
      return {
        key: reportStatus,
        value: reportStatus,
        label: reportStatus
      };
    });

    return (
      <Field
        name={name || 'status'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default ReportStatusSelect;
