import React from 'react';

import _ from 'lodash';

import { Statistic, Segment, Header, Table } from 'semantic-ui-react';

import { Flex } from 'components';

const Statistics = ({ statistics, salesMemberStatistics }) => {
  return (
    <>
      <Flex flexStart style={{ alignItems: 'flex-start' }}>
        <Segment inverted color="black" style={{ margin: '0 20px 0 0' }}>
          <Statistic inverted size="tiny">
            <Statistic.Label>Total</Statistic.Label>
            <Statistic.Value>£ {statistics.total.toFixed(0)}</Statistic.Value>
          </Statistic>
        </Segment>
        <Segment color="green" style={{ margin: '0 20px 0 0' }}>
          <Statistic color="green" size="tiny">
            <Statistic.Label>
              <Header as="div" color="green">
                Paid
              </Header>
            </Statistic.Label>
            <Statistic.Value>
              £ {statistics.paid_total.toFixed(0)}
            </Statistic.Value>
          </Statistic>
        </Segment>
        <Segment color="blue" style={{ margin: '0 20px 0 0' }}>
          <Statistic color="blue" size="tiny">
            <Statistic.Label>
              <Header as="div" color="blue">
                Payment Promised/On route
              </Header>
            </Statistic.Label>
            <Statistic.Value>
              £ {statistics.promised_on_route_total.toFixed(0)}
            </Statistic.Value>
          </Statistic>
        </Segment>

        <Segment color="purple" style={{ margin: '0 20px 0 0' }}>
          <Statistic color="purple" size="tiny">
            <Statistic.Label>
              <Header as="div" color="purple">
                Not Booked, over 50%
              </Header>
            </Statistic.Label>
            <Statistic.Value>
              £ {statistics.not_booked_over_half_certainty_total.toFixed(0)}
            </Statistic.Value>
          </Statistic>
        </Segment>

        <Segment color="red" style={{ margin: '0 20px 0 0' }}>
          <Statistic color="red" size="mini">
            <Statistic.Label>
              <Header as="div" color="red">
                PNR
              </Header>
            </Statistic.Label>
            <Statistic.Value>
              £{' '}
              {statistics.pnr_total.toFixed(0) > 0
                ? `- ${statistics.pnr_total.toFixed(0)}`
                : statistics.pnr_total.toFixed(0)}
            </Statistic.Value>
          </Statistic>
        </Segment>
      </Flex>
      <Table celled definition compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>Total</Table.HeaderCell>

            <Table.HeaderCell>Paid</Table.HeaderCell>
            <Table.HeaderCell>On Route</Table.HeaderCell>
            <Table.HeaderCell>Payment Promised</Table.HeaderCell>
            <Table.HeaderCell>Quoted</Table.HeaderCell>
            <Table.HeaderCell>Interested</Table.HeaderCell>
            <Table.HeaderCell>Evaluator</Table.HeaderCell>
            <Table.HeaderCell>Prospect</Table.HeaderCell>
            <Table.HeaderCell>No Status</Table.HeaderCell>
            <Table.HeaderCell>Delayed</Table.HeaderCell>
            <Table.HeaderCell>Cancelled</Table.HeaderCell>
            <Table.HeaderCell>PNR</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row textAlign="center">
            <Table.Cell textAlign="left">Total</Table.Cell>
            <Table.Cell>
              {_.get(salesMemberStatistics, 'total', 0).toFixed(0)}
            </Table.Cell>
            <Table.Cell>
              {_.get(salesMemberStatistics, 'paid_total', 0).toFixed(0)}
            </Table.Cell>
            <Table.Cell>
              {_.get(
                salesMemberStatistics,
                'payment_on_route_total',
                0
              ).toFixed(0)}
            </Table.Cell>
            <Table.Cell>
              {_.get(
                salesMemberStatistics,
                'payment_promised_total',
                0
              ).toFixed(0)}
            </Table.Cell>
            <Table.Cell>
              {_.get(salesMemberStatistics, 'quoted_total', 0).toFixed(0)}
            </Table.Cell>
            <Table.Cell>
              {_.get(salesMemberStatistics, 'interested_total', 0).toFixed(0)}
            </Table.Cell>
            <Table.Cell>
              {_.get(salesMemberStatistics, 'evaluator_total', 0).toFixed(0)}
            </Table.Cell>
            <Table.Cell>
              {_.get(salesMemberStatistics, 'prospect_total', 0).toFixed(0)}
            </Table.Cell>
            <Table.Cell>
              {_.get(salesMemberStatistics, 'no_status_total', 0).toFixed(0)}
            </Table.Cell>
            <Table.Cell>
              {_.get(salesMemberStatistics, 'delayed_total', 0).toFixed(0)}
            </Table.Cell>
            <Table.Cell>
              {_.get(salesMemberStatistics, 'cancelled_total', 0).toFixed(0)}
            </Table.Cell>
            <Table.Cell>
              {_.get(salesMemberStatistics, 'pnr_total', 0).toFixed(0)}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};

export default Statistics;
