import React from 'react';

import { Field, TransferListField } from 'components';

import { videosList } from './sdk';

class VideoSelect extends React.Component {
  state = {
    videos: []
  };

  async componentDidMount() {
    const { data, success } = await videosList();

    if (success) {
      this.setState({ videos: data });
    }
  }

  render() {
    const { name, ...rest } = this.props;
    const { videos } = this.state;

    const options = videos.map(video => {
      return {
        key: video.id,
        value: video.id,
        label: video.title
      };
    });

    return (
      <>
        <Field
          name={name || 'videos'}
          component={TransferListField}
          options={options}
          isClearable
          {...rest}
        />
      </>
    );
  }
}

export default VideoSelect;
