import _ from 'lodash';
import queryString from 'query-string';
import moment from 'moment';
import slugify from 'slugify';
import { GLOBAL_PRODUCT } from 'domainConstants';

const NEXT_KEY = 'next';

export const parseQueryString = searchParams => {
  /*
  We want to be fully backward compatible with the old query string format parser.
  By old format it's meant that we were using `var=1&var=2` for arrays. That was changed to `var=1,2`

  `var=1&var=2` and `var=1,2` should be fully supported and parsed as `var: [1, 2]`
  */
  const normalFormatted = queryString.parse(searchParams);
  const commaFormatted = queryString.parse(searchParams, {
    arrayFormat: 'comma'
  });

  let result = {};

  Object.keys(normalFormatted).forEach(key => {
    _.isArray(commaFormatted[key])
      ? (result[key] = commaFormatted[key])
      : (result[key] = normalFormatted[key]);
  });

  return result;
};

export const buildUrlFromNextRedirect = url => {
  const params = parseQueryString(url);
  const originalUrl = _.get(params, NEXT_KEY, null);

  return `${originalUrl}`;
};

export const getNextSearch = url => `${NEXT_KEY}=${url}`;

export const getNextUrl = (url, nextUrl) => `${url}?${getNextSearch(nextUrl)}`;

export const stringifyParams = params =>
  queryString.stringify(params, { arrayFormat: 'comma' });

export const formatDate = date => {
  if (!date) {
    return 'N/A';
  }

  return moment(date).format('ll');
};

export const formatDateTime = date => {
  if (!date) {
    return 'N/A';
  }

  return moment(date).format('lll');
};

export const formatDateForApi = date => moment(date).format('YYYY-MM-DD');

export const formatDateTimeForApi = date =>
  moment(date).format('YYYY-MM-DD HH:mm:ss');

export const getItemsIds = items => _.map(items, item => item.id);

export const reverse = (url, params = {}) => {
  let result = url;

  _.forEach(params, (value, key) => {
    result = result.replace(`:${key}`, value);
  });
  // Strip any optional parameter left in the url
  // without value
  result = result.replace(/(:.*)\?/g, '');

  return result;
};

export const openInNewTab = url => {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    href: url
  }).click();
};

export const getItemIds = items => _.map(items, item => item.id);

export const coerceStringToBoolean = value => {
  return !!{
    false: false,
    true: true
  }[value.toString().toLowerCase()];
};

export const populateSlug = (data, setFieldValue) => {
  const slug = slugified(data);
  setFieldValue('slug', slug);
};

export const checkDateIsPast = dateStr => {
  const now = moment();

  const date = moment(dateStr);

  if (date < now) return true;

  return false;
};

export const slugified = input => {
  return slugify(input, {
    lower: true,
    remove: /[/#*+~.,()'"!:@`]/g
  });
};

export const scrollToElement = id => {
  var el = document.getElementById(id);

  el.scrollIntoView({ behavior: 'smooth' });
};

export const getProductName = slug =>
  GLOBAL_PRODUCT[slug] ? GLOBAL_PRODUCT[slug].title : '';

export const noop = () => {};
