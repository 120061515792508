import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const priceSpreadV2Detail = priceSpreadId => {
  const url = `${BASE_URL}/prices/spreads/v2/${priceSpreadId}/`;

  return requestSdk(() => get(url));
};

export const priceSpreadV2Delete = priceSpreadId => {
  const url = `${BASE_URL}/prices/spreads/v2/${priceSpreadId}/delete/`;

  return requestSdk(() => post(url));
};
