import React from 'react';

import styles from './styles.module.css';

const LinkButton = ({ children, onClick, style }) => (
  <button
    type="button"
    className={styles.LinkButton}
    onClick={onClick}
    style={style}>
    {children}
  </button>
);

export default LinkButton;
