import React from 'react';

import _ from 'lodash';
import { Field, SelectField } from 'components';
const OPTIONS = _.map(_.range(24), hour => ({
  key: hour,
  value: hour,
  label: _.padStart(hour, 2, '0')
}));

class HourSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    return (
      <Field
        name={name || 'hour'}
        component={SelectField}
        options={OPTIONS}
        isClearable
        {...rest}
      />
    );
  }
}

export default HourSelect;
