import { AdminSelect, CountrySelect } from 'components';

export const FILTERS = [
  [
    {
      name: 'countries',
      emptyValue: [],
      fieldComponent: CountrySelect,
      label: 'Country',
      props: () => ({
        isMulti: true
      }),
      width: 5
    },
    {
      name: 'account_manager',
      emptyValue: [],
      fieldComponent: AdminSelect,
      label: 'Account Manager',
      props: () => ({
        isMulti: true
      }),
      width: 4
    }
  ]
];
