import React from 'react';

import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { Header, Segment } from 'semantic-ui-react';

import { Page, LoginRequired, Button, Flex, Link } from 'components';

import {
  PermissionsRequired,
  PERMISSIONS,
  DisableableElement,
  hasPermission,
  VisibleIfSuperuser
} from 'permissions';

import { PRICE_SERIES_DETAILS_URL } from 'sdk/urls';

import { priceSeriesDetail } from './sdk';
import {
  PriceSeriesDataImportModal,
  PriceSeriesUpdateForm
} from './components';

class PriceSeriesDetail extends React.Component {
  state = {
    errors: null,
    priceSeries: null,
    dataImportModal: false
  };

  fetchPriceSeries = async () => {
    const { priceSeriesId } = this.props.match.params;

    const { data, errors, success } = await priceSeriesDetail(priceSeriesId);

    if (success) {
      this.setState({ priceSeries: data });
    } else {
      this.setState({ errors });
    }
  };

  toggleDataImportModal = () => {
    this.setState(prevState => ({
      dataImportModal: !prevState.dataImportModal
    }));
  };

  componentDidMount() {
    this.fetchPriceSeries();
  }

  render() {
    const { user, crumbs } = this.props;
    const { priceSeries, dataImportModal } = this.state;
    const { priceSeriesId } = this.props.match.params;

    return (
      <>
        <Page user={user}>
          <Page.Body>
            <Page.Content crumbs={crumbs}>
              <Segment>
                <Flex spaceBetween>
                  <Header as="h1">Price Series Detail</Header>
                  <div>
                    <Link
                      url={PRICE_SERIES_DETAILS_URL(
                        _.get(priceSeries, 'slug')
                      )}>
                      <Button>View in Website</Button>
                    </Link>
                    <VisibleIfSuperuser user={user}>
                      <Button
                        onClick={this.toggleDataImportModal}
                        color="green">
                        Import Data
                      </Button>
                    </VisibleIfSuperuser>
                  </div>
                </Flex>
                {!_.isNil(priceSeries) && (
                  <DisableableElement
                    disabled={!hasPermission(user, PERMISSIONS.CHANGE_PRICES)}>
                    <PriceSeriesUpdateForm
                      data={priceSeries}
                      fetchPriceSeries={this.fetchPriceSeries}
                    />
                  </DisableableElement>
                )}
              </Segment>
            </Page.Content>
          </Page.Body>
        </Page>
        {dataImportModal && (
          <PriceSeriesDataImportModal
            onClose={this.toggleDataImportModal}
            priceSeriesId={priceSeriesId}
          />
        )}
      </>
    );
  }
}

export default LoginRequired(
  withRouter(PermissionsRequired([PERMISSIONS.VIEW_PRICES])(PriceSeriesDetail))
);
