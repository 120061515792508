import _ from 'lodash';
import moment from 'moment';
import { notifyErrors } from './notifications';

export const handleFormErrors = (
  errors,
  setFieldError,
  { formErrorFieldNames } = {}
) => {
  _.map(errors, error => {
    let fieldName = _.camelCase(
      _.last(_.split(_.get(error, 'field', 'form'), '.'))
    );

    if (_.includes(formErrorFieldNames, fieldName)) {
      fieldName = 'form';
    }

    return setFieldError(fieldName, _.get(error, 'message'));
  });

  notifyErrors(errors);
};

export const prepareFormData = values => {
  return _.mapKeys(values, (value, key) => _.snakeCase(key));
};

export const prepareInitialValues = data =>
  _.mapKeys(data, (v, key) => _.camelCase(key));

export const prepareNestedFormData = values => {
  let result = {};

  _.forEach(_.keys(values), key => {
    let value = values[key];

    if (_.isObject(value)) {
      value = prepareFormData(value);
    }

    result[_.snakeCase(key)] = value;
  });

  return result;
};

export const getToday = () => {
  return moment().format('YYYY-MM-DD');
};

export const getCurrentDateTime = () => {
  return moment();
};

export const toSimpleSelectOptions = options => {
  return options.map(option => {
    return {
      key: option,
      value: option.toLowerCase(),
      label: option
    };
  });
};
