import _ from 'lodash';

import { reverse } from 'utils';

export const pageFactory = routes => {
  let pageIndex = new Map(routes.map(route => [route.component, route]));

  const pageUrl = (component, params = {}) =>
    reverse(pageIndex.get(component).path, params);
  const pageBreadcrumb = (component, params = {}) => {
    const data = pageIndex.get(component);

    const breadcrumbData = {
      path: pageUrl(data.component, params),
      title: _.startCase(data.title)
    };

    if (_.isNil(data.parent)) return [breadcrumbData];

    return [...pageBreadcrumb(data.parent, params), breadcrumbData];
  };

  return { pageUrl, pageBreadcrumb };
};
