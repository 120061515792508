import React from 'react';

import { Link } from 'react-router-dom';

import { Table } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { PageDetail } from 'pages';

class PageTable extends React.Component {
  getPageDetailUrl = pageId => {
    return pageUrl(PageDetail, { pageId });
  };

  render() {
    const { pages } = this.props;

    return (
      <Table celled selectable compact={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Slug</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {pages.map(page => (
            <Table.Row key={page.id}>
              <Table.Cell>
                <Link to={this.getPageDetailUrl(page.id)}>{page.id}</Link>
              </Table.Cell>
              <Table.Cell>{page.title}</Table.Cell>
              <Table.Cell>{page.identifier}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}

export default PageTable;
