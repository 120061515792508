import _ from 'lodash';

export const hasRelatedObj = eventType => {
  return _.includes(['Market Report', 'Events'], eventType);
};

export const hasCountryData = data =>
  !_.isEmpty(_.map(data, 'extra_data.Country'));

export const hasCountry = extraData => _.has(extraData, 'country');
