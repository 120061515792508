import React from 'react';

import { Header, Segment } from 'semantic-ui-react';

import { Page, LoginRequired } from 'components';

import { PermissionsRequired, PERMISSIONS } from 'permissions';

import { ClientCreateForm } from '../Create/components';

class ClientCreateCorporateChild extends React.Component {
  render() {
    const { user, crumbs } = this.props;
    const { corporateMasterId } = this.props.match.params;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Header as="h1">
                Create client linked to CM {corporateMasterId}
              </Header>
              <ClientCreateForm corporateMasterId={corporateMasterId} />
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(
  PermissionsRequired([PERMISSIONS.CREATE_CLIENTS])(ClientCreateCorporateChild)
);
