import React from 'react';

import { Field, SelectField } from 'components';
const ORDER_STATUSES = ['Pending', 'Complete', 'Cancelled'];

class OrderStatusSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = ORDER_STATUSES.map(item => {
      return {
        key: item,
        value: item,
        label: item
      };
    });

    return (
      <Field
        name={name || 'orderStatus'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default OrderStatusSelect;
