import React from 'react';

import { Table, Segment } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { WebArticleDetail } from 'pages';

import {
  TopHeadlinesForm,
  FeaturedForm,
  ProductTypeGroupForm,
  RegionForm
} from './components';

import { isArticleAdded, getArticleGroup, getArticleSection } from './utils';

import { POWER_MATERIAL_SLUG, STEEL_SLUG } from 'domainConstants';
const ArticleTable = ({
  newsletter,
  fetchNewsletterContentSections,
  newsletterContentSections,
  rfpArticles,
  hasArticlesWithoutSection,
  productSlug
}) => {
  return (
    <>
      {hasArticlesWithoutSection && (
        <Segment color="red" inverted>
          There are unassigned articles.
        </Segment>
      )}
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            {productSlug === POWER_MATERIAL_SLUG && (
              <Table.HeaderCell></Table.HeaderCell>
            )}
            <Table.HeaderCell>Title</Table.HeaderCell>
            {productSlug === STEEL_SLUG && (
              <>
                <Table.HeaderCell>Top Headlines</Table.HeaderCell>
                <Table.HeaderCell>Featured</Table.HeaderCell>
                <Table.HeaderCell>Product Type Group</Table.HeaderCell>
                <Table.HeaderCell>Region</Table.HeaderCell>
              </>
            )}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {rfpArticles.map(article => (
            <Table.Row key={article.id}>
              {productSlug === STEEL_SLUG && (
                <Table.Cell width={4}>
                  <Link
                    to={pageUrl(WebArticleDetail, {
                      productSlug,
                      articleId: article.id
                    })}>
                    {article.full_headline}
                  </Link>
                </Table.Cell>
              )}
              <Table.Cell
                style={{
                  textAlign: productSlug === STEEL_SLUG ? 'left' : 'center'
                }}>
                <TopHeadlinesForm
                  article={article}
                  newsletter={newsletter}
                  fetchNewsletterContentSections={
                    fetchNewsletterContentSections
                  }
                  productSlug={productSlug}
                  isAdded={isArticleAdded(
                    newsletterContentSections,
                    productSlug === STEEL_SLUG
                      ? 'top-headlines'
                      : 'selected-articles',
                    article
                  )}
                />
              </Table.Cell>
              {productSlug === POWER_MATERIAL_SLUG && (
                <Table.Cell>
                  <Link
                    to={pageUrl(WebArticleDetail, {
                      productSlug,
                      articleId: article.id
                    })}>
                    {article.full_headline}
                  </Link>
                </Table.Cell>
              )}
              {productSlug === STEEL_SLUG && (
                <>
                  <Table.Cell width={2}>
                    <FeaturedForm
                      article={article}
                      newsletter={newsletter}
                      fetchNewsletterContentSections={
                        fetchNewsletterContentSections
                      }
                      isAdded={isArticleAdded(
                        newsletterContentSections,
                        'featured-articles',
                        article
                      )}
                    />
                  </Table.Cell>
                  <Table.Cell width={4}>
                    <ProductTypeGroupForm
                      article={article}
                      newsletter={newsletter}
                      fetchNewsletterContentSections={
                        fetchNewsletterContentSections
                      }
                      articleProductTypeGroup={getArticleGroup(
                        newsletterContentSections,
                        'product-type-group-articles',
                        article
                      )}
                    />
                  </Table.Cell>
                  <Table.Cell width={4}>
                    <RegionForm
                      article={article}
                      newsletter={newsletter}
                      fetchNewsletterContentSections={
                        fetchNewsletterContentSections
                      }
                      articleSection={getArticleSection(
                        newsletterContentSections,
                        'region-articles',
                        article
                      )}
                    />
                  </Table.Cell>
                </>
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default ArticleTable;
