import * as yup from 'yup';
import _ from 'lodash';
import moment from 'moment-timezone';
import 'utils/yup';

export const instantNewValidationSchema = yup.object().shape({
  subject: yup.string().required('Subject is required.'),
  testEmail: yup
    .string()
    .strongEmail()
    .when('isTest', {
      is: true,
      then: schema => schema.required('Test email is required.')
    }),
  sendAt: yup
    .string()
    .nullable()
    .required('Send at is required.')
});

export const EMAIL_OPTIONS = [
  {
    key: 'news',
    value: 'news@kallanish.com',
    label: 'news@kallanish.com'
  },
  {
    key: 'marketing',
    value: 'marketing@kallanish.com',
    label: 'marketing@kallanish.com'
  }
];

export const getInitialValues = generatedData => ({
  fromName: _.get(generatedData, 'from_name', 'Kallanish'),
  fromEmail: _.get(generatedData, 'from_email', 'news@kallanish.com'),
  css: _.get(generatedData, 'css', ''),
  sendAt: _.get(generatedData, 'send_at', ''),
  ...getGeneratedContent(generatedData)
});

export const getGeneratedContent = generatedData => ({
  html: _.get(generatedData, 'html', ''),
  plainText: _.get(generatedData, 'plain_text', ''),
  subject: _.get(generatedData, 'subject', '')
});

export const checkInstantNewDateIsInPast = data => {
  const instantSendDate = _.get(data, 'send_at');

  // Load sending time as if it is already in UK time
  const sendingTime = moment.tz(instantSendDate, 'Europe/London');
  // Load current time and convert it to UK time
  const currentTime = moment().tz('Europe/London');

  if (instantSendDate && sendingTime.isBefore(currentTime)) {
    return true;
  }

  return false;
};
