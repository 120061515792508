import _ from 'lodash';

import { Formik } from 'formik';
import { useCallback } from 'react';

import { Link } from 'react-router-dom';

import { Table, Button, Form } from 'semantic-ui-react';

import { isDraftStatus, isUnapproved } from 'pages/Mailshot/Detail/utils';
import {
  MailshotClients,
  MailshotDetail,
  MailshotSelectedClients
} from 'pages/Mailshot';
import { mailshotUpdateStatus } from 'pages/Mailshot/Detail/sdk';

import { pageUrl } from 'config/routes';
import { handleFormErrors } from 'utils/forms';
import { notifyError, notifyErrors, notifySuccess } from 'utils/notifications';

import {
  AdminSelect,
  DateTimeField,
  Field,
  Flex,
  InputField,
  Tooltip,
  UnsavedFormPrompt
} from 'components';
import { PERMISSIONS, hasPermission } from 'permissions';

import { mailshotGroupItemSchema } from './utils';
import { buildInitialValues } from './utils';

import {
  mailshotGroupItemCreate,
  mailshotGroupItemDelete,
  mailshotGroupItemUpdate
} from '../../sdk';

const MailshotGroupItemForm = ({
  user,
  mailshotGroup,
  mailshotGroupItem,
  refetchGroupItems,
  history
}) => {
  const handleCreate = useCallback(
    async (values, { setSubmitting, setFieldError, resetForm }) => {
      setSubmitting(true);

      const {
        success,
        errors
      } = await mailshotGroupItemCreate(mailshotGroup.id, { ...values });
      setSubmitting(false);

      if (success) {
        notifySuccess('Mailshot created successfully.');
        resetForm();
        refetchGroupItems();
      }

      if (!success) {
        handleFormErrors(errors, setFieldError);
      }
    },
    [mailshotGroup.id, refetchGroupItems]
  );

  const handleDelete = useCallback(async () => {
    const confirm = window.confirm(
      'Are you sure you want to delete this mailshot?'
    );

    if (!confirm) {
      return;
    }

    const { success } = await mailshotGroupItemDelete(
      mailshotGroup.id,
      mailshotGroupItem.id
    );

    if (success) {
      notifySuccess('Mailshot removed successfully.');
      refetchGroupItems();
    }

    if (!success) {
      notifyError("Cound't remove mailshot.");
    }
  }, [mailshotGroup.id, mailshotGroupItem?.id, refetchGroupItems]);

  const handleUpdate = useCallback(
    async (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true);

      const {
        success,
        errors
      } = await mailshotGroupItemUpdate(
        mailshotGroup.id,
        mailshotGroupItem.id,
        { ...values }
      );
      setSubmitting(false);

      if (success) {
        notifySuccess('Mailshot updated successfully.');
        refetchGroupItems();
      }

      if (!success) {
        handleFormErrors(errors, setFieldError);
      }
    },
    [mailshotGroup.id, mailshotGroupItem?.id, refetchGroupItems]
  );

  const goToSelectUsers = useCallback(() => {
    const { mailshot } = mailshotGroupItem;

    const url = `${pageUrl(MailshotClients, {
      mailshotId: mailshot.id
    })}?unsubscribed=false&account_manager=${
      mailshotGroupItem.account_manager.id
    }`;

    history.push(url);
  }, [mailshotGroupItem, history]);

  const goToSelectedUsers = useCallback(() => {
    const { mailshot } = mailshotGroupItem;

    const url = pageUrl(MailshotSelectedClients, { mailshotId: mailshot.id });

    history.push(url);
  }, [mailshotGroupItem, history]);

  const changeStatus = useCallback(async () => {
    const { mailshot } = mailshotGroupItem;
    const status = isDraftStatus(mailshot) ? 'Ready' : 'Draft';

    const confirm = window.confirm(
      `Are you sure you want to change the status to "${status}"?`
    );

    if (!confirm) {
      return;
    }

    const data = {
      status
    };

    const { success, errors } = await mailshotUpdateStatus(mailshot.id, data);

    if (success) {
      notifySuccess('Mailshot preparation started.');
      refetchGroupItems();
    } else {
      notifyErrors(errors);
    }
  }, [mailshotGroupItem, refetchGroupItems]);

  const isMailshotDraft =
    !_.isNil(mailshotGroupItem) && isDraftStatus(mailshotGroupItem.mailshot);

  const canDeleteItem =
    !_.isNil(mailshotGroupItem) && isUnapproved(mailshotGroupItem.mailshot);

  const hasCMUsers =
    !_.isNil(mailshotGroupItem) && !_.isEmpty(mailshotGroupItem.cm_users);

  return (
    <Formik
      onSubmit={_.isNil(mailshotGroupItem) ? handleCreate : handleUpdate}
      initialValues={buildInitialValues(mailshotGroupItem)}
      enableReinitialize={true}
      validationSchema={mailshotGroupItemSchema}>
      {({ handleSubmit, isSubmitting, dirty, values, errors }) => (
        <>
          <UnsavedFormPrompt when={dirty} formName="Mailshot Group Item form" />
          <Form as={Table.Row}>
            <Table.Cell>
              <Flex style={{ flexDirection: 'column', gap: '4px' }}>
                {mailshotGroupItem && (
                  <Link
                    style={{ alignSelf: 'start' }}
                    to={pageUrl(MailshotDetail, {
                      mailshotId: mailshotGroupItem.mailshot.id
                    })}>
                    {mailshotGroupItem.mailshot.title}
                  </Link>
                )}
                <Field
                  required
                  name="subject"
                  placeholder="Subject"
                  component={InputField}
                />
                <Field
                  required
                  name="scheduled_date"
                  component={DateTimeField}
                />
              </Flex>
            </Table.Cell>
            <Table.Cell>
              <AdminSelect required name="account_manager" />
            </Table.Cell>
            <Table.Cell textAlign="center">{values.status}</Table.Cell>
            <Table.Cell textAlign="center">{values.sending_status}</Table.Cell>
            <Table.Cell textAlign="center">{values.total_users}</Table.Cell>
            <Table.Cell textAlign="center">{values.total_opened}</Table.Cell>
            <Table.Cell>
              {mailshotGroupItem ? (
                <Flex style={{ gap: '4px' }}>
                  <Flex style={{ flexDirection: 'column', gap: '4px' }}>
                    <Button
                      fluid
                      basic
                      color="teal"
                      onClick={goToSelectedUsers}>
                      Selected users
                    </Button>
                    <Button
                      fluid
                      color="teal"
                      onClick={goToSelectUsers}
                      disabled={
                        !hasPermission(user, PERMISSIONS.CHANGE_MAILSHOTS) ||
                        !isMailshotDraft
                      }>
                      Select users
                    </Button>
                  </Flex>

                  <Flex style={{ flexDirection: 'column', gap: '4px' }}>
                    <Tooltip
                      disabled={!hasCMUsers || !isMailshotDraft}
                      on="hover"
                      content="Remove CM accounts to be able to mark the mailshot as ready."
                      trigger={
                        <div>
                          <Button
                            fluid
                            color="red"
                            onClick={changeStatus}
                            disabled={hasCMUsers && isMailshotDraft}>
                            Mark as {isMailshotDraft ? 'Ready' : 'Draft'}
                          </Button>
                        </div>
                      }
                    />
                    <Flex style={{ width: '100%' }}>
                      <Tooltip
                        on="hover"
                        content="Save changes to the selected mailshot."
                        trigger={
                          <Button
                            fluid
                            color="blue"
                            icon="save"
                            type="submit"
                            onClick={handleSubmit}
                            disabled={isSubmitting || !_.isEmpty(errors)}
                          />
                        }
                      />
                      <Tooltip
                        on="hover"
                        content="Delete this mailshot."
                        trigger={
                          <Button
                            fluid
                            basic
                            icon="trash"
                            color="red"
                            onClick={handleDelete}
                            disabled={isSubmitting || !canDeleteItem}
                          />
                        }
                      />
                    </Flex>
                  </Flex>
                </Flex>
              ) : (
                <Flex>
                  <Button
                    size="large"
                    color="blue"
                    onClick={handleSubmit}
                    type="submit"
                    disabled={isSubmitting || !_.isEmpty(errors)}>
                    Add New Mailshot
                  </Button>
                </Flex>
              )}
            </Table.Cell>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default MailshotGroupItemForm;
