import React from 'react';

import { pageUrl } from 'config/routes';

import { GroupsAndPermissionsCreate } from 'pages';

import {
  Flex,
  Page,
  Header,
  LoginRequired,
  Button,
  ListViewEssentials
} from 'components';
import { PERMISSIONS, PermissionsRequired } from 'permissions';

import { groupsList } from './sdk';

import { GroupsAndPermissionsTable } from './components';

const GroupsAndPermissionsList = ({ user, data, history }) => (
  <Page user={user}>
    <Page.Body>
      <Page.Content>
        <Flex spaceBetween>
          <Header>Groups and Permissions</Header>
          <Button
            primary
            size="large"
            onClick={() => history.push(pageUrl(GroupsAndPermissionsCreate))}>
            Create group
          </Button>
        </Flex>
        {data && <GroupsAndPermissionsTable data={data} />}
      </Page.Content>
    </Page.Body>
  </Page>
);

export default ListViewEssentials({
  sdk: groupsList
})(
  LoginRequired(
    PermissionsRequired([PERMISSIONS.VIEW_GROUPS])(GroupsAndPermissionsList)
  )
);
