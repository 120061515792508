import React from 'react';

import { Icon } from 'semantic-ui-react';

import { FormRow } from 'components';

import styles from './styles.module.css';

const AdvancedFiltersLabel = ({ isOpen, toggleAction }) => (
  <FormRow flexEnd>
    <div className={styles.showAdvancedLabel} onClick={toggleAction}>
      {!isOpen ? 'Open' : 'Close'} advanced filters
      <Icon name={!isOpen ? 'angle down' : 'angle up'} />
    </div>
  </FormRow>
);

export default AdvancedFiltersLabel;
