import React from 'react';
import _ from 'lodash';

import { Segment } from 'semantic-ui-react';
import { FormRow } from 'components';

const ErrorsAndButtonsInRow = ({ errors, touched, children }) => {
  const hasFieldErrors =
    !_.isEmpty(errors) &&
    _.isEmpty(
      _.filter(
        _.keys(errors),
        fieldName => _.indexOf(_.keys(touched), fieldName) < 0
      )
    );

  return (
    <FormRow
      style={{ justifyContent: hasFieldErrors ? 'space-between' : 'flex-end' }}
      alignCenter>
      {hasFieldErrors && (
        <Segment color="red" inverted compact>
          This form has one or more errors. They need to be fixed before the
          form can be submitted.
        </Segment>
      )}
      {children}
    </FormRow>
  );
};

export default ErrorsAndButtonsInRow;
