import React from 'react';

import { withRouter } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  InputField,
  CheckboxField,
  FormRow,
  CreateButtonInRow,
  FileField,
  UnsavedFormPrompt
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { pageUrl } from 'config/routes';

import { BannerList } from 'pages';

import { bannerCreate } from './sdk';

import { bannerCreateSchema } from './utils';

class BannerCreateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await bannerCreate(data);

    if (success) {
      this.props.history.push(pageUrl(BannerList));
      notifySuccess('Banner created');
      return;
    } else {
      handleFormErrors(errors, setFieldError);
    }
    setSubmitting(false);
  };

  render() {
    const initialValues = {
      title: '',
      image: null,
      openInNewWindow: true,
      homePageBanner: false
    };

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={bannerCreateSchema}
        onSubmit={this.handleSubmit}>
        {({ values, handleSubmit, isSubmitting, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty && !isSubmitting} />

            <FormRow>
              <Field
                required
                width={6}
                name="title"
                component={InputField}
                label="Title:"
              />
              <Field width={6} name="url" component={InputField} label="URL:" />
            </FormRow>
            <FormRow>
              <Field
                required
                width={4}
                name="image"
                component={FileField}
                label="Image"
              />
            </FormRow>
            <FormRow>
              <Field
                width={2}
                name="openInNewWindow"
                component={CheckboxField}
                label="Open in new window"
              />

              <Field
                width={2}
                name="homePageBanner"
                component={CheckboxField}
                label="Home page"
              />
            </FormRow>
            <CreateButtonInRow onClick={handleSubmit} disabled={isSubmitting} />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(BannerCreateForm);
