import React from 'react';

import { Field, SelectField } from 'components';
import { energyCategoryList } from './sdk';

class EnergyCategorySelect extends React.Component {
  state = {
    energyCategories: []
  };
  async componentDidMount() {
    const { data, success } = await energyCategoryList();

    if (success) {
      this.setState({ energyCategories: data });
    }
  }

  render() {
    const { name, ...rest } = this.props;
    const { energyCategories } = this.state;

    const options = energyCategories.map(category => {
      return {
        key: category.id,
        value: category.id,
        label: category.name
      };
    });

    return (
      <Field
        name={name || 'energyCategory'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default EnergyCategorySelect;
