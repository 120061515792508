import React, { useState, useEffect } from 'react';

import { pageUrl } from 'config/routes';
import { useHistory } from 'react-router-dom';

import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { Form, Divider } from 'semantic-ui-react';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import {
  Field,
  ErrorsAndUpdateButtonInRow,
  FormRow,
  InputField,
  IndexTypeSelect,
  UnsavedFormPrompt,
  CreatedUpdatedFormValues,
  UniSelect,
  PriceIndexesTable,
  YesNoSelect,
  CheckboxField
} from 'components';

import { validationSchema, getInitialValues } from './utils';
import { priceIndexesUpdate } from './sdk';

import { PriceIndexList } from 'pages';

function PriceIndexesUpdateForm({ priceSeries, fetchPriceSeries }) {
  const [additionalOptions, setAdditionalOptions] = useState([]);
  const [choices, setChoices] = useState([]);
  const [totalPercent, setTotalPercent] = useState(0);
  const { productSlug } = useParams();
  const [priceInitialValues, setPriceInitialValues] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [units, setUnits] = useState([]);
  let history = useHistory();

  useEffect(() => {
    const filteredOptions = priceSeries.prices.filter(
      item => item.type === 'manual_component'
    );
    const priceElements = priceSeries.prices.filter(
      item => item.type === 'price'
    );
    setAdditionalOptions(filteredOptions);
    const priceDataObjects = priceElements.map(price => ({
      id: price.price_id,
      percent: price.percent
    }));
    setPriceInitialValues(priceDataObjects);
  }, [priceSeries]);

  const handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;
    if (totalPercent !== 100) {
      setSubmitting(false);
      return;
    }
    const prices = values.prices.map(selectedOptionId => ({
      price: selectedOptionId,
      percent: values[`percent_${selectedOptionId}`]
    }));

    const manual_components = additionalOptions.map(option => ({
      name: option.manual_component_name,
      value: option.manual_component_value,
      percent: option.percent
    }));

    const cleanedValues = { ...values };
    Object.keys(cleanedValues).forEach(key => {
      if (key.startsWith('percent_')) {
        delete cleanedValues[key];
      }
    });

    const data = {
      ...prepareFormData({
        ...cleanedValues,
        prices,
        manual_components
      })
    };

    const { errors, success } = await priceIndexesUpdate(priceSeries.id, data);

    setSubmitting(true);

    if (success) {
      notifySuccess('Price Index has been updated successfully.');
      return history.push(
        pageUrl(PriceIndexList, { productSlug: productSlug })
      );
    }

    handleFormErrors(errors, setFieldError);
    setSubmitting(false);
  };

  const initialValues = getInitialValues(priceSeries);

  const { createdAt, createdBy, updatedAt, updatedBy } = initialValues;

  const nonFormValues = {
    createdAt,
    createdBy,
    updatedAt,
    updatedBy
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}>
      {({
        values,
        isSubmitting,
        handleSubmit,
        dirty,
        touched,
        errors,
        setFieldValue
      }) => (
        <Form>
          <UnsavedFormPrompt when={dirty && !isSubmitting} />
          <FormRow>
            <Field
              width={7}
              name="activeInWebsite"
              label="Show on home page"
              component={CheckboxField}
            />
          </FormRow>
          <FormRow>
            <Field
              required
              width={7}
              name="title"
              label="Title:"
              component={InputField}
            />
            <Field width={7} name="slug" label="Slug:" component={InputField} />
          </FormRow>
          <FormRow>
            <UniSelect
              required
              width={7}
              name="currency"
              label="Currency:"
              source="priceSerieCurrencies"
              apiSuccessCallback={currencies => {
                setCurrencies(currencies);
              }}
            />
            <IndexTypeSelect
              width={7}
              required
              name="type"
              label="Index Type"
            />
          </FormRow>
          <FormRow>
            <Field
              width={7}
              name="is_free"
              required
              component={YesNoSelect}
              isClearable={false}
              label="Is Free"
            />
            <UniSelect
              required
              width={7}
              name="unit"
              label="Unit:"
              source="priceSerieUnits"
              apiSuccessCallback={units => {
                setUnits(units);
              }}
            />
          </FormRow>
          <FormRow>
            <UniSelect
              required
              width={7}
              name="groupId"
              label="Category:"
              source="priceSerieCategories"
              productSlug={productSlug}
            />
          </FormRow>
          <FormRow>
            <UniSelect
              name="prices"
              label="Price series"
              isMulti
              closeMenuOnSelect={false}
              transferList={true}
              source="priceSeries"
              productSlug={productSlug}
              apiSuccessCallback={choices => {
                setChoices(choices);
              }}
            />
          </FormRow>
          <FormRow>
            <PriceIndexesTable
              name="manualPrices"
              values={values}
              choices={choices}
              additionalOptions={additionalOptions}
              setAdditionalOptions={setAdditionalOptions}
              setTotalPercent={setTotalPercent}
              priceInitialValues={priceInitialValues}
              setFieldValue={setFieldValue}
              currencies={currencies}
              units={units}
            />
          </FormRow>
          <FormRow>
            {totalPercent !== 100 && (
              <div
                style={{
                  color: 'red',
                  margin: '15px',
                  justifyContent: 'flex-end'
                }}>
                Total percentage should be 100%
              </div>
            )}
          </FormRow>
          <Divider />
          <FormRow widths="equal">
            <Field name="metaTitle" label="Meta Title" component={InputField} />

            <Field
              name="metaKeywords"
              label="Meta Keywords"
              component={InputField}
            />

            <Field
              name="metaDescription"
              label="Meta Description"
              component={InputField}
            />

            <Field
              name="metaStandout"
              label="Meta Standout"
              component={InputField}
            />
          </FormRow>
          <CreatedUpdatedFormValues values={nonFormValues} />
          <ErrorsAndUpdateButtonInRow
            subject="Price Index"
            onClick={handleSubmit}
            disabled={isSubmitting}
            errors={errors}
            touched={touched}
          />
        </Form>
      )}
    </Formik>
  );
}

export default PriceIndexesUpdateForm;
