import * as yup from 'yup';
import 'utils/yup';

export const staffCreateSchema = yup.object().shape({
  email: yup
    .string()
    .strongEmail('Invalid email.')
    .required('Email is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required')
});
