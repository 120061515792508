import React from 'react';

import _ from 'lodash';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { FormRow, Field, InputField, UpdateButton } from 'components';

import { permanentPriceSectionUpdate } from './sdk';
import { permamentPriceSectionSchema } from './utils';

class SectionUpdateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { success, errors } = await permanentPriceSectionUpdate(
      this.props.section.id,
      data
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Heading created.');
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  render() {
    const initialValues = {
      name: _.get(this.props.section, 'name')
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        validationSchema={permamentPriceSectionSchema}>
        {({ isSubmitting, handleSubmit }) => (
          <Form style={{ maxWidth: '500px' }}>
            <FormRow>
              <Field width={12} name="name" component={InputField} />

              <UpdateButton disabled={isSubmitting} onClick={handleSubmit} />
            </FormRow>
            <div>
              This heading changes the header of the newsletter section.
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default SectionUpdateForm;
