import React from 'react';

import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import { Header, Segment, Button as SemanticButton } from 'semantic-ui-react';

import {
  Page,
  Error,
  LoginRequired,
  Meta,
  ContentEditionWarning,
  Flex,
  InstantNewsTable,
  InstantNewsHeadline,
  Button
} from 'components';

import {
  DisableableElement,
  hasPermission,
  PERMISSIONS,
  PermissionsRequired,
  VisibleIf
} from 'permissions';

import { pageUrl } from 'config/routes';

import { TrackingComments, WebArticleCreate } from 'pages';
import { EDITION_WARNING_CONTENT_TYPES } from 'domainConstants';

import WebArticleUpdateForm from './form';

import ArticleHistoryModal from './ArticleHistoryModal';

import {
  articleDetail,
  articleUpdateMeta,
  globalProductsList,
  articlePushToProduct
} from './sdk';

import { reverse } from 'utils';

class WebArticleDetail extends React.Component {
  state = {
    article: null,
    errors: null,
    globalProducts: null,
    openModal: false,
    articleHistory: null
  };

  setOpenModal = openModal => {
    this.setState({ openModal: openModal });
  };

  fetchArticle = async () => {
    const { productSlug, articleId } = this.props.match.params;

    const { data, errors, success } = await articleDetail(
      productSlug,
      articleId
    );
    if (success) {
      this.setState({ article: data, productSlug: productSlug });
    } else {
      this.setState({ errors });
    }
  };
  fetchGlobalProducts = async () => {
    const { data, errors } = await globalProductsList();
    if (data) {
      const filteredData = data.filter(
        element => element.slug !== this.props.match.params.productSlug
      );
      this.setState({ globalProducts: filteredData });
    } else {
      this.setState({ errors });
    }
  };

  componentDidMount() {
    this.fetchArticle();
    this.fetchGlobalProducts();
  }

  openArticleComments = articleId => {
    this.props.history.push({
      pathname: pageUrl(TrackingComments),
      search: `?power_material_article_id=${articleId}`
    });
  };

  transferArticle = async targetProductSlug => {
    const { productSlug, articleId } = this.props.match.params;

    const { success } = await articlePushToProduct(productSlug, articleId);
    const { text, full_headline: fullHeadline } = this.state.article;

    if (success) {
      const location = reverse(pageUrl(WebArticleCreate), {
        productSlug: targetProductSlug
      });

      this.props.history.push(location, {
        sourceArticle: { text, fullHeadline }
      });
    }
  };

  render() {
    const { user, crumbs } = this.props;
    const { article, errors, productSlug, globalProducts } = this.state;

    const hasNoPermissionToUpdateRfpArticles =
      article &&
      !hasPermission(user, PERMISSIONS.CHANGE_RFP_ARTICLES) &&
      (article.status === 'Live' || article.status === 'Ready for Publication');

    const hasComments = _.get(article, 'has_comments', false);

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            {!_.isNil(article) && (
              <ContentEditionWarning
                user={user}
                contentType={
                  productSlug === 'steel'
                    ? EDITION_WARNING_CONTENT_TYPES.STEEL_ARTICLE
                    : EDITION_WARNING_CONTENT_TYPES.POWER_MATERIALS_ARTICLE
                }
                contentId={article.id}
              />
            )}
            <Segment>
              <Flex spaceBetween style={{ marginBottom: '20px' }}>
                <Header as="h1">Edit article</Header>
                <Flex>
                  {hasComments && (
                    <SemanticButton
                      onClick={() => this.openArticleComments(article.id)}>
                      Comments
                    </SemanticButton>
                  )}
                  {!_.isNil(article) && (
                    <VisibleIf
                      user={user}
                      permissions={[PERMISSIONS.PUBLISH_ARTICLES]}>
                      <ArticleHistoryModal
                        articleId={article.id}
                        onOpen={() => this.setOpenModal(true)}
                        onClose={() => this.setOpenModal(false)}
                      />
                    </VisibleIf>
                  )}
                  {globalProducts &&
                    globalProducts.map(product => {
                      return (
                        <Button
                          className="transfer-article"
                          color="orange"
                          onClick={() => {
                            this.transferArticle(product.slug);
                          }}
                          size={'large'}>
                          Push to {product.name}
                        </Button>
                      );
                    })}

                  {article && article.status === 'Ready for Publication' && (
                    <DisableableElement
                      disabled={
                        !hasPermission(user, PERMISSIONS.CHANGE_ARTICLES)
                      }>
                      <InstantNewsHeadline
                        webArticle={article}
                        fetchArticle={this.fetchArticle}
                      />
                    </DisableableElement>
                  )}
                </Flex>
              </Flex>
              {!_.isEmpty(errors) &&
                errors.map((error, index) => (
                  <Error key={index}>{error.message}</Error>
                ))}{' '}
              <div>
                {article && (
                  <>
                    <DisableableElement
                      disabled={
                        !hasPermission(user, PERMISSIONS.CHANGE_ARTICLES)
                      }>
                      <WebArticleUpdateForm
                        user={user}
                        article={article}
                        fetchArticle={this.fetchArticle}
                        productSlug={productSlug}
                      />
                    </DisableableElement>
                    <DisableableElement
                      disabled={
                        !hasPermission(user, PERMISSIONS.CHANGE_ARTICLES) ||
                        hasNoPermissionToUpdateRfpArticles
                      }>
                      <Meta
                        object={article}
                        fetchObject={this.fetchArticle}
                        sdk={articleUpdateMeta}
                      />
                    </DisableableElement>
                    {article && article.status === 'Ready for Publication' && (
                      <DisableableElement
                        disabled={
                          !hasPermission(user, PERMISSIONS.CHANGE_ARTICLES)
                        }>
                        <div id="instant-news-table">
                          <InstantNewsTable
                            webArticle={article}
                            viewDetails={this.toggleInstantNewsModal}
                            fetchArticle={this.fetchArticle}
                          />
                        </div>
                      </DisableableElement>
                    )}
                  </>
                )}
              </div>
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default withRouter(
  LoginRequired(
    PermissionsRequired([PERMISSIONS.VIEW_ARTICLES])(WebArticleDetail)
  )
);
