import React from 'react';

import _ from 'lodash';

import { Field, TransferListField } from 'components';

import { glossaryTermList } from './sdk';

class GlossaryTermsSelect extends React.Component {
  state = {
    glossaryTerms: []
  };

  async componentDidMount() {
    const { data, success } = await glossaryTermList();

    if (success) {
      this.setState({
        glossaryTerms: _.map(data, term => ({
          key: term.id,
          value: term.id,
          label: term.title
        }))
      });
    }
  }

  render() {
    const { name, excludeOptions, ...rest } = this.props;
    const { glossaryTerms } = this.state;

    let options = glossaryTerms;

    if (!_.isEmpty(excludeOptions)) {
      options = _.filter(
        options,
        option => _.indexOf(excludeOptions, option.value) < 0
      );
    }

    return (
      <>
        <Field
          name={name || 'glossaryTerms'}
          component={TransferListField}
          options={options}
          isClearable
          {...rest}
        />
      </>
    );
  }
}

export default GlossaryTermsSelect;
