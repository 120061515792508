import React from 'react';

import { Table } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { DailyNewsletterDetail } from 'pages';

import { formatDateTime } from 'utils';

import { YesNo, Admin, TableFooter, SortableHeader } from 'components';

const DailyNewsletterTable = ({
  dailyNewsletters,
  pagination,
  sort,
  orderBy
}) => (
  <Table celled selectable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell singleLine style={{ fontWeight: '400' }} colspan="11">
          {pagination}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <SortableHeader sort={sort} orderBy={orderBy} field="issue">
          Issue
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="date">
          Date to go out
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="status">
          Status
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="sending_status">
          Sending Status
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="editor_approved">
          Editor Approved
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="editor">
          Editor
        </SortableHeader>
        <SortableHeader
          sort={sort}
          orderBy={orderBy}
          field="marketing_approved">
          Marketing Approved
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="marketer">
          Marketer
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="copies">
          Copies sent
        </SortableHeader>
        <Table.HeaderCell>Articles</Table.HeaderCell>
        <Table.HeaderCell>Prices</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {dailyNewsletters.map(dailyNewsletter => (
        <Table.Row key={dailyNewsletter.id}>
          <Table.Cell>
            <Link
              to={pageUrl(DailyNewsletterDetail, {
                dailyNewsletterId: dailyNewsletter.id
              })}>
              # {dailyNewsletter.id}
            </Link>
          </Table.Cell>
          <Table.Cell>{formatDateTime(dailyNewsletter.datetime)}</Table.Cell>
          <Table.Cell>{dailyNewsletter.status}</Table.Cell>
          <Table.Cell>{dailyNewsletter.sending_status}</Table.Cell>
          <Table.Cell>
            <YesNo value={dailyNewsletter.editor_approved} />
          </Table.Cell>
          <Table.Cell>
            <Admin value={dailyNewsletter.editor} />
          </Table.Cell>
          <Table.Cell>
            <YesNo value={dailyNewsletter.marketing_approved} />
          </Table.Cell>
          <Table.Cell>
            <Admin value={dailyNewsletter.marketer} />
          </Table.Cell>
          <Table.Cell>{dailyNewsletter.sent_count}</Table.Cell>
          <Table.Cell>{dailyNewsletter.article_count}</Table.Cell>
          <Table.Cell>{dailyNewsletter.price_count}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default DailyNewsletterTable;
