import React from 'react';

import { withRouter } from 'react-router-dom';

import { Header, Segment } from 'semantic-ui-react';

import { Page, LoginRequired } from 'components';
import { notifyErrors } from 'utils/notifications';

import { glossaryCategoryDetail } from './sdk';

import { GlossaryCategoryUpdateForm } from './components';

class GlossaryCategoryDetail extends React.Component {
  state = {
    glossaryCategory: null
  };

  fetchGlossaryCategory = async () => {
    const { glossaryCategoryId } = this.props.match.params;

    const { data, errors, success } = await glossaryCategoryDetail(
      glossaryCategoryId
    );

    if (success) {
      this.setState({ glossaryCategory: data });
    } else {
      notifyErrors(errors);
    }
  };

  componentDidMount() {
    this.fetchGlossaryCategory();
  }

  render() {
    const { user, crumbs } = this.props;

    const { glossaryCategory } = this.state;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Header as="h1">Edit Glossary Category</Header>

            {glossaryCategory && (
              <Segment>
                <GlossaryCategoryUpdateForm
                  glossaryCategory={glossaryCategory}
                  fetchGlossaryCategory={this.fetchGlossaryCategory}
                />
              </Segment>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(withRouter(GlossaryCategoryDetail));
