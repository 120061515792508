import React from 'react';

import { Flex } from 'components';

import { Logo } from 'components';

class Maintenance extends React.Component {
  render() {
    return (
      <Flex style={{ height: '100vh' }}>
        <Flex style={{ flexDirection: 'column' }}>
          <Logo
            style={{
              height: '65px',
              position: 'relative',
              bottom: '20px'
            }}></Logo>
          <Flex
            style={{
              border: '2px solid #719a30',
              flexDirection: 'column',
              padding: '20px',
              color: '#719a30',
              fontSize: '26px',
              margin: '10px',
              textAlign: 'center'
            }}>
            <p style={{ margin: '5px' }}>
              Kallanish is undergoing a scheduled maintenance.
            </p>
            <p style={{ margin: '5px' }}>
              {' '}
              If you need something urgent please get in touch with us on{' '}
              <a href="mailto:info@kallanish.com">info@kallanish.com</a>.
            </p>
            <p style={{ margin: '5px' }}>
              We apologise for this inconvenience.
            </p>
          </Flex>
        </Flex>
      </Flex>
    );
  }
}

export default Maintenance;
