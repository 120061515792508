import React from 'react';

import { sortableElement } from 'react-sortable-hoc';

import styles from './styles.module.css';

const Item = ({ title }) => {
  return <div className={styles.Item}>{title}</div>;
};

export default sortableElement(Item);
