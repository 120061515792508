import React from 'react';

import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { Form, Divider } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { PriceSpreadList } from 'pages';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';
import { populateSlug } from 'utils';

import {
  Field,
  CreateButtonInRow,
  FormRow,
  InputField,
  PriceSeriesSingleSelect,
  CheckboxField,
  UnsavedFormPrompt
} from 'components';

import { priceSpreadCreate } from './sdk';

import { DEFAULT_INITIAL_VALUES, validationSchema } from './utils';

class PriceSpreadCreateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await priceSpreadCreate(data);

    if (success) {
      notifySuccess('Price spread created.', {
        onOpen: this.props.history.push(pageUrl(PriceSpreadList))
      });
      setSubmitting(false);

      return;
    }

    handleFormErrors(errors, setFieldError);
    setSubmitting(false);
  };

  render() {
    const initialValues = DEFAULT_INITIAL_VALUES;
    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}>
        {({ isSubmitting, handleSubmit, setFieldValue, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty && !isSubmitting} />
            <FormRow>
              <Field
                required
                width={6}
                name="title"
                label="Title:"
                component={InputField}
                onChange={(e, data) => {
                  populateSlug(data, setFieldValue);
                }}
              />

              <Field
                required
                width={4}
                name="shortTitle"
                label="Short title:"
                component={InputField}
              />
            </FormRow>
            <FormRow>
              <Field
                required
                width={6}
                name="slug"
                label="Slug:"
                component={InputField}
                onChange={(e, data) => {
                  populateSlug(data, setFieldValue);
                }}
              />
              <Field
                required
                width={4}
                name="displayAs"
                label="Display as:"
                component={InputField}
              />
              <Field
                required
                width={2}
                name="showOnHomepage"
                label="Show on homepage"
                component={CheckboxField}
              />
            </FormRow>
            <FormRow>
              <PriceSeriesSingleSelect
                required
                width={6}
                name="priceSerie1"
                label="Price Serie 1:"
              />

              <PriceSeriesSingleSelect
                required
                width={6}
                name="priceSerie2"
                label="Price Serie 2:"
              />
            </FormRow>
            <Divider />
            <FormRow widths="equal">
              <Field
                name="metaTitle"
                label="Meta Title"
                component={InputField}
              />

              <Field
                name="metaKeywords"
                label="Meta Keywords"
                component={InputField}
              />

              <Field
                name="metaDescription"
                label="Meta Description"
                component={InputField}
              />

              <Field
                name="metaStandout"
                label="Meta Standout"
                component={InputField}
              />
            </FormRow>
            <CreateButtonInRow onClick={handleSubmit} disabled={isSubmitting} />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(PriceSpreadCreateForm);
