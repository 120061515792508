import React from 'react';

import { Segment, Header, Table, Button } from 'semantic-ui-react';

import { notifyErrors } from 'utils/notifications';

import { PromoCodesForm, PromoCodesUsageModal } from './components';
import { Flex, Tooltip } from 'components';
import { promoCodeUsage } from './sdk';

class PromoCodes extends React.Component {
  state = {
    isOpenPromoCodesUsageModal: false,
    promocodesUsage: null
  };
  fetchPromoCodesUsage = async () => {
    const { data, errors, success } = await promoCodeUsage(
      this.props.course.item.id
    );
    if (success) {
      this.setState({ promocodesUsage: data });
    } else {
      notifyErrors(errors);
    }
  };

  toggleIsOpenPromoCodesUsageModal = () => {
    // Fetch promo code usage data when the modal is opened
    if (!this.state.isOpenPromoCodesUsageModal) {
      this.fetchPromoCodesUsage();
    }

    this.setState(prevState => ({
      isOpenPromoCodesUsageModal: !prevState.isOpenPromoCodesUsageModal
    }));
  };

  async componentDidMount() {
    this.fetchPromoCodesUsage();
  }

  render() {
    const { promocodes, course, fetchPromoCodes } = this.props;
    const { promocodesUsage } = this.state;
    return (
      <Segment>
        <Flex spaceBetween>
          <Header as="h2">Promo Codes</Header>
          <Tooltip
            disabled={promocodesUsage && promocodesUsage.length > 0}
            on="hover"
            content="This event has no used promocodes yet."
            trigger={
              <div>
                <Button
                  basic
                  color="blue"
                  size="small"
                  disabled={promocodesUsage && promocodesUsage.length === 0}
                  onClick={this.toggleIsOpenPromoCodesUsageModal}>
                  View Website Usage
                </Button>
              </div>
            }
          />
        </Flex>
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>Code</Table.HeaderCell>
              <Table.HeaderCell width={3}>Description</Table.HeaderCell>
              <Table.HeaderCell width={2}>Discount Type</Table.HeaderCell>
              <Table.HeaderCell width={2}>Amount</Table.HeaderCell>
              <Table.HeaderCell width={2}>Start date</Table.HeaderCell>
              <Table.HeaderCell width={2}>End date</Table.HeaderCell>
              <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {promocodes.map(promocode => (
              <PromoCodesForm
                key={promocode.id}
                course={course}
                promocode={promocode}
                promocodes={promocodes}
                fetchPromoCodes={fetchPromoCodes}
                fetchPromoCodesUsage={this.fetchPromoCodesUsage}
              />
            ))}
            <PromoCodesForm
              course={course}
              promocodes={promocodes}
              fetchPromoCodes={fetchPromoCodes}
            />
          </Table.Body>
        </Table>
        {this.state.isOpenPromoCodesUsageModal && (
          <PromoCodesUsageModal
            onClose={this.toggleIsOpenPromoCodesUsageModal}
            course={course}
            promocodesUsage={promocodesUsage}
          />
        )}
      </Segment>
    );
  }
}

export default PromoCodes;
