import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const staffDetail = async userId => {
  const url = `${BASE_URL}/users/staff-members/${userId}/`;

  return await requestSdk(() => get(url));
};

export const memberSendResetPassword = async id => {
  const url = `${BASE_URL}/crm/clients/${id}/reset-password/`;

  return await requestSdk(() => post(url));
};
