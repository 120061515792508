import _ from 'lodash';

import React from 'react';

import { Field, SelectField } from 'components';
import { authorList } from './sdk';

class AuthorSelect extends React.Component {
  state = {
    options: []
  };

  async componentDidMount() {
    const { data, success } = await authorList();

    if (success) {
      let options = data.map(({ id, email, first_name, last_name }) => {
        let label = email;
        if (_.every([first_name, last_name]))
          label = `${first_name} ${last_name}`;

        return {
          label,
          value: id,
          key: id
        };
      });
      options = _.sortBy(options, 'label');
      this.setState({ options });
    }
  }

  render() {
    const { name, ...rest } = this.props;

    const { options } = this.state;

    return (
      <Field
        name={name || 'author'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default AuthorSelect;
