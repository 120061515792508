import React from 'react';
import { Header } from 'semantic-ui-react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import {
  Page,
  ListViewEssentials,
  Flex,
  Button,
  Pagination,
  ExternalLink,
  Filters as SharedFilters
} from 'components';

import { pageUrl } from 'config/routes';
import { PriceSpreadV2Create } from 'pages';
import { PERMISSIONS, VisibleIf } from 'permissions';
import {
  PRICE_SPREAD_V2_LIST_URL,
  PRICE_SPREADS_V2_DOWNLOAD_URL
} from 'sdk/urls';
import { withLoggedUser, withPermissions } from 'hooks';

import { priceSpreadV2List } from './sdk';

import { PriceSpreadsTable } from './components';

import { FILTERS as LAYOUT_FILTERS } from './utils';

const PriceSpreadV2List = ({
  user,
  data,
  crumbs,
  filters,
  count,
  filterBy,
  clear,
  sort,
  orderBy,
  limit,
  page,
  changePage,
  changeRowsPerPage,
  history
}) => {
  const { productSlug } = useParams();

  const mainGroup = _.get(_.first(data), 'main_group_slug');

  return (
    <Page user={user} crumbs={crumbs}>
      <Page.Body>
        <Page.Content>
          <Flex spaceBetween>
            <Header as="h1">Price Spreads</Header>
            <div>
              <ExternalLink url={PRICE_SPREADS_V2_DOWNLOAD_URL(productSlug)}>
                <Button color="orange" size="large">
                  Download Latest Spreads
                </Button>
              </ExternalLink>
              {mainGroup && (
                <ExternalLink url={PRICE_SPREAD_V2_LIST_URL(mainGroup)}>
                  <Button color="orange" size="large">
                    Price spreads in website
                  </Button>
                </ExternalLink>
              )}
              <VisibleIf user={user} permissions={[PERMISSIONS.CREATE_PRICES]}>
                <Button
                  size="large"
                  onClick={() =>
                    history.push(pageUrl(PriceSpreadV2Create, { productSlug }))
                  }>
                  Add Price Spread
                </Button>
              </VisibleIf>
            </div>
          </Flex>
          {data && (
            <>
              <SharedFilters
                filters={filters}
                count={count}
                filterBy={filterBy}
                clearFilters={clear}
                layout={LAYOUT_FILTERS(productSlug)}
              />
              <PriceSpreadsTable
                priceSpreads={data}
                productSlug={productSlug}
                sort={sort}
                orderBy={orderBy}
                pagination={
                  <Pagination
                    limit={limit}
                    count={count}
                    page={page}
                    changePage={changePage}
                    changeRowsPerPage={changeRowsPerPage}
                  />
                }
              />
            </>
          )}
        </Page.Content>
      </Page.Body>
    </Page>
  );
};

export default ListViewEssentials({
  sdk: props => {
    const { productSlug } = props.match.params;
    return _.partial(priceSpreadV2List, productSlug);
  },
  passPropsToSdk: true
})(
  withLoggedUser(withPermissions([PERMISSIONS.VIEW_PRICES])(PriceSpreadV2List))
);
