import React from 'react';

import { withRouter } from 'react-router-dom';

import { Header, Segment } from 'semantic-ui-react';

import { Page, LoginRequired, Meta } from 'components';
import { notifyErrors } from 'utils/notifications';

import { FaqUpdateForm } from './components';
import { faqDetail, faqUpdateMeta } from './sdk';

class FaqDetail extends React.Component {
  state = {
    faq: null
  };

  fetchFaq = async () => {
    const { faqId } = this.props.match.params;

    const { data, errors, success } = await faqDetail(faqId);

    if (success) {
      this.setState({ faq: data });
    } else {
      notifyErrors(errors);
    }
  };

  componentDidMount() {
    this.fetchFaq();
  }

  render() {
    const { user, crumbs } = this.props;

    const { faq } = this.state;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Header as="h1">Edit FAQ</Header>

            {faq && (
              <Segment>
                <FaqUpdateForm faq={faq} fetchFaq={this.fetchFaq} />
                <Meta
                  object={faq}
                  fetchObject={this.fetchFaq}
                  sdk={faqUpdateMeta}
                />
              </Segment>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(withRouter(FaqDetail));
