import React from 'react';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';

import { Header, Table, Button } from 'semantic-ui-react';

import { Flex, Tooltip } from 'components';
import { isDraftStatus, isReadyStatus } from 'pages/Mailshot/Detail/utils';
import { notifySuccess, notifyErrors } from 'utils/notifications';

import { MailshotGroupItemForm } from './components';
import { mailshotGroupBulkUpdateMailshotStatus } from './sdk';

const MailshotGroupItemsTable = ({
  mailshotGroup,
  user,
  history,
  mailshotGroupItemsList,
  fetchMailshotGroupItems
}) => {
  const allMailshotsAreDraft = useMemo(
    () =>
      _.every(mailshotGroupItemsList, ({ mailshot }) =>
        isDraftStatus(mailshot)
      ),
    [mailshotGroupItemsList]
  );
  const allMailshotsAreReady = useMemo(
    () =>
      _.every(mailshotGroupItemsList, ({ mailshot }) =>
        isReadyStatus(mailshot)
      ),
    [mailshotGroupItemsList]
  );

  const bulkActionAllowed =
    !_.isEmpty(mailshotGroupItemsList) &&
    (allMailshotsAreDraft || allMailshotsAreReady);

  const bulkChangeStatus = useCallback(async () => {
    const status = allMailshotsAreDraft ? 'Ready' : 'Draft';

    const confirm = window.confirm(
      `Are you sure you want to change the status of all mailshots to "${status}"?`
    );

    if (!confirm) {
      return;
    }

    const updateData = { status };

    const {
      data,
      success,
      errors
    } = await mailshotGroupBulkUpdateMailshotStatus(
      mailshotGroup.id,
      updateData
    );

    if (success) {
      notifySuccess(`Marked ${data.update_count} mailshots as ${status}.`);
      fetchMailshotGroupItems();
    } else {
      notifyErrors(errors);
    }
  }, [mailshotGroup, allMailshotsAreDraft, fetchMailshotGroupItems]);

  return (
    <>
      <Flex spaceBetween style={{ width: '100%', paddingBottom: '1.5em' }}>
        <Header as="h2" style={{ margin: 0 }}>
          Mailshots
        </Header>
        {bulkActionAllowed && (
          <Tooltip
            on="hover"
            content="Mark all mailshots at once"
            trigger={
              <div>
                <Button color="red" onClick={bulkChangeStatus}>
                  Mark all as {allMailshotsAreDraft ? 'Ready' : 'Draft'}
                </Button>
              </div>
            }
          />
        )}
      </Flex>
      <Table compact celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center" width={6}>
              Subject &amp; Scheduled Date
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Account Manager/From Name
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Status</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Sending Status
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Clients</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Opened</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {mailshotGroupItemsList.map(mailshotGroupItem => (
            <MailshotGroupItemForm
              key={mailshotGroupItem.id}
              user={user}
              mailshotGroup={mailshotGroup}
              mailshotGroupItem={mailshotGroupItem}
              refetchGroupItems={fetchMailshotGroupItems}
              history={history}
            />
          ))}
          <MailshotGroupItemForm
            key="new-mailshot-group-item"
            user={user}
            mailshotGroup={mailshotGroup}
            refetchGroupItems={fetchMailshotGroupItems}
            history={history}
          />
        </Table.Body>
      </Table>
    </>
  );
};

export default MailshotGroupItemsTable;
