import _ from 'lodash';
import React, { useCallback } from 'react';

import { ListViewEssentials, LoginRequired } from 'components';

import { notifyError, notifySuccess } from 'utils/notifications';

import {
  clientSnapshotPriceSpreadsList,
  clientSnapshotToggleEmailNotificationsForPriceSpread,
  clientSnapshotTogglePriceSpread
} from '../../sdk';
import { ADD_ACTION, PRICE_SPREAD_SNAPSHOT_ENTITY } from '../../constants';

import BaseTable from '../BaseTable';
import PriceSpreadsFilters from '../PriceSpreadsFilters';

function PriceSpreadsTable({
  clientId,
  data,
  isFetching,
  refetch,
  filters,
  count,
  filterBy,
  addFilters,
  clear
}) {
  const togglePriceSpread = useCallback(
    async priceSpreadId => {
      const {
        data: { action },
        success
      } = await clientSnapshotTogglePriceSpread(clientId, priceSpreadId);

      if (success) {
        refetch();
        return notifySuccess(
          `Price spread ${action} ${
            action === ADD_ACTION ? 'to' : 'from'
          } client snapshot`
        );
      }

      notifyError("Couldn't update client snapshot");
    },
    [clientId, refetch]
  );

  const toggleEmailNotificationForPriceSpread = useCallback(
    async priceSpread => {
      const {
        success
      } = await clientSnapshotToggleEmailNotificationsForPriceSpread({
        clientId,
        priceSpreadId: priceSpread.id,
        enabled: !priceSpread.email_notifications_enabled
      });

      if (success) {
        refetch();
        return notifySuccess('Notification preferences updated');
      }

      notifyError("Couldn't update email preferences");
    },
    [clientId, refetch]
  );

  const isLoading = isFetching && _.isNil(data);

  return (
    <BaseTable
      entity={PRICE_SPREAD_SNAPSHOT_ENTITY}
      data={data}
      isLoading={isLoading}
      filtersComponent={
        <PriceSpreadsFilters
          filters={filters}
          count={count}
          filterBy={filterBy}
          addFilters={addFilters}
          clear={clear}
        />
      }
      toggleInSnpashot={togglePriceSpread}
      toggleEmailNotification={toggleEmailNotificationForPriceSpread}
    />
  );
}

export default ListViewEssentials({
  limit: null,
  passPropsToSdk: true,
  urlUpdatesEnabled: false,
  sdk: props => {
    const { clientId } = props;
    return _.partial(clientSnapshotPriceSpreadsList, clientId);
  }
})(LoginRequired(PriceSpreadsTable));
