import React from 'react';

import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import { Segment, Header } from 'semantic-ui-react';

import { Page, LoginRequired } from 'components';

import {
  PermissionsRequired,
  PERMISSIONS,
  DisableableElement,
  hasPermission
} from 'permissions';

import { clientReminderDetail } from './sdk';

import { ClientReminderEditForm } from './components';

class ClientReminderDetail extends React.Component {
  state = {
    clientReminderData: null
  };
  async componentDidMount() {
    this.fetchClientReminder();
  }

  fetchClientReminder = async () => {
    const { clientReminderId } = this.props.match.params;

    const { data } = await clientReminderDetail(clientReminderId);

    if (!_.isEmpty(data)) {
      this.setState({ clientReminderData: data });
    }
  };

  render() {
    const { user, crumbs } = this.props;
    const { clientReminderData } = this.state;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Header as="h1">Edit Client Reminder</Header>
              {!_.isNil(clientReminderData) && (
                <DisableableElement
                  disabled={!hasPermission(user, PERMISSIONS.CHANGE_CLIENTS)}>
                  <ClientReminderEditForm
                    data={clientReminderData}
                    fetchClientReminder={this.fetchClientReminder}
                  />
                </DisableableElement>
              )}
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default withRouter(
  LoginRequired(
    PermissionsRequired([PERMISSIONS.VIEW_CLIENTS])(ClientReminderDetail)
  )
);
