import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const processClients = async fileId => {
  const formData = new FormData();
  formData.append('excel', fileId);

  const url = `${BASE_URL}/crm/mass-import/process/`;

  return await requestSdk(() => post(url, formData));
};

export const importClients = async fileId => {
  const formData = new FormData();
  formData.append('excel', fileId);

  const url = `${BASE_URL}/crm/mass-import/upload/`;

  return await requestSdk(() => post(url, formData));
};
