import _ from 'lodash';
import * as yup from 'yup';

export const getInitialValues = course => ({
  title: course.title,
  slug: course.slug,
  titleWeb: course.title_web,
  duration: course.duration,
  durationType: course.duration_type,
  image: course.image,
  file: course.file,
  applicationFile: course.application_file,
  dateType: course.date_type,
  dateCustom: course.date_custom,
  taxName: course.tax_name,
  taxValue: course.tax_value,
  dateStart: course.date_start,
  dateActivation: course.date_activation,
  country: course.country,
  city: course.city,
  topic: course.topic,
  subject: course.subject,
  videoTitle: course.video_title,
  video: course.video,
  trainersTitle: course.trainers_title,
  trainers: course.trainers,
  banners: course.banners,
  isConference: course.is_conference,
  isFree: course.is_free,
  trainersTextOnTop: course.trainers_text_on_top,
  trainersTextOnBottom: course.trainers_text_on_bottom,
  agendaTextOnTop: course.agenda_text_on_top,
  agendaTextOnBottom: course.agenda_text_on_bottom,
  attendeesText: course.attendees_text,
  sponsorship: course.sponsorship,
  hotelBookingForm: course.hotel_booking_form,
  hotelBookingLink: course.hotel_booking_link,
  showCurrentAttendeeList: course.show_current_attendee_list,
  showPreviousAttendeeList: !_.isEmpty(course.previous_events),
  previousEvents: course.previous_events,
  beforeAttendeesText: course.before_attendees_text,
  afterAttendeesText: course.after_attendees_text,
  eventType: course.event_type,
  isRegistrationOpen: course.is_registration_open,
  registrationType: course.registration_type,
  customRegistrationUrl: course.custom_registration_url,
  guestRegistrationsEnabled: course.guest_registrations_enabled
});

export const courseFormSchema = yup.object().shape(
  {
    title: yup.string().required('Title is required.'),
    slug: yup.string().required('Slug is required.'),
    titleWeb: yup.string().required('Event name is required.'),
    duration: yup
      .number()
      .typeError('Please enter a valid number')
      .when('durationType', ([durationType], schema) => {
        return schema.required(`Number of ${durationType} is required.`);
      }),
    taxName: yup
      .string()
      .nullable()
      .when('taxValue', {
        is: taxValue => taxValue && taxValue !== 0,
        then: schema => schema.required('Tax name is required.')
      }),
    taxValue: yup
      .number()
      .min(1)
      .nullable()
      .when('taxName', {
        is: taxName => taxName && taxName.length !== 0,
        then: schema => schema.required('Tax value is required.').max(100)
      }),
    dateStart: yup.string().required('Date start is required.'),
    dateActivation: yup.string().required('Activation date is required.'),
    eventType: yup
      .string()
      .nullable()
      .required('Event Type is required.'),
    customRegistrationUrl: yup
      .string()
      .nullable()
      .when('registrationType', {
        is: 'Custom',
        then: schema => schema.required('Custom registration url is required.')
      })
  },
  [['taxName', 'taxValue']]
);
