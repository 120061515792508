import { Radio } from 'semantic-ui-react';

import { Filters, InputField } from 'components';

export const FILTERS = [
  [
    {
      name: 'search',
      emptyValue: '',
      component: InputField,
      label: 'General Search',
      placeholder: 'Search by title, display name or slug'
    },
    {
      name: 'id',
      emptyValue: '',
      component: InputField,
      label: 'Spread ID'
    }
  ]
];

export default function PriceSpreadsFilters({
  filters,
  count,
  addFilters,
  filterBy,
  clear
}) {
  return (
    <div style={{ width: '100%', zIndex: 2 }}>
      <Radio
        toggle
        label="Show price spreads in snapshot only"
        checked={filters?.in_snapshot_only || false}
        onChange={(e, { checked }) => addFilters({ in_snapshot_only: checked })}
      />
      <Filters
        filters={filters}
        count={count}
        filterBy={filterBy}
        clearFilters={clear}
        layout={FILTERS}
      />
    </div>
  );
}
