import React from 'react';

import {
  Page,
  Flex,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Button
} from 'components';

import { pageUrl } from 'config/routes';

import { MarketDataReportCreate } from 'pages';

import { marketDataReportList } from './sdk';

import { MarketDataReportTable } from './components';

class MarketDataReportList extends React.Component {
  createMarketDataAndReport = () => {
    const location = pageUrl(MarketDataReportCreate);

    this.props.history.push(location);
  };

  render() {
    const {
      user,
      data,
      limit,
      count,
      page,
      changePage,
      changeRowsPerPage,
      sort,
      orderBy
    } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content>
            <Flex spaceBetween>
              <Header>Market Data Report</Header>
              <Button size="large" onClick={this.createMarketDataAndReport}>
                Create Market Data and Report
              </Button>
            </Flex>
            {data && (
              <MarketDataReportTable
                reports={data}
                sort={sort}
                orderBy={orderBy}
                pagination={
                  <Pagination
                    limit={limit}
                    count={count}
                    page={page}
                    changePage={changePage}
                    changeRowsPerPage={changeRowsPerPage}
                  />
                }
              />
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: marketDataReportList
})(LoginRequired(MarketDataReportList));
