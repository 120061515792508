import React from 'react';

import { withRouter } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  OrderStatusSelect,
  FormRow,
  UpdateButtonInRow,
  Flex,
  UnsavedFormPrompt
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';

import { orderUpdate } from './sdk';
import { notifySuccess } from 'utils/notifications';

class OrderUpdateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { order, fetchOrder } = this.props;

    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await orderUpdate(order.id, data);

    setSubmitting(false);

    if (success) {
      notifySuccess('Order updated.');
      fetchOrder();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const { order } = this.props;

    const initialValues = {
      status: order.status
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={this.handleSubmit}>
        {({ handleSubmit, isSubmitting, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty} />

            <FormRow>
              <OrderStatusSelect width={6} name="status" label="status" />
              <Form.Field width={10}>
                <Flex flexEnd>
                  <UpdateButtonInRow
                    subject="Order"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  />
                </Flex>
              </Form.Field>
            </FormRow>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(OrderUpdateForm);
