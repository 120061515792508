import _ from 'lodash';
import { formatDateTime, getItemsIds } from 'utils';

export const getNonFormItems = values => [
  {
    label: 'Ready For Publication Date',
    value: formatDateTime(values.readyForPublicationDate)
  },
  {
    label: 'Created by',
    value: _.get(values.createdBy, 'email', 'N/A'),
    redirectToClient: values.createdBy
  },
  {
    label: 'Created at',
    value: formatDateTime(values.createdAt)
  },
  {
    label: 'Updated by',
    value: _.get(values.updatedBy, 'email', 'N/A'),
    redirectToClient: values.updatedBy
  },
  {
    label: 'Updated at',
    value: formatDateTime(values.updatedAt)
  },
  {
    label: 'Absolute URL:',
    value: values.absoluteUrl
  }
];

export const getInitialValues = article => ({
  fullHeadline: article.full_headline,
  marketingHeadline: article.marketing_headline,
  slug: article.slug,
  text: article.text,
  summary: article.summary,
  tableIcon: article.table_icon,
  graphIcon: article.graph_icon,
  imageIcon: article.image_icon,
  notRfp: article.not_rfp,
  embargoed: article.embargoed,
  push: article.push,
  free: article.free,
  featured: article.featured,
  relatedCompanies: getItemsIds(article.related_companies),
  regions: article.regions,
  countries: article.countries,
  authors: article.authors,
  priceCategories: article.price_categories,
  priceProducts: article.price_products,
  articleTopics: article.article_topics,
  status: article.status,
  type: article.type,
  section: article.section_id,
  tags: _.map(article.tags, tag => tag.tag),
  image: _.get(article, 'image', null),
  group: article.group_id,
  publishDate: article.publish_date,
  byKallanishTeam: article.by_kallanish_team,
  byKallanishAsia: article.by_kallanish_asia
});
