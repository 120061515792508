import React from 'react';

import { Table } from 'semantic-ui-react';

import { Formik } from 'formik';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess, notifyErrors } from 'utils/notifications';

import { Button, InputField, Field } from 'components';

import { pollChoiceUpdate, pollChoiceRemove } from './sdk';

import { choiceSchema } from './utils';

class ChoiceForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const { choice, poll, fetchPoll } = this.props;
    const data = {
      ...prepareFormData(values),
      choice: choice.id
    };

    setSubmitting(true);

    const { success, errors } = await pollChoiceUpdate(poll.id, data);

    setSubmitting(false);

    if (success) {
      notifySuccess('Choice updated.');
      fetchPoll();
      return;
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  deleteChoice = async () => {
    const { choice, poll, fetchPoll } = this.props;

    const data = {
      choice: choice.id
    };

    const { success, errors } = await pollChoiceRemove(poll.id, data);
    if (success) {
      notifySuccess('Choice removed.');
      fetchPoll();
      return;
    } else {
      notifyErrors(errors);
    }
  };
  render() {
    const { choice } = this.props;

    const initialValues = {
      choiceContent: choice.choice
    };

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        validationSchema={choiceSchema}>
        {({ isSubmitting, handleSubmit }) => (
          <Table.Row>
            <Table.Cell>
              <Field name="choiceContent" component={InputField} />
            </Table.Cell>
            <Table.Cell width={6} textAlign="center">
              <Button
                color="blue"
                disabled={isSubmitting}
                onClick={handleSubmit}>
                Update
              </Button>
              <Button color="red" onClick={this.deleteChoice}>
                Delete
              </Button>
            </Table.Cell>
          </Table.Row>
        )}
      </Formik>
    );
  }
}

export default ChoiceForm;
