import React from 'react';

import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { TableFooter, SortableHeader, YesNo, Admin } from 'components';

import { pageUrl } from 'config/routes';

import { VideoDetail } from 'pages';

import { formatDateTime } from 'utils';

const VideoGalleryTable = ({ data, sort, orderBy, pagination }) => (
  <Table celled selectable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell singleLine style={{ fontWeight: '400' }} colspan="6">
          {pagination}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        <SortableHeader sort={sort} orderBy={orderBy} field="title">
          Title
        </SortableHeader>
        <Table.HeaderCell field="is_published">Is published</Table.HeaderCell>
        <SortableHeader sort={sort} orderBy={orderBy} field="created_by">
          Created by
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="created_at">
          Created at
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="updated_by">
          Updated by
        </SortableHeader>
        <SortableHeader sort={sort} orderBy={orderBy} field="updated_at">
          Updated at
        </SortableHeader>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {data.map(video => (
        <Table.Row key={video.id}>
          <Table.Cell>
            <Link
              to={pageUrl(VideoDetail, {
                videoId: video.id
              })}>
              {video.title}
            </Link>
          </Table.Cell>
          <Table.Cell>
            <YesNo value={video.is_published} />
          </Table.Cell>
          <Table.Cell>
            <Admin value={video.created_by} />
          </Table.Cell>
          <Table.Cell>{formatDateTime(video.created_at)}</Table.Cell>
          <Table.Cell>
            <Admin value={video.updated_by} />
          </Table.Cell>
          <Table.Cell>{formatDateTime(video.updated_at)}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
    <TableFooter>{pagination}</TableFooter>
  </Table>
);

export default VideoGalleryTable;
