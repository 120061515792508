import React from 'react';

import { withRouter } from 'react-router-dom';

import {
  Page,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Filters as SharedFilters
} from 'components';

import { feedbackList } from './sdk';

import { FeedbackTable } from './components';

import { FEEDBACK_FILTERS } from './utils';

function FeedbackList(props) {
  const {
    user,
    data,
    limit,
    count,
    page,
    changePage,
    changeRowsPerPage,
    orderBy,
    sort,
    filters,
    filterBy,
    refetch,
    clear
  } = props;

  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content maxWidth>
          <Header>Feedback</Header>

          <SharedFilters
            filters={filters}
            count={count}
            filterBy={filterBy}
            clearFilters={clear}
            layout={FEEDBACK_FILTERS}
          />

          {data && (
            <>
              <FeedbackTable
                orderBy={orderBy}
                sort={sort}
                feedback={data}
                refetch={refetch}
                pagination={
                  <Pagination
                    limit={limit}
                    count={count}
                    page={page}
                    changePage={changePage}
                    changeRowsPerPage={changeRowsPerPage}
                  />
                }
              />
            </>
          )}
        </Page.Content>
      </Page.Body>
    </Page>
  );
}

export default ListViewEssentials({
  sdk: feedbackList
})(LoginRequired(withRouter(FeedbackList)));
