import React, { useCallback } from 'react';
import _ from 'lodash';

import { Formik } from 'formik';
import { Form } from 'semantic-ui-react';
import { useHistory, useParams } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { PriceSpreadV2List } from 'pages';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';
import { populateSlug } from 'utils';

import { CreateButtonInRow } from 'components';

import { PriceSpreadV2Form } from 'pages/PriceSpreadV2/shared/components';

import { priceSpreadV2Create } from './sdk';

import { DEFAULT_INITIAL_VALUES, validationSchema } from './utils';

const PriceSpreadV2CreateForm = () => {
  const { productSlug } = useParams();
  const history = useHistory();

  const handleSubmit = useCallback(
    (values, actions) => {
      const { setSubmitting, setFieldError } = actions;

      const data = prepareFormData(values);

      setSubmitting(true);

      priceSpreadV2Create(productSlug, data).then(({ errors, success }) => {
        if (success) {
          notifySuccess('Price spread created.', {
            onOpen: history.push(pageUrl(PriceSpreadV2List, { productSlug }))
          });
          setSubmitting(false);

          return;
        }

        handleFormErrors(errors, setFieldError);
        setSubmitting(false);
      });
    },
    [history, productSlug]
  );

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={DEFAULT_INITIAL_VALUES}
      validationSchema={validationSchema}>
      {({ isSubmitting, handleSubmit, setFieldValue, dirty, errors }) => (
        <Form>
          <PriceSpreadV2Form
            FormikProps={{ setFieldValue, dirty, isSubmitting }}
            productSlug={productSlug}
            onTitleChange={(e, data) => {
              populateSlug(data, setFieldValue);
            }}
          />
          <CreateButtonInRow
            onClick={handleSubmit}
            disabled={isSubmitting || !_.isEmpty(errors)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default React.memo(PriceSpreadV2CreateForm);
