import React from 'react';
import { arrayMoveImmutable } from 'array-move';

import { Modal, Icon, Header } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  SortableList,
  UpdateButtonInRow,
  Flex,
  PMPriceSeriesMainGroupSelect,
  FormRow
} from 'components';

import { notifyErrors, notifySuccess } from 'utils/notifications';

import { orderPricesForCategory, priceSeriesByCategoryList } from './sdk';

class PriceSeriesCategoryOrderModal extends React.Component {
  state = {
    options: []
  };

  fetchPrices = async categoryId => {
    const { data, errors, success } = await priceSeriesByCategoryList(
      categoryId
    );

    if (success) {
      this.setState({
        options: data.map(price => ({
          id: price.id,
          title: price.display_as
        }))
      });
    } else {
      notifyErrors(errors);
    }
  };

  close = () => {
    this.props.onClose();
  };

  confirmOrder = async () => {
    const data = { prices: this.state.options.map(price => price.id) };

    const { success } = await orderPricesForCategory(data);

    if (success) {
      notifySuccess('Prices are reordered successfully.');
      this.close();
    }
  };

  onSortEnd = async ({ item, oldIndex, newIndex }) => {
    this.setState(prevState => ({
      options: arrayMoveImmutable(prevState.options, oldIndex, newIndex)
    }));
  };

  render() {
    const { options } = this.state;

    return (
      <Modal open={true} size="large" centered={false}>
        <Modal.Header>
          <Flex spaceBetween>
            <Header as="h2">Order Prices By Category</Header>
            <Icon name="close" onClick={this.close} />
          </Flex>
        </Modal.Header>
        <Modal.Content>
          <Formik>
            {({ values }) => (
              <FormRow>
                <PMPriceSeriesMainGroupSelect
                  name="main_group"
                  placeholder="Select Category"
                  value={values.category}
                  onChange={event => this.fetchPrices(event.target.value)}
                />
              </FormRow>
            )}
          </Formik>
          {options.length ? (
            <div style={{ marginTop: 20 }}>
              <SortableList items={options} onSortEnd={this.onSortEnd} />
            </div>
          ) : (
            <Header as="h3">A category must be selected</Header>
          )}
          <Flex flexEnd>
            <UpdateButtonInRow
              onClick={this.confirmOrder}
              subject="Prices Order"
            />
          </Flex>
        </Modal.Content>
      </Modal>
    );
  }
}

export default PriceSeriesCategoryOrderModal;
