import React from 'react';

import _ from 'lodash';

import { Header, Segment } from 'semantic-ui-react';

import { withRouter } from 'react-router-dom';

import { Page, LoginRequired } from 'components';

import { tradeExcelDetail } from './sdk';

import { TradeExcelUpdateForm } from './components';

class TradeExcelDetail extends React.Component {
  state = {
    errors: null,
    tradeExcel: null
  };

  fetchTradeExcel = async () => {
    const { tradeExcelId } = this.props.match.params;

    const { data, errors, success } = await tradeExcelDetail(tradeExcelId);

    if (success) {
      this.setState({ tradeExcel: data });
    } else {
      this.setState({ errors });
    }
  };

  componentDidMount() {
    this.fetchTradeExcel();
  }

  render() {
    const { user, crumbs } = this.props;

    const { tradeExcel } = this.state;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Header as="h1">Trade Excel Detail</Header>
              {!_.isNil(tradeExcel) && (
                <TradeExcelUpdateForm
                  data={tradeExcel}
                  fetchTradeExcel={this.fetchTradeExcel}
                />
              )}
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(withRouter(TradeExcelDetail));
