import React from 'react';

import _ from 'lodash';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { Form, Divider } from 'semantic-ui-react';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import {
  Field,
  ErrorsAndUpdateButtonInRow,
  FormRow,
  DateField,
  InputField,
  AdminSelect,
  RegionSelect,
  CheckboxField,
  RichTextAreaField,
  PriceSeriesUnitSelect,
  PriceSeriesScaleSelect,
  PriceSeriesFormatSelect,
  PriceSeriesRegionSelect,
  PriceSeriesProductSelect,
  PriceSeriesCurrencySelect,
  PriceSeriesCategorySelect,
  PriceSeriesIncoTermSelect,
  PriceSeriesFrequencySelect,
  PriceSeriesDataSourceSelect,
  UnsavedFormPrompt,
  CreatedUpdatedFormValues
} from 'components';

import { prepareValues, validationSchema, getInitialValues } from './utils';
import { priceSeriesUpdate } from './sdk';

class PriceSeriesUpdateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const data = _.pickBy(values, _.negate(_.isNil));

    const { fetchPriceSeries } = this.props;
    const { priceSeriesId } = this.props.match.params;

    const { setSubmitting, setFieldError } = actions;

    const { errors, success } = await priceSeriesUpdate(
      priceSeriesId,
      prepareFormData(data)
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Price has been updated successfully.');
      fetchPriceSeries();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  render() {
    const data = prepareValues(this.props.data);

    const initialValues = getInitialValues(data);
    const {
      created_at: createdAt,
      created_by: createdBy,
      updated_at: updatedAt,
      updated_by: updatedBy
    } = data;

    const nonFormValues = {
      createdAt,
      createdBy,
      updatedAt,
      updatedBy
    };

    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}>
        {({ values, isSubmitting, handleSubmit, dirty, touched, errors }) => (
          <Form>
            <UnsavedFormPrompt when={dirty} />
            <FormRow>
              <Field
                width={2}
                name="showOnHomepage"
                label="Show on home page"
                component={CheckboxField}
              />

              <Field
                width={2}
                name="showInRightColumn"
                label="Show in right column"
                component={CheckboxField}
              />
            </FormRow>
            <FormRow>
              <Field
                required
                width={6}
                name="title"
                label="Title:"
                component={InputField}
              />

              <Field
                required
                width={4}
                name="shortTitle"
                label="Short title:"
                component={InputField}
              />
            </FormRow>
            <FormRow>
              <Field
                width={6}
                name="slug"
                label="Slug:"
                component={InputField}
              />
              <Field
                width={4}
                name="displayAs"
                label="Display as:"
                component={InputField}
              />
            </FormRow>
            <FormRow>
              <PriceSeriesCategorySelect
                required
                width={5}
                name="category"
                label="Category:"
              />

              <PriceSeriesProductSelect
                required
                width={5}
                name="product"
                label="Product:"
              />
            </FormRow>
            <FormRow>
              <PriceSeriesRegionSelect
                required
                width={5}
                name="region"
                label="Region:"
              />

              <PriceSeriesRegionSelect
                width={5}
                name="region2"
                label="Region 2:"
              />
            </FormRow>
            <FormRow widths="equal">
              <PriceSeriesIncoTermSelect
                required
                name="incoTerms"
                label="INCO terms:"
              />

              <PriceSeriesFormatSelect required name="format" label="Format:" />

              <PriceSeriesUnitSelect required name="unit" label="Unit:" />

              <PriceSeriesFrequencySelect
                required
                name="frequency"
                label="Frequency:"
              />
            </FormRow>
            <FormRow>
              <PriceSeriesCurrencySelect
                required
                width={2}
                name="currency"
                label="Currency:"
              />

              <PriceSeriesScaleSelect
                required
                width={2}
                name="scale"
                label="Scale:"
              />
              <Field
                width={3}
                name="decimalPlaces"
                label="Decimal places:"
                component={InputField}
                type="number"
              />

              <Field
                required
                width={3}
                name="date"
                label="Start date:"
                component={DateField}
              />
            </FormRow>
            <FormRow>
              <PriceSeriesDataSourceSelect
                required
                width={4}
                name="priceDataSource"
                label="Price Data Source"
              />
              {values['priceDataSource'] &&
                values['priceDataSource'] !== 'Manual' && (
                  <Field
                    width={4}
                    name="priceDataSymbols"
                    label="Price Data External Symbols:"
                    component={InputField}
                    required
                  />
                )}
            </FormRow>
            <AdminSelect
              width={16}
              name="access"
              isMulti={true}
              label="Access:"
            />

            <RegionSelect
              isMulti={true}
              name="articleRegions"
              label="Region for articles:"
            />
            <PriceSeriesCategorySelect
              isMulti={true}
              name="articlePrices"
              label="Prices for articles:"
            />

            <PriceSeriesProductSelect
              isMulti={true}
              name="articleProducts"
              label="Products for articles:"
            />
            <AdminSelect
              isMulti={true}
              name="articleAuthors"
              label="Authors for articles:"
            />
            <Field name="text" component={RichTextAreaField} label="Text: " />
            <Divider />
            <FormRow widths="equal">
              <Field
                name="metaTitle"
                label="Meta Title"
                component={InputField}
              />

              <Field
                name="metaKeywords"
                label="Meta Keywords"
                component={InputField}
              />

              <Field
                name="metaDescription"
                label="Meta Description"
                component={InputField}
              />

              <Field
                name="metaStandout"
                label="Meta Standout"
                component={InputField}
              />
            </FormRow>
            <CreatedUpdatedFormValues values={nonFormValues} />
            <ErrorsAndUpdateButtonInRow
              subject="Price Serie"
              onClick={handleSubmit}
              disabled={isSubmitting}
              errors={errors}
              touched={touched}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(PriceSeriesUpdateForm);
