import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const invoicesList = async params => {
  const url = `${BASE_URL}/crm/invoices/`;

  return await requestSdk(() => get(url, params));
};

export const invoiceCreate = async data => {
  const url = `${BASE_URL}/crm/invoices/create/`;

  return await requestSdk(() => post(url, data));
};
