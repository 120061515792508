import React from 'react';

import { withRouter } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  InputField,
  FormRow,
  UpdateButton,
  UnsavedFormPrompt,
  Flex
} from 'components';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import NonFormValuesDisplay from './NonFormValuesDisplay';
import { faqCategoryUpdateSchema } from './utils';
import { faqCategoryUpdate } from './sdk';

class FaqCategoryUpdateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { faqCategory } = this.props;

    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await faqCategoryUpdate(faqCategory.id, data);

    setSubmitting(false);

    if (success) {
      notifySuccess('FAQ category updated.');
      this.props.fetchFaqCategory();
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  render() {
    const { faqCategory } = this.props;

    const initialValues = {
      title: faqCategory.title
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={faqCategoryUpdateSchema}
        onSubmit={this.handleSubmit}>
        {({ values, handleSubmit, isSubmitting, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty} />

            <FormRow style={{ alignItems: 'flex-end' }}>
              <Field
                width={6}
                name="title"
                label="Title"
                required
                component={InputField}
              />
              <Flex style={{ alignItems: 'flex-end' }}>
                <UpdateButton onClick={handleSubmit} disabled={isSubmitting} />
              </Flex>
            </FormRow>
            <NonFormValuesDisplay faqCategory={faqCategory} />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(FaqCategoryUpdateForm);
