import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Table, Icon } from 'semantic-ui-react';
import { prepareInitialValues } from 'utils/forms';

import {
  TableFooter,
  SortableHeader,
  CreateButton,
  DeleteModal
} from 'components';
import RecipientGroupForm from './form';
import { deleteRecipientGroup } from './form/sdk';

function RecipientGroupsTable({ data, sort, orderBy, pagination, refetch }) {
  const { productSlug } = useParams();

  const formRef = useRef(null);

  const blankSelectedGroup = () => {
    return {
      subscription: '',
      subscriptionStatus: '',
      articleGrouping: 'Group',
      newsletterSubject: '',
      productType: productSlug
    };
  };

  const [selectedGroup, setSelectedGroup] = useState(blankSelectedGroup());
  const [groupToDelete, setGroupToDelete] = useState(null);
  const [addGroup, setAddGroup] = useState(false);

  const clearSelection = () => {
    setSelectedGroup(blankSelectedGroup());
    setAddGroup(false);
  };

  const handleDelete = () => {
    clearSelection();
    setGroupToDelete(null);
    refetch();
  };

  useEffect(() => {});
  return (
    <Table celled selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            singleLine
            style={{ fontWeight: '400' }}
            colspan="5">
            {pagination}
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <SortableHeader sort={sort} orderBy={orderBy} field="subscription">
            Subscription
          </SortableHeader>
          <SortableHeader
            sort={sort}
            orderBy={orderBy}
            field="subscription_status">
            Subscription status
          </SortableHeader>
          <SortableHeader
            sort={sort}
            orderBy={orderBy}
            field="article_grouping">
            Article grouping
          </SortableHeader>
          <SortableHeader
            sort={sort}
            orderBy={orderBy}
            field="newsletter_subject">
            Newsletter subject
          </SortableHeader>
          <Table.HeaderCell>
            <CreateButton
              size="tiny"
              primary
              floated="right"
              onClick={() => {
                clearSelection();
                setAddGroup(true);
              }}>
              Add group
            </CreateButton>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {addGroup && (
          <Table.Row key="add-recipient-group-row">
            <Table.Cell colSpan={4}>
              <RecipientGroupForm
                formRef={formRef}
                instance={selectedGroup}
                onSave={refetch}
                clearSelection={clearSelection}></RecipientGroupForm>
            </Table.Cell>
            <Table.Cell>
              <CreateButton
                size="tiny"
                floated="right"
                icon
                inverted
                type="submit"
                onClick={ev => {
                  formRef.current.handleSubmit();
                }}>
                <Icon name="save" />
              </CreateButton>
              <CreateButton
                size="tiny"
                icon
                color="red"
                inverted
                floated="right"
                onClick={() => clearSelection()}>
                <Icon name="undo" />
              </CreateButton>
            </Table.Cell>
          </Table.Row>
        )}
        {data.map(group =>
          selectedGroup.id !== group.id ? (
            <Table.Row key={group.id}>
              <Table.Cell>{group.subscription_title}</Table.Cell>
              <Table.Cell>{group.subscription_status_title}</Table.Cell>
              <Table.Cell>{group.article_grouping}</Table.Cell>
              <Table.Cell>{group.newsletter_subject}</Table.Cell>
              <Table.Cell width={2}>
                <CreateButton
                  size="tiny"
                  floated="right"
                  icon
                  onClick={() => {
                    setAddGroup(false);
                    setSelectedGroup(prepareInitialValues(group));
                  }}>
                  <Icon name="edit" />
                </CreateButton>
                <DeleteModal
                  key={`delete-modal-${group.id}`}
                  displayButton={false}
                  itemId={group.id}
                  open={groupToDelete === group.id}
                  objName="Recipient group"
                  deleteButtonText="Delete recipient group"
                  objDetails={`${group.title} with ID: ${group.id}`}
                  sdk={deleteRecipientGroup}
                  onClose={() => handleDelete()}
                />
                <CreateButton
                  size="tiny"
                  icon
                  color="red"
                  inverted
                  floated="right"
                  onClick={() => {
                    setGroupToDelete(group.id);
                  }}>
                  <Icon name="trash alternate" />
                </CreateButton>
              </Table.Cell>
            </Table.Row>
          ) : (
            <Table.Row key={group.id}>
              <Table.Cell colSpan={4}>
                <RecipientGroupForm
                  formRef={formRef}
                  instance={selectedGroup}
                  onSave={refetch}
                  clearSelection={clearSelection}></RecipientGroupForm>
              </Table.Cell>
              <Table.Cell>
                <CreateButton
                  size="tiny"
                  floated="right"
                  icon
                  inverted
                  type="submit"
                  onClick={ev => {
                    formRef.current.handleSubmit();
                  }}>
                  <Icon name="save" />
                </CreateButton>
                <CreateButton
                  size="tiny"
                  icon
                  color="red"
                  inverted
                  floated="right"
                  onClick={() => clearSelection()}>
                  <Icon name="undo" />
                </CreateButton>
              </Table.Cell>
            </Table.Row>
          )
        )}
      </Table.Body>
      <TableFooter>{pagination}</TableFooter>
    </Table>
  );
}
export default RecipientGroupsTable;
