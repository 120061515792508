import React from 'react';

import { withRouter } from 'react-router-dom';

import { Header, Segment } from 'semantic-ui-react';

import { Page, LoginRequired } from 'components';
import { notifyErrors } from 'utils/notifications';

import { productDetail } from './sdk';

import { ProductUpdateForm } from './components';

class ProductDetail extends React.Component {
  state = {
    product: null
  };

  fetchProduct = async () => {
    const { productId } = this.props.match.params;

    const { data, errors, success } = await productDetail(productId);

    if (success) {
      this.setState({ product: data });
    } else {
      notifyErrors(errors);
    }
  };

  componentDidMount() {
    this.fetchProduct();
  }

  render() {
    const { user, crumbs } = this.props;

    const { product } = this.state;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Header as="h1">Edit Product</Header>

            {product && (
              <Segment>
                <ProductUpdateForm
                  product={product}
                  fetchProduct={this.fetchProduct}
                />
              </Segment>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(withRouter(ProductDetail));
