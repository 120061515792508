import React from 'react';

import { Field, SelectField } from 'components';
const REGISTRATION_TYPES = [
  'Real FE',
  'Real BE',
  'System',
  'E-mail',
  'Mobile app',
  'Social app'
];

class RegistrationTypeSelect extends React.Component {
  render() {
    const { name, ...rest } = this.props;

    const options = REGISTRATION_TYPES.map(registrationType => {
      return {
        key: registrationType,
        value: registrationType,
        label: registrationType
      };
    });

    return (
      <Field
        name={name || 'registrationType'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default RegistrationTypeSelect;
