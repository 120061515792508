import React from 'react';

import { withRouter } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  InputField,
  FormRow,
  CreateButtonInRow,
  UnsavedFormPrompt,
  UniSelect
} from 'components';
import { notifySuccess } from 'utils/notifications';
import { handleFormErrors, prepareFormData } from 'utils/forms';

import { pageUrl } from 'config/routes';

import { ProductList } from 'pages';

import { productCreateSchema } from './utils';
import { productCreate } from './sdk';

class ProductCreateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await productCreate(data);

    if (success) {
      notifySuccess('Product created successfully.');
      this.props.history.push(pageUrl(ProductList));
    } else {
      handleFormErrors(errors, setFieldError);
    }
    setSubmitting(false);
  };

  render() {
    const initialValues = {
      title: '',
      shortTitle: '',
      abbreviation: ''
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={productCreateSchema}
        onSubmit={this.handleSubmit}>
        {({ values, handleSubmit, isSubmitting, setFieldValue, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty && !isSubmitting} />

            <CreateButtonInRow onClick={handleSubmit} disabled={isSubmitting} />
            <FormRow>
              <Field
                width={4}
                name="title"
                label="Title"
                required
                component={InputField}
              />
              <Field
                width={4}
                name="shortTitle"
                label="Short title"
                required
                component={InputField}
              />
              <Field
                width={4}
                name="abbreviation"
                label="Abbreviation"
                required
                component={InputField}
              />
              <UniSelect
                width={4}
                name="priceProductGroup"
                label="Product Group"
                source="priceSerieProductGroups"
              />
            </FormRow>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(ProductCreateForm);
