import React from 'react';

import { withRouter } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  InputField,
  FormRow,
  CreateButtonInRow,
  RegionSelect,
  UnsavedFormPrompt
} from 'components';
import { notifySuccess } from 'utils/notifications';
import { handleFormErrors, prepareFormData } from 'utils/forms';

import { pageUrl } from 'config/routes';

import { CountryList } from 'pages';

import { countryCreateSchema } from './utils';
import { countryCreate } from './sdk';

class CountryCreateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await countryCreate(data);

    if (success) {
      notifySuccess('Country created.');
      this.props.history.push(pageUrl(CountryList));
    } else {
      handleFormErrors(errors, setFieldError);
    }
    setSubmitting(false);
  };

  render() {
    const initialValues = {
      title: '',
      region: null
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={countryCreateSchema}
        onSubmit={this.handleSubmit}>
        {({ values, handleSubmit, isSubmitting, setFieldValue, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty && !isSubmitting} />

            <CreateButtonInRow onClick={handleSubmit} disabled={isSubmitting} />
            <FormRow>
              <Field
                width={4}
                name="title"
                label="Title"
                required
                component={InputField}
              />
              <RegionSelect width={4} name="region" label="Region" />
            </FormRow>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(CountryCreateForm);
