import _ from 'lodash';

const isCurrencyKey = value => value.startsWith('currency_');
const extractCurrencyId = value => value.split('currency_')[1];
export const buildCurrencyKey = currency => `currency_${currency.id}`;

export const prepareCurrencies = values => {
  const result = [];

  const currencies = _.mapKeys(
    _.pickBy(values, (value, key) => isCurrencyKey(key)),
    (value, key) => extractCurrencyId(key)
  );

  _.forEach(currencies, (value, key) => {
    const currency = {
      currency: key,
      value: value
    };

    result.push(currency);
  });

  return result;
};
