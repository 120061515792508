import React from 'react';

import { Table } from 'semantic-ui-react';

import { Formik } from 'formik';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { Button, InputField, Field } from 'components';

import { pollChoiceCreate } from './sdk';
import { choiceSchema } from './utils';

class CreateChoiceForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError, resetForm } = actions;

    const { poll, fetchPoll } = this.props;
    const data = {
      ...prepareFormData(values)
    };

    setSubmitting(true);

    const { success, errors } = await pollChoiceCreate(poll.id, data);

    setSubmitting(false);

    if (success) {
      notifySuccess('Choice created.');
      fetchPoll();
      resetForm();
      return;
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  render() {
    const initialValues = {
      choice: ''
    };

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        validationSchema={choiceSchema}>
        {({ isSubmitting, handleSubmit }) => (
          <Table.Row>
            <Table.Cell>
              <Field name="choice" component={InputField} />
            </Table.Cell>
            <Table.Cell width={6} textAlign="center">
              <Button
                color="green"
                disabled={isSubmitting}
                onClick={handleSubmit}>
                Create
              </Button>
            </Table.Cell>
          </Table.Row>
        )}
      </Formik>
    );
  }
}

export default CreateChoiceForm;
