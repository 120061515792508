import React, { useState } from 'react';

import { OpenModalButtonControlled } from 'components';

const OpenModalButton = ({ children, ...rest }) => {
  const [isModalOpen, toggleModal] = useState(false);

  return (
    <OpenModalButtonControlled
      isModalOpen={isModalOpen}
      toggleModal={toggleModal}
      {...rest}>
      {children}
    </OpenModalButtonControlled>
  );
};

export default OpenModalButton;
