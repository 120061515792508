import { transformValuesForMultiselect } from 'utils/filters';
import { coerceStringToBoolean } from 'utils';
import {
  InputField,
  AdminSelect,
  SubscriptionSelect,
  SubscriptionStatusSelect,
  ClientProfileTypeSelect,
  DateField,
  YesNoSelect,
  PrioritySelect,
  UnsubscribeMethodSelect
} from 'components';

export const FILTERS = [
  [
    {
      name: 'search',
      emptyValue: '',
      component: InputField,
      label: 'General Search',
      placeholder: 'Search...',
      width: 6
    },
    {
      name: 'has_admin_updates',
      emptyValue: null,
      transform: coerceStringToBoolean,
      component: YesNoSelect,
      label: 'Has admin updates',
      width: 3
    }
  ],
  [
    {
      name: 'email',
      emptyValue: '',
      component: InputField,
      label: 'Email address',
      width: 4
    },
    {
      name: 'first_name',
      emptyValue: '',
      component: InputField,
      label: 'First name',
      width: 3
    },
    {
      name: 'last_name',
      emptyValue: '',
      component: InputField,
      label: 'Last name',
      width: 3
    },
    {
      name: 'job_title',
      emptyValue: '',
      component: InputField,
      label: 'Job Title',
      width: 4
    },
    {
      name: 'company_name',
      emptyValue: '',
      component: InputField,
      label: 'Company name',
      width: 4
    }
  ],
  [
    {
      name: 'is_active',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'User Status',
      props: () => ({
        yesLabel: 'Active',
        noLabel: 'Inactive'
      }),
      width: 2
    },
    {
      name: 'cm',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: ClientProfileTypeSelect,
      label: 'CM filter',
      width: 3
    },
    {
      name: 'account_manager',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: AdminSelect,
      label: 'Account Manager',
      width: 4
    },
    {
      name: 'priority',
      emptyValue: null,
      fieldComponent: PrioritySelect,
      label: 'Priority',
      props: () => ({
        isMulti: true
      }),
      width: 3
    }
  ],
  [
    {
      name: 'subscription',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: SubscriptionSelect,
      label: 'Subscription',
      props: () => ({
        isMulti: true
      }),
      width: 5
    },
    {
      name: 'subscription_status',
      emptyValue: [],
      transform: transformValuesForMultiselect,
      fieldComponent: SubscriptionStatusSelect,
      label: 'Subscription status',
      props: () => ({
        isMulti: true
      }),
      width: 5
    },
    {
      name: 'unsubscribed_at',
      emptyValue: null,
      component: DateField,
      label: 'Unsubscribed At',
      width: 3
    },
    {
      name: 'unsubscribed_method',
      emptyValue: null,
      fieldComponent: UnsubscribeMethodSelect,
      label: 'Unsubscribed Method',
      width: 3
    }
  ],
  [
    {
      name: 'subscription_starts_from',
      emptyValue: null,
      component: DateField,
      label: 'Subscription starts from',
      width: 3
    },
    {
      name: 'subscription_starts_to',
      emptyValue: null,
      component: DateField,
      label: 'Subscription starts to',
      width: 3
    },
    {
      name: 'subscription_expires_from',
      emptyValue: null,
      component: DateField,
      label: 'Subscription expires from',
      width: 3
    },
    {
      name: 'subscription_expires_to',
      emptyValue: null,
      component: DateField,
      label: 'Subscription expires to',
      width: 3
    }
  ]
];
