import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const weeklyPowerMaterialsDetail = async id => {
  const url = `${BASE_URL}/newsletters/weekly-power-materials/${id}/`;
  return await requestSdk(() => get(url));
};

export const weeklyPowerMaterialsUpdate = async (id, data) => {
  const url = `${BASE_URL}/newsletters/weekly-power-materials/update/${id}/`;
  return await requestSdk(() => post(url, data));
};
