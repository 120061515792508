import React from 'react';

import _ from 'lodash';

import {
  Segment,
  Header,
  Table,
  Dimmer,
  Loader,
  Button
} from 'semantic-ui-react';

import { Flex } from 'components';

import { hasPermission, PERMISSIONS } from 'permissions';

import { notifySuccess, notifyErrors } from 'utils/notifications';

import { priceSeriesDataCreate, priceSeriesDataCommentCreate } from './sdk';

import { PriceSeriesData } from './components';

import { formatHeaderDate } from './utils';

class PriceSeriesFrequencyTable extends React.Component {
  onPriceSave = async (priceSeries, value1, value2, cb) => {
    const { fetchForFrequency } = this.props;

    const postData = {
      value1,
      value2,
      date: priceSeries.date
    };

    const { success, errors } = await priceSeriesDataCreate(
      priceSeries.id,
      postData
    );

    if (success) {
      notifySuccess('Price updated successfully.');
      cb();
      fetchForFrequency();
    }

    if (!success) {
      notifyErrors(errors);
    }
  };

  onPriceComment = async (priceSeries, comment, cb) => {
    const { fetchForFrequency } = this.props;

    const postData = {
      date: priceSeries.date,
      comment: comment
    };

    const { success, errors } = await priceSeriesDataCommentCreate(
      priceSeries.id,
      postData
    );

    if (success) {
      notifySuccess('Price comment added.');
      cb();
      fetchForFrequency();
    }

    if (!success) {
      notifyErrors(errors);
    }
  };

  passFilters = f => {
    const { filters, filterBy } = this.props;

    const _filterBy = dateFilters => filterBy({ ...filters, ...dateFilters });

    return () => f(filters, _filterBy);
  };

  render() {
    const {
      user,
      frequency,
      frequencyFilter,
      priceSeries,
      onSmallStepBack,
      onBigStepBack,
      onSmallStepForward,
      onBigStepForward
    } = this.props;
    const title = `${_.capitalize(frequency)} Price Series`;

    let headers = [];
    let rows = [];

    const isLoading = _.isNil(priceSeries);
    const hide = frequencyFilter && frequencyFilter !== frequency;

    if (hide) {
      return null;
    }

    if (!isLoading && !_.isEmpty(priceSeries)) {
      headers = priceSeries[0].price_data.map(pd => {
        return pd.date;
      });

      rows = priceSeries.map(ps => {
        return [{ title: ps.display_as, isPriceData: false, id: ps.id }].concat(
          ps.price_data.map(pd => {
            return {
              ...pd,
              isPriceData: true,
              format: ps.format,
              id: ps.id
            };
          })
        );
      });
    }

    const canUserUpdate = hasPermission(user, PERMISSIONS.CHANGE_PRICES);

    return (
      <Segment>
        <Flex spaceBetween>
          <Header>{title}</Header>

          <div>
            <Button
              icon="angle double left"
              onClick={this.passFilters(onBigStepBack)}
            />
            <Button
              icon="angle left"
              onClick={this.passFilters(onSmallStepBack)}
            />

            <Button
              icon="angle right"
              onClick={this.passFilters(onSmallStepForward)}
            />
            <Button
              icon="angle double right"
              onClick={this.passFilters(onBigStepForward)}
            />
          </div>
        </Flex>

        {isLoading && (
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        )}

        <Table celled compact striped selectable definition>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{title}</Table.HeaderCell>
              {headers.map(header => (
                <Table.HeaderCell key={header}>
                  {formatHeaderDate(header)}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {rows.map((row, index) => {
              return (
                <Table.Row key={index}>
                  <Table.Cell width={4}>{row[0].title}</Table.Cell>
                  {row.slice(1).map((item, index) => (
                    <PriceSeriesData
                      key={index}
                      data={item}
                      onPriceSave={this.onPriceSave}
                      onPriceComment={this.onPriceComment}
                      disabled={!canUserUpdate}
                      user={user}
                    />
                  ))}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

export default PriceSeriesFrequencyTable;
