import { InputField, UniSelect } from 'components';

export const FILTERS = productSlug => [
  [
    {
      name: 'id',
      emptyValue: null,
      component: InputField,
      type: 'number',
      label: 'ID',
      width: 4
    },
    {
      name: 'title',
      emptyValue: '',
      component: InputField,
      label: 'Title'
    },
    {
      name: 'display_as',
      emptyValue: '',
      component: InputField,
      label: 'Display As'
    }
  ],
  [
    {
      name: 'price_serie_1',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: UniSelect,
      label: 'Price Serie 1',
      props: () => ({
        productSlug: productSlug,
        source: 'priceSeries'
      })
    },
    {
      name: 'price_serie_2',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: UniSelect,
      label: 'Price Serie 2',
      props: () => ({
        productSlug: productSlug,
        source: 'priceSeries'
      })
    }
  ]
];
