import React from 'react';
import cx from 'classnames';

import _ from 'lodash';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import TimeField from 'react-simple-timefield';

import { Error, Flex, DatePicker } from 'components';

import { formatDateTimeForApi } from 'utils';

import { getTime, parseTime } from './utils';

import styles from './styles.module.css';

const BACKEND_FORMAT = 'YYYY-MM-DD';

class DateTimeField extends React.Component {
  state = {
    time: '',
    key: `initial-${uuidv4()}`
  };

  componentDidMount() {
    const { field } = this.props;

    if (field.value) {
      const time = getTime(field.value);

      const date = moment(field.value);

      this.setState({ time }, () => {
        if (date) {
          // TODO: investigate why we set value in form again
          // form.setFieldValue(field.name, formatDateTimeForApi(date.toDate()));
        }
      });
    }
  }

  setFieldValue = ({ date, time }) => {
    const {
      form: { setFieldValue, setFieldTouched },
      field
    } = this.props;

    const value = moment(date);

    if (value.isValid()) {
      const [h, m] = parseTime(time);

      value.set('hour', h);
      value.set('minute', m);

      setFieldValue(field.name, formatDateTimeForApi(value.toDate()));
    } else {
      setFieldValue(field.name, '');
    }
    setFieldTouched(field.name);
  };

  onDateChange = value => {
    const date = value;
    const time = this.state.time;

    this.setFieldValue({ date, time });

    if (this.state.time === '') {
      this.setState({ time });
    }
  };

  onTimeChange = event => {
    const { field } = this.props;

    const time = event.target.value;

    this.setState({ time }, () => {
      this.setFieldValue({ date: field.value, time: time });
    });
  };

  setToday = () => {
    const today = moment().format(BACKEND_FORMAT);
    const time = moment().format('HH:mm');

    this.setState({ time }, () => {
      this.setFieldValue({ date: today, time: time });
      if (!_.isNil(this.props.field.value)) {
        this.setNewKey();
      }
    });
  };
  setNewKey = () => {
    // we set different key because of https://github.com/gpbl/react-day-picker/issues/815
    this.setState({ key: uuidv4() });
  };
  render() {
    const {
      className,
      label,
      form,
      form: { errors, touched },
      field,
      ...props
    } = this.props;

    const hasErrors = _.get(touched, field.name) && _.get(errors, field.name);

    const { time } = this.state;

    return (
      <div className={cx(styles.container, className)}>
        {label && <label>{label}</label>}
        <div className={styles.row}>
          <DatePicker
            key={this.state.key}
            inputProps={{
              className: cx(
                styles.dayPickerField,
                styles.field,
                styles.desktop,
                {
                  [styles.hasErrors]: hasErrors
                }
              ),
              ...props
            }}
            onChange={value => this.onDateChange(value)}
            value={field.value}
          />
          <TimeField
            value={time}
            className={cx(styles.timeField, styles.field, {
              [styles.hasErrors]: hasErrors
            })}
            onChange={this.onTimeChange}
            disabled={_.isNil(field.value)}
          />
        </div>

        <Flex spaceBetween>
          {hasErrors && (
            <Error className={styles.error}>{_.get(errors, field.name)}</Error>
          )}
          <span className={styles.todayBtn} onClick={this.setToday}>
            Today
          </span>
        </Flex>
      </div>
    );
  }
}

export default DateTimeField;
