import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const dailyNewsletterPermanentPrices = async () => {
  const url = `${BASE_URL}/newsletters/daily/permanent-prices/`;

  return await requestSdk(() => get(url));
};

export const showOrHidePrices = async data => {
  const url = `${BASE_URL}/newsletters/daily/permanent-prices/update/`;

  return await requestSdk(() => post(url, data));
};
