import { requestSdk, get, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const bannerDetail = async id => {
  const url = `${BASE_URL}/cms/banners/${id}/`;

  return await requestSdk(() => get(url));
};

export const bannerUpdateStatus = async (id, data) => {
  const url = `${BASE_URL}/cms/banners/${id}/update/`;

  return await requestSdk(() => post(url, data));
};
