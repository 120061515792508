import React from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { Icon, Header, Table, Button, Divider } from 'semantic-ui-react';

import { Flex, YesNo } from 'components';
import { notifyErrors, notifySuccess } from 'utils/notifications';
import { formatDateTime } from 'utils';

import { passwordResetTokenList, setUserPassword } from './sdk';
import { getPasswordResetBackendUrl } from './utils';

class PasswordTokensModal extends React.Component {
  state = {
    passwordTokens: [],
    generatedPassword: null,
    isSavedGeneratedPassword: false
  };

  componentDidMount() {
    this.fetchResetPasswordTokens();
  }

  fetchResetPasswordTokens = async () => {
    const client = _.get(this.props.client, 'id');

    const { success, data, errors } = await passwordResetTokenList(client);

    if (success) {
      this.setState({ passwordTokens: data });
    } else {
      notifyErrors(errors);
    }
  };

  generatePassword = () => {
    this.setState({ generatedPassword: uuidv4() });
  };

  savePassword = async () => {
    const client = _.get(this.props.client, 'id');
    const postData = { new_password: this.state.generatedPassword };

    const { success, errors } = await setUserPassword(client, postData);

    if (success) {
      this.setState({ isSavedGeneratedPassword: true });
      notifySuccess(`You set new password to ${client}`);
    } else {
      notifyErrors(errors);
    }
  };

  render() {
    const {
      passwordTokens,
      generatedPassword,
      isSavedGeneratedPassword
    } = this.state;

    return (
      <>
        {!_.isEmpty(passwordTokens) && (
          <Table basic="very" celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Expires at</Table.HeaderCell>
                <Table.HeaderCell>Can be claimed</Table.HeaderCell>

                <Table.HeaderCell>Used at</Table.HeaderCell>
                <Table.HeaderCell>Voided at</Table.HeaderCell>

                <Table.HeaderCell>URL</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>

                <Table.HeaderCell>Created at</Table.HeaderCell>
                <Table.HeaderCell>Created By</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {passwordTokens.map(token => (
                <Table.Row>
                  <Table.Cell>{formatDateTime(token.expires_at)}</Table.Cell>

                  <Table.Cell>
                    <YesNo
                      value={
                        _.isNil(token.used_at) &&
                        _.isNil(token.voided_at) &&
                        moment().isBefore(moment(token.expires_at))
                      }
                    />
                  </Table.Cell>

                  <Table.Cell>{formatDateTime(token.used_at)}</Table.Cell>
                  <Table.Cell>{formatDateTime(token.voided_at)}</Table.Cell>

                  <Table.Cell>
                    {getPasswordResetBackendUrl(token.id)}
                  </Table.Cell>
                  <Table.Cell>
                    <Icon
                      size="large"
                      name="copy"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          getPasswordResetBackendUrl(token.id)
                        );
                        notifySuccess('URL Copied to clipboard!');
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell>{formatDateTime(token.created_at)}</Table.Cell>
                  <Table.Cell>
                    {_.get(token.created_by, 'first_name')}{' '}
                    {_.get(token.created_by, 'last_name')}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}

        {_.isEmpty(passwordTokens) &&
          'There are no tokens for this client. Click on "Send reset password" button to generate a new one.'}
        <Divider />

        <Header as="h3">Generate Password</Header>
        {!generatedPassword && (
          <Button onClick={this.generatePassword} color="green">
            Generate New Password
          </Button>
        )}
        {generatedPassword && (
          <>
            <Header as="h4" style={{ marginRight: '40px' }}>
              New password:{' '}
            </Header>
            <Flex flexStart alignCenter>
              {generatedPassword}
              {!isSavedGeneratedPassword && (
                <Button
                  style={{ marginLeft: '40px' }}
                  color="green"
                  onClick={this.savePassword}>
                  Save Password
                </Button>
              )}
              {isSavedGeneratedPassword && (
                <Flex>
                  <Header
                    style={{ margin: '0 30px' }}
                    as="h4"
                    color="green"
                    icon="close">
                    Saved
                  </Header>
                  <Button
                    color="green"
                    onClick={() => {
                      navigator.clipboard.writeText(generatedPassword);
                    }}>
                    Copy Password to Clipboard
                  </Button>
                </Flex>
              )}
            </Flex>
          </>
        )}
      </>
    );
  }
}

export default PasswordTokensModal;
