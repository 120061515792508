import React from 'react';

import File from 'react-dropzone';

import { Icon } from 'semantic-ui-react';

import styles from './styles.module.css';

class UploadFile extends React.Component {
  render() {
    const { multiple, placeholder, onDrop, accept, maxSize } = this.props;

    return (
      <File
        onDrop={onDrop}
        multiple={multiple}
        {...(accept && { accept })}
        {...(maxSize && { maxSize })}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className={styles.File}>
                <Icon name="upload" color="grey" size="big" />
                <div>{placeholder || 'Drag & drop file here'}</div>
              </div>
            </div>
          </section>
        )}
      </File>
    );
  }
}

export default UploadFile;
