import React from 'react';

import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import { Header, Segment } from 'semantic-ui-react';

import { Page, Error, LoginRequired } from 'components';

import { PollUpdateForm, Choices, Results } from './components';

import { pollDetail } from './sdk';

class PollDetail extends React.Component {
  state = {
    poll: null,
    errors: null
  };

  fetchPoll = async () => {
    const { pollId } = this.props.match.params;

    const { data, errors, success } = await pollDetail(pollId);

    if (success) {
      this.setState({ poll: data });
    } else {
      this.setState({ errors });
    }
  };
  componentDidMount() {
    this.fetchPoll();
  }

  render() {
    const { user, crumbs } = this.props;

    const { poll, errors } = this.state;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Header as="h1">Edit poll</Header>
              {!_.isEmpty(errors) &&
                errors.map((error, index) => (
                  <Error key={index}>{error.message}</Error>
                ))}{' '}
              {poll && (
                <PollUpdateForm poll={poll} fetchPoll={this.fetchPoll} />
              )}
            </Segment>
            <Segment>
              <Header>Choices</Header>
              {poll && <Choices poll={poll.poll} fetchPoll={this.fetchPoll} />}
            </Segment>

            <Segment>
              <Header>Results</Header>
              {poll && <Results poll={poll.poll} fetchPoll={this.fetchPoll} />}
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default withRouter(LoginRequired(PollDetail));
