import React from 'react';

import { withRouter } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  InputField,
  FormRow,
  CreateButtonInRow,
  UnsavedFormPrompt,
  RichTextAreaField,
  FileField,
  CheckboxField,
  GlossaryCategorySelect,
  GlossaryTermsSelect
} from 'components';
import { notifySuccess } from 'utils/notifications';
import { handleFormErrors, prepareFormData } from 'utils/forms';
import { populateSlug } from 'utils';

import { pageUrl } from 'config/routes';

import { GlossaryTermList } from 'pages';

import { glossaryTermCreateSchema } from './utils';
import { glossaryTermCreate } from './sdk';

class GlossaryTermCreateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await glossaryTermCreate(data);

    if (success) {
      notifySuccess('Glossary Term created.');
      this.props.history.push(pageUrl(GlossaryTermList));
    } else {
      handleFormErrors(errors, setFieldError);
    }
    setSubmitting(false);
  };

  render() {
    const initialValues = {
      title: '',
      slug: '',
      text: '',
      image: null,
      isPublished: false,
      category: null
    };

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={glossaryTermCreateSchema}
        onSubmit={this.handleSubmit}>
        {({ values, handleSubmit, isSubmitting, setFieldValue, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty && !isSubmitting} />

            <CreateButtonInRow onClick={handleSubmit} disabled={isSubmitting} />
            <FormRow>
              <Field
                width={6}
                name="title"
                label="Title"
                required
                component={InputField}
                onChange={(e, data) => {
                  populateSlug(data, setFieldValue);
                }}
              />
              <Field
                required
                width={6}
                name="slug"
                label="Slug"
                component={InputField}
              />
              <Field
                width={4}
                name="isPublished"
                label="Is published"
                component={CheckboxField}
              />
            </FormRow>
            <FormRow>
              <Field
                width={4}
                name="image"
                label="Image"
                component={FileField}
              />
              <GlossaryCategorySelect
                width={6}
                name="glossaryCategory"
                label="Glossary Category"
              />
            </FormRow>
            <FormRow>
              <Field
                width={12}
                component={RichTextAreaField}
                label="Text"
                name="text"
              />
            </FormRow>
            <FormRow>
              <GlossaryTermsSelect
                width={8}
                name="relatedGlossaryTerms"
                label="Related Terms"
              />
            </FormRow>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(GlossaryTermCreateForm);
