import React from 'react';
import { withRouter } from 'react-router-dom';

import {
  Page,
  Flex,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Filters
} from 'components';

import { PermissionsRequired, PERMISSIONS } from 'permissions';

import { SalesTerritoryTable } from './components';

import { salesTerritoryList } from './sdk';

import { FILTERS as LAYOUT_FILTERS } from './utils';

class SalesTerritoryList extends React.Component {
  render() {
    const {
      user,
      data,
      limit,
      count,
      page,
      changePage,
      changeRowsPerPage,
      filters,
      filterBy,
      orderBy,
      clear,
      sort,
      refetch
    } = this.props;
    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content>
            <Flex spaceBetween>
              <Header>Sales Territories</Header>
            </Flex>
            <Filters
              filters={filters}
              count={count}
              filterBy={filterBy}
              clearFilters={clear}
              layout={LAYOUT_FILTERS}
            />
            {data && (
              <>
                <SalesTerritoryTable
                  refetch={refetch}
                  sort={sort}
                  orderBy={orderBy}
                  salesTerritories={data}
                  pagination={
                    <Pagination
                      limit={limit}
                      count={count}
                      page={page}
                      changePage={changePage}
                      changeRowsPerPage={changeRowsPerPage}
                    />
                  }
                />
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: salesTerritoryList
})(
  LoginRequired(
    PermissionsRequired([PERMISSIONS.VIEW_STAFF])(
      withRouter(SalesTerritoryList)
    )
  )
);
