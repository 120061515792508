import React from 'react';

import _ from 'lodash';

import {
  Header,
  Page,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  NoDataMessage,
  Filters as SharedFilters
} from 'components';

import { CurrencyRateTable } from './components';

import {
  currencyRateList,
  priceSeriesCurrencyListForCurrencyRate
} from './sdk';

import { FILTERS as LAYOUT_FILTERS } from './utils';

class CurrencyRateList extends React.Component {
  state = {
    errors: null,
    priceSeriesCurrencies: null
  };

  fetchTableColumns = async () => {
    const {
      data,
      errors,
      success
    } = await priceSeriesCurrencyListForCurrencyRate();
    if (success) {
      const priceSeriesCurrencies = data.filter(el => el.code !== 'EUR');
      this.setState({ priceSeriesCurrencies });
    } else {
      this.setState({ errors });
    }
  };

  componentDidMount() {
    this.fetchTableColumns();
  }

  render() {
    const {
      user,
      data,
      limit,
      count,
      page,
      changePage,
      changeRowsPerPage,
      orderBy,
      filters,
      filterBy,
      clear,
      crumbs
    } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Header>Currency Rates</Header>
            {data && (
              <>
                <SharedFilters
                  filters={filters}
                  filterBy={filterBy}
                  clearFilters={clear}
                  count={count}
                  layout={LAYOUT_FILTERS}
                />
                {_.isEmpty(data) && <NoDataMessage />}
                {!_.isEmpty(data) && (
                  <CurrencyRateTable
                    currencyRates={data}
                    priceSeriesCurrencies={this.state.priceSeriesCurrencies}
                    orderBy={orderBy}
                    pagination={
                      <Pagination
                        limit={limit}
                        count={count}
                        page={page}
                        changePage={changePage}
                        changeRowsPerPage={changeRowsPerPage}
                      />
                    }
                  />
                )}
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: currencyRateList
})(LoginRequired(CurrencyRateList));
