import React from 'react';

import _ from 'lodash';

import { Formik } from 'formik';

import { Modal, Icon, Header, Form } from 'semantic-ui-react';

import {
  Button,
  Field,
  SelectField,
  FormRow,
  InputField,
  RichTextAreaField,
  TextAreaField,
  YesNoSelect,
  EmailVariables
} from 'components';
import { handleFormErrors, prepareFormData } from 'utils/forms';

import { notifySuccess, notifyErrors } from 'utils/notifications';

import { invoiceSendDetails, invoiceSend } from './sdk';

import { invoiceSendValidationSchema } from './schema';

import styles from './styles.module.css';

class InvoiceSendModal extends React.Component {
  state = {
    sendDetails: null
  };

  close = () => {
    this.props.onClose();
  };

  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { invoice } = this.props;

    const { errors, success } = await invoiceSend(invoice.id, data);

    setSubmitting(false);

    if (success) {
      notifySuccess('Invoice sent.');
      this.close();
      return;
    }

    handleFormErrors(errors, setFieldError);
  };

  async componentDidMount() {
    const { success, data, errors } = await invoiceSendDetails();

    if (success) {
      this.setState({ sendDetails: data });
    } else {
      notifyErrors(errors);
    }
  }

  render() {
    const { sendDetails } = this.state;
    const { invoice } = this.props;

    const email = invoice.contact_info.client.email;

    const initialValues = {
      fileType: 'PDF',
      subject: 'Kallanish Invoice # {INVOICE_ID}',
      isTest: false,
      fromName: _.get(sendDetails, 'from_name', ''),
      fromEmail: _.get(sendDetails, 'from_email', ''),
      html: _.get(sendDetails, 'html', ''),
      plainText: _.get(sendDetails, 'plain_text', ''),
      css: _.get(sendDetails, 'css', ''),
      testEmail: ''
    };

    const fileTypeOptions = [
      {
        key: 'PDF',
        label: 'PDF',
        value: 'PDF'
      },
      {
        key: 'HTML',
        label: 'HTML',
        value: 'HTML'
      }
    ];

    return (
      <Modal open={true} size="large" centered={false}>
        <Modal.Header>
          <div className={styles.header}>
            <Header as="h2">Send Invoice</Header>
            <Icon name="close" onClick={this.close} />
          </div>
        </Modal.Header>
        <Modal.Content>
          {sendDetails && (
            <Formik
              // enableReinitialize={true}
              validationSchema={invoiceSendValidationSchema}
              onSubmit={this.handleSubmit}
              initialValues={initialValues}>
              {({ values, handleSubmit, isSubmitting }) => (
                <Form>
                  <FormRow flexEnd>
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      disabled={isSubmitting}>
                      Send
                    </Button>
                  </FormRow>
                  <FormRow>
                    <Field
                      required
                      label="File type"
                      name="fileType"
                      component={SelectField}
                      options={fileTypeOptions}
                    />
                    <Field
                      required
                      label="Subject"
                      name="subject"
                      component={InputField}
                    />
                    <Field
                      required
                      label="Send to"
                      name="isTest"
                      component={YesNoSelect}
                      noLabel={email}
                      yesLabel="Send test email"
                      isClearable={false}
                    />
                    {values.isTest && (
                      <Field
                        required
                        label="Email"
                        name="testEmail"
                        component={InputField}
                      />
                    )}
                  </FormRow>
                  <FormRow>
                    <Field
                      required
                      label="From name"
                      name="fromName"
                      component={InputField}
                    />
                    <Field
                      required
                      label="From email"
                      name="fromEmail"
                      component={InputField}
                    />
                  </FormRow>
                  <FormRow>
                    <Field
                      label="HTML"
                      name="html"
                      component={RichTextAreaField}
                    />
                  </FormRow>
                  <FormRow>
                    <Field
                      label="Plain text"
                      name="plainText"
                      component={TextAreaField}
                    />
                  </FormRow>
                  <FormRow>
                    <Field label="CSS" name="css" component={TextAreaField} />
                  </FormRow>
                </Form>
              )}
            </Formik>
          )}
          <EmailVariables />
        </Modal.Content>

        <Modal.Actions>
          <Button color="red" onClick={this.close}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default InvoiceSendModal;
