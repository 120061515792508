import React from 'react';

import { Link, useParams } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { PriceSerieUpdate, ClientDetail } from 'pages';

import { formatDate, formatDateForApi } from 'utils';

function PriceTable({ priceSeries, startDate, endDate, frequency }) {
  const { productSlug } = useParams();

  const getPriceSeriesUpdateUrl = (priceSeriesId, date, frequency) => {
    if (!date) {
      date = '';
    }

    const url = pageUrl(PriceSerieUpdate, { productSlug });

    return `${url}?id=${priceSeriesId}&date=${date}&frequency=${frequency}`;
  };

  return (
    <Table celled selectable compact={true}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Price Series Item</Table.HeaderCell>
          <Table.HeaderCell>Latest available data</Table.HeaderCell>
          <Table.HeaderCell>Date for latest available data</Table.HeaderCell>
          <Table.HeaderCell>
            To be filled in {formatDate(startDate)} - {formatDate(endDate)}
          </Table.HeaderCell>
          <Table.HeaderCell>Principal owner</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {priceSeries &&
          priceSeries.map(price => (
            <Table.Row key={price.id}>
              <Table.Cell>{price.display_as}</Table.Cell>
              <Table.Cell>
                <Link
                  to={getPriceSeriesUpdateUrl(
                    price.id,
                    price.latest_date,
                    frequency
                  )}>
                  {price.latest_value ? price.latest_value : 'No previous data'}
                </Link>
              </Table.Cell>
              <Table.Cell>{price.latest_date}</Table.Cell>
              <Table.Cell>
                <Link
                  to={getPriceSeriesUpdateUrl(
                    price.id,
                    formatDateForApi(startDate),
                    frequency
                  )}>
                  Edit
                </Link>
              </Table.Cell>
              <Table.Cell>
                {price.principal_owner ? (
                  <Link
                    to={pageUrl(ClientDetail, {
                      clientId: price.principal_owner.id
                    })}>
                    {price.principal_owner.first_name}{' '}
                    {price.principal_owner.last_name}
                  </Link>
                ) : (
                  'N/A'
                )}
              </Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );
}

export default PriceTable;
