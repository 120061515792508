import React from 'react';

import _ from 'lodash';

import {
  Header,
  Radio,
  Divider,
  Segment,
  Button,
  Dimmer
} from 'semantic-ui-react';

import { Flex, Loader } from 'components';

import SendTestEmail from './SendTestEmail';
import InstantNewForm from './InstantNewForm';
import { BREAKING_NEWS, TRENDING_NEWS } from './utils';

import { generateContent, getInstantNew } from './sdk';

class InstantNewsModal extends React.Component {
  state = {
    sendDetails: null,
    newsType: BREAKING_NEWS.value,
    instantNewsData: {},
    isLoading: true
  };

  close = () => {
    this.props.onClose();
  };

  componentDidMount() {
    const { instantNew, newsType } = this.props;

    this.setState({ newsType }, () => {
      if (!instantNew) {
        this.generateContent();
      } else {
        this.fetchInstantNew();
      }
    });
  }

  generateContent = async () => {
    const { articleData } = this.props;
    const { newsType } = this.state;

    const postData = {
      ...articleData,
      news_type: newsType
    };

    const { data, success } = await generateContent(postData);

    if (success) {
      this.setState({
        instantNewsData: data,
        isLoading: false
      });
    }
  };

  fetchInstantNew = async () => {
    const { instantNew } = this.props;

    const { data, success } = await getInstantNew(instantNew);

    if (success) {
      this.setState({
        instantNewsData: data,
        isLoading: false
      });
    }
  };

  handleNewsTypeChange = (e, { value }) => {
    this.setState({ newsType: value });
  };

  render() {
    const { newsType, instantNewsData, isLoading } = this.state;

    const { refetch, articleData, instantNew } = this.props;

    const formProps = {
      articleData,
      refetch,
      instantNew
    };

    return (
      <>
        <Segment>
          <Flex spaceBetween>
            <div>
              <Header as="h4">Choose news type:</Header>
              <Flex>
                <Radio
                  label={BREAKING_NEWS.label}
                  value={BREAKING_NEWS.value}
                  checked={newsType === BREAKING_NEWS.value}
                  onChange={this.handleNewsTypeChange}
                />
                <Radio
                  style={{ margin: '0 30px' }}
                  label={TRENDING_NEWS.label}
                  value={TRENDING_NEWS.value}
                  checked={newsType === TRENDING_NEWS.value}
                  onChange={this.handleNewsTypeChange}
                />

                <Button
                  color="green"
                  onClick={() =>
                    this.setState({ isLoading: true }, this.generateContent)
                  }>
                  Generate Content
                </Button>
              </Flex>
            </div>
            <Divider vertical />
            <div style={{ width: '47%' }}>
              <SendTestEmail instantNew={instantNew} />
            </div>
          </Flex>
        </Segment>
        <Segment style={{ minHeight: '300px' }}>
          <Dimmer active={isLoading} inverted>
            <Loader inverted active={isLoading} />
          </Dimmer>

          {!_.isEmpty(instantNewsData) && (
            <InstantNewForm
              instantNewsData={instantNewsData}
              newsType={newsType}
              close={this.close}
              fetchInstantNew={this.fetchInstantNew}
              {...formProps}
            />
          )}
        </Segment>
      </>
    );
  }
}

export default InstantNewsModal;
