import React from 'react';

import { Field, SelectField } from 'components';

class SimpleSelect extends React.Component {
  render() {
    const { name, options, ...rest } = this.props;

    return (
      <Field
        name={name}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default SimpleSelect;
