import React from 'react';

import { Table } from 'semantic-ui-react';

import ChangesModal from './ChangesModal/index';

import { Link } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { WebArticleDetail } from 'pages';

import { formatDateTime } from 'utils';
import { Admin, TableFooter, HighlightedText } from 'components';

import styles from './styles.module.css';

class ArticleChangesTable extends React.Component {
  state = {
    openModal: false
  };

  setOpenModal = openModal => {
    this.setState({ openModal: openModal });
  };

  render() {
    const { articleChanges, pagination } = this.props;
    return (
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              singleLine
              style={{ fontWeight: '400' }}
              colspan="8">
              {pagination}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Title </Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Authors</Table.HeaderCell>
            <Table.HeaderCell>Update by</Table.HeaderCell>
            <Table.HeaderCell>Update Time</Table.HeaderCell>
            <Table.HeaderCell>Status Changes</Table.HeaderCell>
            <Table.HeaderCell>Percent Text Changes</Table.HeaderCell>
            <Table.HeaderCell>Text Changes</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {articleChanges.map(log => (
            <Table.Row key={log.id}>
              <Table.Cell width={3}>
                <Link
                  to={pageUrl(WebArticleDetail, {
                    productSlug: log.article.type
                      .replace(/\s+/g, '-')
                      .toLowerCase(),
                    articleId: log.article.id
                  })}>
                  {log.article.title}
                </Link>
              </Table.Cell>
              <Table.Cell width={1}>{log.article.type}</Table.Cell>
              <Table.Cell width={2}>
                {log.article.authors.map((author, index) => (
                  <>
                    {index > 0 && ', '}
                    <Admin value={author} key={index} />{' '}
                  </>
                ))}
              </Table.Cell>
              <Table.Cell width={2}>{log.updated_by.email}</Table.Cell>
              <Table.Cell width={2}>
                {formatDateTime(log.updated_at)}
              </Table.Cell>
              <Table.Cell width={2}>
                {log.old_status && log.new_status ? (
                  <>
                    <HighlightedText
                      value={log.old_status}
                      highlightColor="#ffeef0"
                    />{' '}
                    -&gt;{' '}
                    <HighlightedText
                      value={log.new_status}
                      highlightColor="#e6ffed"
                    />
                  </>
                ) : (
                  'N/A'
                )}
              </Table.Cell>
              <Table.Cell width={1}>
                {log.percent_changed_text ? (
                  <>{log.percent_changed_text}%</>
                ) : (
                  'N/A'
                )}
              </Table.Cell>
              <Table.Cell width={1} className={styles.LargeText}>
                {log.new_text ? (
                  <ChangesModal
                    oldValue={log.old_text}
                    newValue={log.new_text}
                    onOpen={() => this.setOpenModal(true)}
                    onClose={() => this.setOpenModal(false)}
                  />
                ) : (
                  'N/A'
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <TableFooter>{pagination}</TableFooter>
      </Table>
    );
  }
}

export default ArticleChangesTable;
