import * as yup from 'yup';

export const courseAddAgendaSchema = yup.object().shape({
  title: yup.string().required('Title is required.'),
  order: yup
    .number()
    .typeError('Please enter a number.')
    .required('Order is required.'),
  onDay: yup.string().required('Day is required.')
});
