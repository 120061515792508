import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const priceSeriesDataCreate = async (priceSeriesId, data) => {
  const url = `${BASE_URL}/prices/series/v2/${priceSeriesId}/data-points/create/`;

  return await requestSdk(() => post(url, data));
};

export const priceSeriesDataCommentCreate = async (priceSeriesId, data) => {
  const url = `${BASE_URL}/prices/series/v2/${priceSeriesId}/comment/create/`;

  return await requestSdk(() => post(url, data));
};

export const priceSeriesDataDelete = async sdkArgs => {
  const { itemId } = sdkArgs;
  const url = `${BASE_URL}/prices/series/v2/data-points/${itemId}/delete/`;

  return await requestSdk(() => post(url));
};
