import _ from 'lodash';

// The zero plural is a plural form of a count noun that is identical to the singular form.
const ZERO_PLURALS = ['series'];

export const pluralize = (word, count, suffix = 's') => {
  if (_.includes(ZERO_PLURALS, word)) {
    return word;
  }

  const pluralRules = new Intl.PluralRules('en', { type: 'cardinal' });
  const pluralForm = pluralRules.select(count);

  if (pluralForm === 'one') {
    return word;
  }

  return word + suffix;
};
