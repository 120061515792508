import * as yup from 'yup';

export const courseAddPromoCodeSchema = yup.object().shape({
  name: yup.string().required('Code is required.'),
  description: yup.string().required('Description is required'),
  discount_type: yup
    .string()
    .nullable()
    .required('Discount type is required.'),
  amount: yup.number().required('Amount is required')
});
