import React from 'react';

import { withRouter } from 'react-router-dom';

import { Header, Segment } from 'semantic-ui-react';

import { Page, LoginRequired, Error, Meta } from 'components';

import { marketDataReportDetail, marketDataReportUpdateMeta } from './sdk';

import { MarketDataReportForm } from './components';

class MarketDataReportDetail extends React.Component {
  state = {
    errors: null,
    marketDataReport: null
  };

  fetchMarketDataReport = async () => {
    const { reportId } = this.props.match.params;

    const { data, errors, success } = await marketDataReportDetail(reportId);

    if (success) {
      this.setState({ marketDataReport: data });
    } else {
      this.setState({ errors });
    }
  };

  componentDidMount() {
    this.fetchMarketDataReport();
  }

  render() {
    const { user, crumbs } = this.props;

    const { marketDataReport, errors } = this.state;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Header as="h1">Change report</Header>
              {errors &&
                errors.map((error, index) => (
                  <Error key={index}>{error.message}</Error>
                ))}
              {marketDataReport && (
                <>
                  <MarketDataReportForm
                    marketDataReport={marketDataReport}
                    fetchMarketDataReport={this.fetchMarketDataReport}
                  />
                  <Meta
                    object={marketDataReport}
                    fetchObject={this.fetchMarketDataReport}
                    sdk={marketDataReportUpdateMeta}
                  />
                </>
              )}
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(withRouter(MarketDataReportDetail));
