import _ from 'lodash';

export const getLoggedUserInitials = user => {
  const names = _.words(user.name);

  let result = '';

  _.forEach(names, name => {
    result += name.charAt(0).toUpperCase();
  });

  return result;
};
