import React from 'react';

import {
  Page,
  Header,
  LoginRequired,
  ListViewEssentials,
  Filters as SharedFilters
} from 'components';

import { articleChangesAnalyticsList } from './sdk';
import { formatDateForApi } from 'utils';
import moment from 'moment';

import { Statistic } from 'semantic-ui-react';

import {
  ArticleChangesAnalyticsTable,
  EditorsChangesAnalyticsTable
} from './components';

import { FILTERS as LAYOUT_FILTERS } from './utils';

const DEFAULT_LIMIT = 50;

class ArticleChangesAnalyticsList extends React.Component {
  clear = async () => {
    await this.props.filterBy({
      start: formatDateForApi(moment().startOf('month')),
      end: formatDateForApi(moment().endOf('month'))
    });
    this.props.refetch();
  };
  render() {
    const { user, data, filters, filterBy, orderBy, sort } = this.props;
    const clearFilters = this.clear;
    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content>
            <Header>Article Changes Analytics</Header>
            <SharedFilters
              filters={filters}
              clearFilters={clearFilters}
              filterBy={filterBy}
              layout={LAYOUT_FILTERS}
            />
            {data && data.articles.length > 0 && (
              <React.Fragment>
                <Statistic.Group size="tiny">
                  <Statistic color="green">
                    <Statistic.Value>{data.articles.length}</Statistic.Value>
                    <Statistic.Label>Articles</Statistic.Label>
                  </Statistic>
                </Statistic.Group>
                <ArticleChangesAnalyticsTable
                  sort={sort}
                  orderBy={orderBy}
                  articleChanges={data.articles}
                  filters={filters}
                />
              </React.Fragment>
            )}
            {data && data.editors.length > 0 && (
              <React.Fragment>
                <Statistic.Group size="tiny">
                  <Statistic color="green">
                    <Statistic.Value>{data.editors.length}</Statistic.Value>
                    <Statistic.Label>Editors</Statistic.Label>
                  </Statistic>
                </Statistic.Group>
                <EditorsChangesAnalyticsTable
                  sort={sort}
                  orderBy={orderBy}
                  editorsChanges={data.editors}
                  filters={filters}
                  articleType={filters.article_type}
                />
              </React.Fragment>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: articleChangesAnalyticsList,
  limit: DEFAULT_LIMIT
})(LoginRequired(ArticleChangesAnalyticsList));
