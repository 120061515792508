import * as yup from 'yup';
// import _ from 'lodash';
import moment from 'moment';
import _ from 'lodash';
import 'utils/yup';

export const trainerUpdateSchema = yup.object().shape({
  name: yup.string().required('Title is required.'),
  slug: yup.string().required('Slug is required.'),
  email: yup
    .string()
    .nullable()
    .strongEmail('Invalid email.')
});

export const getUpcomingEvents = events =>
  _.filter(events, event => moment(event.date_start, 'YYYY-MM-DD') > moment());

export const getPastEvents = events =>
  _.filter(events, event => moment(event.date_start, 'YYYY-MM-DD') < moment());
