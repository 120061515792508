import React from 'react';

import { createMedia } from '@artsy/fresnel';

import { withRouter } from 'react-router-dom';

import { Header } from 'semantic-ui-react';

import styles from './styles.module.scss';

class StagingStickyFooter extends React.Component {
  render() {
    const AppMedia = createMedia({
      breakpoints: {
        mobile: 320,
        tablet: 768,
        computer: 992,
        largeScreen: 1200,
        widescreen: 1920
      }
    });
    const mediaStyles = AppMedia.createMediaStyle();
    const { Media, MediaContextProvider } = AppMedia;

    return (
      <div className={styles.StaggingFooterContainer}>
        <style>{mediaStyles}</style>
        <MediaContextProvider>
          <Media greaterThanOrEqual="computer">
            <Header color="red">
              Test environment. This is not live Kallanish!
            </Header>
          </Media>
          <Media lessThan="computer">
            <Header color="red">Test environment</Header>
          </Media>
        </MediaContextProvider>
      </div>
    );
  }
}

export default withRouter(StagingStickyFooter);
