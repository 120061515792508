import React, { useEffect, useState } from 'react';

import { Form, Segment, Grid } from 'semantic-ui-react';

import { Formik } from 'formik';
import { FormRow, UniSelect, SortableList } from 'components';
import { notifyErrors } from 'utils/notifications';

import { useHistory, useParams } from 'react-router';
import { pageUrl } from 'config/routes';
import { WebArticleDetail, PriceSerieDetail } from 'pages';
import { sectionRelated } from './sdk';

function SectionData({ sectionId }) {
  const history = useHistory();

  const { productSlug } = useParams();

  const [articles, setArticles] = useState([]);
  const [prices, setPrices] = useState([]);
  const [search, setSearch] = useState(null);

  const autoCompleteSelected = async (selection, itemsType) => {
    if (!selection) {
      return;
    }
    setSearch(selection.target.label);
    const { errors, success } = await sectionRelated({
      sectionId,
      itemId: selection.target.value,
      itemsType,
      action: 'add'
    });
    if (success) {
      getRelatedItems(sectionId, itemsType);
    } else {
      notifyErrors(errors);
    }
  };

  const articleEdit = articleId => {
    history.push(pageUrl(WebArticleDetail, { productSlug, articleId }));
  };
  const priceEdit = priceSeriesId => {
    history.push(pageUrl(PriceSerieDetail, { productSlug, priceSeriesId }));
  };

  const getRelatedItems = async (sectionId, itemsType) => {
    const { errors, success, data } = await sectionRelated({
      sectionId,
      itemsType
    });
    if (success) {
      if (itemsType === 'articles') {
        setArticles(data);
      } else if (itemsType === 'prices') {
        setPrices(data);
      }
    } else {
      notifyErrors(errors);
    }
    setSearch(null);
  };

  const relatedItemsSorted = ({ items }, itemsType) => {
    sectionRelated({ sectionId, items, itemsType, action: 'order' });
  };

  useEffect(() => {
    ['articles', 'prices'].map(itemsType =>
      getRelatedItems(sectionId, itemsType)
    );
  }, [sectionId]);

  return (
    <Segment>
      <Formik>
        {({ values, handleSubmit, isSubmitting, dirty, resetForm }) => (
          <Form>
            <FormRow>
              <UniSelect
                width={8}
                name="articles"
                label="Articles"
                autoComplete={true}
                source="articleChoices"
                productSlug={productSlug}
                onChange={selection =>
                  autoCompleteSelected(selection, 'articles')
                }
                value={search}
                params={{ section_id: sectionId }}
              />
              <UniSelect
                width={8}
                name="prices"
                label="Price Series"
                autoComplete={true}
                source="priceSeriesChoicesOnly"
                productSlug={productSlug}
                onChange={selection =>
                  autoCompleteSelected(selection, 'prices')
                }
                value={search}
                params={{ section_id: sectionId }}
              />
            </FormRow>
          </Form>
        )}
      </Formik>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column>
            {
              <SortableList
                items={articles}
                onSortEnd={items => relatedItemsSorted(items, 'articles')}
                handleEdit={articleEdit}
                handleDelete={() => getRelatedItems(sectionId, 'articles')}
                deleteSdk={sectionRelated}
                sdkExtraArgs={{
                  sectionId: sectionId,
                  itemsType: 'articles',
                  action: 'remove'
                }}
                deleteButtonText={'Remove article'}
              />
            }
          </Grid.Column>
          <Grid.Column>
            {
              <SortableList
                items={prices}
                onSortEnd={items => relatedItemsSorted(items, 'prices')}
                handleEdit={priceEdit}
                handleDelete={() => getRelatedItems(sectionId, 'prices')}
                deleteSdk={sectionRelated}
                sdkExtraArgs={{
                  sectionId: sectionId,
                  itemsType: 'prices',
                  action: 'remove'
                }}
                deleteButtonText={'Remove price'}
              />
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
}

export default SectionData;
