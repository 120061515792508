import _ from 'lodash';
import * as yup from 'yup';

export const getExcludeMonths = ({ year, data }) => {
  if (_.isEmpty(data)) {
    return;
  }

  let result = [];

  _.forEach(data, monthYearArr => {
    if (monthYearArr[1] === year) {
      result.push(monthYearArr[0]);
    }
  });

  return result;
};

export const snapshotCreateSchema = yup.object().shape({
  month: yup.string().required('Month is required.')
});
