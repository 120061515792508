import React from 'react';
import { Link } from 'react-router-dom';
import { Segment, Table, Dimmer, Loader } from 'semantic-ui-react';

import _ from 'lodash';

import { pageUrl } from 'config/routes';

import { TableFooter } from 'components';

import { PERMISSIONS, hasPermission, DisableableElement } from 'permissions';

import { formatDateForApi } from 'utils';

class PriceSeriesPublishTable extends React.Component {
  getPriceDataRepresentation = (priceData, date = null, priceId) => {
    const EMPTY_DATA_VALUE = '';

    if (_.isNil(priceData)) {
      return EMPTY_DATA_VALUE;
    }

    const isRange = !_.isNil(priceData.value_1) && !_.isNil(priceData.value_2);
    const isSingle = !isRange && !_.isNil(priceData.value_1);

    let dataRepresentation = EMPTY_DATA_VALUE;

    if (isRange) {
      dataRepresentation = `${priceData.value_1} | ${priceData.value_2}`;
    }

    if (isSingle) {
      dataRepresentation = priceData.value_1;
    }

    if (dataRepresentation !== EMPTY_DATA_VALUE) {
      dataRepresentation = (
        <Link
          to={{
            pathname: pageUrl(this.props.updatePage),
            search: `?id=${priceId}&date=${formatDateForApi(date)}`
          }}>
          {dataRepresentation}
        </Link>
      );
    }

    return dataRepresentation;
  };

  getDatePriceArray = price => {
    return this.props.dates.map(date =>
      this.getPriceDataRepresentation(
        _.find(price.price_data, priceData => priceData.date === date),
        date,
        price.id
      )
    );
  };

  getPriceStatus = price => {
    const approved = _.get(this.props.currentFilters, 'approved', false);

    if (!approved) {
      return (
        <Link onClick={() => this.props.approvePrice(price)}>Publish</Link>
      );
    }

    return 'Approved';
  };

  getPriceDisplay = price => {
    return (
      <Link to={pageUrl(this.props.detailPage, { priceSeriesId: price.id })}>
        {price.display_as || price.title}
      </Link>
    );
  };

  render() {
    const { prices, dates, pagination, user } = this.props;

    let headers = [];
    let rows = [];

    const isLoading = _.isNil(prices) || _.isNil(dates);

    if (!isLoading && !_.isEmpty(dates)) {
      headers = ['Price Name', ...dates, 'Status', 'Editor'];
    }

    if (!isLoading && !_.isEmpty(prices)) {
      rows = prices.map(price => [
        this.getPriceDisplay(price),
        ...this.getDatePriceArray(price),
        this.getPriceStatus(price),
        price.last_approved_by
      ]);
    }

    const isTableVisible = !isLoading && !_.isEmpty(prices);

    const canUserPublish = hasPermission(user, PERMISSIONS.PUBLISH_PRICES);

    return (
      <Segment>
        {isLoading && (
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        )}
        {isTableVisible && (
          <Table celled compact striped selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  singleLine
                  style={{ fontWeight: '400' }}
                  colspan={headers.length}>
                  {pagination}
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                {headers.map(header => (
                  <Table.HeaderCell key={header}>{header}</Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {rows.map((row, index) => {
                return (
                  <Table.Row key={index}>
                    {row.map((cellValue, cellIndex) => (
                      <Table.Cell key={`cell${cellIndex}`}>
                        <DisableableElement disabled={!canUserPublish}>
                          {cellValue}
                        </DisableableElement>
                      </Table.Cell>
                    ))}
                  </Table.Row>
                );
              })}
            </Table.Body>
            <TableFooter>{pagination}</TableFooter>
          </Table>
        )}
      </Segment>
    );
  }
}

export default PriceSeriesPublishTable;
