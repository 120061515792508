import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const pipelinesDashboardApi = async (userId, params) => {
  params['account_manager'] = userId;

  const url = `${BASE_URL}/crm/pipelines/`;

  return await requestSdk(() => get(url, params));
};
