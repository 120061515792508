import React from 'react';

import { Formik } from 'formik';
import { Form } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { MailshotList } from 'pages';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import {
  Field,
  InputField,
  FormRow,
  DateTimeField,
  CreateButtonInRow,
  SelectField,
  UnsavedFormPrompt
} from 'components';

import { mailshotCreate } from './sdk';
import {
  INITIAL_VALUES as initialValues,
  MAILSHOT_EMAIL_OPTIONS as options,
  MailshotValidationSchema
} from './utils';

class MailshotCreateForm extends React.Component {
  handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await mailshotCreate(data);

    if (success) {
      notifySuccess('Mailshot created.');
      this.props.history.push(pageUrl(MailshotList));
      return;
    }

    handleFormErrors(errors, setFieldError);
    setSubmitting(false);
  };

  render() {
    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        validationSchema={MailshotValidationSchema}>
        {({ isSubmitting, handleSubmit, dirty }) => (
          <Form>
            <UnsavedFormPrompt when={dirty && !isSubmitting} />
            <FormRow>
              <Field
                required
                width={4}
                name="scheduledDate"
                label="Scheduled for:"
                component={DateTimeField}
              />
              <Field
                required
                width={6}
                name="fromName"
                label="From name:"
                component={InputField}
              />
              <Field
                width={6}
                name="fromEmail"
                label="From email:"
                component={SelectField}
                options={options}
                required
                isClearable
              />
            </FormRow>
            <Field
              required
              width={6}
              name="title"
              label="Title:"
              component={InputField}
            />
            <Field
              required
              name="subject"
              width={6}
              label="Subject:"
              component={InputField}
            />
            <FormRow>
              <Field
                width={6}
                name="returnPath"
                label="Return path:"
                component={InputField}
              />
            </FormRow>
            <CreateButtonInRow onClick={handleSubmit} disabled={isSubmitting} />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(MailshotCreateForm);
