import * as yup from 'yup';
import 'utils/yup';

export const emailTemplateUpdateSchema = yup.object().shape({
  title: yup.string().required('This field is required'),
  status: yup
    .string()
    .nullable()
    .required('This field required'),
  trigger: yup
    .string()
    .nullable()
    .required('This field required'),
  fromName: yup.string().required('This field required'),
  fromEmail: yup
    .string()
    .required('This field required')
    .strongEmail('Invalid email.'),
  subject: yup.string().required('This field required'),
  html: yup.string().required('This field required'),
  plainText: yup.string().required('This field required')
});
