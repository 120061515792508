import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Form } from 'semantic-ui-react';

import { Formik } from 'formik';
import { pageUrl } from 'config/routes';
import { ContentSection } from 'pages';

import { notifySuccess } from 'utils/notifications';
import { handleFormErrors, prepareFormData } from 'utils/forms';

import {
  Field,
  FormRow,
  CreateButtonInRow,
  UnsavedFormPrompt,
  UniSelect,
  InputField
} from 'components';

import { contentSectionSchema } from './schema';
import { saveSection } from './sdk';

function ContentSectionForm({ section }) {
  const { productSlug, newsletterId } = useParams();
  const history = useHistory();

  const handleSubmit = async (values, actions) => {
    const { setSubmitting, setFieldError } = actions;
    setSubmitting(true);
    const { errors, success, data } = await saveSection(
      prepareFormData(values)
    );
    setSubmitting(false);
    if (success) {
      notifySuccess('Section saved.');
      history.push(
        pageUrl(ContentSection, {
          productSlug,
          newsletterId,
          sectionId: data.id
        })
      );
      return;
    }
    handleFormErrors(errors, setFieldError);
  };

  return (
    <Formik
      initialValues={section}
      validationSchema={contentSectionSchema}
      enableReinitialize
      onSubmit={handleSubmit}>
      {({ values, handleSubmit, isSubmitting, dirty }) => (
        <Form>
          <UnsavedFormPrompt when={dirty && isSubmitting} />
          <FormRow>
            <Field
              width={5}
              required
              name="title"
              component={InputField}
              label="Title"
            />
            <Field
              width={5}
              required
              name="displayName"
              component={InputField}
              label="Display name"
            />
          </FormRow>

          <FormRow>
            <UniSelect
              width={5}
              required
              name="column"
              label="Column"
              source="newsletterSectionsColumn"
            />
            <UniSelect
              width={5}
              required
              name="sectionType"
              label="Section Type"
              source="newsletterSectionTypes"
            />
          </FormRow>
          <FormRow>
            <UniSelect
              width={5}
              name="articleSection"
              label="Article section"
              productSlug={productSlug}
              source="articleSections"
            />
            <UniSelect
              width={5}
              name="articleGroup"
              label="Article group"
              productSlug={productSlug}
              source="articleGroups"
            />
          </FormRow>
          <FormRow>
            <UniSelect
              width={5}
              required
              name="templateName"
              label="Template"
              source="sectionTemplates"
            />
          </FormRow>
          <FormRow>
            <UniSelect
              width={10}
              required
              name="visibleFor"
              label="Recipient Group"
              source="recipientGroups"
              transferList={true}
              productSlug={productSlug}
              isMulti
            />
          </FormRow>
          <CreateButtonInRow onClick={handleSubmit} disabled={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
}

export default ContentSectionForm;
