import React from 'react';
import _ from 'lodash';

import { withRouter, useParams } from 'react-router-dom';

import { Segment } from 'semantic-ui-react';

import { STEEL_SLUG } from 'domainConstants';

import {
  Page,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  Loader,
  Filters as SharedFilters
} from 'components';

import { newsletterReceiversListV2 } from './sdk';

import { NewsletterReceiversTable } from './components';

import { FILTERS as LAYOUT_FILTERS } from './utils';

const DEFAULT_LIMIT = 50;

const NEWSLETTER_PARAMS_TO_TITLE = {
  [STEEL_SLUG]: 'Daily Steel'
};

function NewsletterReceiversListV2({
  user,
  data,
  limit,
  count,
  page,
  changePage,
  changeRowsPerPage,
  orderBy,
  sort,
  filters,
  clear,
  filterBy
}) {
  const { newsletter } = useParams();
  return (
    <Page user={user}>
      <Page.Body>
        <Page.Content>
          <Header>
            {NEWSLETTER_PARAMS_TO_TITLE[newsletter]} Newsletter Receivers
          </Header>
          <SharedFilters
            filters={filters}
            count={count}
            filterBy={filterBy}
            clearFilters={clear}
            layout={LAYOUT_FILTERS(newsletter)}
          />
          {!data && (
            <Segment>
              <Loader />
            </Segment>
          )}
          {data && (
            <>
              <NewsletterReceiversTable
                sort={sort}
                orderBy={orderBy}
                data={data}
                pagination={
                  <Pagination
                    limit={limit}
                    count={count}
                    page={page}
                    changePage={changePage}
                    changeRowsPerPage={changeRowsPerPage}
                  />
                }
              />
            </>
          )}
        </Page.Content>
      </Page.Body>
    </Page>
  );
}

export default ListViewEssentials({
  sdk: props => {
    const { newsletter } = props.match.params;
    return _.partial(newsletterReceiversListV2, newsletter);
  },
  passPropsToSdk: true,
  limit: DEFAULT_LIMIT
})(LoginRequired(withRouter(NewsletterReceiversListV2)));
