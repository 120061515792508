import React from 'react';

import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import { pageUrl } from 'config/routes';

import { PriceSpreadDetail } from 'pages';

import { YesNo, SortableHeader, TableFooter, ExternalLink } from 'components';
import { PRICE_SPREAD_DETAILS_URL } from 'sdk/urls';

class PriceTable extends React.Component {
  getPriceSpreadUrl = priceSpreadId => {
    return pageUrl(PriceSpreadDetail, { priceSpreadId });
  };

  render() {
    const { priceSpreads, sort, orderBy, pagination } = this.props;

    return (
      <Table celled selectable compact={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              singleLine
              style={{ fontWeight: '400' }}
              colspan="7">
              {pagination}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <SortableHeader sort={sort} orderBy={orderBy} field="id">
              ID
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="title">
              Title
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="display_as">
              Display as
            </SortableHeader>
            <Table.HeaderCell>Show on homepage</Table.HeaderCell>
            <SortableHeader sort={sort} orderBy={orderBy} field="price_serie_1">
              Price Serie 1
            </SortableHeader>
            <SortableHeader sort={sort} orderBy={orderBy} field="price_serie_2">
              Price Serie 2
            </SortableHeader>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {priceSpreads &&
            priceSpreads.map(price => (
              <Table.Row key={price.id}>
                <Table.Cell>
                  <Link to={this.getPriceSpreadUrl(price.id)}>{price.id}</Link>
                </Table.Cell>
                <Table.Cell width={3}>{price.title}</Table.Cell>
                <Table.Cell>{price.display_as}</Table.Cell>
                <Table.Cell width={1}>
                  <YesNo value={price.show_on_homepage} />
                </Table.Cell>
                <Table.Cell>{price.price_serie_1}</Table.Cell>
                <Table.Cell>{price.price_serie_2}</Table.Cell>
                <Table.Cell width={2}>
                  <ExternalLink url={PRICE_SPREAD_DETAILS_URL(price.slug)}>
                    View in website
                  </ExternalLink>
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
        <TableFooter>{pagination}</TableFooter>
      </Table>
    );
  }
}

export default PriceTable;
