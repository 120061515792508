import { DateField, YesNoSelect } from 'components';

import { coerceStringToBoolean } from 'utils';

export const FILTERS = [
  [
    {
      name: 'live',
      emptyValue: null,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Status',
      props: () => ({
        yesLabel: 'Live',
        noLabel: 'Being Edited'
      })
    },
    {
      name: 'date_to_go_out_from',
      emptyValue: null,
      component: DateField,
      label: 'Date from'
    },
    {
      name: 'date_to_go_out_to',
      emptyValue: null,
      component: DateField,
      label: 'Date to'
    }
  ]
];
