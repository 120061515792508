import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import _ from 'lodash';

import { Segment, Table, Dimmer, Loader, Header } from 'semantic-ui-react';

import { SortableHeader, TableFooter, Pagination } from 'components';

import { pageUrl } from 'config/routes';

import { InvoiceDetail, InvoiceList } from 'pages';

import { formatDate } from 'utils';
class EventsInvoices extends React.Component {
  render() {
    const {
      data,
      sort,
      orderBy,
      limit,
      count,
      changePage,
      changeRowsPerPage,
      page
    } = this.props;

    let eventsInvoices = data;

    return (
      <Segment>
        <Header as="h2">Invoices</Header>
        {_.isNil(eventsInvoices) && (
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        )}
        {!_.isNil(eventsInvoices) && !_.isEmpty(eventsInvoices) && (
          <Table celled selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  singleLine
                  style={{ fontWeight: '400' }}
                  colspan="11">
                  <Pagination
                    limit={limit}
                    count={count}
                    page={page}
                    changePage={changePage}
                    changeRowsPerPage={changeRowsPerPage}
                  />
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row textAlign="center" verticalAlign="middle">
                <Table.HeaderCell>Identifier</Table.HeaderCell>
                <Table.HeaderCell>Proform ID</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Date Paid</Table.HeaderCell>
                <Table.HeaderCell>Client ID</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <SortableHeader sort={sort} orderBy={orderBy} field="company">
                  Company
                </SortableHeader>
                <SortableHeader sort={sort} orderBy={orderBy} field="course">
                  Event
                </SortableHeader>
                <SortableHeader
                  sort={sort}
                  orderBy={orderBy}
                  field="invoice_status">
                  Invoice status
                </SortableHeader>
                <Table.HeaderCell>Total</Table.HeaderCell>
                <Table.HeaderCell>Currency</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {eventsInvoices.map(item => (
                <Table.Row key={item.invoice.id}>
                  <Table.Cell>
                    <Link
                      to={pageUrl(InvoiceDetail, {
                        invoiceId: item.invoice.id
                      })}>
                      {item.invoice.identifier}
                    </Link>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {item.invoice.proform_id}
                  </Table.Cell>
                  <Table.Cell>
                    {item.invoice.invoice_date &&
                      formatDate(item.invoice.invoice_date)}
                  </Table.Cell>
                  <Table.Cell>
                    {item.invoice.invoice_paid_on &&
                      formatDate(item.invoice.invoice_paid_on)}
                  </Table.Cell>
                  <Table.Cell>
                    {_.get(item.invoice.contact_info, 'client.id')}
                  </Table.Cell>
                  <Table.Cell>
                    {_.get(item.invoice.contact_info, 'name')}
                  </Table.Cell>
                  <Table.Cell width={2}>
                    {_.get(item.invoice.contact_info, 'company_name')}
                  </Table.Cell>
                  <Table.Cell>
                    <Link
                      to={`${pageUrl(InvoiceList)}?upcoming_event=${
                        item.course_id
                      }`}>
                      {item.course}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{item.invoice.invoice_status}</Table.Cell>
                  <Table.Cell>
                    {item.invoice.payment_info.total_price}
                  </Table.Cell>
                  <Table.Cell>{item.invoice.payment_info.currency}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <TableFooter>
              <Pagination
                limit={limit}
                count={count}
                page={page}
                changePage={changePage}
                changeRowsPerPage={changeRowsPerPage}
              />
            </TableFooter>
          </Table>
        )}
        {!_.isNil(eventsInvoices) &&
          _.isEmpty(eventsInvoices) &&
          'There are no invoice items'}{' '}
      </Segment>
    );
  }
}

export default withRouter(EventsInvoices);
