import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';
import { stringifyParams } from 'utils';

export const priceSeriesList = async (productSlug, params) => {
  params['product_type'] = productSlug;

  const url = `${BASE_URL}/prices/series/v2/`;

  return await requestSdk(() => get(url, params));
};

export const getDownloadRenewalsExportUrl = (productSlug, params) => {
  params['product_type'] = productSlug;
  return `${BASE_URL}/prices/series/v2/export/?${stringifyParams(params)}`;
};
