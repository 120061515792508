import React from 'react';

import _ from 'lodash';

import { Field, SelectField } from 'components';
import { countryList } from './sdk';

class CountrySelect extends React.Component {
  state = {
    options: []
  };
  async componentDidMount() {
    const { data, success } = await countryList();

    const { isValueTitle } = this.props;
    if (success) {
      let options = data.map(country => {
        return {
          key: country.id,
          value: isValueTitle ? country.title : country.id,
          label: country.title,
          meta: {
            region: _.get(country, 'region.title', null)
          }
        };
      });
      options = _.orderBy(options, 'label');
      this.setState({ options });
    }
  }

  render() {
    const { name, isCustom, ...rest } = this.props;
    const { options } = this.state;

    return (
      <Field
        name={name || 'country'}
        component={SelectField}
        options={options}
        isClearable
        {...rest}
      />
    );
  }
}

export default CountrySelect;
