import React from 'react';

import { withRouter } from 'react-router-dom';

import { Segment, Button, Form, Header } from 'semantic-ui-react';

import { Formik } from 'formik';

import { openInNewTab, stringifyParams } from 'utils';

import { BACKEND_URL } from 'sdk/urls';

import {
  Field,
  FormRow,
  InputField,
  SimpleSelect,
  RegionSelect,
  ArticleGroupSelect
} from 'components';
import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { newsletterSendTestSchema } from './schema';
import { newsletterSendTest } from './sdk';

const toSimpleSelectOptions = options => {
  return options.map(option => {
    return {
      key: option,
      value: option.toLowerCase(),
      label: option
    };
  });
};

class SendAndPreview extends React.Component {
  handleSubmit = async (values, actions) => {
    const { newsletter, type } = this.props;

    const { setSubmitting, setFieldError } = actions;

    const data = prepareFormData(values);

    setSubmitting(true);

    const { errors, success } = await newsletterSendTest(
      type,
      newsletter.id,
      data
    );

    setSubmitting(false);

    if (success) {
      notifySuccess('Test email sent.');
      return;
    } else {
      handleFormErrors(errors, setFieldError);
    }
  };

  openPreview = values => {
    const getParams = {
      order_by: { Region: 0, Group: 1 }[values.orderBy],
      edition_type: values.edition.toLowerCase()
    };

    if (values.region) {
      getParams.client_region = values.region;
    }

    const { newsletter, type } = this.props;
    const url = `${BACKEND_URL}/newsletters/${
      newsletter.id
    }/${type}/preview/?${stringifyParams(getParams)}`;

    openInNewTab(url);
  };

  render() {
    const editionOptions = toSimpleSelectOptions(['Full', 'Headlines']);

    const initialValues = {
      email: '',
      edition: 'full',
      orderBy: 'Region',
      region: null
    };

    return (
      <Segment>
        <Header as="h3">Send / Preview Newsletter</Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={newsletterSendTestSchema}
          onSubmit={this.handleSubmit}>
          {({ values, handleSubmit, isSubmitting }) => (
            <Form>
              <FormRow widths="equal">
                <Field
                  required
                  component={InputField}
                  name="email"
                  placeholder="Email"
                  label="Email"
                />
                <Field
                  component={SimpleSelect}
                  name="edition"
                  options={editionOptions}
                  isClearable={false}
                  label="Edition"
                />
                <Field
                  component={ArticleGroupSelect}
                  name="orderBy"
                  isClearable={false}
                  label="Order by"
                />
              </FormRow>
              <FormRow alignEnd>
                <Field component={RegionSelect} name="region" label="Region" />
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  primary
                  disabled={isSubmitting}>
                  Send
                </Button>
                <Button onClick={() => this.openPreview(values)}>
                  Preview
                </Button>
              </FormRow>
            </Form>
          )}
        </Formik>
      </Segment>
    );
  }
}

export default withRouter(SendAndPreview);
