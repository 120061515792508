import {
  InputField,
  AuthorSelect,
  YesNoSelect,
  DateField,
  SelectField
} from 'components';

const eventTypesOptions = [
  {
    key: 'Conference',
    value: 'Conference',
    label: 'Conference'
  },
  {
    key: 'Networking',
    value: 'Networking',
    label: 'Networking'
  },
  {
    key: 'Webinar',
    value: 'Webinar',
    label: 'Webinar'
  },
  {
    key: 'Training',
    value: 'Training',
    label: 'Training'
  }
];

export const FILTERS = [
  [
    {
      name: 'title',
      emptyValue: '',
      component: InputField,
      label: 'Title',
      width: 7
    },
    {
      name: 'date_from',
      emptyValue: null,
      component: DateField,
      label: 'Start date from',
      width: 4
    },
    {
      name: 'date_to',
      emptyValue: null,
      component: DateField,
      label: 'Start date to',
      width: 4
    }
  ],
  [
    {
      name: 'author',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: AuthorSelect,
      label: 'Created by',
      width: 5
    },
    {
      name: 'is_published',
      emptyValue: null,
      fieldComponent: YesNoSelect,
      label: 'Is published',
      width: 3
    },
    {
      name: 'event_type',
      emptyValue: '',
      component: SelectField,
      label: 'Event Type',
      width: 4,
      props: () => ({
        options: eventTypesOptions,
        isClearable: true
      })
    }
  ]
];
