import _ from 'lodash';
import { AsyncSelectField, Field } from 'components';

import { clientTagsList } from './sdk';

const ClientTagsSelectField = props => {
  const loadOptions = async (inputValue, callback) => {
    if (inputValue.length < 3) {
      callback([]);
      return;
    }

    const { data } = await clientTagsList(inputValue);
    const options = data.map(tag => ({
      key: tag,
      value: tag,
      label: tag
    }));
    callback(options);
  };

  const value = [props.field.value]
    .flat()
    .map(x => ({ label: x.trim(), value: x.trim() }));

  return (
    <Field
      component={AsyncSelectField}
      loadOptions={loadOptions}
      value={value}
      isClearable
      isCreatable
      isMulti
      isValidNewOption={(inputValue, values, options) => {
        const trimmedInputValue = _.trim(inputValue);

        if (_.isEmpty(trimmedInputValue)) {
          return false;
        }

        return !_.includes(
          _.map(values, tag => tag.value),
          trimmedInputValue
        );
      }}
      {...props}
    />
  );
};

export default ClientTagsSelectField;
