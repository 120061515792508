import React from 'react';

import { Table } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { ArticleChangesList } from 'pages';

import styles from './styles.module.css';

import { SortableHeader } from 'components';
class EditorsChangesAnalyticsTable extends React.Component {
  render() {
    const { editorsChanges, filters, orderBy, sort, articleType } = this.props;
    return (
      <Table celled selectable className={styles.EditorsChangesAnalyticsTable}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Editor </Table.HeaderCell>
            <SortableHeader
              sort={sort}
              orderBy={orderBy}
              field="editor_total_edits">
              Total Edits
            </SortableHeader>
            <SortableHeader
              sort={sort}
              orderBy={orderBy}
              field="editor_total_edits_in_steel">
              Total Edits in Steel
            </SortableHeader>
            <SortableHeader
              sort={sort}
              orderBy={orderBy}
              field="editor_total_edits_in_power">
              Тotal Edits in Power
            </SortableHeader>
            <SortableHeader
              sort={sort}
              orderBy={orderBy}
              field="editor_avg_percent_total_edits">
              Avg. % Text Edits
            </SortableHeader>
            <SortableHeader
              sort={sort}
              orderBy={orderBy}
              field="editor_avg_percent_total_edits_in_steel">
              % Text Edits in Steel
            </SortableHeader>
            <SortableHeader
              sort={sort}
              orderBy={orderBy}
              field="editor_avg_percent_total_edits_in_power">
              % Text Edits in Power
            </SortableHeader>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {editorsChanges.map(log => (
            <Table.Row key={log.editor_id}>
              <Table.Cell width={2}>{log.editor_email}</Table.Cell>
              <Table.Cell width={1}>{log.total_edits}</Table.Cell>
              <Table.Cell width={1}>
                {articleType !== 'Power Materials' ? (
                  <>{log.total_edits_in_steel}</>
                ) : (
                  'N/A'
                )}
              </Table.Cell>
              <Table.Cell width={1}>
                {articleType !== 'Steel' ? (
                  <>{log.total_edits_in_power}</>
                ) : (
                  'N/A'
                )}
              </Table.Cell>
              <Table.Cell width={1}>
                {log.avg_percent_total_edits ? (
                  <>{log.avg_percent_total_edits}%</>
                ) : (
                  'N/A'
                )}
              </Table.Cell>
              <Table.Cell width={1}>
                {log.avg_percent_total_edits_in_steel &&
                articleType !== 'Power Materials' ? (
                  <>{log.avg_percent_total_edits_in_steel}%</>
                ) : (
                  'N/A'
                )}
              </Table.Cell>
              <Table.Cell width={1}>
                {log.avg_percent_total_edits_in_power &&
                articleType !== 'Steel' ? (
                  <>{log.avg_percent_total_edits_in_power}%</>
                ) : (
                  'N/A'
                )}
              </Table.Cell>
              <Table.Cell width={2} className={styles.LargeText}>
                <Link
                  to={{
                    pathname: pageUrl(ArticleChangesList),
                    search: `?updated_by=${log.editor_id}&updated_at_from=${filters.start}&updated_at_to=${filters.end}`
                  }}>
                  See edits
                </Link>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}

export default EditorsChangesAnalyticsTable;
