import _ from 'lodash';
import { requestSdk, post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const saveSection = async data => {
  let url = `${BASE_URL}/newsletters/v2/content-sections/create/`;
  if (!_.isNil(data.id)) {
    url = `${BASE_URL}/newsletters/v2/content-sections/${data.id}/update/`;
  }
  return await requestSdk(() => post(url, data));
};
