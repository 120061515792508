import { LoginRequired, ListViewEssentials, Page, Header } from 'components';
import React from 'react';

import { unitConversionRateList } from './sdk';
import { UnitConversionRatesTable } from './components';

class UnitConversionRateList extends React.Component {
  render() {
    const { user, data } = this.props;
    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content>
            <Header>Unit Conversion Rates</Header>
            {data && <UnitConversionRatesTable unitRates={data} />}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: unitConversionRateList
})(LoginRequired(UnitConversionRateList));
