import React from 'react';

import _ from 'lodash';

import { Table } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import { pageUrl } from 'config/routes';

import { ClientDetail } from 'pages';

import { formatDateTime } from 'utils';

import { TableFooter, OpenModalButton, YesNo } from 'components';

import { hasRelatedObj } from './utils';

import TrackingEventDetailsModal from './TrackingEventDetailsModal';

import RelatedObject from '../RelatedObject';

const TrackingEventsTable = ({ data, pagination, eventType }) => {
  return (
    <Table celled selectable compact={true}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            singleLine
            style={{ fontWeight: '400' }}
            colspan="8">
            {pagination}
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>Client id</Table.HeaderCell>
          <Table.HeaderCell>Guest</Table.HeaderCell>
          <Table.HeaderCell>Names</Table.HeaderCell>
          <Table.HeaderCell>Company Name</Table.HeaderCell>
          <Table.HeaderCell>Account Manager</Table.HeaderCell>
          <Table.HeaderCell>Date</Table.HeaderCell>
          {hasRelatedObj(eventType) && (
            <Table.HeaderCell>{eventType}</Table.HeaderCell>
          )}
          <Table.HeaderCell>Extra Data</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {data.map(log => (
          <Table.Row key={log.id}>
            <Table.Cell width={1}>
              {log.user && (
                <Link
                  to={pageUrl(ClientDetail, {
                    clientId: log.user.id
                  })}>
                  {log.user.id}
                </Link>
              )}
              {!log.user && 'N/A'}
            </Table.Cell>
            <Table.Cell>
              <YesNo
                value={
                  log.extra_data ? log.extra_data.guest_registration : false
                }
              />
            </Table.Cell>
            <Table.Cell width={2}>
              {log.user
                ? `${_.get(log, 'user.first_name')} ${_.get(
                    log,
                    'user.last_name'
                  )}`
                : `${_.get(log, 'extra_data.client.first_name')} ${_.get(
                    log,
                    'extra_data.client.last_name'
                  )}`}
            </Table.Cell>
            <Table.Cell width={2}>
              {log.user
                ? _.get(log, 'user.company_name')
                : _.get(log, 'extra_data.client.company_name')}
            </Table.Cell>
            <Table.Cell width={2}>
              {_.get(log, 'user.account_manager.first_name')}{' '}
              {_.get(log, 'user.account_manager.last_name')}
            </Table.Cell>
            <Table.Cell>{formatDateTime(log.created_at)}</Table.Cell>
            {hasRelatedObj(eventType) && (
              <Table.Cell>
                <RelatedObject
                  relatedObj={log.related_obj}
                  relatedObjType={eventType}
                />
              </Table.Cell>
            )}
            <Table.Cell>
              <OpenModalButton
                key={log.id}
                size="medium"
                color="green"
                modal={{
                  header: 'Extra Data',
                  render: props => (
                    <TrackingEventDetailsModal
                      extraData={log.extra_data}
                      {...props}
                    />
                  )
                }}>
                Show Details
              </OpenModalButton>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <TableFooter>{pagination}</TableFooter>
    </Table>
  );
};

export default TrackingEventsTable;
