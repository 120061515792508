import _ from 'lodash';

import * as yup from 'yup';

export const buildInitialValues = mailshotGroupItem => {
  if (mailshotGroupItem) {
    const mailshot = mailshotGroupItem.mailshot;

    return {
      account_manager: mailshotGroupItem.account_manager.id,
      ..._.pick(mailshot, [
        'subject',
        'scheduled_date',
        'status',
        'sending_status',
        'total_users',
        'total_opened'
      ])
    };
  }

  return {
    account_manager: null,
    scheduled_date: null,
    subject: '',
    status: 'N/A',
    sending_status: 'N/A',
    total_users: 'N/A',
    total_opened: 'N/A'
  };
};

export const mailshotGroupItemSchema = yup.object().shape({
  scheduled_date: yup
    .string()
    .nullable()
    .required('Scheduled date is required.'),
  subject: yup.string().required('Subject is required.'),
  account_manager: yup.number().required('Account manager is required.')
});
