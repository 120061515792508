import _ from 'lodash';

export const areAllProductsSelected = (products, selectedProducts) =>
  _.isEmpty(
    // filter non selected products
    _.filter(
      products,
      //  check product id is not in selected products ids
      product => !_.includes(_.map(selectedProducts, 'id'), product.itemId)
    )
  );
