import React from 'react';

import { Label } from 'semantic-ui-react';

class HighlightedText extends React.Component {
  render() {
    const { value, highlightColor } = this.props;
    const style = {
      backgroundColor: highlightColor,
      margin: '5px 0'
    };
    return (
      <Label key={value} style={style} basic>
        {value}
      </Label>
    );
  }
}

export default HighlightedText;
