import { useState } from 'react';

import { getFiltersFromUrl, stringifyFilters } from './utils';

function useFilters({ history }) {
  const { search } = history.location;

  const [filters, setFilters] = useState(getFiltersFromUrl(search));

  const filterBy = values => {
    setFilters(values);

    history.replace({
      search: stringifyFilters(values)
    });
  };

  const clear = () => {
    history.replace({ search: '' });

    setFilters({});
  };

  return { filters, filterBy, clear };
}

export default useFilters;
