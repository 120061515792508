import React from 'react';
import { post } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const trackEvent = ({ name, data }) => {
  const payload = {
    event_name: name,
    event_data: data
  };

  const url = `${BASE_URL}/integrations/mixpanel/track/`;

  return post(url, payload);
};

const EventTracking = ({ path, title }) => {
  React.useEffect(() => {
    trackEvent({
      name: 'ADMIN_PAGE_OPEN',
      data: {
        path,
        title
      }
    });
  }, [path, title]);

  return null;
};

export default EventTracking;
