import React from 'react';

import {
  Page,
  Header,
  LoginRequired,
  ListViewEssentials,
  Flex,
  Button,
  ExternalLink,
  PriceSeriesMovementTable
} from 'components';

import { PermissionsRequired, PERMISSIONS } from 'permissions';

import { PriceSeriesDetail, PriceSeriesUpdate } from 'pages';

import { PRICE_MOVEMENT_EXPORT_URL } from 'sdk/urls';

import { priceMovementList } from './sdk';

class PriceMovementList extends React.Component {
  render() {
    const { user, data, crumbs } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Flex spaceBetween>
              <Header>Prices Movement</Header>
              <ExternalLink url={PRICE_MOVEMENT_EXPORT_URL}>
                <Button color="green">Export to excel</Button>
              </ExternalLink>
            </Flex>
            {data && (
              <>
                <PriceSeriesMovementTable
                  priceSeries={data}
                  detailPage={PriceSeriesDetail}
                  updatePage={PriceSeriesUpdate}
                />
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: priceMovementList
})(
  LoginRequired(
    PermissionsRequired([PERMISSIONS.VIEW_PRICES])(PriceMovementList)
  )
);
