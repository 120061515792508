export const UNSUBSCRIPTION_OPTIONS = {
  Events: 'interested_in_events',
  'Steel & Prices': 'interested_in_news',
  'Power Materials': 'power_materials_unsubscribed',
  Reports: 'interested_in_reports',
  'Full Unsubsribe': 'unsubscribed'
};

export const getUnsubscriptionValue = (data, option) => {
  const unsubsribeOption = UNSUBSCRIPTION_OPTIONS[option];

  return data[unsubsribeOption];
};
