import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import {
  Page,
  Header,
  LoginRequired,
  ListViewEssentials,
  Pagination,
  NoDataMessage,
  Filters as SharedFilters
} from 'components';

import { PERMISSIONS, PermissionsRequired } from 'permissions';

import { orderList } from './sdk';

import { OrderTable } from './components';

import { FILTERS } from './utils';

class OrderList extends React.Component {
  render() {
    const {
      user,
      data,
      count,
      page,
      limit,
      filters,
      sort,
      filterBy,
      orderBy,
      clear,
      changePage,
      changeRowsPerPage
    } = this.props;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content>
            <Header>Orders</Header>
            {data && (
              <>
                <SharedFilters
                  filters={filters}
                  count={count}
                  filterBy={filterBy}
                  clearFilters={clear}
                  layout={FILTERS}
                />
                {_.isEmpty(data) && <NoDataMessage />}
                {!_.isEmpty(data) && (
                  <OrderTable
                    orders={data}
                    sort={sort}
                    orderBy={orderBy}
                    pagination={
                      <Pagination
                        limit={limit}
                        count={count}
                        page={page}
                        changePage={changePage}
                        changeRowsPerPage={changeRowsPerPage}
                      />
                    }
                  />
                )}
              </>
            )}
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default ListViewEssentials({
  sdk: orderList
})(
  LoginRequired(
    PermissionsRequired([PERMISSIONS.VIEW_ORDERS])(withRouter(OrderList))
  )
);
