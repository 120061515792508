import { requestSdk, post, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';

export const weeklyPowerMaterialsCreate = async data => {
  const url = `${BASE_URL}/newsletters/weekly-power-materials/create/`;

  return await requestSdk(() => post(url, data));
};

export const weeklyPowerMaterialsCreateTemplates = async () => {
  const url = `${BASE_URL}/newsletters/weekly-power-materials/create/templates/`;

  return await requestSdk(() => get(url));
};
