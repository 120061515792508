import _ from 'lodash';
import * as yup from 'yup';

export const isSelected = (productId, selectedProducts) => {
  return !!_.find(selectedProducts, product => product.id === productId);
};

export const bannerUpdateSchema = yup.object().shape({
  url: yup
    .string()
    .required('URL is required.')
    .kallanishUrl(),
  image: yup.string().required('Image is required.')
});
