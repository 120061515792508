import { coerceStringToBoolean } from 'utils';
import { UniSelect } from 'components';

import {
  PriceSeriesFrequencySelect,
  YesNoSelect,
  AdminSelect,
  DateField
} from 'components';
import { POWER_MATERIAL_SLUG } from 'domainConstants';

export const FILTERS = productSlug => [
  [
    {
      name: 'frequency',
      emptyValue: null,
      fieldComponent: PriceSeriesFrequencySelect,
      label: 'Frequency'
    },
    {
      name: productSlug === POWER_MATERIAL_SLUG ? 'main_group' : 'category',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: UniSelect,
      label: 'Category',
      props: () => ({
        productSlug: productSlug,
        source: 'priceSerieCategoriesForFilters'
      })
    },
    {
      name: 'product',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: UniSelect,
      label: 'Product',
      props: () => ({
        productSlug: productSlug,
        source: 'priceSerieProducts'
      })
    },
    {
      name: 'approved',
      emptyValue: false,
      transform: coerceStringToBoolean,
      fieldComponent: YesNoSelect,
      label: 'Approved'
    }
  ],
  [
    {
      name: 'approved_by',
      emptyValue: null,
      transform: value => parseInt(value, 10),
      fieldComponent: AdminSelect,
      label: 'Approved By'
    },
    {
      name: 'date_from',
      emptyValue: null,
      component: DateField,
      label: 'From'
    },
    {
      name: 'date_to',
      emptyValue: null,
      component: DateField,
      label: 'To'
    }
  ]
];
